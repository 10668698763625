import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import {
  PartialSearchingProps,
  PaginationParams,
  SearchParams
} from 'modules/Shared/type';
import {
  SetFamilyListParamsAction,
  setFamilyListParamsAction
} from 'modules/Family/action/List';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FilterInputType } from 'modules/Layout/type';

export interface StateProps {
  filter: SearchParams;
  pagination: PaginationParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetFamilyListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  values?: SearchParams;
}

export const mapState = (state: RootState): StateProps => {
  const { filter, pagination } = state.family.list;

  return { filter, pagination };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setFamilyListParamsAction(payload))
});

export class FamilyFilter extends React.Component<Props> {
  protected staticInputs: FilterInputType[];

  constructor(props: Props) {
    super(props);

    const { values = {} } = props;

    this.staticInputs = [
      {
        type: 'text',
        property: 'genus',
        label: 'Genus',
        value: _get(values, 'genus')
      },
      {
        type: 'switch',
        property: 'with_deleted',
        label: 'Show all with deleted',
        value: _get(values, 'with_deleted')
      }
    ];
  }

  render(): ReactNode {
    const { filter, pagination, setParams } = this.props;

    return (
      <ListingFilter
        inputs={this.staticInputs}
        filter={filter}
        pagination={pagination}
        setParams={setParams}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(FamilyFilter);
