import listReducer from 'modules/PlantDiseases/reducer/list';
import { combineReducers, Reducer } from 'redux';
import { PlantDiseasesState } from 'modules/PlantDiseases/state';

const reducer = (): Reducer<PlantDiseasesState> => {
  const reducers = {
    list: listReducer
  };

  return combineReducers<PlantDiseasesState>(reducers);
};

export default reducer;
