export type MediaAdvancementEntity = {
  id?: number;
  name?: string;
  langs?: {
    id: number;
    lang: string;
    text: string;
  }[];
  created_at?: string;
  updated_at?: string;
};

export default class MediaAdvancement {
  readonly id?: number;
  readonly name?: string;
  readonly langs?: {
    id: number;
    lang: string;
    text: string;
  }[];

  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(entity: MediaAdvancementEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.langs = entity.langs;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  static getFilterableAttributes(): string[] {
    return [];
  }

  static getSortableAttributes(): string[] {
    return [];
  }
}

export const createMediaAdvancement = (
  entity: MediaAdvancementEntity
): MediaAdvancement => new MediaAdvancement(entity);
