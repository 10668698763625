import React from 'react';
import { useHistory } from 'react-router-dom';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import { thresholdArr } from 'modules/Shared/configs/tresholdsEntity/detailsArr';
import { ROUTE_PLANT_DETAILS_FORM_REDIRECT_TEMPLATE } from 'modules/Plants/routes';
import { PLANT_DETAILS_THRESHOLD } from 'modules/Plants/view/DetailsForm/helper/acceptableTypes';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';

interface IProps {
  data: PlantDetails;
}

const DetailsThresholds: React.FC<IProps> = ({ data }) => {
  const history = useHistory();

  const onEditBtnClick = !data.is_deleted
    ? () =>
        history.push(
          ROUTE_PLANT_DETAILS_FORM_REDIRECT_TEMPLATE +
            PLANT_DETAILS_THRESHOLD +
            '/' +
            data?.id
        )
    : null;

  return (
    <DisplayBaseTabContent title="Thresholds" onEditBtnClick={onEditBtnClick}>
      <div className="tab-content mt-3 row">
        {displayOptionRowContent(
          thresholdArr(data.thresholds),
          true,
          'col-lg-6'
        )}
      </div>
    </DisplayBaseTabContent>
  );
};

export default DetailsThresholds;
