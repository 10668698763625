import sensorSoftwareListReducer from 'modules/Sensor/reducer/sensorSoftwareList';
import sensorDetailsReducer from 'modules/Sensor/reducer/sensorDetails';
import sensorListReducer from 'modules/Sensor/reducer/sensorList';
import { combineReducers, Reducer } from 'redux';
import { SensorState } from 'modules/Sensor/state';

const reducer = (): Reducer<SensorState> => {
  const reducers = {
    sensorSoftwareList: sensorSoftwareListReducer,
    sensorDetails: sensorDetailsReducer,
    sensorList: sensorListReducer
  };

  return combineReducers<SensorState>(reducers);
};

export default reducer;
