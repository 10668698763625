import React from 'react';

export interface Props {
  tabs: {
    slug: string;
    title?: string;
    className?: string;
  }[];
  activeTab: string;
  onChange: (slug: string) => void;
  className?: string;
}

const NavTab = (props: Props): JSX.Element => {
  const { tabs, activeTab, onChange, className } = props;

  return (
    <div className={className}>
      <ul className="nav nav-tabs">
        {tabs.map((tab) => (
          <li className="nav-item mr-1" key={tab.slug}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={`nav-link cursor-pointer ${
                activeTab === tab.slug ? 'active' : ''
              }`}
              onClick={() => onChange(tab.slug)}
            >
              {tab.title || tab.slug}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavTab;
