import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import Loader from 'modules/Layout/component/Loader';
import InformationList from 'modules/Layout/component/InformationList';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import Media from 'modules/Media/model/Media';
import {
  deleteMediaToastError,
  deleteMediaToastSuccess
} from 'modules/Media/toasts';
import { deleteMediaDetails } from 'modules/Media/repository';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onSuccess?: (response: AxiosResponse) => void;
}

export interface State {
  isOpen: boolean;
  media: Media;
  fetching: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class DeleteMediaModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      media: null,
      fetching: false
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  openModal(media: Media): void {
    this.setState({
      isOpen: true,
      fetching: false,
      media
    });
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  async onSubmit(): Promise<void> {
    const { onSuccess, addToast } = this.props;
    const { media } = this.state;

    this.setState({
      fetching: true
    });

    try {
      const response = await deleteMediaDetails(media.id);

      addToast(deleteMediaToastSuccess());

      this.setState({
        isOpen: false
      });

      if (onSuccess) {
        onSuccess(response);
      }
    } catch (error) {
      console.log(error);
      addToast(deleteMediaToastError());
    }
  }

  renderTitle = (): React.ReactNode => {
    return 'Do you want to delete media?';
  };

  renderBody(): React.ReactNode {
    const { fetching, media } = this.state;

    const fields = [
      {
        label: 'Title:',
        value: media.title
      },
      {
        label: 'Type:',
        value: media.type
      },
      {
        label: 'Link:',
        value: media.url
      }
    ];

    return (
      <>
        {fetching && <Loader />}
        <div className="d-flex justify-content-center mb-3">
          {Boolean(media.image) && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img src={media.image} style={{ height: '150px' }} />
          )}
        </div>
        <InformationList fields={fields} />
      </>
    );
  }

  renderFooter(): React.ReactNode {
    const { fetching } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button disabled={fetching} color="primary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button disabled={fetching} color="danger" onClick={this.onSubmit}>
          Delete
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen, media } = this.state;

    if (!media) {
      return null;
    }

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(DeleteMediaModal);
