import {
  PaginationParams,
  SearchParams,
  SortParams,
  PathParams,
  PartialSearchingProps
} from 'modules/Shared/type';
import React from 'react';
import { CustomInput } from 'reactstrap';

export interface Props {
  pagination: PaginationParams;
  currentCount: number;
  filter?: SearchParams;
  sort?: SortParams;
  path?: string;
  pathParams?: PathParams;
  totalPages: number;
  setParams: (params: PartialSearchingProps) => void;
}

export const Pagination = (props: Props) => {
  const { totalPages, pagination, setParams } = props;

  const onClickItem = (page: number) => {
    if (!totalPages || page === 0 || page === totalPages + 1) return;

    setParams({ pagination: { ...pagination, page } });
  };

  const selectOptions = () => {
    const arr = [];
    for (let i = 1; i <= totalPages; i++) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return arr;
  };

  const displayCustomInput = () => {
    if (totalPages === 1 || !totalPages) {
      return (
        <div className="pagination-wrapper">
          <div className="pagination-item-btn">1</div>
        </div>
      );
    } else {
      return (
        <CustomInput
          className="pagination-page-selector"
          id="count"
          type="select"
          onChange={(event) => onClickItem(parseInt(event.target.value))}
          value={pagination.page}
        >
          {selectOptions()}
        </CustomInput>
      );
    }
  };

  return (
    <div className="d-inline-flex">
      <div className="pagination-wrapper">
        <div
          className={`pagination-item-btn ${
            !totalPages || pagination.page - 1 === 0
              ? 'pagination-btn-inactive'
              : 'pagination-btn-active'
          }`}
          onClick={() => onClickItem(pagination.page - 1)}
        >
          Previous
        </div>
      </div>
      <div className="pagination-wrapper">{displayCustomInput()}</div>
      <div className="pagination-wrapper">
        <div
          className={`pagination-item-btn ${
            !totalPages || pagination.page === totalPages
              ? 'pagination-btn-inactive'
              : 'pagination-btn-active'
          }`}
          onClick={() => onClickItem(pagination.page + 1)}
        >
          Next
        </div>
      </div>
    </div>
  );
};
