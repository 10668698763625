import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import { MissingFamilyRequest } from 'modules/MissingFamily/model/MissingFamily';

export interface State {
  isOpen: boolean;
  missingFamilyRequest: MissingFamilyRequest;
}

export class PlantnetResponseModal extends React.Component<unknown, State> {
  constructor(props: unknown) {
    super(props);

    this.state = {
      isOpen: false,
      missingFamilyRequest: null
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  openModal(missingFamilyRequest: MissingFamilyRequest): void {
    this.setState({
      isOpen: true,
      missingFamilyRequest
    });
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  renderTitle = (): React.ReactNode => {
    const { missingFamilyRequest } = this.state;

    return `PlantNet response - ${missingFamilyRequest.user_email} ${missingFamilyRequest.created_at}`;
  };

  renderBody(): React.ReactNode {
    const { missingFamilyRequest } = this.state;

    return (
      <div style={{ maxHeight: '700px', overflow: 'scroll' }}>
        <pre>
          <code>
            {JSON.stringify(missingFamilyRequest.plantnet_response, null, 2)}
          </code>
        </pre>
      </div>
    );
  }

  renderFooter(): React.ReactNode {
    return (
      <div className="d-flex justify-content-end w-100">
        <Button color="primary" onClick={this.toggle}>
          Close
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen, missingFamilyRequest } = this.state;

    if (!missingFamilyRequest) {
      return null;
    }

    return (
      <BaseModal
        isOpen={isOpen}
        size="lg"
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default PlantnetResponseModal;
