import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import {
  managePageAction,
  ManagePageAction,
  AddToastAction,
  AddToastPayload,
  addToastAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteSensorList } from 'modules/Sensor/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import SensorTable from 'modules/Sensor/container/Table/sensorList';
import SensorFilter from 'modules/Sensor/container/Filter/sensorList';
import SensorPagination from 'modules/Sensor/container/Pagination/sensorList';
import SensorPerPage from 'modules/Sensor/container/PerPage/sensorList';
import Sensor from 'modules/Sensor/model/Sensor';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setSensorListParamsAction,
  SetSensorListParamsAction
} from 'modules/Sensor/action/sensorList';
import { RootState } from 'app/reducer';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (payload: PartialSearchingProps) => SetSensorListParamsAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setSensorListParamsAction(payload)),
  addToast: (payload: AddToastPayload) => dispatch(addToastAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.sensor.sensorList;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class SensorListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      Sensor.getFilterableAttributes(),
      Sensor.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Sensors',
      breadcrumb: breadcrumbRouteSensorList()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { fetching, type } = this.props;
    const { filter } = this.searchProps;

    if (type) return null;
    return (
      <div className="row sensor-view">
        <div className="col-12">
          <Listing
            table={<SensorTable />}
            filter={<SensorFilter values={filter} />}
            pagination={<SensorPagination />}
            perPage={<SensorPerPage />}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(SensorListView);
