import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { FaqEntity } from 'modules/Faq/model/FAQ';

export const API_FAQ_LIST = '/admin-faq/list';
export const API_FAQ = '/admin-faq/:id';

export interface FetchFaqListResponse {
  faq: FaqEntity[];
}

export const fetchFaqList = (): AxiosPromise<FetchFaqListResponse> =>
  axios.post(getAPIUrl(API_FAQ_LIST));

export interface UpdateFaqLang {
  [lang: string]: {
    text: string;
  };
}

export const updateFaq = (id: number, lang: UpdateFaqLang): AxiosPromise =>
  axios.put(getAPIUrl(API_FAQ, { id }), { lang });

export interface FetchFaqResponse {
  faq: FaqEntity;
}

export const fetchFaq = (id: number): AxiosPromise<FetchFaqResponse> =>
  axios.get(getAPIUrl(API_FAQ, { id }));
