import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteDetailsPlantUser } from 'modules/PlantUser/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { setPlantUserDetailsParamsAction } from 'modules/PlantUser/action/details';
import { RootState } from 'app/reducer';
import Loader from 'modules/Layout/component/Loader';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import DetailsMain from 'modules/PlantUser/view/Details/components/DisplayDetailsMain';
import DetailsMeasurements from 'modules/PlantUser/view/Details/components/DisplayDetailsMeasurements';

interface IParams {
  id: string;
}

const PlantUserDetailsView = () => {
  const params = useParams<IParams>();
  const dispatch = useDispatch();
  const details = useSelector((state: RootState) => state.plantUser.details);
  const { type } = useSelector((state: RootState) => state.layout.page);

  const navElements = ['Main data', 'Measurements'];
  const [nav, setNav] = useState(navElements.map((_, index) => index === 0));

  useEffect(() => {
    dispatch(
      managePageAction({
        title: details?.plantUserDetails
          ? details.plantUserDetails.nickname
          : 'User plant',
        breadcrumb: breadcrumbRouteDetailsPlantUser(details?.plantUserDetails)
      })
    );
  }, [details.plantUserDetails]);

  useEffect(() => {
    if (params?.id) {
      const id = parseInt(params.id, 10);
      if (id) dispatch(setPlantUserDetailsParamsAction(id));
    }
  }, []);

  if (type) return null;
  if (details.fetching)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );
  return (
    <div className="position-relative">
      <div className="details-top-nav mb-3">
        <DisplayNav
          valueArr={navElements}
          booleanArr={nav}
          setBooleanArr={setNav}
        />
      </div>

      {nav[0] && <DetailsMain data={details.plantUserDetails} />}
      {nav[1] && (
        <DetailsMeasurements data={details.plantUserDetails?.measurements} />
      )}
    </div>
  );
};

export default PlantUserDetailsView;
