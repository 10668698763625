import MissingFamilyDetails from 'modules/MissingFamily/model/MissingFamilyDetails';

export const primaryInfoArr = (data: MissingFamilyDetails = null) => [
  {
    title: 'Genus',
    description: data?.family_scientific_name || ''
  },
  {
    title: 'Scientific name',
    description: data?.scientific_name || '-'
  },
  {
    title: 'Created at',
    description: data?.created_at || ''
  },
  {
    title: 'Updated at',
    description: data?.updated_at || ''
  }
];
