import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { PlantUserEntity } from 'modules/PlantUser/model/PlantUser';
import { Action } from 'redux';

export const SET_PLANT_USER_LIST_PARAMS = 'PLANT/USER/LIST/PARAMS/SET';
export const FETCH_PLANT_USER_LIST = 'PLANT/USER/LIST/FETCH';
export const PLANT_USER_LIST_FETCHED = 'PLANT/USER/LIST/FETCHED';

export type PlantUserListAction =
  | SetPlantUserListParamsAction
  | FetchPlantUserListAction
  | PlantUserListFetchedAction;

export type SetPlantUserListParamsAction = SetSearchingParamsAction<
  typeof SET_PLANT_USER_LIST_PARAMS
>;

export const setPlantUserListParamsAction = (
  payload: PartialSearchingProps
): SetPlantUserListParamsAction =>
  setSearchingParamsAction(SET_PLANT_USER_LIST_PARAMS, payload);

export type FetchPlantUserListAction = Action<typeof FETCH_PLANT_USER_LIST>;

export const fetchPlantUserListAction = (): FetchPlantUserListAction => ({
  type: FETCH_PLANT_USER_LIST
});

export type PlantUserListFetchedPayload = {
  plantUser: PlantUserEntity[];
  totalPages: number;
};

export interface PlantUserListFetchedAction
  extends Action<typeof PLANT_USER_LIST_FETCHED> {
  payload: PlantUserListFetchedPayload;
}

export const plantUserListFetchedAction = (
  payload: PlantUserListFetchedPayload
): PlantUserListFetchedAction => ({
  type: PLANT_USER_LIST_FETCHED,
  payload
});
