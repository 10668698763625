import { RootState } from 'app/reducer';
import { setLocationSearchAction, manageThrow } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_PLANT_GROUPS_LIST,
  fetchPlantGroupsListAction,
  SET_PLANT_GROUPS_LIST_PARAMS,
  plantGroupsListFetchedAction
} from 'modules/PlantGroups/action/list';
import {
  fetchPlantGroups,
  FetchPlantGroupsResponse,
  fetchPlantGroupsAll
} from 'modules/PlantGroups/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { AxiosResponse } from 'axios';

export function* setPlantGroupsListParamsSaga(): SagaIterator {
  yield put(fetchPlantGroupsListAction());
}

export function* fetchPlantGroupsListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.plantGroups.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    let response: AxiosResponse<FetchPlantGroupsResponse> = null;
    if (pagination === 'all') {
      response = yield call(fetchPlantGroupsAll);
    } else {
      yield put(setLocationSearchAction({ searchParams }));

      response = yield call(fetchPlantGroups, {
        ...sort,
        pagination,
        search: filter
      });
    }

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      plantGroupsListFetchedAction({
        plantGroups: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        plantGroupsListFetchedAction({ plantGroups: [], totalPages: 1 })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantListSaga(): SagaIterator {
  yield takeLatest(SET_PLANT_GROUPS_LIST_PARAMS, setPlantGroupsListParamsSaga);
  yield takeLatest(FETCH_PLANT_GROUPS_LIST, fetchPlantGroupsListSaga);
}
