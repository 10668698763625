import React from 'react';
import { IState } from 'modules/Layout/view/DetailsForm/Reducer';
import DisplayBaseInput from 'modules/Layout/view/DetailsForm/components/DisplayBaseInput';

interface IProps {
  arrayOfFieldDesc: {
    title: string;
    mapped: string;
    shortTitle?: string;
    type?: string;
  }[];
  field: IState;
  updateField: (value: string | number, mapped: string | number) => void;
  loading?: boolean;
}

const DisplayThresholds: React.FC<IProps> = (props) => {
  const { arrayOfFieldDesc, field, updateField, loading } = props;

  const displayArr = () => {
    return arrayOfFieldDesc.map((desc, index) => {
      if (!desc.mapped) {
        return null;
      }

      const alterDesc = Object.assign({}, desc);
      if (desc?.shortTitle) {
        alterDesc.title = desc.shortTitle;
      }

      const displayContent = (
        <DisplayBaseInput
          objInfo={alterDesc}
          value={(field?.[desc.mapped]?.value as string | number) || ''}
          mapped={desc.mapped}
          onChange={updateField}
          type={desc.type}
          loading={loading}
        />
      );

      if (
        field?.[desc.mapped]?.name.includes('_min_acceptable') ||
        field?.[desc.mapped]?.name.includes('ph_min') ||
        field?.[desc.mapped]?.name.includes('air_humidity_min')
      ) {
        return (
          <div
            className="col-6 col-xl-3 mt-5 position-relative d-flex"
            key={index}
          >
            <div
              className="position-absolute absolute-top--50px h4 white-space-nowrap text-center"
              style={{ width: '20vw' }}
            >
              {desc.title}
            </div>
            {displayContent}
          </div>
        );
      }

      if (field?.[desc.mapped]?.name.includes('ph_max')) {
        return (
          <div
            className="col-6 col-xl-9 mt-5 d-flex flex-wrap justify-content-between"
            key={index}
          >
            {displayContent}
          </div>
        );
      }

      return (
        <div
          className="col-6 col-xl-3 mt-5 d-flex flex-wrap justify-content-between"
          key={index}
        >
          {displayContent}
        </div>
      );
    });
  };

  return <>{displayArr()}</>;
};

export default DisplayThresholds;
