import React, { ReactNode } from 'react';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { Dispatch } from 'redux';
import { RootState } from 'app/reducer';
import { breadcrumbRoutePlantGroupsBulkUpdate } from 'modules/PlantGroups/breadcrumbs';
import { connect } from 'react-redux';
import PlantGroupBulkUpdateForm from 'modules/PlantGroups/component/Form/Edit/Bulk';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export type Props = StateProps & DispatchProps;

export interface State {
  test?: null;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class PlantGroupsBulkUpdateView extends React.Component<Props, State> {
  componentDidMount() {
    const { managePage } = this.props;

    managePage({
      title: 'Plant groups - multiple update',
      breadcrumb: breadcrumbRoutePlantGroupsBulkUpdate(),
      type: null
    });
  }

  render(): ReactNode {
    const { type } = this.props;

    if (type) {
      return null;
    }

    return <PlantGroupBulkUpdateForm />;
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantGroupsBulkUpdateView);
