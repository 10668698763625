import React, { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import User, { createUser } from 'modules/User/model/User';
import { fetchUser } from 'modules/User/repository';
import { breadcrumbRouteUser } from 'modules/User/breadcrumbs';
import UserDetails from 'modules/User/component/Details';
import { ConnectedPlantListEntity } from 'modules/PlantGroups/model/PlantGroupDetails';
import { GardenEntity } from 'modules/Garden/model/Garden';
import { HubEntity } from 'modules/Hub/model/Hub';
import { SensorEntity } from 'modules/Sensor/model/Sensor';
import { FeedbackEntity } from 'modules/User/model/Feedback';
import { HelpFindPlantEntity } from 'modules/Plants/model/HelpFindPlant';
import { HealthCheckHelpEntity } from 'modules/PlantDiseases/model/HealthCheckHelp';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps {
  id: string;
}

export interface State {
  user: User;
  plants: ConnectedPlantListEntity[];
  gardens: GardenEntity[];
  hubs: HubEntity[];
  sensors: SensorEntity[];
  feedback: FeedbackEntity[];
  finder_help: HelpFindPlantEntity[];
  health_check: HealthCheckHelpEntity[];
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class UserDetailsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: null,
      plants: [],
      gardens: [],
      hubs: [],
      sensors: [],
      feedback: [],
      finder_help: [],
      health_check: []
    };
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow } = this.props;

    managePage({
      title: 'User',
      breadcrumb: breadcrumbRouteUser(),
      type: 'loading'
    });

    try {
      const { data } = await fetchUser(id);

      const user = createUser(data.user);

      this.setState({
        user,
        plants: data.plants,
        gardens: data.gardens,
        hubs: data.hubs,
        sensors: data.sensors,
        feedback: data.feedback,
        finder_help: data.finder_help,
        health_check: data.health_check
      });

      managePage({
        title: user.getName(),
        breadcrumb: breadcrumbRouteUser(user),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  render(): ReactNode {
    const { type } = this.props;
    const {
      user,
      plants,
      gardens,
      hubs,
      sensors,
      feedback,
      finder_help,
      health_check
    } = this.state;

    if (type || !user) {
      return null;
    }

    return (
      <UserDetails
        user={user}
        plants={plants}
        gardens={gardens}
        hubs={hubs}
        sensors={sensors}
        feedback={feedback}
        finder_help={finder_help}
        health_check={health_check}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(UserDetailsView);
