import React, { useState } from 'react';
import Dictionary from 'modules/Dictionary/model/Dictionary';
import DisplayOptionRowArray from 'modules/Layout/view/Details/Helper/DisplayOptionRowArray';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { ROUTE_DICTIONARY_UPDATE } from 'modules/Dictionary/routes';

export interface Props extends RouteComponentProps<any> {
  dictionary: Dictionary;
  onDelete: () => void;
  deleteMessageText: string;
  deleteMessage: () => void;
  deleteMessageVariant: string;
  table: string;
}

const DictionaryDetails = (props: Props): JSX.Element => {
  const {
    dictionary,
    history,
    onDelete,
    deleteMessage,
    deleteMessageText,
    deleteMessageVariant,
    table
  } = props;
  const [langs, setLangs] = useState(
    supportedLangs.map((_, index) => index === 0)
  );

  const getLang = supportedLangs[langs.findIndex((lang) => lang)];

  return (
    <div className="position-relative">
      <div className="content">
        <div className="row">
          <DisplayOptionRowArray
            optionArr={[
              {
                title: 'Primary Info',
                arrayOfBtn: [
                  {
                    text: 'Delete',
                    callback: onDelete,
                    bsColor: 'danger',
                    inactive: dictionary.is_deleted
                  }
                ],
                content: () => [
                  <div key="content">
                    <ShowMessage
                      message={deleteMessageText}
                      deleteMessage={deleteMessage}
                      bsColor={deleteMessageVariant}
                    />
                    {displayOptionRowContent([
                      {
                        title: 'name',
                        description: dictionary.name
                      },
                      {
                        title: 'Is deleted',
                        description: dictionary.getIsDeleted()
                      },
                      {
                        title: 'Created at',
                        description: dictionary.created_at
                      },
                      {
                        title: 'Deleted at',
                        description: dictionary.deleted_at
                      }
                    ])}
                  </div>
                ]
              }
            ]}
          />
          <DisplayBaseTabContent
            title="Descriptions"
            className="content col-lg-6"
            onEditBtnClick={() =>
              history.push(
                getPathUrl(
                  ROUTE_DICTIONARY_UPDATE,
                  { id: dictionary.id },
                  { table }
                )
              )
            }
          >
            <DisplayNav
              booleanArr={langs}
              setBooleanArr={setLangs}
              valueArr={supportedLangs}
            />
            <div className="tab-content mt-3">
              {displayOptionRowContent([
                {
                  title: 'Text',
                  description: dictionary.langs[getLang].text
                }
              ])}
            </div>
          </DisplayBaseTabContent>
        </div>
      </div>
    </div>
  );
};

export default withRouter(DictionaryDetails);
