import FamilyDetails from 'modules/Family/model/FamilyDetails';

export const primaryInfoArr = (data: FamilyDetails = null) => [
  {
    title: 'Genus',
    description: data?.genus || '',
    mapped: 'genus',
    type: 'text'
  },
  {
    title: 'Is deleted',
    description: data?.is_deleted ? 'Yes' : 'No',
    mapped: ''
  },
  {
    title: 'Eligibility',
    description: data?.eligibility || '',
    mapped: ''
  },
  {
    title: 'Created at',
    description: data?.created_at || '',
    mapped: ''
  }
];
