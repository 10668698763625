import { UserEntity, userStatus } from 'modules/User/model/User';
import PlantUserDetails, {
  plantUserStatus
} from 'modules/PlantUser/model/PlantUserDetails';
import { ROUTE_USERS_DETAILS } from 'modules/User/routes';
import {
  GardenDetailsEntity,
  gardenStatusIsDeleted
} from 'modules/Garden/model/GardenDetails';
import SensorDetails, {
  sensorStatus
} from 'modules/Sensor/model/SensorDetails';
import { ROUTE_SENSOR_DETAILS_REDIRECT_TEMPLATE } from 'modules/Sensor/routes';
import { ROUTE_GARDEN_DETAILS_REDIRECT_TEMPLATE } from 'modules/Garden/routes';
import { hubStatus } from 'modules/Hub/model/HubDetails';
import { ROUTE_PLANTS_DETAILS_TEMPLATE } from 'modules/Plants/routes';
import { ROUTE_HUB_DETAILS_REDIRECT } from 'modules/Hub/routes';

const createUserLink = (id: number) =>
  id ? `${ROUTE_USERS_DETAILS}/${id}` : null;

const createGardenLink = (id: number) =>
  id ? `${ROUTE_GARDEN_DETAILS_REDIRECT_TEMPLATE}${id}` : null;

const createSensorLink = (id: string) =>
  id ? `${ROUTE_SENSOR_DETAILS_REDIRECT_TEMPLATE}${id}` : null;

const createHubLink = (id: number) =>
  id ? `${ROUTE_HUB_DETAILS_REDIRECT}${id}` : null;

const createPlantLink = (id: number) =>
  id ? `${ROUTE_PLANTS_DETAILS_TEMPLATE}${id}` : null;

export const userPlantInfoArr = (data: PlantUserDetails) => [
  {
    title: 'Nickname:',
    description: data?.nickname || '-',
    mapped: 'nickname',
    type: 'text'
  },
  {
    title: 'Scientific name',
    type: 'link',
    description: data?.scientific_name || '-',
    to: createPlantLink(data?.plant_id)
  },
  {
    title: 'Status:',
    description: plantUserStatus(data?.status) || '-',
    type: 'text'
  },
  {
    title: 'Owner email:',
    type: 'link',
    description: data?.user ? data.user.email : '-',
    to: createUserLink(data?.user?.id)
  },
  {
    title: 'Owner username:',
    type: 'link',
    description: data?.user ? data.user.username : '-',
    to: createUserLink(data?.user?.id)
  },
  {
    title: 'Garden name:',
    type: 'link',
    description: data?.garden ? data.garden.name : '-',
    to: createGardenLink(data?.garden?.id)
  },
  {
    title: 'Hub:',
    type: 'link',
    description: data?.hub ? data.hub.hub_id : '-',
    to: createHubLink(data?.hub?.id)
  },
  {
    title: 'Hub status:',
    description: hubStatus(data?.hub?.status)
  },
  {
    title: 'Sensor:',
    type: 'link',
    description: data?.sensor?.id ? data.sensor.id : '-',
    to: createSensorLink(data?.sensor?.id)
  },
  {
    title: 'Sensor status:',
    description: sensorStatus(data?.sensor?.status)
  },
  {
    title: 'Created at:',
    description: data?.created_at || '-',
    type: 'text'
  }
];

export const gardenUserPlantInfoArr = (data: GardenDetailsEntity) => [
  {
    title: 'Name',
    description: data?.name || '-',
    type: 'link',
    to: createGardenLink(data?.id)
  },
  {
    title: 'Is deleted',
    description: gardenStatusIsDeleted(data?.status) || '-',
    type: 'text'
  },
  {
    title: 'Created at',
    description: data?.created_at || '-',
    mapped: '',
    type: 'text'
  }
];

export const plantUserOwnerInfoArr = (data: UserEntity) => [
  {
    title: 'Email',
    description: data?.email || '-',
    mapped: 'email',
    type: 'link',
    to: createUserLink(data?.id)
  },
  {
    title: 'Username',
    description: data?.username || '-',
    mapped: 'username',
    type: 'link',
    to: createUserLink(data?.id)
  },
  {
    title: 'Country',
    description: data?.country || '-',
    mapped: 'country',
    type: 'text'
  },
  {
    title: 'City',
    description: data?.city || '-',
    mapped: 'city',
    type: 'text'
  },
  {
    title: 'Birth date',
    description: data?.birth_date || '-',
    mapped: 'birth_date',
    type: 'text'
  },
  {
    title: 'Language',
    description: data?.lang || '-',
    mapped: 'lang',
    type: 'text'
  },
  {
    title: 'Status',
    description: userStatus(data?.status) || '-',
    type: 'text'
  }
];

export const plantUserSensorInfoArr = (data: SensorDetails) =>
  data
    ? [
        {
          title: 'Mac',
          description: data?.id || '-',
          type: 'link',
          to: createSensorLink(data?.id)
        },
        {
          title: 'Status',
          description: sensorStatus(data?.status),
          type: 'text'
        },
        {
          title: 'Version',
          description: data?.version || '-',
          type: 'text'
        },
        {
          title: 'Created at',
          description: data?.sensor_created_at || '-',
          type: 'text'
        }
      ]
    : [
        {
          title: 'No sensor:',
          description: "seems like there isn't any sensor connected.",
          type: 'text'
        }
      ];
