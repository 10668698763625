import React, { createRef, ReactNode } from 'react';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import MediaAdvancement, {
  createMediaAdvancement
} from 'modules/Media/model/MediaAdvancement';
import { fetchMediaAdvancementDetails } from 'modules/Media/repository';
import { breadcrumbRouteMediaAdvancementDetails } from 'modules/Media/breadcrumbs';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { getPathUrl } from 'modules/Shared/helper/api';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import {
  ROUTE_MEDIA_ADVANCEMENT,
  ROUTE_MEDIA_ADVANCEMENT_EDIT
} from 'modules/Media/routes';
import DeleteMediaAdvancementModal, {
  DeleteMediaAdvancementModal as DeleteMediaAdvancementModalClass
} from 'modules/Media/component/Modal/Delete/Advancement';
import InformationList from 'modules/Layout/component/InformationList';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps, RouteComponentProps {
  id: string;
}

export interface State {
  mediaAdvancement: MediaAdvancement;
  langTab: boolean[];
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class MediaAdvancementDetailsView extends React.Component<Props, State> {
  readonly deleteModalRef: React.RefObject<DeleteMediaAdvancementModalClass>;

  constructor(props: Props) {
    super(props);

    this.state = {
      mediaAdvancement: null,
      langTab: supportedLangs.map((_, index) => index === 0)
    };

    this.deleteModalRef = createRef();
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow } = this.props;

    managePage({
      title: 'Media advancement',
      breadcrumb: breadcrumbRouteMediaAdvancementDetails(id),
      type: 'loading'
    });

    try {
      const { data } = await fetchMediaAdvancementDetails(id);

      const mediaAdvancement = createMediaAdvancement(data.mediaAdvancement);

      this.setState({
        mediaAdvancement
      });

      managePage({
        title: 'Media advancement',
        breadcrumb: breadcrumbRouteMediaAdvancementDetails(
          id,
          mediaAdvancement.name
        ),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  render(): ReactNode {
    const { type, history } = this.props;
    const { mediaAdvancement, langTab } = this.state;

    if (type || !mediaAdvancement) {
      return null;
    }

    const activeIndex = langTab.findIndex((bool) => bool);
    const activeLang = supportedLangs[activeIndex];

    return (
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <DeleteMediaAdvancementModal
                ref={this.deleteModalRef}
                onSuccess={() =>
                  history.push(getPathUrl(ROUTE_MEDIA_ADVANCEMENT))
                }
              />
              <div className="d-flex justify-content-end actions-wrapper">
                <ActionUpdate
                  title="Edit media advancement"
                  className="mr-2"
                  to={getPathUrl(ROUTE_MEDIA_ADVANCEMENT_EDIT, {
                    id: mediaAdvancement.id
                  })}
                />
                <ActionDelete
                  title="Delete media advancement"
                  onClick={() =>
                    this.deleteModalRef?.current?.openModal(mediaAdvancement)
                  }
                />
              </div>
              <InformationList
                fields={[
                  {
                    label: 'Name:',
                    value: mediaAdvancement.name
                  }
                ]}
              />
              <hr className="border my-3" />
              <div className="details-top-nav mt-3 mb-1">
                <DisplayNav
                  booleanArr={langTab}
                  setBooleanArr={(newTabs: boolean[]) =>
                    this.setState({ langTab: newTabs })
                  }
                  valueArr={supportedLangs}
                />
              </div>
              {supportedLangs.map((lang) => {
                const found = mediaAdvancement.langs.find(
                  (obj) => obj.lang === lang
                );

                if (activeLang !== lang) {
                  return null;
                }

                return <div key={lang}>{found?.text || ''}</div>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(
    mapState,
    mapDispatch
  )(MediaAdvancementDetailsView)
);
