import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { NewsEntity } from 'modules/News/model/News';
import { Action } from 'redux';

export const SET_NEWS_LIST_PARAMS = 'NEWS/LIST/PARAMS/SET';
export const FETCH_NEWS_LIST = 'NEWS/LIST/FETCH';
export const NEWS_LIST_FETCHED = 'NEWS/LIST/FETCHED';

export type NewsListAction =
  | SetNewsListParamsAction
  | FetchNewsListAction
  | NewsListFetchedAction;

export type SetNewsListParamsAction = SetSearchingParamsAction<
  typeof SET_NEWS_LIST_PARAMS
>;

export const setNewsListParamsAction = (
  payload: PartialSearchingProps
): SetNewsListParamsAction =>
  setSearchingParamsAction(SET_NEWS_LIST_PARAMS, payload);

export type FetchNewsListAction = Action<typeof FETCH_NEWS_LIST>;

export const fetchNewsListAction = (): FetchNewsListAction => ({
  type: FETCH_NEWS_LIST
});

export type NewsListFetchedPayload = {
  news: NewsEntity[];
  totalPages: number;
};

export interface NewsListFetchedAction
  extends Action<typeof NEWS_LIST_FETCHED> {
  payload: NewsListFetchedPayload;
}

export const newsListFetchedAction = (
  payload: NewsListFetchedPayload
): NewsListFetchedAction => ({
  type: NEWS_LIST_FETCHED,
  payload
});
