import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import Loader from 'modules/Layout/component/Loader';
import InformationList from 'modules/Layout/component/InformationList';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import Family from 'modules/Family/model/Family';
import { deleteFamilyDetails } from 'modules/Family/repository';
import {
  deleteFamilyToastError,
  deleteFamilyToastSuccess
} from 'modules/Family/toasts';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onSuccess?: (response: AxiosResponse) => void;
}

export interface State {
  isOpen: boolean;
  family: Family;
  fetching: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class DeleteFamilyModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      family: null,
      fetching: false
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  openModal(family: Family): void {
    this.setState({
      isOpen: true,
      fetching: false,
      family
    });
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  async onSubmit(): Promise<void> {
    const { onSuccess, addToast } = this.props;
    const { family } = this.state;

    this.setState({
      fetching: true
    });

    try {
      const response = await deleteFamilyDetails(family.id);

      addToast(deleteFamilyToastSuccess());

      this.setState({
        isOpen: false
      });

      if (onSuccess) {
        onSuccess(response);
      }
    } catch (error) {
      console.log(error);
      addToast(deleteFamilyToastError());
    }
  }

  renderTitle = (): React.ReactNode => {
    return 'Do you want to delete family?';
  };

  renderBody(): React.ReactNode {
    const { fetching, family } = this.state;

    const fields = [
      {
        label: 'Name:',
        value: family.name
      },
      {
        label: 'Genus:',
        value: family.genus
      }
    ];

    return (
      <>
        {fetching && <Loader />}
        <InformationList fields={fields} />
      </>
    );
  }

  renderFooter(): React.ReactNode {
    const { fetching } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button disabled={fetching} color="primary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button disabled={fetching} color="danger" onClick={this.onSubmit}>
          Delete
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen, family } = this.state;

    if (!family) {
      return null;
    }

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(DeleteFamilyModal);
