import React from 'react';
import { StandardBtn } from 'modules/Layout/view/Buttons/StandardBtn';

interface IProps {
  warningType?: string;
  goBackBtnCallback?: () => void;
}

export const NotEditableWarning: React.FC<IProps> = ({
  warningType,
  goBackBtnCallback
}) => {
  const displayMessage = () => {
    if (warningType === 'id')
      return "Sorry, but we can't find this plant. If you are sure this plant should exist contact with administrator to resolve problem.";
    else if (warningType) return 'This ' + warningType + ' is not supported.';
    else
      return "Sorry, but we can't display what you are looking for. If you see this error multiple times contact with your administrator to resolve problem.";
  };
  return (
    <div className="card restrict-width">
      <div className="card-body p-3">
        {displayMessage()}
        {goBackBtnCallback && (
          <div className="mt-2">
            <StandardBtn text="Back" callback={goBackBtnCallback} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NotEditableWarning;
