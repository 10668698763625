import listReducer from 'modules/Policy/reducer/list';
import { combineReducers, Reducer } from 'redux';
import { PolicyState } from 'modules/Policy/state';

const reducer = (): Reducer<PolicyState> => {
  const reducers = {
    list: listReducer
  };

  return combineReducers<PolicyState>(reducers);
};

export default reducer;
