import News, { acceptableNewsTypesValues } from 'modules/News/model/News';

export const primaryInfoArr = (data: News) => [
  {
    title: 'Title',
    description: data?.title || '',
    mapped: 'title',
    type: 'text'
  },
  {
    title: 'Description',
    description: data?.description || '',
    mapped: 'description',
    type: 'text'
  },
  {
    title: 'Section',
    description: News.getSection(data?.section),
    type: 'text'
  },
  {
    title: 'Type',
    description: data?.type || '',
    mapped: 'type',
    type: 'select',
    selectOptions: acceptableNewsTypesValues
  },
  {
    title: 'Link',
    description: data?.url || '',
    mapped: 'url',
    type: 'outerLink',
    to: data?.url
  },
  {
    title: 'Updated at',
    description: data?.updated_at || '',
    mapped: '',
    type: 'text'
  },
  {
    title: 'Created at',
    description: data?.created_at || '',
    mapped: '',
    type: 'text'
  }
];
