import {
  FETCH_MEDIA_ADVANCEMENT_LIST,
  FETCH_MEDIA_LIST,
  MEDIA_ADVANCEMENT_LIST_FETCHED,
  MEDIA_LIST_FETCHED,
  MediaListAction,
  SET_MEDIA_ADVANCEMENT_LIST_PARAMS,
  SET_MEDIA_LIST_PARAMS
} from 'modules/Media/action/List';
import { createMedia } from 'modules/Media/model/Media';
import { initMediaListState, MediaListState } from 'modules/Media/state/list';
import { createMediaAdvancement } from 'modules/Media/model/MediaAdvancement';

const initState = initMediaListState();

const reducer = (
  state: MediaListState = initState,
  action: MediaListAction
): MediaListState => {
  switch (action.type) {
    case SET_MEDIA_LIST_PARAMS:
    case SET_MEDIA_ADVANCEMENT_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_MEDIA_LIST:
    case FETCH_MEDIA_ADVANCEMENT_LIST:
      return { ...state, fetching: true };

    case MEDIA_LIST_FETCHED: {
      const { media = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        media: media.map(createMedia),
        totalPages: action.payload.totalPages
      };
    }

    case MEDIA_ADVANCEMENT_LIST_FETCHED: {
      const { mediaAdvancement = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        mediaAdvancement: mediaAdvancement.map(createMediaAdvancement),
        totalPages: action.payload.totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
