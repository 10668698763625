import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setGardenListParamsAction,
  SetGardenListParamsAction
} from 'modules/Garden/action/list';
import Garden from 'modules/Garden/model/Garden';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { gardenStatusIsDeleted } from 'modules/Garden/model/GardenDetails';
import ActionShow from 'modules/Layout/component/Action/Show';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_GARDEN_DETAILS } from 'modules/Garden/routes';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';

export interface StateProps {
  gardens: Garden[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetGardenListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (plant: Garden) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { gardens, sort } = state.garden.list;

  return { gardens, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setGardenListParamsAction(payload))
});

export class GardenTable extends React.Component<Props> {
  readonly cols: TableCol<Garden>[];

  constructor(props: Props) {
    super(props);
    this.cols = [
      {
        property: 'name',
        label: 'Name',
        sortable: true,
        className: 'col-4',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_GARDEN_DETAILS, { id: row.id })
      },
      {
        property: 'email',
        label: 'Owner email',
        sortable: true,
        className: 'col-4',
        columnRedirectTo: (row) =>
          Boolean(row.user_id) &&
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: 'username',
        label: 'Owner username',
        className: 'col-3',
        columnRedirectTo: (row) =>
          Boolean(row.user_id) &&
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: 'is_deleted',
        label: 'Is deleted',
        value: (row) => gardenStatusIsDeleted(row.status),
        className: 'col-1'
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show garden"
              to={getPathUrl(ROUTE_GARDEN_DETAILS, { id: row.id })}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { gardens, sort, setParams } = this.props;

    return (
      <Table
        cols={this.cols}
        rows={gardens}
        sort={sort}
        onSort={(params) => setParams({ sort: params })}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(GardenTable);
