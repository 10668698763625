import { BreadcrumbItem } from 'modules/Layout/type';
import SensorDetails from 'modules/Sensor/model/SensorDetails';

// eslint-disable-next-line import/prefer-default-export
export const breadcrumbRouteSensorSoftwares = (): BreadcrumbItem[] => [
  {
    title: 'Sensor software'
  }
];

export const breadcrumbRouteSensorList = (): BreadcrumbItem[] => [
  {
    title: 'Sensors'
  }
];

export const breadcrumbRouteSensorDetails = (
  sensor?: SensorDetails
): BreadcrumbItem[] => [
  {
    title: 'Sensors',
    link: '/sensor/list'
  },
  {
    title: sensor ? `Sensor - ${sensor.sensor_id}` : 'Details'
  }
];
