import { SelectOption } from 'modules/Shared/type';
import { MediaKnowHowEntity } from 'modules/Media/model/MediaKnowHow';
import {
  MeasurementStatus,
  MeasurementType
} from 'modules/Shared/configs/measurementStatusDescEntity/type';

export type MediaEntity = {
  id?: number;
  title?: string;
  type?: string;
  url?: string;
  image?: string;
  advancement_level?: string;
  advancement_level_id?: number;
  knowHows?: MediaKnowHowEntity[];
  created_at?: string;
  updated_at?: string;
};

export default class Media {
  readonly id?: number;
  readonly title?: string;
  readonly type?: string;
  readonly url?: string;
  readonly image?: string;
  readonly advancement_level?: string;
  readonly advancement_level_id?: number;
  readonly knowHows?: MediaKnowHowEntity[];
  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(entity: MediaEntity) {
    this.id = entity.id;
    this.title = entity.title;
    this.type = entity.type;
    this.url = entity.url;
    this.image = entity.image;
    this.advancement_level = entity.advancement_level;
    this.advancement_level_id = entity.advancement_level_id;
    this.knowHows = entity.knowHows;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  static getFilterableAttributes(): string[] {
    return ['title'];
  }

  static getSortableAttributes(): string[] {
    return ['title'];
  }

  static getMediaType(): SelectOption[] {
    return [
      {
        value: 'video',
        label: 'video'
      },
      {
        value: 'article',
        label: 'article'
      }
    ];
  }

  getAttachedToAsKnowHow(
    attachedTo: 'plant' | 'plantGroup' | 'family',
    measurement: MeasurementType,
    status: MeasurementStatus
  ): MediaKnowHowEntity[] {
    const { knowHows } = this;

    if (!knowHows) {
      return [];
    }

    const filteredKnowHows = knowHows.filter((knowHow) => {
      if (attachedTo === 'plant') {
        return knowHow.plant_id !== null;
      }

      if (attachedTo === 'plantGroup') {
        return knowHow.plant_group_id !== null;
      }

      return knowHow.family_id !== null;
    });

    return filteredKnowHows.filter(
      (obj) =>
        obj.measurement === measurement && obj.measurement_status === status
    );
  }
}

export const createMedia = (entity: MediaEntity): Media => new Media(entity);

export const acceptableAdvancementLevelValues = ['basic', 'advanced'];

export const acceptableMediaTypesValues = ['article', 'video'];

export const getAdvancementLevelId = (advancement_level: string) => {
  switch (advancement_level) {
    case 'basic':
      return 1;
    case 'advanced':
      return 2;
    default:
      return null;
  }
};
