import React from 'react';
import { StandardBtn } from 'modules/Layout/view/Buttons/StandardBtn';

export interface IRowsDisplay {
  content: () => JSX.Element[] | JSX.Element;
  title: React.ReactNode;
  onEditClick?: () => void;
}

const DisplayOptionRow: React.FC<IRowsDisplay> = ({
  content,
  title,
  onEditClick,
  children
}) => {
  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body p-3">
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
            <div className="h3">{title}</div>
            {onEditClick && (
              <StandardBtn
                callback={onEditClick}
                text="Edit"
                classNames="ml-2"
              />
            )}
          </div>
          {children}
          <div className="tab-content mt-3">{content()}</div>
        </div>
      </div>
    </div>
  );
};

export default DisplayOptionRow;
