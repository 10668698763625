import { RootState } from 'app/reducer';
import { setLocationSearchAction } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_NEWS_LIST,
  fetchNewsListAction,
  SET_NEWS_LIST_PARAMS,
  newsListFetchedAction
} from 'modules/News/action/List';
import { fetchNews, FetchNewsListResponse } from 'modules/News/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';
import { AxiosResponse } from 'axios';

export function* setNewsListParamsSaga(): SagaIterator {
  yield put(fetchNewsListAction());
}

export function* fetchNewsListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.news.list
    );

    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response: AxiosResponse<FetchNewsListResponse> = yield call(
      fetchNews,
      {
        ...sort,
        pagination,
        search: filter
      }
    );

    const { content, totalCount } = response.data;
    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount?.[0]?.['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      newsListFetchedAction({
        news: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(newsListFetchedAction({ news: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantListSaga(): SagaIterator {
  yield takeLatest(SET_NEWS_LIST_PARAMS, setNewsListParamsSaga);
  yield takeLatest(FETCH_NEWS_LIST, fetchNewsListSaga);
}
