import MediaListView from 'modules/Media/view/List';
import MediaDetailsView from 'modules/Media/view/Details';
import MediaCreateUpdateView from 'modules/Media/view/CreateUpdate';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import MediaAdvancementListView from 'modules/Media/view/List/Advancement';
import MediaAdvancementCreateUpdateView from 'modules/Media/view/CreateUpdate/Advancement';
import MediaAdvancementDetailsView from 'modules/Media/view/Details/Advancement';

export const ROUTE_MEDIA = '/media/list';
export const ROUTE_MEDIA_ADD = '/media/add';
export const ROUTE_MEDIA_DETAILS = '/media/details/:id';
export const ROUTE_MEDIA_DETAILS_FORM = '/media/details/form/:id';
export const ROUTE_MEDIA_DETAILS_FORM_REDIRECT_TEMPLATE =
  '/media/details/form/';
export const ROUTE_MEDIA_ADVANCEMENT = '/media-advancement/list';
export const ROUTE_MEDIA_ADVANCEMENT_ADD = '/media-advancement/add';
export const ROUTE_MEDIA_ADVANCEMENT_DETAILS = '/media-advancement/:id(\\d+)';
export const ROUTE_MEDIA_ADVANCEMENT_EDIT = '/media-advancement/:id(\\d+)/edit';

export const createMediaRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="media-list"
      path={ROUTE_MEDIA}
      exact
      component={MediaListView}
    />,
    <Route
      key="media-details"
      path={ROUTE_MEDIA_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <MediaDetailsView {...props} key={props.match.params.id} />
      )}
    />,
    <Route
      key="media-add"
      path={ROUTE_MEDIA_ADD}
      exact
      component={MediaCreateUpdateView}
    />,
    <Route
      key="media-details-form"
      path={ROUTE_MEDIA_DETAILS_FORM}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <MediaCreateUpdateView
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={props.match.params.id}
          id={props.match.params.id}
        />
      )}
    />,
    <Route
      key="media-advancement"
      path={ROUTE_MEDIA_ADVANCEMENT}
      exact
      component={MediaAdvancementListView}
    />,
    <Route
      key="media-advancement-add"
      path={ROUTE_MEDIA_ADVANCEMENT_ADD}
      exact
      component={MediaAdvancementCreateUpdateView}
    />,
    <Route
      key="media-advancement-form"
      path={ROUTE_MEDIA_ADVANCEMENT_EDIT}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <MediaAdvancementCreateUpdateView
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={props.match.params.id}
          id={props.match.params.id}
        />
      )}
    />,
    <Route
      key="media-advancement-details"
      path={ROUTE_MEDIA_ADVANCEMENT_DETAILS}
      exact
      render={(props) => (
        <MediaAdvancementDetailsView
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={props.match.params.id}
          id={props.match.params.id}
        />
      )}
    />
  ]
});
