import { RootState } from 'app/reducer';
import { setLocationSearchAction, manageThrow } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_PLANT_LIST,
  fetchPlantListAction,
  SET_PLANT_LIST_PARAMS,
  plantListFetchedAction,
  fetchPlantImportsListAction,
  SET_PLANT_IMPORTS_LIST_PARAMS,
  FETCH_PLANT_IMPORTS_LIST,
  plantImportsListFetchedAction,
  SET_HELP_FIND_PLANT_LIST_PARAMS,
  FETCH_HELP_FIND_PLANT_LIST,
  fetchHelpFindPlantListAction,
  helpFindPlantListFetchedAction,
  helpFindPlantListSetRespondedAction,
  HELP_FIND_PLANT_LIST_RESPONDED,
  HelpFindPlantListRespondedAction
} from 'modules/Plants/action/list';
import {
  editHelpFindPlant,
  fetchHelpFindPlant,
  fetchPlantImports,
  fetchPlants,
  HelpFindPlantResponse
} from 'modules/Plants/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { AxiosResponse } from 'axios';

export function* setPlantListParamsSaga(): SagaIterator {
  yield put(fetchPlantListAction());
}

export function* fetchPlantListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.plant.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const mappedFilter = {
      ...filter
    };

    if (mappedFilter.is_deleted) {
      if (mappedFilter.is_deleted === '0') mappedFilter.is_deleted = false;
      if (mappedFilter.is_deleted === '1') mappedFilter.is_deleted = true;
    }

    if (mappedFilter.confirmed) {
      if (mappedFilter.confirmed === '0') mappedFilter.confirmed = false;
      if (mappedFilter.confirmed === '1') mappedFilter.confirmed = true;
    }

    const response = yield call(fetchPlants, {
      ...sort,
      pagination,
      search: mappedFilter
    });

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      plantListFetchedAction({
        plants: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(plantListFetchedAction({ plants: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export function* setPlantImportsListParamsSaga(): SagaIterator {
  yield put(fetchPlantImportsListAction());
}

export function* fetchPlantImportsListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.plant.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response = yield call(fetchPlantImports, {
      ...sort,
      pagination,
      search: filter
    });

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      plantImportsListFetchedAction({
        plantImports: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        plantImportsListFetchedAction({ plantImports: [], totalPages: 1 })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export function* setHelpFindPlantListParamsSaga(): SagaIterator {
  yield put(fetchHelpFindPlantListAction());
}

export function* fetchHelpFindPlantListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.plant.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const mappedFilter = {
      ...filter
    };

    if (filter.responded) {
      mappedFilter.responded = '0';
    }

    const response: AxiosResponse<HelpFindPlantResponse> = yield call(
      fetchHelpFindPlant,
      {
        ...sort,
        pagination,
        search: mappedFilter
      }
    );

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      helpFindPlantListFetchedAction({
        helpFindPlant: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        helpFindPlantListFetchedAction({ helpFindPlant: [], totalPages: 1 })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export function* helpFindPlantListRespondedSaga({
  payload
}: HelpFindPlantListRespondedAction): SagaIterator {
  try {
    const { plantId, responded } = payload;

    yield call(editHelpFindPlant, plantId, { responded });

    yield put(helpFindPlantListSetRespondedAction({ plantId, responded }));
  } catch (error) {
    console.log(error);
  }
}

export default function* plantListSaga(): SagaIterator {
  yield takeLatest(SET_PLANT_LIST_PARAMS, setPlantListParamsSaga);
  yield takeLatest(FETCH_PLANT_LIST, fetchPlantListSaga);
  yield takeLatest(
    SET_PLANT_IMPORTS_LIST_PARAMS,
    setPlantImportsListParamsSaga
  );
  yield takeLatest(FETCH_PLANT_IMPORTS_LIST, fetchPlantImportsListSaga);
  yield takeLatest(
    SET_HELP_FIND_PLANT_LIST_PARAMS,
    setHelpFindPlantListParamsSaga
  );
  yield takeLatest(FETCH_HELP_FIND_PLANT_LIST, fetchHelpFindPlantListSaga);
  yield takeLatest(
    HELP_FIND_PLANT_LIST_RESPONDED,
    helpFindPlantListRespondedSaga
  );
}
