import { PlantUserDetailsEntity } from 'modules/PlantUser/model/PlantUserDetails';

export type GardenDetailsEntity = {
  id?: number;
  name?: string;
  status?: number;
  user_id?: number;
  origin_path?: string;
  thumb_path?: string;
  created_at?: string;
  updated_at?: string;
  mac_address?: string;
  sensor_mesh_error?: string;
  sensor_data_received?: string;
  username?: string;
  email?: string;
  userPlants?: PlantUserDetailsEntity[];
};

export default class GardenDetails {
  readonly id?: number;
  readonly user_id?: number;
  readonly name?: string;
  readonly status?: number;
  readonly sensor_data_received?: string;
  readonly username?: string;
  readonly email?: string;
  readonly mac_address?: string;
  readonly sensor_mesh_error?: string;
  readonly origin_path?: string;
  readonly thumb_path?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly userPlants?: PlantUserDetailsEntity[];

  constructor(entity: GardenDetailsEntity) {
    this.id = entity.id;
    this.user_id = entity.user_id;
    this.name = entity.name;
    this.status = entity.status;
    this.sensor_data_received = entity.sensor_data_received;
    this.email = entity.email;
    this.username = entity.username;
    this.mac_address = entity.mac_address;
    this.sensor_mesh_error = entity.sensor_mesh_error;
    this.origin_path = entity.origin_path;
    this.thumb_path = entity.thumb_path;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.userPlants = entity.userPlants;
  }
}

export const createGardenDetails = (
  entity: GardenDetailsEntity
): GardenDetails => new GardenDetails(entity);

export const gardenStatus = (status: number) => {
  switch (status) {
    case 0:
      return 'Deleted';
    case 1:
      return 'Active';
    default:
      return null;
  }
};

export const gardenStatusIsDeleted = (status: number) => {
  switch (status) {
    case 0:
      return 'Yes';
    case 1:
      return 'No';
    default:
      return null;
  }
};
