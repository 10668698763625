import {
  FETCH_MISSING_FAMILY_LIST,
  SET_MISSING_FAMILY_LIST_PARAMS,
  MISSING_FAMILY_LIST_FETCHED,
  MissingFamilyListAction
} from 'modules/MissingFamily/action/List';
import { createMissingFamily } from 'modules/MissingFamily/model/MissingFamily';
import {
  initMissingFamilyListState,
  MissingFamilyListState
} from 'modules/MissingFamily/state/list';

const initState = initMissingFamilyListState();

const reducer = (
  state: MissingFamilyListState = initState,
  action: MissingFamilyListAction
): MissingFamilyListState => {
  switch (action.type) {
    case SET_MISSING_FAMILY_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_MISSING_FAMILY_LIST:
      return { ...state, fetching: true };

    case MISSING_FAMILY_LIST_FETCHED: {
      const { families = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        missingFamilies: families.map(createMissingFamily),
        totalPages: action.payload.totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
