import React, { useState } from 'react';
import ShowError from 'modules/Layout/component/ShowMessage';
import { Delete, CardImage } from 'modules/Layout/view/SVGs';

interface IProps {
  image: File;
  setImage: React.Dispatch<React.SetStateAction<File>>;
}

const ImageUploader: React.FC<IProps> = ({ image, setImage }) => {
  const [error, setError] = useState('');

  const deleteError = () => useState('');

  const uploadImage = (file: File) => {
    if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
      setError('Not an image');
    } else {
      if (error) setError(null);
      setImage(file);
    }
  };

  const content = () => {
    if (image)
      return (
        <div className="col-lg-12 pt-2 pb-2 position-relative border">
          <div className="position-absolute absolute-top-right rise-index">
            <button
              type="button"
              className="btn btn-light details-carousel-delete-btn details-form-icon-btn"
              onClick={() => setImage(null)}
            >
              <Delete />
            </button>
          </div>
          <div className="img-container w-100 position-relative">
            <img
              src={URL.createObjectURL(image)}
              alt="img"
              className="img-fluid mx-auto d-block center-position-absolute"
              style={{
                maxHeight: '400px'
              }}
            />
          </div>
        </div>
      );
    else
      return (
        <div className="d-flex justify-content-center align-items-center">
          <label htmlFor="upload" className="cursor-pointer mb-0">
            <CardImage width="30" height="30" />
          </label>
          <input
            type="file"
            id="upload"
            className="pl-1 pt-1 pb-1 cursor-pointer"
            onChange={(e) => uploadImage(e.target.files[0])}
          />
        </div>
      );
  };

  return (
    <div>
      <ShowError message={error} deleteMessage={deleteError} />
      <div className="buttons">{content()}</div>
    </div>
  );
};

export default ImageUploader;
