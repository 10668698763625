import { RootState } from 'app/reducer';
import { setLocationSearchAction, manageThrow } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import {
  dictionaryListFetchedAction,
  FETCH_DICTIONARY_LIST,
  fetchDictionaryListAction,
  SET_DICTIONARY_LIST_PARAMS
} from 'modules/Dictionary/action/list';
import {
  fetchDictionaries,
  FetchDictionariesResponse
} from 'modules/Dictionary/repository';

export function* setDictionaryListParamsSaga(): SagaIterator {
  yield put(fetchDictionaryListAction());
}

export function* fetchDictionaryListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.dictionary.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const paths = window.location.hash.split('/');
    const route = paths[1].split('?')[0];
    const table = route.replace(/-/g, '_');

    const mappedFilter = {
      ...filter
    };

    if (filter.is_deleted) {
      mappedFilter.is_deleted = true;
    }

    const response: FetchDictionariesResponse = yield call(
      fetchDictionaries,
      {
        ...sort,
        pagination,
        search: mappedFilter
      },
      table
    );

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      dictionaryListFetchedAction({
        dictionaries: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        dictionaryListFetchedAction({ dictionaries: [], totalPages: 1 })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* userListSaga(): SagaIterator {
  yield takeLatest(SET_DICTIONARY_LIST_PARAMS, setDictionaryListParamsSaga);
  yield takeLatest(FETCH_DICTIONARY_LIST, fetchDictionaryListSaga);
}
