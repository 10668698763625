import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { PlantEntity } from 'modules/Plants/model/Plant';
import {
  PlantDetailsEntity,
  LangsObject,
  ImagesObject,
  LangsEntity
} from 'modules/Plants/model/PlantDetails';
import { TresholdsEntity } from 'modules/Shared/configs/tresholdsEntity/type';
import { PlantGroupDetailsBaseEntity } from 'modules/PlantGroups/model/PlantGroupDetails';
import { MeasurementsStatusDescEntity } from 'modules/Shared/configs/measurementStatusDescEntity/type';
import { MeasurementsKnowHow } from 'modules/Shared/configs/measurementKnowHowEntity/type';
import { supportedLangsEnum } from 'modules/Shared/configs/supportedLang/config';
import { PlantImportEntity } from 'modules/Plants/model/PlantImport';
import { HelpFindPlantEntity } from 'modules/Plants/model/HelpFindPlant';
import { PlantSection } from 'modules/Plants/type';
import { PhResultsEntity } from 'modules/Plants/model/PhResults';

export const API_PLANTS_LIST = '/admin-plant/list';
export const API_PLANTS_LIST_ALL = '/admin-plant/list-all';
export const API_PLANTS = '/admin-plant';
export const API_PLANT_THRESHOLD = '/admin-plant/thresholds';
export const API_PLANT_DESCRIPTION = '/admin-plant/description';
export const API_PLANT_CONFIRM = '/admin-plant/confirm';
export const MANAGE_PLANT_GROUP = '/admin-plant/groups';
export const API_PLANT_IMPORTS_LIST = '/admin-plant/import-excel/list';
export const API_IMPORT_PLANT_EXCEL = '/admin-plant/import-excel';
export const API_PLANT_GOOD_TO_KNOW = '/admin-plant/good-to-knows';
export const API_HELP_FIND_PLANT = '/admin-plant-finder/support';
export const API_HELP_FIND_PLANT_DETAILS = '/admin-plant-finder/support/:id';
export const API_PLANT_EDIT_SECTIONS = '/admin-plant/sections/:id';
export const API_PLANT_EDIT_DESCRIPTIONS = '/admin-plant/descriptions/:id';
export const API_PLANT_KNOW_HOW = '/admin-plant/know-how/:id';
export const API_PH_RESULTS_LIST = '/admin-ph/list';
export const API_PH_RESULTS = '/admin-ph/:id';
export const API_PLANT_SYNONYMS = '/admin-plant/:id/synonyms';

export const MEASUREMENT_KNOW_HOW_ADD = (plantId: number) =>
  `${API_PLANTS}/${plantId}/know-how`;
export const MEASUREMENT_KNOW_HOW_REMOVE = (mediaId: number) =>
  `${API_PLANTS}/know-how/${mediaId}`;

export interface FetchPlantsResponse {
  content: PlantEntity[];
  totalCount: any;
}

export const fetchPlants = (
  params: SearchParams = {}
): AxiosPromise<FetchPlantsResponse> =>
  axios.post(getAPIUrl(API_PLANTS_LIST, null), params);

export interface FetchPlantsAllResponse {
  content: PlantEntity[];
}

export const fetchPlantsAll = (
  params: SearchParams = {}
): AxiosPromise<FetchPlantsAllResponse> =>
  axios.post(getAPIUrl(API_PLANTS_LIST_ALL, null), params);

export type FetchPlantImportsResponse = AxiosResponse<{
  content: PlantImportEntity[];
  totalCount: any;
}>;

export const fetchPlantImports = (
  params: SearchParams = {}
): AxiosPromise<FetchPlantImportsResponse> =>
  axios.post(getAPIUrl(API_PLANT_IMPORTS_LIST, null), params);

// add plants

export interface CreatePlantResponse {
  plant: {
    id: number | string;
  };
}

export const createNewPlant = (
  params: {
    scientific_name: string;
    plant_langs?: { [key in supportedLangsEnum]: { name: string } };
    family_id: number;
    image?: string;
  },
  cancelToken?: any
): AxiosPromise<CreatePlantResponse> =>
  axios.post(getAPIUrl(API_PLANTS), params, { cancelToken });

// details

export interface FetchPlantDetails {
  plant: PlantDetailsEntity;
  langs: LangsObject;
  thresholds: TresholdsEntity;
  images: ImagesObject[];
  plant_groups?: PlantGroupDetailsBaseEntity[];
  measurements_statuses_desc?: MeasurementsStatusDescEntity[];
  measurements_statuses_know_hows?: MeasurementsKnowHow[];
}

export type FetchPlantDetailsResponse = AxiosResponse<FetchPlantDetails>;

export const fetchPlantDetails = (
  id: number
): AxiosPromise<FetchPlantDetails> =>
  axios.get(getAPIUrl(`${API_PLANTS}/${id}`));

// connection with plant groups

export type AddPlantGroupResponse = AxiosResponse<{
  success: boolean;
  group: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  };
}>;

export const addPlantGroup = (
  plantId: number,
  groupId: number,
  cancelToken?: any
): AxiosPromise<AddPlantGroupResponse> =>
  axios.post(
    getAPIUrl(`${MANAGE_PLANT_GROUP}/${plantId}`),
    { groupId },
    { cancelToken }
  );

export const deletePlantKnowHow = (id: number | string): AxiosPromise =>
  axios.delete(getAPIUrl(API_PLANT_KNOW_HOW, { id }));

export const deletePlantGroup = (
  plantId: number,
  groupId: number,
  cancelToken?: any
): AxiosPromise<AxiosResponse<{}>> =>
  axios.delete(getAPIUrl(`${MANAGE_PLANT_GROUP}/${plantId}`), {
    data: {
      groupId
    },
    cancelToken
  });

export const updatePlantsDetails = (
  plantId: number,
  params: {
    scientific_name: string;
    family_id?: number;
    plant_langs?: {
      [type: string]: string;
    };
    plant_groups?: number[];
  },
  cancelToken?: any
) => axios.put(getAPIUrl(`${API_PLANTS}/${plantId}`), params, { cancelToken });

export const importPlantExcel = (file: string): AxiosPromise => {
  return axios.post(getAPIUrl(API_IMPORT_PLANT_EXCEL), { excel_file: file });
};

export const updatePlantThresholds = (
  plantId: number,
  params: TresholdsEntity,
  cancelToken?: any
) =>
  axios.put(getAPIUrl(`${API_PLANT_THRESHOLD}/${plantId}`), params, {
    cancelToken
  });

export type IPlantDescriptionParams = {
  plant_langs: {
    [type: string]: {
      description: string;
    };
  };
};

export const updatePlantDescription = (
  plantId: number,
  params: IPlantDescriptionParams,
  cancelToken?: any
) =>
  axios.put(getAPIUrl(`${API_PLANT_DESCRIPTION}/${plantId}`), params, {
    cancelToken
  });

export const updatePlantOtherDescription = (
  plantId: number,
  params: IPlantDescriptionParams,
  cancelToken?: any
) =>
  axios.put(getAPIUrl(`${API_PLANT_DESCRIPTION}s/${plantId}`), params, {
    cancelToken
  });

// edit measurements

export const updatePlantsMeasurementDesc = (
  plantId: number,
  params: { descriptions: MeasurementsStatusDescEntity[] },
  cancelToken?: any
): AxiosPromise<AxiosResponse<{}>> =>
  axios.put(
    getAPIUrl(`${API_PLANTS}/${plantId}/measurements-statuses-desc`),
    params,
    { cancelToken }
  );

// add image

export const addPlantImage = (
  params: { plant_id: number; image: unknown },
  cancelToken?: any
) => axios.post(getAPIUrl(`${API_PLANTS}/images`), params, { cancelToken });

export const deletePlantImage = (imageId: number, cancelToken?: any) =>
  axios.delete(getAPIUrl(`${API_PLANTS}/images/${imageId}`), { cancelToken });

// media

export interface AddMediaForPlantResponse {
  know_how: MeasurementsKnowHow;
}

export const addMediaForPlant = (
  plantId: number,
  params: {
    media_id: number;
    measurement_status: number;
    measurement: string;
  },
  cancelToken?: any
): AxiosPromise<AddMediaForPlantResponse> =>
  axios.post(getAPIUrl(MEASUREMENT_KNOW_HOW_ADD(plantId)), params, {
    cancelToken
  });

export const deleteMediaForPlant = (mediaId: number, cancelToken?: any) =>
  axios.delete(getAPIUrl(MEASUREMENT_KNOW_HOW_REMOVE(mediaId)), {
    cancelToken
  });

// confirm plant

export const confirmPlant = (plantId: number, cancelToken?: any) =>
  axios.post(
    getAPIUrl(`${API_PLANT_CONFIRM}/${plantId}`),
    {},
    {
      cancelToken
    }
  );

// delete

export const deletePlant = (plantId: number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(`${API_PLANTS}/${plantId}`), {
    cancelToken
  });

// good to know
export type AddGoodToKnowResponse = AxiosResponse<{
  success: boolean;
  good_to_know: {
    id: number;
    plant_id: number;
    lang: string;
    content: string;
  };
}>;

export const addGoodToKnow = (
  params: {
    plant_id: number;
    content: string;
    lang: string;
  },
  cancelToken?: any
): AxiosPromise<AddGoodToKnowResponse> =>
  axios.post(getAPIUrl(API_PLANT_GOOD_TO_KNOW), params, { cancelToken });

export const editGoodToKnow = (
  goodToKnowId: number,
  params: {
    content: string;
  },
  cancelToken?: any
): AxiosPromise<AxiosResponse<{}>> =>
  axios.put(getAPIUrl(`${API_PLANT_GOOD_TO_KNOW}/${goodToKnowId}`), params, {
    cancelToken
  });

export const deleteGoodToKnow = (
  goodToKnowId: number,
  cancelToken?: any
): AxiosPromise<AxiosResponse<{}>> =>
  axios.delete(getAPIUrl(`${API_PLANT_GOOD_TO_KNOW}/${goodToKnowId}`), {
    cancelToken
  });

export interface HelpFindPlantResponse {
  content: HelpFindPlantEntity[];
  totalCount: any;
}

export const fetchHelpFindPlant = (
  params: SearchParams = {}
): AxiosPromise<HelpFindPlantResponse> =>
  axios.post(getAPIUrl(API_HELP_FIND_PLANT), params);

export interface HelpFindPlantDetailsResponse {
  finder_help: HelpFindPlantEntity;
}

export const fetchHelpFindPlantDetails = (
  id: number | string
): AxiosPromise<HelpFindPlantDetailsResponse> =>
  axios.get(getAPIUrl(API_HELP_FIND_PLANT_DETAILS, { id }));

export const updatePlantDescriptions = (
  id: number | string,
  sections: PlantSection[],
  plant_langs: {
    [lang: string]: LangsEntity;
  }
): AxiosPromise =>
  axios.put(getAPIUrl(API_PLANT_EDIT_DESCRIPTIONS, { id }), {
    sections,
    plant_langs
  });

export interface PhResultsResponse {
  content: PhResultsEntity[];
}

export const fetchPhResults = (): AxiosPromise<PhResultsResponse> =>
  axios.post(getAPIUrl(API_PH_RESULTS_LIST));

export const updatePhResults = (
  id: number,
  lang: {
    lang: string;
    description: string;
    what_you_can_do: string;
    media_id: number;
  }[]
): AxiosPromise => axios.put(getAPIUrl(API_PH_RESULTS, { id }), { lang });

export const editPlantSynonyms = (
  id: number,
  synonyms: string[]
): AxiosPromise =>
  axios.post(getAPIUrl(API_PLANT_SYNONYMS, { id }), { synonyms });

export const editHelpFindPlant = (
  id: number,
  data: { responded: boolean }
): AxiosPromise =>
  axios.put(getAPIUrl(API_HELP_FIND_PLANT_DETAILS, { id }), data);
