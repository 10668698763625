import {
  FETCH_PLANT_GROUPS_LIST,
  SET_PLANT_GROUPS_LIST_PARAMS,
  PLANT_GROUPS_LIST_FETCHED,
  PlantGroupsListAction
} from 'modules/PlantGroups/action/list';
import { createPlantGroups } from 'modules/PlantGroups/model/PlantGroups';
import {
  initPlantGroupsListState,
  PlantGroupsListState
} from 'modules/PlantGroups/state/list';

const initState = initPlantGroupsListState();

const reducer = (
  state: PlantGroupsListState = initState,
  action: PlantGroupsListAction
): PlantGroupsListState => {
  switch (action.type) {
    case SET_PLANT_GROUPS_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_PLANT_GROUPS_LIST:
      return { ...state, fetching: true };

    case PLANT_GROUPS_LIST_FETCHED: {
      const { plantGroups = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        plantGroups: plantGroups.map(createPlantGroups),
        totalPages: action.payload.totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
