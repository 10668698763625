import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import Garden from 'modules/Garden/model/Garden';

export interface GardenListState extends SearchingProps {
  gardens: Garden[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initGardenListState = (): GardenListState => ({
  gardens: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
