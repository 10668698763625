export interface MissingFamilyRequest {
  id?: number;
  user_id?: number;
  plantnet_response?: string;
  user_email?: string;
  created_at?: string;
}

export type MissingFamilyEntity = {
  id?: number;
  scientific_name?: string;
  family_scientific_name?: string;
  requests?: number;
  created_at?: string;
};

export default class MissingFamily {
  readonly id?: number;
  readonly scientific_name?: string;
  readonly family_scientific_name?: string;
  readonly requests?: number;
  readonly created_at?: string;

  constructor(entity: MissingFamilyEntity) {
    this.id = entity.id;
    this.scientific_name = entity.scientific_name;
    this.family_scientific_name = entity.family_scientific_name;
    this.requests = entity.requests;
    this.created_at = entity.created_at;
  }

  static getFilterableAttributes(): string[] {
    return ['scientific_name', 'family_scientific_name'];
  }

  static getSortableAttributes(): string[] {
    return ['id', 'scientific_name', 'family_scientific_name', 'requests'];
  }
}

export const createMissingFamily = (
  entity: MissingFamilyEntity
): MissingFamily => new MissingFamily(entity);
