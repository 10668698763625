import { InputComponent } from 'modules/Layout/view/InputComponent';
import React from 'react';
import { Input } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';

interface IProps {
  objInfo: { [key: string]: string | number | string[] };
  value: string | number;
  mapped: string | number;
  onChange: (value: string | number, mapped: string | number) => void;
  loading?: boolean;
  type?: string;
  displayType?: string;
  required?: boolean;
}

const DisplayBaseInput: React.FC<IProps> = ({
  objInfo,
  value,
  mapped,
  type,
  onChange,
  loading,
  displayType = null,
  required = false
}) => {
  const displayInput = () => {
    if (type === 'select')
      return (
        <InputComponent
          inputConfig={{
            label: '',
            name: mapped as string,
            type: 'select'
          }}
          selectConfig={(objInfo.selectOptions as any[]) || []}
          value={(value as string) || ''}
          onChangeSelect={(value: string) => onChange(value, mapped)}
          className="row col-sm-10 col-md-8 col-lg-5 col-xl-3"
          required={required}
        />
      );
    else
      return (
        <Input
          id={`${objInfo.title}`}
          type={(type as InputType) || 'text'}
          className={`form-control${
            type === 'textarea' ? ' details-form-textarea' : ''
          }${
            type === 'number' && displayType !== 'list'
              ? ' details-form-number-input'
              : ''
          }`}
          step={type === 'number' ? '0.01' : ''}
          value={value}
          disabled={loading}
          onChange={(e) => onChange(e.target.value, mapped)}
          required={required}
        />
      );
  };

  return (
    <div
      className={`form-group row${
        displayType !== 'list' ? ' align-items-center' : ''
      }`}
    >
      <label
        className={`bv-no-focus-ring pt-1 ${
          displayType === 'list' ? 'col-lg-3' : 'col-lg-6'
        }`}
        htmlFor={`${objInfo.title}`}
      >
        {objInfo.title}
      </label>
      <div
        className={`bv-no-focus-ring ${
          displayType === 'list' ? 'col-lg-9' : 'col-lg-6'
        }`}
      >
        {displayInput()}
      </div>
    </div>
  );
};

export default DisplayBaseInput;
