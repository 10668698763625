import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import {
  PartialSearchingProps,
  PaginationParams,
  SearchParams
} from 'modules/Shared/type';
import {
  SetNewsListParamsAction,
  setNewsListParamsAction
} from 'modules/News/action/List';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FilterInputType } from 'modules/Layout/type';
import News from 'modules/News/model/News';

export interface StateProps {
  filter: SearchParams;
  pagination: PaginationParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetNewsListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  values?: SearchParams;
}

export const mapState = (state: RootState): StateProps => {
  const { filter, pagination } = state.news.list;

  return { filter, pagination };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setNewsListParamsAction(payload))
});

export class NewsFilter extends React.Component<Props> {
  protected staticInputs: FilterInputType[];

  constructor(props: Props) {
    super(props);

    const { values = {} } = props;

    this.staticInputs = [
      {
        type: 'text',
        property: 'title',
        label: 'Title',
        value: _get(values, 'title')
      },
      {
        type: 'select',
        property: 'type',
        label: 'Type',
        value: _get(values, 'type'),
        options: [
          {
            label: '',
            value: ''
          },
          ...News.getTypeOptions()
        ]
      }
    ];
  }

  render(): ReactNode {
    const { filter, pagination, setParams } = this.props;

    return (
      <ListingFilter
        inputs={this.staticInputs}
        filter={filter}
        pagination={pagination}
        setParams={setParams}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(NewsFilter);
