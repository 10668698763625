import listReducer from 'modules/MissingFamily/reducer/list';
import detailsReducer from 'modules/MissingFamily/reducer/details';
import { combineReducers, Reducer } from 'redux';
import { MissingFamilyState } from 'modules/MissingFamily/state';

const reducer = (): Reducer<MissingFamilyState> => {
  const reducers = {
    list: listReducer,
    details: detailsReducer
  };

  return combineReducers<MissingFamilyState>(reducers);
};

export default reducer;
