import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import {
  breadcrumbRouteDetailsFormDescriptionsPlant,
  breadcrumbRouteDetailsFormMainPlant,
  breadcrumbRouteDetailsFormMeasurementsDescriptionsPlant,
  breadcrumbRouteDetailsFormThresholdsPlant
} from 'modules/Plants/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { setPlantDetailsParamsAction } from 'modules/Plants/action/Details';
import { RootState } from 'app/reducer';
import {
  acceptableTypesArray,
  accetableOptionsForDescriptionArray,
  fillArrayOfFieldDesc,
  PLANT_DETAILS_DESCRIPTION,
  PLANT_DETAILS_MAIN,
  PLANT_DETAILS_MEASUREMENT_DESC,
  PLANT_DETAILS_THRESHOLD
} from 'modules/Plants/view/DetailsForm/helper/acceptableTypes';
import DetailsForm, { IOnSubmitAction } from 'modules/Layout/view/DetailsForm';
import { ROUTE_PLANTS } from 'modules/Plants/routes';
import {
  supportedLangs,
  supportedLangsEnum
} from 'modules/Shared/configs/supportedLang/config';
import {
  handleChangeRequest,
  handlePlantErrorResponse
} from 'modules/Plants/view/DetailsForm/helper/handleChangeRequest';
import Loader from 'modules/Layout/component/Loader';
import DisplayEditFormMeasurement from 'modules/Plants/view/DetailsForm/components/DisplayEditFormMeasurement';
import { measurementTypes } from 'modules/Shared/configs/measurementStatusDescEntity/type';
import NotEditableWarning from 'modules/Plants/view/DetailsForm/helper/NotEditableWarning';
import { createHistoryPath } from 'modules/Plants/view/DetailsForm/helper/setHistoryPath';
import useCancelToken from 'modules/Shared/helper/useCancelToken';
import EditPlantForm from 'modules/Plants/component/Form';
import { BreadcrumbItem } from 'modules/Layout/type';

interface IParams {
  id: string;
  type: string;
  lang?: string;
  option?: string;
}

interface IProps {
  data: { [key: string]: string | boolean | number };
  serverEndpoint: string;
  title: string;
  stateTypeChanged?: boolean;
}

export const FamilyDetailsForm: React.FC<IProps> = ({}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [notEditableError, setNotEditableError] = useState('');
  const dispatch = useDispatch();
  const params = useParams<IParams>();
  const history = useHistory();
  const details = useSelector((state: RootState) => state.plant.details);
  const { type } = useSelector((state: RootState) => state.layout.page);

  useEffect(() => {
    let title = '';
    let breadcrumb: BreadcrumbItem[] = [];

    if (params?.type === 'main') {
      title = `${
        details?.plantDetails
          ? `${details.plantDetails.scientific_name} - edit`
          : 'Plant - edit'
      } `;
      breadcrumb = breadcrumbRouteDetailsFormMainPlant(details?.plantDetails);
    } else if (params?.type === 'thresholds') {
      title = `${
        details?.plantDetails
          ? `${details.plantDetails.scientific_name} - edit thresholds`
          : 'Plant - edit thresholds'
      } `;
      breadcrumb = breadcrumbRouteDetailsFormThresholdsPlant(
        details?.plantDetails
      );
    } else if (params?.type === 'description') {
      title = `${
        details?.plantDetails
          ? `${details.plantDetails.scientific_name} - edit description(${params?.lang})`
          : `Plant - edit description(${params?.lang})`
      } `;
      breadcrumb = breadcrumbRouteDetailsFormDescriptionsPlant(
        details?.plantDetails,
        params?.lang
      );
    } else if (params?.type === 'measurement_desc') {
      title = `${
        details?.plantDetails
          ? `${details.plantDetails.scientific_name} - edit ${params?.option} descriptions(${params?.lang})`
          : `Plant - edit ${params?.option} descriptions(${params?.lang})`
      } `;
      breadcrumb = breadcrumbRouteDetailsFormMeasurementsDescriptionsPlant(
        details?.plantDetails,
        params?.option,
        params?.lang
      );
    }

    dispatch(
      managePageAction({
        title,
        breadcrumb
      })
    );
  }, [details?.plantDetails, dispatch, params?.type]);

  useEffect(() => {
    if (error) setError('');
    if (notEditableError) setNotEditableError('');
    if (params?.type && params?.id) {
      const id = parseInt(params.id, 10);
      if (!id) {
        setNotEditableError('id');
      } else if (!acceptableTypesArray.includes(params.type)) {
        setNotEditableError('type');
      } else if (id !== details?.plantId) {
        dispatch(setPlantDetailsParamsAction(id));
      }
    }
    if (params?.lang && !supportedLangs.includes(params.lang)) {
      setNotEditableError('language');
    }
    if (params?.option) {
      if (
        !(
          measurementTypes.includes(params.option) ||
          accetableOptionsForDescriptionArray.includes(params.option)
        )
      ) {
        setNotEditableError('option');
      }
    }
  }, [params]);

  const historyPath = () =>
    createHistoryPath(params?.type, params?.id, history, params?.option);

  const cancelToken = useCancelToken();
  const onSubmitBtnClick = async (data: IOnSubmitAction) => {
    const standardError =
      "Sorry, seems like web panel doesn't support current version of api. Contact with administrator to resolve this problem.";
    const id = parseInt(params?.id, 10) || null;

    if (!loading) {
      if (!data?.editType || !id) {
        setError(standardError);
      } else {
        setLoading(true);
        try {
          const response = await handleChangeRequest(
            {
              data,
              details,
              id: parseInt(params?.id, 10) || null
            },
            cancelToken
          );
          if (response.status >= 200 && response.status < 300) {
            historyPath();
          } else {
            setLoading(false);
          }
        } catch (err) {
          console.log(err);
          if (err?.message !== 'AxiosCancel') {
            setLoading(false);
            if (err?.message === 'internal error') setError(standardError);
            else
              setError(
                handlePlantErrorResponse(
                  err?.error?.response?.data?.statusCode,
                  err?.error?.response?.data?.errors,
                  err?.message
                )
              );
          }
        }
      }
    }
  };

  const deleteError = () => setError('');

  let data = {};
  if (params?.type === PLANT_DETAILS_DESCRIPTION) {
    data = details.plantDetails?.langs?.[params.lang as supportedLangsEnum];
  } else if (params?.type === PLANT_DETAILS_THRESHOLD) {
    data = details.plantDetails?.thresholds;
  }

  if (type) return null;

  if (details.fetching || !details.plantDetails)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );

  if (notEditableError) {
    return (
      <NotEditableWarning
        warningType={notEditableError}
        goBackBtnCallback={() => history.push(ROUTE_PLANTS)}
      />
    );
  }

  if (params?.type === PLANT_DETAILS_MAIN) {
    return (
      <>
        <EditPlantForm plant={details.plantDetails} />
      </>
    );
  }

  if (params?.type === PLANT_DETAILS_MEASUREMENT_DESC) {
    return (
      <>
        <DisplayEditFormMeasurement
          data={details.plantDetails?.measurements_statuses_desc}
          onSubmitAction={onSubmitBtnClick}
          onCancelAction={historyPath}
          error={error}
          deleteError={deleteError}
          type={params?.type}
          lang={params?.lang}
          option={params?.option}
          loading={loading}
        />
      </>
    );
  }

  return (
    <>
      {data !== undefined && (
        <DetailsForm
          data={data}
          arrayOfFieldDesc={fillArrayOfFieldDesc(
            params?.type,
            details.plantDetails,
            params?.lang,
            params?.option
          )}
          onSubmitAction={onSubmitBtnClick}
          onCancelAction={historyPath}
          error={error}
          deleteError={deleteError}
          editType={params?.type}
          editLang={params?.lang}
          editOption={params?.option}
          loading={loading}
        />
      )}
    </>
  );
};

export default FamilyDetailsForm;
