export interface FeedbackEntity {
  id?: number;
  user_id?: number;
  email?: string;
  description?: string;
  responded?: number;
  imageBase64?: string;
  created_at?: string;
}

export default class Feedback {
  id?: number;
  user_id?: number;
  email?: string;
  description?: string;
  responded?: number;
  imageBase64?: string;
  created_at?: string;

  constructor(feedback?: FeedbackEntity) {
    this.id = feedback?.id;
    this.user_id = feedback?.user_id;
    this.email = feedback?.email;
    this.description = feedback?.description;
    this.responded = feedback?.responded;
    this.imageBase64 = feedback?.imageBase64;
    this.created_at = feedback?.created_at;
  }

  static getFilterableAttributes(): string[] {
    return ['email', 'responded'];
  }

  static getSortableAttributes(): string[] {
    return ['email', 'responded'];
  }
}

export const createFeedback = (feedback?: FeedbackEntity): Feedback =>
  new Feedback(feedback);
