import React, { Component, FormEvent, ReactNode } from 'react';
import {
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  FormFeedback
} from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { breadcrumbRouteCreateAdministrator } from 'modules/User/breadcrumbs';
import { createAdmin } from 'modules/User/repository';
import { withRouter } from 'react-router-dom';
import { ConnectedRouterProps } from 'connected-react-router';
import { ROUTE_ADMINISTRATOR_DETAILS } from 'modules/User/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ValidationErrors } from 'modules/Shared/type';
import ApiError from 'modules/Shared/exception/ApiError';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface State {
  creating: boolean;
  email: string;
  password: string;
  error?: ValidationErrors;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
}

export type Props = DispatchProps & ConnectedRouterProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload))
});

class CreateAdministratorView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      creating: false,
      email: '',
      password: '',
      error: null
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(): void {
    const { managePage } = this.props;

    managePage({
      title: 'Add administrator',
      breadcrumb: breadcrumbRouteCreateAdministrator()
    });
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const { history } = this.props;
    const { email, password } = this.state;

    try {
      this.setState({
        creating: true
      });

      const {
        data: { user }
      } = await createAdmin({ email, password });

      history.push(getPathUrl(ROUTE_ADMINISTRATOR_DETAILS, { id: user.id }));
    } catch (error) {
      console.log(error);
      if (error instanceof ApiError) {
        this.setState({
          creating: false,
          error: error.getErrors()
        });
      }
    }
  }

  render(): ReactNode {
    const { creating, email, password, error } = this.state;

    return (
      <div className="d-flex justify-content-center">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body p-4">
              {creating && <Loader />}
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <Label>Email*:</Label>
                  <Input
                    type="email"
                    required
                    value={email}
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                    invalid={hasError(error, 'email')}
                  />
                  {hasError(error, 'email') && (
                    <FormFeedback>{getError(error, 'email')}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>Password*:</Label>
                  <Input
                    type="password"
                    required
                    pattern={`/^(?=.*[a-z])(?=.*[0-9])(?=.*[@#\\$_&-+()/~\`!%^*={}[]|:;"'<>,.?€])(?=.{6,20})/`}
                    title={`"Minimum length 6, maximum length 20, must contain at least one number and lowercase letter, and one special character @#$_&-+()/\\~\`!%^*={}[]|:;"'<>,.?€"`}
                    value={password}
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup className="mt-3 d-flex justify-content-between">
                  <Button type="button" color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="secondary">
                    Create
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(CreateAdministratorView)
);
