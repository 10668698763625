import React, { Component, ReactNode } from 'react';
import BaseModal from 'modules/Layout/component/Modal';
import InformationList from 'modules/Layout/component/InformationList';
import { Button } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Dictionary from 'modules/Dictionary/model/Dictionary';
import { deleteDictionary } from 'modules/Dictionary/repository';
import {
  deleteDictionaryToastError,
  deleteDictionaryToastSuccess
} from 'modules/Dictionary/toasts';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onSuccess?: () => void;
  onError?: () => void;
  table: string;
}

export interface State {
  dictionary: Dictionary;
  open: boolean;
  deleting: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class DeleteDictionaryModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dictionary: null,
      open: false,
      deleting: false
    };

    this.renderBody = this.renderBody.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.delete = this.delete.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  open(dictionary: Dictionary): void {
    this.setState({
      open: true,
      dictionary,
      deleting: false
    });
  }

  close(): void {
    this.setState({
      open: false
    });
  }

  renderBody(): ReactNode {
    const { dictionary, deleting } = this.state;

    const fields = [
      {
        label: 'Name:',
        value: dictionary.name
      }
    ];

    return (
      <div>
        {deleting && <Loader />}
        <InformationList fields={fields} />
      </div>
    );
  }

  renderFooter(): ReactNode {
    const { deleting } = this.state;

    return (
      <div className="d-flex w-100 justify-content-between">
        <Button
          color="primary"
          disabled={deleting}
          onClick={this.close}
          className="mr-2"
        >
          Cancel
        </Button>
        <Button color="danger" disabled={deleting} onClick={this.delete}>
          Delete
        </Button>
      </div>
    );
  }

  async delete(): Promise<void> {
    const { onSuccess, onError, addToast, table } = this.props;
    const { dictionary } = this.state;

    try {
      this.setState({
        deleting: true
      });

      await deleteDictionary(dictionary.id, table);

      if (onSuccess) {
        onSuccess();
      }

      addToast(deleteDictionaryToastSuccess());
    } catch (error) {
      if (onError) {
        onError();
      }

      addToast(deleteDictionaryToastError());
    } finally {
      this.close();
    }
  }

  render(): ReactNode {
    const { open, dictionary } = this.state;

    if (!dictionary) {
      return null;
    }

    return (
      <BaseModal
        isOpen={open}
        body={this.renderBody()}
        footer={this.renderFooter()}
        title="Do you want to delete dictionary?"
        toggle={this.close}
        onClosed={this.close}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(DeleteDictionaryModal);
