import {
  DELETE_FAMILY_KNOW_HOWS,
  FAMILY_DETAILS_DELETED,
  FAMILY_DETAILS_FETCHED,
  FamilyDetailsAction,
  FETCH_FAMILY_DETAILS,
  SET_FAMILY_DETAILS_PARAMS,
  SET_FAMILY_KNOW_HOWS
} from 'modules/Family/action/Details';
import { createFamilyDetails } from 'modules/Family/model/FamilyDetails';
import {
  FamilyDetailsState,
  initFamilyDetailsState
} from 'modules/Family/state/details';

const initState = initFamilyDetailsState();

const reducer = (
  state: FamilyDetailsState = initState,
  action: FamilyDetailsAction
): FamilyDetailsState => {
  switch (action.type) {
    case SET_FAMILY_DETAILS_PARAMS:
      return { ...state, familyId: action.payload };

    case FETCH_FAMILY_DETAILS:
      return { ...state, fetching: true };

    case FAMILY_DETAILS_FETCHED: {
      const { familyDetails = null, measurementsStatusesKnowHows } =
        action.payload;

      return {
        ...state,
        fetching: false,
        familyDetails: createFamilyDetails(familyDetails),
        measurementsStatusesKnowHows
      };
    }

    case FAMILY_DETAILS_DELETED: {
      const { familyDetails } = state;
      familyDetails.is_deleted = true;

      return { ...state, familyDetails };
    }

    case SET_FAMILY_KNOW_HOWS: {
      const { measurementsStatusesKnowHows } = action.payload;

      return { ...state, measurementsStatusesKnowHows };
    }

    case DELETE_FAMILY_KNOW_HOWS: {
      const { id } = action.payload;

      return {
        ...state,
        measurementsStatusesKnowHows: state.measurementsStatusesKnowHows.filter(
          (obj) => Number(obj.id) !== Number(id)
        )
      };
    }

    default:
      return state;
  }
};

export default reducer;
