import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const importPlantExcelToastSuccess = (): AddToastPayload => ({
  header: 'Import plant excel',
  body: 'Importing plant excel started.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const importPlantExcelToastError = (): AddToastPayload => ({
  header: 'Import plant excel',
  body: 'Importing plant excel failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const createPlantToastSuccess = (): AddToastPayload => ({
  header: 'CreateUpdate plant',
  body: 'Plant created.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createPlantToastError = (): AddToastPayload => ({
  header: 'CreateUpdate plant',
  body: 'Creating plant failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deletePlantToastSuccess = (): AddToastPayload => ({
  header: 'Delete plant',
  body: 'Plant deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deletePlantToastError = (): AddToastPayload => ({
  header: 'Delete plant',
  body: 'Deleting plant failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const editPlantToastSuccess = (): AddToastPayload => ({
  header: 'Edit plant',
  body: 'Plant updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const editPlantToastError = (): AddToastPayload => ({
  header: 'Edit plant',
  body: 'Plant updating failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const addMeasurementKnowHowToastSuccess = (): AddToastPayload => ({
  header: 'Measurement know-how',
  body: 'Measurement know-how added.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const addMeasurementKnowHowToastError = (): AddToastPayload => ({
  header: 'Measurement know-how',
  body: 'Measurement know-how was not added.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deleteMeasurementKnowHowToastSuccess = (): AddToastPayload => ({
  header: 'Measurement know-how',
  body: 'Measurement know-how was deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteMeasurementKnowHowToastError = (): AddToastPayload => ({
  header: 'Measurement know-how',
  body: 'Measurement know-how was not deleted.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const addPlantImageToastSuccess = (): AddToastPayload => ({
  header: 'Plant image',
  body: 'Plant image added.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const addPlantImageToastError = (): AddToastPayload => ({
  header: 'Plant image',
  body: 'Plant image adding failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const editPhResultsToastSuccess = (): AddToastPayload => ({
  header: 'pH results',
  body: 'ph Results updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const editPhResultsToastError = (): AddToastPayload => ({
  header: 'pH results',
  body: 'ph Results not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const editSynonymsToastSuccess = (): AddToastPayload => ({
  header: 'Plant synonyms',
  body: 'Plant synonyms updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const editSynonymsToastError = (): AddToastPayload => ({
  header: 'Plant synonyms',
  body: 'Plant synonyms not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
