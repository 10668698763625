import {
  FETCH_HUB_LIST,
  SET_HUB_LIST_PARAMS,
  HUB_LIST_FETCHED,
  HubListAction
} from 'modules/Hub/action/list';
import { createHub } from 'modules/Hub/model/Hub';
import { initHubListState, HubListState } from 'modules/Hub/state/list';

const initState = initHubListState();

const reducer = (
  state: HubListState = initState,
  action: HubListAction
): HubListState => {
  switch (action.type) {
    case SET_HUB_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_HUB_LIST:
      return { ...state, fetching: true };

    case HUB_LIST_FETCHED: {
      const { hubs = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        hubs: hubs.map(createHub),
        totalPages: action.payload.totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
