import {
  FETCH_HELP_FIND_PLANT_LIST,
  FETCH_PLANT_IMPORTS_LIST,
  FETCH_PLANT_LIST,
  HELP_FIND_PLANT_LIST_FETCHED,
  HELP_FIND_PLANT_LIST_SET_RESPONDED,
  PLANT_IMPORTS_LIST_FETCHED,
  PLANT_LIST_FETCHED,
  PlantListAction,
  SET_HELP_FIND_PLANT_LIST_PARAMS,
  SET_PLANT_IMPORTS_LIST_PARAMS,
  SET_PLANT_LIST_PARAMS
} from 'modules/Plants/action/list';
import { createPlant } from 'modules/Plants/model/Plant';
import { initPlantListState, PlantListState } from 'modules/Plants/state/list';
import { createPlantImport } from 'modules/Plants/model/PlantImport';
import { createHelpFindPlant } from 'modules/Plants/model/HelpFindPlant';

const initState = initPlantListState();

const reducer = (
  state: PlantListState = initState,
  action: PlantListAction
): PlantListState => {
  switch (action.type) {
    case SET_PLANT_IMPORTS_LIST_PARAMS:
    case SET_PLANT_LIST_PARAMS:
    case SET_HELP_FIND_PLANT_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_PLANT_IMPORTS_LIST:
    case FETCH_PLANT_LIST:
    case FETCH_HELP_FIND_PLANT_LIST:
      return { ...state, fetching: true };

    case PLANT_LIST_FETCHED: {
      const { plants = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        plants: plants.map(createPlant),
        totalPages
      };
    }

    case PLANT_IMPORTS_LIST_FETCHED: {
      const { plantImports = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        plantImports: plantImports.map(createPlantImport),
        totalPages
      };
    }

    case HELP_FIND_PLANT_LIST_FETCHED: {
      const { helpFindPlant = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        helpFindPlant: helpFindPlant.map(createHelpFindPlant),
        totalPages
      };
    }

    case HELP_FIND_PLANT_LIST_SET_RESPONDED: {
      const { plantId, responded } = action.payload;

      return {
        ...state,
        helpFindPlant: state.helpFindPlant.map((obj) => {
          if (obj.id === plantId) {
            return {
              ...obj,
              responded
            };
          }

          return obj;
        })
      };
    }

    default:
      return state;
  }
};

export default reducer;
