import React from 'react';
import { Routes } from 'app/routes';
import { Route } from 'react-router-dom';
import FaqList from 'modules/Faq/view/List';
import FaqUpdateView from 'modules/Faq/view/Update';

export const ROUTE_FAQ_LIST = '/faq';
export const ROUTE_FAQ_UPDATE = '/faq/:id/update';

export const createFaqRoutes = (): Routes => ({
  wrapped: [
    <Route key="faq-list" path={ROUTE_FAQ_LIST} exact component={FaqList} />,
    <Route
      key="faq-update"
      path={ROUTE_FAQ_UPDATE}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FaqUpdateView {...props} id={props.match.params.id} />
      )}
    />
  ]
});
