export interface HealthCheckHelpEntity {
  id?: number;
  user_id?: number;
  email?: string;
  description?: string;
  responded?: number;
  images?: string[];
  created_at?: string;
}

export default class HealthCheckHelp {
  id?: number;
  user_id?: number;
  email?: string;
  description?: string;
  responded?: number;
  images?: string[];
  created_at?: string;

  constructor(healthCheckHelp?: HealthCheckHelpEntity) {
    this.id = healthCheckHelp?.id;
    this.user_id = healthCheckHelp?.user_id;
    this.email = healthCheckHelp?.email;
    this.description = healthCheckHelp?.description;
    this.responded = healthCheckHelp?.responded;
    this.images = healthCheckHelp?.images;
    this.created_at = healthCheckHelp?.created_at;
  }

  static getFilterableAttributes(): string[] {
    return ['email', 'responded'];
  }

  static getSortableAttributes(): string[] {
    return ['email', 'responded'];
  }
}

export const createHealthCheckHelp = (
  healthCheckHelp?: HealthCheckHelpEntity
): HealthCheckHelp => new HealthCheckHelp(healthCheckHelp);
