import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deletePlantGroupToastSuccess = (): AddToastPayload => ({
  header: 'Delete plant group',
  body: 'Plant group was deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deletePlantGroupToastError = (): AddToastPayload => ({
  header: 'Delete plant group',
  body: 'Plant groups was not deleted.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const editPlantGroupToastSuccess = (): AddToastPayload => ({
  header: 'Edit plant group',
  body: 'Plant group updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const editPlantGroupToastError = (): AddToastPayload => ({
  header: 'Edit plant group',
  body: 'Plant groups deleted.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const addPlantToGroupToastSuccess = (): AddToastPayload => ({
  header: 'Plant group',
  body: 'Plant added to group.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const addPlantToGroupToastError = (): AddToastPayload => ({
  header: 'Plant group',
  body: 'Plant not added to group.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const removePlantFromGroupToastSuccess = (): AddToastPayload => ({
  header: 'Plant group',
  body: 'Plant removed from group.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const removePlantFromGroupToastError = (): AddToastPayload => ({
  header: 'Plant group',
  body: 'Plant removed from group.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const bulkUpdatePlantGroupsToastSuccess = (): AddToastPayload => ({
  header: 'Plant groups',
  body: 'Plant groups updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const bulkUpdatePlantGroupsToastError = (): AddToastPayload => ({
  header: 'Plant groups',
  body: 'Plant groups were not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
