import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'modules/Layout/view/Carousel';
import { Delete, BoxArrow } from 'modules/Layout/view/SVGs';

import { IconBtn } from '../../Buttons/IconBtn';

type IObjArr = {
  title: string;
  description: string | number;
  type?: string;
  to?: string;
};

export interface IDiplayPrimaryInfo {
  objArr: IObjArr[];
  loader?: boolean;
  imagesURL: { id: number; url: string }[];
  onDeleteImageClick?: (id: number) => void;
}

const DiplayPrimaryInfo: React.FC<IDiplayPrimaryInfo> = ({
  objArr,
  onDeleteImageClick,
  imagesURL,
  loader
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const displayContent = (info: IObjArr) => {
    if (info.type === 'link' && info.to) {
      return (
        <Link to={info.to} className="link-with-svg">
          <div className="d-inline-flex flex-wrap align-items-center">
            {info.description}
            <div className="p-1 d-flex align-items-center justify-content-center">
              <BoxArrow width="16" height="16" />
            </div>
          </div>
        </Link>
      );
    }
    if (info.type === 'outerLink' && info.to) {
      return (
        <a href={info.to} className="link-with-svg">
          <div className="d-inline-flex flex-wrap align-items-center">
            {info.description}
            <div className="p-1 d-flex align-items-center justify-content-center">
              <BoxArrow width="16" height="16" />
            </div>
          </div>
        </a>
      );
    }
    return info.description;
  };

  return (
    <div className="mb-1 flex-grow-1 w-100">
      <div className="row">
        <div className="col-lg-8 mt-1">
          {objArr.map((info) => {
            return (
              <div className="form-group" key={info.title}>
                <div className="d-flex flex-wrap">
                  <div className="col-sm-3 bv-no-focus-ring col-form-label font-weight-bold">
                    {info.title}
                  </div>
                  <div className="bv-no-focus-ring col">
                    <div className="form-control bg-white border-none">
                      {displayContent(info)}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-lg-4 bg-grey pt-2 pb-2 position-relative border">
          {imagesURL.length > 0 && onDeleteImageClick && (
            <div className="position-absolute absolute-top-right rise-index">
              <IconBtn
                callback={() =>
                  onDeleteImageClick(imagesURL?.[activeIndex]?.id || null)
                }
                loading={loader}
                icon={<Delete />}
                classNames="btn-light details-carousel-delete-btn"
              />
            </div>
          )}

          <Carousel
            urlImageArr={imagesURL.map((image) => image.url)}
            parentControlIndex={{ activeIndex, setActiveIndex }}
          />
        </div>
      </div>
    </div>
  );
};

export default DiplayPrimaryInfo;
