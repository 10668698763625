import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRoutePlantGroups } from 'modules/PlantGroups/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import PlantTable from 'modules/PlantGroups/container/Table';
import PlantFilter from 'modules/PlantGroups/container/Filter';
import PlantPagination from 'modules/PlantGroups/container/Pagination';
import PlantPerPage from 'modules/PlantGroups/container/PerPage';
import PlantGroups from 'modules/PlantGroups/model/PlantGroups';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setPlantGroupsListParamsAction,
  SetPlantGroupsListParamsAction
} from 'modules/PlantGroups/action/list';
import { RootState } from 'app/reducer';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANT_GROUP_BULK_UPDATE } from 'modules/PlantGroups/routes';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (payload: PartialSearchingProps) => SetPlantGroupsListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPlantGroupsListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.plantGroups.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class PlantGroupsListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      PlantGroups.getFilterableAttributes(),
      PlantGroups.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Plant groups - list',
      breadcrumb: breadcrumbRoutePlantGroups()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { fetching, type } = this.props;
    const { filter } = this.searchProps;

    if (type) return null;

    return (
      <div className="row plant-groups-view">
        <div className="col-12">
          <Listing
            table={<PlantTable />}
            filter={<PlantFilter values={filter} />}
            pagination={<PlantPagination />}
            perPage={<PlantPerPage />}
            addBtn={{ text: 'Add group', url: '/plant-groups/add' }}
            buttons={[
              <Link
                key={0}
                className="btn btn-secondary ml-2"
                to={getPathUrl(ROUTE_PLANT_GROUP_BULK_UPDATE)}
              >
                Update multiple plant groups
              </Link>
            ]}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantGroupsListView);
