import { GardenDetailsEntity } from 'modules/Garden/model/GardenDetails';
import { HubDetailsEntity } from 'modules/Hub/model/HubDetails';
import { SensorDetailsEntity } from 'modules/Sensor/model/SensorDetails';
import { UserEntity } from 'modules/User/model/User';

export type PlantUserDetailsMeasurementsEntity = {
  [key: string]: {
    status: number;
    values: {
      min: number;
      max: number;
      current: number;
    };
    unit: string;
  };
};

export type PlantUserDetailsEntity = {
  id?: number;
  nickname?: string;
  status?: number;
  plant_id?: number;
  family_id?: number;
  genus?: string;
  scientific_name?: string;
  origin_path?: string;
  thumb_path?: string;
  received_data_at?: string;
  created_at?: string;
  updated_at?: string;
  garden?: GardenDetailsEntity;
  sensor?: SensorDetailsEntity;
  hub?: HubDetailsEntity;
  user?: UserEntity;
  temperature_status?: number;
  light_status?: number;
  moisture_status?: number;
  salinity_status?: number;
  measurements?: PlantUserDetailsMeasurementsEntity;
};

export default class PlantUserDetails {
  readonly id?: number;
  readonly nickname?: string;
  readonly status?: number;
  readonly plant_id?: number;
  readonly family_id?: number;
  readonly genus?: string;
  readonly scientific_name?: string;
  readonly origin_path?: string;
  readonly thumb_path?: string;
  readonly received_data_at?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly garden?: GardenDetailsEntity;
  readonly sensor?: SensorDetailsEntity;
  readonly hub?: HubDetailsEntity;
  readonly user?: UserEntity;
  readonly temperature_status?: number;
  readonly light_status?: number;
  readonly moisture_status?: number;
  readonly salinity_status?: number;
  readonly measurements?: PlantUserDetailsMeasurementsEntity;

  constructor(entity: PlantUserDetailsEntity) {
    this.id = entity.id;
    this.nickname = entity.nickname;
    this.status = entity.status;
    this.plant_id = entity.plant_id;
    this.family_id = entity.family_id;
    this.genus = entity.genus;
    this.scientific_name = entity.scientific_name;
    this.origin_path = entity.origin_path;
    this.thumb_path = entity.thumb_path;
    this.received_data_at = entity.received_data_at;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.garden = entity.garden;
    this.sensor = entity.sensor;
    this.hub = entity.hub;
    this.user = entity.user;
    this.temperature_status = entity.temperature_status;
    this.light_status = entity.light_status;
    this.moisture_status = entity.moisture_status;
    this.salinity_status = entity.salinity_status;
    this.measurements = entity.measurements;
  }
}

export const createPlantUserDetails = (
  entity: PlantUserDetails
): PlantUserDetails => new PlantUserDetails(entity);

export const plantUserStatus = (status: number) => {
  switch (status) {
    case 0:
      return 'Plant is deleted.';
    case 1:
      return 'All measurements are perfect.';
    case 2:
      return "At least one measurement isn't perfect.";
    case 3:
      return "This plant doesn't have connected sensor.";
    default:
      return null;
  }
};
