import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteDetailsFamily } from 'modules/Family/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import {
  familyDetailsDeletedAction,
  setFamilyDetailsParamsAction
} from 'modules/Family/action/Details';
import { RootState } from 'app/reducer';
import DetailsMain from 'modules/Family/view/Details/components/DisplayDetailsMain';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import Loader from 'modules/Layout/component/Loader';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { DetailsPlantList } from 'modules/Family/view/Details/components/DisplayPlantList';
import { ROUTE_FAMILY } from 'modules/Family/routes';
import DeleteFamilyModal from 'modules/Family/component/Modal/Delete';
import MeasurementsKnowHow from '../../../Plants/component/MeasurementsKnowHow';

interface IParams {
  id: string;
}

export const FamilyDetailsView = () => {
  const [deleteMessage, setDeleteMessage] = useState({ text: '', color: '' });
  const [deleteBtnLoading, setDErrDeleteBtnLoading] = useState(false);
  const params = useParams<IParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector((state: RootState) => state.family.details);
  const measurementsKnowHows = useSelector(
    (state: RootState) => state.family.details.measurementsStatusesKnowHows
  );
  const { type } = useSelector((state: RootState) => state.layout.page);
  const deleteModalRef = useRef(null);

  const onDeleteSuccess = async () => {
    dispatch(familyDetailsDeletedAction());
    setDeleteMessage({
      text: 'Family successfully deleted.',
      color: 'success'
    });
    setDErrDeleteBtnLoading(false);
  };

  const navElements = [
    'Main data',
    'Connected plants',
    'Measurements know-how'
  ];
  const [nav, setNav] = useState(navElements.map((_, index) => index === 0));

  useEffect(() => {
    dispatch(
      managePageAction({
        title: details?.familyDetails ? details.familyDetails.genus : 'Family',
        breadcrumb: breadcrumbRouteDetailsFamily(details?.familyDetails)
      })
    );
  }, [details.familyDetails, dispatch]);

  useEffect(() => {
    if (params?.id) {
      const id = parseInt(params.id, 10);
      if (id) dispatch(setFamilyDetailsParamsAction(id));
      else history.push(ROUTE_FAMILY);
    }
  }, [dispatch, history, params.id]);

  if (type) return null;

  if (details.fetching)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );

  return (
    <div className="position-relative">
      <DeleteFamilyModal ref={deleteModalRef} onSuccess={onDeleteSuccess} />
      <div className="details-top-nav mb-3">
        <DisplayNav
          valueArr={navElements}
          booleanArr={nav}
          setBooleanArr={setNav}
        />
      </div>

      {nav[0] &&
        details.familyDetails &&
        details.familyDetails.id === details.familyId && (
          <DetailsMain
            supportedLang={supportedLangs}
            data={details.familyDetails}
            onDelete={() =>
              deleteModalRef?.current?.openModal(details.familyDetails)
            }
            onDeleteMessage={deleteMessage}
            onDeleteBtnLoading={deleteBtnLoading}
            removeDeleteMessage={() =>
              setDeleteMessage({ text: '', color: '' })
            }
          />
        )}
      {nav[1] && <DetailsPlantList />}
      {nav[2] && (
        <MeasurementsKnowHow
          family={details.familyDetails}
          measurementsKnowHow={measurementsKnowHows}
        />
      )}
    </div>
  );
};

export default FamilyDetailsView;
