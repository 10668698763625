import React, { ReactNode } from 'react';
import HelpFindPlant from 'modules/Plants/model/HelpFindPlant';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';
import ActionDelete from '../../../../Layout/component/Action/Delete';
import ActionUpdate from '../../../../Layout/component/Action/Update';

export interface Props {
  helpFindPlant: HelpFindPlant;
  onSetResponded: (responded: boolean) => Promise<void>;
}

export interface State {
  activeTabs: boolean[];
}

class HelpFindPlantDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTabs: [true, false]
    };

    this.renderNav = this.renderNav.bind(this);
    this.renderMainData = this.renderMainData.bind(this);
  }

  renderNav(): ReactNode {
    const { helpFindPlant } = this.props;
    const { activeTabs } = this.state;

    const navElements = ['Main data'];

    if (helpFindPlant.plantnet_response) {
      navElements.push('Raw plant net response');
    }

    return (
      <div className="details-top-nav mb-3">
        <DisplayNav
          booleanArr={activeTabs}
          setBooleanArr={(newActiveTabs: boolean[]) =>
            this.setState({ activeTabs: newActiveTabs })
          }
          valueArr={navElements}
        />
      </div>
    );
  }

  renderMainData(): ReactNode {
    const { helpFindPlant, onSetResponded } = this.props;

    const fields = [
      {
        title: 'User email:',
        description: helpFindPlant.email,
        to: getPathUrl(ROUTE_USER_DETAILS, { id: helpFindPlant.user_id }),
        type: 'link'
      },
      {
        title: 'Responded:',
        reactNode: (
          <div>
            {helpFindPlant.responded ? 'Yes' : 'No'}
            {helpFindPlant.responded ? (
              <ActionDelete
                label={<i className="fas font-22 fa-times ml-2" />}
                title="Set to not responded"
                onClick={() => onSetResponded(false)}
              />
            ) : (
              <ActionUpdate
                label={<i className="fas font-20 fa-check ml-2" />}
                title="Set to responded"
                onClick={() => onSetResponded(true)}
              />
            )}
          </div>
        )
      },
      {
        title: 'Sent at:',
        description: helpFindPlant.created_at
      },
      {
        title: 'Description:',
        description: helpFindPlant.description
      }
    ];

    return (
      <div className="card">
        <div className="card-body p-3">
          <div className="d-flex">
            <div className="d-flex flex-column mr-5">
              <div className="mb-2 font-weight-bold font-20">
                Image sent by user:
              </div>
              <img
                style={{ maxWidth: 400 }}
                alt="sent_image"
                src={helpFindPlant.image}
              />
            </div>
            <div className="flex-grow-1">{displayOptionRowContent(fields)}</div>
          </div>
        </div>
      </div>
    );
  }

  renderRawPlantNetResponse(): ReactNode {
    const { helpFindPlant } = this.props;

    return (
      <div className="card">
        <div className="card-body p-3">
          <pre>
            <code>
              {JSON.stringify(helpFindPlant.plantnet_response, null, 2)}
            </code>
          </pre>
        </div>
      </div>
    );
  }

  render(): ReactNode {
    const { activeTabs } = this.state;

    return (
      <div>
        {this.renderNav()}
        {activeTabs[0] && this.renderMainData()}
        {activeTabs[1] && this.renderRawPlantNetResponse()}
      </div>
    );
  }
}

export default HelpFindPlantDetails;
