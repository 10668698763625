import React, { FormEvent, ReactNode } from 'react';
import {
  Input,
  Label,
  Form,
  Button,
  FormGroup,
  FormFeedback
} from 'reactstrap';
import { PageProps, PageType } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Validation from 'modules/Shared/exception/Validation';
import { AxiosResponse } from 'axios';
import Loader from 'modules/Layout/component/Loader';
import { ValidationPayload } from 'modules/Shared/type';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { getPathUrl } from 'modules/Shared/helper/api';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import {
  addMediaAdvancement,
  AddMediaAdvancementResponse,
  fetchMediaAdvancementDetails,
  FetchMediaAdvancementDetailsResponse,
  updateMediaAdvancement
} from 'modules/Media/repository';
import {
  breadcrumbRouteCreateMediaAdvancement,
  breadcrumbRouteEditMediaAdvancement
} from 'modules/Media/breadcrumbs';
import {
  createMediaAdvancementToastError,
  createMediaAdvancementToastSuccess,
  updateMediaAdvancementToastError,
  updateMediaAdvancementToastSuccess
} from 'modules/Media/toasts';
import {
  ROUTE_MEDIA_ADVANCEMENT,
  ROUTE_MEDIA_ADVANCEMENT_DETAILS
} from 'modules/Media/routes';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';

export interface StateProps {
  pageType: PageType;
}

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrowSaga: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps, RouteComponentProps {
  id?: string | number;
}

export interface State {
  name: string;
  langs: {
    [lang: string]: {
      text: string;
    };
  };
  langTab: boolean[];
  fetching: boolean;
  errors?: ValidationPayload;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload)),
  manageThrowSaga: (error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return {
    pageType: type
  };
};

class CreateUpdateMediaAdvancementView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const langs: {
      [lang: string]: {
        text: string;
      };
    } = {};

    supportedLangs.forEach((lang) => {
      langs[lang] = {
        text: ''
      };
    });

    this.state = {
      name: '',
      langs,
      langTab: supportedLangs.map((_, index) => index === 0),
      fetching: false,
      errors: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.fetchAdvancement = this.fetchAdvancement.bind(this);
    this.onLangChange = this.onLangChange.bind(this);
  }

  async fetchAdvancement(): Promise<string> {
    const { id, manageThrowSaga } = this.props;
    const { langs } = this.state;

    try {
      const {
        data: { mediaAdvancement }
      }: AxiosResponse<FetchMediaAdvancementDetailsResponse> = await fetchMediaAdvancementDetails(
        Number(id)
      );

      const state = {
        name: mediaAdvancement.name,
        langs
      };

      mediaAdvancement.langs.forEach((lang) => {
        state.langs[lang.lang] = {
          text: lang.text
        };
      });

      this.setState(state);

      return mediaAdvancement.name;
    } catch (error) {
      manageThrowSaga(error);
      throw error;
    }
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id } = this.props;

    if (id) {
      managePage({
        title: 'Media advancement - edit',
        type: 'loading',
        breadcrumb: breadcrumbRouteEditMediaAdvancement(id)
      });
    } else {
      managePage({
        title: 'Media advancement - create',
        type: 'loading',
        breadcrumb: breadcrumbRouteCreateMediaAdvancement()
      });
    }

    if (id) {
      try {
        const title = await this.fetchAdvancement();

        managePage({
          title: `${title} - edit`,
          breadcrumb: breadcrumbRouteEditMediaAdvancement(id, title)
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      managePage({
        title: 'Media advancement - create',
        breadcrumb: breadcrumbRouteCreateMediaAdvancement()
      });
    }
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { history, addToast, id } = this.props;
    const { name, langs } = this.state;

    this.setState({
      fetching: true,
      errors: null
    });

    let redirectId = id;

    const payload = {
      name,
      langs
    };

    try {
      if (id) {
        await updateMediaAdvancement(id, payload);

        addToast(updateMediaAdvancementToastSuccess());
      } else {
        const {
          data: {
            media_advancement_level: { id: newId }
          }
        }: AxiosResponse<AddMediaAdvancementResponse> = await addMediaAdvancement(
          payload
        );

        redirectId = newId;

        addToast(createMediaAdvancementToastSuccess());
      }

      history.push(
        getPathUrl(ROUTE_MEDIA_ADVANCEMENT_DETAILS, { id: redirectId })
      );
    } catch (error) {
      if (id) {
        addToast(updateMediaAdvancementToastError());
      } else {
        addToast(createMediaAdvancementToastError());
      }

      if (error instanceof Validation) {
        this.setState({
          fetching: false,
          errors: error.getPayload()
        });
      } else {
        this.setState({
          fetching: false
        });
      }
    }
  }

  onLangChange(text: string, lang: string): void {
    this.setState((state) => ({
      langs: {
        ...state.langs,
        [lang]: {
          text
        }
      }
    }));
  }

  render(): ReactNode {
    const { history, id, pageType } = this.props;
    const { name, langs, fetching, errors, langTab } = this.state;

    if (pageType) {
      return null;
    }

    const activeIndex = langTab.findIndex((bool) => bool);
    const activeLang = supportedLangs[activeIndex];

    return (
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              {fetching && <Loader />}
              <Form onSubmit={this.onSubmit}>
                {errors && (
                  <ShowMessage
                    message={errors.message.value}
                    bsColor={errors.message.variant}
                  />
                )}
                <FormGroup>
                  <Label className="w-100">
                    Name*:
                    <Input
                      type="text"
                      value={name || ''}
                      required
                      onChange={(event) =>
                        this.setState({
                          name: event.currentTarget.value
                        })
                      }
                      invalid={errors && hasError(errors.errors, 'name')}
                    />
                    {errors && hasError(errors.errors, 'name') && (
                      <FormFeedback>
                        {getError(errors.errors, 'name')}
                      </FormFeedback>
                    )}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <DisplayNav
                    booleanArr={langTab}
                    setBooleanArr={(newTabs: boolean[]) =>
                      this.setState({ langTab: newTabs })
                    }
                    valueArr={supportedLangs}
                  />
                  {supportedLangs.map((lang) => {
                    return (
                      <div
                        key={lang}
                        className={activeLang === lang ? '' : 'd-none'}
                      >
                        <Label className="mt-2 w-100">
                          Text:
                          <Input
                            type="text"
                            value={langs[lang].text || ''}
                            onChange={(event) =>
                              this.onLangChange(event.currentTarget.value, lang)
                            }
                          />
                        </Label>
                      </div>
                    );
                  })}
                </FormGroup>
                <div className="mt-4 d-flex justify-content-between">
                  <Button
                    type="button"
                    onClick={() =>
                      history.push(
                        id
                          ? getPathUrl(ROUTE_MEDIA_ADVANCEMENT_DETAILS, { id })
                          : getPathUrl(ROUTE_MEDIA_ADVANCEMENT)
                      )
                    }
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="success">
                    {id ? 'Update' : 'Create'}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(
    mapState,
    mapDispatch
  )(CreateUpdateMediaAdvancementView)
);
