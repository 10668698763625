import { RootState } from 'app/reducer';
import {
  SET_MISSING_FAMILY_DETAILS_PARAMS,
  FETCH_MISSING_FAMILY_DETAILS,
  fetchMissingFamilyDetailsAction,
  missingFamilyDetailsFetchedAction
} from 'modules/MissingFamily/action/Details';
import {
  fetchMissingFamilyDetails,
  FetchMissingFamilyDetailsResponse
} from 'modules/MissingFamily/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';

export function* setMissingFamilyDetailsParamsSaga(): SagaIterator {
  yield put(fetchMissingFamilyDetailsAction());
}

export function* fetchMissingFamilyDetailsSaga(): SagaIterator {
  try {
    const { missingFamilyId } = yield select(
      (state: RootState) => state.missingFamily.details
    );

    const response: FetchMissingFamilyDetailsResponse = yield call(
      fetchMissingFamilyDetails,
      missingFamilyId
    );

    const { missingFamily } = response.data;

    yield put(
      missingFamilyDetailsFetchedAction({
        missingFamilyDetails: missingFamily
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        missingFamilyDetailsFetchedAction({ missingFamilyDetails: null })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantDetailsSaga(): SagaIterator {
  yield takeLatest(
    SET_MISSING_FAMILY_DETAILS_PARAMS,
    setMissingFamilyDetailsParamsSaga
  );
  yield takeLatest(FETCH_MISSING_FAMILY_DETAILS, fetchMissingFamilyDetailsSaga);
}
