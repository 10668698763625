import React, { useRef } from 'react';
import { primaryInfoArr } from 'modules/MissingFamily/view/Details/helper/DetailsConfig';
import MissingFamilyDetails from 'modules/MissingFamily/model/MissingFamilyDetails';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import { Button } from 'reactstrap';
import PlantnetResponseModal from 'modules/MissingFamily/component/Modal/PlantnetResponse';

interface IProps {
  data: MissingFamilyDetails;
}

const Details: React.FC<IProps> = ({
  data
}: {
  data: MissingFamilyDetails;
}) => {
  const modalRef = useRef<PlantnetResponseModal>(null);

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Missing family">
        <div className="tab-content mt-3 row">
          {displayOptionRowContent(primaryInfoArr(data), false, 'col-lg-6')}
        </div>
      </DisplayBaseTabContent>
      <PlantnetResponseModal ref={modalRef} />
      <DisplayBaseTabContent title="Requests">
        <div className="tab-content mt-3">
          {data?.requests.map((request) => (
            <div key={request.id} className="mt-2 w-100">
              <Button
                color="primary"
                onClick={() => {
                  modalRef?.current?.openModal(request);
                }}
                className="w-100"
              >
                {`${request.user_email} - ${request.created_at}`}
              </Button>
            </div>
          ))}
        </div>
      </DisplayBaseTabContent>
    </div>
  );
};

export default Details;
