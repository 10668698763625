import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import News, { NewsEntity } from 'modules/News/model/News';
import { PlantEntity } from 'modules/Plants/model/Plant';

export const API_NEWS = '/admin-news';
export const API_NEWS_LIST = '/admin-news/list';
export const API_NEWS_DETAILS = '/admin-news/:id';
export const API_NEWS_ORDER_LIST = '/admin-news/ordered/list';
export const API_UPDATE_NEWS_ORDER = '/admin-news/reorder/list';
export const API_NEWS_DETAILS_REDIRECT_TEMPLATE = '/admin-news/';
export const API_PLANTS_NEWS_ORDER_LIST = '/admin-news/plant-ordered/list';
export const API_PLANTS_UPDATE_NEWS_ORDER = '/admin-news/plant-reorder/list';

// list

export interface FetchNewsListResponse {
  content: NewsEntity[];
  totalCount: any;
}

export const fetchNews = (
  params: SearchParams = {}
): AxiosPromise<FetchNewsListResponse> =>
  axios.post(getAPIUrl(API_NEWS_LIST), params);

export type FetchNewsRequest = {
  title: string;
  description: string;
  type: string;
  url: string;
  image: any;
};

// add

export interface FetchAddNewsResponse {
  success: boolean;
  news: NewsEntity;
}

export const fetchAddNews = (
  params: FetchNewsRequest,
  cancelToken?: any
): AxiosPromise<FetchAddNewsResponse> =>
  axios.post(getAPIUrl(API_NEWS), params, { cancelToken });

// details

export interface FetchNewsDetailsResponse {
  news: NewsEntity;
}

export const fetchNewsDetails = (
  id: number,
  cancelToken?: any
): AxiosPromise<FetchNewsDetailsResponse> =>
  axios.get(getAPIUrl(API_NEWS_DETAILS, { id }), { cancelToken });

// delete

export type DeleteNewsDetailsResponse = AxiosResponse<{
  statusCode: number;
}>;

export const deleteNewsDetails = (
  id: number,
  cancelToken?: any
): AxiosPromise<DeleteNewsDetailsResponse> =>
  axios.delete(getAPIUrl(`${API_NEWS}/${id}`), { cancelToken });

// edit

export type UpdateNewsDetailsResponse = AxiosResponse<{
  news: NewsEntity;
}>;

export const updateNewsDetails = (
  id: number | string,
  params: FetchNewsRequest,
  cancelToken?: any
): AxiosPromise<UpdateNewsDetailsResponse> =>
  axios.put(getAPIUrl(API_NEWS_DETAILS, { id }), params, { cancelToken });

export interface FetchOrderedNewsResponse {
  content: News[];
}

export const fetchOrderedNews = (): AxiosPromise<FetchOrderedNewsResponse> =>
  axios.get(getAPIUrl(API_NEWS_ORDER_LIST));

export const updateNewsOrder = (ordered_news_id: number[]): AxiosPromise =>
  axios.post(getAPIUrl(API_UPDATE_NEWS_ORDER), { ordered_news_id });

export interface FetchOrderedPlantNewsResponse {
  plants: PlantEntity[];
}

export const fetchOrderedPlantNews =
  (): AxiosPromise<FetchOrderedPlantNewsResponse> =>
    axios.get(getAPIUrl(API_PLANTS_NEWS_ORDER_LIST));

export const updatePlantNewsOrder = (
  ordered_plants_id: number[]
): AxiosPromise =>
  axios.post(getAPIUrl(API_PLANTS_UPDATE_NEWS_ORDER), { ordered_plants_id });
