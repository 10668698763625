import React, { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import HealthCheckHelp, {
  createHealthCheckHelp
} from 'modules/PlantDiseases/model/HealthCheckHelp';
import { breadcrumbRouteHealthCheckHelp } from 'modules/PlantDiseases/breadcrumbs';
import {
  fetchHealthCheckHelp,
  updateHealthCheckHelp
} from 'modules/PlantDiseases/repository';
import HealthCheckHelpDetails from 'modules/PlantDiseases/component/details/HealthCheckHelp';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps {
  id: number;
}

export interface State {
  healthCheckHelp: HealthCheckHelp;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class HealthCheckHelpDetailsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      healthCheckHelp: null
    };

    this.onSetResponded = this.onSetResponded.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow } = this.props;

    managePage({
      title: 'Health check help',
      breadcrumb: breadcrumbRouteHealthCheckHelp(),
      type: 'loading'
    });

    try {
      const {
        data: { healthCheckHelp }
      } = await fetchHealthCheckHelp(id);

      const healthCheck = createHealthCheckHelp(healthCheckHelp);

      this.setState({
        healthCheckHelp: healthCheck
      });

      managePage({
        title: healthCheck.email,
        breadcrumb: breadcrumbRouteHealthCheckHelp(healthCheck),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  async onSetResponded(responded: boolean): Promise<void> {
    const { healthCheckHelp } = this.state;

    try {
      await updateHealthCheckHelp(healthCheckHelp.id, { responded });
      this.setState({
        healthCheckHelp: {
          ...healthCheckHelp,
          responded: Number(responded)
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  render(): ReactNode {
    const { type } = this.props;
    const { healthCheckHelp } = this.state;

    if (type || !healthCheckHelp) {
      return null;
    }

    return (
      <HealthCheckHelpDetails
        onSetResponded={this.onSetResponded}
        healthCheckHelp={healthCheckHelp}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HealthCheckHelpDetailsView);
