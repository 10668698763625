import listReducer from 'modules/Hub/reducer/list';
import listHubSoftwareReducer from 'modules/Hub/reducer/hubSoftwareList';
import { combineReducers, Reducer } from 'redux';
import { HubState } from 'modules/Hub/state';

const reducer = (): Reducer<HubState> => {
  const reducers = {
    list: listReducer,
    hubSoftwareList: listHubSoftwareReducer
  };

  return combineReducers<HubState>(reducers);
};

export default reducer;
