import React, { useEffect, useState } from 'react';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { breadcrumbRouteFaqList } from 'modules/Faq/view/breadcrumbs';
import Loader from 'modules/Layout/component/Loader';
import { fetchFaqList } from 'modules/Faq/repository';
import { createFaq, Faq } from 'modules/Faq/model/FAQ';
import FaqListItem from 'modules/Faq/component/Faq/List/Item';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
}

export type Props = DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload))
});

const useFaqList = (props: Props) => {
  const { managePage } = props;
  const [fetching, setFetching] = useState(false);
  const [faqs, setFaqs] = useState<Faq[]>([]);

  const fetchFaq = async () => {
    try {
      setFetching(true);
      const {
        data: { faq }
      } = await fetchFaqList();
      setFaqs(faq.map(createFaq));
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    managePage({
      title: 'FAQ',
      breadcrumb: breadcrumbRouteFaqList()
    });

    fetchFaq();
  }, [managePage]);

  return {
    fetching,
    faqs
  };
};

const FaqList = (props: Props): JSX.Element => {
  const { fetching, faqs } = useFaqList(props);

  if (fetching) {
    return <Loader />;
  }

  return (
    <div className="row justify-content-center faq-list-view">
      {faqs.map((faq) => (
        <div key={faq.id} className="col-12 col-lg-6">
          <FaqListItem faq={faq} />
        </div>
      ))}
    </div>
  );
};

export default connect<null, DispatchProps>(null, mapDispatch)(FaqList);
