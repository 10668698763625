import PlantDetails, {
  PlantDetailsEntity
} from 'modules/Plants/model/PlantDetails';
import { Action } from 'redux';

export const SET_PLANT_DETAILS_PARAMS = 'PLANT/DETAILS/PARAMS/SET';
export const FETCH_PLANT_DETAILS = 'PLANT/DETAILS/FETCH';
export const PLANT_DETAILS_FETCHED = 'PLANT/DETAILS/FETCHED';
export const PLANT_DETAILS_DELETED = 'PLANT/DETAILS/DELETED';
export const PLANT_DETAILS_UPDATE = 'PLANT/DETAILS/UPDATE';

export type PlantDetailsAction =
  | SetPlantDetailsParamsAction
  | FetchPlantDetailsAction
  | PlantDetailsFetchedAction
  | PlantDetailsDeletedAction
  | PlantDetailsUpdateAction;

export type SetPlantDetailsParamsAction = {
  type: typeof SET_PLANT_DETAILS_PARAMS;
  payload: number;
};

export const setPlantDetailsParamsAction = (
  payload: number
): SetPlantDetailsParamsAction => {
  return { type: SET_PLANT_DETAILS_PARAMS, payload };
};

export type FetchPlantDetailsAction = Action<typeof FETCH_PLANT_DETAILS>;

export const fetchPlantDetailsAction = (): FetchPlantDetailsAction => ({
  type: FETCH_PLANT_DETAILS
});

export type PlantDetailsFetchedPayload = {
  plantDetails: PlantDetailsEntity;
};

export interface PlantDetailsFetchedAction
  extends Action<typeof PLANT_DETAILS_FETCHED> {
  payload: PlantDetailsFetchedPayload;
}

export const plantDetailsFetchedAction = (
  payload: PlantDetailsFetchedPayload
): PlantDetailsFetchedAction => ({
  type: PLANT_DETAILS_FETCHED,
  payload
});

export type PlantDetailsDeletedAction = {
  type: typeof PLANT_DETAILS_DELETED;
};

export const plantDetailsDeletedAction = (): PlantDetailsDeletedAction => ({
  type: PLANT_DETAILS_DELETED
});

export interface PlantDetailsUpdateAction
  extends Action<typeof PLANT_DETAILS_UPDATE> {
  payload: PlantDetails;
}

export const plantDetailsUpdatedAction = (
  payload: PlantDetails
): PlantDetailsUpdateAction => ({
  type: PLANT_DETAILS_UPDATE,
  payload
});
