import authSaga from 'modules/Auth/saga';
import sharedSaga from 'modules/Shared/saga';
import { Action } from 'redux';
import { Saga, SagaIterator } from 'redux-saga';
import { fork, select, takeEvery } from 'redux-saga/effects';
import layoutSaga from 'modules/Layout/saga';
import userSaga from 'modules/User/saga';
import hubSaga from 'modules/Hub/saga';
import sensorSaga from 'modules/Sensor/saga';
import plantSaga from 'modules/Plants/saga';
import familySaga from 'modules/Family/saga';
import plantGroupsSaga from 'modules/PlantGroups/saga';
import mediaGroupsSaga from 'modules/Media/saga';
import plantUserSaga from 'modules/PlantUser/saga';
import dictionarySaga from 'modules/Dictionary/saga';
import gardenSaga from 'modules/Garden/saga';
import newsSaga from 'modules/News/saga';
import policySaga from 'modules/Policy/saga';
import missingFamilySaga from 'modules/MissingFamily/saga';
import plantDiseasesSaga from 'modules/PlantDiseases/saga';

export function* loggerSaga(action: Action): SagaIterator {
  const state = yield select();

  console.log('action', action);
  console.log('state', state);
}

const createRootSaga = (): Saga => {
  return function* rootSaga(): SagaIterator {
    if (process.env.SAGA_LOGGER === 'true') {
      yield takeEvery('*', loggerSaga);
    }

    yield fork(sharedSaga);
    yield fork(authSaga);
    yield fork(layoutSaga);
    yield fork(userSaga);
    yield fork(hubSaga);
    yield fork(sensorSaga);
    yield fork(plantSaga);
    yield fork(familySaga);
    yield fork(plantGroupsSaga);
    yield fork(mediaGroupsSaga);
    yield fork(plantUserSaga);
    yield fork(gardenSaga);
    yield fork(newsSaga);
    yield fork(dictionarySaga);
    yield fork(missingFamilySaga);
    yield fork(policySaga);
    yield fork(plantDiseasesSaga);
  };
};

export default createRootSaga;
