import {
  FETCH_MEDIA_DETAILS,
  MEDIA_DETAILS_FETCHED,
  SET_MEDIA_DETAILS_PARAMS,
  MediaDetailsAction
  //MEDIA_DETAILS_DELETED
} from 'modules/Media/action/Details';
import { createMedia } from 'modules/Media/model/Media';
import {
  initMediaDetailsState,
  MediaDetailsState
} from 'modules/Media/state/details';

const initState = initMediaDetailsState();

const reducer = (
  state: MediaDetailsState = initState,
  action: MediaDetailsAction
): MediaDetailsState => {
  switch (action.type) {
    case SET_MEDIA_DETAILS_PARAMS:
      return { ...state, mediaId: action.payload };

    case FETCH_MEDIA_DETAILS:
      return { ...state, fetching: true };

    case MEDIA_DETAILS_FETCHED: {
      const { mediaDetails = null } = action.payload;

      return {
        ...state,
        fetching: false,
        mediaDetails: createMedia(mediaDetails)
      };
    }

    default:
      return state;
  }
};

export default reducer;
