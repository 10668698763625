import { BreadcrumbItem } from 'modules/Layout/type';
import Media from 'modules/Media/model/Media';
import { getPathUrl } from 'modules/Shared/helper/api';
import {
  ROUTE_MEDIA_ADVANCEMENT,
  ROUTE_MEDIA_ADVANCEMENT_DETAILS
} from 'modules/Media/routes';

export const breadcrumbRouteMediaAdvancement = (): BreadcrumbItem[] => [
  {
    title: 'Media advancements',
    link: getPathUrl(ROUTE_MEDIA_ADVANCEMENT)
  }
];

export const breadcrumbRouteCreateMediaAdvancement = (): BreadcrumbItem[] => [
  ...breadcrumbRouteMediaAdvancement(),
  {
    title: 'Create'
  }
];

export const breadcrumbRouteMediaAdvancementDetails = (
  id?: number | string,
  title?: string
): BreadcrumbItem[] => [
  ...breadcrumbRouteMediaAdvancement(),
  {
    title: title || 'Details',
    link: id ? getPathUrl(ROUTE_MEDIA_ADVANCEMENT_DETAILS, { id }) : null
  }
];

export const breadcrumbRouteEditMediaAdvancement = (
  id?: number | string,
  title?: string
): BreadcrumbItem[] => [
  ...breadcrumbRouteMediaAdvancementDetails(id, title),
  {
    title: 'Edit'
  }
];

export const breadcrumbRouteFormDetailsMediaAdvancement =
  (): BreadcrumbItem[] => [
    ...breadcrumbRouteMediaAdvancement(),
    {
      title: 'Edit'
    }
  ];

export const breadcrumbRouteMedia = (): BreadcrumbItem[] => [
  {
    title: 'Media'
  }
];

export const breadcrumbRouteAddMedia = (): BreadcrumbItem[] => [
  {
    title: 'Media',
    link: '/media/list'
  },
  {
    title: 'Create'
  }
];

export const breadcrumbRouteDetailsMedia = (
  media?: Media
): BreadcrumbItem[] => [
  {
    title: 'Media',
    link: '/media/list'
  },
  {
    title: media ? media.title : 'Details'
  }
];

export const breadcrumbRouteFormDetailsMedia = (
  id: string | number,
  title?: string
): BreadcrumbItem[] => [
  {
    title: 'Media',
    link: '/media/list'
  },
  {
    title: title || 'Details',
    link: `/media/details/${id}`
  },
  {
    title: 'Edit'
  }
];
