import {
  FETCH_PLANT_DETAILS,
  PLANT_DETAILS_FETCHED,
  SET_PLANT_DETAILS_PARAMS,
  PlantDetailsAction,
  PLANT_DETAILS_DELETED,
  PLANT_DETAILS_UPDATE
} from 'modules/Plants/action/Details';
import { createPlantDetails } from 'modules/Plants/model/PlantDetails';
import {
  initPlantDetailsState,
  PlantDetailsState
} from 'modules/Plants/state/details';

const initState = initPlantDetailsState();

const reducer = (
  state: PlantDetailsState = initState,
  action: PlantDetailsAction
): PlantDetailsState => {
  switch (action.type) {
    case SET_PLANT_DETAILS_PARAMS:
      return { ...state, plantId: action.payload };

    case FETCH_PLANT_DETAILS:
      return { ...state, fetching: true };

    case PLANT_DETAILS_UPDATE:
      return { ...state, plantDetails: { ...action.payload } };

    case PLANT_DETAILS_FETCHED: {
      const { plantDetails = null } = action.payload;

      return {
        ...state,
        fetching: false,
        plantDetails: createPlantDetails(plantDetails)
      };
    }

    case PLANT_DETAILS_DELETED: {
      const { plantDetails } = state;
      plantDetails.is_deleted = true;

      return {
        ...state,
        plantDetails
      };
    }

    default:
      return state;
  }
};

export default reducer;
