import React, { useState } from 'react';
import Media from 'modules/Media/model/Media';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { MediaKnowHowEntity } from 'modules/Media/model/MediaKnowHow';

export interface Props {
  media: Media;
  attachedTo: 'plant' | 'plantGroup' | 'family';
  cols: TableCol<MediaKnowHowEntity>[];
}

const AttachedToAsKnowHow: React.FC<Props> = ({
  media,
  attachedTo,
  cols
}: Props): JSX.Element => {
  const [measurementsNav, setMeasurementsNav] = useState([
    true,
    false,
    false,
    false
  ]);

  let measurementToFilter:
    | 'temperature'
    | 'light'
    | 'soil_moisture'
    | 'soil_fertility' = 'temperature';

  if (measurementsNav[1]) {
    measurementToFilter = 'soil_moisture';
  } else if (measurementsNav[2]) {
    measurementToFilter = 'soil_fertility';
  } else if (measurementsNav[3]) {
    measurementToFilter = 'light';
  }

  return (
    <div className="w-100">
      <DisplayNav
        booleanArr={measurementsNav}
        setBooleanArr={setMeasurementsNav}
        valueArr={['Temperature', 'Soil moisture', 'Soil fertility', 'Light']}
      />
      <div className="tab-content mt-3">
        <div className="form-group mb-3">
          <div className="form-row justify-content-between">
            <div className="mr-1 h4 d-flex justify-content-center align-items-center">
              Too low
            </div>
          </div>
          <div className="mt-2">
            <Table
              cols={cols}
              rows={media.getAttachedToAsKnowHow(
                attachedTo,
                measurementToFilter,
                1
              )}
            />
          </div>
        </div>
        <div className="form-group mb-3">
          <div className="form-row justify-content-between">
            <div className="mr-1 h4 d-flex justify-content-center align-items-center">
              Low
            </div>
          </div>
          <div className="mt-2">
            <Table
              cols={cols}
              rows={media.getAttachedToAsKnowHow(
                attachedTo,
                measurementToFilter,
                2
              )}
            />
          </div>
        </div>
        <div className="form-group mb-3">
          <div className="form-row justify-content-between">
            <div className="mr-1 h4 d-flex justify-content-center align-items-center">
              Perfect
            </div>
          </div>
          <div className="mt-2">
            <Table
              cols={cols}
              rows={media.getAttachedToAsKnowHow(
                attachedTo,
                measurementToFilter,
                3
              )}
            />
          </div>
        </div>
        <div className="form-group mb-3">
          <div className="form-row justify-content-between">
            <div className="mr-1 h4 d-flex justify-content-center align-items-center">
              High
            </div>
          </div>
          <div className="mt-2">
            <Table
              cols={cols}
              rows={media.getAttachedToAsKnowHow(
                attachedTo,
                measurementToFilter,
                4
              )}
            />
          </div>
        </div>
        <div className="form-group mb-3">
          <div className="form-row justify-content-between">
            <div className="mr-1 h4 d-flex justify-content-center align-items-center">
              Too high
            </div>
          </div>
          <div className="mt-2">
            <Table
              cols={cols}
              rows={media.getAttachedToAsKnowHow(
                attachedTo,
                measurementToFilter,
                5
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachedToAsKnowHow;
