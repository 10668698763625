import React, { Component, FormEvent, ReactNode } from 'react';
import BaseModal from 'modules/Layout/component/Modal';
import { Button, Form, Label } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  MeasurementsKnowHow as MeasurementsKnowHowType,
  MeasurementsKnowHow
} from 'modules/Shared/configs/measurementKnowHowEntity/type';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import {
  addMeasurementKnowHowToastError,
  addMeasurementKnowHowToastSuccess
} from 'modules/Plants/toasts';
import MediaAutocomplete from 'modules/Plants/container/Autocomplete';
import { addMediaForPlant } from 'modules/Plants/repository';
import {
  PlantDetailsUpdateAction,
  plantDetailsUpdatedAction
} from 'modules/Plants/action/Details';
import PlantGroupDetails from 'modules/PlantGroups/model/PlantGroupDetails';
import {
  addMediaForFamily,
  addMediaForPlantGroup
} from 'modules/PlantGroups/repository';
import {
  setFamilyKnowHowsAction,
  SetFamilyKnowHowsAction,
  SetFamilyKnowHowsPayload
} from 'modules/Family/action/Details';
import FamilyDetails from '../../../../../Family/model/FamilyDetails';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
  plantDetailsUpdatedAction: (
    payload: PlantDetails
  ) => PlantDetailsUpdateAction;
  setFamilyKnowHows: (
    payload: SetFamilyKnowHowsPayload
  ) => SetFamilyKnowHowsAction;
}

export interface Props extends DispatchProps {
  onSuccess?: (media?: MeasurementsKnowHow) => void;
  onError?: () => void;
  plant?: PlantDetails;
  plantGroup?: PlantGroupDetails;
  plantGroupUpdated?: (
    knowHow: MeasurementsKnowHowType,
    type: 'add' | 'delete'
  ) => void;
  family?: FamilyDetails;
  familyMeasurementsKnowHows?: MeasurementsKnowHowType[];
}

export interface State {
  mediaId: number;
  open: boolean;
  fetching: boolean;
  measurement: 'soil_moisture' | 'soil_fertility' | 'light' | 'temperature';
  measurementStatus: number;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload)),
  plantDetailsUpdatedAction: (payload) =>
    dispatch(plantDetailsUpdatedAction(payload)),
  setFamilyKnowHows: (payload) => dispatch(setFamilyKnowHowsAction(payload))
});

export class AddMeasurementKnowHowModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mediaId: null,
      open: false,
      fetching: false,
      measurement: null,
      measurementStatus: null
    };

    this.renderBody = this.renderBody.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  open(
    measurement: 'soil_moisture' | 'soil_fertility' | 'light' | 'temperature',
    measurementStatus: number
  ): void {
    this.setState({
      mediaId: null,
      open: true,
      fetching: false,
      measurement,
      measurementStatus
    });
  }

  close(): void {
    this.setState({
      open: false
    });
  }

  renderBody(): ReactNode {
    const { fetching, mediaId } = this.state;

    return (
      <Form id="add-measurement-know-how-form" onSubmit={this.onSubmit}>
        {fetching && <Loader />}
        <Label>Know-how*:</Label>
        <MediaAutocomplete
          mediaId={String(mediaId)}
          required
          onChange={(chooseMediaId) =>
            this.setState({ mediaId: Number(chooseMediaId) })
          }
        />
      </Form>
    );
  }

  renderFooter(): ReactNode {
    const { fetching } = this.state;

    return (
      <div className="d-flex w-100 justify-content-between">
        <Button
          disabled={fetching}
          color="primary"
          onClick={this.close}
          className="mr-2"
          type="button"
        >
          Cancel
        </Button>
        <Button
          disabled={fetching}
          type="submit"
          form="add-measurement-know-how-form"
          color="secondary"
        >
          Add
        </Button>
      </div>
    );
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const {
      onSuccess,
      onError,
      addToast,
      plant,
      plantGroup,
      plantDetailsUpdatedAction: plantDetailsUpdatedActionProp,
      plantGroupUpdated,
      family,
      setFamilyKnowHows,
      familyMeasurementsKnowHows
    } = this.props;
    const { measurementStatus, measurement, mediaId } = this.state;

    try {
      this.setState({
        fetching: true
      });

      if (plant) {
        const {
          data: { know_how }
        } = await addMediaForPlant(plant.id, {
          measurement,
          measurement_status: measurementStatus,
          media_id: mediaId
        });

        plantDetailsUpdatedActionProp({
          ...plant,
          measurements_statuses_know_hows: [
            ...plant.measurements_statuses_know_hows,
            { ...know_how, measurement, measurement_status: measurementStatus }
          ]
        });
      } else if (plantGroup) {
        const {
          data: { know_how }
        } = await addMediaForPlantGroup(plantGroup.id, {
          measurement,
          measurement_status: measurementStatus,
          media_id: mediaId
        });

        plantGroupUpdated(
          {
            ...know_how,
            measurement_status: measurementStatus,
            measurement
          },
          'add'
        );
      } else if (family) {
        const {
          data: { know_how }
        } = await addMediaForFamily(family.id, {
          measurement,
          measurement_status: measurementStatus,
          media_id: mediaId
        });

        setFamilyKnowHows({
          measurementsStatusesKnowHows: [
            ...familyMeasurementsKnowHows.map((obj) => ({ ...obj })),
            { ...know_how, measurement, measurement_status: measurementStatus }
          ]
        });
      }

      if (onSuccess) {
        onSuccess();
      }

      addToast(addMeasurementKnowHowToastSuccess());
    } catch (error) {
      if (onError) {
        onError();
      }

      addToast(addMeasurementKnowHowToastError());
    } finally {
      this.close();
    }
  }

  render(): ReactNode {
    const { open } = this.state;

    return (
      <BaseModal
        isOpen={open}
        body={this.renderBody()}
        footer={this.renderFooter()}
        title="Add measurements know-how"
        toggle={this.close}
        onClosed={this.close}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(AddMeasurementKnowHowModal);
