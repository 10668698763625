import React from 'react';
import { primaryInfoArr } from 'modules/Garden/view/Details/helper/DetailsConfig';
import GardenDetails from 'modules/Garden/model/GardenDetails';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import { GardenDetailsUserPlantsTable } from 'modules/Garden/container/Table/gardenDetailsUserPlantsTable';

interface IProps {
  data: GardenDetails;
}

const Details: React.FC<IProps> = ({ data }) => {
  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Garden">
        <div className="tab-content mt-3 row">
          {displayOptionRowContent(primaryInfoArr(data), false, 'col-lg-6')}
        </div>
      </DisplayBaseTabContent>
      <DisplayBaseTabContent title="Connected user plants">
        <GardenDetailsUserPlantsTable userPlants={data?.userPlants} />
      </DisplayBaseTabContent>
    </div>
  );
};

export default Details;
