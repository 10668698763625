import React, { Component, FormEvent, ReactNode } from 'react';
import BaseModal from 'modules/Layout/component/Modal';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import {
  AddToastAction,
  addToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { MeasurementsKnowHow } from 'modules/Shared/configs/measurementKnowHowEntity/type';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import {
  editSynonymsToastError,
  editSynonymsToastSuccess
} from 'modules/Plants/toasts';
import { editPlantSynonyms } from 'modules/Plants/repository';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import {
  PlantDetailsUpdateAction,
  plantDetailsUpdatedAction
} from 'modules/Plants/action/Details';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
  plantDetailsUpdate: (payload: PlantDetails) => PlantDetailsUpdateAction;
}

export interface Props extends DispatchProps {
  onSuccess?: (media?: MeasurementsKnowHow) => void;
  onError?: () => void;
}

export interface State {
  plant: PlantDetails;
  synonyms: { key: number; synonym: string }[];
  open: boolean;
  fetching: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload)),
  plantDetailsUpdate: (payload) => dispatch(plantDetailsUpdatedAction(payload))
});

export class PlantSynonymsModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      plant: null,
      synonyms: [],
      open: false,
      fetching: false
    };

    this.renderBody = this.renderBody.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  open(plant: PlantDetails): void {
    this.setState({
      plant,
      synonyms: plant.synonyms.map((synonym, key) => ({
        key,
        synonym
      })),
      open: true,
      fetching: false
    });
  }

  close(): void {
    this.setState({
      open: false
    });
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const { onSuccess, onError, addToast, plantDetailsUpdate } = this.props;
    const { plant, synonyms } = this.state;

    try {
      this.setState({
        fetching: true
      });

      const mappedSynonyms = synonyms.map((obj) => obj.synonym);

      await editPlantSynonyms(plant.id, mappedSynonyms);

      plantDetailsUpdate({ ...plant, synonyms: mappedSynonyms });

      if (onSuccess) {
        onSuccess();
      }

      addToast(editSynonymsToastSuccess());
    } catch (error) {
      if (onError) {
        onError();
      }

      addToast(editSynonymsToastError());
    } finally {
      this.close();
    }
  }

  renderBody(): ReactNode {
    const { fetching, synonyms } = this.state;

    return (
      <Form id="edit-synonyms-form" onSubmit={this.onSubmit}>
        {fetching && <Loader />}
        <FormGroup>
          <Label>Synonyms:</Label>
          <div className="d-flex my-2">
            <Button
              className="w-100"
              type="button"
              onClick={() => {
                const sortedSynonyms = synonyms.sort((a, b) => b.key - a.key);
                const key =
                  sortedSynonyms.length > 0 ? sortedSynonyms[0].key + 1 : 0;

                this.setState({
                  synonyms: [{ synonym: '', key }, ...synonyms]
                });
              }}
            >
              ADD SYNONYM
            </Button>
          </div>
          <div>
            {synonyms.map((synonym, index) => (
              <div key={synonym.key} className="d-flex align-items-center mb-2">
                <Input
                  type="text"
                  onChange={(event) => {
                    synonyms[index].synonym = event.currentTarget.value;

                    this.setState({
                      synonyms: [...synonyms]
                    });
                  }}
                  value={synonym.synonym}
                />
                <ActionDelete
                  className="ml-2"
                  onClick={() =>
                    this.setState({
                      synonyms: synonyms.filter(
                        (_obj, objIndex) => objIndex !== index
                      )
                    })
                  }
                />
              </div>
            ))}
          </div>
        </FormGroup>
      </Form>
    );
  }

  renderFooter(): ReactNode {
    const { fetching } = this.state;

    return (
      <div className="d-flex w-100 justify-content-between">
        <Button
          disabled={fetching}
          color="primary"
          onClick={this.close}
          className="mr-2"
          type="button"
        >
          Cancel
        </Button>
        <Button
          disabled={fetching}
          type="submit"
          form="edit-synonyms-form"
          color="secondary"
        >
          Save
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { open } = this.state;

    return (
      <BaseModal
        isOpen={open}
        body={this.renderBody()}
        footer={this.renderFooter()}
        title="Edit plant synonyms"
        toggle={this.close}
        onClosed={this.close}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(PlantSynonymsModal);
