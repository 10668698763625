import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const editPlantDiseaseToastSuccess = (): AddToastPayload => ({
  header: 'Edit plant disease',
  body: 'Plant disease updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const editPlantDiseaseToastError = (): AddToastPayload => ({
  header: 'Edit plant disease',
  body: 'Plant disease not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
