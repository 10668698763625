import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PROFILE } from 'modules/User/routes';

export const NavbarProfile: React.FC = (): JSX.Element => {
  return (
    <Link
      className="text-primary font-16 font-weight-400"
      to={ROUTE_PROFILE}
      title="Profile"
    >
      <span>Profile</span>
      <i className="fas fa-user ml-1" />
    </Link>
  );
};

export default NavbarProfile;
