import { RootState } from 'app/reducer';
import axios from 'axios';
import Unauthorized from 'modules/Auth/exception/Unauthorized';
import { handleUnauthenticated } from 'modules/Auth/helper/token';
import { addToastAction, AddToastPayload } from 'modules/Layout/action';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';
import BadRequest from 'modules/Shared/exception/BadRequest';
import NotFound from 'modules/Shared/exception/NotFound';
import ServerError from 'modules/Shared/exception/ServerError';
import TooManyAttempts from 'modules/Shared/exception/TooManyAttempts';
import Validation from 'modules/Shared/exception/Validation';
import { ColorVariants } from 'modules/Shared/type';
import { Store } from 'redux';
import NotAcceptable from 'modules/Shared/exception/NotAcceptable';
import NetworkError from 'modules/Shared/exception/NetworkError';

// eslint-disable-next-line import/prefer-default-export
export const registerAxiosInterceptors = (store: Store<RootState>): void => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 400: {
            return Promise.reject(new BadRequest(error));
          }

          case 401: {
            return handleUnauthenticated(error, store);
          }

          case 403: {
            return Promise.reject(new Unauthorized(error));
          }

          case 404: {
            return Promise.reject(new NotFound(error));
          }

          case 406: {
            return Promise.reject(new NotAcceptable(error));
          }

          case 422: {
            return Promise.reject(new Validation(error));
          }

          case 429: {
            const toast: AddToastPayload = {
              header: 'Server',
              body: 'Too many requests to server',
              color: ColorVariants.Danger,
              closeIn: TOAST_CLOSE_IN
            };

            store.dispatch(addToastAction(toast));

            return Promise.reject(new TooManyAttempts(error));
          }

          case 500: {
            return Promise.reject(new ServerError(error));
          }

          default:
            return Promise.reject(error);
        }
      } else if (typeof error.response === 'undefined') {
        return Promise.reject(new NetworkError(error));
      }

      return Promise.reject(error);
    }
  );
};
