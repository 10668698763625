import React from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import Hub from 'modules/Hub/model/Hub';
import HubDetailsPlants from 'modules/Hub/component/Details/Plants';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';
import HubDetailsLogs from 'modules/Hub/component/Details/Logs';

export interface Props {
  hub: Hub;
}

const HubDetails = (props: Props): JSX.Element => {
  const { hub } = props;

  return (
    <div className="position-relative">
      <div className="content restrict-width">
        <DisplayBaseTabContent title={`Hub - ${hub.hub_id}`}>
          <div className="tab-content mt-3 row">
            {displayOptionRowContent(
              [
                {
                  title: 'Mac:',
                  description: hub.hub_id
                },
                {
                  title: 'Name:',
                  description: hub.name
                },
                {
                  title: 'Status:',
                  description: hub.getStatus()
                },
                {
                  title: 'Owner email:',
                  type: 'link',
                  description: hub.user?.email,
                  to: hub.user
                    ? getPathUrl(ROUTE_USER_DETAILS, { id: hub.user.id })
                    : null
                },
                {
                  title: 'Owner username:',
                  description: hub.user?.username,
                  to: hub.user
                    ? getPathUrl(ROUTE_USER_DETAILS, { id: hub.user.id })
                    : null
                },
                {
                  title: 'Software version:',
                  description: hub.version
                },
                {
                  title: 'IoT certificate id:',
                  description: hub.iot_certificate_id
                },
                {
                  title: 'IoT thing name:',
                  description: hub.iot_thing_name
                }
              ],
              false,
              'col-lg-6'
            )}
          </div>
        </DisplayBaseTabContent>
      </div>
      <div className="content restrict-width">
        <div className="row">
          <div className="col-6">
            <HubDetailsPlants hub={hub} />
          </div>
          <div className="col-6">
            <HubDetailsLogs hub={hub} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubDetails;
