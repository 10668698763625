import React, { ChangeEvent, ReactNode } from 'react';
import {
  Button,
  DropdownMenu,
  FormFeedback,
  FormGroup,
  Input
} from 'reactstrap';
import _debounce from 'lodash/debounce';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'modules/Shared/exception/ApiError';
import './style.scss';

export type AutocompleteOption = {
  key?: string | number;
  label: string;
  node?: ReactNode;
  value: string;
  disabled?: boolean;
};

export interface AutocompleteProps {
  options?: AutocompleteOption[];
  onChange?: (value: string) => void;
  onSearch: (search: string) => void;
  onReset?: () => void;
  value?: AutocompleteOption;
  required?: boolean;
  disabled?: boolean;
  error?: ApiError;
  fetching?: boolean;
  noResults?: string;
  label?: string;
  render?: (row: unknown) => ReactNode;
  closeOnSelect?: boolean;
}

export type AutocompleteOmitProps = Omit<
  AutocompleteProps,
  'onSearch' | 'onReset' | 'fetching' | 'error' | 'value'
>;

export interface AutocompleteState {
  search: string;
  showSelect: boolean;
}

class Autocomplete extends React.Component<
  AutocompleteProps,
  AutocompleteState
> {
  protected onSearchDebounce: (search: string) => void;
  protected skipFirstFetch: boolean;

  constructor(props: AutocompleteProps) {
    super(props);

    const { onSearch } = props;

    this.state = {
      search: '',
      showSelect: false
    };

    this.skipFirstFetch = true;

    this.onChange = this.onChange.bind(this);
    this.getSelectedOption = this.getSelectedOption.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onOptionClick = this.onOptionClick.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.reset = this.reset.bind(this);
    this.onSearchDebounce = _debounce(onSearch, 500);
  }

  static getDerivedStateFromProps(
    nextProps: AutocompleteProps,
    prevState: AutocompleteState
  ): Partial<AutocompleteState> {
    if (prevState.showSelect) {
      return null;
    }

    return {
      search: nextProps.value ? nextProps.value.label : ''
    };
  }

  componentDidMount(): void {
    const { onSearch } = this.props;

    onSearch('');
  }

  componentWillUnmount(): void {
    const { onReset } = this.props;

    if (onReset) {
      onReset();
    }
  }

  onChange(event: ChangeEvent<HTMLInputElement>): void {
    const {
      target: { value }
    } = event;

    this.setState(
      {
        search: value || null
      },
      () => {
        this.onSearchDebounce(value);
      }
    );
  }

  onFocus(): void {
    const { onSearch } = this.props;

    this.setState(
      {
        search: '',
        showSelect: true
      },
      () => {
        if (this.skipFirstFetch) {
          this.skipFirstFetch = false;
        } else {
          onSearch('');
        }
      }
    );
  }

  onBlur(event: ChangeEvent): void {
    const { onReset } = this.props;
    const { currentTarget } = event;

    console.log('onBlur');

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.reset(null, true);
        if (onReset) {
          onReset();
        }
      }
    }, 0);
  }

  onOptionClick(option?: AutocompleteOption): void {
    const { onChange } = this.props;

    this.reset(() => {
      onChange(option ? option.value : '');
    });
  }

  getSelectedOption(): AutocompleteOption | null {
    const { value, options } = this.props;

    if (!value) {
      return null;
    }

    return options.find((option) => option.value === value.value) || null;
  }

  reset(callback?: () => void, blur?: boolean): void {
    const { closeOnSelect = true } = this.props;
    const selected = this.getSelectedOption();

    this.setState(
      {
        search: selected ? selected.label : '',
        showSelect: blur ? false : !closeOnSelect
      },
      callback
    );
  }

  renderSearch(): ReactNode {
    const {
      disabled = false,
      required = false,
      label = 'Choose',
      error = null
    } = this.props;
    const { search } = this.state;

    return (
      <FormGroup>
        <Input
          type="text"
          value={search || ''}
          required={required}
          disabled={disabled}
          placeholder={required ? `${label}*` : label}
          onChange={this.onChange}
          onFocus={this.onFocus}
          invalid={error instanceof ApiError}
        />
        {error && <FormFeedback>{error.getMessageValue()}</FormFeedback>}
      </FormGroup>
    );
  }

  renderDropdown(): ReactNode {
    const {
      options = [],
      disabled,
      value,
      noResults = 'No results',
      fetching,
      onReset,
      closeOnSelect = false
    } = this.props;
    const { showSelect } = this.state;

    if (!showSelect) {
      return null;
    }

    const filteredOptions = options.filter(
      (option) => option.value !== value.value
    );

    return (
      <>
        <DropdownMenu className="d-block w-100">
          {value && value.value && (
            <button
              type="button"
              className="dropdown-item value bg-secondary text-white d-flex align-items-center justify-content-between"
              onClick={() => this.onOptionClick(null)}
              disabled={disabled}
            >
              <div>{value.node ? value.node : value.label}</div>
              <span className="hint">Remove</span>
            </button>
          )}
          {filteredOptions.length === 0 && (
            <div className="dropdown-item">
              {fetching ? 'Fetching...' : noResults}
            </div>
          )}
          {filteredOptions.map((option) => (
            <button
              key={option.key || option.value}
              type="button"
              className="dropdown-item d-flex align-items-center justify-content-between"
              onClick={() => this.onOptionClick(option)}
              disabled={disabled || option.disabled}
            >
              <div>{option.node ? option.node : option.label}</div>
              <span className="hint">Select</span>
            </button>
          ))}
        </DropdownMenu>
        {!closeOnSelect && (
          <Button
            type="button"
            color="danger"
            className="px-2 m-0"
            title="Close"
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => {
              this.reset(null, true);

              if (onReset) {
                onReset();
              }
            }}
          >
            X
          </Button>
        )}
      </>
    );
  }

  render(): ReactNode {
    const { fetching } = this.props;

    return (
      <div className="autocomplete position-relative" onBlur={this.onBlur}>
        {fetching && <Loader />}
        {this.renderSearch()}
        {this.renderDropdown()}
      </div>
    );
  }
}

export default Autocomplete;
