import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import React, { createRef, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ActionShow from 'modules/Layout/component/Action/Show';
import { getPathUrl } from 'modules/Shared/helper/api';
import TermsOfService from 'modules/Policy/model/TermsOfService';
import {
  setPPListParamsAction,
  SetPPListParamsAction
} from 'modules/Policy/action/list';
import { ROUTE_PP_DETAILS, ROUTE_UPDATE_PP } from 'modules/Policy/routes';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import PrivacyPolicy from 'modules/Policy/model/PrivacyPolicy';
import DeletePrivacyPolicyModal, {
  DeletePrivacyPolicyModal as DeletePrivacyPolicyModalType
} from 'modules/Policy/component/Modal/Delete/PrivacyPolicy';

export interface StateProps {
  pp: PrivacyPolicy[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetPPListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { pp, sort } = state.policy.list;

  return { pp, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPPListParamsAction(payload))
});

export class PrivacyPolicyTable extends React.Component<Props> {
  readonly cols: TableCol<TermsOfService>[];
  readonly deleteModalRef: React.RefObject<DeletePrivacyPolicyModalType>;

  constructor(props: Props) {
    super(props);

    this.deleteModalRef = createRef();

    this.cols = [
      {
        property: 'activation_date',
        label: 'Activation date',
        className: 'col-11',
        sortable: true,
        columnRedirectTo: (row) => getPathUrl(ROUTE_PP_DETAILS, { id: row.id })
      },
      {
        property: 'published',
        label: 'Published',
        className: 'col-1',
        value: (row) => row.getIsPublished()
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show privacy policy"
              to={getPathUrl(ROUTE_PP_DETAILS, { id: row.id })}
            />
            <ActionUpdate
              title="Edit privacy policy"
              to={getPathUrl(ROUTE_UPDATE_PP, { id: row.id })}
              disabled={Boolean(row.published)}
            />
            <ActionDelete
              title="Delete privacy policy"
              onClick={() => this.deleteModalRef?.current?.open(row)}
              disabled={Boolean(row.published)}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { pp, sort, setParams } = this.props;

    return (
      <>
        <DeletePrivacyPolicyModal
          ref={this.deleteModalRef}
          onSuccess={() => setParams({ sort })}
        />
        <Table
          cols={this.cols}
          rows={pp}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PrivacyPolicyTable);
