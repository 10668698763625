import React from 'react';
import HealthCheckHelp from 'modules/PlantDiseases/model/HealthCheckHelp';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';

export interface Props {
  healthCheckHelp: HealthCheckHelp;
  onSetResponded: (responded: boolean) => Promise<void>;
}

const HealthCheckHelpDetails = (props: Props): JSX.Element => {
  const { healthCheckHelp, onSetResponded } = props;

  const fields = [
    {
      title: 'User email:',
      description: healthCheckHelp.email,
      to: getPathUrl(ROUTE_USER_DETAILS, { id: healthCheckHelp.user_id }),
      type: 'link'
    },
    {
      title: 'Responded:',
      reactNode: (
        <div>
          {healthCheckHelp.responded ? 'Yes' : 'No'}
          {healthCheckHelp.responded ? (
            <ActionDelete
              label={<i className="fas font-22 fa-times ml-2" />}
              title="Set to not responded"
              onClick={() => onSetResponded(false)}
            />
          ) : (
            <ActionUpdate
              label={<i className="fas font-20 fa-check ml-2" />}
              title="Set to responded"
              onClick={() => onSetResponded(true)}
            />
          )}
        </div>
      )
    },
    {
      title: 'Sent at:',
      description: healthCheckHelp.created_at
    },
    {
      title: 'Description:',
      description: healthCheckHelp.description
    }
  ];

  return (
    <div>
      <div className="card">
        <div className="card-body p-3">
          <div className="d-flex">
            {Boolean(healthCheckHelp.images[0]) && (
              <div className="d-flex flex-column mr-5">
                <div className="mb-2 font-weight-bold font-20">
                  Image sent by user:
                </div>
                <img
                  style={{ maxWidth: 400 }}
                  alt="sent_image"
                  src={healthCheckHelp.images[0]}
                />
              </div>
            )}
            <div className="flex-grow-1">{displayOptionRowContent(fields)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthCheckHelpDetails;
