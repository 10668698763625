const getPlantSectionName = (section: string): string => {
  switch (section) {
    case 'dr_beam': {
      return 'Dr. Beam';
    }

    case 'form_varieties': {
      return 'Form & Varieties';
    }

    case 'placement': {
      return 'Placement';
    }

    case 'plant_care': {
      return 'Plant Care';
    }

    case 'pro_tips': {
      return 'Pro Tip';
    }

    case 'propagation': {
      return 'Propagation';
    }

    case 'repotting': {
      return 'Repotting';
    }

    default: {
      return '';
    }
  }
};

export default getPlantSectionName;
