import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setHubListParamsAction,
  SetHubListParamsAction
} from 'modules/Hub/action/list';
import Hub from 'modules/Hub/model/Hub';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ActionShow from 'modules/Layout/component/Action/Show';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_HUB_DETAILS } from 'modules/Hub/routes';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';

export interface StateProps {
  hubs: Hub[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetHubListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (hub: Hub) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { hubs, sort } = state.hub.list;

  return { hubs, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setHubListParamsAction(payload))
});

export class HubTable extends React.Component<Props> {
  readonly cols: TableCol<Hub>[];

  constructor(props: Props) {
    super(props);

    this.cols = [
      {
        property: 'hub_id',
        label: 'Mac',
        sortable: true,
        className: 'col-4',
        columnRedirectTo: (row) => getPathUrl(ROUTE_HUB_DETAILS, { id: row.id })
      },
      {
        property: 'username',
        label: 'Owner username',
        sortable: true,
        className: 'col-4',
        columnRedirectTo: (row) =>
          Boolean(row.user_id) &&
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: 'email',
        label: 'Owner email',
        sortable: true,
        className: 'col-4',
        columnRedirectTo: (row) =>
          Boolean(row.user_id) &&
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show hub"
              to={getPathUrl(ROUTE_HUB_DETAILS, { id: row.id })}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { hubs, sort, setParams } = this.props;

    return (
      <Table
        cols={this.cols}
        rows={hubs}
        sort={sort}
        onSort={(params) => setParams({ sort: params })}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HubTable);
