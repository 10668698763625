import NavbarLogout from 'modules/Layout/component/Navbar/Logout';
import React from 'react';
import Logo from 'modules/Layout/component/Logo';
import NavbarProfile from 'modules/Layout/component/Navbar/Profile';

const Navbar: React.FC = (): JSX.Element => {
  return (
    <div className="navbar-custom d-flex">
      <div className="logo-box">
        <Logo width={null} className="logo text-center" />
      </div>
      <ul className="list-unstyled topnav-menu m-0 ml-auto">
        <li
          style={{ height: 70 }}
          className="d-flex justify-content-between align-items-center mr-4"
        >
          <NavbarProfile />
        </li>
        <li
          style={{ height: 70 }}
          className="d-flex justify-content-between align-items-center mr-2"
        >
          <NavbarLogout />
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
