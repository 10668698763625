import React, { ReactNode } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export type Props = {
  isOpen: boolean;
  title: ReactNode;
  toggle: () => void;
  body?: ReactNode;
  footer?: ReactNode;
  className?: string;
  onClosed?: () => void;
  size?: string;
};

export const BaseModal = (props: Props): JSX.Element => {
  const {
    isOpen,
    toggle,
    title,
    body,
    footer,
    className,
    onClosed,
    size = null
  } = props;

  return (
    <Modal
      size={size}
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      onClosed={onClosed}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};

export default BaseModal;
