import { RootState } from 'app/reducer';
import { setLocationSearchAction, manageThrow } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  administratorListFetchedAction,
  FETCH_ADMINISTRATOR_LIST,
  FETCH_USER_FEEDBACK_LIST,
  FETCH_USER_LIST,
  fetchAdministratorListAction,
  fetchUserFeedbackListAction,
  fetchUserListAction,
  SET_ADMINISTRATOR_LIST_PARAMS,
  SET_USER_FEEDBACK_LIST_PARAMS,
  SET_USER_LIST_PARAMS,
  USER_FEEDBACK_LIST_RESPONDED,
  userFeedbackListFetchedAction,
  UserFeedbackListRespondedAction,
  userFeedbackListSetRespondedAction,
  userListFetchedAction
} from 'modules/User/action/list';
import {
  editUserFeedback,
  fetchAdministrators,
  fetchUsers,
  fetchUsersFeedback,
  FetchUsersFeedbackResponse,
  FetchUsersResponse
} from 'modules/User/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';

export function* setUserListParamsSaga(): SagaIterator {
  yield put(fetchUserListAction());
}

export function* fetchUserListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.user.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const mappedFilter = {
      ...filter
    };

    if (filter.age_max) {
      mappedFilter.age_max = Number(filter.age_max);
    }
    if (filter.age_min) {
      mappedFilter.age_min = Number(filter.age_min);
    }
    if (filter.plants_owned) {
      mappedFilter.plants_owned = Number(filter.plants_owned);
    }
    if (filter.plants_knowledge) {
      mappedFilter.plants_knowledge = Number(filter.plants_knowledge);
    }

    const response: FetchUsersResponse = yield call(fetchUsers, {
      ...sort,
      pagination,
      search: mappedFilter
    });

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      userListFetchedAction({
        users: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(userListFetchedAction({ users: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export function* setAdministratorsListParamsSaga(): SagaIterator {
  yield put(fetchAdministratorListAction());
}

export function* fetchAdministratorsListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.user.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response: FetchUsersResponse = yield call(fetchAdministrators, {
      ...sort,
      pagination,
      search: filter
    });

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      administratorListFetchedAction({
        users: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(administratorListFetchedAction({ users: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export function* setUserFeedbackListParamsSaga(): SagaIterator {
  yield put(fetchUserFeedbackListAction());
}

export function* fetchUserFeedbackListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.user.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const mappedFilter = {
      ...filter
    };

    if (filter.responded) {
      mappedFilter.responded = '0';
    }

    const response: FetchUsersFeedbackResponse = yield call(
      fetchUsersFeedback,
      {
        ...sort,
        pagination,
        search: mappedFilter
      }
    );

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      userFeedbackListFetchedAction({
        feedback: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(userFeedbackListFetchedAction({ feedback: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export function* userFeedbackListRespondedSaga({
  payload
}: UserFeedbackListRespondedAction): SagaIterator {
  try {
    const { feedbackId, responded } = payload;

    yield call(editUserFeedback, feedbackId, { responded });

    yield put(userFeedbackListSetRespondedAction({ feedbackId, responded }));
  } catch (error) {
    console.log(error);
  }
}

export default function* userListSaga(): SagaIterator {
  yield takeLatest(SET_USER_LIST_PARAMS, setUserListParamsSaga);
  yield takeLatest(FETCH_USER_LIST, fetchUserListSaga);
  yield takeLatest(
    SET_USER_FEEDBACK_LIST_PARAMS,
    setUserFeedbackListParamsSaga
  );
  yield takeLatest(FETCH_USER_FEEDBACK_LIST, fetchUserFeedbackListSaga);
  yield takeLatest(
    SET_ADMINISTRATOR_LIST_PARAMS,
    setAdministratorsListParamsSaga
  );
  yield takeLatest(FETCH_ADMINISTRATOR_LIST, fetchAdministratorsListSaga);
  yield takeLatest(USER_FEEDBACK_LIST_RESPONDED, userFeedbackListRespondedSaga);
}
