export const convertArrayToObject = (
  array: { [key: string]: string }[],
  key: string
) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
};
