import { primaryInfoArr } from 'modules/Family/view/Details/helper/DetailsConfig';
import FamilyDetails from 'modules/Family/model/FamilyDetails';
import { thresholdArr } from 'modules/Shared/configs/tresholdsEntity/detailsArr';
import { TresholdsEntity } from 'modules/Shared/configs/tresholdsEntity/type';

export const FAMILY_DETAILS_MAIN = 'main';
export const FAMILY_DETAILS_DESCRIPTION = 'description';
export const FAMILY_DETAILS_THRESHOLD = 'thresholds';

export const acceptableTypesArray = [
  FAMILY_DETAILS_MAIN,
  FAMILY_DETAILS_DESCRIPTION,
  FAMILY_DETAILS_THRESHOLD
];

export type IType =
  | typeof FAMILY_DETAILS_MAIN
  | typeof FAMILY_DETAILS_DESCRIPTION
  | typeof FAMILY_DETAILS_THRESHOLD;

export const fillArrayOfFieldDesc = (
  type: string,
  data: FamilyDetails | TresholdsEntity
) => {
  switch (type) {
    case 'main':
      return primaryInfoArr(data as FamilyDetails);
    case 'thresholds':
      return thresholdArr(data as TresholdsEntity);
    default:
      return [];
  }
};
