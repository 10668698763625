import {
  initPlantDiseasesListState,
  PlantDiseasesListState
} from 'modules/PlantDiseases/state/list';
import {
  FETCH_HEALTH_CHECK_HELP_LIST,
  HEALTH_CHECK_HELP_LIST_FETCHED,
  HEALTH_CHECK_HELP_LIST_SET_RESPONDED,
  PlantDiseasesListAction,
  SET_HEALTH_CHECK_HELP_LIST_PARAMS
} from 'modules/PlantDiseases/action/list';
import { createHealthCheckHelp } from 'modules/PlantDiseases/model/HealthCheckHelp';

const initState = initPlantDiseasesListState();

const reducer = (
  state: PlantDiseasesListState = initState,
  action: PlantDiseasesListAction
): PlantDiseasesListState => {
  switch (action.type) {
    case SET_HEALTH_CHECK_HELP_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_HEALTH_CHECK_HELP_LIST:
      return { ...state, fetching: true };

    case HEALTH_CHECK_HELP_LIST_FETCHED: {
      const { content = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        healthCheckHelp: content.map(createHealthCheckHelp),
        totalPages
      };
    }

    case HEALTH_CHECK_HELP_LIST_SET_RESPONDED: {
      const { healthCheckHelpId, responded } = action.payload;

      return {
        ...state,
        healthCheckHelp: state.healthCheckHelp.map((obj) => {
          if (obj.id === healthCheckHelpId) {
            return {
              ...obj,
              responded: Number(responded)
            };
          }

          return obj;
        })
      };
    }

    default:
      return state;
  }
};

export default reducer;
