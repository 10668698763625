import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { MediaEntity } from 'modules/Media/model/Media';
import { Action } from 'redux';
import { MediaAdvancementEntity } from 'modules/Media/model/MediaAdvancement';

export const SET_MEDIA_LIST_PARAMS = 'MEDIA/LIST/PARAMS/SET';
export const FETCH_MEDIA_LIST = 'MEDIA/LIST/FETCH';
export const MEDIA_LIST_FETCHED = 'MEDIA/LIST/FETCHED';
export const SET_MEDIA_ADVANCEMENT_LIST_PARAMS =
  'MEDIA_ADVANCEMENT/LIST/PARAMS/SET';
export const FETCH_MEDIA_ADVANCEMENT_LIST = 'MEDIA_ADVANCEMENT/LIST/FETCH';
export const MEDIA_ADVANCEMENT_LIST_FETCHED = 'MEDIA_ADVANCEMENT/LIST/FETCHED';

export type MediaListAction =
  | SetMediaListParamsAction
  | FetchMediaListAction
  | MediaListFetchedAction
  | SetMediaAdvancementListParamsAction
  | FetchMediaAdvancementListAction
  | MediaAdvancementListFetchedAction;

export type SetMediaListParamsAction = SetSearchingParamsAction<
  typeof SET_MEDIA_LIST_PARAMS
>;

export const setMediaListParamsAction = (
  payload: PartialSearchingProps
): SetMediaListParamsAction =>
  setSearchingParamsAction(SET_MEDIA_LIST_PARAMS, payload);

export type FetchMediaListAction = Action<typeof FETCH_MEDIA_LIST>;

export const fetchMediaListAction = (): FetchMediaListAction => ({
  type: FETCH_MEDIA_LIST
});

export type MediaListFetchedPayload = {
  media: MediaEntity[];
  totalPages: number;
};

export interface MediaListFetchedAction
  extends Action<typeof MEDIA_LIST_FETCHED> {
  payload: MediaListFetchedPayload;
}

export const mediaListFetchedAction = (
  payload: MediaListFetchedPayload
): MediaListFetchedAction => ({
  type: MEDIA_LIST_FETCHED,
  payload
});

export type SetMediaAdvancementListParamsAction = SetSearchingParamsAction<
  typeof SET_MEDIA_ADVANCEMENT_LIST_PARAMS
>;

export const setMediaAdvancementListParamsAction = (
  payload: PartialSearchingProps
): SetMediaAdvancementListParamsAction =>
  setSearchingParamsAction(SET_MEDIA_ADVANCEMENT_LIST_PARAMS, payload);

export type FetchMediaAdvancementListAction = Action<
  typeof FETCH_MEDIA_ADVANCEMENT_LIST
>;

export const fetchMediaAdvancementListAction =
  (): FetchMediaAdvancementListAction => ({
    type: FETCH_MEDIA_ADVANCEMENT_LIST
  });

export type MediaAdvancementListFetchedPayload = {
  mediaAdvancement: MediaAdvancementEntity[];
  totalPages: number;
};

export interface MediaAdvancementListFetchedAction
  extends Action<typeof MEDIA_ADVANCEMENT_LIST_FETCHED> {
  payload: MediaAdvancementListFetchedPayload;
}

export const mediaAdvancementListFetchedAction = (
  payload: MediaAdvancementListFetchedPayload
): MediaAdvancementListFetchedAction => ({
  type: MEDIA_ADVANCEMENT_LIST_FETCHED,
  payload
});
