import React from 'react';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import SensorDetails, {
  sensorStatus
} from 'modules/Sensor/model/SensorDetails';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';
import { ROUTE_PLANT_USER_DETAILS } from 'modules/PlantUser/routes';

interface IProps {
  data: SensorDetails;
}

const Details: React.FC<IProps> = ({ data }: IProps) => {
  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Sensor">
        <div className="tab-content mt-3 row">
          {displayOptionRowContent(
            [
              {
                title: 'Mac:',
                description: data?.sensor_id
              },
              {
                title: 'Status:',
                description: sensorStatus(data?.status)
              },
              {
                title: 'Version:',
                description: data?.version
              },
              {
                title: 'Owner email:',
                type: 'link',
                description: data?.user?.email,
                to: data?.user
                  ? getPathUrl(ROUTE_USER_DETAILS, { id: data.user.id })
                  : null
              },
              {
                title: 'Owner username:',
                type: 'link',
                description: data?.user?.username,
                to: data?.user
                  ? getPathUrl(ROUTE_USER_DETAILS, { id: data.user.id })
                  : null
              },
              {
                title: 'Plant nickname:',
                type: 'link',
                description: data?.userPlant?.nickname,
                to: data?.userPlant
                  ? getPathUrl(ROUTE_PLANT_USER_DETAILS, {
                      id: data.userPlant.id
                    })
                  : null
              }
            ],
            false,
            'col-lg-6'
          )}
        </div>
      </DisplayBaseTabContent>
    </div>
  );
};

export default Details;
