import React, { ReactNode } from 'react';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import Loader from 'modules/Layout/component/Loader';
import { breadcrumbRoutePlantDiseases } from 'modules/PlantDiseases/breadcrumbs';
import PlantDisease, {
  createPlantDisease
} from 'modules/PlantDiseases/model/PlantDisease';
import { fetchPlantDiseases } from 'modules/PlantDiseases/repository';
import { connect } from 'react-redux';
import { RootState } from 'app/reducer';
import {
  Button,
  Card,
  CardBody,
  Input,
  Label,
  UncontrolledCollapse
} from 'reactstrap';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANT_DISEASES_EDIT } from 'modules/PlantDiseases/routes';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
}

export type Props = StateProps & DispatchProps;

export interface State {
  fetching: boolean;
  plantDiseases: PlantDisease[];
  search: string;
}

export type DiseaseType =
  | 'first_type'
  | 'second_type'
  | 'third_type'
  | 'fourth_type'
  | 'fifth_type';

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

export class PlantDiseasesView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fetching: true,
      plantDiseases: [],
      search: ''
    };

    this.fetchDiseases = this.fetchDiseases.bind(this);
    this.getUniqueTypes = this.getUniqueTypes.bind(this);
    this.getDiseasesForType = this.getDiseasesForType.bind(this);
    this.renderDiseasesTreeChildren =
      this.renderDiseasesTreeChildren.bind(this);
  }

  async fetchDiseases(): Promise<void> {
    const {
      data: { content }
    } = await fetchPlantDiseases();

    this.setState({
      plantDiseases: content.map(createPlantDisease)
    });
  }

  async componentDidMount(): Promise<void> {
    const { managePage } = this.props;

    await this.fetchDiseases();

    managePage({
      title: 'Plant diseases',
      breadcrumb: breadcrumbRoutePlantDiseases()
    });

    this.setState({
      fetching: false
    });
  }

  getUniqueTypes(type: DiseaseType, plantDiseases: PlantDisease[]): string[] {
    const keys: string[] = [];

    plantDiseases.forEach((disease) => {
      if (disease[type]) {
        keys.push(disease[type]);
      }
    });

    return keys.filter(
      (obj, index) => keys.findIndex((findObj) => findObj === obj) === index
    );
  }

  getDiseasesForType(
    plantDiseases: PlantDisease[],
    type: DiseaseType,
    typeValue: string
  ): PlantDisease[] {
    return plantDiseases.filter((obj) => obj[type] === typeValue);
  }

  renderDiseasesTreeChildren(
    indexType: number,
    plantDiseases: PlantDisease[],
    key: string
  ): ReactNode {
    const type: DiseaseType = [
      'first_type' as DiseaseType,
      'second_type' as DiseaseType,
      'third_type' as DiseaseType,
      'fourth_type' as DiseaseType,
      'fifth_type' as DiseaseType
    ][indexType];
    const uniqueTypes = this.getUniqueTypes(type, plantDiseases);

    if (!uniqueTypes.length) {
      return null;
    }

    return (
      <div key={key}>
        {uniqueTypes.map((uniqueType) => {
          const filteredDiseases = this.getDiseasesForType(
            plantDiseases,
            type,
            uniqueType
          );

          const currentType = filteredDiseases.find((obj) => {
            if (indexType === 0) {
              return (
                obj.first_type === uniqueType &&
                obj.second_type === null &&
                obj.third_type === null &&
                obj.fourth_type === null &&
                obj.fifth_type === null
              );
            }

            if (indexType === 1) {
              return (
                obj.second_type === uniqueType &&
                obj.third_type === null &&
                obj.fourth_type === null &&
                obj.fifth_type === null
              );
            }

            if (indexType === 2) {
              return (
                obj.third_type === uniqueType &&
                obj.fourth_type === null &&
                obj.fifth_type === null
              );
            }

            if (indexType === 3) {
              return obj.fourth_type === uniqueType && obj.fifth_type === null;
            }

            return obj.fifth_type === uniqueType;
          });

          const keyNoSpaces = `${key
            .replace(/ /g, '')
            .replace(/,/g, '')
            .replace(/\//g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')}-${uniqueType
            .replace(/ /g, '')
            .replace(/,/g, '')
            .replace(/\//g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')}`;

          const childrenTree =
            indexType <= 4
              ? this.renderDiseasesTreeChildren(
                  indexType + 1,
                  filteredDiseases,
                  `${key}-${uniqueType}`
                )
              : null;

          return (
            <div key={`${key}-${uniqueType}`}>
              <div className="d-flex align-items-center">
                {childrenTree ? (
                  <Button
                    id={keyNoSpaces}
                    color="link"
                    className="font-18 p-2 pl-0 mr-2 text-primary font-weight-400"
                  >
                    {uniqueType} <i className="fa fa-sort-down ml-2 mb-1" />
                  </Button>
                ) : (
                  <div className="font-18 p-2 pl-0 mr-2 text-primary font-weight-400">
                    {uniqueType}
                  </div>
                )}
                {currentType && (
                  <ActionUpdate
                    to={getPathUrl(ROUTE_PLANT_DISEASES_EDIT, {
                      id: currentType.id
                    })}
                  />
                )}
              </div>
              {childrenTree && (
                <>
                  <UncontrolledCollapse toggler={`#${keyNoSpaces}`}>
                    <div className="pl-5 pt-2 pb-2">{childrenTree}</div>
                  </UncontrolledCollapse>
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  render(): ReactNode {
    const { type } = this.props;
    const { fetching, plantDiseases, search } = this.state;

    if (type) return null;

    const loweredSearch = search.toLowerCase().trim();

    const filteredDiseases = plantDiseases.filter(
      (obj) =>
        (obj.first_type
          ? obj.first_type.toLowerCase().includes(loweredSearch)
          : false) ||
        (obj.second_type
          ? obj.second_type.toLowerCase().includes(loweredSearch)
          : false) ||
        (obj.third_type
          ? obj.third_type.toLowerCase().includes(loweredSearch)
          : false) ||
        (obj.fourth_type
          ? obj.fourth_type.toLowerCase().includes(loweredSearch)
          : false) ||
        (obj.fifth_type
          ? obj.fifth_type.toLowerCase().includes(loweredSearch)
          : false)
    );

    return (
      <div className="row justify-content-center plant-diseases-view">
        <div className="col-6">
          {fetching && <Loader />}
          <Card>
            <CardBody>
              <div className="mb-3">
                <Label className="font-18">
                  Search
                  <Input
                    value={search}
                    onChange={(event) =>
                      this.setState({ search: event.target.value })
                    }
                  />
                </Label>
              </div>
              {this.renderDiseasesTreeChildren(0, filteredDiseases, 'init')}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantDiseasesView);
