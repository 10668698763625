// eslint-disable-next-line import/prefer-default-export
export const supportedSections = [
  'placement',
  'plant_care',
  'dr_beam',
  'repotting',
  'propagation',
  'form_varieties',
  'pro_tips'
];
