import React, { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import TermsOfService, {
  createTermsOfService
} from 'modules/Policy/model/TermsOfService';
import { breadcrumbRouteTermsOfService } from 'modules/Policy/breadcrumbs';
import { fetchTOSDetails } from 'modules/Policy/repository';
import TermsOfServiceDetails from 'modules/Policy/component/Details/TermsOfService';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps {
  id: string;
}

export interface State {
  termsOfService: TermsOfService;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class PrivacyPolicyDetailsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      termsOfService: null
    };
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow } = this.props;

    managePage({
      title: 'Terms of service',
      breadcrumb: breadcrumbRouteTermsOfService(),
      type: 'loading'
    });

    try {
      const { data } = await fetchTOSDetails(id);

      const termsOfService = createTermsOfService(data.tos);

      this.setState({
        termsOfService
      });

      managePage({
        title: 'Terms of service',
        breadcrumb: breadcrumbRouteTermsOfService(termsOfService),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  render(): ReactNode {
    const { type } = this.props;
    const { termsOfService } = this.state;

    if (type || !termsOfService) {
      return null;
    }

    return (
      <div className="row justify-content-center">
        <div className="col-md-6">
          <TermsOfServiceDetails termsOfService={termsOfService} />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PrivacyPolicyDetailsView);
