import React, { FormEvent, ReactNode } from 'react';
import {
  Input,
  Label,
  Form,
  Button,
  FormGroup,
  FormFeedback
} from 'reactstrap';
import ImageCropUpload from 'modules/Layout/component/ImageCropUpload';
import { PageProps } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { breadcrumbRouteAddPlant } from 'modules/Plants/breadcrumbs';
import Validation from 'modules/Shared/exception/Validation';
import { createNewPlant, CreatePlantResponse } from 'modules/Plants/repository';
import { AxiosResponse } from 'axios';
import objectUrlToBase64 from 'modules/Shared/helper/image';
import Loader from 'modules/Layout/component/Loader';
import { ValidationPayload } from 'modules/Shared/type';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { getError, hasError } from 'modules/Shared/helper/validation';
import FamiliesAutocomplete from 'modules/Family/container/Autocomplete';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANTS, ROUTE_PLANTS_DETAILS } from 'modules/Plants/routes';
import {
  createPlantToastError,
  createPlantToastSuccess
} from 'modules/Plants/toasts';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
  managePage: (payload: PageProps) => ManagePageAction;
}

export type Props = DispatchProps & RouteComponentProps;

export interface State {
  scientific_name: string;
  image: string;
  family_id: number;
  fetching: boolean;
  errors?: ValidationPayload;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload))
});

class CreatePlantView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      scientific_name: '',
      image: null,
      fetching: false,
      family_id: null,
      errors: null
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(): void {
    const { managePage } = this.props;

    managePage({
      title: 'Plant - create',
      breadcrumb: breadcrumbRouteAddPlant()
    });
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { history, addToast } = this.props;
    const { scientific_name, image, family_id } = this.state;

    this.setState({
      fetching: true,
      errors: null
    });

    const imageBase64 = image ? await objectUrlToBase64(image) : null;

    try {
      const {
        data: {
          plant: { id }
        }
      }: AxiosResponse<CreatePlantResponse> = await createNewPlant({
        scientific_name,
        image: imageBase64,
        family_id
      });

      addToast(createPlantToastSuccess());

      history.push(getPathUrl(ROUTE_PLANTS_DETAILS, { id }));
    } catch (error) {
      addToast(createPlantToastError());

      if (error instanceof Validation) {
        this.setState({
          fetching: false,
          errors: error.getPayload()
        });
      } else {
        this.setState({
          fetching: false
        });
      }
    }
  }

  render(): ReactNode {
    const { history } = this.props;
    const { scientific_name, fetching, errors, family_id } = this.state;

    return (
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              {fetching && <Loader />}
              <Form onSubmit={this.onSubmit}>
                {errors && (
                  <ShowMessage
                    message={errors.message.value}
                    bsColor={errors.message.variant}
                  />
                )}
                <FormGroup>
                  <Label className="w-100">
                    Scientific name*:
                    <Input
                      type="text"
                      value={scientific_name}
                      required
                      onChange={(event) =>
                        this.setState({
                          scientific_name: event.currentTarget.value
                        })
                      }
                      invalid={
                        errors && hasError(errors.errors, 'scientific_name')
                      }
                    />
                    {errors && hasError(errors.errors, 'scientific_name') && (
                      <FormFeedback>
                        {getError(errors.errors, 'scientific_name')}
                      </FormFeedback>
                    )}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <div className="font-weight-bold w-100">
                    Genus:
                    <FamiliesAutocomplete
                      familyId={family_id}
                      onChange={(familyId) =>
                        this.setState({
                          family_id: familyId ? Number(familyId) : null
                        })
                      }
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="w-100 font-weight-bold">
                    Image:
                    <ImageCropUpload
                      onChange={(image) => this.setState({ image })}
                      error={errors ? getError(errors.errors, 'image') : null}
                    />
                  </div>
                </FormGroup>
                <div className="mt-4 d-flex justify-content-between">
                  <Button
                    type="button"
                    onClick={() => history.push(ROUTE_PLANTS)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="success">
                    Create
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(CreatePlantView)
);
