import { BreadcrumbItem } from 'modules/Layout/type';
import { ROUTE_TOOLS_WYSIWYG_HTML_PREVIEW } from 'modules/Tools/routes';

// eslint-disable-next-line import/prefer-default-export
export const breadcrumbRouteToolsWysiwygHtml = (): BreadcrumbItem[] => [
  {
    title: 'Tools - Wysiwyg/HTML preview',
    link: ROUTE_TOOLS_WYSIWYG_HTML_PREVIEW
  }
];
