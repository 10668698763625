import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import HealthCheckHelp from 'modules/PlantDiseases/model/HealthCheckHelp';

export interface PlantDiseasesListState extends SearchingProps {
  healthCheckHelp: HealthCheckHelp[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initPlantDiseasesListState = (): PlantDiseasesListState => ({
  healthCheckHelp: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
