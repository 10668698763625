import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';

export type DictionaryEntity = {
  id?: number;
  name?: string;
  langs?: {
    [lang: string]: {
      text: string;
    };
  };
  is_deleted?: boolean;
  deleted_at?: string;
  created_at?: string;
  updated_at?: string;
};

export default class Dictionary {
  readonly id?: number;
  name?: string;
  is_deleted?: boolean;
  deleted_at?: string;
  readonly updated_at?: string;
  readonly created_at?: string;
  readonly langs?: {
    [lang: string]: {
      text: string;
    };
  };

  constructor(entity?: DictionaryEntity) {
    this.id = entity?.id;
    this.name = entity?.name;
    this.langs = {};

    supportedLangs.forEach((lang) => {
      const existingLang = entity?.langs ? entity.langs[lang] : null;

      if (existingLang) {
        this.langs[lang] = existingLang;
      } else {
        this.langs[lang] = {
          text: ''
        };
      }
    });
    this.is_deleted = entity?.is_deleted;
    this.deleted_at = entity?.deleted_at;
    this.created_at = entity?.created_at;
    this.updated_at = entity?.updated_at;
  }

  getIsDeleted(): string {
    if (this.is_deleted) {
      return 'Yes';
    }

    return 'No';
  }

  static getFilterableAttributes(): string[] {
    return ['name', 'with_deleted'];
  }

  static getSortableAttributes(): string[] {
    return ['id', 'name'];
  }

  static getName(table: string): string {
    switch (table) {
      case 'dictionary_account_delete_reason': {
        return 'account delete reason';
      }

      case 'dictionary_plant_delete_reason': {
        return 'plant delete reason';
      }

      case 'dictionary_hub_delete_reason': {
        return 'hub delete reason';
      }

      case 'dictionary_sensor_delete_reason': {
        return 'sensor delete reason';
      }

      case 'dictionary_plant_status': {
        return 'plants status';
      }

      case 'dictionary_plants_knowledge': {
        return 'plants knowledge';
      }

      case 'dictionary_plants_owned': {
        return 'plants owned';
      }

      default:
        return null;
    }
  }
}

export const createDictionary = (entity?: DictionaryEntity): Dictionary =>
  new Dictionary(entity);
