import { RootState } from 'app/reducer';
//import ListingPagination from 'modules/Layout/component/Listing/Pagination';
import {
  PartialSearchingProps,
  PaginationParams,
  SearchParams,
  SortParams
} from 'modules/Shared/type';
import {
  setHubListParamsAction,
  SetHubListParamsAction
} from 'modules/Hub/action/list';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Pagination } from 'modules/Layout/component/Pagination/pagination';

export interface StateProps {
  pagination: PaginationParams;
  filter: SearchParams;
  sort: SortParams;
  currentCount: number;
  totalPages: number;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetHubListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { pagination, filter, sort, hubs, totalPages } = state.hub.list;

  return { pagination, filter, sort, currentCount: hubs.length, totalPages };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setHubListParamsAction(payload))
});

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(Pagination);
