import Loader from 'modules/Layout/component/Loader';
import { ColorVariants } from 'modules/Shared/type';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { ROUTE_LOGIN } from 'modules/Auth/routes';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { verifyEmail } from 'modules/Auth/repository';
import {
  verifyEmailToastError,
  verifyEmailToastSuccess
} from 'modules/Auth/toasts';
import { setAuthStateAction, SetAuthStateAction } from 'modules/Auth/action';
import { SettableAuthState } from 'modules/Auth/state';
import { getPathUrl } from 'modules/Shared/helper/api';
import ApiError from 'modules/Shared/exception/ApiError';
import { getError } from 'modules/Shared/helper/validation';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
  setAuthState: (payload: SettableAuthState) => SetAuthStateAction;
}

export interface Props extends DispatchProps, RouteComponentProps {
  token: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload)),
  setAuthState: (payload) => dispatch(setAuthStateAction(payload))
});

export class VerifyEmailView extends React.Component<Props> {
  async componentDidMount(): Promise<void> {
    const { token, managePage, addToast, setAuthState, history } = this.props;

    if (!token) {
      return;
    }

    managePage({ title: 'Verifying email...' });

    try {
      await verifyEmail({
        token
      });

      addToast(verifyEmailToastSuccess());

      setAuthState({
        message: {
          value: 'Email changed.',
          variant: ColorVariants.Success
        }
      });
    } catch (error) {
      addToast(verifyEmailToastError());

      if (error instanceof ApiError) {
        setAuthState({
          message: {
            value:
              getError(error.getErrors(), 'email') ||
              'Error occurred, email not changed.',
            variant: ColorVariants.Danger
          }
        });
      } else {
        setAuthState({
          message: {
            value: 'Error occurred, email not changed.',
            variant: ColorVariants.Danger
          }
        });
      }
    } finally {
      history.push(getPathUrl(ROUTE_LOGIN));
    }
  }

  render(): JSX.Element {
    const { token } = this.props;

    if (!token) {
      return <Redirect to={ROUTE_LOGIN} />;
    }

    return <Loader />;
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(VerifyEmailView)
);
