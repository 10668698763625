import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import {
  PartialSearchingProps,
  PaginationParams,
  SearchParams
} from 'modules/Shared/type';
import {
  SetPlantUserListParamsAction,
  setPlantUserListParamsAction
} from 'modules/PlantUser/action/list';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FilterInputType } from 'modules/Layout/type';
import PlantUser from 'modules/PlantUser/model/PlantUser';

export interface StateProps {
  filter: SearchParams;
  pagination: PaginationParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetPlantUserListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  values?: SearchParams;
}

export const mapState = (state: RootState): StateProps => {
  const { filter, pagination } = state.plantUser.list;

  return { filter, pagination };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPlantUserListParamsAction(payload))
});

export class PlantUserFilter extends React.Component<Props> {
  protected staticInputs: FilterInputType[];

  constructor(props: Props) {
    super(props);

    const { values = {} } = props;

    this.staticInputs = [
      {
        type: 'text',
        property: 'nickname',
        label: 'Nickname',
        value: _get(values, 'nickname')
      },
      {
        type: 'text',
        property: 'username',
        label: 'Owner username',
        value: _get(values, 'username')
      },
      {
        type: 'text',
        property: 'email',
        label: 'Owner email',
        value: _get(values, 'email')
      },
      {
        type: 'select',
        property: 'has_sensor',
        label: 'Connected sensor',
        value: _get(values, 'has_sensor'),
        options: [
          {
            value: '',
            label: ''
          },
          ...PlantUser.getPlantUserSensorStatusOptions()
        ]
      },
      {
        type: 'text',
        property: 'garden_name',
        label: 'Garden name',
        value: _get(values, 'garden_name')
      },
      {
        type: 'switch',
        property: 'with_deleted',
        label: 'Show all with deleted',
        value: _get(values, 'with_deleted')
      }
    ];
  }

  render(): ReactNode {
    const { filter, pagination, setParams } = this.props;

    return (
      <ListingFilter
        inputs={this.staticInputs}
        filter={filter}
        pagination={pagination}
        setParams={setParams}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantUserFilter);
