import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { PlantEntity } from 'modules/Plants/model/Plant';
import { Action } from 'redux';
import { PlantImportEntity } from 'modules/Plants/model/PlantImport';
import { HelpFindPlantEntity } from 'modules/Plants/model/HelpFindPlant';

export const SET_PLANT_LIST_PARAMS = 'PLANT/LIST/PARAMS/SET';
export const UPDATE_PLANT_LIST_PARAMS = 'PLANT/LIST/PARAMS/UPDATE';
export const FETCH_PLANT_LIST = 'PLANT/LIST/FETCH';
export const PLANT_LIST_FETCHED = 'PLANT/LIST/FETCHED';

export const SET_PLANT_IMPORTS_LIST_PARAMS = 'PLANT/IMPORTS/LIST/PARAMS/SET';
export const UPDATE_PLANT_IMPORTS_LIST_PARAMS =
  'PLANT/IMPORTS/LIST/PARAMS/UPDATE';
export const FETCH_PLANT_IMPORTS_LIST = 'PLANT/IMPORTS/LIST/FETCH';
export const PLANT_IMPORTS_LIST_FETCHED = 'PLANT/IMPORTS/LIST/FETCHED';

export const SET_HELP_FIND_PLANT_LIST_PARAMS =
  'PLANT/HELP_FIND/LIST/PARAMS/SET';
export const UPDATE_HELP_FIND_PLANT_LIST_PARAMS =
  'PLANT/HELP_FIND/LIST/PARAMS/UPDATE';
export const FETCH_HELP_FIND_PLANT_LIST = 'PLANT/HELP_FIND/LIST/FETCH';
export const HELP_FIND_PLANT_LIST_FETCHED = 'PLANT/HELP_FIND/LIST/FETCHED';
export const HELP_FIND_PLANT_LIST_SET_RESPONDED =
  'PLANT/HELP_FIND/LIST/SET_RESPONDED';
export const HELP_FIND_PLANT_LIST_RESPONDED = 'PLANT/HELP_FIND/LIST/RESPONDED';

export type PlantListAction =
  | SetPlantListParamsAction
  | FetchPlantListAction
  | PlantListFetchedAction
  | SetPlantImportsListParamsAction
  | FetchPlantImportsListAction
  | PlantImportsListFetchedAction
  | SetHelpFindPlantListParamsAction
  | FetchHelpFindPlantListAction
  | HelpFindPlantFetchedAction
  | HelpFindPlantListSetRespondedAction
  | HelpFindPlantListRespondedAction;

export type SetPlantListParamsAction = SetSearchingParamsAction<
  typeof SET_PLANT_LIST_PARAMS
>;

export const setPlantListParamsAction = (
  payload: PartialSearchingProps
): SetPlantListParamsAction =>
  setSearchingParamsAction(SET_PLANT_LIST_PARAMS, payload);

export type FetchPlantListAction = Action<typeof FETCH_PLANT_LIST>;

export const fetchPlantListAction = (): FetchPlantListAction => ({
  type: FETCH_PLANT_LIST
});

export type PlantListFetchedPayload = {
  plants: PlantEntity[];
  totalPages: number;
};

export interface PlantListFetchedAction
  extends Action<typeof PLANT_LIST_FETCHED> {
  payload: PlantListFetchedPayload;
}

export const plantListFetchedAction = (
  payload: PlantListFetchedPayload
): PlantListFetchedAction => ({
  type: PLANT_LIST_FETCHED,
  payload
});

export type SetPlantImportsListParamsAction = SetSearchingParamsAction<
  typeof SET_PLANT_IMPORTS_LIST_PARAMS
>;

export const setPlantImportsListParamsAction = (
  payload: PartialSearchingProps
): SetPlantImportsListParamsAction =>
  setSearchingParamsAction(SET_PLANT_IMPORTS_LIST_PARAMS, payload);

export type FetchPlantImportsListAction = Action<
  typeof FETCH_PLANT_IMPORTS_LIST
>;

export const fetchPlantImportsListAction = (): FetchPlantImportsListAction => ({
  type: FETCH_PLANT_IMPORTS_LIST
});

export type PlantImportsListFetchedPayload = {
  plantImports: PlantImportEntity[];
  totalPages: number;
};

export interface PlantImportsListFetchedAction
  extends Action<typeof PLANT_IMPORTS_LIST_FETCHED> {
  payload: PlantImportsListFetchedPayload;
}

export const plantImportsListFetchedAction = (
  payload: PlantImportsListFetchedPayload
): PlantImportsListFetchedAction => ({
  type: PLANT_IMPORTS_LIST_FETCHED,
  payload
});

export type SetHelpFindPlantListParamsAction = SetSearchingParamsAction<
  typeof SET_HELP_FIND_PLANT_LIST_PARAMS
>;

export const setHelpFindPlantListParamsAction = (
  payload: PartialSearchingProps
): SetHelpFindPlantListParamsAction =>
  setSearchingParamsAction(SET_HELP_FIND_PLANT_LIST_PARAMS, payload);

export type FetchHelpFindPlantListAction = Action<
  typeof FETCH_HELP_FIND_PLANT_LIST
>;

export const fetchHelpFindPlantListAction =
  (): FetchHelpFindPlantListAction => ({
    type: FETCH_HELP_FIND_PLANT_LIST
  });

export type HelpFindPlantListFetchedPayload = {
  helpFindPlant: HelpFindPlantEntity[];
  totalPages: number;
};

export interface HelpFindPlantFetchedAction
  extends Action<typeof HELP_FIND_PLANT_LIST_FETCHED> {
  payload: HelpFindPlantListFetchedPayload;
}

export const helpFindPlantListFetchedAction = (
  payload: HelpFindPlantListFetchedPayload
): HelpFindPlantFetchedAction => ({
  type: HELP_FIND_PLANT_LIST_FETCHED,
  payload
});

export interface HelpFindPlantListRespondedPayload {
  responded: boolean;
  plantId: number;
}

export interface HelpFindPlantListRespondedAction
  extends Action<typeof HELP_FIND_PLANT_LIST_RESPONDED> {
  payload: HelpFindPlantListRespondedPayload;
}

export const helpFindPlantListRespondedAction = (
  payload: HelpFindPlantListRespondedPayload
): HelpFindPlantListRespondedAction => ({
  type: HELP_FIND_PLANT_LIST_RESPONDED,
  payload
});

export interface HelpFindPlantListSetRespondedPayload {
  responded: boolean;
  plantId: number;
}

export interface HelpFindPlantListSetRespondedAction
  extends Action<typeof HELP_FIND_PLANT_LIST_SET_RESPONDED> {
  payload: HelpFindPlantListSetRespondedPayload;
}

export const helpFindPlantListSetRespondedAction = (
  payload: HelpFindPlantListSetRespondedPayload
): HelpFindPlantListSetRespondedAction => ({
  type: HELP_FIND_PLANT_LIST_SET_RESPONDED,
  payload
});
