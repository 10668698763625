import { BreadcrumbItem } from 'modules/Layout/type';
import Dictionary from 'modules/Dictionary/model/Dictionary';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_DICTIONARY_DETAILS } from 'modules/Dictionary/routes';

// eslint-disable-next-line import/prefer-default-export
export const breadcrumbRouteDictionaryList = (
  table: string
): BreadcrumbItem[] => [
  {
    title: `Dictionaries ${Dictionary.getName(table)}`,
    link: `/${getPathUrl(table.replace(/_/g, '-'))}`
  }
];

export const breadcrumbRouteDictionaryDetails = (
  table: string,
  dictionary?: Dictionary
): BreadcrumbItem[] => [
  ...breadcrumbRouteDictionaryList(table),
  {
    title: dictionary?.name || 'Details',
    link: dictionary
      ? getPathUrl(ROUTE_DICTIONARY_DETAILS, { id: dictionary.id }, { table })
      : null
  }
];

export const breadcrumbRouteDictionaryAdd = (
  table: string
): BreadcrumbItem[] => [
  ...breadcrumbRouteDictionaryList(table),
  {
    title: 'Add position'
  }
];

export const breadcrumbRouteDictionaryUpdate = (
  table: string,
  dictionary?: Dictionary
): BreadcrumbItem[] => [
  ...breadcrumbRouteDictionaryDetails(table, dictionary),
  {
    title: 'Edit'
  }
];
