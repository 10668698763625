import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { UserEntity } from 'modules/User/model/User';
import { Action } from 'redux';
import { PrivacyPolicyEntity } from 'modules/Policy/model/PrivacyPolicy';

export const SET_TOS_LIST_PARAMS = 'TOS/LIST/PARAMS/SET';
export const FETCH_TOS_LIST = 'TOS/LIST/FETCH';
export const TOS_LIST_FETCHED = 'TOS/LIST/FETCHED';
export const SET_PP_LIST_PARAMS = 'PP/LIST/PARAMS/SET';
export const FETCH_PP_LIST = 'PP/LIST/FETCH';
export const PP_LIST_FETCHED = 'PP/LIST/FETCHED';

export type PolicyListAction =
  | SetTOSListParamsAction
  | FetchTOSListAction
  | TOSListFetchedAction
  | SetPPListParamsAction
  | FetchPPListAction
  | PPListFetchedAction;

export type SetTOSListParamsAction = SetSearchingParamsAction<
  typeof SET_TOS_LIST_PARAMS
>;

export const setTOSListParamsAction = (
  payload: PartialSearchingProps
): SetTOSListParamsAction =>
  setSearchingParamsAction(SET_TOS_LIST_PARAMS, payload);

export type FetchTOSListAction = Action<typeof FETCH_TOS_LIST>;

export const fetchTOSListAction = (): FetchTOSListAction => ({
  type: FETCH_TOS_LIST
});

export type TOSListFetchedPayload = {
  termsOfServices: UserEntity[];
  totalPages: number;
};

export interface TOSListFetchedAction extends Action<typeof TOS_LIST_FETCHED> {
  payload: TOSListFetchedPayload;
}

export const tosListFetchedAction = (
  payload: TOSListFetchedPayload
): TOSListFetchedAction => ({
  type: TOS_LIST_FETCHED,
  payload
});

export type SetPPListParamsAction = SetSearchingParamsAction<
  typeof SET_PP_LIST_PARAMS
>;

export const setPPListParamsAction = (
  payload: PartialSearchingProps
): SetPPListParamsAction =>
  setSearchingParamsAction(SET_PP_LIST_PARAMS, payload);

export type FetchPPListAction = Action<typeof FETCH_PP_LIST>;

export const fetchPPListAction = (): FetchPPListAction => ({
  type: FETCH_PP_LIST
});

export type PPListFetchedPayload = {
  pp: PrivacyPolicyEntity[];
  totalPages: number;
};

export interface PPListFetchedAction extends Action<typeof PP_LIST_FETCHED> {
  payload: PPListFetchedPayload;
}

export const ppListFetchedAction = (
  payload: PPListFetchedPayload
): PPListFetchedAction => ({
  type: PP_LIST_FETCHED,
  payload
});
