import * as H from 'history';

import React from 'react';
import User from 'modules/User/model/User';
import {
  ROUTE_HELP_FIND_PLANT,
  ROUTE_PH_RESULTS_LIST,
  ROUTE_PLANT_IMPORTS,
  ROUTE_PLANTS
} from 'modules/Plants/routes';
import { ROUTE_DASHBOARD } from 'modules/Layout/routes';
import { ROUTE_PLANT_GROUPS } from 'modules/PlantGroups/routes';
import { ROUTE_FAMILY } from 'modules/Family/routes';
import { ROUTE_HUB_SOFTWARES, ROUTE_HUBS } from 'modules/Hub/routes';
import {
  ROUTE_SENSOR_LIST,
  ROUTE_SENSOR_SOFTWARES
} from 'modules/Sensor/routes';
import {
  ROUTE_ADMINISTRATORS,
  ROUTE_USERS,
  ROUTE_USERS_FEEDBACK
} from 'modules/User/routes';
import { ROUTE_PLANT_USER } from 'modules/PlantUser/routes';
import { ROUTE_GARDEN } from 'modules/Garden/routes';
import { ROUTE_NEWS, ROUTE_PLANTS_NEWS_ORDER } from 'modules/News/routes';
import { ROUTE_MEDIA, ROUTE_MEDIA_ADVANCEMENT } from 'modules/Media/routes';
import { ROUTE_MISSING_FAMILY } from 'modules/MissingFamily/routes';
import {
  ROUTE_DICTIONARY_ACCOUNT_DELETE_REASON_LIST,
  ROUTE_DICTIONARY_HUB_DELETE_REASON_LIST,
  ROUTE_DICTIONARY_PLANT_DELETE_REASON_LIST,
  ROUTE_DICTIONARY_PLANT_STATUS_LIST,
  ROUTE_DICTIONARY_PLANTS_KNOWlEDGE_LIST,
  ROUTE_DICTIONARY_PLANTS_OWNED_LIST,
  ROUTE_DICTIONARY_SENSOR_DELETE_REASON_LIST
} from 'modules/Dictionary/routes';
import { ROUTE_TOOLS_WYSIWYG_HTML_PREVIEW } from 'modules/Tools/routes';
import { ROUTE_PP_LIST, ROUTE_TOS_LIST } from 'modules/Policy/routes';
import {
  ROUTE_HEALTH_CHECK_HELP,
  ROUTE_PLANT_DISEASES
} from 'modules/PlantDiseases/routes';
import { ROUTE_FAQ_LIST } from 'modules/Faq/routes';

export type MenuKey = string;

export interface MenuItem<T extends string> {
  key: MenuKey;
  type: T;
  title: React.ReactNode;
  icon?: string;
  hasAccess?: (user: User) => boolean;
}

export interface MenuLink extends MenuItem<'link'> {
  href: string;
}

export interface MenuNested extends MenuItem<'nested'> {
  href: string;
  children: Menu[];
  navLevel: 'second' | 'third';
  isActive?: (_match: null, location: H.Location) => boolean;
}

export type MenuSection = MenuItem<'section'>;

export type Menu = MenuLink | MenuNested | MenuSection;

export const MenuItems: Menu[] = [
  {
    key: 'dashboard',
    type: 'link',
    title: 'Dashboard',
    icon: 'fas fa-table',
    href: ROUTE_DASHBOARD
  },
  {
    key: 'plant-library',
    href: '/plant',
    type: 'nested',
    title: 'Plant library',
    icon: 'fab fa-pagelines',
    navLevel: 'second',
    isActive: (_match: null, location: H.Location): boolean =>
      [
        ROUTE_PLANTS,
        ROUTE_PLANT_IMPORTS,
        ROUTE_FAMILY,
        ROUTE_PLANT_GROUPS,
        ROUTE_PLANT_DISEASES
      ].includes(location.pathname),
    children: [
      {
        key: 'plants',
        type: 'link',
        title: 'Plants',
        icon: 'fas fa-seedling',
        href: ROUTE_PLANTS
      },
      {
        key: 'plant-imports',
        type: 'link',
        title: 'Plant imports',
        icon: 'fas fa-file-import',
        href: ROUTE_PLANT_IMPORTS
      },
      {
        key: 'family',
        type: 'link',
        title: 'Families',
        icon: 'fas fa-spa',
        href: ROUTE_FAMILY
      },
      {
        key: 'PlantGroups',
        type: 'link',
        title: 'Plant groups',
        icon: 'fas fa-layer-group',
        href: ROUTE_PLANT_GROUPS
      },
      {
        key: 'ph-results',
        type: 'link',
        title: 'pH results',
        icon: 'fas fa-poll',
        href: ROUTE_PH_RESULTS_LIST
      },
      {
        key: 'plant-diseases',
        type: 'link',
        title: 'Plant diseases',
        icon: 'fas fa-allergies',
        href: ROUTE_PLANT_DISEASES
      }
    ]
  },
  {
    key: 'ota',
    title: 'OTA',
    type: 'nested',
    navLevel: 'second',
    href: '/ota',
    icon: 'fab fa-cloudversify',
    isActive: (_match: null, location: H.Location): boolean =>
      [ROUTE_HUB_SOFTWARES, ROUTE_SENSOR_SOFTWARES].includes(location.pathname),
    children: [
      {
        key: 'hub-softwares',
        type: 'link',
        title: 'Hub software',
        icon: 'fas fa-cloud-upload-alt',
        href: ROUTE_HUB_SOFTWARES
      },
      {
        key: 'sensor-softwares',
        type: 'link',
        title: 'Sensor software',
        icon: 'fas fa-cloud-upload-alt',
        href: ROUTE_SENSOR_SOFTWARES
      }
    ]
  },
  {
    key: 'user-data',
    title: 'User data',
    type: 'nested',
    navLevel: 'second',
    href: '/user-data',
    icon: 'fas fa-street-view',
    isActive: (_match: null, location: H.Location): boolean =>
      [
        ROUTE_USERS,
        ROUTE_PLANT_USER,
        ROUTE_GARDEN,
        ROUTE_HUBS,
        ROUTE_SENSOR_LIST
      ].includes(location.pathname),
    children: [
      {
        key: 'users',
        type: 'link',
        title: 'Users',
        icon: 'fas fa-users',
        href: ROUTE_USERS
      },
      {
        key: 'user-plant',
        type: 'link',
        title: 'User plant',
        icon: 'fas fa-seedling',
        href: ROUTE_PLANT_USER
      },
      {
        key: 'gardens',
        type: 'link',
        title: 'Gardens',
        icon: 'fas fa-tree',
        href: ROUTE_GARDEN
      },
      {
        key: 'hubs',
        type: 'link',
        title: 'Hubs',
        icon: 'fas fa-boxes',
        href: ROUTE_HUBS
      },
      {
        key: 'sensor-list',
        type: 'link',
        title: 'Sensor',
        icon: 'fas fa-thermometer',
        href: ROUTE_SENSOR_LIST
      }
    ]
  },
  {
    key: 'news',
    type: 'link',
    title: 'News',
    icon: 'fas fa-rss',
    href: ROUTE_NEWS
  },
  {
    key: 'plants-news',
    type: 'link',
    title: 'Plants on home screen',
    icon: 'fas fa-seedling',
    href: ROUTE_PLANTS_NEWS_ORDER
  },
  {
    key: 'media',
    type: 'link',
    title: 'Media',
    icon: 'fab fa-medium',
    href: ROUTE_MEDIA
  },
  {
    key: 'media-advancement',
    type: 'link',
    title: 'Media advancement',
    icon: 'fas fa-level-up-alt',
    href: ROUTE_MEDIA_ADVANCEMENT
  },
  {
    key: 'users-feedback-list',
    type: 'link',
    title: 'Users feedback',
    icon: 'fas fa-question-circle',
    href: ROUTE_USERS_FEEDBACK
  },
  {
    key: 'help-find-plant',
    type: 'link',
    title: 'Help find plant',
    icon: 'fas fa-question-circle',
    href: ROUTE_HELP_FIND_PLANT
  },
  {
    key: 'health-check-help',
    type: 'link',
    title: 'Health check help',
    icon: 'fas fa-question-circle',
    href: ROUTE_HEALTH_CHECK_HELP
  },
  {
    key: 'missing-family',
    type: 'link',
    title: 'Missing family',
    icon: 'fas fa-search-minus',
    href: ROUTE_MISSING_FAMILY
  },
  {
    key: 'faq',
    type: 'link',
    title: 'FAQ',
    icon: 'fa fa-list',
    href: ROUTE_FAQ_LIST
  },
  {
    key: 'terms-of-service',
    type: 'link',
    title: 'Terms of service',
    icon: 'fas fa-list-ol',
    href: ROUTE_TOS_LIST
  },
  {
    key: 'privacy-policy',
    type: 'link',
    title: 'Privacy policy',
    icon: 'fas fa-stream',
    href: ROUTE_PP_LIST
  },
  {
    key: 'administrators',
    type: 'link',
    title: 'Administrators',
    icon: 'fas fa-user-shield',
    href: ROUTE_ADMINISTRATORS
  },
  {
    key: 'dictionaries',
    type: 'nested',
    title: 'Dictionaries',
    href: '',
    icon: 'fas fa-atlas',
    navLevel: 'second',
    isActive: (_match: null, location: H.Location): boolean =>
      location.pathname.includes('dictionary'),
    children: [
      {
        key: 'dictionary-account-delete-reason',
        type: 'link',
        title: 'Account delete reason',
        icon: 'fas fa-user-alt-slash',
        href: ROUTE_DICTIONARY_ACCOUNT_DELETE_REASON_LIST
      },
      {
        key: 'dictionary-plant-delete-reason',
        type: 'link',
        title: 'Plant delete reason',
        icon: 'fas fa-seedling',
        href: ROUTE_DICTIONARY_PLANT_DELETE_REASON_LIST
      },
      {
        key: 'dictionary-hub-delete-reason',
        type: 'link',
        title: 'Hub delete reason',
        icon: 'fas fa-boxes',
        href: ROUTE_DICTIONARY_HUB_DELETE_REASON_LIST
      },
      {
        key: 'dictionary-sensor-delete-reason',
        type: 'link',
        title: 'Sensor delete reason',
        icon: 'fas fa-thermometer',
        href: ROUTE_DICTIONARY_SENSOR_DELETE_REASON_LIST
      },
      {
        key: 'dictionary-plants-status',
        type: 'link',
        title: 'Plants status',
        icon: 'fas fa-thermometer-three-quarters',
        href: ROUTE_DICTIONARY_PLANT_STATUS_LIST
      },
      {
        key: 'dictionary-plants-knowledge',
        type: 'link',
        title: 'Plants knowledge',
        icon: 'fas fa-brain',
        href: ROUTE_DICTIONARY_PLANTS_KNOWlEDGE_LIST
      },
      {
        key: 'dictionary-plants-owned',
        type: 'link',
        title: 'Plants owned',
        icon: 'fas fa-sort-numeric-down',
        href: ROUTE_DICTIONARY_PLANTS_OWNED_LIST
      }
    ]
  },
  {
    key: 'tools',
    type: 'nested',
    title: 'Tools',
    href: '',
    icon: 'fas fa-tools',
    navLevel: 'second',
    isActive: (_match: null, location: H.Location): boolean =>
      location.pathname.includes('tools'),
    children: [
      {
        key: 'tools-wysiwyg-html-preview',
        type: 'link',
        title: 'Wysiwyg/HTML preview',
        icon: 'fab fa-html5',
        href: ROUTE_TOOLS_WYSIWYG_HTML_PREVIEW
      }
    ]
  }
];
