import {
  initPolicyListState,
  PolicyListState
} from 'modules/Policy/state/list';
import {
  FETCH_PP_LIST,
  FETCH_TOS_LIST,
  PolicyListAction,
  PP_LIST_FETCHED,
  SET_PP_LIST_PARAMS,
  SET_TOS_LIST_PARAMS,
  TOS_LIST_FETCHED
} from 'modules/Policy/action/list';
import { createTermsOfService } from 'modules/Policy/model/TermsOfService';
import { createPrivacyPolicy } from 'modules/Policy/model/PrivacyPolicy';

const initState = initPolicyListState();

const reducer = (
  state: PolicyListState = initState,
  action: PolicyListAction
): PolicyListState => {
  switch (action.type) {
    case SET_TOS_LIST_PARAMS:
    case SET_PP_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_TOS_LIST:
    case FETCH_PP_LIST:
      return { ...state, fetching: true };

    case TOS_LIST_FETCHED: {
      const { termsOfServices = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        termsOfServices: termsOfServices.map(createTermsOfService),
        totalPages
      };
    }

    case PP_LIST_FETCHED: {
      const { pp = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        pp: pp.map(createPrivacyPolicy),
        totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
