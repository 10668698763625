import { PageProps } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import React, { useEffect, useState } from 'react';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { breadcrumbRouteFaqUpdate } from 'modules/Faq/view/breadcrumbs';
import { connect } from 'react-redux';
import Wysiwyg from 'modules/Layout/component/Wysiwyg';
import { EditorState } from 'draft-js';
import { Button, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ROUTE_FAQ_LIST } from 'modules/Faq/routes';
import Loader from 'modules/Layout/component/Loader';
import { updateFaqToastError, updateFaqToastSuccess } from 'modules/Faq/toasts';
import {
  mapEditorStateToHtml,
  mapHtmlToEditorState
} from 'modules/Layout/helper/wysiwyg';
import { fetchFaq, updateFaq, UpdateFaqLang } from 'modules/Faq/repository';
import { createFaq, Faq } from 'modules/Faq/model/FAQ';
import { faqToolbar } from 'modules/Layout/component/Wysiwyg/config';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  id: number | string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload))
});

const useFaqUpdate = (props: Props) => {
  const { managePage, addToast, id } = props;
  const [fetching, setFetching] = useState(false);
  const [content, setContent] = useState<EditorState[]>(
    new Array(supportedLangs.length)
  );
  const [faq, setFaq] = useState<Faq>(null);
  const fetch = async () => {
    try {
      const {
        data: { faq: fetchedFaq }
      } = await fetchFaq(Number(id));
      console.log(fetchedFaq);
      const { lang: fetchedLang } = fetchedFaq;
      const newContents = supportedLangs.map((lang) => {
        const foundLang = fetchedLang.find((fetched) => fetched.lang === lang);
        return mapHtmlToEditorState(foundLang?.text || '');
      });
      setContent(newContents);
      setFaq(createFaq(fetchedFaq));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    managePage({
      title: 'FAQ - Update',
      breadcrumb: breadcrumbRouteFaqUpdate()
    });
    fetch();
  }, []);
  const [langs, setLangs] = useState(
    supportedLangs.map((_, index) => index === 0)
  );
  const currentLangIndex = langs.findIndex((lang) => lang);
  const onChange = (editorState: EditorState) => {
    content[currentLangIndex] = editorState;
    setContent([...content]);
  };
  const update = async () => {
    try {
      setFetching(true);
      const payload: UpdateFaqLang = {};
      supportedLangs.forEach((lang, index) => {
        console.log(mapEditorStateToHtml(content[index]));
        payload[lang] = {
          text: mapEditorStateToHtml(content[index])
        };
      });
      await updateFaq(faq.id, payload);
      addToast(updateFaqToastSuccess());
    } catch (error) {
      console.log(error);
      addToast(updateFaqToastError());
    } finally {
      setFetching(false);
    }
  };

  return {
    currentLangIndex,
    setLangs,
    langs,
    content,
    setContent,
    onChange,
    update,
    fetching,
    faq
  };
};

const FaqUpdateView = (props: Props): JSX.Element => {
  const {
    langs,
    setLangs,
    onChange,
    currentLangIndex,
    content,
    update,
    fetching,
    faq
  } = useFaqUpdate(props);

  if (!faq) {
    return <Loader />;
  }

  return (
    <div className="row justify-content-center faq-update-view">
      <div className="col-12 col-lg-6">
        <div className="card">
          <div className="card-body position-relative">
            {fetching && <Loader />}
            <h2 className="text-center">{`${faq.getFaqTypeName()} FAQ`}</h2>
            <DisplayNav
              booleanArr={langs}
              setBooleanArr={setLangs}
              valueArr={supportedLangs}
            />
            <FormGroup>
              <Wysiwyg
                key={currentLangIndex}
                onChange={onChange}
                toolbar={faqToolbar}
                initialEditorState={content[currentLangIndex]}
              />
            </FormGroup>
            <div className="d-flex justify-content-between">
              <Link to={ROUTE_FAQ_LIST} className="btn btn-primary">
                Cancel
              </Link>
              <Button onClick={update} color="success">
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect<null, DispatchProps>(null, mapDispatch)(FaqUpdateView);
