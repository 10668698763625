import React, { ReactNode } from 'react';
import { SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteAddFamily } from 'modules/Family/breadcrumbs';
import { connect } from 'react-redux';
import { CreateNewPage } from 'modules/Layout/view/AddNewPageTemplate';
import { fetchAddFamily } from 'modules/Family/repository';
import { inputConfigType } from 'modules/Layout/view/InputComponent';
import { refactorData } from 'modules/Family/view/Add/helper/refactorData';
import { ROUTE_FAMILY_DETAILS_REDIRECT_TEMPLATE } from 'modules/Family/routes';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
}

export type Props = DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload))
});

export class FamilyAddView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    const { managePage } = this.props;

    managePage({
      title: 'Family - add',
      breadcrumb: breadcrumbRouteAddFamily()
    });
  }

  render(): ReactNode {
    const newPageInputs: inputConfigType[] = [
      {
        label: 'Genus',
        type: 'text',
        name: 'genus'
      }
    ];

    return (
      <div className="listing-wrapper">
        <CreateNewPage
          inputsObj={newPageInputs}
          onSubmit={fetchAddFamily}
          dataFormat={refactorData}
          successUrl={ROUTE_FAMILY_DETAILS_REDIRECT_TEMPLATE}
          dataNameInResponse="family"
        />
      </div>
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch)(FamilyAddView);
