import React from 'react';
import { FamilyDetailsPlantsTable } from 'modules/Family/container/Table/familyDetailsPlantsTable';

export const DetailsPlantList = () => {
  return (
    <div className="content position-relative restrict-width">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body p-3">
              <div className="listing-wrapper">
                <div className="table-wrapper position-relative">
                  <FamilyDetailsPlantsTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
