import React, { FormEvent, ReactNode } from 'react';
import {
  Input,
  Label,
  Form,
  Button,
  FormGroup,
  FormFeedback,
  CustomInput,
  FormText
} from 'reactstrap';
import ImageCropUpload from 'modules/Layout/component/ImageCropUpload';
import { PageProps, PageType } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Validation from 'modules/Shared/exception/Validation';
import { AxiosResponse } from 'axios';
import objectUrlToBase64 from 'modules/Shared/helper/image';
import Loader from 'modules/Layout/component/Loader';
import { ValidationPayload } from 'modules/Shared/type';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { getPathUrl } from 'modules/Shared/helper/api';
import { acceptableNewsTypesValues } from 'modules/News/model/News';
import { manageThrow, ManageThrowAction } from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import {
  AttachMediaAsKnowHowPayload,
  fetchAddMedia,
  FetchAddMediaResponse,
  fetchMediaAdvancementLevel,
  fetchMediaDetails,
  FetchMediaDetailsResponse,
  FetchMediaRequest,
  updateMediaDetails
} from 'modules/Media/repository';
import {
  breadcrumbRouteAddMedia,
  breadcrumbRouteFormDetailsMedia
} from 'modules/Media/breadcrumbs';
import {
  createMediaToastError,
  createMediaToastSuccess,
  updateMediaToastError,
  updateMediaToastSuccess
} from 'modules/Media/toasts';
import { ROUTE_MEDIA, ROUTE_MEDIA_DETAILS } from 'modules/Media/routes';
import { MediaAdvancementEntity } from 'modules/Media/model/MediaAdvancement';
import AttachToAsKnowHowFieldset, {
  AttachToMeasurementsValues
} from '../../component/Fieldset/AttachToAsKnowHow';
import Plant from '../../../Plants/model/Plant';
import PlantGroups from '../../../PlantGroups/model/PlantGroups';
import Family from '../../../Family/model/Family';

export interface StateProps {
  pageType: PageType;
}

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrowSaga: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps, RouteComponentProps {
  id?: string | number;
}

export interface State {
  title: string;
  type: string;
  url: string;
  image: string;
  advancement_level_id: number;
  attachToPlants: Plant[];
  attachToPlantGroups: PlantGroups[];
  attachToFamilies: Family[];
  attachToMeasurements: AttachToMeasurementsValues;
  fetching: boolean;
  errors?: ValidationPayload;
  media_advancement_level: MediaAdvancementEntity[];
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload)),
  manageThrowSaga: (error) => dispatch(manageThrow(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return {
    pageType: type
  };
};

class CreateUpdateMediaView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const statuses = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false
    };

    this.state = {
      media_advancement_level: [],
      title: null,
      type: acceptableNewsTypesValues[0],
      url: null,
      advancement_level_id: null,
      image: null,
      attachToFamilies: [],
      attachToPlantGroups: [],
      attachToPlants: [],
      attachToMeasurements: {
        temperature: { ...statuses },
        light: { ...statuses },
        soil_moisture: { ...statuses },
        soil_fertility: { ...statuses }
      },
      fetching: false,
      errors: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.fetchNews = this.fetchNews.bind(this);
    this.fetchAdvancementLevels = this.fetchAdvancementLevels.bind(this);
  }

  async fetchNews(): Promise<string> {
    const { id, manageThrowSaga } = this.props;

    try {
      const {
        data: { media }
      }: AxiosResponse<FetchMediaDetailsResponse> = await fetchMediaDetails(
        Number(id)
      );

      this.setState({
        title: media.title,
        advancement_level_id: media.advancement_level_id,
        type: media.type,
        url: media.url,
        image: media.image
      });

      return media.title;
    } catch (error) {
      manageThrowSaga(error);
    }

    return '';
  }

  async fetchAdvancementLevels(): Promise<void> {
    const {
      data: { content }
    } = await fetchMediaAdvancementLevel({
      pagination: {
        page: 1,
        count: 100
      } as unknown as any
    });

    const defaultValue = content[0]?.id || null;

    this.setState({
      media_advancement_level: content,
      advancement_level_id: Number(defaultValue)
    });
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id } = this.props;

    if (id) {
      managePage({
        title: 'Media - edit',
        type: 'loading',
        breadcrumb: breadcrumbRouteFormDetailsMedia(id)
      });
    } else {
      managePage({
        title: 'Media - create',
        type: 'loading',
        breadcrumb: breadcrumbRouteAddMedia()
      });
    }

    await this.fetchAdvancementLevels();

    if (id) {
      const title = await this.fetchNews();

      managePage({
        title: `${title} - edit`,
        breadcrumb: breadcrumbRouteFormDetailsMedia(id, title)
      });
    } else {
      managePage({
        title: 'Media - create',
        breadcrumb: breadcrumbRouteAddMedia()
      });
    }
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { history, addToast, id } = this.props;
    const {
      title,
      type,
      url,
      image,
      advancement_level_id,
      attachToMeasurements,
      attachToPlants,
      attachToPlantGroups,
      attachToFamilies
    } = this.state;

    this.setState({
      fetching: true,
      errors: null
    });

    const imageBase64 =
      image && image.includes('blob') ? await objectUrlToBase64(image) : null;

    let redirectId = id;

    const payload = {
      image: imageBase64,
      advancement_level_id,
      title,
      type,
      url
    };

    try {
      if (id) {
        await updateMediaDetails(id, payload);

        addToast(updateMediaToastSuccess());
      } else {
        const addPayload: FetchMediaRequest & AttachMediaAsKnowHowPayload = {
          ...payload,
          plants_know_how: attachToPlants.map((plant) => plant.id),
          plant_groups_know_how: attachToPlantGroups.map((group) => group.id),
          families_know_how: attachToFamilies.map((group) => group.id),
          know_how_measurements: []
        };

        Object.keys(attachToMeasurements).forEach((measurement) => {
          Object.keys(measurement).forEach((status) => {
            if (attachToMeasurements[measurement][status]) {
              addPayload.know_how_measurements.push({
                measurement,
                measurement_status: Number(status)
              });
            }
          });
        });

        const {
          data: {
            media: { id: newId }
          }
        }: AxiosResponse<FetchAddMediaResponse> = await fetchAddMedia(
          addPayload
        );

        redirectId = newId;

        addToast(createMediaToastSuccess());
      }

      history.push(getPathUrl(ROUTE_MEDIA_DETAILS, { id: redirectId }));
    } catch (error) {
      if (id) {
        addToast(updateMediaToastError());
      } else {
        addToast(createMediaToastError());
      }

      if (error instanceof Validation) {
        this.setState({
          fetching: false,
          errors: error.getPayload()
        });
      } else {
        this.setState({
          fetching: false
        });
      }
    }
  }

  render(): ReactNode {
    const { history, id, pageType } = this.props;
    const {
      title,
      fetching,
      errors,
      advancement_level_id,
      url,
      type,
      image,
      media_advancement_level,
      attachToPlants,
      attachToPlantGroups,
      attachToFamilies,
      attachToMeasurements
    } = this.state;

    if (pageType) {
      return null;
    }

    return (
      <div className="row justify-content-center">
        <div className={id ? 'col-md-4' : 'col-md-8'}>
          <div className="card">
            <div className="card-body">
              {fetching && <Loader />}
              <Form onSubmit={this.onSubmit}>
                {errors && (
                  <ShowMessage
                    message={errors.message.value}
                    bsColor={errors.message.variant}
                  />
                )}
                <FormGroup>
                  <Label className="w-100">
                    Title*:
                    <Input
                      type="text"
                      value={title || ''}
                      required
                      onChange={(event) =>
                        this.setState({
                          title: event.currentTarget.value
                        })
                      }
                      invalid={errors && hasError(errors.errors, 'title')}
                    />
                    {errors && hasError(errors.errors, 'title') && (
                      <FormFeedback>
                        {getError(errors.errors, 'title')}
                      </FormFeedback>
                    )}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="w-100">
                    Type*:
                    <CustomInput
                      id="type"
                      type="select"
                      value={type || ''}
                      onChange={(event) =>
                        this.setState({ type: event.currentTarget.value })
                      }
                      required
                      invalid={errors && hasError(errors.errors, 'type')}
                    >
                      {acceptableNewsTypesValues.map((typeOption) => (
                        <option key={typeOption} value={typeOption}>
                          {typeOption}
                        </option>
                      ))}
                    </CustomInput>
                    {errors && hasError(errors.errors, 'type') && (
                      <FormFeedback>
                        {getError(errors.errors, 'type')}
                      </FormFeedback>
                    )}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="w-100">
                    Advancement level*:
                    <CustomInput
                      id="type"
                      type="select"
                      value={advancement_level_id || ''}
                      onChange={(event) =>
                        this.setState({
                          advancement_level_id: Number(
                            event.currentTarget.value
                          )
                        })
                      }
                      required
                      invalid={
                        errors &&
                        hasError(errors.errors, 'advancement_level_id')
                      }
                    >
                      {media_advancement_level.map((level) => (
                        <option key={level.id} value={level.id}>
                          {level.name}
                        </option>
                      ))}
                    </CustomInput>
                    {errors &&
                      hasError(errors.errors, 'advancement_level_id') && (
                        <FormFeedback>
                          {getError(errors.errors, 'advancement_level_id')}
                        </FormFeedback>
                      )}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="w-100">
                    Link:
                    <Input
                      type="text"
                      value={url || ''}
                      onChange={(event) =>
                        this.setState({
                          url: event.currentTarget.value
                        })
                      }
                      invalid={errors && hasError(errors.errors, 'url')}
                    />
                    {errors && hasError(errors.errors, 'url') && (
                      <FormFeedback>
                        {getError(errors.errors, 'url')}
                      </FormFeedback>
                    )}
                  </Label>
                  {type === 'video' && (
                    <FormText color="danger">
                      Link for video must be from YouTube.
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <div className="w-100 font-weight-bold">
                    Image:
                    <ImageCropUpload
                      required={Boolean(id)}
                      src={image}
                      onChange={(newImage) =>
                        this.setState({ image: newImage })
                      }
                      error={errors ? getError(errors.errors, 'image') : null}
                    />
                  </div>
                </FormGroup>
                {!id && (
                  <FormGroup>
                    <AttachToAsKnowHowFieldset
                      values={{
                        attachToPlants,
                        attachToPlantGroups,
                        attachToFamilies,
                        attachToMeasurements
                      }}
                      onChange={(values) =>
                        this.setState((state) => ({ ...state, ...values }))
                      }
                    />
                  </FormGroup>
                )}
                <div className="mt-4 d-flex justify-content-between">
                  <Button
                    type="button"
                    onClick={() =>
                      history.push(
                        id
                          ? getPathUrl(ROUTE_MEDIA_DETAILS, { id })
                          : ROUTE_MEDIA
                      )
                    }
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="success">
                    {id ? 'Update' : 'Create'}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(
    mapState,
    mapDispatch
  )(CreateUpdateMediaView)
);
