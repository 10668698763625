import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import User from 'modules/User/model/User';
import UserDetailsPlants from 'modules/User/component/Details/Plants';
import { ConnectedPlantListEntity } from 'modules/PlantGroups/model/PlantGroupDetails';
import UserDetailsGardens from 'modules/User/component/Details/Gardens/Details';
import { GardenEntity } from 'modules/Garden/model/Garden';
import UserDetailsHubs from 'modules/User/component/Details/Hubs/Details';
import { HubEntity } from 'modules/Hub/model/Hub';
import { SensorEntity } from 'modules/Sensor/model/Sensor';
import UserDetailsSensors from 'modules/User/component/Details/Sensors/Details';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import DeleteUserModal from 'modules/User/component/Modal/Delete';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_EDIT_ADMINISTRATOR } from 'modules/User/routes';
import UserDetailsFeedback from 'modules/User/component/Details/UserFeedback/Details';
import { FeedbackEntity } from 'modules/User/model/Feedback';
import { HelpFindPlantEntity } from 'modules/Plants/model/HelpFindPlant';
import UserDetailsHelpFindPlant from 'modules/User/component/Details/HelpFindPlant/Details';
import { HealthCheckHelpEntity } from 'modules/PlantDiseases/model/HealthCheckHelp';
import UserDetailsHelpHealthCheck from 'modules/User/component/Details/HelpHealthCheck/Details';

export interface Props {
  user: User;
  plants: ConnectedPlantListEntity[];
  gardens: GardenEntity[];
  hubs: HubEntity[];
  sensors: SensorEntity[];
  feedback: FeedbackEntity[];
  finder_help: HelpFindPlantEntity[];
  health_check: HealthCheckHelpEntity[];
  admin?: boolean;
  onDeleted?: () => void;
}

const UserDetails = (props: Props): JSX.Element => {
  const {
    user,
    gardens,
    hubs,
    sensors,
    feedback,
    finder_help,
    health_check,
    onDeleted,
    admin = false
  } = props;

  const navElements = [
    'Main data',
    'Gardens',
    'Plants',
    'Hubs',
    'Sensors',
    'Feedback',
    'Help find plant',
    'Help health check'
  ];

  const [nav, setNav] = useState(navElements.map((_, index) => index === 0));

  const [showMessage, setShowMessage] = useState('');

  const deleteModal = useRef(null);

  const history = useHistory();

  const additionalButtons = [];

  if (admin && user.status !== 0) {
    additionalButtons.push({
      text: 'Edit',
      bsColor: 'success',
      callback: () =>
        history.push(getPathUrl(ROUTE_EDIT_ADMINISTRATOR, { id: user.id }))
    });
  }

  additionalButtons.push({
    text: 'Delete',
    bsColor: 'danger',
    callback: () => {
      deleteModal.current.open(user);
    }
  });

  return (
    <div className="position-relative">
      <div className="details-top-nav mb-3">
        <DisplayNav
          booleanArr={nav}
          setBooleanArr={setNav}
          valueArr={navElements}
        />
      </div>

      <DeleteUserModal
        ref={deleteModal}
        onError={() => setShowMessage('Something went wrong.')}
        onSuccess={() => {
          if (onDeleted) {
            onDeleted();
          }
          setShowMessage(
            `${admin ? 'Administrator' : 'User'} successfully deleted.`
          );
        }}
        admin={admin}
      />

      {nav[0] && (
        <div className="content restrict-width">
          <DisplayBaseTabContent
            additionalButtons={additionalButtons}
            title={`${admin ? 'Administrator' : 'User'} - ${user.getName()}`}
          >
            <ShowMessage
              message={showMessage}
              deleteMessage={() => setShowMessage('')}
              bsColor={showMessage.includes('uccess') ? 'success' : 'danger'}
            />
            <div className="tab-content mt-3 row">
              {displayOptionRowContent(
                [
                  {
                    title: 'User ID',
                    description: user.id
                  },
                  {
                    title: 'Email',
                    description: user.email
                  },
                  {
                    title: 'Username',
                    description: user.username
                  },
                  {
                    title: 'Status',
                    description: user.getStatus()
                  },
                  {
                    title: 'Using Google account',
                    description: user.getIsUsingGoogle()
                  },
                  {
                    title: 'Using Apple account',
                    description: user.getIsUsingApple()
                  },
                  {
                    title: 'Using Facebook account',
                    description: user.getIsUsingFacebook()
                  },
                  {
                    title: 'Age',
                    description: user.age
                  },
                  {
                    title: 'Birth date',
                    description: user.birth_date
                  },
                  {
                    title: 'City',
                    description: user.city
                  },
                  {
                    title: 'Country',
                    description: user.country
                  },
                  {
                    title: 'Language',
                    description: user.lang
                  },
                  {
                    title: 'Plants knowledge',
                    description: user.getPlantsKnowledge()
                  },
                  {
                    title: 'Plants owned',
                    description: user.getPlantsOwned()
                  },
                  {
                    title: 'Joined at',
                    description: user.created_at
                  }
                ],
                false,
                'col-lg-4'
              )}
            </div>
          </DisplayBaseTabContent>
        </div>
      )}

      {nav[1] && <UserDetailsGardens gardens={gardens} />}

      {nav[2] && <UserDetailsPlants user={user} />}

      {nav[3] && <UserDetailsHubs hubs={hubs} />}

      {nav[4] && <UserDetailsSensors sensors={sensors} />}

      {nav[5] && <UserDetailsFeedback feedback={feedback} />}

      {nav[6] && <UserDetailsHelpFindPlant helpFindPlant={finder_help} />}

      {nav[7] && <UserDetailsHelpHealthCheck healthCheck={health_check} />}
    </div>
  );
};

export default UserDetails;
