import { MissingFamilyRequest } from 'modules/MissingFamily/model/MissingFamily';

export type MissingFamilyDetailsEntity = {
  id?: number;
  scientific_name?: string;
  family_scientific_name?: string;
  requests?: MissingFamilyRequest[];
  created_at?: string;
  updated_at?: string;
};

export default class MissingFamilyDetails {
  readonly id?: number;
  readonly scientific_name?: string;
  readonly family_scientific_name?: string;
  readonly requests?: MissingFamilyRequest[];
  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(entity: MissingFamilyDetailsEntity) {
    this.id = entity.id;
    this.scientific_name = entity.scientific_name;
    this.family_scientific_name = entity.family_scientific_name;
    this.requests = entity.requests;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }
}

export const createMissingFamilyDetails = (
  entity: MissingFamilyDetailsEntity
): MissingFamilyDetails => new MissingFamilyDetails(entity);
