import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { Action } from 'redux';
import { SensorSoftwareEntity } from 'modules/Sensor/model/SensorSoftware';

export const SET_SENSOR_SOFTWARE_LIST_PARAMS =
  'SENSOR_SOFTWARE/LIST/PARAMS/SET';
export const UPDATE_SENSOR_SOFTWARE_LIST_PARAMS =
  'SENSOR_SOFTWARE/LIST/PARAMS/UPDATE';
export const FETCH_SENSOR_SOFTWARE_LIST = 'SENSOR_SOFTWARE/LIST/FETCH';
export const SENSOR_SOFTWARE_LIST_FETCHED = 'SENSOR_SOFTWARE/LIST/FETCHED';

export type SensorSoftwareListAction =
  | SetSensorSoftwareListParamsAction
  | FetchSensorSoftwareListAction
  | SensorSoftwareListFetchedAction;

export type SetSensorSoftwareListParamsAction = SetSearchingParamsAction<
  typeof SET_SENSOR_SOFTWARE_LIST_PARAMS
>;

export const setSensorSoftwareListParamsAction = (
  payload: PartialSearchingProps
): SetSensorSoftwareListParamsAction =>
  setSearchingParamsAction(SET_SENSOR_SOFTWARE_LIST_PARAMS, payload);

export type FetchSensorSoftwareListAction = Action<
  typeof FETCH_SENSOR_SOFTWARE_LIST
>;

export const fetchSensorSoftwareListAction =
  (): FetchSensorSoftwareListAction => ({
    type: FETCH_SENSOR_SOFTWARE_LIST
  });

export type SensorSoftwareListFetchedPayload = {
  sensorSoftwares: SensorSoftwareEntity[];
  totalPages: number;
};

export interface SensorSoftwareListFetchedAction
  extends Action<typeof SENSOR_SOFTWARE_LIST_FETCHED> {
  payload: SensorSoftwareListFetchedPayload;
}

export const sensorSoftwareListFetchedAction = (
  payload: SensorSoftwareListFetchedPayload
): SensorSoftwareListFetchedAction => ({
  type: SENSOR_SOFTWARE_LIST_FETCHED,
  payload
});
