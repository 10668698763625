import { FamilyDetailsEntity } from 'modules/Family/model/FamilyDetails';
import { Action } from 'redux';
import { MeasurementsKnowHow as MeasurementsKnowHowType } from 'modules/Shared/configs/measurementKnowHowEntity/type';

export const SET_FAMILY_DETAILS_PARAMS = 'FAMILY/DETAILS/PARAMS/SET';
export const FETCH_FAMILY_DETAILS = 'FAMILY/DETAILS/FETCH';
export const FAMILY_DETAILS_FETCHED = 'FAMILY/DETAILS/FETCHED';
export const FAMILY_DETAILS_DELETED = 'FAMILY/DETAILS/DELETED';
export const SET_FAMILY_KNOW_HOWS = 'FAMILY/KNOW_HOWS/SET';
export const DELETE_FAMILY_KNOW_HOWS = 'FAMILY/KNOW_HOWS/DELETE';

export type FamilyDetailsAction =
  | SetFamilyDetailsParamsAction
  | FetchFamilyDetailsAction
  | FamilyDetailsFetchedAction
  | FamilyDetailsDeletedAction
  | SetFamilyKnowHowsAction
  | DeleteFamilyKnowHowAction;

export type SetFamilyDetailsParamsAction = {
  type: typeof SET_FAMILY_DETAILS_PARAMS;
  payload: number;
};

export const setFamilyDetailsParamsAction = (
  payload: number
): SetFamilyDetailsParamsAction => {
  return { type: SET_FAMILY_DETAILS_PARAMS, payload };
};

export type FetchFamilyDetailsAction = Action<typeof FETCH_FAMILY_DETAILS>;

export const fetchFamilyDetailsAction = (): FetchFamilyDetailsAction => ({
  type: FETCH_FAMILY_DETAILS
});

export type FamilyDetailsFetchedPayload = {
  familyDetails: FamilyDetailsEntity;
  measurementsStatusesKnowHows: MeasurementsKnowHowType[];
};

export interface FamilyDetailsFetchedAction
  extends Action<typeof FAMILY_DETAILS_FETCHED> {
  payload: FamilyDetailsFetchedPayload;
}

export const familyDetailsFetchedAction = (
  payload: FamilyDetailsFetchedPayload
): FamilyDetailsFetchedAction => ({
  type: FAMILY_DETAILS_FETCHED,
  payload
});

export type FamilyDetailsDeletedAction = {
  type: typeof FAMILY_DETAILS_DELETED;
};

export const familyDetailsDeletedAction = (): FamilyDetailsDeletedAction => ({
  type: FAMILY_DETAILS_DELETED
});

export interface SetFamilyKnowHowsPayload {
  measurementsStatusesKnowHows: MeasurementsKnowHowType[];
}

export interface SetFamilyKnowHowsAction
  extends Action<typeof SET_FAMILY_KNOW_HOWS> {
  payload: SetFamilyKnowHowsPayload;
}

export const setFamilyKnowHowsAction = (
  payload: SetFamilyKnowHowsPayload
): SetFamilyKnowHowsAction => ({
  type: SET_FAMILY_KNOW_HOWS,
  payload
});

export interface DeleteFamilyKnowHowPayload {
  id: number;
}

export interface DeleteFamilyKnowHowAction
  extends Action<typeof DELETE_FAMILY_KNOW_HOWS> {
  payload: DeleteFamilyKnowHowPayload;
}

export const deleteFamilyKnowHowAction = (
  payload: DeleteFamilyKnowHowPayload
): DeleteFamilyKnowHowAction => ({
  type: DELETE_FAMILY_KNOW_HOWS,
  payload
});
