import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setPlantUserListParamsAction,
  SetPlantUserListParamsAction
} from 'modules/PlantUser/action/list';
import PlantUser from 'modules/PlantUser/model/PlantUser';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ProtectedImage from 'modules/Layout/component/ProtectedImage';
import ActionShow from 'modules/Layout/component/Action/Show';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANT_USER_DETAILS } from 'modules/PlantUser/routes';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';
import { ROUTE_GARDEN_DETAILS } from 'modules/Garden/routes';
import { ROUTE_SENSOR_DETAILS } from 'modules/Sensor/routes';

export interface StateProps {
  plantUser: PlantUser[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetPlantUserListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (plant: PlantUser) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { plantUser, sort } = state.plantUser.list;

  return { plantUser, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPlantUserListParamsAction(payload))
});

export class PlantUserTable extends React.Component<Props> {
  readonly cols: TableCol<PlantUser>[];

  constructor(props: Props) {
    super(props);

    this.cols = [
      {
        property: 'id',
        label: 'User plant ID'
      },
      {
        property: 'thumb_path',
        label: 'Image',
        className: 'col-1',
        value: (row) => (
          <div className="d-flex justify-content-center">
            <ProtectedImage url={row.thumb_path} width={50} />
          </div>
        ),
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_PLANT_USER_DETAILS, { id: row.id })
      },
      {
        property: 'nickname',
        label: 'Nickname',
        sortable: true,
        className: 'col-2',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_PLANT_USER_DETAILS, { id: row.id })
      },
      {
        property: 'username',
        label: 'Owner username',
        sortable: true,
        className: 'col-2',
        columnRedirectTo: (row) =>
          Boolean(row.user_id) &&
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: 'email',
        label: 'Owner email',
        className: 'col-2',
        columnRedirectTo: (row) =>
          Boolean(row.user_id) &&
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: 'sensor_id',
        label: 'Sensor mac',
        className: 'col-2',
        columnRedirectTo: (row) =>
          Boolean(row.sensor_id) &&
          getPathUrl(ROUTE_SENSOR_DETAILS, { id: row.sensor_id })
      },
      {
        property: 'garden_name',
        sortable: true,
        label: 'Garden name',
        className: 'col-2',
        columnRedirectTo: (row) =>
          Boolean(row.garden_id) &&
          getPathUrl(ROUTE_GARDEN_DETAILS, { id: row.garden_id })
      },
      {
        property: 'status',
        label: 'Is deleted',
        className: 'col-1',
        value: (row) => row.getIsDeleted()
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show user plant"
              to={getPathUrl(ROUTE_PLANT_USER_DETAILS, { id: row.id })}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { plantUser, sort, setParams } = this.props;

    return (
      <Table
        cols={this.cols}
        rows={plantUser}
        sort={sort}
        onSort={(params) => setParams({ sort: params })}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantUserTable);
