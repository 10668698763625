import React, { FormEvent, ReactNode } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import Loader from 'modules/Layout/component/Loader';
import InformationList from 'modules/Layout/component/InformationList';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import Media from 'modules/Media/model/Media';
import Family from 'modules/Family/model/Family';
import PlantGroups from 'modules/PlantGroups/model/PlantGroups';
import Plant from 'modules/Plants/model/Plant';
import AttachToAsKnowHowFieldset, {
  AttachToMeasurementsValues
} from '../../Fieldset/AttachToAsKnowHow';
import {
  attachMediaAsKnowHow,
  AttachMediaAsKnowHowPayload
} from '../../../repository';
import {
  attachMediaAsKnowHowToastError,
  attachMediaAsKnowHowToastSuccess
} from '../../../toasts';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onSuccess?: (response: AxiosResponse) => void;
}

export interface State {
  isOpen: boolean;
  media: Media;
  attachToPlants: Plant[];
  attachToPlantGroups: PlantGroups[];
  attachToFamilies: Family[];
  attachToMeasurements: AttachToMeasurementsValues;
  fetching: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class AttachAsKnowHowMediaModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      media: null,
      fetching: false,
      attachToFamilies: [],
      attachToPlantGroups: [],
      attachToPlants: [],
      attachToMeasurements: {
        temperature: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false
        },
        light: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false
        },
        soil_moisture: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false
        },
        soil_fertility: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false
        }
      }
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  openModal(media: Media): void {
    this.setState({
      isOpen: true,
      fetching: false,
      attachToFamilies: [],
      attachToPlantGroups: [],
      attachToPlants: [],
      attachToMeasurements: {
        temperature: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false
        },
        light: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false
        },
        soil_moisture: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false
        },
        soil_fertility: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false
        }
      },
      media
    });
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const {
      media: { id },
      attachToFamilies,
      attachToPlantGroups,
      attachToPlants,
      attachToMeasurements
    } = this.state;
    const { addToast, onSuccess } = this.props;

    this.setState({
      fetching: true
    });

    try {
      const payload: AttachMediaAsKnowHowPayload = {
        know_how_measurements: [],
        families_know_how: attachToFamilies.map((obj) => obj.id),
        plant_groups_know_how: attachToPlantGroups.map((obj) => obj.id),
        plants_know_how: attachToPlants.map((obj) => obj.id)
      };

      Object.keys(attachToMeasurements).forEach((measurement) => {
        Object.keys(measurement).forEach((status) => {
          if (attachToMeasurements[measurement][status]) {
            payload.know_how_measurements.push({
              measurement,
              measurement_status: Number(status)
            });
          }
        });
      });

      const response = await attachMediaAsKnowHow(id, payload);

      onSuccess(response);

      addToast(attachMediaAsKnowHowToastSuccess());

      this.setState({
        fetching: false
      });
      this.toggle();
    } catch (error) {
      console.log(error);
      addToast(attachMediaAsKnowHowToastError());
      this.setState({
        fetching: false
      });
    }
  }

  renderTitle = (): React.ReactNode => {
    return 'Attach media as know-how';
  };

  renderBody(): React.ReactNode {
    const {
      fetching,
      media,
      attachToPlants,
      attachToPlantGroups,
      attachToFamilies,
      attachToMeasurements
    } = this.state;

    const fields = [
      {
        label: 'Title:',
        value: media.title
      },
      {
        label: 'Type:',
        value: media.type
      },
      {
        label: 'Link:',
        value: media.url
      }
    ];

    return (
      <Form id="attach-to-as-know-how-form" onSubmit={this.onSubmit}>
        {fetching && <Loader />}
        <FormGroup className="mb-4">
          <div className="d-flex justify-content-center mb-3">
            {Boolean(media.image) && (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img src={media.image} style={{ height: '100px' }} alt="Media" />
            )}
          </div>
          <InformationList fields={fields} />
        </FormGroup>
        <AttachToAsKnowHowFieldset
          values={{
            attachToPlants,
            attachToPlantGroups,
            attachToFamilies,
            attachToMeasurements
          }}
          onChange={(values) =>
            this.setState((state) => ({ ...state, ...values }))
          }
        />
      </Form>
    );
  }

  renderFooter(): React.ReactNode {
    const { fetching } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button disabled={fetching} color="primary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button
          disabled={fetching}
          color="success"
          type="submit"
          form="attach-to-as-know-how-form"
        >
          Attach media as know-how
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen, media } = this.state;

    if (!media) {
      return null;
    }

    return (
      <BaseModal
        size="xl"
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(AttachAsKnowHowMediaModal);
