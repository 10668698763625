import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PlantSection, PlantSectionsLangs } from 'modules/Plants/type';
import getPlantSectionName from 'modules/Plants/Helper/section';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import NavTab from 'modules/Layout/component/NavTab';

export interface Props
  extends RouteComponentProps<
    null,
    null,
    {
      lang?: string;
      section?: string;
    }
  > {
  plantSections: PlantSectionsLangs;
}

export interface State {
  activeLanguage: string;
  activeSection: string;
}

class PlantSections extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { location } = props;

    const activeLanguage = location.state?.lang || 'en';
    const activeSection = location.state?.section || 'placement';

    this.state = {
      activeLanguage,
      activeSection
    };

    this.renderSection = this.renderSection.bind(this);
  }

  renderSection(section: PlantSection): ReactNode {
    if (!section.content) {
      return (
        <div className="d-flex justify-content-center font-weight-bold">
          Section is empty.
        </div>
      );
    }

    return (
      <div
        className="mt-3"
        dangerouslySetInnerHTML={{ __html: section.content }}
      />
    );
  }

  render(): ReactNode {
    const { plantSections } = this.props;
    const { activeLanguage, activeSection } = this.state;

    const tabs = supportedLangs.map((lang) => ({
      slug: lang
    }));
    const activeSections = plantSections[activeLanguage];
    const sectionsTabs = Object.keys(activeSections).map((section) => ({
      slug: section,
      title: getPlantSectionName(section)
    }));
    const selectedSection = plantSections[activeLanguage][activeSection];

    return (
      <>
        <NavTab
          tabs={tabs}
          activeTab={activeLanguage}
          onChange={(lang) => this.setState({ activeLanguage: lang })}
        />
        <NavTab
          className="mt-1"
          tabs={sectionsTabs}
          activeTab={activeSection}
          onChange={(section) => this.setState({ activeSection: section })}
        />
        {this.renderSection(selectedSection)}
      </>
    );
  }
}

export default withRouter(PlantSections);
