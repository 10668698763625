import React, { Component, FormEvent, ReactNode } from 'react';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import User from 'modules/User/model/User';
import { RootState } from 'app/reducer';
import { Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import { updateProfile } from 'modules/User/repository';
import ShowMessage from 'modules/Layout/component/ShowMessage';

export interface StateProps {
  user: User;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
}

export type Props = DispatchProps & StateProps;

export interface State {
  email: string;
  password: string;
  repeat_password: string;
  updating: boolean;
  message: string;
  bgColor: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { user } = state.auth;

  return { user };
};

class ProfileView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { user } = props;

    this.state = {
      email: user.email,
      password: '',
      repeat_password: '',
      updating: false,
      message: '',
      bgColor: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(): void {
    const { managePage } = this.props;

    managePage({
      title: 'Profile'
    });
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    this.setState({
      updating: true,
      message: ''
    });

    try {
      const { email, password, repeat_password } = this.state;

      await updateProfile({
        email,
        password,
        repeat_password
      });

      this.setState({
        message: 'Profile updated successfully.',
        bgColor: 'success',
        password: '',
        repeat_password: ''
      });
    } catch (error) {
      this.setState({
        message: 'Error occurred.',
        bgColor: 'error'
      });
    } finally {
      this.setState({ updating: false });
    }
  }

  render(): ReactNode {
    const { email, password, repeat_password, updating, message, bgColor } =
      this.state;

    return (
      <div className="profile-view d-flex justify-content-center">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body p-4">
              <ShowMessage
                message={message}
                bsColor={bgColor}
                deleteMessage={() => this.setState({ message: '' })}
              />
              {updating && <Loader />}
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <Label>Email*:</Label>
                  <Input
                    value={email}
                    type="email"
                    required
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Password:</Label>
                  <Input
                    value={password}
                    type="password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*)(\-+=]).{5,20}$"
                    title="Minimum length 5, maximum length 20, must contain at least one number and one uppercase and lowercase letter, and one special character  !@#$%^&*)(\-+="
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                  />
                  <FormText color="muted">
                    You need to fill password only if you want to change it.
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Label>{`Repeat password${password ? '*' : ''}:`}</Label>
                  <Input
                    value={repeat_password}
                    type="password"
                    required={Boolean(password)}
                    onChange={(event) =>
                      this.setState({ repeat_password: event.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup className="mt-2 mb-0">
                  <Button className="w-100" type="submit">
                    Save
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(ProfileView);
