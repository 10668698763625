import React from 'react';
import { StandardBtn } from 'modules/Layout/view/Buttons/StandardBtn';

interface IProps {
  onEditBtnClick?: () => void;
  additionalButtons?: {
    callback: () => void;
    text: string;
    bsColor?: string;
    loading?: boolean;
  }[];
  title?: string;
  removeCard?: boolean;
  className?: string;
}

const DisplayBaseTabContent: React.FC<IProps> = ({
  onEditBtnClick = null,
  additionalButtons,
  title,
  removeCard,
  children,
  className = 'content restrict-width'
}) => {
  return (
    <div className={className}>
      <div className="row justify-content-center ">
        <div className="col-lg-12">
          <div className={removeCard ? '' : 'card'}>
            <div className="card-body p-3">
              <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                {Boolean(title) && <div className="h3">{title}</div>}
                <div className="d-flex flex-wrap align-items-center">
                  {additionalButtons &&
                    additionalButtons.map((button) => (
                      <StandardBtn
                        callback={button.callback}
                        text={button.text}
                        bsColor={button.bsColor}
                        classNames="ml-2 mb-1 mt-1"
                        key={button.text}
                        loading={button.loading}
                      />
                    ))}
                  {onEditBtnClick && (
                    <StandardBtn
                      callback={onEditBtnClick}
                      text="Edit"
                      classNames="ml-2 mb-1 mt-1"
                    />
                  )}
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayBaseTabContent;
