import React, { ReactNode } from 'react';
import Wysiwyg from 'modules/Layout/component/Wysiwyg';
import { EditorState } from 'draft-js';
import { mapEditorStateToHtml } from 'modules/Layout/helper/wysiwyg';
import { Button } from 'reactstrap';
import copyToClipboardHelper from 'modules/Tools/helper/clipboard';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  copyToClipboardToastError,
  copyToClipboardToastSuccess
} from 'modules/Tools/toasts';
import { PageProps } from 'modules/Layout/type';
import { breadcrumbRouteToolsWysiwygHtml } from 'modules/Tools/breadcrumbs';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
  managePage: (payload: PageProps) => ManagePageAction;
}

export type Props = DispatchProps;

export interface State {
  html: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload)),
  managePage: (payload: PageProps) => dispatch(managePageAction(payload))
});

class WysiwygHtmlPreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      html: ''
    };

    this.onChange = this.onChange.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  onChange(editorState: EditorState): void {
    const html = mapEditorStateToHtml(editorState);

    this.setState({
      html
    });
  }

  componentDidMount(): void {
    const { managePage } = this.props;

    managePage({
      title: 'Tools - Wysiwyg/HTML preview',
      breadcrumb: breadcrumbRouteToolsWysiwygHtml()
    });
  }

  copyToClipboard(): void {
    const { addToast } = this.props;
    const { html } = this.state;

    let success = false;

    try {
      success = copyToClipboardHelper(html);
    } catch (error) {
      console.log(error);
    } finally {
      if (success) {
        addToast(copyToClipboardToastSuccess());
      } else {
        addToast(copyToClipboardToastError());
      }
    }
  }

  render(): ReactNode {
    const { html } = this.state;

    return (
      <div className="d-flex">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Formatted text</h3>
              <Wysiwyg initialValue="" onChange={this.onChange} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-content-center justify-content-between">
                <h3 className="card-title m-0">HTML</h3>
                <Button
                  color="link"
                  className="p-0 px-2 m-0"
                  title="Copy to clipboard"
                  onClick={this.copyToClipboard}
                >
                  <i className="far fa-copy font-18" />
                </Button>
              </div>
              <div className="mt-2">{html}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect<null, DispatchProps>(
  null,
  mapDispatch
)(WysiwygHtmlPreview);
