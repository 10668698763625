import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { FamilyEntity } from 'modules/Family/model/Family';
import { Action } from 'redux';

export const SET_FAMILY_LIST_PARAMS = 'FAMILY/LIST/PARAMS/SET';
export const FETCH_FAMILY_LIST = 'FAMILY/LIST/FETCH';
export const FAMILY_LIST_FETCHED = 'FAMILY/LIST/FETCHED';

export type FamilyListAction =
  | SetFamilyListParamsAction
  | FetchFamilyListAction
  | FamilyListFetchedAction;

export type SetFamilyListParamsAction = SetSearchingParamsAction<
  typeof SET_FAMILY_LIST_PARAMS
>;

export const setFamilyListParamsAction = (
  payload: PartialSearchingProps
): SetFamilyListParamsAction =>
  setSearchingParamsAction(SET_FAMILY_LIST_PARAMS, payload);

export type FetchFamilyListAction = Action<typeof FETCH_FAMILY_LIST>;

export const fetchFamilyListAction = (): FetchFamilyListAction => ({
  type: FETCH_FAMILY_LIST
});

export type FamilyListFetchedPayload = {
  families: FamilyEntity[];
  totalPages: number;
};

export interface FamilyListFetchedAction
  extends Action<typeof FAMILY_LIST_FETCHED> {
  payload: FamilyListFetchedPayload;
}

export const familyListFetchedAction = (
  payload: FamilyListFetchedPayload
): FamilyListFetchedAction => ({
  type: FAMILY_LIST_FETCHED,
  payload
});
