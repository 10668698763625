import React, { ReactNode } from 'react';
import Dictionary, {
  createDictionary,
  DictionaryEntity
} from 'modules/Dictionary/model/Dictionary';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import { breadcrumbRouteDictionaryUpdate } from 'modules/Dictionary/breadcrumbs';
import {
  fetchDictionary,
  updateDictionary
} from 'modules/Dictionary/repository';
import Loader from 'modules/Layout/component/Loader';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { FormGroup, Input, Label } from 'reactstrap';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import { StandardBtn } from 'modules/Layout/view/Buttons/StandardBtn';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ROUTE_DICTIONARY_DETAILS } from 'modules/Dictionary/routes';
import { getPathUrl } from 'modules/Shared/helper/api';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props
  extends StateProps,
    DispatchProps,
    RouteComponentProps<any> {
  id: string;
  table: string;
}

export interface State {
  dictionary: Dictionary;
  updating: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class DictionaryUpdateView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dictionary: null,
      updating: false
    };

    this.onChange = this.onChange.bind(this);
    this.update = this.update.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { managePage, table, id, manageThrow } = this.props;

    managePage({
      title: `Dictionary ${Dictionary.getName(table)} - edit`,
      breadcrumb: breadcrumbRouteDictionaryUpdate(table),
      type: 'loading'
    });

    try {
      const { data } = await fetchDictionary(id, table);

      const dictionary = createDictionary(data.dictionary);

      this.setState({
        dictionary
      });

      managePage({
        title: `Dictionary ${Dictionary.getName(table)} - ${
          dictionary.name
        } - edit`,
        breadcrumb: breadcrumbRouteDictionaryUpdate(table, dictionary),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  onChange(value: string, lang: string): void {
    const { dictionary } = this.state;

    dictionary.langs[lang] = {
      text: value
    };

    this.setState({
      dictionary
    });
  }

  update(): void {
    const { table, history } = this.props;

    this.setState(
      {
        updating: true
      },
      async () => {
        const { dictionary } = this.state;

        try {
          const mappedData: DictionaryEntity = {
            id: dictionary.id,
            langs: {},
            name: dictionary.name
          };

          Object.keys(dictionary.langs).forEach((lang) => {
            mappedData.langs[lang] = {
              text: dictionary.langs[lang].text
            };
          });

          await updateDictionary(mappedData, table);

          history.push(
            getPathUrl(
              ROUTE_DICTIONARY_DETAILS,
              { id: dictionary.id },
              { table }
            )
          );
        } catch (error) {
          this.setState({
            updating: false
          });
        }
      }
    );
  }

  render(): ReactNode {
    const { type, history, id, table } = this.props;
    const { dictionary, updating } = this.state;

    if (type) {
      return null;
    }

    if (!dictionary) {
      return (
        <div className="position-relative">
          <Loader />
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body p-3">
          <div className="row">
            <div className="col-12">
              {supportedLangs.map((suppLang) => (
                <FormGroup key={suppLang} className="row">
                  <Label className="col-4">
                    {`Text ${suppLang.toUpperCase()}`}
                  </Label>
                  <Input
                    className="col-8"
                    id={`text-${suppLang}`}
                    type="text"
                    value={dictionary.langs[suppLang].text}
                    onChange={(event) =>
                      this.onChange(event.target.value, suppLang)
                    }
                  />
                </FormGroup>
              ))}
            </div>
            <div className="col-12 d-flex mt-2 flex-wrap justify-content-end">
              <StandardBtn
                callback={() =>
                  history.push(
                    getPathUrl(ROUTE_DICTIONARY_DETAILS, { id }, { table })
                  )
                }
                text="Cancel"
                bsColor="danger"
                inactive={updating}
              />
              <StandardBtn
                callback={this.update}
                text="Update"
                loading={updating}
                bsColor="success"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(
    mapState,
    mapDispatch
  )(DictionaryUpdateView)
);
