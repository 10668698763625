import { BreadcrumbItem } from 'modules/Layout/type';
import GardenDetails from 'modules/Garden/model/GardenDetails';

export const breadcrumbRouteGardens = (): BreadcrumbItem[] => [
  {
    title: 'Gardens'
  }
];

export const breadcrumbRouteDetailsGarden = (
  garden?: GardenDetails
): BreadcrumbItem[] => [
  {
    title: 'Gardens',
    link: '/garden/list'
  },
  {
    title: garden ? garden.name : 'Details'
  }
];
