import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { HubEntity } from 'modules/Hub/model/Hub';
import { HubSoftwareEntity } from 'modules/Hub/model/HubSoftware';

export const API_HUBS = '/admin-user-hub/list';
export const API_HUB_SOFTWARES = '/admin-hub-software/list';
export const API_HUB_SOFTWARE = '/admin-hub-software/:version';
export const API_PUBLISH_HUB_SOFTWARE = '/admin-hub-software/:version/publish';
export const API_HUB_DETAILS = '/admin-user-hub/:id';

export type FetchHubsResponse = AxiosResponse<{
  content: HubEntity[];
  totalCount: any;
}>;

export const fetchHubs = (
  params: SearchParams = {}
): AxiosPromise<FetchHubsResponse> =>
  axios.post(getAPIUrl(API_HUBS, null), params);

export type FetchHubResponse = AxiosResponse<{
  hub: HubEntity;
}>;

export type FetchHubSoftwaresResponse = AxiosResponse<{
  content: HubSoftwareEntity[];
  totalCount: any;
}>;

export const fetchHubSoftwares = (
  params: SearchParams = {}
): AxiosPromise<FetchHubSoftwaresResponse> =>
  axios.post(getAPIUrl(API_HUB_SOFTWARES, null), params);

export const deleteHubSoftware = (version: string): AxiosPromise => {
  return axios.delete(getAPIUrl(API_HUB_SOFTWARE, { version }));
};

export const publishHubSoftware = (version: string): AxiosPromise => {
  return axios.post(getAPIUrl(API_PUBLISH_HUB_SOFTWARE, { version }));
};

export const editHubSoftware = (
  version: string,
  data: {
    version: string;
    required: boolean;
  }
): AxiosPromise => {
  return axios.put(getAPIUrl(API_HUB_SOFTWARE, { version }), data);
};

export const fetchHub = (hubRowId: number | string): AxiosPromise<HubEntity> =>
  axios.get(getAPIUrl(API_HUB_DETAILS, { id: hubRowId }));
