import React, { useState, useEffect, createRef } from 'react';
import { StandardBtn } from 'modules/Layout/view/Buttons/StandardBtn';
import {
  MeasurementsStatusDescEntity,
  measurementStatusesOptions,
  MeasurementTypesEnum
} from 'modules/Shared/configs/measurementStatusDescEntity/type';
import { extractObjectFromMeasurement } from 'modules/Shared/configs/measurementStatusDescEntity/helper/getDataFromMeasurement';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import Wysiwyg from 'modules/Layout/component/Wysiwyg';
import { mapEditorStateToHtml } from 'modules/Layout/helper/wysiwyg';
import { Label } from 'reactstrap';
import { faqToolbar } from 'modules/Layout/component/Wysiwyg/config';

interface IProps {
  data: MeasurementsStatusDescEntity[];
  onCancelAction: () => void;
  onSubmitAction: (data: any) => void;
  loading: boolean;
  error: string;
  deleteError: () => void;
  type: string;
  lang: string;
  option: string;
}

interface IReturningValue {
  lang: string;
  measurement: string;
  measurement_status: number;
  description: string;
  long_term_description: string;
}

const Details: React.FC<IProps> = ({
  data,
  onCancelAction,
  onSubmitAction,
  loading,
  error,
  deleteError,
  type,
  lang,
  option
}) => {
  const measurements = extractObjectFromMeasurement(
    { lang, measurement: option as MeasurementTypesEnum },
    data
  );

  const [fieldState, setFieldState] = useState([]);
  const [stateRefs, setStateRefs] = useState([]);
  const [longTermStateRefs, setLongTermStateRefs] = useState([]);

  useEffect(() => {
    if (data) {
      setStateRefs((elRefs) =>
        Array(measurementStatusesOptions?.length)
          .fill(null)
          .map((_, i) => elRefs[i] || createRef())
      );
      setLongTermStateRefs((elRefs) =>
        Array(measurementStatusesOptions?.length)
          .fill(null)
          .map((_, i) => elRefs[i] || createRef())
      );
      setFieldState(
        measurementStatusesOptions?.map((value, i) => {
          const index = i + 1;
          let fieldValues = {
            title: value,
            description: '',
            long_term_description: '',
            measurement_status: index
          };
          const measurement = measurements?.find(
            (measurement) => measurement.measurement_status === index
          );
          if (measurement) {
            const { description, long_term_description, measurement_status } =
              measurement;
            fieldValues = {
              title: fieldValues.title,
              description,
              long_term_description,
              measurement_status
            };
          }
          return fieldValues;
        })
      );
    }
  }, [lang, type, option]);

  const convertSendData = () => {
    const stateValue: IReturningValue[] = [];

    fieldState.forEach((value, index) => {
      const html = mapEditorStateToHtml(
        stateRefs[index].current.state.editorState
      );
      const longTermHtml = mapEditorStateToHtml(
        longTermStateRefs[index].current.state.editorState
      );

      if (html || longTermHtml) {
        const measurement = measurements?.find(
          (measurement) =>
            measurement.measurement_status === value.measurement_status
        );

        if (measurement) {
          if (
            measurement.description !== html ||
            measurement.long_term_description !== longTermHtml
          ) {
            stateValue.push({
              lang,
              description: html,
              long_term_description: longTermHtml,
              measurement_status: value.measurement_status,
              measurement: option
            });
          }
        } else
          stateValue.push({
            lang,
            description: html,
            long_term_description: longTermHtml,
            measurement_status: value.measurement_status,
            measurement: option
          });
      }
    });

    return {
      editType: type,
      editOption: option,
      editLang: lang,
      stateValue
    };
  };

  if (data && fieldState.length) {
    return (
      <div className="card restrict-width">
        <div className="card-body p-3">
          <ShowMessage message={error} deleteMessage={deleteError} />
          <div className="row flex-wrap">
            <div className="col-12">
              <div className="form-group row align-items-center">
                <div className="col-lg-2 font-weight-bold">Status</div>
                <div className="col-lg-5 font-weight-bold">
                  Short term description
                </div>
                <div className="col-lg-5 font-weight-bold">
                  Long term description
                </div>
              </div>
            </div>
          </div>
          <div className="row flex-wrap">
            <div className="col-12">
              {measurementStatusesOptions?.map((value, index) => {
                const title = value?.charAt(0)?.toUpperCase() + value?.slice(1);

                return (
                  <div className="form-group row" key={value}>
                    <Label className="bv-no-focus-ring pt-1 col-lg-2">
                      {title}
                    </Label>
                    <div className="bv-no-focus-ring col-lg-5">
                      <Wysiwyg
                        ref={stateRefs[index]}
                        toolbar={faqToolbar}
                        initialValue={fieldState?.[index]?.description}
                      />
                    </div>
                    <div className="bv-no-focus-ring col-lg-5">
                      <Wysiwyg
                        ref={longTermStateRefs[index]}
                        toolbar={faqToolbar}
                        initialValue={
                          fieldState?.[index]?.long_term_description
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="col-sm-11 d-flex mt-2 flex-wrap justify-content-end">
              <StandardBtn
                callback={onCancelAction}
                text="Cancel"
                bsColor="danger"
              />
              <StandardBtn
                callback={() => onSubmitAction(convertSendData())}
                text="Save"
                loading={loading}
                bsColor="success"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Details;
