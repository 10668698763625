import { RootState } from 'app/reducer';
import {
  PartialSearchingProps,
  PaginationParams,
  SearchParams
} from 'modules/Shared/type';
import {
  SetMediaAdvancementListParamsAction,
  setMediaAdvancementListParamsAction
} from 'modules/Media/action/List';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Pagination } from 'modules/Layout/component/Pagination/pagination';

export interface StateProps {
  pagination: PaginationParams;
  filter: SearchParams;
  currentCount: number;
  totalPages: number;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetMediaAdvancementListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { pagination, filter, mediaAdvancement, totalPages } = state.media.list;

  return {
    pagination,
    filter,
    currentCount: mediaAdvancement.length,
    totalPages
  };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setMediaAdvancementListParamsAction(payload))
});

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(Pagination);
