import { RootState } from 'app/reducer';
import {
  SET_FAMILY_DETAILS_PARAMS,
  FETCH_FAMILY_DETAILS,
  fetchFamilyDetailsAction,
  familyDetailsFetchedAction
} from 'modules/Family/action/Details';
import {
  fetchFamilyDetails,
  FetchFamilyDetailsResponse
} from 'modules/Family/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { plantListFetchedAction } from 'modules/Plants/action/list';
import { manageThrow } from 'modules/Shared/action';

export function* setFamilyDetailsParamsSaga(): SagaIterator {
  yield put(fetchFamilyDetailsAction());
}

export function* fetchFamilyDetailsSaga(): SagaIterator {
  try {
    const { familyId } = yield select(
      (state: RootState) => state.family.details
    );

    const response: FetchFamilyDetailsResponse = yield call(
      fetchFamilyDetails,
      familyId
    );

    const {
      family,
      thresholds = null,
      plants,
      measurements_statuses_know_hows
    } = response.data;

    family.thresholds = thresholds;

    yield put(
      familyDetailsFetchedAction({
        familyDetails: family,
        measurementsStatusesKnowHows: measurements_statuses_know_hows
      })
    );
    yield put(
      plantListFetchedAction({
        plants,
        totalPages: 1
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        familyDetailsFetchedAction({
          familyDetails: null,
          measurementsStatusesKnowHows: []
        })
      );
      yield put(plantListFetchedAction({ plants: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantDetailsSaga(): SagaIterator {
  yield takeLatest(SET_FAMILY_DETAILS_PARAMS, setFamilyDetailsParamsSaga);
  yield takeLatest(FETCH_FAMILY_DETAILS, fetchFamilyDetailsSaga);
}
