import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import Dictionary from 'modules/Dictionary/model/Dictionary';

export interface DictionaryListState extends SearchingProps {
  dictionaries: Dictionary[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initDictionaryListState = (): DictionaryListState => ({
  dictionaries: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
