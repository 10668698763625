import FamilyListView from 'modules/Family/view/List';
import FamilyAddView from 'modules/Family/view/Add';
import FamilyDetailsView from 'modules/Family/view/Details';
import FamilyDetailsForm from 'modules/Family/view/DetailsForm';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';

export const ROUTE_FAMILY = '/family/list';
export const ROUTE_FAMILY_ADD = '/family/add';
export const ROUTE_FAMILY_DETAILS = '/family/details/:id';
export const ROUTE_FAMILY_DETAILS_FORM = '/family/details/form/:type/:id';
export const ROUTE_FAMILY_DETAILS_FORM_LANG =
  '/family/details/form/:type/:id/:lang';
export const ROUTE_FAMILY_DETAILS_REDIRECT_TEMPLATE = '/family/details/';
export const ROUTE_FAMILY_DETAILS_FORM_REDIRECT_TEMPLATE =
  '/family/details/form/';

export const createFamilyRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="family-list"
      path={ROUTE_FAMILY}
      exact
      component={FamilyListView}
    />,
    <Route
      key="family-add"
      path={ROUTE_FAMILY_ADD}
      exact
      component={FamilyAddView}
    />,
    <Route
      key="family-details"
      path={ROUTE_FAMILY_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FamilyDetailsView {...props} key={props.match.params.id} />
      )}
    />,
    <Route
      key="family-details-form"
      path={ROUTE_FAMILY_DETAILS_FORM}
      exact
      component={FamilyDetailsForm}
    />,
    <Route
      key="family-details-form-lang"
      path={ROUTE_FAMILY_DETAILS_FORM_LANG}
      exact
      component={FamilyDetailsForm}
    />
  ]
});
