import React from 'react';
import { Routes } from 'app/routes';
import { Route } from 'react-router-dom';
import TermsOfServiceListView from 'modules/Policy/view/list/termsOfService';
import TermsOfServiceDetailsView from 'modules/Policy/view/details/termsOfService';
import AddUpdateTermsOfServiceView from 'modules/Policy/view/addUpdate/termsOfService';
import PrivacyPolicyListView from 'modules/Policy/view/list/privacyPolicy';
import PrivacyPolicyDetailsView from 'modules/Policy/view/details/privacyPolicy';
import AddUpdatePrivacyPolicyView from 'modules/Policy/view/addUpdate/privacyPolicy';

export const ROUTE_TOS_LIST = '/terms-of-service/list';
export const ROUTE_TOS_DETAILS = '/terms-of-service/:id(\\d+)';
export const ROUTE_ADD_TOS = '/terms-of-service/add';
export const ROUTE_UPDATE_TOS = '/terms-of-service/:id(\\d+)/edit';
export const ROUTE_PP_LIST = '/privacy-policy/list';
export const ROUTE_ADD_PP = '/privacy-policy/add';
export const ROUTE_PP_DETAILS = '/privacy-policy/:id(\\d+)';
export const ROUTE_UPDATE_PP = '/privacy-policy/:id(\\d+)/edit';

export const createPolicyRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="tos-list"
      path={ROUTE_TOS_LIST}
      exact
      component={TermsOfServiceListView}
    />,
    <Route
      key="tos-details"
      path={ROUTE_TOS_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TermsOfServiceDetailsView {...props} id={props.match.params.id} />
      )}
    />,
    <Route
      key="tos-create"
      path={ROUTE_ADD_TOS}
      exact
      component={AddUpdateTermsOfServiceView}
    />,
    <Route
      key="tos-update"
      path={ROUTE_UPDATE_TOS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <AddUpdateTermsOfServiceView {...props} id={props.match.params.id} />
      )}
    />,
    <Route
      key="pp-list"
      path={ROUTE_PP_LIST}
      exact
      component={PrivacyPolicyListView}
    />,
    <Route
      key="pp-details"
      path={ROUTE_PP_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <PrivacyPolicyDetailsView {...props} id={props.match.params.id} />
      )}
    />,
    <Route
      key="pp-create"
      path={ROUTE_ADD_PP}
      exact
      component={AddUpdatePrivacyPolicyView}
    />,
    <Route
      key="pp-update"
      path={ROUTE_UPDATE_PP}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <AddUpdatePrivacyPolicyView {...props} id={props.match.params.id} />
      )}
    />
  ]
});
