import { RootState } from 'app/reducer';
import { setLocationSearchAction, manageThrow } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_MEDIA_LIST,
  fetchMediaListAction,
  SET_MEDIA_LIST_PARAMS,
  mediaListFetchedAction,
  fetchMediaAdvancementListAction,
  SET_MEDIA_ADVANCEMENT_LIST_PARAMS,
  FETCH_MEDIA_ADVANCEMENT_LIST,
  mediaAdvancementListFetchedAction
} from 'modules/Media/action/List';
import {
  fetchMedia,
  FetchMediaAdvancementLevel,
  fetchMediaAdvancementLevel,
  FetchMediaResponse
} from 'modules/Media/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { AxiosResponse } from 'axios';

export function* setMediaListParamsSaga(): SagaIterator {
  yield put(fetchMediaListAction());
}

export function* fetchMediaListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.media.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const mappedFilter = {
      ...filter
    };

    if (mappedFilter.is_deleted) {
      mappedFilter.is_deleted = true;
    }

    const response: AxiosResponse<FetchMediaResponse> = yield call(fetchMedia, {
      ...sort,
      pagination,
      search: mappedFilter
    });

    const { content, totalCount } = response.data;
    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      mediaListFetchedAction({
        media: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(mediaListFetchedAction({ media: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export function* setMediaAdvancementListParamsSaga(): SagaIterator {
  yield put(fetchMediaAdvancementListAction());
}

export function* fetchMediaAdvancementListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.media.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response: AxiosResponse<FetchMediaAdvancementLevel> = yield call(
      fetchMediaAdvancementLevel,
      {
        ...sort,
        pagination,
        search: filter
      }
    );

    const { content, totalCount } = response.data;
    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      mediaAdvancementListFetchedAction({
        mediaAdvancement: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        mediaAdvancementListFetchedAction({
          mediaAdvancement: [],
          totalPages: 1
        })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantListSaga(): SagaIterator {
  yield takeLatest(SET_MEDIA_LIST_PARAMS, setMediaListParamsSaga);
  yield takeLatest(FETCH_MEDIA_LIST, fetchMediaListSaga);
  yield takeLatest(
    SET_MEDIA_ADVANCEMENT_LIST_PARAMS,
    setMediaAdvancementListParamsSaga
  );
  yield takeLatest(FETCH_MEDIA_ADVANCEMENT_LIST, fetchMediaAdvancementListSaga);
}
