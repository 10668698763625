import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { SensorSoftwareEntity } from 'modules/Sensor/model/SensorSoftware';
import { SensorDetailsEntity } from 'modules/Sensor/model/SensorDetails';
import { SensorEntity } from 'modules/Sensor/model/Sensor';

export const API_SENSOR_SOFTWARES = '/admin-sensor-software/list';
export const API_SENSOR_SOFTWARE = '/admin-sensor-software/:version';
export const API_PUBLISH_SENSOR_SOFTWARE =
  '/admin-sensor-software/:version/publish';
export const API_SENSOR_DETAILS = '/admin-user-plant/sensor/:id';
export const API_USER_SENSOR = '/admin-user-sensor/:userSensorId';
export const API_SENSOR_LIST = '/admin-user-sensor/list';

export type FetchSensorSoftwaresResponse = AxiosResponse<{
  content: SensorSoftwareEntity[];
  totalCount: any;
}>;

export const fetchSensorSoftwares = (
  params: SearchParams = {}
): AxiosPromise<FetchSensorSoftwaresResponse> =>
  axios.post(getAPIUrl(API_SENSOR_SOFTWARES, null), params);

export const deleteSensorSoftware = (version: string): AxiosPromise => {
  return axios.delete(getAPIUrl(API_SENSOR_SOFTWARE, { version }));
};

export const publishSensorSoftware = (version: string): AxiosPromise => {
  return axios.post(getAPIUrl(API_PUBLISH_SENSOR_SOFTWARE, { version }));
};

export const editSensorSoftware = (
  version: string,
  data: {
    version: string;
    required: boolean;
  }
): AxiosPromise => {
  return axios.put(getAPIUrl(API_SENSOR_SOFTWARE, { version }), data);
};

// sensor details

export type GetSensorDetailsResponse = AxiosResponse<SensorDetailsEntity>;

export const getSensorDetails = (
  sensorId: string
): AxiosPromise<GetSensorDetailsResponse> => {
  return axios.get(getAPIUrl(API_SENSOR_DETAILS, { id: sensorId }));
};

// sensorList
export type FetchSensorsResponse = AxiosResponse<{
  content: SensorEntity[];
  totalCount: any;
}>;

export const fetchSensor = (
  params: SearchParams = {}
): AxiosPromise<FetchSensorsResponse> =>
  axios.post(getAPIUrl(API_SENSOR_LIST), params);

export type DeleteSensorResponse = AxiosResponse<{
  statusCode: number;
}>;

export const deleteSensor = async (
  userSensorId: string,
  cancelToken?: any
): Promise<DeleteSensorResponse> => {
  const response = await axios.delete(getAPIUrl(API_USER_SENSOR, { userSensorId }), {
    cancelToken
  });
  console.log('GOT IT', response);
  return response;
};
