import React, { ReactNode } from 'react';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import PhResults from 'modules/Plants/model/PhResults';
import ActionUpdate from 'modules/Layout/component/Action/Update';

export interface Props {
  phResults: PhResults;
  onEdit?: () => void;
}

export interface State {
  activeTabs: boolean[];
}

class PhResultsDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTabs: supportedLangs.map((_obj, index) => index === 0)
    };

    this.renderNav = this.renderNav.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  renderNav(): ReactNode {
    const { activeTabs } = this.state;

    return (
      <div className="top-nav mt-3 mb-3">
        <DisplayNav
          booleanArr={activeTabs}
          setBooleanArr={(newActiveTabs: boolean[]) =>
            this.setState({ activeTabs: newActiveTabs })
          }
          valueArr={supportedLangs}
        />
      </div>
    );
  }

  renderContent(): ReactNode {
    const { phResults } = this.props;
    const { activeTabs } = this.state;
    const activeTabIndex = activeTabs.findIndex((obj) => obj);
    const activeLang = supportedLangs[activeTabIndex];
    const phResultsLang = phResults.lang.find((obj) => obj.lang === activeLang);

    if (!phResultsLang) {
      return null;
    }

    return (
      <>
        <div
          className="my-2"
          dangerouslySetInnerHTML={{
            __html: phResultsLang.description
              .replace('{threshold_min}', 'x.x')
              .replace('{threshold_max}', 'x.x')
          }}
        />
        {Boolean(phResultsLang.what_you_can_do) && (
          <>
            <h4>What you can do:</h4>
            <div
              className="my-2"
              dangerouslySetInnerHTML={{
                __html: phResultsLang.what_you_can_do
                  .replace('{threshold_min}', 'x.x')
                  .replace('{threshold_max}', 'x.x')
              }}
            />
          </>
        )}
        {phResultsLang.media && (
          <>
            <a
              className="d-flex justify-content-center"
              href={phResultsLang.media.url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src={phResultsLang.media.image}
                alt="image_not_found"
                title={phResultsLang.media.url}
              />
            </a>
          </>
        )}
      </>
    );
  }

  render(): ReactNode {
    const { phResults, onEdit } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <h3 className="d-flex justify-content-between">
            {phResults.getStatus()}
            {onEdit && <ActionUpdate title="Edit pH result" onClick={onEdit} />}
          </h3>
          {this.renderNav()}
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

export default PhResultsDetails;
