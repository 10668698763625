import { Action } from 'redux';
import { SensorDetailsEntity } from 'modules/Sensor/model/SensorDetails';

export const SET_SENSOR_DETAILS_PARAMS = 'SENSOR/DETAILS/PARAMS/SET';
export const FETCH_SENSOR_DETAILS = 'SENSOR/DETAILS/FETCH';
export const SENSOR_DETAILS_FETCHED = 'SENSOR/DETAILS/FETCHED';

export type SensorDetailsAction =
  | SetSensorDetailsParamsAction
  | FetchSensorDetailsAction
  | SensorDetailsFetchedAction;

export type SetSensorDetailsParamsAction = {
  type: typeof SET_SENSOR_DETAILS_PARAMS;
  payload: string;
};

export const setSensorDetailsParamsAction = (
  payload: string
): SetSensorDetailsParamsAction => {
  return { type: SET_SENSOR_DETAILS_PARAMS, payload };
};

export type FetchSensorDetailsAction = Action<typeof FETCH_SENSOR_DETAILS>;

export const fetchSensorDetailsAction = (): FetchSensorDetailsAction => ({
  type: FETCH_SENSOR_DETAILS
});

export type SensorDetailsFetchedPayload = {
  sensorDetails: SensorDetailsEntity;
};

export interface SensorDetailsFetchedAction
  extends Action<typeof SENSOR_DETAILS_FETCHED> {
  payload: SensorDetailsFetchedPayload;
}

export const sensorDetailsFetchedAction = (
  payload: SensorDetailsFetchedPayload
): SensorDetailsFetchedAction => ({
  type: SENSOR_DETAILS_FETCHED,
  payload
});
