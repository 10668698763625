import { RootState } from 'app/reducer';
import { setLocationSearchAction, manageThrow } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import {
  FETCH_PP_LIST,
  FETCH_TOS_LIST,
  fetchPPListAction,
  fetchTOSListAction,
  ppListFetchedAction,
  SET_PP_LIST_PARAMS,
  SET_TOS_LIST_PARAMS,
  tosListFetchedAction
} from 'modules/Policy/action/list';
import {
  fetchPP,
  FetchPPResponse,
  fetchTOS,
  FetchTOSResponse
} from 'modules/Policy/repository';
import { AxiosResponse } from 'axios';

export function* setTOSListParamsSaga(): SagaIterator {
  yield put(fetchTOSListAction());
}

export function* fetchTOSListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.policy.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response: AxiosResponse<FetchTOSResponse> = yield call(fetchTOS, {
      ...sort,
      pagination,
      search: filter
    });

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      tosListFetchedAction({
        termsOfServices: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(tosListFetchedAction({ termsOfServices: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export function* setPPListParamsSaga(): SagaIterator {
  yield put(fetchPPListAction());
}

export function* fetchPPListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.policy.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response: AxiosResponse<FetchPPResponse> = yield call(fetchPP, {
      ...sort,
      pagination,
      search: filter
    });

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      ppListFetchedAction({
        pp: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(ppListFetchedAction({ pp: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* policyListSaga(): SagaIterator {
  yield takeLatest(SET_TOS_LIST_PARAMS, setTOSListParamsSaga);
  yield takeLatest(FETCH_TOS_LIST, fetchTOSListSaga);
  yield takeLatest(SET_PP_LIST_PARAMS, setPPListParamsSaga);
  yield takeLatest(FETCH_PP_LIST, fetchPPListSaga);
}
