import React, { ReactNode } from 'react';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  breadcrumbRouteCreateTermsOfService,
  breadcrumbRouteEditTermsOfService
} from 'modules/Policy/breadcrumbs';
import TermsOfServiceForm from 'modules/Policy/component/Form';
import TermsOfService, {
  createTermsOfService
} from 'modules/Policy/model/TermsOfService';
import { RootState } from 'app/reducer';
import { fetchTOSDetails } from 'modules/Policy/repository';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps, RouteComponentProps {
  id?: number;
}

export interface State {
  termsOfService: TermsOfService;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class AddUpdateTermsOfServiceView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      termsOfService: null
    };
  }

  async componentDidMount(): Promise<void> {
    const { id, managePage, manageThrow } = this.props;

    if (id) {
      managePage({
        title: 'Terms of service - edit',
        type: 'loading',
        breadcrumb: breadcrumbRouteEditTermsOfService()
      });

      try {
        const { data } = await fetchTOSDetails(id);

        const termsOfService = createTermsOfService(data.tos);

        this.setState({
          termsOfService
        });

        managePage({
          title: 'Terms of service - edit',
          type: null,
          breadcrumb: breadcrumbRouteEditTermsOfService(termsOfService)
        });
      } catch (error) {
        manageThrow(error);
      }
    } else {
      managePage({
        title: 'Terms of service - create',
        breadcrumb: breadcrumbRouteCreateTermsOfService()
      });
    }
  }

  render(): ReactNode {
    const { id, type } = this.props;
    const { termsOfService } = this.state;

    if (type || (id && !termsOfService)) {
      return null;
    }

    return (
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="card">
            <div className="card-body">
              <TermsOfServiceForm termsOfService={termsOfService} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(
    mapState,
    mapDispatch
  )(AddUpdateTermsOfServiceView)
);
