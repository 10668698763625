import React, { useReducer, useEffect, useState } from 'react';
import FamilyDetailsFormReducer, {
  initState,
  updateFieldAction,
  resetState,
  IPayload
} from 'modules/Layout/view/DetailsForm/Reducer';
import { FAMILY_DETAILS_THRESHOLD } from 'modules/Family/view/DetailsForm/helper/acceptableTypes';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import DisplayBaseContent from 'modules/Layout/view/DetailsForm/components/DisplayBaseContent';
import DisplayThresholds from 'modules/Layout/view/DetailsForm/components/DisplayThresholds';
import { imageToBase64 } from 'modules/Layout/component/ImageUploader/helper/imageToBase64';
import ImageUploader from 'modules/Layout/component/ImageUploader';
import { IconBtn } from '../Buttons/IconBtn';
import { Delete } from '../SVGs';
import Carousel from '../Carousel';
import { StandardBtn } from '../Buttons/StandardBtn';

export type IOnSubmitAction = {
  [key: string]: string | number;
};

interface IProps {
  arrayOfFieldDesc: {
    title: string;
    mapped: string;
    shortTitle?: string;
    type?: string;
    selectOptions?: string[];
  }[];
  data: { [key: string]: string | boolean | number } | null;
  onSubmitAction: (data: IOnSubmitAction) => void;
  onCancelAction: () => void;
  displayImageUploader?: boolean;
  editType?: string;
  editLang?: string;
  editOption?: string;
  loading: boolean;
  error?: string;
  deleteError?: () => void;
}

export const DetailsForm: React.FC<IProps> = React.memo(
  ({
    arrayOfFieldDesc,
    data,
    onSubmitAction,
    displayImageUploader,
    editType = '',
    editLang = null,
    editOption = null,
    error,
    deleteError,
    loading,
    onCancelAction,
    children
  }) => {
    const createArrObj: IPayload[] = [];
    const [image, setImage] = useState<File>(null);
    const [showImageUploader, setShowImageUploader] = useState(false);
    Object.entries(arrayOfFieldDesc).forEach(([_, value]) => {
      if (value.mapped)
        createArrObj.push({
          name: value.mapped,
          // @ts-ignore
          value: data?.[value.mapped] || ''
        });
    });

    const [field, setField] = useReducer(
      FamilyDetailsFormReducer,
      data?.id ? initState(createArrObj) : {}
    );

    useEffect(() => {
      if (!loading) setField(resetState(createArrObj));
    }, [arrayOfFieldDesc]);

    const convertImage = async (data: IOnSubmitAction, image: File) => ({
      ...data,
      image: (await imageToBase64(image)) as string
    });

    const convertSendData = async () => {
      let sendData: IOnSubmitAction = {};
      for (const key in field) {
        sendData = {
          ...sendData,
          [key]: field[key].value,
          editType,
          editLang,
          editOption
        };
      }

      return image ? await convertImage(sendData, image) : sendData;
    };

    const displayContent = () => {
      if (editType === FAMILY_DETAILS_THRESHOLD) {
        return (
          <DisplayThresholds
            arrayOfFieldDesc={arrayOfFieldDesc}
            field={field}
            updateField={(value: string, mapped: string) =>
              setField(updateFieldAction(mapped, value))
            }
          />
        );
      }

      return (
        <DisplayBaseContent
          arrayOfFieldDesc={arrayOfFieldDesc}
          field={field}
          updateField={(value: string, mapped: string) =>
            setField(updateFieldAction(mapped, value))
          }
        />
      );
    };

    const displayImage = () => {
      if (data?.image && !showImageUploader) {
        return (
          <>
            <label className="bv-no-focus-ring col-lg-3">Image</label>
            <div className="col-lg-9 ml-2 border" style={{ maxWidth: '400px' }}>
              <div className="position-absolute absolute-top-right rise-index">
                <IconBtn
                  callback={() => setShowImageUploader(true)}
                  icon={<Delete />}
                  classNames="btn-light details-carousel-delete-btn"
                />
              </div>
              <Carousel
                urlImageArr={data?.image ? [data?.image as string] : []}
              />
            </div>
          </>
        );
      }
      return (
        <>
          <label className="bv-no-focus-ring col-lg-3">Image</label>
          <div className="col-lg-9" style={{ maxWidth: '400px' }}>
            <div
              className="pt-1 pb-1"
              style={{ border: image ? 'none' : '1px solid #ced4da' }}
            >
              <ImageUploader image={image} setImage={setImage} />
            </div>
          </div>
        </>
      );
    };

    return (
      <div className="card restrict-width">
        <div className="card-body p-3">
          <ShowMessage message={error} deleteMessage={deleteError} />
          <div className="row flex-wrap">
            {displayContent()}
            {displayImageUploader ? displayImage() : false}
            {children}
            <div className="col-sm-11 d-flex mt-3 flex-wrap justify-content-end">
              <StandardBtn
                callback={onCancelAction}
                text="Cancel"
                bsColor="danger"
              />
              <StandardBtn
                callback={async () => onSubmitAction(await convertSendData())}
                text="Save"
                loading={loading}
                bsColor="success"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default DetailsForm;
