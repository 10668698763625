import {
  ADMINISTRATOR_LIST_FETCHED,
  FETCH_ADMINISTRATOR_LIST,
  FETCH_USER_FEEDBACK_LIST,
  FETCH_USER_LIST,
  SET_ADMINISTRATOR_LIST_PARAMS,
  SET_USER_FEEDBACK_LIST_PARAMS,
  SET_USER_LIST_PARAMS,
  USER_FEEDBACK_LIST_FETCHED,
  USER_FEEDBACK_LIST_RESPONDED,
  USER_LIST_FETCHED,
  UserListAction
} from 'modules/User/action/list';
import { createUser } from 'modules/User/model/User';
import { initUserListState, UserListState } from 'modules/User/state/list';

const initState = initUserListState();

const reducer = (
  state: UserListState = initState,
  action: UserListAction
): UserListState => {
  switch (action.type) {
    case SET_ADMINISTRATOR_LIST_PARAMS:
    case SET_USER_LIST_PARAMS:
    case SET_USER_FEEDBACK_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_ADMINISTRATOR_LIST:
    case FETCH_USER_LIST:
    case FETCH_USER_FEEDBACK_LIST:
      return { ...state, fetching: true };

    case ADMINISTRATOR_LIST_FETCHED:
    case USER_LIST_FETCHED: {
      const { users = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        users: users.map(createUser),
        totalPages
      };
    }

    case USER_FEEDBACK_LIST_FETCHED: {
      const { feedback = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        feedback,
        totalPages
      };
    }

    case USER_FEEDBACK_LIST_RESPONDED: {
      const { feedbackId, responded } = action.payload;

      return {
        ...state,
        feedback: state.feedback.map((obj) => {
          if (obj.id === feedbackId) {
            return {
              ...obj,
              responded: Number(responded)
            };
          }

          return obj;
        })
      };
    }

    default:
      return state;
  }
};

export default reducer;
