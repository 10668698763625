import Autocomplete, {
  AutocompleteOmitProps,
  AutocompleteOption
} from 'modules/Layout/component/Autocomplete';
import React, { ReactNode } from 'react';
import ApiError from 'modules/Shared/exception/ApiError';
import Media, { createMedia } from 'modules/Media/model/Media';
import { SearchParams } from 'modules/Shared/type';
import { fetchMedia } from 'modules/Media/repository';

export interface Props extends AutocompleteOmitProps {
  mediaId?: number | string;
  disabledOption?: (media: Media) => boolean;
}

export interface State {
  media?: Media[];
  error?: ApiError;
  fetching?: boolean;
}

class MediaAutocomplete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fetching: false,
      media: []
    };

    this.toOption = this.toOption.bind(this);
    this.fetchMedia = this.fetchMedia.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  toOption(
    mediaId?: number | string,
    disabledOption?: (media: Media) => boolean
  ): AutocompleteOption {
    const { media } = this.state;

    const foundMedia = media.find((obj) => String(obj.id) === String(mediaId));

    if (!foundMedia) {
      return {
        label: '',
        value: '',
        node: null
      };
    }

    return {
      label: `${foundMedia.title} - ${foundMedia.type}`,
      value: foundMedia.id.toString(),
      node: (
        <div className="d-flex align-items-center">
          <img
            className="mr-2"
            style={{ maxHeight: '40px' }}
            src={foundMedia.image}
            alt="Media"
          />
          <div
            style={{ whiteSpace: 'break-spaces' }}
          >{`${foundMedia.title} - ${foundMedia.type}`}</div>
        </div>
      ),
      disabled: disabledOption ? disabledOption(foundMedia) : false
    };
  }

  async fetchMedia(payload: SearchParams): Promise<void> {
    try {
      this.setState({
        fetching: true
      });

      const {
        data: { content }
      } = await fetchMedia({
        search: payload as unknown as string,
        pagination: {
          count: 10
        } as unknown as string
      });

      this.setState({
        media: content.map(createMedia)
      });
    } catch (error) {
      this.setState({
        error
      });
    } finally {
      this.setState({
        fetching: false
      });
    }
  }

  render(): ReactNode {
    const {
      onChange,
      mediaId,
      required = false,
      disabled = false,
      disabledOption = null,
      label = 'Choose know-how'
    } = this.props;
    const { media, fetching, error } = this.state;

    const plantGroupsOptions = media.map((obj) =>
      this.toOption(obj.id, disabledOption)
    );

    return (
      <Autocomplete
        onChange={onChange}
        fetching={fetching}
        error={error}
        onSearch={async (title) => this.fetchMedia({ title })}
        value={this.toOption(mediaId)}
        options={plantGroupsOptions}
        required={required}
        disabled={disabled}
        render={(row: Media) => `XD ${row.title}`}
        label={label}
      />
    );
  }
}

export default MediaAutocomplete;
