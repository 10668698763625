import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { UserEntity } from 'modules/User/model/User';
import { Action } from 'redux';
import { FeedbackEntity } from '../../model/Feedback';

export const SET_USER_LIST_PARAMS = 'USER/LIST/PARAMS/SET';
export const UPDATE_USER_LIST_PARAMS = 'USER/LIST/PARAMS/UPDATE';
export const FETCH_USER_LIST = 'USER/LIST/FETCH';
export const USER_LIST_FETCHED = 'USER/LIST/FETCHED';
export const SET_ADMINISTRATOR_LIST_PARAMS = 'ADMINISTRATOR/LIST/PARAMS/SET';
export const FETCH_ADMINISTRATOR_LIST = 'ADMINISTRATOR/LIST/FETCH';
export const ADMINISTRATOR_LIST_FETCHED = 'ADMINISTRATOR/LIST/FETCHED';

export const SET_USER_FEEDBACK_LIST_PARAMS = 'USER_FEEDBACK/LIST/PARAMS/SET';
export const UPDATE_USER_FEEDBACK_LIST_PARAMS =
  'USER_FEEDBACK/LIST/PARAMS/UPDATE';
export const FETCH_USER_FEEDBACK_LIST = 'USER_FEEDBACK/LIST/FETCH';
export const USER_FEEDBACK_LIST_FETCHED = 'USER_FEEDBACK/LIST/FETCHED';
export const USER_FEEDBACK_LIST_SET_RESPONDED =
  'USER_FEEDBACK/LIST/SET_RESPONDED';
export const USER_FEEDBACK_LIST_RESPONDED = 'USER_FEEDBACK/LIST/RESPONDED';

export type UserListAction =
  | SetUserListParamsAction
  | FetchUserListAction
  | UserListFetchedAction
  | SetAdministratorListParamsAction
  | FetchAdministratorListAction
  | AdministratorListFetchedAction
  | SetUserFeedbackListParamsAction
  | FetchUserFeedbackListAction
  | UserFeedbackListFetchedAction
  | UserFeedbackListSetRespondedAction
  | UserFeedbackListRespondedAction;

export type SetUserListParamsAction = SetSearchingParamsAction<
  typeof SET_USER_LIST_PARAMS
>;

export const setUserListParamsAction = (
  payload: PartialSearchingProps
): SetUserListParamsAction =>
  setSearchingParamsAction(SET_USER_LIST_PARAMS, payload);

export type FetchUserListAction = Action<typeof FETCH_USER_LIST>;

export const fetchUserListAction = (): FetchUserListAction => ({
  type: FETCH_USER_LIST
});

export type UserListFetchedPayload = {
  users: UserEntity[];
  totalPages: number;
};

export interface UserListFetchedAction
  extends Action<typeof USER_LIST_FETCHED> {
  payload: UserListFetchedPayload;
}

export const userListFetchedAction = (
  payload: UserListFetchedPayload
): UserListFetchedAction => ({
  type: USER_LIST_FETCHED,
  payload
});

export type SetAdministratorListParamsAction = SetSearchingParamsAction<
  typeof SET_ADMINISTRATOR_LIST_PARAMS
>;

export const setAdministratorListParamsAction = (
  payload: PartialSearchingProps
): SetAdministratorListParamsAction =>
  setSearchingParamsAction(SET_ADMINISTRATOR_LIST_PARAMS, payload);

export type FetchAdministratorListAction = Action<
  typeof FETCH_ADMINISTRATOR_LIST
>;

export const fetchAdministratorListAction =
  (): FetchAdministratorListAction => ({
    type: FETCH_ADMINISTRATOR_LIST
  });

export type AdministratorListFetchedPayload = {
  users: UserEntity[];
  totalPages: number;
};

export interface AdministratorListFetchedAction
  extends Action<typeof ADMINISTRATOR_LIST_FETCHED> {
  payload: AdministratorListFetchedPayload;
}

export const administratorListFetchedAction = (
  payload: AdministratorListFetchedPayload
): AdministratorListFetchedAction => ({
  type: ADMINISTRATOR_LIST_FETCHED,
  payload
});

export type SetUserFeedbackListParamsAction = SetSearchingParamsAction<
  typeof SET_USER_FEEDBACK_LIST_PARAMS
>;

export const setUserFeedbackListParamsAction = (
  payload: PartialSearchingProps
): SetUserFeedbackListParamsAction =>
  setSearchingParamsAction(SET_USER_FEEDBACK_LIST_PARAMS, payload);

export type FetchUserFeedbackListAction = Action<
  typeof FETCH_USER_FEEDBACK_LIST
>;

export const fetchUserFeedbackListAction = (): FetchUserFeedbackListAction => ({
  type: FETCH_USER_FEEDBACK_LIST
});

export type UserFeedbackListFetchedPayload = {
  feedback: FeedbackEntity[];
  totalPages: number;
};

export interface UserFeedbackListFetchedAction
  extends Action<typeof USER_FEEDBACK_LIST_FETCHED> {
  payload: UserFeedbackListFetchedPayload;
}

export const userFeedbackListFetchedAction = (
  payload: UserFeedbackListFetchedPayload
): UserFeedbackListFetchedAction => ({
  type: USER_FEEDBACK_LIST_FETCHED,
  payload
});

export interface UserFeedbackListRespondedPayload {
  responded: boolean;
  feedbackId: number;
}

export interface UserFeedbackListRespondedAction
  extends Action<typeof USER_FEEDBACK_LIST_RESPONDED> {
  payload: UserFeedbackListRespondedPayload;
}

export const userFeedbackListRespondedAction = (
  payload: UserFeedbackListRespondedPayload
): UserFeedbackListRespondedAction => ({
  type: USER_FEEDBACK_LIST_RESPONDED,
  payload
});

export interface UserFeedbackListSetRespondedPayload {
  feedbackId: number;
  responded: boolean;
}

export interface UserFeedbackListSetRespondedAction
  extends Action<typeof USER_FEEDBACK_LIST_SET_RESPONDED> {
  payload: UserFeedbackListSetRespondedPayload;
}

export const userFeedbackListSetRespondedAction = (
  payload: UserFeedbackListSetRespondedPayload
): UserFeedbackListSetRespondedAction => ({
  type: USER_FEEDBACK_LIST_SET_RESPONDED,
  payload
});
