import React, { FormEvent, ReactNode, RefObject } from 'react';
import { Button, CustomInput, Form, FormGroup, Label } from 'reactstrap';
import PlantGroups from 'modules/PlantGroups/model/PlantGroups';
import PlantGroupsAutocomplete from 'modules/PlantGroups/container/Autocomplete';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import { MeasurementType } from 'modules/Shared/configs/measurementStatusDescEntity/type';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import Wysiwyg from 'modules/Layout/component/Wysiwyg';
import { mapEditorStateToHtml } from 'modules/Layout/helper/wysiwyg';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANT_GROUPS } from 'modules/PlantGroups/routes';
import { bulkUpdatePlantGroups } from 'modules/PlantGroups/repository';
import Loader from 'modules/Layout/component/Loader';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  bulkUpdatePlantGroupsToastError,
  bulkUpdatePlantGroupsToastSuccess
} from 'modules/PlantGroups/toasts';
import { faqToolbar } from 'modules/Layout/component/Wysiwyg/config';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export type Props = DispatchProps;

export interface State {
  plantGroups: PlantGroups[];
  updateTemperature: boolean;
  updateMoisture: boolean;
  updateFertility: boolean;
  updateLight: boolean;
  lang: string;
  fetching: boolean;
}

interface MeasurementStatusesRef {
  1: RefObject<any>;
  2: RefObject<any>;
  3: RefObject<any>;
  4: RefObject<any>;
  5: RefObject<any>;
  [index: number]: any;
}

export interface MeasurementPayload {
  lang: string;
  measurement: MeasurementType;
  measurement_status: number;
  description: string;
  long_term_description: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

class PlantGroupBulkUpdateForm extends React.Component<Props, State> {
  temperature: {
    short: MeasurementStatusesRef;
    long: MeasurementStatusesRef;
  };

  soil_moisture: {
    short: MeasurementStatusesRef;
    long: MeasurementStatusesRef;
  };

  soil_fertility: {
    short: MeasurementStatusesRef;
    long: MeasurementStatusesRef;
  };

  light: {
    short: MeasurementStatusesRef;
    long: MeasurementStatusesRef;
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      plantGroups: [],
      updateTemperature: false,
      updateMoisture: false,
      updateFertility: false,
      updateLight: false,
      lang: 'en',
      fetching: false
    };

    const createRefs = () => ({
      1: React.createRef(),
      2: React.createRef(),
      3: React.createRef(),
      4: React.createRef(),
      5: React.createRef()
    });

    this.temperature = {
      short: createRefs(),
      long: createRefs()
    };

    this.soil_moisture = {
      short: createRefs(),
      long: createRefs()
    };

    this.soil_fertility = {
      short: createRefs(),
      long: createRefs()
    };

    this.light = {
      short: createRefs(),
      long: createRefs()
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.getMeasurementData = this.getMeasurementData.bind(this);
  }

  getDescriptionsData(
    measurement: MeasurementType,
    status: number
  ): {
    description: string;
    long_term_description: string;
  } {
    const { short, long } = this[measurement];

    return {
      description: mapEditorStateToHtml(
        short[status].current.state.editorState
      ),
      long_term_description: mapEditorStateToHtml(
        long[status].current.state.editorState
      )
    };
  }

  getMeasurementData(measurement: MeasurementType): MeasurementPayload[] {
    const { lang } = this.state;

    return [
      {
        lang,
        measurement,
        measurement_status: 1,
        description: this.getDescriptionsData(measurement, 1).description,
        long_term_description: this.getDescriptionsData(measurement, 1)
          .long_term_description
      },
      {
        lang,
        measurement,
        measurement_status: 2,
        description: this.getDescriptionsData(measurement, 2).description,
        long_term_description: this.getDescriptionsData(measurement, 2)
          .long_term_description
      },
      {
        lang,
        measurement,
        measurement_status: 3,
        description: this.getDescriptionsData(measurement, 3).description,
        long_term_description: this.getDescriptionsData(measurement, 3)
          .long_term_description
      },
      {
        lang,
        measurement,
        measurement_status: 4,
        description: this.getDescriptionsData(measurement, 4).description,
        long_term_description: this.getDescriptionsData(measurement, 4)
          .long_term_description
      },
      {
        lang,
        measurement,
        measurement_status: 5,
        description: this.getDescriptionsData(measurement, 5).description,
        long_term_description: this.getDescriptionsData(measurement, 5)
          .long_term_description
      }
    ];
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    const {
      plantGroups,
      updateLight,
      updateFertility,
      updateMoisture,
      updateTemperature
    } = this.state;
    const { addToast } = this.props;

    if (!plantGroups.length) {
      return;
    }

    let data: MeasurementPayload[] = [];

    if (updateLight) {
      data = [...data, ...this.getMeasurementData('light')];
    }
    if (updateFertility) {
      data = [...data, ...this.getMeasurementData('soil_fertility')];
    }
    if (updateMoisture) {
      data = [...data, ...this.getMeasurementData('soil_moisture')];
    }
    if (updateTemperature) {
      data = [...data, ...this.getMeasurementData('temperature')];
    }

    if (!data.length) {
      return;
    }

    try {
      this.setState({
        fetching: true
      });

      await bulkUpdatePlantGroups(
        plantGroups.map((group) => group.id),
        data
      );

      addToast(bulkUpdatePlantGroupsToastSuccess());
    } catch (error) {
      console.log(error);
      addToast(bulkUpdatePlantGroupsToastError());
    } finally {
      this.setState({
        fetching: false
      });
    }
  }

  renderMeasurementStatuses(measurement: MeasurementType): ReactNode {
    return (
      <>
        <FormGroup>
          <div className="row">
            <Label className="col-2">Status</Label>
            <Label className="col-5">Short term description</Label>
            <Label className="col-5">Long term description</Label>
          </div>
          <FormGroup className="row">
            <div className="col-2">Too low</div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].short[1]} toolbar={faqToolbar} />
            </div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].long[1]} toolbar={faqToolbar} />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className="col-2">Low</div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].short[2]} toolbar={faqToolbar} />
            </div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].long[2]} toolbar={faqToolbar} />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className="col-2">Perfect</div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].short[3]} toolbar={faqToolbar} />
            </div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].long[3]} toolbar={faqToolbar} />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className="col-2">High</div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].short[4]} toolbar={faqToolbar} />
            </div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].long[4]} toolbar={faqToolbar} />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className="col-2">Too high</div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].short[5]} toolbar={faqToolbar} />
            </div>
            <div className="col-5">
              <Wysiwyg ref={this[measurement].long[5]} toolbar={faqToolbar} />
            </div>
          </FormGroup>
        </FormGroup>
      </>
    );
  }

  render(): ReactNode {
    const {
      plantGroups,
      updateTemperature,
      updateFertility,
      updateLight,
      updateMoisture,
      lang,
      fetching
    } = this.state;

    return (
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10">
          <div className="card p-3">
            <Form onSubmit={this.onSubmit}>
              {fetching && <Loader />}
              <FormGroup>
                <Label>Plant groups:</Label>
                <PlantGroupsAutocomplete
                  onChangeObject={(plantGroup) =>
                    this.setState({
                      plantGroups: [...plantGroups, plantGroup]
                    })
                  }
                  label="Choose plant groups for update"
                  closeOnSelect={false}
                  disabledOption={(plantGroup) =>
                    plantGroups.some(
                      (attached) => attached.id === plantGroup.id
                    )
                  }
                />
              </FormGroup>
              <FormGroup>
                <div className="row">
                  {plantGroups.map((plantGroup, index) => {
                    return (
                      <div
                        key={plantGroup.id}
                        className="col-12 col-sm-6 col-md-3 mb-1 d-flex align-items-center"
                      >
                        <span className="font-weight-bold">{`${
                          index + 1
                        }. `}</span>
                        <div className="mx-1">{plantGroup.name}</div>
                        <ActionDelete
                          className="mr-2 ml-auto"
                          onClick={() =>
                            this.setState({
                              plantGroups: plantGroups.filter(
                                (attachedPlantGroup) =>
                                  attachedPlantGroup.id !== plantGroup.id
                              )
                            })
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="w-100">Language:</Label>
                <CustomInput
                  id="language"
                  type="select"
                  style={{ width: '200px' }}
                  onChange={(event) =>
                    this.setState({ lang: event.target.value })
                  }
                  required
                  value={lang}
                >
                  {supportedLangs.map((suppLang) => (
                    <option key={suppLang} value={suppLang}>
                      {suppLang}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label>Measurements:</Label>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  id="temperature"
                  type="checkbox"
                  checked={updateTemperature}
                  onChange={(event) =>
                    this.setState({ updateTemperature: event.target.checked })
                  }
                  label="Temperature"
                />
              </FormGroup>
              {updateTemperature &&
                this.renderMeasurementStatuses('temperature')}
              <FormGroup>
                <CustomInput
                  id="moisture"
                  type="checkbox"
                  checked={updateMoisture}
                  onChange={(event) =>
                    this.setState({ updateMoisture: event.target.checked })
                  }
                  label="Soil moisture"
                />
              </FormGroup>
              {updateMoisture &&
                this.renderMeasurementStatuses('soil_moisture')}
              <FormGroup>
                <CustomInput
                  id="fertility"
                  type="checkbox"
                  checked={updateFertility}
                  onChange={(event) =>
                    this.setState({ updateFertility: event.target.checked })
                  }
                  label="Soil fertility"
                />
              </FormGroup>
              {updateFertility &&
                this.renderMeasurementStatuses('soil_fertility')}
              <FormGroup>
                <CustomInput
                  id="light"
                  type="checkbox"
                  checked={updateLight}
                  onChange={(event) =>
                    this.setState({ updateLight: event.target.checked })
                  }
                  label="Light"
                />
              </FormGroup>
              {updateLight && this.renderMeasurementStatuses('light')}
              <div className="d-flex mt-3 justify-content-between">
                <Link
                  className="btn btn-secondary"
                  to={getPathUrl(ROUTE_PLANT_GROUPS)}
                >
                  Cancel
                </Link>
                <Button type="submit" color="success">
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect<null, DispatchProps>(
  null,
  mapDispatch
)(PlantGroupBulkUpdateForm);
