import classNames from 'classnames';
import _get from 'lodash/get';
import { SortParams } from 'modules/Shared/type';
import React from 'react';
import { Table as Tablestrap, TableProps as TablestrapProps } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router';
import './style.scss';

export interface TableCol<T = never> {
  property: Extract<keyof T, string> | string;
  label: React.ReactNode;
  sortable?: boolean;
  type?: 'img' | 'field';
  value?: (row: T, index: number) => React.ReactNode;
  columnRedirectTo?: (row: T) => string;
  className?: string;
  selectableField?: {
    onFieldClick?: (id: number) => void;
    fieldContent?: React.ReactNode;
  };
}

export interface TableProps<T = never> extends RouteComponentProps {
  cols: TableCol<T>[];
  rows?: T[];
  selected?: React.ReactText[];
  sort?: SortParams;
  colKey?: (column: TableCol<T>, index: number) => React.ReactText;
  rowKey?: (row: T, index: number) => React.ReactText;
  onSort?: (params: SortParams) => void;
  onRowClickPath?: string;
  specialOnClick?: (id: number | string) => void;
  onRowClickPathIdMappedBy?: string;
  tableProps?: TablestrapProps;
}

class Table<T = never> extends React.Component<TableProps<T>> {
  constructor(props: TableProps<T>) {
    super(props);

    this.getColSpan = this.getColSpan.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getColKey = this.getColKey.bind(this);
    this.getRowKey = this.getRowKey.bind(this);
    this.getSortIcon = this.getSortIcon.bind(this);
    this.toggleSort = this.toggleSort.bind(this);
    this.renderHeaders = this.renderHeaders.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderSortableHeader = this.renderSortableHeader.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  getColSpan(): number {
    const { cols } = this.props;

    return cols.length;
  }

  getValue(col: TableCol<T>, row: T, index: number): React.ReactNode {
    if (col.value) {
      return col.value(row, index);
    }

    return _get(row, col.property, null);
  }

  getColKey(col: TableCol<T>, index: number): string | number {
    const { colKey } = this.props;

    if (colKey) {
      return colKey(col, index);
    }

    return col.property;
  }

  getRowKey(row: T, index: number): string | number {
    const { rowKey } = this.props;

    if (rowKey) {
      return rowKey(row, index);
    }

    return `row-${index}`;
  }

  getSortIcon(property: string): React.ReactNode {
    const { sort } = this.props;

    if (sort.sort === property) {
      if (sort.sort_method === 'desc') {
        return <i className="fa fa-sort-down ml-2" />;
      }

      return <i className="fa fa-sort-up ml-2" />;
    }

    return <i className="fa fa-sort ml-2" />;
  }

  toggleSort(property: string): SortParams {
    const { sort } = this.props;

    const params: SortParams = {};

    if (sort.sort === property) {
      if (sort.sort_method === 'desc') {
        params.sort = property;
        params.sort_method = 'asc';
      }
    } else {
      params.sort = property;
      params.sort_method = 'desc';
    }

    return params;
  }

  renderHeaders(): React.ReactNode {
    const { cols } = this.props;

    return cols.map((col, index) => {
      return col.sortable
        ? this.renderSortableHeader(col, index)
        : this.renderHeader(col, index);
    });
  }

  renderHeader(col: TableCol<T>, index: number): React.ReactNode {
    const { className = 'col-1' } = col;
    return (
      <th key={this.getColKey(col, index)} className={className}>
        {col.label}
      </th>
    );
  }

  renderSortableHeader(col: TableCol<T>, index: number): React.ReactNode {
    const { onSort = () => null } = this.props;

    const params = this.toggleSort(col.property);
    const { className = 'col-1' } = col;

    return (
      <th
        key={this.getColKey(col, index)}
        title={`Sort by ${col.label}`}
        role="button"
        onClick={() => onSort(params)}
        className={className}
      >
        <div className="d-flex justify-content-between align-items-center">
          {col.label}
          {this.getSortIcon(col.property)}
        </div>
      </th>
    );
  }

  renderRow(row: any, rowIndex: number): React.ReactNode {
    const {
      cols,
      selected,
      onRowClickPath,
      onRowClickPathIdMappedBy,
      specialOnClick,
      history
    } = this.props;

    const key = this.getRowKey(row, rowIndex);

    const onClickHandler = () => {
      if (specialOnClick) {
        return specialOnClick(row?.id ?? null);
      }
      if (onRowClickPath) {
        if (onRowClickPathIdMappedBy && row?.[onRowClickPathIdMappedBy]) {
          return history.push(
            `${onRowClickPath}${row?.[onRowClickPathIdMappedBy]}`
          );
        }
        return history.push(`${onRowClickPath}${row.id}`);
      }
      return null;
    };

    return (
      <tr
        key={key}
        style={{ wordBreak: 'break-word' }}
        className={`col-12 ${classNames({ active: selected?.includes(key) })} ${
          onRowClickPath || specialOnClick
            ? 'cursor-pointer add-list-hover'
            : ''
        }`}
        onClick={onClickHandler}
      >
        {cols.map((col, colIndex) => {
          const { className = 'col-1', columnRedirectTo } = col;
          const value = this.getValue(col, row, rowIndex);

          const onClickUrl = columnRedirectTo ? columnRedirectTo(row) : null;
          const redirectToUrl = onClickUrl
            ? () => history.push(onClickUrl)
            : null;

          if (col.type === 'img' && value) {
            return (
              <td
                className={`${className} ${
                  redirectToUrl ? 'cursor-pointer column-hover' : ''
                }`}
                key={this.getColKey(col, colIndex)}
                vertical-align="middle"
                onClick={redirectToUrl}
              >
                <div className="d-flex justify-content-center">
                  <img src={`${value}`} width="50px" />
                </div>
              </td>
            );
          }

          if (col.type === 'field' && col.selectableField) {
            return (
              <td
                className={`${className} ${
                  redirectToUrl ? 'cursor-pointer column-hover' : ''
                }`}
                key={this.getColKey(col, colIndex)}
                vertical-align="middle"
                onClick={redirectToUrl}
              >
                <div className="d-flex justify-content-center">
                  <div
                    onClick={() => col.selectableField.onFieldClick?.(row?.id)}
                  >
                    {col.selectableField.fieldContent}
                  </div>
                </div>
              </td>
            );
          }

          return (
            <td
              key={this.getColKey(col, colIndex)}
              className={`${className} ${
                redirectToUrl ? 'cursor-pointer column-hover' : ''
              }`}
              onClick={redirectToUrl}
            >
              {value}
            </td>
          );
        })}
      </tr>
    );
  }

  render(): React.ReactNode {
    const { rows = [], tableProps } = this.props;

    return (
      <Tablestrap
        striped={tableProps?.striped ?? false}
        bordered={tableProps?.bordered ?? true}
        responsive={tableProps?.responsive ?? true}
        className={classNames('mb-0', tableProps?.className)}
        {...tableProps} /* eslint-disable-line react/jsx-props-no-spreading */
      >
        <thead>
          <tr>{this.renderHeaders()}</tr>
        </thead>
        {rows.length > 0 ? (
          <tbody className="border">{rows.map(this.renderRow)}</tbody>
        ) : (
          <tfoot>
            <tr>
              <td colSpan={this.getColSpan()} className="text-center">
                No data
              </td>
            </tr>
          </tfoot>
        )}
      </Tablestrap>
    );
  }
}

export default withRouter(Table);
