import { RootState } from 'app/reducer';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import {
  FETCH_SENSOR_DETAILS,
  fetchSensorDetailsAction,
  sensorDetailsFetchedAction,
  SET_SENSOR_DETAILS_PARAMS
} from 'modules/Sensor/action/details';
import {
  getSensorDetails,
  GetSensorDetailsResponse
} from 'modules/Sensor/repository';
import { manageThrow } from 'modules/Shared/action';

export function* setSensorDetailsParamsSaga(): SagaIterator {
  yield put(fetchSensorDetailsAction());
}

export function* fetchSensorDetailsSaga(): SagaIterator {
  try {
    const { sensorId } = yield select(
      (state: RootState) => state.sensor.sensorDetails
    );

    const response: GetSensorDetailsResponse = yield call(
      getSensorDetails,
      sensorId
    );

    const { data } = response;

    yield put(sensorDetailsFetchedAction({ sensorDetails: data }));
  } catch (error) {
    console.log(error);
    if (error instanceof Validation) {
      yield put(sensorDetailsFetchedAction({ sensorDetails: null }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* sensorDetailsSaga(): SagaIterator {
  yield takeLatest(SET_SENSOR_DETAILS_PARAMS, setSensorDetailsParamsSaga);
  yield takeLatest(FETCH_SENSOR_DETAILS, fetchSensorDetailsSaga);
}
