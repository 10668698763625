import { RootState } from 'app/reducer';
import { setLocationSearchAction } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_SENSOR_LIST,
  fetchSensorListAction,
  SET_SENSOR_LIST_PARAMS,
  sensorListFetchedAction
} from 'modules/Sensor/action/sensorList';
import { fetchSensor, FetchSensorsResponse } from 'modules/Sensor/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';

export function* setSensorListParamsSaga(): SagaIterator {
  yield put(fetchSensorListAction());
}

export function* fetchSensorListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.sensor.sensorList
    );

    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    let response: FetchSensorsResponse = yield call(fetchSensor, {
      ...sort,
      pagination,
      search: filter
    });

    const { content, totalCount } = response.data;
    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount?.[0]?.['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      sensorListFetchedAction({
        sensors: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(sensorListFetchedAction({ sensors: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantListSaga(): SagaIterator {
  yield takeLatest(SET_SENSOR_LIST_PARAMS, setSensorListParamsSaga);
  yield takeLatest(FETCH_SENSOR_LIST, fetchSensorListSaga);
}
