import React from 'react';
import { Button, Spinner } from 'reactstrap';

interface IProps {
  callback: () => void;
  text: string;
  loading?: boolean;
  inactive?: boolean;
  bsColor?: string;
  classNames?: string;
  type?: 'button' | 'submit';
}

export const StandardBtn: React.FC<IProps> = ({
  callback,
  text,
  loading,
  inactive,
  bsColor,
  classNames,
  type = 'button'
}) => {
  if (!bsColor) bsColor = 'info';
  if (!classNames) classNames = 'm-3';

  if (inactive)
    return (
      <div className={`d-flex justify-content-center ${classNames}`}>
        <Button
          type={type}
          color={bsColor}
          disabled
          className="cursor-not-allowed"
        >
          {text}
        </Button>
      </div>
    );
  return (
    <div className={`d-flex justify-content-center ${classNames}`}>
      <Button type={type} color={bsColor} onClick={callback}>
        {loading ? <Spinner size="sm" /> : text}
      </Button>
    </div>
  );
};
