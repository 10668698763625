import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { SensorEntity } from 'modules/Sensor/model/Sensor';
import { Action } from 'redux';

export const SET_SENSOR_LIST_PARAMS = 'SENSOR/LIST/PARAMS/SET';
export const FETCH_SENSOR_LIST = 'SENSOR/LIST/FETCH';
export const SENSOR_LIST_FETCHED = 'SENSOR/LIST/FETCHED';

export type SensorListAction =
  | SetSensorListParamsAction
  | FetchSensorListAction
  | SensorListFetchedAction;

export type SetSensorListParamsAction = SetSearchingParamsAction<
  typeof SET_SENSOR_LIST_PARAMS
>;

export const setSensorListParamsAction = (
  payload: PartialSearchingProps
): SetSensorListParamsAction =>
  setSearchingParamsAction(SET_SENSOR_LIST_PARAMS, payload);

export type FetchSensorListAction = Action<typeof FETCH_SENSOR_LIST>;

export const fetchSensorListAction = (): FetchSensorListAction => ({
  type: FETCH_SENSOR_LIST
});

export type SensorListFetchedPayload = {
  sensors: SensorEntity[];
  totalPages: number;
};

export interface SensorListFetchedAction
  extends Action<typeof SENSOR_LIST_FETCHED> {
  payload: SensorListFetchedPayload;
}

export const sensorListFetchedAction = (
  payload: SensorListFetchedPayload
): SensorListFetchedAction => ({
  type: SENSOR_LIST_FETCHED,
  payload
});
