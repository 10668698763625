import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import {
  MeasurementsStatusDescEntity,
  measurementTypes,
  measurementStatusesOptions,
  MeasurementTypesEnum
} from 'modules/Shared/configs/measurementStatusDescEntity/type';
import { extractValueFromMeasurement } from 'modules/Shared/configs/measurementStatusDescEntity/helper/getDataFromMeasurement';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';

interface IProps {
  editUrl: (lang: string, type: string) => string;
  supportedLang: string[];
  measurements?: MeasurementsStatusDescEntity[];
  isDeleted?: boolean;
}

const DetailsMeasurementDesc: React.FC<IProps> = ({
  editUrl,
  measurements,
  supportedLang,
  isDeleted
}) => {
  const history = useHistory();
  const [langs, setLangs] = useState(
    supportedLang.map((_, index) => index === 0)
  );

  const { state: locationState } = useLocation<any>();

  const prevMeasurement = locationState?.option;

  const [measurementsNav, setMeasurementsNav] = useState(
    measurementTypes.map((measurement, index) =>
      prevMeasurement ? measurement === prevMeasurement : index === 0
    )
  );

  const findLang = supportedLang[langs.findIndex((lang) => lang)];
  const findType =
    measurementTypes[measurementsNav.findIndex((option) => option)];

  const onEditBtnClick = !isDeleted
    ? () => history.push(editUrl(findLang, findType))
    : null;

  return (
    <DisplayBaseTabContent
      title="Measurement descriptions (What to do)"
      onEditBtnClick={onEditBtnClick}
    >
      <DisplayNav
        booleanArr={langs}
        setBooleanArr={setLangs}
        valueArr={supportedLang}
      />
      <div className="mt-1" />
      <DisplayNav
        booleanArr={measurementsNav}
        setBooleanArr={setMeasurementsNav}
        valueArr={measurementTypes}
      />
      <div className="tab-content pl-2 pr-2">
        {displayOptionRowContent(
          measurementStatusesOptions.map((title) => {
            const { description, long_term_description } =
              extractValueFromMeasurement(
                {
                  measurement: findType as MeasurementTypesEnum,
                  lang: findLang,
                  option: title
                },
                measurements
              );

            return {
              title,
              description: `<div class="font-weight-bold">Short term description</div><div>${description}</div><div class="font-weight-bold">Long term description</div><div>${long_term_description}</div>`,
              html: true
            };
          }),
          false,
          null,
          true
        )}
      </div>
    </DisplayBaseTabContent>
  );
};

export default DetailsMeasurementDesc;
