import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteDetailsPlant } from 'modules/Plants/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/reducer';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import DetailsMain from 'modules/Plants/view/Details/components/DisplayDetailsMain';
import DetailsThresholds from 'modules/Plants/view/Details/components/DisplayDetailsThresholds';
import DetailsDescriptions from 'modules/Plants/view/Details/components/DisplayDetailsDescriptions';
import DetailsPlantMeasurementDesc from 'modules/Plants/view/Details/components/DisplayDetailsMeasurementDesc';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import Loader from 'modules/Layout/component/Loader';
import { setPlantDetailsParamsAction } from 'modules/Plants/action/Details';
import {
  ROUTE_PLANT_DETAILS_FORM_REDIRECT_TEMPLATE,
  ROUTE_PLANT_EDIT_DESCRIPTIONS,
  ROUTE_PLANTS
} from 'modules/Plants/routes';
import { PLANT_DETAILS_MEASUREMENT_DESC } from 'modules/Plants/view/DetailsForm/helper/acceptableTypes';
import PlantSections from 'modules/Plants/component/Sections';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import { getPathUrl } from 'modules/Shared/helper/api';
import MeasurementsKnowHow from 'modules/Plants/component/MeasurementsKnowHow';
import { Button } from 'reactstrap';
import Table from 'modules/Layout/component/Table';
import PlantSynonymsModal from 'modules/Plants/component/Modal/Synonyms';

interface IParams {
  id: string;
}

export const NAV_THRESHOLDS = 'Thresholds';
export const NAV_DESCRIPTIONS_AND_SECTIONS = 'Descriptions & Sections';
export const NAV_SYNONYMS = 'Synonyms';
export const NAV_PLANT_MEASUREMENTS_DESC = 'Measurements desc (What to do)';
export const NAV_PLANT_MEASUREMENTS_KNOW_HOW =
  'Measurements know-how (Tutorials)';

export const PlantDetailsView = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const location = useLocation<any>();
  const dispatch = useDispatch();
  const details = useSelector((state: RootState) => state.plant.details);
  const { type } = useSelector((state: RootState) => state.layout.page);
  const synonymsModalRef = useRef(null);

  const navElements = [
    'Main data',
    NAV_SYNONYMS,
    NAV_DESCRIPTIONS_AND_SECTIONS,
    NAV_THRESHOLDS,
    NAV_PLANT_MEASUREMENTS_DESC,
    NAV_PLANT_MEASUREMENTS_KNOW_HOW
  ];
  const [nav, setNav] = useState(navElements.map((_, index) => index === 0));

  useEffect(() => {
    dispatch(
      managePageAction({
        title: `${
          details.plantDetails ? details.plantDetails.scientific_name : 'Plant'
        }`,
        breadcrumb: breadcrumbRouteDetailsPlant(details.plantDetails)
      })
    );
  }, [details.plantDetails, dispatch]);

  useEffect(() => {
    if (params?.id) {
      const id = parseInt(params.id, 10);
      if (id) dispatch(setPlantDetailsParamsAction(id));
      else history.push(ROUTE_PLANTS);
    }

    if (
      typeof location.state === 'object' &&
      // eslint-disable-next-line no-prototype-builtins
      location.state.hasOwnProperty('navElementChange')
    ) {
      const navElIndex = navElements.findIndex(
        (navEl) => navEl === location.state.navElementChange
      );
      if (navElIndex > -1) {
        setNav(navElements.map((_, index) => index === navElIndex));
      }
    }
  }, []);

  if (type) return null;

  if (details.fetching)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );

  return (
    <div className="position-relative">
      <PlantSynonymsModal ref={synonymsModalRef} />
      <div className="details-top-nav mb-3">
        <DisplayNav
          valueArr={navElements}
          booleanArr={nav}
          setBooleanArr={setNav}
        />
      </div>

      {nav[0] &&
        details.plantDetails &&
        details.plantDetails.id === details.plantId && (
          <DetailsMain
            data={details.plantDetails}
            imagesURL={
              details.plantDetails?.images?.map((image) => {
                return { id: image.id, url: image.origin_path };
              }) || []
            }
            supportedLang={supportedLangs}
          />
        )}
      {nav[1] && (
        <div className="content restrict-width">
          <DisplayBaseTabContent
            onEditBtnClick={
              !details.plantDetails?.is_deleted
                ? () => synonymsModalRef?.current?.open(details.plantDetails)
                : null
            }
            title="Synonyms"
          >
            <div className="tab-content mt-3">
              <Table
                cols={[
                  {
                    property: null,
                    value: (row) => row as string,
                    label: 'Synonym'
                  }
                ]}
                rows={details.plantDetails.synonyms}
              />
            </div>
          </DisplayBaseTabContent>
        </div>
      )}
      {nav[2] &&
        details.plantDetails &&
        details.plantDetails.id === details.plantId && (
          <>
            {!details.plantDetails.is_deleted && (
              <div className="d-flex justify-content-end">
                <Button
                  className="mt-2 mb-2"
                  color="secondary"
                  onClick={() =>
                    history.push(
                      getPathUrl(ROUTE_PLANT_EDIT_DESCRIPTIONS, {
                        id: details.plantDetails.id
                      })
                    )
                  }
                >
                  Edit descriptions and sections
                </Button>
              </div>
            )}
            <DetailsDescriptions
              data={details.plantDetails}
              supportedLang={supportedLangs}
            />
            <div className="content restrict-width">
              <DisplayBaseTabContent title="Sections">
                <PlantSections plantSections={details.plantDetails?.sections} />
              </DisplayBaseTabContent>
            </div>
          </>
        )}
      {nav[3] &&
        details.plantDetails &&
        details.plantDetails.id === details.plantId && (
          <DetailsThresholds data={details.plantDetails} />
        )}
      {nav[4] && (
        <DetailsPlantMeasurementDesc
          editUrl={(lang, measurementType) =>
            `${
              ROUTE_PLANT_DETAILS_FORM_REDIRECT_TEMPLATE +
              PLANT_DETAILS_MEASUREMENT_DESC
            }/${details.plantId}/${lang}/${measurementType}`
          }
          measurements={details.plantDetails?.measurements_statuses_desc}
          supportedLang={supportedLangs}
          isDeleted={details.plantDetails?.is_deleted}
        />
      )}
      {nav[5] && (
        <>
          <MeasurementsKnowHow
            plant={details.plantDetails}
            measurementsKnowHow={
              details.plantDetails?.measurements_statuses_know_hows
            }
            showActions={!details.plantDetails?.is_deleted}
          />
        </>
      )}
    </div>
  );
};

export default PlantDetailsView;
