import React from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import { PlantUserDetailsMeasurementsEntity } from 'modules/PlantUser/model/PlantUserDetails';
import {
  getMeasurementStatus,
  getPhStatus
} from 'modules/Shared/configs/measurementStatusDescEntity/type';

interface IProps {
  data: PlantUserDetailsMeasurementsEntity;
}

const DetailsMeasurements: React.FC<IProps> = ({ data }) => {
  console.log(data);
  const convertData = Object.entries(data).map(([key, value]) => [
    key,
    value?.unit || null,
    { title: 'status', description: value?.status },
    ...Object.entries(value?.values || {})?.map((value) => ({
      title: value[0],
      description: value[1]
    }))
  ]);

  console.log(convertData);

  return (
    <DisplayBaseTabContent title="Measurements" onEditBtnClick={null}>
      <div className="mt-3 ml-2">
        {convertData.map((mainValue, index) => {
          if (mainValue.length < 4) return null;
          const [title, unit, ...restArr] = mainValue;
          restArr.unshift(restArr.pop());

          return (
            <div className="form-group p-2 pb-4 border-bottom" key={index}>
              <div
                className="h4 mb-2"
                style={{ textTransform: 'capitalize' }}
                key={index}
              >
                {(title as string) + (unit ? ` (${unit})` : '')}
              </div>
              <div className="row">
                {restArr.map((value, index) => {
                  if (typeof value === 'string') return null;
                  const { title, description } = value;
                  return (
                    <div
                      className={`form-row col-sm-6${
                        title === 'status'
                          ? ' col-xl-9 order-1'
                          : ` col-xl-3 order-${index + 1}`
                      }`}
                      key={index}
                    >
                      <div className="bv-no-focus-ring d-flex font-weight-bold pt-1 pl-1 mr-3">
                        {title?.replace('_', ' ')}
                      </div>
                      <div className="pt-1 pl-2">
                        {(title === 'status'
                          ? (mainValue[0] as string) === 'ph'
                            ? getPhStatus(description)
                            : getMeasurementStatus(description)
                          : description) ?? '-'}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </DisplayBaseTabContent>
  );
};

export default DetailsMeasurements;
