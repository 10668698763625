import React from 'react';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import PlantAutocomplete from '../../../../Plants/container/Autocomplete/Plant';
import Plant from '../../../../Plants/model/Plant';
import PlantGroups from '../../../../PlantGroups/model/PlantGroups';
import PlantGroupsAutocomplete from '../../../../PlantGroups/container/Autocomplete';
import FamiliesAutocomplete from '../../../../Family/container/Autocomplete';
import Family from '../../../../Family/model/Family';
import { getMeasurementStatus } from '../../../../Shared/configs/measurementStatusDescEntity/type';
import ActionDelete from '../../../../Layout/component/Action/Delete';

export interface AttachToMeasurementsValues {
  [measurement: string]: any;
  temperature: {
    1: boolean;
    2: boolean;
    3: boolean;
    4: boolean;
    5: boolean;
  };
  light: {
    1: boolean;
    2: boolean;
    3: boolean;
    4: boolean;
    5: boolean;
  };
  soil_moisture: {
    1: boolean;
    2: boolean;
    3: boolean;
    4: boolean;
    5: boolean;
  };
  soil_fertility: {
    1: boolean;
    2: boolean;
    3: boolean;
    4: boolean;
    5: boolean;
  };
}

export interface Values {
  attachToPlants: Plant[];
  attachToPlantGroups: PlantGroups[];
  attachToFamilies: Family[];
  attachToMeasurements: AttachToMeasurementsValues;
}

export interface Props {
  values: Values;
  onChange: (values: Values) => void;
}

const AttachToAsKnowHowFieldset: React.FC<Props> = (
  props: Props
): JSX.Element => {
  const { values, onChange } = props;
  const {
    attachToPlants,
    attachToPlantGroups,
    attachToFamilies,
    attachToMeasurements
  } = values;

  const anyMeasurementChecked = Object.keys(attachToMeasurements).some(
    (measurement) => {
      return Object.keys(attachToMeasurements[measurement]).some(
        (status) => attachToMeasurements[measurement][status]
      );
    }
  );

  return (
    <>
      <FormGroup className="mb-3">
        <Label>Measurements statuses to be attached:</Label>
        {Object.keys(attachToMeasurements).map((measurement) => {
          return (
            <div
              className="d-flex justify-content-between align-items-center flex-wrap mb-2"
              key={measurement}
            >
              <div className="text-capitalize">
                {measurement.replace('_', ' ')}
              </div>
              <div className="d-flex align-items-center flex-wrap">
                {Object.keys(attachToMeasurements[measurement]).map(
                  (status) => {
                    return (
                      <CustomInput
                        id={`${measurement}-${status}`}
                        key={`${measurement}-${status}`}
                        className="ml-5"
                        checked={attachToMeasurements[measurement][status]}
                        required={!anyMeasurementChecked}
                        onChange={(checked) =>
                          onChange({
                            ...values,
                            attachToMeasurements: {
                              ...values.attachToMeasurements,
                              [measurement]: {
                                ...values.attachToMeasurements[measurement],
                                [status]: checked.currentTarget.checked
                              }
                            }
                          })
                        }
                        label={getMeasurementStatus(Number(status))}
                        type="checkbox"
                      />
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </FormGroup>
      <FormGroup className="mb-3">
        <Label>Plants to be attached:</Label>
        <PlantAutocomplete
          onChange={(plant) =>
            onChange({
              ...values,
              attachToPlants: [...attachToPlants, plant]
            })
          }
          closeOnSelect={false}
          label="Choose plants to be attached"
          disabledOption={(plant) =>
            attachToPlants.some((attached) => attached.id === plant.id)
          }
        />
        <div className="row">
          {attachToPlants.map((plant, index) => {
            return (
              <div
                key={plant.id}
                className="col-12 col-sm-6 col-md-3 mb-1 d-flex align-items-center"
              >
                <span className="font-weight-bold">{`${index + 1}. `}</span>
                <img
                  src={plant.thumb_path}
                  style={{ height: '30px' }}
                  className="ml-1"
                  alt=""
                />
                <div className="mx-1">{plant.scientific_name}</div>
                <ActionDelete
                  className="mr-2 ml-auto"
                  onClick={() =>
                    onChange({
                      ...values,
                      attachToPlants: attachToPlants.filter(
                        (attachedPlant) => attachedPlant.id !== plant.id
                      )
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </FormGroup>
      <FormGroup className="mb-3">
        <Label>Plant groups to be attached:</Label>
        <PlantGroupsAutocomplete
          onChangeObject={(plantGroup) =>
            onChange({
              ...values,
              attachToPlantGroups: [...attachToPlantGroups, plantGroup]
            })
          }
          label="Choose plant groups to be attached"
          closeOnSelect={false}
          disabledOption={(plantGroup) =>
            attachToPlantGroups.some(
              (attached) => attached.id === plantGroup.id
            )
          }
        />
        <div className="row">
          {attachToPlantGroups.map((plantGroup, index) => {
            return (
              <div
                key={plantGroup.id}
                className="col-12 col-sm-6 col-md-3 mb-1 d-flex align-items-center"
              >
                <span className="font-weight-bold">{`${index + 1}. `}</span>
                <div className="mx-1">{plantGroup.name}</div>
                <ActionDelete
                  className="mr-2 ml-auto"
                  onClick={() =>
                    onChange({
                      ...values,
                      attachToPlantGroups: attachToPlantGroups.filter(
                        (attachedPlantGroup) =>
                          attachedPlantGroup.id !== plantGroup.id
                      )
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </FormGroup>
      <FormGroup className="mb-3">
        <Label>Families to be attached:</Label>
        <FamiliesAutocomplete
          onChangeObject={(family) =>
            onChange({
              ...values,
              attachToFamilies: [...attachToFamilies, family]
            })
          }
          closeOnSelect={false}
          label="Choose families to be attached"
          disabledOption={(family) =>
            attachToFamilies.some((attached) => attached.id === family.id)
          }
        />
        <div className="row">
          {attachToFamilies.map((family, index) => {
            return (
              <div
                key={family.id}
                className="col-12 col-sm-6 col-md-3 mb-1 d-flex align-items-center"
              >
                <span className="font-weight-bold">{`${index + 1}. `}</span>
                <div className="mx-1">{family.genus}</div>
                <ActionDelete
                  className="mr-2 ml-auto"
                  onClick={() =>
                    onChange({
                      ...values,
                      attachToFamilies: attachToFamilies.filter(
                        (attachedFamily) => attachedFamily.id !== family.id
                      )
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </FormGroup>
      <FormGroup>
        If this media is already attached to plant/plant group/family, it will
        be ignored for this plant/plant group/family and will not be duplicated.
      </FormGroup>
    </>
  );
};

export default AttachToAsKnowHowFieldset;
