import { RootState } from 'app/reducer';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { authenticateAction, AuthenticateAction } from 'modules/Auth/action';
import { ROUTE_LOGIN } from 'modules/Auth/routes';
import { hasToken } from 'modules/Auth/service';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export interface StateProps {
  authenticated: boolean;
}

export interface DispatchProps {
  authenticate: () => AuthenticateAction;
  redirect: () => CallHistoryMethodAction;
}

export interface Props extends StateProps, DispatchProps {
  children?: Readonly<ReactNode>;
}

export const mapState = (state: RootState): StateProps => {
  const { authenticated } = state.auth;

  return { authenticated };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  authenticate: () => dispatch(authenticateAction()),
  redirect: () => dispatch(push(ROUTE_LOGIN))
});

export class Guard extends React.Component<Props> {
  protected readonly hasToken: boolean;

  constructor(props: Props) {
    super(props);

    this.hasToken = hasToken();
  }

  componentDidMount(): void {
    const { authenticate, redirect } = this.props;

    if (this.hasToken) {
      authenticate();
    } else {
      redirect();
    }
  }

  render(): ReactNode {
    const { authenticated, children } = this.props;

    if (authenticated) {
      return children;
    }

    return null;
  }
}

export default connect<StateProps, DispatchProps>(mapState, mapDispatch)(Guard);
