import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import Loader from 'modules/Layout/component/Loader';
import Plant from 'modules/Plants/model/Plant';
import InformationList from 'modules/Layout/component/InformationList';
import { deletePlant } from 'modules/Plants/repository';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import {
  deletePlantToastError,
  deletePlantToastSuccess
} from 'modules/Plants/toasts';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import ApiError from 'modules/Shared/exception/ApiError';
import { ColorVariants, ValidationErrors } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onSuccess?: (response: AxiosResponse) => void;
}

export interface State {
  isOpen: boolean;
  plant: Plant;
  fetching: boolean;
  error?: ValidationErrors;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class DeletePlantModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      plant: null,
      fetching: false,
      error: null
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  openModal(plant: Plant): void {
    this.setState({
      isOpen: true,
      fetching: false,
      error: null,
      plant
    });
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  async onSubmit(): Promise<void> {
    const { onSuccess, addToast } = this.props;
    const { plant } = this.state;

    this.setState({
      fetching: true,
      error: null
    });

    try {
      const response = await deletePlant(plant.id);

      addToast(deletePlantToastSuccess());

      this.setState({
        isOpen: false
      });

      if (onSuccess) {
        onSuccess(response);
      }
    } catch (error) {
      console.log(error);
      addToast(deletePlantToastError());

      if (error instanceof ApiError) {
        this.setState({
          fetching: false,
          error: error.getErrors()
        });
      }
    }
  }

  renderTitle = (): React.ReactNode => {
    return 'Do you want to delete plant?';
  };

  renderBody(): React.ReactNode {
    const { fetching, plant, error } = this.state;

    const fields = [
      {
        label: 'Scientific name:',
        value: plant.scientific_name
      },
      {
        label: 'Name:',
        value: plant.name
      },
      {
        label: 'Genus:',
        value: plant.genus
      }
    ];

    const message = {
      value: getError(error, 'user_plants'),
      variant: ColorVariants.Danger
    };

    return (
      <>
        {fetching && <Loader />}
        {Boolean(plant.thumb_path) && (
          <div className="d-flex justify-content-center mb-3">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img style={{ width: '100%' }} src={plant.thumb_path} />
          </div>
        )}
        <InformationList fields={fields} />
        {hasError(error, 'user_plants') && <Alert message={message} />}
      </>
    );
  }

  renderFooter(): React.ReactNode {
    const { fetching } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button disabled={fetching} color="primary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button disabled={fetching} color="danger" onClick={this.onSubmit}>
          Delete
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen, plant } = this.state;

    if (!plant) {
      return null;
    }

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(DeletePlantModal);
