import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const updateAdminToastSuccess = (): AddToastPayload => ({
  header: 'Update administrator',
  body: 'Administrator was updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateAdminToastError = (): AddToastPayload => ({
  header: 'Update administrator',
  body: 'Administrator was not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deleteAdminToastSuccess = (): AddToastPayload => ({
  header: 'Delete administrator',
  body: 'Administrator was deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteAdminToastError = (): AddToastPayload => ({
  header: 'Delete administrator',
  body: 'Administrator was not deleted.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deleteUserToastSuccess = (): AddToastPayload => ({
  header: 'Delete user',
  body: 'User was deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteUserToastError = (): AddToastPayload => ({
  header: 'Delete user',
  body: 'User was not deleted.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const changeUserRoleToastSuccess = (): AddToastPayload => ({
  header: 'Role',
  body: 'Role changed.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const changeUserRoleToastError = (): AddToastPayload => ({
  header: 'Role',
  body: 'Role was not changed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
