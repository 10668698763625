import { SelectOption } from 'modules/Shared/type';

export type UserEntity = {
  id?: number;
  email?: string;
  username?: string;
  age?: number;
  birth_date?: string;
  city?: string;
  country?: string;
  lang?: string;
  plants_knowledge?: number;
  plants_owned?: number;
  status?: number;
  google_id?: string;
  fb_id?: string;
  apple_id?: string;
  created_at?: string;
};

export default class User {
  id?: number;
  email?: string;
  password?: string;
  google_id?: string;
  fb_id?: string;
  apple_id?: string;
  username?: string;
  age?: number;
  birth_date?: string;
  city?: string;
  country?: string;
  lang?: string;
  plants_knowledge?: number;
  plants_owned?: number;
  status?: number;
  created_at?: string;

  constructor(entity: UserEntity) {
    this.id = entity.id;
    this.google_id = entity.google_id;
    this.fb_id = entity.fb_id;
    this.apple_id = entity.apple_id;
    this.email = entity.email;
    this.password = null;
    this.username = entity.username;
    this.age = entity.age;
    this.birth_date = entity.birth_date;
    this.city = entity.city;
    this.country = entity.country;
    this.lang = entity.lang;
    this.plants_knowledge = entity.plants_knowledge;
    this.plants_owned = entity.plants_owned;
    this.status = entity.status;
    this.created_at = entity.created_at;
  }

  static getFilterableAttributes(): string[] {
    return [
      'email',
      'username',
      'country',
      'age_min',
      'age_max',
      'plants_owned',
      'plants_knowledge',
      'with_deleted'
    ];
  }

  static getSortableAttributes(): string[] {
    return ['username', 'birth_date', 'age', 'country', 'created_at'];
  }

  static getPlantsOwnedOptions(): SelectOption[] {
    return [
      {
        value: 1,
        label: '1-5'
      },
      {
        value: 2,
        label: '6-10'
      },
      {
        value: 3,
        label: '11-20'
      },
      {
        value: 4,
        label: '>20'
      }
    ];
  }

  static getPlantsKnowledgeOptions(): SelectOption[] {
    return [
      {
        value: 1,
        label: 'I hardly know how to keep them alive.'
      },
      {
        value: 2,
        label: 'We are slowly becoming friends.'
      },
      {
        value: 3,
        label: 'Plants love me!'
      }
    ];
  }

  static getStatusOptions(): SelectOption[] {
    return [
      {
        value: 0,
        label: 'Deleted'
      },
      {
        value: 1,
        label: 'Email verified'
      },
      {
        value: 2,
        label: 'Email not verified'
      }
    ];
  }

  isDeleted(): boolean {
    return this.status === 0;
  }

  getName(): string {
    if (this.username) {
      return this.username;
    }

    return this.email;
  }

  getPlantsKnowledge(): string {
    const option = User.getPlantsKnowledgeOptions().find(
      (opt) => opt.value === this.plants_knowledge
    );

    if (!option) {
      return null;
    }

    return option.label;
  }

  getPlantsOwned(): string {
    const option = User.getPlantsOwnedOptions().find(
      (opt) => opt.value === this.plants_owned
    );

    if (!option) {
      return null;
    }

    return option.label;
  }

  getStatus(): string {
    const option = User.getStatusOptions().find(
      (opt) => opt.value === this.status
    );

    if (!option) {
      return null;
    }

    return option.label;
  }

  getIsUsingGoogle(): string {
    if (this.google_id) {
      return 'Yes';
    }

    return 'No';
  }

  getIsUsingApple(): string {
    if (this.apple_id) {
      return 'Yes';
    }

    return 'No';
  }

  getIsUsingFacebook(): string {
    if (this.fb_id) {
      return 'Yes';
    }

    return 'No';
  }
}

export const createUser = (entity: UserEntity): User => new User(entity);

export const userStatus = (status: number) => {
  switch (status) {
    case 0:
      return 'Account deleted.';
    case 1:
      return 'Account is active.';
    case 2:
      return 'Account awaiting activation.';
    default:
      return null;
  }
};
