import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

interface IProps {
  urlImageArr: string[];
  parentControlIndex?: {
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  };
}

const CarouselComponent: React.FC<IProps> = ({
  urlImageArr,
  parentControlIndex
}) => {
  const [localActiveIndex, setLocalActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const activeIndex = parentControlIndex
    ? parentControlIndex.activeIndex
    : localActiveIndex;
  const setActiveIndex = (index: number) =>
    parentControlIndex
      ? parentControlIndex.setActiveIndex(index)
      : setLocalActiveIndex(index);

  let items;
  if (urlImageArr.length > 0) {
    items = urlImageArr.map((url, index) => {
      return {
        src: url,
        altText: `image ${index + 1}`,
        caption: ''
      };
    });
  } else {
    items = [
      {
        src: 'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg',
        altText: 'no photo',
        caption: ''
      }
    ];
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        className="img-container w-100 position-relative"
      >
        <img
          src={item.src}
          alt={item.altText}
          className="img-fluid mx-auto d-block center-position-absolute"
          style={{
            maxHeight: '400px'
          }}
        />

        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={false}
    >
      {slides}

      {urlImageArr.length > 1 && (
        <>
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
            className="carousel-slider-background"
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
            className="carousel-slider-background"
          />
        </>
      )}
    </Carousel>
  );
};

export default CarouselComponent;
