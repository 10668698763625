import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { Action } from 'redux';
import { HubSoftwareEntity } from 'modules/Hub/model/HubSoftware';

export const SET_HUB_SOFTWARE_LIST_PARAMS = 'HUB_SOFTWARE/LIST/PARAMS/SET';
export const UPDATE_HUB_SOFTWARE_LIST_PARAMS =
  'HUB_SOFTWARE/LIST/PARAMS/UPDATE';
export const FETCH_HUB_SOFTWARE_LIST = 'HUB_SOFTWARE/LIST/FETCH';
export const HUB_SOFTWARE_LIST_FETCHED = 'HUB_SOFTWARE/LIST/FETCHED';

export type HubSoftwareListAction =
  | SetHubSoftwareListParamsAction
  | FetchHubSoftwareListAction
  | HubSoftwareListFetchedAction;

export type SetHubSoftwareListParamsAction = SetSearchingParamsAction<
  typeof SET_HUB_SOFTWARE_LIST_PARAMS
>;

export const setHubSoftwareListParamsAction = (
  payload: PartialSearchingProps
): SetHubSoftwareListParamsAction =>
  setSearchingParamsAction(SET_HUB_SOFTWARE_LIST_PARAMS, payload);

export type FetchHubSoftwareListAction = Action<typeof FETCH_HUB_SOFTWARE_LIST>;

export const fetchHubSoftwareListAction = (): FetchHubSoftwareListAction => ({
  type: FETCH_HUB_SOFTWARE_LIST
});

export type HubSoftwareListFetchedPayload = {
  hubSoftwares: HubSoftwareEntity[];
  totalPages: number;
};

export interface HubSoftwareListFetchedAction
  extends Action<typeof HUB_SOFTWARE_LIST_FETCHED> {
  payload: HubSoftwareListFetchedPayload;
}

export const hubSoftwareListFetchedAction = (
  payload: HubSoftwareListFetchedPayload
): HubSoftwareListFetchedAction => ({
  type: HUB_SOFTWARE_LIST_FETCHED,
  payload
});
