import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import {
  TermsOfServiceEntity,
  TermsOfServiceLangs
} from 'modules/Policy/model/TermsOfService';
import { PrivacyPolicyEntity } from 'modules/Policy/model/PrivacyPolicy';

export const API_TOS = '/admin-terms-of-service/list';
export const API_TOS_DETAILS = '/admin-terms-of-service/:id';
export const API_TOS_CREATE = '/admin-terms-of-service';
export const API_PP = '/admin-privacy-policy/list';
export const API_PP_DETAILS = '/admin-privacy-policy/:id';
export const API_PP_CREATE = '/admin-privacy-policy';

export interface FetchTOSResponse {
  content: TermsOfServiceEntity[];
  totalCount: any;
}

export const fetchTOS = (
  params: SearchParams = {}
): AxiosPromise<FetchTOSResponse> => axios.post(getAPIUrl(API_TOS), params);

export interface FetchTOSDetailsResponse {
  tos: TermsOfServiceEntity;
}

export const fetchTOSDetails = (
  id: number | string
): AxiosPromise<FetchTOSDetailsResponse> =>
  axios.get(getAPIUrl(API_TOS_DETAILS, { id }));

export const deleteTOS = (id: number | string): AxiosPromise =>
  axios.delete(getAPIUrl(API_TOS_DETAILS, { id }));

export interface CreateTOSPayload {
  activation_date: string;
  langs: TermsOfServiceLangs;
}

export interface CreateTOSResponse {
  tos: {
    id: number;
  };
}

export const createTOS = (
  payload: CreateTOSPayload
): AxiosPromise<CreateTOSResponse> =>
  axios.post(getAPIUrl(API_TOS_CREATE), payload);

export const updateTOS = (
  id: number | string,
  payload: CreateTOSPayload
): AxiosPromise<CreateTOSResponse> =>
  axios.put(getAPIUrl(API_TOS_DETAILS, { id }), payload);

export interface FetchPPResponse {
  content: PrivacyPolicyEntity[];
  totalCount: any;
}

export const fetchPP = (
  params: SearchParams = {}
): AxiosPromise<FetchPPResponse> => axios.post(getAPIUrl(API_PP), params);

export const deletePP = (id: number | string): AxiosPromise =>
  axios.delete(getAPIUrl(API_PP_DETAILS, { id }));

export interface FetchPPDetailsResponse {
  pp: PrivacyPolicyEntity;
}

export const fetchPPDetails = (
  id: number | string
): AxiosPromise<FetchPPDetailsResponse> =>
  axios.get(getAPIUrl(API_PP_DETAILS, { id }));

export interface CreatePPPayload {
  activation_date: string;
  langs: TermsOfServiceLangs;
}

export interface CreatePPResponse {
  pp: {
    id: number;
  };
}

export const createPP = (
  payload: CreatePPPayload
): AxiosPromise<CreatePPResponse> =>
  axios.post(getAPIUrl(API_PP_CREATE), payload);

export const updatePP = (
  id: number | string,
  payload: CreatePPPayload
): AxiosPromise<CreatePPResponse> =>
  axios.put(getAPIUrl(API_PP_DETAILS, { id }), payload);
