import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { UserEntity } from 'modules/User/model/User';
import { GardenEntity } from 'modules/Garden/model/Garden';
import { ConnectedPlantListEntity } from 'modules/PlantGroups/model/PlantGroupDetails';
import { HubEntity } from 'modules/Hub/model/Hub';
import { SensorEntity } from 'modules/Sensor/model/Sensor';
import { FeedbackEntity } from './model/Feedback';
import { HelpFindPlantEntity } from 'modules/Plants/model/HelpFindPlant';
import { HealthCheckHelpEntity } from 'modules/PlantDiseases/model/HealthCheckHelp';

export const API_USERS = '/admin-user/list';
export const API_ADMINISTRATORS = '/admin-user/admin/list';
export const API_USER = '/admin-user/:id';
export const API_CREATE_ADMIN = '/admin-user/admin';
export const API_ADMIN = '/admin-user/admin/:id';
export const API_PROFILE = '/user/panel/credentials';
export const API_USER_ROLE = '/admin-user/:id/role';

export const API_USERS_FEEDBACK = '/admin-feedback/list';
export const API_USER_FEEDBACK = '/admin-feedback/:id';

export type FetchUsersResponse = AxiosResponse<{
  content: UserEntity[];
  totalCount: any;
}>;

export const fetchUsers = (
  params: SearchParams = {}
): AxiosPromise<FetchUsersResponse> =>
  axios.post(getAPIUrl(API_USERS, null), params);

export type FetchUsersFeedbackResponse = AxiosResponse<{
  content: FeedbackEntity[];
  totalCount: any;
}>;

export const fetchUsersFeedback = (
  params: SearchParams = {}
): AxiosPromise<FetchUsersFeedbackResponse> =>
  axios.post(getAPIUrl(API_USERS_FEEDBACK, null), params);

export type FetchUserFeedbackResponse = {
  feedback: FeedbackEntity;
};

export const fetchUserFeedback = (
  id: number | string
): AxiosPromise<FetchUserFeedbackResponse> =>
  axios.get(getAPIUrl(API_USER_FEEDBACK, { id }));

export const fetchAdministrators = (
  params: SearchParams = {}
): AxiosPromise<FetchUsersResponse> =>
  axios.post(getAPIUrl(API_ADMINISTRATORS, null), params);

export type FetchUserResponse = {
  user: UserEntity;
  gardens: GardenEntity[];
  plants: ConnectedPlantListEntity[];
  hubs: HubEntity[];
  sensors: SensorEntity[];
  feedback: FeedbackEntity[];
  finder_help: HelpFindPlantEntity[];
  health_check: HealthCheckHelpEntity[];
};

export const fetchUser = (
  id: number | string
): AxiosPromise<FetchUserResponse> => axios.get(getAPIUrl(API_USER, { id }));

export const deleteUser = (id: number | string): AxiosPromise =>
  axios.delete(getAPIUrl(API_USER, { id }));

export const updateUserRole = (
  id: number | string,
  role_name: string
): AxiosPromise => axios.put(getAPIUrl(API_USER_ROLE, { id }), { role_name });

export interface CreateUserResponse {
  user: UserEntity;
}

export const createAdmin = (data: {
  email: string;
  password: string;
}): AxiosPromise<CreateUserResponse> =>
  axios.post(getAPIUrl(API_CREATE_ADMIN), data);

export const updateAdmin = (data: {
  id: number | string;
  username?: string;
  password?: string;
  email: string;
}): AxiosPromise => axios.put(getAPIUrl(API_ADMIN, { id: data.id }), data);

export const updateProfile = (data: {
  email: string;
  password: string;
  repeat_password: string;
}): AxiosPromise => axios.put(getAPIUrl(API_PROFILE), data);

export const deleteAdmin = (id: number | string): AxiosPromise =>
  axios.delete(getAPIUrl(API_ADMIN, { id }));

export const editUserFeedback = (
  id: number,
  data: { responded: boolean }
): AxiosPromise => axios.put(getAPIUrl(API_USER_FEEDBACK, { id }), data);
