export type PlantImportEntity = {
  id?: number;
  file?: string;
  progress?: number;
  total?: number;
  error?: boolean;
  created_at?: string;
  updated_at?: string;
  finished_at?: string;
};

export default class PlantImport {
  readonly id?: number;
  readonly file?: string;
  readonly progress?: number;
  readonly total?: number;
  readonly error?: boolean;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly finished_at?: string;

  constructor(entity: PlantImportEntity) {
    this.id = entity.id;
    this.file = entity.file;
    this.progress = entity.progress;
    this.total = entity.total;
    this.error = entity.error;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.finished_at = entity.finished_at;
  }

  static getSortableAttributes(): string[] {
    return ['id', 'created_at', 'updated_at', 'finished_at'];
  }

  getError(): string {
    if (this.error) {
      return 'Error occur';
    }

    return 'No error';
  }

  getFinishedAt(): string {
    if (this.finished_at) {
      return this.finished_at;
    }

    return 'Not yet';
  }
}

export const createPlantImport = (entity: PlantImportEntity): PlantImport =>
  new PlantImport(entity);
