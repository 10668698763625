import {
  FETCH_NEWS_DETAILS,
  NEWS_DETAILS_FETCHED,
  SET_NEWS_DETAILS_PARAMS,
  NewsDetailsAction,
  NEWS_DETAILS_DELETED
} from 'modules/News/action/Details';
import { createNews } from 'modules/News/model/News';
import {
  initNewsDetailsState,
  NewsDetailsState
} from 'modules/News/state/details';

const initState = initNewsDetailsState();

const reducer = (
  state: NewsDetailsState = initState,
  action: NewsDetailsAction
): NewsDetailsState => {
  switch (action.type) {
    case SET_NEWS_DETAILS_PARAMS:
      return { ...state, newsId: action.payload };

    case FETCH_NEWS_DETAILS:
      return { ...state, fetching: true };

    case NEWS_DETAILS_FETCHED: {
      const { newsDetails = null } = action.payload;

      return {
        ...state,
        fetching: false,
        newsDetails: createNews(newsDetails)
      };
    }

    case NEWS_DETAILS_DELETED:
      return { ...state, newsDetails: null };

    default:
      return state;
  }
};

export default reducer;
