import { PlantGroupDetailsEntity } from 'modules/PlantGroups/model/PlantGroupDetails';
import { Action } from 'redux';

export const SET_PLANT_GROUPS_DETAILS_PARAMS =
  'PLANT_GROUPS/DETAILS/PARAMS/SET';
export const FETCH_PLANT_GROUPS_DETAILS = 'PLANT_GROUPS/DETAILS/FETCH';
export const PLANT_GROUPS_DETAILS_FETCHED = 'PLANT_GROUPS/DETAILS/FETCHED';
export const PLANT_GROUPS_DETAILS_DELETED = 'PLANT_GROUPS/DETAILS/DELETED';

export type PlantGroupsDetailsAction =
  | SetPlantGroupsDetailsParamsAction
  | FetchPlantGroupsDetailsAction
  | PlantGroupsDetailsFetchedAction
  | PlantGroupsDetailsDeletedAction;

export type SetPlantGroupsDetailsParamsAction = {
  type: typeof SET_PLANT_GROUPS_DETAILS_PARAMS;
  payload: number;
};

export const setPlantGroupsDetailsParamsAction = (
  payload: number
): SetPlantGroupsDetailsParamsAction => {
  return { type: SET_PLANT_GROUPS_DETAILS_PARAMS, payload };
};

export type FetchPlantGroupsDetailsAction = Action<
  typeof FETCH_PLANT_GROUPS_DETAILS
>;

export const fetchPlantGroupsDetailsAction =
  (): FetchPlantGroupsDetailsAction => ({
    type: FETCH_PLANT_GROUPS_DETAILS
  });

export type PlantGroupsDetailsFetchedPayload = {
  plantGroupDetails: PlantGroupDetailsEntity;
};

export interface PlantGroupsDetailsFetchedAction
  extends Action<typeof PLANT_GROUPS_DETAILS_FETCHED> {
  payload: PlantGroupsDetailsFetchedPayload;
}

export const plantGroupDetailsFetchedAction = (
  payload: PlantGroupsDetailsFetchedPayload
): PlantGroupsDetailsFetchedAction => ({
  type: PLANT_GROUPS_DETAILS_FETCHED,
  payload
});

export type PlantGroupsDetailsDeletedAction = {
  type: typeof PLANT_GROUPS_DETAILS_DELETED;
};

export const plantGroupsDetailsDeletedAction =
  (): PlantGroupsDetailsDeletedAction => ({
    type: PLANT_GROUPS_DETAILS_DELETED
  });
