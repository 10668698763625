import React, { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import HelpFindPlant, {
  createHelpFindPlant
} from 'modules/Plants/model/HelpFindPlant';
import {
  editHelpFindPlant,
  fetchHelpFindPlantDetails
} from 'modules/Plants/repository';
import { breadcrumbRouteHelpFindPlantDetails } from 'modules/Plants/breadcrumbs';
import HelpFindPlantDetails from 'modules/Plants/component/Details/HelpFindPlant';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps {
  id: string;
}

export interface State {
  helpFindPlant: HelpFindPlant;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class HelpFindPlantDetailsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      helpFindPlant: null
    };

    this.onSetResponded = this.onSetResponded.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow } = this.props;

    managePage({
      title: 'Help find plant',
      breadcrumb: breadcrumbRouteHelpFindPlantDetails(),
      type: 'loading'
    });

    try {
      const { data } = await fetchHelpFindPlantDetails(id);

      this.setState({
        helpFindPlant: createHelpFindPlant(data.finder_help)
      });

      managePage({
        title: `Help find plant - ${data.finder_help.email}`,
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  async onSetResponded(responded: boolean): Promise<void> {
    const { helpFindPlant } = this.state;

    try {
      await editHelpFindPlant(helpFindPlant.id, { responded });
      this.setState({
        helpFindPlant: {
          ...helpFindPlant,
          responded
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  render(): ReactNode {
    const { type } = this.props;
    const { helpFindPlant } = this.state;

    if (type || !helpFindPlant) {
      return null;
    }

    return (
      <HelpFindPlantDetails
        helpFindPlant={helpFindPlant}
        onSetResponded={this.onSetResponded}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HelpFindPlantDetailsView);
