import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteMissingFamily } from 'modules/MissingFamily/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import MissingFamilyTable from 'modules/MissingFamily/container/Table';
import MissingFamilyFilter from 'modules/MissingFamily/container/Filter';
import MissingFamilyPagination from 'modules/MissingFamily/container/Pagination';
import MissingFamilyPerPage from 'modules/MissingFamily/container/PerPage';
import MissingFamily from 'modules/MissingFamily/model/MissingFamily';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setMissingFamilyListParamsAction,
  SetMissingFamilyListParamsAction
} from 'modules/MissingFamily/action/List';
import { RootState } from 'app/reducer';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (
    payload: PartialSearchingProps
  ) => SetMissingFamilyListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setMissingFamilyListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.missingFamily.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class MissingFamilyListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      MissingFamily.getFilterableAttributes(),
      MissingFamily.getSortableAttributes(),
      { sort: 'requests', sort_method: 'desc' }
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Missing families',
      breadcrumb: breadcrumbRouteMissingFamily()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { fetching, type } = this.props;
    const { filter } = this.searchProps;

    if (type) return null;
    return (
      <div className="row missingFamily-view">
        <div className="col-12">
          <Listing
            table={<MissingFamilyTable />}
            filter={<MissingFamilyFilter values={filter} />}
            pagination={<MissingFamilyPagination />}
            perPage={<MissingFamilyPerPage />}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(MissingFamilyListView);
