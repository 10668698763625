import { convertArrayToObject } from 'modules/Shared/helper/convertArrayToObject';

export interface IPayload {
  name: string;
  value: string | number;
}

export type IState = { [key: string]: IPayload };

export const initState = (fieldArr: IPayload[]) =>
  convertArrayToObject(
    fieldArr as unknown as { [key: string]: string }[],
    'name'
  ) as IState;

const ON_CHANGE_FIELD = 'ON_CHANGE_FIELD';
const RESET_STATE = 'RESET_STATE';

type IUpdateFieldActionPayload = { name: string; value: string };
interface IUpdateFieldAction {
  type: typeof ON_CHANGE_FIELD;
  payload: IUpdateFieldActionPayload;
}
export const updateFieldAction = (
  name: string,
  value: string
): IUpdateFieldAction => {
  return {
    type: ON_CHANGE_FIELD,
    payload: { name, value }
  };
};

interface IResetStateAction {
  type: typeof RESET_STATE;
  payload: IState;
}
export const resetState = (fieldArr: IPayload[]): IResetStateAction => {
  return {
    type: RESET_STATE,
    payload: convertArrayToObject(
      fieldArr as unknown as { [key: string]: string }[],
      'name'
    ) as IState
  };
};

type IAction = IUpdateFieldAction | IResetStateAction;

const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case ON_CHANGE_FIELD:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          value: action.payload.value
        }
      };
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
