import listReducer from 'modules/PlantGroups/reducer/list';
import detailsReducer from 'modules/PlantGroups/reducer/details';
import { combineReducers, Reducer } from 'redux';
import { PlantGroupsState } from 'modules/PlantGroups/state';

const reducer = (): Reducer<PlantGroupsState> => {
  const reducers = {
    list: listReducer,
    details: detailsReducer
  };

  return combineReducers<PlantGroupsState>(reducers);
};

export default reducer;
