import {
  FETCH_USER_PLANT_DETAILS,
  PLANT_USER_DETAILS_FETCHED,
  SET_USER_PLANT_DETAILS_PARAMS,
  PlantUserDetailsAction
} from 'modules/PlantUser/action/details';
import { createPlantUserDetails } from 'modules/PlantUser/model/PlantUserDetails';
import {
  initPlantUserDetailsState,
  PlantUserDetailsState
} from 'modules/PlantUser/state/details';

const initState = initPlantUserDetailsState();

const reducer = (
  state: PlantUserDetailsState = initState,
  action: PlantUserDetailsAction
): PlantUserDetailsState => {
  switch (action.type) {
    case SET_USER_PLANT_DETAILS_PARAMS:
      return { ...state, plantUserId: action.payload };

    case FETCH_USER_PLANT_DETAILS:
      return { ...state, fetching: true };

    case PLANT_USER_DETAILS_FETCHED: {
      const { plantUserDetails = null } = action.payload;

      return {
        ...state,
        fetching: false,
        plantUserDetails: createPlantUserDetails(plantUserDetails)
      };
    }

    default:
      return state;
  }
};

export default reducer;
