export interface TermsOfServiceLangs {
  [lang: string]: {
    body: string;
    created_at?: string;
    updated_at?: string;
  };
}

export type TermsOfServiceEntity = {
  id?: number;
  langs?: TermsOfServiceLangs;
  activation_date?: string;
  mails_sended?: string;
  published?: number;
  created_at?: string;
  updated_at?: string;
};

export default class TermsOfService {
  id?: number;
  langs?: TermsOfServiceLangs;
  activation_date?: string;
  mails_sended?: string;
  published?: number;
  created_at?: string;
  updated_at?: string;

  constructor(entity: TermsOfServiceEntity) {
    this.id = entity.id;
    this.langs = entity.langs;
    this.activation_date = entity.activation_date;
    this.mails_sended = entity.mails_sended;
    this.published = entity.published;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  static getFilterableAttributes(): string[] {
    return ['activation_date_from', 'activation_date_to'];
  }

  static getSortableAttributes(): string[] {
    return ['activation_date'];
  }

  getIsPublished(): string {
    if (this.published) {
      return 'Yes';
    }

    return 'No';
  }
}

export const createTermsOfService = (
  entity: TermsOfServiceEntity
): TermsOfService => new TermsOfService(entity);
