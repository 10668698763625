import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteMediaAdvancement } from 'modules/Media/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import MediaAdvancementTable from 'modules/Media/container/Table/Advancement';
import MediaAdvancementPagination from 'modules/Media/container/Pagination/Advancement';
import MediaAdvancementPerPage from 'modules/Media/container/PerPage/Advancement';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setMediaAdvancementListParamsAction,
  SetMediaAdvancementListParamsAction
} from 'modules/Media/action/List';
import { RootState } from 'app/reducer';
import MediaAdvancement from 'modules/Media/model/MediaAdvancement';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_MEDIA_ADVANCEMENT_ADD } from 'modules/Media/routes';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (
    payload: PartialSearchingProps
  ) => SetMediaAdvancementListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  specialOnClick?: (id: number) => void;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setMediaAdvancementListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.media.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class MediaAdvancementListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      MediaAdvancement.getFilterableAttributes(),
      MediaAdvancement.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Media advancements',
      breadcrumb: breadcrumbRouteMediaAdvancement()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { fetching, type } = this.props;

    if (type) return null;

    return (
      <div className="row media-advancement-view">
        <div className="col-12">
          <Listing
            table={<MediaAdvancementTable />}
            pagination={<MediaAdvancementPagination />}
            perPage={<MediaAdvancementPerPage />}
            addBtn={{
              text: 'Add media advancement',
              url: getPathUrl(ROUTE_MEDIA_ADVANCEMENT_ADD)
            }}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(MediaAdvancementListView);
