import { RootState } from 'app/reducer';
import {
  PartialSearchingProps,
  PaginationParams,
  SearchParams,
  SortParams
} from 'modules/Shared/type';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Pagination } from 'modules/Layout/component/Pagination/pagination';
import {
  SetPlantUserListParamsAction,
  setPlantUserListParamsAction
} from 'modules/PlantUser/action/list';

export interface StateProps {
  pagination: PaginationParams;
  filter: SearchParams;
  sort: SortParams;
  currentCount: number;
  totalPages: number;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetPlantUserListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { pagination, filter, sort, plantUser, totalPages } =
    state.plantUser.list;

  return {
    pagination,
    filter,
    sort,
    currentCount: plantUser.length,
    totalPages
  };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPlantUserListParamsAction(payload))
});

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(Pagination);
