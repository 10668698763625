import React, { ReactNode } from 'react';
import PlantGroupDetails from 'modules/PlantGroups/model/PlantGroupDetails';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { Dispatch } from 'redux';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import { fetchPlantGroupDetails } from 'modules/PlantGroups/repository';
import { breadcrumbRouteEditPlantGroup } from 'modules/PlantGroups/breadcrumbs';
import EditPlantGroupForm from 'modules/PlantGroups/component/Form/Edit';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface StateProps {
  type: string;
}

export interface Props extends DispatchProps, StateProps, RouteComponentProps {
  id: number | string;
}

export interface State {
  plantGroup: PlantGroupDetails;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class EditPlantGroupView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      plantGroup: null
    };
  }

  async componentDidMount(): Promise<void> {
    const { id, managePage, manageThrow } = this.props;

    try {
      managePage({
        title: 'Plant group - edit',
        breadcrumb: breadcrumbRouteEditPlantGroup(),
        type: 'loading'
      });

      const {
        data: { plantGroup }
      } = await fetchPlantGroupDetails(id);

      this.setState({
        plantGroup
      });

      managePage({
        title: `${plantGroup.name} - edit`,
        breadcrumb: breadcrumbRouteEditPlantGroup(plantGroup),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  render(): ReactNode {
    const { type } = this.props;
    const { plantGroup } = this.state;

    if (type || !plantGroup) {
      return null;
    }

    return (
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <EditPlantGroupForm plantGroup={plantGroup} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(mapState, mapDispatch)(EditPlantGroupView)
);
