import FamilyDetails from 'modules/Family/model/FamilyDetails';
import { MeasurementsKnowHow } from 'modules/Shared/configs/measurementKnowHowEntity/type';

export interface FamilyDetailsState {
  familyDetails: FamilyDetails;
  measurementsStatusesKnowHows: MeasurementsKnowHow[];
  fetching: boolean;
  familyId: number;
}

export const initFamilyDetailsState = (): FamilyDetailsState => ({
  familyDetails: null,
  fetching: false,
  familyId: null,
  measurementsStatusesKnowHows: []
});
