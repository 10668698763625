export type FamilyEntity = {
  id?: number;
  genus?: string;
  name?: string;
  created_at?: string;
  updated_at?: string;
  is_deleted?: number;
};

export default class Family {
  readonly id?: number;
  readonly genus?: string;
  readonly name?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly is_deleted?: number;

  constructor(entity: FamilyEntity) {
    this.id = entity.id;
    this.genus = entity.genus;
    this.name = entity.name;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.is_deleted = entity.is_deleted;
  }

  static getFilterableAttributes(): string[] {
    return ['genus', 'with_deleted'];
  }

  static getSortableAttributes(): string[] {
    return ['genus', 'id'];
  }

  static displayFamilyDeleteStatus(is_deleted: number): string {
    return is_deleted === 1 ? 'true' : 'false';
  }

  getIsDeleted(): string {
    if (this.is_deleted) {
      return 'Yes';
    }

    return 'No';
  }
}

export const createFamily = (entity: FamilyEntity): Family =>
  new Family(entity);
