import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { ROUTE_PLANT_USER_DETAILS_REDIRECT_TEMPLATE } from 'modules/PlantUser/routes';
import { PlantUserDetailsEntity } from 'modules/PlantUser/model/PlantUserDetails';

export const GardenDetailsUserPlantsTable: React.FC<{
  userPlants: PlantUserDetailsEntity[];
}> = ({ userPlants }) => {
  const cols: TableCol<PlantUserDetailsEntity>[] = [
    {
      property: 'nickname',
      label: 'Nickname',
      className: 'col-2'
    },
    {
      property: 'scientific_name',
      label: 'Scientific name',
      className: 'col-2'
    }
  ];

  return (
    <div>
      <Table
        cols={cols}
        rows={userPlants}
        onRowClickPath={ROUTE_PLANT_USER_DETAILS_REDIRECT_TEMPLATE}
      />
    </div>
  );
};
