import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteHubs } from 'modules/Hub/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import HubTable from 'modules/Hub/container/Table';
import HubFilter from 'modules/Hub/container/Filter';
import HubPagination from 'modules/Hub/container/Pagination';
import HubPerPage from 'modules/Hub/container/PerPage';
import Hub from 'modules/Hub/model/Hub';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setHubListParamsAction,
  SetHubListParamsAction
} from 'modules/Hub/action/list';
import { RootState } from 'app/reducer';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (payload: PartialSearchingProps) => SetHubListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setHubListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.hub.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class HubsListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      Hub.getFilterableAttributes(),
      Hub.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Hubs',
      breadcrumb: breadcrumbRouteHubs()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { fetching, type } = this.props;
    const { filter } = this.searchProps;

    if (type) return null;

    return (
      <div className="row users-view">
        <div className="col-12">
          <Listing
            table={<HubTable />}
            filter={<HubFilter values={filter} />}
            pagination={<HubPagination />}
            perPage={<HubPerPage />}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HubsListView);
