import { PlantGroupDetailsBaseEntity } from 'modules/PlantGroups/model/PlantGroupDetails';
import { MeasurementsStatusDescEntity } from 'modules/Shared/configs/measurementStatusDescEntity/type';
import { MeasurementsKnowHow } from 'modules/Shared/configs/measurementKnowHowEntity/type';
import { supportedLangsEnum } from 'modules/Shared/configs/supportedLang/config';
import { TresholdsEntity } from 'modules/Shared/configs/tresholdsEntity/type';
import { PlantSectionsLangs } from 'modules/Plants/type';

export type LangsEntity = {
  name?: string;
  alternative_names?: string;
  description?: string;
  location?: string;
  plant_experience?: string;
  life_span?: string;
};

export type LangsObject = Record<supportedLangsEnum, LangsEntity>;

export type ImagesObject = {
  readonly created_at: string;
  readonly id: number;
  readonly origin_path: string;
  readonly thumb_path: string;
  readonly updated_at: string;
};

export type GoodToKnowObject = {
  readonly content: string;
  readonly id: number;
  readonly plant_id: number;
  readonly created_at: string;
  readonly lang: string;
  readonly updated_at: string;
};

export type PlantDetailsEntity = {
  id?: number;
  scientific_name?: string;
  confirmed?: boolean;
  eligibility?: 'Yes' | 'No' | 'Illegal';
  genus?: string;
  family_id?: number;
  thumb_path?: string;
  origin_path?: string;
  created_at?: string;
  updated_at?: string;
  is_deleted?: boolean;
  langs?: LangsObject;
  thresholds?: TresholdsEntity;
  images?: ImagesObject[];
  plant_groups?: PlantGroupDetailsBaseEntity[];
  measurements_statuses_desc?: MeasurementsStatusDescEntity[];
  measurements_statuses_know_hows?: MeasurementsKnowHow[];
  good_to_knows?: GoodToKnowObject[];
  sections?: PlantSectionsLangs;
  synonyms?: string[];
};

export default class PlantDetails {
  readonly id?: number;
  readonly scientific_name?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly confirmed?: boolean;
  readonly eligibility?: 'Yes' | 'No' | 'Illegal';
  readonly genus?: string;
  readonly family_id?: number;
  readonly thumb_path?: string;
  readonly origin_path?: string;
  is_deleted?: boolean;
  readonly langs?: LangsObject;
  readonly thresholds?: TresholdsEntity;
  readonly images?: ImagesObject[];
  readonly plant_groups?: PlantGroupDetailsBaseEntity[];
  readonly measurements_statuses_desc?: MeasurementsStatusDescEntity[];
  readonly measurements_statuses_know_hows?: MeasurementsKnowHow[];
  readonly good_to_knows?: GoodToKnowObject[];
  readonly sections?: PlantSectionsLangs;
  readonly synonyms?: string[];

  constructor(entity: PlantDetailsEntity) {
    this.id = entity.id;
    this.scientific_name = entity.scientific_name;
    this.is_deleted = entity.is_deleted;
    this.confirmed = entity.confirmed;
    this.eligibility = entity.eligibility;
    this.genus = entity.genus;
    this.family_id = entity.family_id;
    this.thumb_path = entity.thumb_path;
    this.origin_path = entity.origin_path;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.langs = entity.langs;
    this.thresholds = entity.thresholds;
    this.images = entity.images;
    this.plant_groups = entity.plant_groups;
    this.measurements_statuses_desc = entity.measurements_statuses_desc;
    this.measurements_statuses_know_hows =
      entity.measurements_statuses_know_hows;
    this.good_to_knows = entity.good_to_knows;
    this.sections = entity.sections;
    this.synonyms = entity.synonyms;
  }
}

export const createPlantDetails = (entity: PlantDetailsEntity): PlantDetails =>
  new PlantDetails(entity);
