import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import MissingFamily from 'modules/MissingFamily/model/MissingFamily';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { ROUTE_MISSING_FAMILY_DETAILS } from 'modules/MissingFamily/routes';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setMissingFamilyListParamsAction,
  SetMissingFamilyListParamsAction
} from 'modules/MissingFamily/action/List';
import { Dispatch } from 'redux';
import ActionShow from 'modules/Layout/component/Action/Show';
import { getPathUrl } from 'modules/Shared/helper/api';

export interface StateProps {
  missingFamilies: MissingFamily[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetMissingFamilyListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (family: MissingFamily) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { missingFamilies, sort } = state.missingFamily.list;

  return { missingFamilies, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setMissingFamilyListParamsAction(payload))
});

export class MissingFamilyTable extends React.Component<Props> {
  readonly cols: TableCol<MissingFamily>[];

  constructor(props: Props) {
    super(props);

    this.cols = [
      {
        property: 'family_scientific_name',
        label: 'Genus',
        sortable: true,
        className: 'col-4',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_MISSING_FAMILY_DETAILS, { id: row.id })
      },
      {
        property: 'scientific_name',
        label: 'Scientific name',
        sortable: true,
        className: 'col-5',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_MISSING_FAMILY_DETAILS, { id: row.id })
      },
      {
        property: 'requests',
        label: 'Requests',
        className: 'col-3',
        sortable: true,
        value: (row) => row.requests,
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_MISSING_FAMILY_DETAILS, { id: row.id })
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show missing family"
              to={getPathUrl(ROUTE_MISSING_FAMILY_DETAILS, { id: row.id })}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { missingFamilies, sort, setParams } = this.props;

    return (
      <Table
        cols={this.cols}
        rows={missingFamilies}
        sort={sort}
        onSort={(params) => setParams({ sort: params })}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(MissingFamilyTable);
