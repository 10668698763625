import React, { ReactNode } from 'react';
import { SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CreateNewPage } from 'modules/Layout/view/AddNewPageTemplate';
import { createNewPlant } from 'modules/PlantGroups/repository';
import { inputConfigType } from 'modules/Layout/view/InputComponent';
import { refactorData } from 'modules/PlantGroups/view/Add/helper/refactorData';
import { ROUTE_PLANT_GROUPS_DETAILS_REDIRECT_TEMPLATE } from 'modules/PlantGroups/routes';
import { breadcrumbRouteAddPlantGroup } from 'modules/PlantGroups/breadcrumbs';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
}

export type Props = DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload))
});

export class PlantGroupAddView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  componentDidMount(): void {
    const { managePage } = this.props;

    managePage({
      title: 'Plant group - add',
      breadcrumb: breadcrumbRouteAddPlantGroup()
    });
  }

  render(): ReactNode {
    const newPageInputs: inputConfigType[] = [
      {
        label: 'Name',
        type: 'text',
        name: 'name'
      }
    ];

    return (
      <div className="listing-wrapper">
        <CreateNewPage
          inputsObj={newPageInputs}
          onSubmit={createNewPlant}
          dataFormat={refactorData}
          successUrl={ROUTE_PLANT_GROUPS_DETAILS_REDIRECT_TEMPLATE}
          dataNameInResponse="plantGroup"
        />
      </div>
    );
  }
}

export default connect<null, DispatchProps>(
  null,
  mapDispatch
)(PlantGroupAddView);
