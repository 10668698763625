import React, { ReactNode } from 'react';
import PlantGroupDetailsModel, {
  createPlantGroupDetails
} from 'modules/PlantGroups/model/PlantGroupDetails';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import { fetchPlantGroupDetails } from 'modules/PlantGroups/repository';
import { breadcrumbRouteDetailsPlantGroups } from 'modules/PlantGroups/breadcrumbs';
import PlantGroupDetails from 'modules/PlantGroups/component/Details/Details';
import { MeasurementsKnowHow } from 'modules/Shared/configs/measurementKnowHowEntity/type';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps {
  id: string;
}

export interface State {
  plantGroup: PlantGroupDetailsModel;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class PlantGroupDetailsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      plantGroup: null
    };

    this.plantGroupUpdated = this.plantGroupUpdated.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow } = this.props;

    managePage({
      title: 'Plant group',
      breadcrumb: breadcrumbRouteDetailsPlantGroups(),
      type: 'loading'
    });

    try {
      const { data } = await fetchPlantGroupDetails(id);

      this.setState({
        plantGroup: createPlantGroupDetails(data.plantGroup)
      });

      managePage({
        title: data.plantGroup.name,
        breadcrumb: breadcrumbRouteDetailsPlantGroups(data.plantGroup),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  plantGroupUpdated(
    knowHow: MeasurementsKnowHow,
    type: 'add' | 'delete'
  ): void {
    const { plantGroup } = this.state;

    if (type === 'add') {
      plantGroup.measurements_statuses_know_hows.push(knowHow);
    } else if (type === 'delete') {
      plantGroup.measurements_statuses_know_hows =
        plantGroup.measurements_statuses_know_hows.filter(
          (obj) => obj.id !== knowHow.id
        );
    }

    this.setState({
      plantGroup
    });
  }

  render(): ReactNode {
    const { type } = this.props;
    const { plantGroup } = this.state;

    if (type || !plantGroup) {
      return null;
    }

    return (
      <PlantGroupDetails
        plantGroup={plantGroup}
        plantGroupUpdated={this.plantGroupUpdated}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantGroupDetailsView);
