import SensorDetails from 'modules/Sensor/model/SensorDetails';

export interface SensorDetailsState {
  sensorId: string;
  sensorDetails: SensorDetails;
  fetching: boolean;
}

export const initSensorDetailsState = (): SensorDetailsState => ({
  sensorId: null,
  sensorDetails: null,
  fetching: false
});
