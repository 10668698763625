import React, { ReactNode } from 'react';
import { PageProps } from 'modules/Layout/type';
import {
  AddToastAction,
  addToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteOrderNews } from 'modules/News/breadcrumbs';
import { connect } from 'react-redux';
import { RootState } from 'app/reducer';
import ReorderList, { ListCol } from 'modules/Layout/component/ReorderList';
import News, { createNews } from 'modules/News/model/News';
import { fetchOrderedNews, updateNewsOrder } from 'modules/News/repository';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { Button } from 'reactstrap';
import {
  reorderNewsToastError,
  reorderNewsToastSuccess
} from 'modules/News/toasts';
import Loader from 'modules/Layout/component/Loader';
import DisplayNav from '../../../Layout/view/Details/Helper/DisplayNav';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export type Props = StateProps & DispatchProps;

export interface State {
  tutorialsNews: News[];
  plantOfTheWeekNews: News[];
  smartGardeningNews: News[];
  livingWithPlantsNews: News[];
  fetching: boolean;
  nav: boolean[];
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error)),
  addToast: (payload) => dispatch(addToastAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

export class NewsOrderListView extends React.Component<Props, State> {
  readonly cols: ListCol<News>[];
  readonly navElements: string[];

  constructor(props: Props) {
    super(props);

    this.cols = [
      {
        property: 'image',
        label: 'image',
        value: (row) => <img src={`${row.image}`} width="50px" alt="News" />,
        className: 'p-1 d-flex align-items-center justify-content-center'
      },
      {
        property: 'title',
        label: 'Title',
        className: 'w-100 p-1 d-flex align-items-center font-weight-bold'
      }
    ];

    this.navElements = [
      'Tutorials',
      'Plant of the week',
      'Smart gardening',
      'Living with plants'
    ];

    this.state = {
      tutorialsNews: [],
      plantOfTheWeekNews: [],
      smartGardeningNews: [],
      livingWithPlantsNews: [],
      fetching: false,
      nav: [true, false, false, false]
    };

    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
    this.getNews = this.getNews.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { managePage } = this.props;

    managePage({
      title: 'News - reorder',
      type: 'loading',
      breadcrumb: breadcrumbRouteOrderNews()
    });

    try {
      const {
        data: { content }
      } = await fetchOrderedNews();

      const tutorials = content.filter((obj) => obj.section === 'tutorials');
      const plantOfTheWeek = content.filter(
        (obj) => obj.section === 'plant_of_the_week'
      );
      const smartGardening = content.filter(
        (obj) => obj.section === 'smart_gardening'
      );
      const livingWithPlants = content.filter(
        (obj) => obj.section === 'living_with_plants'
      );

      this.setState({
        tutorialsNews: tutorials.map(createNews),
        plantOfTheWeekNews: plantOfTheWeek.map(createNews),
        smartGardeningNews: smartGardening.map(createNews),
        livingWithPlantsNews: livingWithPlants.map(createNews)
      });

      managePage({
        title: 'News - reorder',
        type: null
      });
    } catch (error) {
      manageThrowAction(error);
    }
  }

  async save(): Promise<void> {
    const { addToast } = this.props;
    const {
      tutorialsNews,
      plantOfTheWeekNews,
      livingWithPlantsNews,
      smartGardeningNews
    } = this.state;

    const orderedNewsIds = [
      ...tutorialsNews.map((obj) => obj.id),
      ...plantOfTheWeekNews.map((obj) => obj.id),
      ...livingWithPlantsNews.map((obj) => obj.id),
      ...smartGardeningNews.map((obj) => obj.id)
    ];

    try {
      this.setState({
        fetching: true
      });

      await updateNewsOrder(orderedNewsIds);

      this.setState({
        fetching: false
      });

      addToast(reorderNewsToastSuccess());
    } catch (error) {
      console.log(error);

      this.setState({
        fetching: false
      });

      addToast(reorderNewsToastError());
    }
  }

  onChange(news: News[], section: string): void {
    if (section === 'tutorials') {
      this.setState({ tutorialsNews: news });
    } else if (section === 'plant_of_the_week') {
      this.setState({ plantOfTheWeekNews: news });
    } else if (section === 'smart_gardening') {
      this.setState({ smartGardeningNews: news });
    } else if (section === 'living_with_plants') {
      this.setState({ livingWithPlantsNews: news });
    }
  }

  getNews(): News[] {
    const {
      nav,
      tutorialsNews,
      plantOfTheWeekNews,
      livingWithPlantsNews,
      smartGardeningNews
    } = this.state;
    const navIndex = nav.findIndex((obj) => obj);
    const activeSection = [
      'tutorials',
      'plant_of_the_week',
      'smart_gardening',
      'living_with_plants'
    ][navIndex];

    if (activeSection === 'tutorials') {
      return tutorialsNews;
    }

    if (activeSection === 'plant_of_the_week') {
      return plantOfTheWeekNews;
    }

    if (activeSection === 'living_with_plants') {
      return livingWithPlantsNews;
    }

    if (activeSection === 'smart_gardening') {
      return smartGardeningNews;
    }

    return [];
  }

  render(): ReactNode {
    const { type } = this.props;
    const { fetching, nav } = this.state;

    if (type) return null;

    const navIndex = nav.findIndex((obj) => obj);
    const activeSection = [
      'tutorials',
      'plant_of_the_week',
      'smart_gardening',
      'living_with_plants'
    ][navIndex];

    return (
      <>
        <div
          className="card position-fixed"
          style={{ right: '20px', top: '220px' }}
        >
          <div className="card-body d-flex flex-column p-2">
            {fetching && <Loader />}
            <Button disabled={fetching} onClick={this.save} color="success">
              Save
            </Button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 mb-2">
            <DisplayNav
              booleanArr={nav}
              setBooleanArr={(newNav: boolean[]) => {
                this.setState({ nav: newNav });
              }}
              valueArr={this.navElements}
            />
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <ReorderList
                  accept="news"
                  items={this.getNews()}
                  cols={this.cols}
                  rowKey={(row) => row.id}
                  onChange={(news) => this.onChange(news, activeSection)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(NewsOrderListView);
