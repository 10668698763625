import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import TermsOfService from 'modules/Policy/model/TermsOfService';
import PrivacyPolicy from 'modules/Policy/model/PrivacyPolicy';

export interface PolicyListState extends SearchingProps {
  termsOfServices: TermsOfService[];
  pp: PrivacyPolicy[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initPolicyListState = (): PolicyListState => ({
  termsOfServices: [],
  pp: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
