import React, { ReactNode } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { fetchProtectedImage } from 'modules/Layout/repository';

export interface Props {
  url?: string;
  width?: number;
  maxHeight?: number;
}

export interface State {
  image: string | ArrayBuffer | null;
  fetching: boolean;
}

class ProtectedImage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      image: null,
      fetching: false
    };

    this.fetch = this.fetch.bind(this);
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const { url } = this.props;

    if (prevProps.url !== url) {
      await this.fetch();
    }
  }

  async fetch(): Promise<void> {
    const { url } = this.props;

    if (url) {
      this.setState(
        {
          fetching: true
        },
        async () => {
          try {
            const { data } = await fetchProtectedImage(url);

            const reader = new FileReader();
            reader.onload = () => {
              const base64 = reader.result;
              this.setState({
                image: base64,
                fetching: false
              });
            };
            reader.readAsDataURL(data);
          } catch (_error) {
            this.setState({
              image: null,
              fetching: false
            });
          }
        }
      );
    }
  }

  async componentDidMount(): Promise<void> {
    await this.fetch();
  }

  render(): ReactNode {
    const { width, maxHeight } = this.props;
    const { fetching, image } = this.state;

    if (fetching) {
      return <Loader />;
    }

    // eslint-disable-next-line jsx-a11y/alt-text
    return <img src={image as string} style={{ width, maxHeight }} />;
  }
}

export default ProtectedImage;
