import { SelectOption } from 'modules/Shared/type';

export type PlantUserEntity = {
  id?: number;
  user_id?: number;
  garden_id?: number;
  nickname?: string;
  status?: number;
  email?: number;
  username?: string;
  sensor_id?: string;
  scientific_name?: string;
  garden_name?: string;
  thumb_path?: string;
};

export default class PlantUser {
  readonly id?: number;
  readonly user_id?: number;
  readonly garden_id?: number;
  readonly nickname?: string;
  readonly status?: number;
  readonly email?: number;
  readonly username?: string;
  readonly sensor_id?: string;
  readonly scientific_name?: string;
  readonly garden_name?: string;
  readonly thumb_path?: string;

  constructor(entity: PlantUserEntity) {
    this.id = entity.id;
    this.user_id = entity.user_id;
    this.garden_id = entity.garden_id;
    this.nickname = entity.nickname;
    this.status = entity.status;
    this.email = entity.email;
    this.username = entity.username;
    this.sensor_id = entity.sensor_id;
    this.scientific_name = entity.scientific_name;
    this.garden_name = entity.garden_name;
    this.thumb_path = entity.thumb_path;
  }

  static getFilterableAttributes(): string[] {
    return [
      'nickname',
      'status',
      'email',
      'username',
      'has_sensor',
      'scientific_name',
      'garden_name',
      'with_deleted'
    ];
  }

  static getSortableAttributes(): string[] {
    return ['username', 'garden_name', 'nickname', 'scientific_name'];
  }

  static getPlantUserSensorStatusOptions(): SelectOption[] {
    return [
      {
        value: false,
        label: 'No'
      },
      {
        value: true,
        label: 'Yes'
      }
    ];
  }

  static getPlantUserDeletedStatusOptions(): SelectOption[] {
    return [
      {
        value: 1,
        label: 'No'
      },
      {
        value: 0,
        label: 'Yes'
      }
    ];
  }

  getIsDeleted(): string {
    if (this.status) {
      return 'No';
    }

    return 'Yes';
  }
}

export const createPlantUser = (entity: PlantUserEntity): PlantUser =>
  new PlantUser(entity);
