import { AxiosError } from 'axios';
import ApiError from 'modules/Shared/exception/ApiError';

export default class NotAcceptable extends ApiError {
  constructor(error: AxiosError) {
    super('Validation.', error);
  }

  getMessageValue(): string {
    if (this.error.response) {
      const { data } = this.error.response;

      if (data) {
        const { text } = data;

        if (text) {
          return text;
        }
      }
    }

    return 'Not acceptable';
  }
}
