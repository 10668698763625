import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteDetailsMedia } from 'modules/Media/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { setMediaDetailsParamsAction } from 'modules/Media/action/Details';
import { RootState } from 'app/reducer';
import DetailsMain from 'modules/Media/view/Details/components/DisplayDetailsMain';
import Loader from 'modules/Layout/component/Loader';
import { ROUTE_MEDIA } from 'modules/Media/routes';

interface IParams {
  id: string;
}

export const MediaDetailsView = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector((state: RootState) => state.media.details);
  const { type } = useSelector((state: RootState) => state.layout.page);

  useEffect(() => {
    dispatch(
      managePageAction({
        title: details?.mediaDetails ? details.mediaDetails.title : 'Media',
        breadcrumb: breadcrumbRouteDetailsMedia(details?.mediaDetails)
      })
    );
  }, [details.mediaDetails]);

  useEffect(() => {
    if (params?.id) {
      const id = parseInt(params.id, 10);
      if (id) {
        dispatch(setMediaDetailsParamsAction(id));
      } else {
        history.push(ROUTE_MEDIA);
      }
    }
  }, []);

  if (type) return null;
  if (details.fetching)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );
  return (
    <DetailsMain
      data={details.mediaDetails}
      onSuccessMediaAttach={() => {
        const id = parseInt(params.id, 10);
        dispatch(setMediaDetailsParamsAction(id));
      }}
    />
  );
};

export default MediaDetailsView;
