import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { PlantGroupsEntity } from 'modules/PlantGroups/model/PlantGroups';
import { PlantGroupDetailsEntity } from 'modules/PlantGroups/model/PlantGroupDetails';
import { MeasurementsStatusDescEntity } from 'modules/Shared/configs/measurementStatusDescEntity/type';
import { MeasurementPayload } from 'modules/PlantGroups/component/Form/Edit/Bulk';

export const API_PLANT_GROUPS_LIST = '/admin-plant-groups/list';
export const API_PLANT_GROUPS_LIST_ALL = '/admin-plant-groups/list-all';
export const API_PLANT_GROUPS = '/admin-plant-groups';
export const API_PLANT_GROUP_MEASUREMENTS =
  '/admin-plant-groups/:id/measurements-statuses-desc';
export const API_PLANT_GROUP_ADD_MEDIA = '/admin-plant-groups/:id/know-how';
export const API_FAMILY_ADD_MEDIA = '/admin-families/:id/know-how';
export const API_PLANT_GROUP_DELETE_MEDIA = '/admin-plant-groups/know-how/:id';
export const API_FAMILY_DELETE_MEDIA = '/admin-families/know-how/:id';
export const API_PLANT_GROUP = '/admin-plant-groups/:id';
export const API_ADD_PLANT = '/admin-plant-groups/:id/add-plant';
export const API_REMOVE_PLANT = '/admin-plant-groups/:id/delete-plant';
export const API_BULK_UPDATE_PLANT_GROUPS = '/admin-plant-groups/bulk-update';

export interface FetchPlantGroupsResponse {
  content: PlantGroupsEntity[];
  totalCount: any;
}

export const fetchPlantGroups = (
  params: SearchParams = {}
): AxiosPromise<FetchPlantGroupsResponse> =>
  axios.post(getAPIUrl(API_PLANT_GROUPS_LIST, null), params);

export interface FetchPlantGroupsAllPayload {
  search: {
    name?: string;
  };
}

export const fetchPlantGroupsAll = (
  payload?: FetchPlantGroupsAllPayload
): AxiosPromise<FetchPlantGroupsResponse> =>
  axios.post(getAPIUrl(API_PLANT_GROUPS_LIST_ALL), payload);

// details

export type FetchPlantGroupDetailsResponse = {
  plantGroup: PlantGroupDetailsEntity;
};

export const fetchPlantGroupDetails = (
  id: number | string
): AxiosPromise<FetchPlantGroupDetailsResponse> =>
  axios.get(getAPIUrl(`${API_PLANT_GROUPS}/${id}`));

// create new

export type CreatePlantGroupResponse = AxiosResponse<{
  plantGroup: PlantGroupsEntity;
}>;

export const createNewPlant = (params: {
  name: string;
}): AxiosPromise<CreatePlantGroupResponse> =>
  axios.post(getAPIUrl(API_PLANT_GROUPS), params);

export const updatePlantGroupMeasurements = (
  id: number | string,
  measurements: MeasurementsStatusDescEntity[]
): AxiosPromise =>
  axios.put(getAPIUrl(API_PLANT_GROUP_MEASUREMENTS, { id }), {
    descriptions: measurements
  });

export const addMediaForPlantGroup = (
  id: number,
  params: {
    media_id: number;
    measurement_status: number;
    measurement: string;
  }
) => axios.post(getAPIUrl(API_PLANT_GROUP_ADD_MEDIA, { id }), params);

export const addMediaForFamily = (
  id: number,
  params: { media_id: number; measurement_status: number; measurement: string }
) => axios.post(getAPIUrl(API_FAMILY_ADD_MEDIA, { id }), params);

export const deleteMediaForPlantGroup = (id: number) =>
  axios.delete(getAPIUrl(API_PLANT_GROUP_DELETE_MEDIA, { id }));

export const deleteMediaForFamily = (id: number) =>
  axios.delete(getAPIUrl(API_FAMILY_DELETE_MEDIA, { id }));

export const deletePlantGroup = (id: number): AxiosPromise =>
  axios.delete(getAPIUrl(API_PLANT_GROUP, { id }));

export const updatePlantGroup = (
  id: number,
  data: { name: string }
): AxiosPromise => axios.put(getAPIUrl(API_PLANT_GROUP, { id }), data);

export const addPlant = (
  plantGroupId: number | string,
  plantId: number | string
): AxiosPromise =>
  axios.post(getAPIUrl(API_ADD_PLANT, { id: plantGroupId }), {
    plantId: String(plantId)
  });

export const removePlant = (
  plantGroupId: number | string,
  plantId: number | string
): AxiosPromise =>
  axios.post(getAPIUrl(API_REMOVE_PLANT, { id: plantGroupId }), {
    plantId: String(plantId)
  });

export const bulkUpdatePlantGroups = (
  plantGroupsIds: number[],
  descriptions: MeasurementPayload[]
): AxiosPromise =>
  axios.post(getAPIUrl(API_BULK_UPDATE_PLANT_GROUPS), {
    plantGroupsIds,
    descriptions
  });
