import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import User from 'modules/User/model/User';
import { FeedbackEntity } from '../../model/Feedback';

export interface UserListState extends SearchingProps {
  users: User[];
  feedback: FeedbackEntity[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initUserListState = (): UserListState => ({
  users: [],
  feedback: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
