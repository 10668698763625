import { BreadcrumbItem } from 'modules/Layout/type';
import {
  ROUTE_ADMINISTRATOR_DETAILS,
  ROUTE_ADMINISTRATORS,
  ROUTE_USERS,
  ROUTE_USERS_FEEDBACK
} from 'modules/User/routes';
import User from 'modules/User/model/User';
import { getPathUrl } from 'modules/Shared/helper/api';
import { FeedbackEntity } from './model/Feedback';

export const breadcrumbRouteAdministrators = (): BreadcrumbItem[] => [
  {
    title: 'Administrators',
    link: ROUTE_ADMINISTRATORS
  }
];

export const breadcrumbRouteUsersFeedback = (): BreadcrumbItem[] => [
  {
    title: 'Users feedback',
    link: ROUTE_USERS_FEEDBACK
  }
];

export const breadcrumbRouteFeedback = (
  feedback?: FeedbackEntity
): BreadcrumbItem[] => [
  ...breadcrumbRouteUsersFeedback(),
  {
    title: feedback ? feedback.email : 'Details'
  }
];

export const breadcrumbRouteUsers = (): BreadcrumbItem[] => [
  {
    title: 'Users',
    link: ROUTE_USERS
  }
];

export const breadcrumbRouteUser = (user?: User): BreadcrumbItem[] => [
  ...breadcrumbRouteUsers(),
  {
    title: user ? user.getName() : 'Details'
  }
];

export const breadcrumbRouteAdministrator = (
  administrator?: User
): BreadcrumbItem[] => [
  ...breadcrumbRouteAdministrators(),
  {
    title: administrator ? administrator.getName() : 'Administrator',
    link: administrator
      ? getPathUrl(ROUTE_ADMINISTRATOR_DETAILS, { id: administrator.id })
      : null
  }
];

export const breadcrumbRouteEditAdministrator = (
  administrator?: User
): BreadcrumbItem[] => [
  ...breadcrumbRouteAdministrator(administrator),
  {
    title: 'Edit'
  }
];

export const breadcrumbRouteCreateAdministrator = (): BreadcrumbItem[] => [
  ...breadcrumbRouteAdministrators(),
  {
    title: 'Add'
  }
];
