import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import { ColorVariants, Message, ValidationErrors } from 'modules/Shared/type';
import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import Logo from 'modules/Layout/component/Logo';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ROUTE_LOGIN } from 'modules/Auth/routes';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import NotFound from 'modules/Shared/exception/NotFound';
import { forgotPassword } from 'modules/Auth/repository';
import {
  forgotPasswordToastError,
  forgotPasswordToastSuccess
} from 'modules/Auth/toasts';
import { SetAuthStateAction, setAuthStateAction } from 'modules/Auth/action';
import { SettableAuthState } from 'modules/Auth/state';
import { getPathUrl } from 'modules/Shared/helper/api';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
  setAuthState: (payload: SettableAuthState) => SetAuthStateAction;
}

export type Props = DispatchProps & RouteComponentProps;

export interface State {
  busy: boolean;
  message?: Message;
  errors: ValidationErrors;
  email: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload)),
  setAuthState: (payload) => dispatch(setAuthStateAction(payload))
});

export class ForgotPasswordView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      busy: false,
      message: null,
      errors: null,
      email: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(): void {
    const { managePage } = this.props;

    managePage({ title: 'Forgot password' });
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const { addToast, setAuthState, history } = this.props;
    const { email } = this.state;

    this.setState({
      busy: true,
      message: null,
      errors: null
    });

    try {
      await forgotPassword({
        email
      });

      addToast(forgotPasswordToastSuccess());

      setAuthState({
        message: {
          value: 'Email with instructions sent.',
          variant: ColorVariants.Success
        }
      });

      history.push(getPathUrl(ROUTE_LOGIN));
    } catch (error) {
      addToast(forgotPasswordToastError());

      if (error instanceof NotFound) {
        this.setState({
          busy: false,
          message: {
            value: 'Email not found.',
            variant: ColorVariants.Danger
          }
        });
      } else {
        this.setState({
          busy: false,
          message: error.getMessage()
        });
      }
    }
  }

  render(): JSX.Element {
    const { busy, message, errors, email } = this.state;

    return (
      <PublicWrapper>
        <div className="forgot-password-view pt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11 col-lg-9 col-xl-7">
                <div className="card position-relative px-md-5 py-2 bg-secondary">
                  <div className="card-body">
                    <Logo />
                    {busy && <Loader />}
                    <div className="px-lg-4 mt-4">
                      <h4 className="text-primary">Forgot password</h4>
                      {message && <Alert message={message} />}
                      <Form className="mt-3" onSubmit={this.onSubmit}>
                        <FormGroup>
                          <Input
                            type="email"
                            onChange={(event) =>
                              this.setState({
                                email: event.currentTarget.value
                              })
                            }
                            placeholder="Email*"
                            value={email}
                            invalid={hasError(errors, 'email')}
                            required
                          />
                          {hasError(errors, 'email') && (
                            <FormFeedback>
                              {getError(errors, 'email')}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <div className="d-flex justify-content-between mt-3">
                          <Link className="btn btn-primary" to={ROUTE_LOGIN}>
                            Cancel
                          </Link>
                          <Button color="success" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PublicWrapper>
    );
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(ForgotPasswordView)
);
