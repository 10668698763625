import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { primaryInfoArr } from 'modules/News/view/Details/helper/DetailsConfig';
import News from 'modules/News/model/News';
import DiplayPrimaryInfo from 'modules/Layout/view/Details/Helper/DisplayPrimaryInfo';
import DisplayPrimaryInfoWrapper from 'modules/Layout/view/Details/Helper/DisplayPrimaryInfoWrapper';
import { ROUTE_NEWS, ROUTE_NEWS_EDIT } from 'modules/News/routes';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { newsDetailsDeletedAction } from 'modules/News/action/Details';
import DeleteNewsModal from 'modules/News/component/Modal/Delete';
import { getPathUrl } from 'modules/Shared/helper/api';

interface IProps {
  data: News;
}

const Details: React.FC<IProps> = ({ data }: IProps) => {
  const [deleteMessage, setDeleteMessage] = useState({ text: '', color: '' });

  const history = useHistory();
  const dispatch = useDispatch();

  const deleteModalRef = useRef(null);

  const onDeleteSuccess = () => {
    dispatch(newsDetailsDeletedAction());
    history.push(ROUTE_NEWS);
  };

  const arrayOfBtnObjects = [
    {
      callback: () => deleteModalRef?.current?.openModal(data),
      text: 'Delete',
      bsColor: 'danger'
    },
    {
      callback: () =>
        history.push(getPathUrl(ROUTE_NEWS_EDIT, { id: data?.id })),
      text: 'Edit'
    }
  ];

  return (
    <div className="content restrict-width">
      <DeleteNewsModal ref={deleteModalRef} onSuccess={onDeleteSuccess} />
      <DisplayPrimaryInfoWrapper title="News" arrBtnObj={arrayOfBtnObjects}>
        <ShowMessage
          message={deleteMessage.text}
          deleteMessage={() => setDeleteMessage({ text: '', color: '' })}
          bsColor={deleteMessage.color}
        />
        <DiplayPrimaryInfo
          imagesURL={data?.image ? [{ id: data?.id, url: data?.image }] : []}
          objArr={primaryInfoArr(data)}
        />
      </DisplayPrimaryInfoWrapper>
    </div>
  );
};

export default Details;
