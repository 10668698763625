import React, { ReactNode } from 'react';
import PlantGroupDetails, {
  ConnectedPlantListEntity
} from 'modules/PlantGroups/model/PlantGroupDetails';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PageProps } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { Dispatch } from 'redux';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import {
  addPlant,
  fetchPlantGroupDetails,
  removePlant
} from 'modules/PlantGroups/repository';
import { breadcrumbRouteEditPlantGroupPlants } from 'modules/PlantGroups/breadcrumbs';
import { PlantEntity } from 'modules/Plants/model/Plant';
import MoveList, { MoveListValue } from 'modules/Layout/component/MoveList';
import { fetchPlantsAll } from 'modules/Plants/repository';
import { UncontrolledTooltip } from 'reactstrap';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import {
  addPlantToGroupToastError,
  addPlantToGroupToastSuccess,
  removePlantFromGroupToastError,
  removePlantFromGroupToastSuccess
} from 'modules/PlantGroups/toasts';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface StateProps {
  type: string;
}

export interface Props extends DispatchProps, StateProps, RouteComponentProps {
  id: number | string;
}

export interface State {
  plantGroup: PlantGroupDetails;
  plants: PlantEntity[];
  fetchingOptions: boolean;
  fetchingValues: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error)),
  addToast: (payload) => dispatch(addToastAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class EditPlantGroupPlantsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      plantGroup: null,
      plants: [],
      fetchingOptions: false,
      fetchingValues: false
    };

    this.plantToOption = this.plantToOption.bind(this);
    this.addToGroup = this.addToGroup.bind(this);
    this.removeFromGroup = this.removeFromGroup.bind(this);
    this.plantGroupToOption = this.plantGroupToOption.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { id, managePage, manageThrow } = this.props;

    try {
      managePage({
        title: 'Plant group - manage plants',
        breadcrumb: breadcrumbRouteEditPlantGroupPlants(),
        type: 'loading'
      });

      const {
        data: { plantGroup }
      } = await fetchPlantGroupDetails(id);

      const {
        data: { content }
      } = await fetchPlantsAll({});

      this.setState({
        plantGroup,
        plants: content
      });

      managePage({
        title: `${plantGroup.name} - manage plants`,
        breadcrumb: breadcrumbRouteEditPlantGroupPlants(plantGroup),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  async addToGroup(id: number, index: number): Promise<void> {
    const { addToast } = this.props;
    const { plantGroup, plants } = this.state;
    try {
      this.setState({
        fetchingOptions: true
      });

      await addPlant(plantGroup.id, id);

      plantGroup.plants.push(plants[index]);

      addToast(addPlantToGroupToastSuccess());
    } catch (error) {
      console.log(error);

      addToast(addPlantToGroupToastError());
    } finally {
      this.setState({
        fetchingOptions: false,
        plantGroup: {
          ...plantGroup,
          plants: [...plantGroup.plants]
        }
      });
    }
  }

  async removeFromGroup(id: number): Promise<void> {
    const { addToast } = this.props;
    const { plantGroup, plants } = this.state;
    try {
      this.setState({
        fetchingValues: true
      });

      await removePlant(plantGroup.id, id);

      plantGroup.plants = plantGroup.plants.filter((plant) => plant.id !== id);

      const indexOfPlant = plants.findIndex((obj) => obj.id === id);

      plants[indexOfPlant] = {
        ...plants[indexOfPlant],
        plant_groups_count: plants[indexOfPlant].plant_groups_count - 1
      };

      addToast(removePlantFromGroupToastSuccess());
    } catch (error) {
      console.log(error);

      addToast(removePlantFromGroupToastError());
    } finally {
      this.setState({
        fetchingValues: false,
        plantGroup: {
          ...plantGroup,
          plants: [...plantGroup.plants]
        },
        plants
      });
    }
  }

  plantToOption(plant: PlantEntity, index: number): MoveListValue {
    const label = plant.scientific_name || plant.name || '';
    const disabled = plant.plant_groups_count >= 2;

    return {
      label,
      value: String(plant.id),
      render: () => {
        return (
          <div
            id={`option-${plant.id}`}
            className="d-flex justify-content-between position-relative"
          >
            {label}
            <ActionUpdate
              title="Add to group"
              disabled={disabled}
              label={<i className="fas font-20 fa-plus-circle" />}
              onClick={() => this.addToGroup(plant.id, index)}
            />
            {disabled && (
              <UncontrolledTooltip target={`#option-${plant.id}`}>
                This plant have already 2 groups.
              </UncontrolledTooltip>
            )}
          </div>
        );
      }
    };
  }

  plantGroupToOption(plant: ConnectedPlantListEntity): MoveListValue {
    const label = plant.scientific_name || plant.name || '';

    return {
      label,
      value: String(plant.id),
      render: () => {
        return (
          <div className="d-flex justify-content-between">
            <ActionDelete
              title="Remove from group"
              label={<i className="fas font-20 fa-minus-circle" />}
              onClick={() => this.removeFromGroup(plant.id)}
            />
            {label}
          </div>
        );
      }
    };
  }

  render(): ReactNode {
    const { type } = this.props;
    const { plantGroup, plants, fetchingValues, fetchingOptions } = this.state;

    if (type || !plantGroup) {
      return null;
    }

    return (
      <div className="row justify-content-center">
        <div className="card">
          <div className="card-body">
            <h3 className="mb-3">{plantGroup.name}</h3>
            <div className="d-flex justify-content-between">
              <div className="font-18">Plants</div>
              <div className="font-18">Plants in group</div>
            </div>
            <MoveList
              options={plants.map(this.plantToOption)}
              values={plantGroup.plants.map(this.plantGroupToOption)}
              fetchingOptions={fetchingOptions}
              fetchingValues={fetchingValues}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(
    mapState,
    mapDispatch
  )(EditPlantGroupPlantsView)
);
