import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { HubEntity } from 'modules/Hub/model/Hub';
import { Action } from 'redux';

export const SET_HUB_LIST_PARAMS = 'HUB/LIST/PARAMS/SET';
export const UPDATE_HUB_LIST_PARAMS = 'HUB/LIST/PARAMS/UPDATE';
export const FETCH_HUB_LIST = 'HUB/LIST/FETCH';
export const HUB_LIST_FETCHED = 'HUB/LIST/FETCHED';

export type HubListAction =
  | SetHubListParamsAction
  | FetchHubListAction
  | HubListFetchedAction;

export type SetHubListParamsAction = SetSearchingParamsAction<
  typeof SET_HUB_LIST_PARAMS
>;

export const setHubListParamsAction = (
  payload: PartialSearchingProps
): SetHubListParamsAction =>
  setSearchingParamsAction(SET_HUB_LIST_PARAMS, payload);

export type FetchHubListAction = Action<typeof FETCH_HUB_LIST>;

export const fetchHubListAction = (): FetchHubListAction => ({
  type: FETCH_HUB_LIST
});

export type HubListFetchedPayload = {
  hubs: HubEntity[];
  totalPages: number;
};

export interface HubListFetchedAction extends Action<typeof HUB_LIST_FETCHED> {
  payload: HubListFetchedPayload;
}

export const hubListFetchedAction = (
  payload: HubListFetchedPayload
): HubListFetchedAction => ({
  type: HUB_LIST_FETCHED,
  payload
});
