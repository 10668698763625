import { MeasurementsStatusDescEntity } from 'modules/Shared/configs/measurementStatusDescEntity/type';
import { MeasurementsKnowHow } from 'modules/Shared/configs/measurementKnowHowEntity/type';

export type ConnectedPlantListEntity = {
  id?: number;
  name?: string;
  scientific_name?: string;
  thumb_path?: string;
  origin_path?: string;
  images?: {
    id: number;
    origin_path: string;
    thumb_path: string;
    updated_at: string;
  }[];
};

export type PlantGroupDetailsBaseEntity = {
  id?: number;
  name?: string;
  measurements_statuses_desc?: MeasurementsStatusDescEntity[];
  measurements_statuses_know_hows?: MeasurementsKnowHow[];
  created_at?: string;
  updated_at?: string;
};

export type PlantGroupDetailsEntity = PlantGroupDetailsBaseEntity & {
  plants: ConnectedPlantListEntity[];
};

export default class PlantGroupDetails {
  readonly id?: number;
  readonly name?: string;
  readonly measurements_statuses_desc?: MeasurementsStatusDescEntity[];
  measurements_statuses_know_hows?: MeasurementsKnowHow[];
  readonly created_at?: string;
  readonly updated_at?: string;
  plants: ConnectedPlantListEntity[];

  constructor(entity: PlantGroupDetailsEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.measurements_statuses_desc = entity.measurements_statuses_desc;
    this.measurements_statuses_know_hows =
      entity.measurements_statuses_know_hows;
    this.measurements_statuses_know_hows =
      entity.measurements_statuses_know_hows;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.plants = entity.plants;
  }
}

export const createPlantGroupDetails = (
  entity: PlantGroupDetailsEntity
): PlantGroupDetails => new PlantGroupDetails(entity);
