import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setPlantImportsListParamsAction,
  SetPlantImportsListParamsAction
} from 'modules/Plants/action/list';
import Plant from 'modules/Plants/model/Plant';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PlantImport from 'modules/Plants/model/PlantImport';

export interface StateProps {
  plantImports: PlantImport[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetPlantImportsListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (plant: Plant) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { plantImports, sort } = state.plant.list;

  return { plantImports, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPlantImportsListParamsAction(payload))
});

export class PlantImportsTable extends React.Component<Props> {
  readonly cols: TableCol<PlantImport>[];

  constructor(props: Props) {
    super(props);

    this.cols = [
      {
        property: 'file',
        label: 'File'
      },
      {
        property: 'progress',
        label: 'Processed / Total',
        value: (row) => `${row.progress} / ${row.total}`
      },
      {
        property: 'error',
        label: 'Error',
        value: (row) => row.getError()
      },
      {
        property: 'created_at',
        label: 'Created at',
        sortable: true
      },
      {
        property: 'updated_at',
        label: 'Updated at',
        sortable: true
      },
      {
        property: 'finished_at',
        label: 'Finished at',
        value: (row) => row.getFinishedAt(),
        sortable: true
      }
    ];
  }

  render(): ReactNode {
    const { plantImports, sort, setParams } = this.props;

    return (
      <Table
        cols={this.cols}
        rows={plantImports}
        sort={sort}
        onSort={(params) => setParams({ sort: params })}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantImportsTable);
