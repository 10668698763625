import { createAuthRoutes } from 'modules/Auth/routes';
import { createLayoutRoutes } from 'modules/Layout/routes';
import { ReactNode } from 'react';
import { createUserRoutes } from 'modules/User/routes';
import { createHubRoutes } from 'modules/Hub/routes';
import { createSensorRoutes } from 'modules/Sensor/routes';
import { createPlantsRoutes } from 'modules/Plants/routes';
import { createFamilyRoutes } from 'modules/Family/routes';
import { createPlantGroupsRoutes } from 'modules/PlantGroups/routes';
import { createMediaRoutes } from 'modules/Media/routes';
import { createPlantUserRoutes } from 'modules/PlantUser/routes';
import { createDictionaryRoutes } from 'modules/Dictionary/routes';
import { createGardenRoutes } from 'modules/Garden/routes';
import { createNewsRoutes } from 'modules/News/routes';
import { createMissingFamilyRoutes } from 'modules/MissingFamily/routes';
import { createToolsRoutes } from 'modules/Tools/routes';
import { createPolicyRoutes } from 'modules/Policy/routes';
import { createPlantDiseasesRoutes } from 'modules/PlantDiseases/routes';
import { createFaqRoutes } from 'modules/Faq/routes';

export type RouteType = 'public' | 'guarded' | 'wrapped';

export type Routes = {
  [type in RouteType]?: ReactNode[];
};

export const registerRoutes = (...routes: Routes[]): Routes => {
  return routes.reduce(
    (previousValue, currentValue) => ({
      public: [...previousValue.public, ...(currentValue.public ?? [])],
      guarded: [...previousValue.guarded, ...(currentValue.guarded ?? [])],
      wrapped: [...previousValue.wrapped, ...(currentValue.wrapped ?? [])]
    }),
    {
      public: [],
      guarded: [],
      wrapped: []
    }
  );
};

export const createRoutes = (): Routes => {
  return registerRoutes(
    createLayoutRoutes(),
    createAuthRoutes(),
    createUserRoutes(),
    createHubRoutes(),
    createSensorRoutes(),
    createPlantsRoutes(),
    createFamilyRoutes(),
    createPlantGroupsRoutes(),
    createMediaRoutes(),
    createPlantUserRoutes(),
    createDictionaryRoutes(),
    createGardenRoutes(),
    createNewsRoutes(),
    createMissingFamilyRoutes(),
    createToolsRoutes(),
    createPolicyRoutes(),
    createPlantDiseasesRoutes(),
    createFaqRoutes()
  );
};
