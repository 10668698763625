import classNames from 'classnames';
import * as H from 'history';
import { ColorVariants } from 'modules/Shared/type';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

export interface ActionProps {
  id?: string;
  title?: string;
  label?: ReactNode;
  color?: ColorVariants;
  className?: string;
  disabled?: boolean;
  to?: H.LocationDescriptor | ((location: H.Location) => H.LocationDescriptor);
  onClick?: () => void;
}

const Action: React.FC<ActionProps> = ({
  title = null,
  label = null,
  color = ColorVariants.Primary,
  className = '',
  disabled = false,
  to = null,
  onClick = null,
  id = null
}: ActionProps): JSX.Element => {
  const calculatedColor = disabled ? 'gray' : color;

  if (onClick || disabled) {
    return (
      <>
        <Button
          id={id}
          type="button"
          color="link"
          className={classNames(
            'd-inline-flex',
            'align-items-center',
            'justify-content-center',
            'p-0',
            className,
            `text-${calculatedColor}`
          )}
          title={title}
          disabled={disabled}
          onClick={onClick}
        >
          {label}
        </Button>
      </>
    );
  }

  if (to) {
    return (
      <Link
        id={id}
        title={title}
        className={classNames(
          'd-inline-flex',
          'align-items-center',
          'justify-content-center',
          className,
          color && `text-${calculatedColor}`
        )}
        to={to}
      >
        {label}
      </Link>
    );
  }

  return null;
};

export default Action;
