import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import News from 'modules/News/model/News';
import React, { createRef, ReactNode } from 'react';
import { connect } from 'react-redux';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setNewsListParamsAction,
  SetNewsListParamsAction
} from 'modules/News/action/List';
import { Dispatch } from 'redux';
import ActionShow from 'modules/Layout/component/Action/Show';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_NEWS_DETAILS, ROUTE_NEWS_EDIT } from 'modules/News/routes';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import DeleteNewsModal, {
  DeleteNewsModal as DeleteNewsModalClass
} from 'modules/News/component/Modal/Delete';
import ActionDelete from 'modules/Layout/component/Action/Delete';

export interface StateProps {
  news: News[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetNewsListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (news: News) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { news, sort } = state.news.list;

  return { news, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setNewsListParamsAction(payload))
});

export class NewsTable extends React.Component<Props> {
  readonly cols: TableCol<News>[];
  readonly deleteNewsModal: React.RefObject<DeleteNewsModalClass>;

  constructor(props: Props) {
    super(props);

    this.deleteNewsModal = createRef();

    this.cols = [
      {
        property: 'image',
        label: 'Image',
        type: 'img',
        className: 'col-2',
        value: (row) => row.image || '',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_NEWS_DETAILS, { id: row.id })
      },
      {
        property: 'title',
        label: 'Title',
        className: 'col-6',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_NEWS_DETAILS, { id: row.id })
      },
      {
        property: 'section',
        label: 'Section',
        className: 'col-2',
        value: (row) => News.getSection(row.section)
      },
      {
        property: 'type',
        label: 'Type',
        className: 'col-2'
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show news"
              to={getPathUrl(ROUTE_NEWS_DETAILS, { id: row.id })}
            />
            <ActionUpdate
              title="Edit news"
              to={getPathUrl(ROUTE_NEWS_EDIT, { id: row.id })}
            />
            <ActionDelete
              title="Delete news"
              onClick={() => this.deleteNewsModal?.current?.openModal(row)}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { news, sort, setParams } = this.props;

    return (
      <>
        <DeleteNewsModal
          ref={this.deleteNewsModal}
          onSuccess={() => setParams({ sort })}
        />
        <Table
          cols={this.cols}
          rows={news}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(NewsTable);
