import React, { ReactNode } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import wysiwygToolbar from 'modules/Layout/component/Wysiwyg/config';
import './style.scss';

export interface Props {
  initialEditorState?: EditorState;
  initialValue?: string;
  toolbar?: Record<string, unknown>;
  onChange?: (editorState: EditorState) => void;
  toolbarCustomButtons?: Array<React.ReactElement<HTMLElement>> | undefined;
}

export interface State {
  editorState: EditorState;
}

export class Wysiwyg extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { initialValue, initialEditorState } = props;

    if (initialValue) {
      const contentBlock = htmlToDraft(initialValue);
      let editorState;
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        editorState = EditorState.createWithContent(contentState);

        this.state = {
          editorState
        };
      }
    } else if (initialEditorState) {
      this.state = {
        editorState: initialEditorState
      };
    } else {
      this.state = {
        editorState: EditorState.createEmpty()
      };
    }

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange(editorState: EditorState): void {
    const { onChange } = this.props;

    if (onChange) {
      onChange(editorState);
    }

    this.setState({
      editorState
    });
  }

  render(): ReactNode {
    const { toolbar = wysiwygToolbar, toolbarCustomButtons = [] } = this.props;
    const { editorState } = this.state;

    return (
      <Editor
        editorState={editorState}
        onEditorStateChange={this.onEditorStateChange}
        toolbar={toolbar}
        localization={{
          locale: 'en'
        }}
        handlePastedText={() => false}
        toolbarCustomButtons={toolbarCustomButtons}
      />
    );
  }
}

export default Wysiwyg;
