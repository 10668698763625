import {
  FETCH_MISSING_FAMILY_DETAILS,
  MISSING_FAMILY_DETAILS_FETCHED,
  SET_MISSING_FAMILY_DETAILS_PARAMS,
  MissingFamilyDetailsAction
} from 'modules/MissingFamily/action/Details';
import { createMissingFamilyDetails } from 'modules/MissingFamily/model/MissingFamilyDetails';
import {
  initMissingFamilyDetailsState,
  MissingFamilyDetailsState
} from 'modules/MissingFamily/state/details';

const initState = initMissingFamilyDetailsState();

const reducer = (
  state: MissingFamilyDetailsState = initState,
  action: MissingFamilyDetailsAction
): MissingFamilyDetailsState => {
  switch (action.type) {
    case SET_MISSING_FAMILY_DETAILS_PARAMS:
      return { ...state, missingFamilyId: action.payload };

    case FETCH_MISSING_FAMILY_DETAILS:
      return { ...state, fetching: true };

    case MISSING_FAMILY_DETAILS_FETCHED: {
      const { missingFamilyDetails = null } = action.payload;

      return {
        ...state,
        fetching: false,
        missingFamilyDetails: createMissingFamilyDetails(missingFamilyDetails)
      };
    }

    default:
      return state;
  }
};

export default reducer;
