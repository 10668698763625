import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteSensorDetails } from 'modules/Sensor/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { setSensorDetailsParamsAction } from 'modules/Sensor/action/details';
import { RootState } from 'app/reducer';
import Loader from 'modules/Layout/component/Loader';
import DetailsMain from 'modules/Sensor/view/sensorDetails/components/DisplayDetailsMain';

interface IParams {
  id: string;
}

export const FamilyDetailsView = () => {
  const dispatch = useDispatch();
  const params = useParams<IParams>();
  const sensorDetails = useSelector(
    (state: RootState) => state.sensor.sensorDetails
  );
  const { type } = useSelector((state: RootState) => state.layout.page);

  const { id } = params;

  useEffect(() => {
    dispatch(
      managePageAction({
        title: sensorDetails?.sensorDetails
          ? `Sensor - ${sensorDetails.sensorDetails.sensor_id}`
          : 'Sensor',
        breadcrumb: breadcrumbRouteSensorDetails(sensorDetails?.sensorDetails)
      })
    );
  }, [sensorDetails.sensorDetails]);

  useEffect(() => {
    if (id) {
      dispatch(setSensorDetailsParamsAction(id));
    }
  }, []);

  if (type) return null;
  if (sensorDetails.fetching)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );
  return (
    <div className="position-relative">
      <DetailsMain data={sensorDetails.sensorDetails} />
    </div>
  );
};

export default FamilyDetailsView;
