import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import MissingFamily from 'modules/MissingFamily/model/MissingFamily';

export interface MissingFamilyListState extends SearchingProps {
  missingFamilies: MissingFamily[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initMissingFamilyListState = (): MissingFamilyListState => ({
  missingFamilies: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
