import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setPlantGroupsListParamsAction,
  SetPlantGroupsListParamsAction
} from 'modules/PlantGroups/action/list';
import PlantGroups from 'modules/PlantGroups/model/PlantGroups';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ROUTE_PLANT_GROUP_EDIT,
  ROUTE_PLANT_GROUP_MANAGE_PLANTS,
  ROUTE_PLANT_GROUPS_DETAILS
} from 'modules/PlantGroups/routes';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import { getPathUrl } from 'modules/Shared/helper/api';
import DeletePlantGroupModal, {
  DeletePlantGroupModal as DeletePlantGroupModalClass
} from 'modules/PlantGroups/component/Modal/Delete';
import ActionShow from 'modules/Layout/component/Action/Show';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionRedo from 'modules/Layout/component/Action/Redo';

export interface StateProps {
  plantGroups: PlantGroups[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetPlantGroupsListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (plant: PlantGroups) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { plantGroups, sort } = state.plantGroups.list;

  return { plantGroups, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPlantGroupsListParamsAction(payload))
});

export class PlantGroupsTable extends React.Component<Props> {
  readonly cols: TableCol<PlantGroups>[];
  readonly modalDeleteRef: React.RefObject<DeletePlantGroupModalClass>;

  constructor(props: Props) {
    super(props);

    this.modalDeleteRef = React.createRef();

    this.openModal = this.openModal.bind(this);

    this.cols = [
      {
        property: 'name',
        label: 'Name',
        sortable: true,
        className: 'col-8',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_PLANT_GROUPS_DETAILS, { id: row.id })
      },
      {
        property: 'plants_count',
        label: 'Plants count',
        className: 'col-4'
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              id="show-plant-group"
              title="Show plant group"
              to={getPathUrl(ROUTE_PLANT_GROUPS_DETAILS, { id: row.id })}
            />
            <ActionUpdate
              id="edit-plant-group"
              title="Edit plant group"
              to={getPathUrl(ROUTE_PLANT_GROUP_EDIT, { id: row.id })}
            />
            <ActionRedo
              id="manage-plants"
              title="Manage plants in group"
              label={<i className="fas font-20 fa-seedling" />}
              to={getPathUrl(ROUTE_PLANT_GROUP_MANAGE_PLANTS, { id: row.id })}
            />
            <ActionDelete
              id="delete-plant-group"
              title="Delete plant group"
              onClick={() => this.openModal(row, this.modalDeleteRef)}
            />
          </div>
        )
      }
    ];
  }

  openModal(
    plantGroup: PlantGroups,
    modalRef: React.RefObject<DeletePlantGroupModalClass>
  ): void {
    modalRef?.current?.open(plantGroup);
  }

  render(): ReactNode {
    const { plantGroups, sort, setParams } = this.props;

    return (
      <>
        <DeletePlantGroupModal
          ref={this.modalDeleteRef}
          onDeleted={() => setParams({ sort })}
        />
        <Table
          cols={this.cols}
          rows={plantGroups}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantGroupsTable);
