import React, { useRef } from 'react';
import {
  MeasurementsKnowHow as MeasurementsKnowHowType,
  MeasurementsKnowHow
} from 'modules/Shared/configs/measurementKnowHowEntity/type';
import Table, { TableCol } from 'modules/Layout/component/Table';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import DeleteMeasurementsKnowHowModal from 'modules/Plants/component/Modal/MeasurementsKnowHow/Delete';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import PlantGroupDetails from 'modules/PlantGroups/model/PlantGroupDetails';
import FamilyDetails from '../../../../Family/model/FamilyDetails';

export interface Props {
  measurementsKnowHow: MeasurementsKnowHow[];
  plant?: PlantDetails;
  plantGroup?: PlantGroupDetails;
  family?: FamilyDetails;
  showActions?: boolean;
  plantGroupUpdated?: (
    knowHow: MeasurementsKnowHowType,
    type: 'add' | 'delete'
  ) => void;
}

export const MeasurementsKnowHowTable: React.FC<Props> = (props: Props) => {
  const {
    measurementsKnowHow,
    plant,
    plantGroup,
    plantGroupUpdated,
    family,
    showActions = true
  } = props;
  const deleteModalRef = useRef(null);

  const cols: TableCol<MeasurementsKnowHow>[] = [
    {
      property: 'image',
      label: 'Image',
      type: 'img',
      value: (row) => row.image,
      className: 'col-2'
    },
    {
      property: 'title',
      label: 'Title',
      className: 'col-4'
    },
    {
      property: 'type',
      label: 'Type',
      className: 'col-2'
    },
    {
      property: 'url',
      label: 'Link',
      className: 'col-4'
    },
    {
      property: null,
      label: 'Action',
      value: (row) =>
        showActions ? (
          <div className="actions-wrapper">
            <ActionDelete
              title="Delete media"
              onClick={() =>
                deleteModalRef?.current?.openModal(
                  plant,
                  row,
                  plantGroup,
                  family
                )
              }
            />
          </div>
        ) : null
    }
  ];

  return (
    <>
      <DeleteMeasurementsKnowHowModal
        ref={deleteModalRef}
        plantGroupUpdated={plantGroupUpdated}
      />
      <Table
        rowKey={(row: MeasurementsKnowHowType) => row.id}
        cols={cols}
        rows={measurementsKnowHow}
      />
    </>
  );
};
