import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import Loader from 'modules/Layout/component/Loader';
import Plant from 'modules/Plants/model/Plant';
import InformationList from 'modules/Layout/component/InformationList';
import { deletePlantKnowHow } from 'modules/Plants/repository';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import {
  deleteMeasurementKnowHowToastError,
  deleteMeasurementKnowHowToastSuccess
} from 'modules/Plants/toasts';
import { connect } from 'react-redux';
import {
  MeasurementsKnowHow as MeasurementsKnowHowType,
  MeasurementsKnowHow
} from 'modules/Shared/configs/measurementKnowHowEntity/type';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import {
  PlantDetailsUpdateAction,
  plantDetailsUpdatedAction
} from 'modules/Plants/action/Details';
import PlantGroupDetails from 'modules/PlantGroups/model/PlantGroupDetails';
import {
  deleteMediaForFamily,
  deleteMediaForPlantGroup
} from 'modules/PlantGroups/repository';
import {
  DeleteFamilyKnowHowAction,
  deleteFamilyKnowHowAction,
  DeleteFamilyKnowHowPayload
} from 'modules/Family/action/Details';
import FamilyDetails from '../../../../../Family/model/FamilyDetails';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
  plantDetailsUpdatedAction: (
    payload: PlantDetails
  ) => PlantDetailsUpdateAction;
  deleteFamilyKnowHow: (
    payload: DeleteFamilyKnowHowPayload
  ) => DeleteFamilyKnowHowAction;
}

export interface Props extends DispatchProps {
  onSuccess?: (measurementKnowHow?: MeasurementsKnowHow) => void;
  plantGroupUpdated?: (
    knowHow: MeasurementsKnowHowType,
    type: 'add' | 'delete'
  ) => void;
}

export interface State {
  isOpen: boolean;
  plant?: PlantDetails;
  plantGroup?: PlantGroupDetails;
  family?: FamilyDetails;
  measurementKnowHow: MeasurementsKnowHow;
  fetching: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload)),
  plantDetailsUpdatedAction: (payload) =>
    dispatch(plantDetailsUpdatedAction(payload)),
  deleteFamilyKnowHow: (payload) => dispatch(deleteFamilyKnowHowAction(payload))
});

export class DeleteMeasurementsKnowHowModal extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      plant: null,
      plantGroup: null,
      family: null,
      measurementKnowHow: null,
      fetching: false
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  openModal(
    plant: Plant,
    measurementKnowHow: MeasurementsKnowHow,
    plantGroup: PlantGroupDetails,
    family: FamilyDetails
  ): void {
    this.setState({
      isOpen: true,
      fetching: false,
      measurementKnowHow,
      plant,
      plantGroup,
      family
    });
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  async onSubmit(): Promise<void> {
    const {
      onSuccess,
      addToast,
      plantDetailsUpdatedAction: plantDetailsUpdatedActionProp,
      plantGroupUpdated,
      deleteFamilyKnowHow
    } = this.props;
    const { measurementKnowHow, plant, plantGroup, family } = this.state;

    this.setState({
      fetching: true
    });

    try {
      if (plant) {
        await deletePlantKnowHow(measurementKnowHow.id);
        const filteredMeasurements =
          plant.measurements_statuses_know_hows.filter(
            (obj) => Number(obj.id) !== Number(measurementKnowHow.id)
          );

        plantDetailsUpdatedActionProp({
          ...plant,
          measurements_statuses_know_hows: filteredMeasurements
        });
      } else if (plantGroup) {
        await deleteMediaForPlantGroup(measurementKnowHow.id);

        plantGroupUpdated(measurementKnowHow, 'delete');
      } else if (family) {
        await deleteMediaForFamily(measurementKnowHow.id);

        deleteFamilyKnowHow({ id: measurementKnowHow.id });
      }

      addToast(deleteMeasurementKnowHowToastSuccess());

      this.toggle();

      if (onSuccess) {
        onSuccess(measurementKnowHow);
      }
    } catch (error) {
      console.log(error);
      addToast(deleteMeasurementKnowHowToastError());

      if (onSuccess) {
        onSuccess();
      }
    }
  }

  renderTitle = (): React.ReactNode => {
    const { plantGroup, family } = this.state;

    let type = 'plant';

    if (plantGroup) {
      type = 'plant group';
    } else if (family) {
      type = 'family';
    }

    return `Do you want to delete media from ${type}?`;
  };

  renderBody(): React.ReactNode {
    const { fetching, measurementKnowHow } = this.state;

    const fields = [
      {
        label: 'Title:',
        value: measurementKnowHow.title
      },
      {
        label: 'Type:',
        value: measurementKnowHow.type
      },
      {
        label: 'Url:',
        value: measurementKnowHow.url
      }
    ];

    return (
      <>
        {fetching && <Loader />}
        {Boolean(measurementKnowHow.image) && (
          <div className="d-flex justify-content-center mb-3">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img style={{ height: '150px' }} src={measurementKnowHow.image} />
          </div>
        )}
        <InformationList fields={fields} />
      </>
    );
  }

  renderFooter(): React.ReactNode {
    const { fetching } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button disabled={fetching} color="primary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button disabled={fetching} color="danger" onClick={this.onSubmit}>
          Delete
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen, plant, plantGroup, measurementKnowHow, family } =
      this.state;

    if (measurementKnowHow && (plant || plantGroup || family)) {
      return (
        <BaseModal
          isOpen={isOpen}
          title={this.renderTitle()}
          body={this.renderBody()}
          footer={this.renderFooter()}
          toggle={this.toggle}
        />
      );
    }

    return null;
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(DeleteMeasurementsKnowHowModal);
