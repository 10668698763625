import React from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { SensorEntity } from 'modules/Sensor/model/Sensor';
import { ROUTE_SENSOR_DETAILS_REDIRECT_TEMPLATE } from 'modules/Sensor/routes';

export interface Props {
  sensors: SensorEntity[];
}

const UserDetailsSensors = (props: Props): JSX.Element => {
  const { sensors } = props;

  const cols: TableCol<SensorEntity>[] = [
    {
      label: 'Mac',
      property: 'mac_address'
    },
    {
      label: 'Version',
      property: 'version'
    }
  ];

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Sensors">
        <Table
          cols={cols}
          rows={sensors}
          onRowClickPathIdMappedBy="mac_address"
          onRowClickPath={ROUTE_SENSOR_DETAILS_REDIRECT_TEMPLATE}
        />
      </DisplayBaseTabContent>
    </div>
  );
};

export default UserDetailsSensors;
