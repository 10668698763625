import React from 'react';
import PlantGroupDetailsModel from 'modules/PlantGroups/model/PlantGroupDetails';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { ROUTE_PLANTS_DETAILS_REDIRECT_TEMPLATE } from 'modules/Plants/routes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANT_GROUP_MANAGE_PLANTS } from 'modules/PlantGroups/routes';

export interface Props extends RouteComponentProps {
  plantGroup: PlantGroupDetailsModel;
}

const PlantGroupDetailsPlants = (props: Props): JSX.Element => {
  const { plantGroup, history } = props;

  const cols: TableCol<PlantGroupDetailsModel['plants'][0]>[] = [
    {
      label: 'Image',
      property: 'thumb_path',
      type: 'img',
      value: (row) => (row.images.length > 0 ? row.images[0].thumb_path : null),
      className: 'col-2'
    },
    {
      label: 'Scientific name',
      property: 'scientific_name',
      className: 'col-5'
    },
    {
      label: 'Name',
      property: 'name',
      className: 'col-5'
    }
  ];

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent
        title="Plants"
        additionalButtons={[
          {
            callback: () =>
              history.push(
                getPathUrl(ROUTE_PLANT_GROUP_MANAGE_PLANTS, {
                  id: plantGroup.id
                })
              ),
            text: 'Manage plants in group',
            bsColor: 'primary'
          }
        ]}
      >
        <Table
          cols={cols}
          rows={plantGroup.plants}
          onRowClickPath={ROUTE_PLANTS_DETAILS_REDIRECT_TEMPLATE}
        />
      </DisplayBaseTabContent>
    </div>
  );
};

export default withRouter(PlantGroupDetailsPlants);
