export interface FaqEntity {
  id?: number;
  type?: 'hub';
  lang?: {
    id: number;
    lang: string;
    text: string;
  }[];
}

export class Faq {
  readonly id?: number;
  readonly type?: 'hub';
  readonly lang?: {
    id: number;
    lang: string;
    text: string;
  }[];

  constructor(entity?: FaqEntity) {
    this.id = entity?.id;
    this.type = entity?.type;
    this.lang = entity?.lang;
  }

  getFaqTypeName(): string {
    switch (this.type) {
      case 'hub': {
        return 'Hub';
      }

      default: {
        return '';
      }
    }
  }
}

export const createFaq = (entity?: FaqEntity) => new Faq(entity);
