import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deleteSensorSoftwareToastSuccess = (): AddToastPayload => ({
  header: 'Delete sensor software',
  body: 'Sensor software was deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteSensorSoftwareToastError = (): AddToastPayload => ({
  header: 'Delete sensor software',
  body: 'Sensor software was not deleted.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const publishSensorSoftwareToastSuccess = (): AddToastPayload => ({
  header: 'Publish sensor software',
  body: 'Sensor software was published.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const publishSensorSoftwareToastError = (): AddToastPayload => ({
  header: 'Publish sensor software',
  body: 'Sensor software was not published.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const editSensorSoftwareToastSuccess = (): AddToastPayload => ({
  header: 'Edit sensor software',
  body: 'Sensor software was updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const editSensorSoftwareToastError = (): AddToastPayload => ({
  header: 'Edit sensor software',
  body: 'Sensor software was not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deleteSensorToastSuccess = (): AddToastPayload => ({
  header: 'Delete Sensor',
  body: 'Sensor deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteSensorToastError = (): AddToastPayload => ({
  header: 'Delete Sensor',
  body: 'Deleting Sensor failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
