import {
  DICTIONARY_LIST_FETCHED,
  DictionaryListAction,
  FETCH_DICTIONARY_LIST,
  SET_DICTIONARY_LIST_PARAMS
} from 'modules/Dictionary/action/list';
import {
  DictionaryListState,
  initDictionaryListState
} from 'modules/Dictionary/state/list';
import { createDictionary } from 'modules/Dictionary/model/Dictionary';

const initState = initDictionaryListState();

const reducer = (
  state: DictionaryListState = initState,
  action: DictionaryListAction
): DictionaryListState => {
  switch (action.type) {
    case SET_DICTIONARY_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_DICTIONARY_LIST:
      return { ...state, fetching: true };

    case DICTIONARY_LIST_FETCHED: {
      const { dictionaries = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        dictionaries: dictionaries.map(createDictionary),
        totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
