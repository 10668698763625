import PlantGroupDetails from 'modules/PlantGroups/model/PlantGroupDetails';

export interface PlantGroupDetailsState {
  plantGroupDetails: PlantGroupDetails;
  fetching: boolean;
  plantGroupId: number;
}

export const initPlantGroupDetailsState = (): PlantGroupDetailsState => ({
  plantGroupDetails: null,
  fetching: false,
  plantGroupId: null
});
