import { SelectOption } from 'modules/Shared/type';
import { PlantGroupsEntity } from 'modules/PlantGroups/model/PlantGroups';
import { ImagesObject } from 'modules/Plants/model/PlantDetails';

export interface PlantEntity {
  id?: number;
  family_id?: number;
  thumb_path?: string;
  scientific_name?: string;
  plant_groups_count?: number;
  name?: string;
  genus?: string;
  alternative_names?: string;
  is_deleted?: boolean;
  confirmed?: boolean;
  images?: ImagesObject[];
  created_at?: string;
  updated_at?: string;
  plant_groups?: PlantGroupsEntity[];
}

export default class Plant {
  readonly id?: number;
  readonly family_id?: number;
  readonly thumb_path?: string;
  readonly scientific_name?: string;
  readonly plant_groups_count?: number;
  readonly name?: string;
  readonly genus?: string;
  readonly alternative_names?: string;
  readonly is_deleted?: boolean;
  readonly confirmed?: boolean;
  readonly images?: ImagesObject[];

  readonly created_at?: string;
  readonly updated_at?: string;
  readonly plant_groups?: PlantGroupsEntity[];

  constructor(entity: PlantEntity) {
    this.id = entity.id;
    this.family_id = entity.family_id;
    this.thumb_path = entity.thumb_path;
    this.scientific_name = entity.scientific_name;
    this.plant_groups_count = entity.plant_groups_count;
    this.name = entity.name;
    this.genus = entity.genus;
    this.alternative_names = entity.alternative_names;
    this.is_deleted = entity.is_deleted;
    this.confirmed = entity.confirmed;
    this.images = entity.images;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.plant_groups = entity.plant_groups;
  }

  static getFilterableAttributes(): string[] {
    return [
      'scientific_name',
      'name',
      'genus_name',
      'alternative_names',
      'with_deleted',
      'confirmed',
      'genus',
      'responded'
    ];
  }

  static getSortableAttributes(): string[] {
    return ['id', 'scientific_name', 'name', 'genus_name'];
  }

  static getPlantDeleteStatusOptions(): SelectOption[] {
    return [
      {
        value: 0,
        label: 'No'
      },
      {
        value: 1,
        label: 'Yes'
      }
    ];
  }

  static getPlantConfirmedStatusOptions(): SelectOption[] {
    return [
      {
        value: 0,
        label: 'No'
      },
      {
        value: 1,
        label: 'Yes'
      }
    ];
  }
}

export const createPlant = (entity: PlantEntity): Plant => new Plant(entity);
