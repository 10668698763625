import {
  MeasurementsStatusDescEntity,
  MeasurementTypesEnum,
  getStatusValue
} from 'modules/Shared/configs/measurementStatusDescEntity/type';

export const extractValueFromMeasurement = (
  obj: { option: string; measurement: MeasurementTypesEnum; lang: string },
  measurements: MeasurementsStatusDescEntity[]
) => {
  const found = measurements?.find(
    (value) =>
      value?.measurement === obj.measurement &&
      value?.lang === obj.lang &&
      value?.measurement_status === getStatusValue(obj.option)
  );

  return {
    description: found?.description,
    long_term_description: found?.long_term_description
  };
};

export const extractObjectFromMeasurement = (
  obj: { measurement: MeasurementTypesEnum; lang: string },
  measurements: MeasurementsStatusDescEntity[]
) => {
  const arr: MeasurementsStatusDescEntity[] = [];
  measurements?.forEach((value) => {
    if (value?.measurement === obj.measurement && value?.lang === obj.lang)
      arr.push(value);
  });
  return arr;
};

export const removeDuplicateFromMeasurement = (
  obj: { measurement: MeasurementTypesEnum; lang: string; statuses: number[] },
  measurements: MeasurementsStatusDescEntity[]
) => {
  const arr: MeasurementsStatusDescEntity[] = [];
  measurements?.forEach((value) => {
    if (value?.measurement === obj.measurement) {
      if (value?.lang === obj.lang) {
        if (!obj.statuses?.includes(value?.measurement_status)) {
          arr.push(value);
        }
      } else arr.push(value);
    } else arr.push(value);
  });
  return arr;
};
