import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteDetailsMissingFamily } from 'modules/MissingFamily/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { setMissingFamilyDetailsParamsAction } from 'modules/MissingFamily/action/Details';
import { RootState } from 'app/reducer';
import DetailsMain from 'modules/MissingFamily/view/Details/components/DisplayDetailsMain';
import Loader from 'modules/Layout/component/Loader';
import { ROUTE_MISSING_FAMILY } from 'modules/MissingFamily/routes';
import MissingFamily from 'modules/MissingFamily/model/MissingFamily';

interface IParams {
  id: string;
}

export const MissingFamilyDetailsView = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector(
    (state: RootState) => state.missingFamily.details
  );
  const { type } = useSelector((state: RootState) => state.layout.page);

  useEffect(() => {
    dispatch(
      managePageAction({
        title:
          details?.missingFamilyDetails?.family_scientific_name ||
          'Missing family',
        breadcrumb: breadcrumbRouteDetailsMissingFamily(
          details?.missingFamilyDetails as unknown as MissingFamily
        )
      })
    );
  }, [details?.missingFamilyDetails]);

  useEffect(() => {
    if (params?.id) {
      const id = parseInt(params.id, 10);
      if (id) dispatch(setMissingFamilyDetailsParamsAction(id));
      else history.push(ROUTE_MISSING_FAMILY);
    }
  }, []);

  if (type) return null;
  if (details.fetching)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );
  return (
    <div className="position-relative">
      <DetailsMain data={details.missingFamilyDetails} />
    </div>
  );
};

export default MissingFamilyDetailsView;
