export interface PhResultsMedia {
  id?: number;
  url?: string;
  image?: string;
  type?: string;
  title?: string;
  advancement_level?: string;
}

export interface PhResultsLang {
  id: number;
  lang: string;
  description: string;
  what_you_can_do: string;
  media?: PhResultsMedia;
}

export interface PhResultsEntity {
  id?: number;
  status?: number;
  lang?: PhResultsLang[];
}

export default class PhResults {
  readonly id?: number;
  readonly status?: number;
  readonly lang?: PhResultsLang[];

  constructor(entity: PhResultsEntity) {
    this.id = entity.id;
    this.status = entity.status;
    this.lang = entity?.lang || [];
  }

  getStatus(): string {
    switch (this.status) {
      case 0: {
        return 'Too low pH result';
      }

      case 1: {
        return 'Optimal pH result';
      }

      case 2: {
        return 'Too high pH result';
      }

      default: {
        return '';
      }
    }
  }
}

export const createPhResults = (entity: PhResultsEntity): PhResults =>
  new PhResults(entity);
