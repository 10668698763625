import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { Action } from 'redux';
import { DictionaryEntity } from 'modules/Dictionary/model/Dictionary';

export const SET_DICTIONARY_LIST_PARAMS = 'DICTIONARY/LIST/PARAMS/SET';
export const UPDATE_DICTIONARY_LIST_PARAMS = 'DICTIONARY/LIST/PARAMS/UPDATE';
export const FETCH_DICTIONARY_LIST = 'DICTIONARY/LIST/FETCH';
export const DICTIONARY_LIST_FETCHED = 'DICTIONARY/LIST/FETCHED';

export type DictionaryListAction =
  | SetDictionaryListParamsAction
  | FetchDictionaryListAction
  | DictionaryListFetchedAction;

export type SetDictionaryListParamsAction = SetSearchingParamsAction<
  typeof SET_DICTIONARY_LIST_PARAMS
>;

export const setDictionaryListParamsAction = (
  payload: PartialSearchingProps
): SetDictionaryListParamsAction =>
  setSearchingParamsAction(SET_DICTIONARY_LIST_PARAMS, payload);

export type FetchDictionaryListAction = Action<typeof FETCH_DICTIONARY_LIST>;

export const fetchDictionaryListAction = (): FetchDictionaryListAction => ({
  type: FETCH_DICTIONARY_LIST
});

export type DictionaryListFetchedPayload = {
  dictionaries: DictionaryEntity[];
  totalPages: number;
};

export interface DictionaryListFetchedAction
  extends Action<typeof DICTIONARY_LIST_FETCHED> {
  payload: DictionaryListFetchedPayload;
}

export const dictionaryListFetchedAction = (
  payload: DictionaryListFetchedPayload
): DictionaryListFetchedAction => ({
  type: DICTIONARY_LIST_FETCHED,
  payload
});
