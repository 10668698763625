import React from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import Hub from 'modules/Hub/model/Hub';
import { ROUTE_PLANT_USER_DETAILS_REDIRECT_TEMPLATE } from 'modules/PlantUser/routes';
import ProtectedImage from 'modules/Layout/component/ProtectedImage';

export interface Props {
  hub: Hub;
}

const HubDetailsPlants = (props: Props): JSX.Element => {
  const { hub } = props;

  const cols: TableCol<Hub['plants'][0]>[] = [
    {
      label: 'Image',
      property: 'thumb_path',
      // eslint-disable-next-line react/prop-types,react/display-name
      value: ({ thumb_path }) =>
        thumb_path ? <ProtectedImage url={thumb_path} width={200} /> : null,
      className: 'col-2'
    },
    {
      label: 'Nickname',
      property: 'nickname',
      className: 'col-10'
    }
  ];

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="User plants">
        <Table
          cols={cols}
          rows={hub.plants}
          onRowClickPath={ROUTE_PLANT_USER_DETAILS_REDIRECT_TEMPLATE}
        />
      </DisplayBaseTabContent>
    </div>
  );
};

export default HubDetailsPlants;
