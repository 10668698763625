import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import authReducer from 'modules/Auth/reducer';
import { AuthState } from 'modules/Auth/state';
import layoutReducer from 'modules/Layout/reducer';
import { LayoutState } from 'modules/Layout/state';
import { combineReducers, Reducer } from 'redux';
import userReducer from 'modules/User/reducer';
import { UserState } from 'modules/User/state';
import hubReducer from 'modules/Hub/reducer';
import { HubState } from 'modules/Hub/state';
import { SensorState } from 'modules/Sensor/state';
import sensorReducer from 'modules/Sensor/reducer';
import plantReducer from 'modules/Plants/reducer';
import { PlantState } from 'modules/Plants/state';
import familyReducer from 'modules/Family/reducer';
import { FamilyState } from 'modules/Family/state';
import plantGroupsReducer from 'modules/PlantGroups/reducer';
import { PlantGroupsState } from 'modules/PlantGroups/state';
import mediaReducer from 'modules/Media/reducer';
import { MediaState } from 'modules/Media/state';
import plantUserReducer from 'modules/PlantUser/reducer';
import { PlantUserState } from 'modules/PlantUser/state';
import dictionaryReducer from 'modules/Dictionary/reducer';
import { DictionaryState } from 'modules/Dictionary/state';
import gardenReducer from 'modules/Garden/reducer';
import { GardenState } from 'modules/Garden/state';
import newsReducer from 'modules/News/reducer';
import { NewsState } from 'modules/News/state';
import missingFamilyReducer from 'modules/MissingFamily/reducer';
import { MissingFamilyState } from 'modules/MissingFamily/state';
import { PolicyState } from 'modules/Policy/state';
import policyReducer from 'modules/Policy/reducer';
import plantDiseasesReducer from 'modules/PlantDiseases/reducer';
import { PlantDiseasesState } from 'modules/PlantDiseases/state';

export type RootState = {
  auth: AuthState;
  layout: LayoutState;
  router: RouterState;
  user: UserState;
  hub: HubState;
  sensor: SensorState;
  plant: PlantState;
  family: FamilyState;
  plantGroups: PlantGroupsState;
  media: MediaState;
  plantUser: PlantUserState;
  garden: GardenState;
  news: NewsState;
  dictionary: DictionaryState;
  missingFamily: MissingFamilyState;
  policy: PolicyState;
  plantDiseases: PlantDiseasesState;
};

const createRootReducer = (history: History): Reducer => {
  const reducers = {
    auth: authReducer,
    layout: layoutReducer,
    router: connectRouter(history),
    user: userReducer(),
    hub: hubReducer(),
    sensor: sensorReducer(),
    plant: plantReducer(),
    family: familyReducer(),
    plantGroups: plantGroupsReducer(),
    media: mediaReducer(),
    plantUser: plantUserReducer(),
    dictionary: dictionaryReducer(),
    garden: gardenReducer(),
    news: newsReducer(),
    missingFamily: missingFamilyReducer(),
    policy: policyReducer(),
    plantDiseases: plantDiseasesReducer()
  };

  return combineReducers<RootState>(reducers);
};

export default createRootReducer;
