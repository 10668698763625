import { ROUTE_PLANTS_DETAILS_REDIRECT_TEMPLATE } from 'modules/Plants/routes';
import {
  NAV_DESCRIPTIONS_AND_SECTIONS,
  NAV_THRESHOLDS,
  NAV_PLANT_MEASUREMENTS_DESC
} from 'modules/Plants/view/Details/index';

export const createHistoryPath = (
  type: string,
  id: string,
  history: any,
  option?: string
) => {
  const pathname = ROUTE_PLANTS_DETAILS_REDIRECT_TEMPLATE + id;
  if (type === 'description' && option !== 'base') {
    return history.push({
      pathname,
      state: { navElementChange: NAV_DESCRIPTIONS_AND_SECTIONS }
    });
  }
  if (type === 'thresholds') {
    return history.push({
      pathname,
      state: { navElementChange: NAV_THRESHOLDS }
    });
  }
  if (type === 'measurement_desc') {
    return history.push({
      pathname,
      state: { navElementChange: NAV_PLANT_MEASUREMENTS_DESC }
    });
  }
  return history.push(pathname);
};
