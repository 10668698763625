import { RootState } from 'app/reducer';
import { setLocationSearchAction, manageThrow } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import {
  FETCH_HEALTH_CHECK_HELP_LIST,
  fetchHealthCheckHelpListAction,
  HEALTH_CHECK_HELP_LIST_SET_RESPONDED,
  healthCheckHelpListFetchedAction,
  HealthCheckHelpListSetRespondedAction,
  SET_HEALTH_CHECK_HELP_LIST_PARAMS
} from 'modules/PlantDiseases/action/list';
import {
  fetchHealthCheckHelpList,
  FetchHealthCheckResponse,
  updateHealthCheckHelp
} from 'modules/PlantDiseases/repository';
import { AxiosResponse } from 'axios';

export function* setHealthCheckHelpListParamsSaga(): SagaIterator {
  yield put(fetchHealthCheckHelpListAction());
}

export function* fetchHealthCheckHelpListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.plantDiseases.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const mappedFilter = {
      ...filter
    };

    if (filter.responded) {
      mappedFilter.responded = '0';
    }

    const response: AxiosResponse<FetchHealthCheckResponse> = yield call(
      fetchHealthCheckHelpList,
      {
        ...sort,
        pagination,
        search: mappedFilter
      }
    );

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      healthCheckHelpListFetchedAction({
        content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        healthCheckHelpListFetchedAction({ content: [], totalPages: 1 })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export function* updateHealthCheckHelpSaga({
  payload
}: HealthCheckHelpListSetRespondedAction): SagaIterator {
  try {
    const { healthCheckHelpId, responded } = payload;

    yield call(updateHealthCheckHelp, healthCheckHelpId, { responded });
  } catch (error) {
    console.log(error);
  }
}

export default function* plantDiseasesListSaga(): SagaIterator {
  yield takeLatest(
    SET_HEALTH_CHECK_HELP_LIST_PARAMS,
    setHealthCheckHelpListParamsSaga
  );
  yield takeLatest(FETCH_HEALTH_CHECK_HELP_LIST, fetchHealthCheckHelpListSaga);
  yield takeLatest(
    HEALTH_CHECK_HELP_LIST_SET_RESPONDED,
    updateHealthCheckHelpSaga
  );
}
