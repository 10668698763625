import Media, { acceptableMediaTypesValues } from 'modules/Media/model/Media';

export const primaryInfoArr = (data: Media, advancementLevelArr?: string[]) => [
  {
    title: 'Title',
    description: data?.title || '',
    mapped: 'title',
    type: 'text'
  },
  {
    title: 'Advancement level',
    description: data?.advancement_level || '',
    mapped: 'advancement_level',
    type: 'select',
    selectOptions: advancementLevelArr ?? []
  },
  {
    title: 'Type',
    description: data?.type || '',
    mapped: 'type',
    type: 'select',
    selectOptions: acceptableMediaTypesValues
  },
  {
    title: 'Link',
    description: data?.url || '',
    mapped: 'url',
    type: 'outerLink',
    to: data?.url
  },
  {
    title: 'Updated at',
    description: data?.updated_at || '',
    mapped: '',
    type: 'text'
  },
  {
    title: 'Created at',
    description: data?.created_at || '',
    mapped: '',
    type: 'text'
  }
];
