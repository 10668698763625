import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import Hub from 'modules/Hub/model/Hub';

export interface HubListState extends SearchingProps {
  hubs: Hub[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initHubListState = (): HubListState => ({
  hubs: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
