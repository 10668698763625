import { BreadcrumbItem } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_FAQ_LIST } from 'modules/Faq/routes';

export const breadcrumbRouteFaqList = (): BreadcrumbItem[] => [
  {
    title: 'FAQ',
    link: getPathUrl(ROUTE_FAQ_LIST)
  }
];

export const breadcrumbRouteFaqUpdate = (): BreadcrumbItem[] => [
  ...breadcrumbRouteFaqList(),
  {
    title: 'Update'
  }
];
