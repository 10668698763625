import { PlantUserDetailsEntity } from 'modules/PlantUser/model/PlantUserDetails';
import { Action } from 'redux';

export const SET_USER_PLANT_DETAILS_PARAMS = 'PLANT/USER/DETAILS/PARAMS/SET';
export const FETCH_USER_PLANT_DETAILS = 'PLANT/USER/DETAILS/FETCH';
export const PLANT_USER_DETAILS_FETCHED = 'PLANT/USER/DETAILS/FETCHED';

export type PlantUserDetailsAction =
  | SetPlantUserDetailsParamsAction
  | FetchPlantUserDetailsAction
  | PlantUserDetailsFetchedAction;

export type SetPlantUserDetailsParamsAction = {
  type: typeof SET_USER_PLANT_DETAILS_PARAMS;
  payload: number;
};

export const setPlantUserDetailsParamsAction = (
  payload: number
): SetPlantUserDetailsParamsAction => {
  return { type: SET_USER_PLANT_DETAILS_PARAMS, payload };
};

export type FetchPlantUserDetailsAction = Action<
  typeof FETCH_USER_PLANT_DETAILS
>;

export const fetchPlantUserDetailsAction = (): FetchPlantUserDetailsAction => ({
  type: FETCH_USER_PLANT_DETAILS
});

export type PlantUserDetailsFetchedPayload = {
  plantUserDetails: PlantUserDetailsEntity;
};

export interface PlantUserDetailsFetchedAction
  extends Action<typeof PLANT_USER_DETAILS_FETCHED> {
  payload: PlantUserDetailsFetchedPayload;
}

export const plantUserDetailsFetchedAction = (
  payload: PlantUserDetailsFetchedPayload
): PlantUserDetailsFetchedAction => ({
  type: PLANT_USER_DETAILS_FETCHED,
  payload
});
