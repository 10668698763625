import { SagaIterator } from 'redux-saga';
import {
  clearToken,
  loadToken,
  saveToken,
  setAuthHeader
} from 'modules/Auth/service';
import {
  AUTHENTICATE,
  authenticatedAction,
  LOGIN,
  LoginAction,
  LOGOUT,
  logoutAction,
  setAuthStateAction,
  setAuthUserAction
} from 'modules/Auth/action';
import ApiError from 'modules/Shared/exception/ApiError';
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SettableAuthState } from 'modules/Auth/state';
import { createRequestTokenPayload } from 'modules/Auth/helper';
import { ROUTE_DASHBOARD } from 'modules/Layout/routes';
import { ROUTE_LOGIN } from 'modules/Auth/routes';
import {
  authenticate,
  AuthenticateResponse,
  requestToken,
  TokenResponse
} from '../repository';
import NetworkError from '../../Shared/exception/NetworkError';
import Unauthorized from '../exception/Unauthorized';

export function* authenticateSaga(): SagaIterator {
  const token = loadToken();

  if (token) {
    setAuthHeader(token.access_token);
  }

  try {
    const {
      data: { profileData }
    }: AuthenticateResponse = yield call(authenticate);

    yield put(setAuthUserAction(profileData));

    yield put(authenticatedAction());
  } catch (error) {
    if (
      !(error instanceof ApiError) ||
      error instanceof NetworkError ||
      error instanceof Unauthorized
    ) {
      yield put(logoutAction());
    }
  }
}

export function* loginSaga({ payload }: LoginAction): SagaIterator {
  let state: SettableAuthState = { busy: false };

  try {
    const { data: token }: TokenResponse = yield call(
      requestToken,
      createRequestTokenPayload(payload)
    );

    saveToken(token);

    yield put(push(ROUTE_DASHBOARD));
  } catch (error) {
    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }
  }

  yield put(setAuthStateAction(state));
}

export function* logoutSaga(): SagaIterator {
  clearToken();

  yield put(push(ROUTE_LOGIN));
}

export default function* authSaga(): SagaIterator {
  yield takeLatest(AUTHENTICATE, authenticateSaga);
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(LOGOUT, logoutSaga);
}
