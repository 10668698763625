import { IOnSubmitAction } from 'modules/Layout/view/DetailsForm';
import {
  updateFamilyDetails,
  updateFamilyDetailsThresholds
} from 'modules/Family/repository';
import { FamilyDetailsState } from 'modules/Family/state/details';
import { TresholdsEntity } from 'modules/Shared/configs/tresholdsEntity/type';

interface IHandleChangeRequest {
  data: IOnSubmitAction;
  id: number;
  details?: FamilyDetailsState;
}

export const handleChangeRequest = (
  object: IHandleChangeRequest,
  cancelToken?: any
) => {
  const { data, id, details } = object;

  const { editType, ...rest } = data;

  switch (editType) {
    case 'main': {
      if (!details?.familyDetails?.hasOwnProperty('genus')) {
        throw new Error('internal error');
      }

      return updateFamilyDetails(
        id,
        {
          genus: data?.genus as string
        },
        cancelToken
      );
    }

    case 'thresholds':
      let restConvert = {};
      if (typeof rest === 'object') {
        Object.entries(rest).forEach((threshold) => {
          if (typeof threshold[1] === 'string') {
            restConvert = {
              ...restConvert,
              [threshold[0]]: parseFloat(threshold[1]) ?? null
            };
          }
        });
      }
      return updateFamilyDetailsThresholds(
        id,
        {
          ...restConvert
        } as TresholdsEntity,
        cancelToken
      );
    default:
      return null;
  }
};

export const handleChangeErrorResponse = (
  serverStatus?: number,
  message?: string
) => {
  const validMessage = typeof message === 'string';
  if (validMessage) {
    return message;
  }
  if (serverStatus === 422) return 'Invalid values.';
  if (serverStatus === 500)
    return 'Server unavailable. Please try again later.';
  if (serverStatus === 404) return 'Family not found.';
  return 'Something went wrong. Contact with administrator to resolve the problem.';
};
