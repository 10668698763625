import {
  initSensorSoftwareListState,
  SensorSoftwareListState
} from 'modules/Sensor/state/sensorSoftwareList';
import {
  FETCH_SENSOR_SOFTWARE_LIST,
  SENSOR_SOFTWARE_LIST_FETCHED,
  SensorSoftwareListAction,
  SET_SENSOR_SOFTWARE_LIST_PARAMS
} from 'modules/Sensor/action/list';
import { createSensorSoftware } from 'modules/Sensor/model/SensorSoftware';

const initState = initSensorSoftwareListState();

const reducer = (
  state: SensorSoftwareListState = initState,
  action: SensorSoftwareListAction
): SensorSoftwareListState => {
  switch (action.type) {
    case SET_SENSOR_SOFTWARE_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_SENSOR_SOFTWARE_LIST:
      return { ...state, fetching: true };

    case SENSOR_SOFTWARE_LIST_FETCHED: {
      const { sensorSoftwares = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        sensorSoftwares: sensorSoftwares.map(createSensorSoftware),
        totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
