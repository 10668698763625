import { BreadcrumbItem } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';
import {
  ROUTE_HEALTH_CHECK_HELP,
  ROUTE_PLANT_DISEASES
} from 'modules/PlantDiseases/routes';
import HealthCheckHelp from 'modules/PlantDiseases/model/HealthCheckHelp';

export const breadcrumbRoutePlantDiseases = (): BreadcrumbItem[] => [
  {
    title: 'Plant diseases',
    link: getPathUrl(ROUTE_PLANT_DISEASES)
  }
];

export const breadcrumbRouteEditPlantDisease = (): BreadcrumbItem[] => [
  ...breadcrumbRoutePlantDiseases(),
  {
    title: 'Plant disease - edit'
  }
];

export const breadcrumbRouteHealthCheckHelpList = (): BreadcrumbItem[] => [
  {
    title: 'Health check help',
    link: getPathUrl(ROUTE_HEALTH_CHECK_HELP)
  }
];

export const breadcrumbRouteHealthCheckHelp = (
  healthCheckHelp?: HealthCheckHelp
): BreadcrumbItem[] => [
  ...breadcrumbRouteHealthCheckHelpList(),
  {
    title: healthCheckHelp ? healthCheckHelp.email : 'Details'
  }
];
