import { TresholdsEntity } from 'modules/Shared/configs/tresholdsEntity/type';

export type FamilyDetailsEntity = {
  id?: number;
  genus?: string;
  eligibility?: 'Yes' | 'No' | 'Illegal';
  created_at?: string;
  updated_at?: string;
  is_deleted?: boolean;
  thresholds?: TresholdsEntity;
};

export default class FamilyDetails {
  readonly id?: number;
  readonly genus?: string;
  readonly eligibility?: 'Yes' | 'No' | 'Illegal';
  readonly created_at?: string;
  readonly updated_at?: string;
  is_deleted?: boolean;
  readonly thresholds?: TresholdsEntity;

  constructor(entity: FamilyDetailsEntity) {
    this.id = entity.id;
    this.genus = entity.genus;
    this.eligibility = entity.eligibility;
    this.is_deleted = entity.is_deleted;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.thresholds = entity.thresholds;
  }
}

export const createFamilyDetails = (
  entity: FamilyDetailsEntity
): FamilyDetails => new FamilyDetails(entity);
