import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteMedia } from 'modules/Media/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import MediaTable from 'modules/Media/container/Table';
import MediaFilter from 'modules/Media/container/Filter';
import MediaPagination from 'modules/Media/container/Pagination';
import MediaPerPage from 'modules/Media/container/PerPage';
import Media from 'modules/Media/model/Media';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setMediaListParamsAction,
  SetMediaListParamsAction
} from 'modules/Media/action/List';
import { RootState } from 'app/reducer';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (payload: PartialSearchingProps) => SetMediaListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  specialOnClick?: (id: number) => void;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setMediaListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.media.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class MediaListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      Media.getFilterableAttributes(),
      Media.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Media',
      breadcrumb: breadcrumbRouteMedia()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { fetching, type } = this.props;
    const { filter } = this.searchProps;

    if (type) return null;
    return (
      <div className="row media-view">
        <div className="col-12">
          <Listing
            table={<MediaTable />}
            filter={<MediaFilter values={filter} />}
            pagination={<MediaPagination />}
            perPage={<MediaPerPage />}
            addBtn={{ text: 'Add media', url: '/media/add' }}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(MediaListView);
