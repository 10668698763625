import { NewsEntity } from 'modules/News/model/News';
import { Action } from 'redux';

export const SET_NEWS_DETAILS_PARAMS = 'NEWS/DETAILS/PARAMS/SET';
export const FETCH_NEWS_DETAILS = 'NEWS/DETAILS/FETCH';
export const NEWS_DETAILS_FETCHED = 'NEWS/DETAILS/FETCHED';
export const NEWS_DETAILS_DELETED = 'NEWS/DETAILS/DELETED';

export type NewsDetailsAction =
  | SetNewsDetailsParamsAction
  | FetchNewsDetailsAction
  | NewsDetailsFetchedAction
  | NewsDetailsDeletedAction;

export type SetNewsDetailsParamsAction = {
  type: typeof SET_NEWS_DETAILS_PARAMS;
  payload: number;
};

export const setNewsDetailsParamsAction = (
  payload: number
): SetNewsDetailsParamsAction => {
  return { type: SET_NEWS_DETAILS_PARAMS, payload };
};

export type FetchNewsDetailsAction = Action<typeof FETCH_NEWS_DETAILS>;

export const fetchNewsDetailsAction = (): FetchNewsDetailsAction => ({
  type: FETCH_NEWS_DETAILS
});

export type NewsDetailsFetchedPayload = {
  newsDetails: NewsEntity;
};

export interface NewsDetailsFetchedAction
  extends Action<typeof NEWS_DETAILS_FETCHED> {
  payload: NewsDetailsFetchedPayload;
}

export const newsDetailsFetchedAction = (
  payload: NewsDetailsFetchedPayload
): NewsDetailsFetchedAction => ({
  type: NEWS_DETAILS_FETCHED,
  payload
});

export type NewsDetailsDeletedAction = {
  type: typeof NEWS_DETAILS_DELETED;
};

export const newsDetailsDeletedAction = (): NewsDetailsDeletedAction => ({
  type: NEWS_DETAILS_DELETED
});
