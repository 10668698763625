import React, { FormEvent } from 'react';
import PlantAutocomplete from 'modules/Plants/container/Autocomplete/Plant';
import BaseModal from 'modules/Layout/component/Modal';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { updatePlantType } from 'modules/PlantUser/repository';
import Loader from 'modules/Layout/component/Loader';

export interface State {
  fromPlantId: number;
  toPlantId: number;
  isOpen: boolean;
  confirm: boolean;
  fetching: boolean;
  affectedPlants: number | null;
  affectedUsers: number | null;
}

export class ChangeUserPlantTypeModal extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      fromPlantId: null,
      toPlantId: null,
      isOpen: false,
      confirm: false,
      fetching: false,
      affectedPlants: null,
      affectedUsers: null
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  openModal(): void {
    this.setState({
      isOpen: true,
      fromPlantId: null,
      toPlantId: null,
      confirm: false,
      fetching: false,
      affectedPlants: null,
      affectedUsers: null
    });
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  renderTitle(): React.ReactNode {
    return 'Change plant type of user plants';
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const { confirm, fromPlantId, toPlantId } = this.state;

    try {
      this.setState({
        fetching: true
      });

      const response = await updatePlantType(fromPlantId, toPlantId, confirm);
      if (confirm) {
        this.toggle();
      } else {
        const {
          data: { affected_plants }
        } = response;
        const userIds = affected_plants.map((plant) => plant.user_id);
        const uniqueUsers = userIds.filter(
          (userId, index) => userIds.indexOf(userId) === index
        );
        this.setState({
          confirm: true,
          affectedPlants: affected_plants.length,
          affectedUsers: uniqueUsers.length
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        fetching: false
      });
    }
  }

  renderBody(): React.ReactNode {
    const {
      fromPlantId,
      toPlantId,
      fetching,
      confirm,
      affectedPlants,
      affectedUsers
    } = this.state;

    return (
      <Form id="change-plant-type" onSubmit={this.onSubmit}>
        {fetching && <Loader />}
        <FormGroup>
          <Label>From plant:</Label>
          <PlantAutocomplete
            onChange={(plant) =>
              this.setState({
                fromPlantId: plant.id,
                confirm: false,
                affectedPlants: null,
                affectedUsers: null
              })
            }
            required
            plantId={fromPlantId}
          />
        </FormGroup>
        <FormGroup>
          <Label>To plant:</Label>
          <PlantAutocomplete
            onChange={(plant) =>
              this.setState({
                toPlantId: plant.id,
                confirm: false,
                affectedPlants: null,
                affectedUsers: null
              })
            }
            required
            plantId={toPlantId}
          />
        </FormGroup>
        {confirm && (
          <FormGroup className="text-danger font-24">
            <div>{`Affected users: ${affectedUsers}`}</div>
            <div>{`Affected plants: ${affectedPlants}`}</div>
          </FormGroup>
        )}
      </Form>
    );
  }

  renderFooter(): React.ReactNode {
    const { confirm } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button color="secondary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button color="success" type="submit" form="change-plant-type">
          {confirm ? 'Update plant types' : 'Check affected plants'}
        </Button>
      </div>
    );
  }

  render(): React.ReactNode {
    const { isOpen } = this.state;

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default ChangeUserPlantTypeModal;
