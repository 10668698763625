import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import TermsOfServiceTable from 'modules/Policy/container/Table';
import TermsOfServiceFilter from 'modules/Policy/container/Filter';
import TermsOfServicePagination from 'modules/Policy/container/Pagination';
import TermsOfServicePerPage from 'modules/Policy/container/PerPage';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { RootState } from 'app/reducer';
import {
  setTOSListParamsAction,
  SetTOSListParamsAction
} from 'modules/Policy/action/list';
import TermsOfService from 'modules/Policy/model/TermsOfService';
import { breadcrumbRouteTermsOfServices } from 'modules/Policy/breadcrumbs';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_ADD_TOS } from 'modules/Policy/routes';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (payload: PartialSearchingProps) => SetTOSListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setTOSListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.policy.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class TermsOfServiceListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      TermsOfService.getFilterableAttributes(),
      TermsOfService.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Terms of services',
      breadcrumb: breadcrumbRouteTermsOfServices()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { filter } = this.searchProps;
    const { fetching, type } = this.props;

    if (type) return null;

    return (
      <div className="row terms-of-services-view">
        <div className="col-12">
          <Listing
            addBtn={{
              text: 'Add terms of service',
              url: getPathUrl(ROUTE_ADD_TOS)
            }}
            table={<TermsOfServiceTable />}
            filter={<TermsOfServiceFilter values={filter} />}
            pagination={<TermsOfServicePagination />}
            perPage={<TermsOfServicePerPage />}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(TermsOfServiceListView);
