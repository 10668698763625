import GardenDetails, {
  gardenStatusIsDeleted
} from 'modules/Garden/model/GardenDetails';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';

export const primaryInfoArr = (data: GardenDetails = null) => [
  {
    title: 'Name:',
    description: data?.name || '',
    mapped: 'name'
  },
  {
    title: 'Owner email:',
    description: data?.email,
    type: 'link',
    to: data?.user_id
      ? getPathUrl(ROUTE_USER_DETAILS, { id: data.user_id })
      : null
  },
  {
    title: 'Owner username:',
    description: data?.username,
    type: 'link',
    to: data?.user_id
      ? getPathUrl(ROUTE_USER_DETAILS, { id: data.user_id })
      : null
  },
  {
    title: 'Is deleted:',
    description: gardenStatusIsDeleted(data?.status),
    mapped: ''
  },
  {
    title: 'Updated at:',
    description: data?.updated_at || '',
    mapped: ''
  },
  {
    title: 'Created at:',
    description: data?.created_at || '',
    mapped: ''
  }
];
