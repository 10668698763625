import React, { ReactNode } from 'react';
import PlantGroupDetailsModel, {
  createPlantGroupDetails
} from 'modules/PlantGroups/model/PlantGroupDetails';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import {
  fetchPlantGroupDetails,
  updatePlantGroupMeasurements
} from 'modules/PlantGroups/repository';
import { breadcrumbRouteEditPlantGroup } from 'modules/PlantGroups/breadcrumbs';
import DisplayEditFormMeasurement from 'modules/Plants/view/DetailsForm/components/DisplayEditFormMeasurement';
import { RouteComponentProps } from 'react-router-dom';
import { ROUTE_PLANT_GROUPS_DETAILS } from 'modules/PlantGroups/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { MeasurementsStatusDescEntity } from 'modules/Shared/configs/measurementStatusDescEntity/type';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props
  extends StateProps,
    DispatchProps,
    RouteComponentProps<any> {
  id: string;
  editType: string;
  lang: string;
  option?: string;
}

export interface State {
  plantGroup: PlantGroupDetailsModel;
  updating: boolean;
  error: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class PlantGroupEditView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      plantGroup: null,
      updating: false,
      error: ''
    };

    this.onSubmitMeasurements = this.onSubmitMeasurements.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow, editType, lang, option } = this.props;

    const mappedType =
      editType === 'measurement_desc' ? 'descriptions' : 'basic';

    const title = (plantName?: string) =>
      `${plantName || 'Plant group'} - edit ${option.replace(
        '_',
        ' '
      )} ${mappedType}(${lang})`;

    managePage({
      title: title(null),
      breadcrumb: breadcrumbRouteEditPlantGroup(null, option, mappedType, lang),
      type: 'loading'
    });

    try {
      const { data } = await fetchPlantGroupDetails(id);

      this.setState({
        plantGroup: createPlantGroupDetails(data.plantGroup)
      });

      managePage({
        title: title(data.plantGroup.name),
        breadcrumb: breadcrumbRouteEditPlantGroup(
          data.plantGroup,
          option,
          mappedType,
          lang
        ),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  async onSubmitMeasurements(data: {
    stateValue: MeasurementsStatusDescEntity[];
  }): Promise<void> {
    const { id } = this.props;
    const { plantGroup } = this.state;

    this.setState(
      {
        updating: true
      },
      async () => {
        try {
          let updateData: MeasurementsStatusDescEntity[] = [
            ...data.stateValue,
            ...plantGroup.measurements_statuses_desc
          ];

          updateData = updateData.filter(
            (measurement, index) =>
              updateData.findIndex(
                (obj) =>
                  obj.lang === measurement.lang &&
                  obj.measurement_status === measurement.measurement_status &&
                  obj.measurement === measurement.measurement
              ) === index
          );

          await updatePlantGroupMeasurements(id, updateData);

          this.redirect();
          // eslint-disable-next-line no-empty
        } catch (_error) {}
      }
    );
  }

  redirect(): void {
    const { id, history, option } = this.props;

    return history.push({
      pathname: getPathUrl(ROUTE_PLANT_GROUPS_DETAILS, { id }),
      state: {
        nav: 'Measurements desc',
        option
      }
    });
  }

  render(): ReactNode {
    const { type, editType, option, lang } = this.props;
    const { plantGroup, updating, error } = this.state;

    if (type || !plantGroup) {
      return null;
    }

    if (editType === 'measurement_desc') {
      return (
        <DisplayEditFormMeasurement
          data={plantGroup.measurements_statuses_desc}
          onSubmitAction={this.onSubmitMeasurements}
          onCancelAction={this.redirect}
          error={error}
          deleteError={() => this.setState({ error: '' })}
          type={editType}
          lang={lang}
          option={option}
          loading={updating}
        />
      );
    }

    this.redirect();

    return null;
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantGroupEditView);
