import { GardenDetailsEntity } from 'modules/Garden/model/GardenDetails';
import { Action } from 'redux';

export const SET_GARDEN_DETAILS_PARAMS = 'GARDEN/DETAILS/PARAMS/SET';
export const FETCH_GARDEN_DETAILS = 'GARDEN/DETAILS/FETCH';
export const GARDEN_DETAILS_FETCHED = 'GARDEN/DETAILS/FETCHED';

export type GardenDetailsAction =
  | SetGardenDetailsParamsAction
  | FetchGardenDetailsAction
  | GardenDetailsFetchedAction;

export type SetGardenDetailsParamsAction = {
  type: typeof SET_GARDEN_DETAILS_PARAMS;
  payload: number;
};

export const setGardenDetailsParamsAction = (
  payload: number
): SetGardenDetailsParamsAction => {
  return { type: SET_GARDEN_DETAILS_PARAMS, payload };
};

export type FetchGardenDetailsAction = Action<typeof FETCH_GARDEN_DETAILS>;

export const fetchGardenDetailsAction = (): FetchGardenDetailsAction => ({
  type: FETCH_GARDEN_DETAILS
});

export type GardenDetailsFetchedPayload = {
  gardenDetails: GardenDetailsEntity;
};

export interface GardenDetailsFetchedAction
  extends Action<typeof GARDEN_DETAILS_FETCHED> {
  payload: GardenDetailsFetchedPayload;
}

export const gardenDetailsFetchedAction = (
  payload: GardenDetailsFetchedPayload
): GardenDetailsFetchedAction => ({
  type: GARDEN_DETAILS_FETCHED,
  payload
});
