import {
  FETCH_PLANT_USER_LIST,
  SET_PLANT_USER_LIST_PARAMS,
  PLANT_USER_LIST_FETCHED,
  PlantUserListAction
} from 'modules/PlantUser/action/list';
import { createPlantUser } from 'modules/PlantUser/model/PlantUser';
import {
  initPlantUserListState,
  PlantUserListState
} from 'modules/PlantUser/state/list';

const initState = initPlantUserListState();

const reducer = (
  state: PlantUserListState = initState,
  action: PlantUserListAction
): PlantUserListState => {
  switch (action.type) {
    case SET_PLANT_USER_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_PLANT_USER_LIST:
      return { ...state, fetching: true };

    case PLANT_USER_LIST_FETCHED: {
      const { plantUser = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        plantUser: plantUser.map(createPlantUser),
        totalPages: action.payload.totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
