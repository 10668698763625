export type HelpFindPlantEntity = {
  id?: number;
  user_id?: number;
  email?: string;
  image?: string;
  description?: string;
  responded?: boolean;
  created_at?: string;
  plantnet_response?: Record<any, any>;
  updated_at?: string;
};

export default class HelpFindPlant {
  readonly id?: number;
  readonly user_id?: number;
  readonly email?: string;
  readonly image?: string;
  readonly description?: string;
  readonly responded?: boolean;
  readonly plantnet_response?: Record<any, any>;
  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(entity: HelpFindPlantEntity) {
    this.id = entity.id;
    this.user_id = entity.user_id;
    this.email = entity.email;
    this.image = entity.image;
    this.description = entity.description;
    this.responded = entity.responded;
    this.plantnet_response = entity.plantnet_response;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  static getFilterableAttributes(): string[] {
    return ['email', 'with_deleted', 'responded'];
  }

  static getSortableAttributes(): string[] {
    return ['id', 'created_at'];
  }
}

export const createHelpFindPlant = (
  entity: HelpFindPlantEntity
): HelpFindPlant => new HelpFindPlant(entity);
