import React, { createRef, ReactNode, RefObject } from 'react';
import PlantDetails, { LangsEntity } from 'modules/Plants/model/PlantDetails';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { Dispatch } from 'redux';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import {
  fetchPlantDetails,
  updatePlantDescriptions
} from 'modules/Plants/repository';
import getPlantSectionName from 'modules/Plants/Helper/section';
import Wysiwyg from 'modules/Layout/component/Wysiwyg';
import { Button, Input } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANTS_DETAILS } from 'modules/Plants/routes';
import { breadcrumbRoutePlantEditDescriptions } from 'modules/Plants/breadcrumbs';
import Loader from 'modules/Layout/component/Loader';
import { PlantSection } from 'modules/Plants/type';
import { mapEditorStateToHtml } from 'modules/Layout/helper/wysiwyg';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import { supportedSections } from 'modules/Shared/configs/supportedSections/config';
import { NAV_DESCRIPTIONS_AND_SECTIONS } from '../../Details';
import NavTab from '../../../../Layout/component/NavTab';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps, RouteComponentProps {
  id: string;
}

export interface State {
  plant: PlantDetails;
  updating: boolean;
  activeLanguage: string;
  langs: {
    [lang: string]: LangsEntity;
  };
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class PlantEditSectionView extends React.Component<Props, State> {
  readonly wysiwygRefs: {
    [lang: string]: {
      [section: string]: RefObject<Wysiwyg>;
    };
  };

  constructor(props: Props) {
    super(props);

    this.wysiwygRefs = {};

    supportedLangs.forEach((lang) => {
      supportedSections.forEach((section) => {
        if (!this.wysiwygRefs[lang]) {
          this.wysiwygRefs[lang] = {};
        }
        this.wysiwygRefs[lang][section] = createRef();
      });
    });

    this.state = {
      plant: null,
      updating: false,
      activeLanguage: 'en',
      langs: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.renderSection = this.renderSection.bind(this);
    this.renderDescription = this.renderDescription.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { id, managePage, manageThrow } = this.props;

    const state = {
      navElementChange: NAV_DESCRIPTIONS_AND_SECTIONS
    };

    managePage({
      title: `Plant - descriptions and sections`,
      breadcrumb: breadcrumbRoutePlantEditDescriptions(null, state),
      type: 'loading'
    });

    try {
      const response = await fetchPlantDetails(parseInt(id, 10));

      const { plant, ...rest } = response.data;
      const newPlant = { ...plant, ...rest };

      this.setState({
        plant: newPlant,
        langs: newPlant.langs
      });

      managePage({
        title: `${newPlant.scientific_name} - edit descriptions`,
        breadcrumb: breadcrumbRoutePlantEditDescriptions(newPlant, state),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  async onSubmit(): Promise<void> {
    const { id, history } = this.props;
    const { plant, langs } = this.state;

    this.setState({
      updating: true
    });

    const sections: PlantSection[] = [];

    Object.keys(plant.sections).forEach((currentLang) => {
      Object.keys(plant.sections[currentLang]).forEach((currentSection) => {
        const obj = plant.sections[currentLang][currentSection];
        obj.content = mapEditorStateToHtml(
          this.wysiwygRefs[currentLang][currentSection].current.state
            .editorState
        );

        sections.push(obj);
      });
    });

    const newLangs: {
      [lang: string]: LangsEntity;
    } = {};

    Object.keys(langs).forEach((lang) => {
      newLangs[lang] = {
        location: langs[lang].location,
        life_span: langs[lang].life_span,
        description: langs[lang].description,
        plant_experience: langs[lang].plant_experience
      };
    });

    try {
      await updatePlantDescriptions(id, sections, newLangs);

      history.push({
        pathname: getPathUrl(ROUTE_PLANTS_DETAILS, { id }),
        state: {
          navElementChange: NAV_DESCRIPTIONS_AND_SECTIONS
        }
      });
    } catch (error) {
      console.log(error);

      this.setState({
        updating: false
      });
    }
  }

  renderSection(lang: string, section: string): ReactNode {
    const { plant } = this.state;
    const initialValue = plant.sections[lang][section]?.content;

    return (
      <div key={`${lang}-${section}`} className="mt-2">
        <div className="font-weight-bold">{getPlantSectionName(section)}</div>
        <Wysiwyg
          ref={this.wysiwygRefs[lang][section]}
          initialValue={initialValue}
        />
      </div>
    );
  }

  renderDescription(
    lang: string,
    field: 'description' | 'location' | 'plant_experience' | 'life_span'
  ): ReactNode {
    const { langs } = this.state;
    const value = langs[lang][field];

    return (
      <div className="mt-2">
        <Input
          style={{ minHeight: '200px' }}
          type="textarea"
          value={value || ''}
          aria-colspan={5}
          onChange={({ currentTarget: { value: newValue } }) =>
            this.setState({
              langs: {
                ...langs,
                [lang]: {
                  ...langs[lang],
                  [field]: newValue
                }
              }
            })
          }
        />
      </div>
    );
  }

  render(): ReactNode {
    const { id, type } = this.props;
    const { plant, updating, activeLanguage } = this.state;

    if (type || !plant) {
      return null;
    }

    const tabs = supportedLangs.map((lang) => ({
      slug: lang
    }));

    return (
      <div className="d-flex justify-content-center">
        <div className="col-md-6 card">
          <div className="card-body">
            {updating && <Loader />}
            <div className="font-weight-bold font-20 mb-2">
              {plant.scientific_name}
            </div>
            <NavTab
              tabs={tabs}
              activeTab={activeLanguage}
              onChange={(lang) => this.setState({ activeLanguage: lang })}
            />
            <div className="mt-2 font-20">Description</div>
            {this.renderDescription(activeLanguage, 'description')}
            <div className="mt-2 font-20">Location</div>
            {this.renderDescription(activeLanguage, 'location')}
            <div className="mt-2 font-20">Plant experience</div>
            {this.renderDescription(activeLanguage, 'plant_experience')}
            <div className="mt-2 font-20">Life span</div>
            {this.renderDescription(activeLanguage, 'life_span')}
            <div className="mt-2 font-20">Sections</div>
            {supportedLangs.map((lang) => {
              return (
                <div
                  key={lang}
                  className={activeLanguage !== lang ? 'd-none' : ''}
                >
                  {supportedSections.map((section) =>
                    this.renderSection(lang, section)
                  )}
                </div>
              );
            })}
            <div className="mt-3 d-flex justify-content-between">
              <Link
                to={{
                  pathname: getPathUrl(ROUTE_PLANTS_DETAILS, { id }),
                  state: {
                    navElementChange: NAV_DESCRIPTIONS_AND_SECTIONS
                  }
                }}
                className="btn btn-primary"
                color="primary"
              >
                Cancel
              </Link>
              <Button
                disabled={updating}
                onClick={this.onSubmit}
                color="secondary"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(
    mapState,
    mapDispatch
  )(PlantEditSectionView)
);
