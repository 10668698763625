import React from 'react';
import { IState } from 'modules/Layout/view/DetailsForm/Reducer';
import DisplayBaseInput from 'modules/Layout/view/DetailsForm/components/DisplayBaseInput';

interface IProps {
  arrayOfFieldDesc: {
    title: string;
    mapped: string;
    shortTitle?: string;
    type?: string;
    selectOptions?: string[];
  }[];
  field: IState;
  updateField: (value: string | number, mapped: string | number) => void;
  loading?: boolean;
}

const DisplayBaseContent: React.FC<IProps> = (props) => {
  const { arrayOfFieldDesc, field, updateField, loading } = props;

  const displayArr = () => {
    return arrayOfFieldDesc.map((desc, index) => {
      if (!desc.mapped) {
        return null;
      }

      return (
        <div className="col-12" key={index}>
          <DisplayBaseInput
            objInfo={desc}
            value={(field?.[desc.mapped]?.value as string | number) || ''}
            mapped={desc.mapped}
            onChange={updateField}
            type={desc.type}
            displayType="list"
            loading={loading}
          />
        </div>
      );
    });
  };

  return <>{displayArr()}</>;
};

export default DisplayBaseContent;
