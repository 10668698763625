import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import InformationList, {
  InformationField
} from 'modules/Layout/component/InformationList';
import Loader from 'modules/Layout/component/Loader';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import HubSoftware from 'modules/Hub/model/HubSoftware';

export interface Props {
  onSubmit: (hubSoftware: HubSoftware) => void;
  fetching: boolean;
  errorMessage?: Message | null;
}

export interface State {
  isOpen: boolean;
  hubSoftware?: HubSoftware;
}

class HubSoftwarePublishModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      hubSoftware: null
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  openModal(hubSoftware: HubSoftware): void {
    const state: State = {
      isOpen: true,
      hubSoftware
    };

    this.setState(state);
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  renderTitle = (): React.ReactNode => {
    const { hubSoftware } = this.state;

    if (hubSoftware) {
      return `Publish hub software - ${hubSoftware.version}`;
    }

    return null;
  };

  renderBody(): React.ReactNode {
    const { fetching, errorMessage } = this.props;
    const { hubSoftware } = this.state;

    if (hubSoftware) {
      const fields: InformationField[] = [
        {
          label: 'Version:',
          value: hubSoftware.version
        },
        {
          label: 'Software:',
          value: hubSoftware.software
        },
        {
          label: 'Required:',
          value: hubSoftware.getRequired()
        },
        {
          label: 'Published:',
          value: hubSoftware.getPublished()
        },
        {
          label: 'Created at:',
          value: hubSoftware.created_at
        },
        {
          label: 'Updated at:',
          value: hubSoftware.updated_at
        }
      ];

      return (
        <>
          {errorMessage && <Alert message={errorMessage} />}
          {fetching && <Loader />}
          <InformationList fields={fields} />
        </>
      );
    }

    return null;
  }

  renderFooter(): React.ReactNode {
    const { onSubmit } = this.props;
    const { hubSoftware } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button color="secondary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button color="success" onClick={() => onSubmit(hubSoftware)}>
          Publish
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen } = this.state;

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default HubSoftwarePublishModal;
