import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  helpFindPlantListRespondedAction,
  HelpFindPlantListRespondedAction,
  HelpFindPlantListRespondedPayload,
  setHelpFindPlantListParamsAction,
  SetHelpFindPlantListParamsAction
} from 'modules/Plants/action/list';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HelpFindPlant from 'modules/Plants/model/HelpFindPlant';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_HELP_FIND_PLANT_DETAILS } from 'modules/Plants/routes';
import ActionShow from 'modules/Layout/component/Action/Show';
import ActionUpdate from '../../../../Layout/component/Action/Update';
import ActionDelete from '../../../../Layout/component/Action/Delete';

export interface StateProps {
  helpFindPlant: HelpFindPlant[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetHelpFindPlantListParamsAction;
  setResponded: (
    payload: HelpFindPlantListRespondedPayload
  ) => HelpFindPlantListRespondedAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { helpFindPlant, sort } = state.plant.list;

  return { helpFindPlant, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setHelpFindPlantListParamsAction(payload)),
  setResponded: (payload) => dispatch(helpFindPlantListRespondedAction(payload))
});

export class HelpFindPlantTable extends React.Component<Props> {
  readonly cols: TableCol<HelpFindPlant>[];

  constructor(props: Props) {
    super(props);

    const { setResponded } = props;

    this.cols = [
      {
        property: 'email',
        label: 'User email',
        className: 'col-8',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_HELP_FIND_PLANT_DETAILS, { id: row.id })
      },
      {
        property: 'created_at',
        label: 'Sent at',
        className: 'col-4',
        sortable: true
      },
      {
        property: 'responded',
        label: 'Responded',
        value: (row) => (
          <div className="actions-wrapper">
            <div>{row.responded ? 'Yes' : 'No'}</div>
            {row.responded ? (
              <ActionDelete
                label={<i className="fas font-22 fa-times" />}
                title="Set to not responded"
                onClick={() =>
                  setResponded({ plantId: row.id, responded: false })
                }
              />
            ) : (
              <ActionUpdate
                label={<i className="fas font-20 fa-check" />}
                title="Set to responded"
                onClick={() =>
                  setResponded({ plantId: row.id, responded: true })
                }
              />
            )}
          </div>
        )
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show help find plant"
              to={getPathUrl(ROUTE_HELP_FIND_PLANT_DETAILS, { id: row.id })}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { helpFindPlant, sort, setParams } = this.props;

    return (
      <Table
        cols={this.cols}
        rows={helpFindPlant}
        sort={sort}
        onSort={(params) => setParams({ sort: params })}
      />
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HelpFindPlantTable);
