import React from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { ROUTE_USER_FEEDBACK_DETAILS } from 'modules/User/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { FeedbackEntity } from 'modules/User/model/Feedback';

export interface Props {
  feedback: FeedbackEntity[];
}

const UserDetailsFeedback = (props: Props): JSX.Element => {
  const { feedback } = props;

  const cols: TableCol<FeedbackEntity>[] = [
    {
      label: 'Description',
      property: 'description',
      columnRedirectTo: (row) =>
        getPathUrl(ROUTE_USER_FEEDBACK_DETAILS, { id: row.id })
    },
    {
      label: 'Sent at',
      property: 'created_at',
      columnRedirectTo: (row) =>
        getPathUrl(ROUTE_USER_FEEDBACK_DETAILS, { id: row.id })
    }
  ];

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Feedback">
        <Table cols={cols} rows={feedback} />
      </DisplayBaseTabContent>
    </div>
  );
};

export default UserDetailsFeedback;
