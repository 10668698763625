import React, { FormEvent, ReactNode } from 'react';
import { Button, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import Datetime from 'modules/Layout/component/Input/Datetime';
import { getError, hasError } from 'modules/Shared/helper/validation';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import Wysiwyg from 'modules/Layout/component/Wysiwyg';
import { ROUTE_PP_DETAILS, ROUTE_PP_LIST } from 'modules/Policy/routes';
import { EditorState } from 'react-draft-wysiwyg';
import { ValidationPayload } from 'modules/Shared/type';
import moment from 'moment';
import {
  mapEditorStateToHtml,
  mapHtmlToEditorState
} from 'modules/Layout/helper/wysiwyg';
import { AxiosResponse } from 'axios';
import {
  createPP,
  CreatePPResponse,
  updatePP
} from 'modules/Policy/repository';
import {
  createPrivacyPolicyToastError,
  createPrivacyPolicyToastSuccess,
  updatePrivacyPolicyToastError,
  updatePrivacyPolicyToastSuccess
} from 'modules/Policy/toasts';
import { getPathUrl } from 'modules/Shared/helper/api';
import Validation from 'modules/Shared/exception/Validation';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import Loader from 'modules/Layout/component/Loader';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { connect } from 'react-redux';
import PrivacyPolicy, {
  PrivacyPolicyLangs
} from 'modules/Policy/model/PrivacyPolicy';
import { policyWysiwygToolbar } from 'modules/Layout/component/Wysiwyg/config';
import WysiwygPreview from 'modules/Layout/component/WysiwygPreview';
import WysiwygExplanation from 'modules/Layout/component/Wysiwyg/Explanation';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends RouteComponentProps, DispatchProps {
  pp?: PrivacyPolicy;
}

export interface State {
  activation_date: string;
  langs: {
    [lang: string]: {
      body: EditorState;
    };
  };
  langTab: boolean[];
  fetching: boolean;
  errors?: ValidationPayload;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

class PrivacyPolicyForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { pp } = props;

    const langs: {
      [lang: string]: {
        body: EditorState;
      };
    } = {};

    supportedLangs.forEach((lang) => {
      langs[lang] = {
        body:
          pp && pp.langs && pp.langs[lang]
            ? mapHtmlToEditorState(pp.langs[lang].body)
            : null
      };
    });

    this.state = {
      activation_date: pp?.activation_date || '',
      langs,
      langTab: supportedLangs.map((_, index) => index === 0),
      fetching: false,
      errors: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onBodyChange = this.onBodyChange.bind(this);
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { history, addToast, pp } = this.props;
    const { activation_date, langs } = this.state;

    this.setState({
      fetching: true,
      errors: null
    });

    const mappedLangs: PrivacyPolicyLangs = {};

    supportedLangs.forEach((lang) => {
      const { body } = langs[lang];

      if (body) {
        mappedLangs[lang] = {
          body: mapEditorStateToHtml(body)
        };
      }
    });

    const payload = {
      activation_date,
      langs: mappedLangs
    };

    try {
      let id;

      if (pp) {
        id = pp.id;

        await updatePP(id, payload);
      } else {
        const {
          data: { pp: createdPP }
        }: AxiosResponse<CreatePPResponse> = await createPP(payload);

        id = createdPP.id;
      }

      if (pp) {
        addToast(updatePrivacyPolicyToastSuccess());
      } else {
        addToast(createPrivacyPolicyToastSuccess());
      }

      history.push(getPathUrl(ROUTE_PP_DETAILS, { id }));
    } catch (error) {
      if (pp) {
        addToast(updatePrivacyPolicyToastError());
      } else {
        addToast(createPrivacyPolicyToastError());
      }

      if (error instanceof Validation) {
        this.setState({
          fetching: false,
          errors: error.getPayload()
        });
      } else {
        this.setState({
          fetching: false
        });
      }
    }
  }

  onBodyChange(body: EditorState, lang: string): void {
    const { langs } = this.state;

    langs[lang].body = body;

    this.setState({
      langs
    });
  }

  render(): ReactNode {
    const { pp, history } = this.props;
    const { fetching, errors, langTab, activation_date, langs } = this.state;

    const activeIndex = langTab.findIndex((bool) => bool);
    const activeLang = supportedLangs[activeIndex];

    const now = moment().utc().format('YYYY-MM-DD HH:mm');

    return (
      <>
        <Form onSubmit={this.onSubmit}>
          {fetching && <Loader />}
          {errors && (
            <ShowMessage
              message={errors.message.value}
              bsColor={errors.message.variant}
            />
          )}
          <FormGroup>
            <Label className="w-100">
              Activation date UTC*:
              <Datetime
                name="activation_date"
                onChange={(newDate) =>
                  this.setState({
                    activation_date: newDate
                  })
                }
                value={activation_date}
                min={now}
                required
                invalid={errors && hasError(errors.errors, 'activation_date')}
              />
              {errors && hasError(errors.errors, 'activation_date') && (
                <FormFeedback className="d-block">
                  {getError(errors.errors, 'activation_date')}
                </FormFeedback>
              )}
            </Label>
          </FormGroup>
          <FormGroup>
            <DisplayNav
              booleanArr={langTab}
              setBooleanArr={(newTabs: boolean[]) =>
                this.setState({ langTab: newTabs })
              }
              valueArr={supportedLangs}
            />
            {supportedLangs.map((lang) => {
              const initialValue = langs[lang].body;

              return (
                <div
                  key={lang}
                  className={activeLang === lang ? 'row' : 'd-none'}
                >
                  <div className="col-12 col-md-6">
                    <Wysiwyg
                      initialEditorState={initialValue}
                      onChange={(body) => this.onBodyChange(body, lang)}
                      toolbar={policyWysiwygToolbar}
                      toolbarCustomButtons={[
                        <WysiwygExplanation key="explanation" />
                      ]}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <WysiwygPreview editorState={langs[activeLang].body} />
                  </div>
                </div>
              );
            })}
          </FormGroup>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="button"
              onClick={() => {
                if (pp) {
                  history.push(getPathUrl(ROUTE_PP_DETAILS, { id: pp.id }));
                } else {
                  history.push(ROUTE_PP_LIST);
                }
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button type="submit" color="success">
              {pp ? 'Update' : 'Create'}
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(PrivacyPolicyForm)
);
