import { BreadcrumbItem } from 'modules/Layout/type';
import PlantUserDetails from 'modules/PlantUser/model/PlantUserDetails';

export const breadcrumbRoutePlantUser = (): BreadcrumbItem[] => [
  {
    title: 'User plants'
  }
];

export const breadcrumbRouteDetailsPlantUser = (
  userPlant: PlantUserDetails
): BreadcrumbItem[] => [
  {
    title: 'User plants',
    link: '/plant-user/list'
  },
  {
    title: userPlant ? userPlant.nickname : 'Details'
  }
];
