import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import { ColorVariants, Message, ValidationErrors } from 'modules/Shared/type';
import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import Logo from 'modules/Layout/component/Logo';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ROUTE_LOGIN } from 'modules/Auth/routes';
import {
  Link,
  Redirect,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';
import { resetPassword } from 'modules/Auth/repository';
import {
  forgotPasswordToastError,
  resetPasswordToastSuccess
} from 'modules/Auth/toasts';
import ApiError from 'modules/Shared/exception/ApiError';
import { setAuthStateAction, SetAuthStateAction } from 'modules/Auth/action';
import { SettableAuthState } from 'modules/Auth/state';
import { getPathUrl } from 'modules/Shared/helper/api';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
  setAuthState: (payload: SettableAuthState) => SetAuthStateAction;
}

export interface Props extends DispatchProps, RouteComponentProps {
  token: string;
}

export interface State {
  busy: boolean;
  message?: Message;
  errors: ValidationErrors;
  password: string;
  repeat_password: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload)),
  setAuthState: (payload) => dispatch(setAuthStateAction(payload))
});

export class ResetPasswordView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      busy: false,
      message: null,
      errors: null,
      password: '',
      repeat_password: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(): void {
    const { managePage } = this.props;

    managePage({ title: 'Change password' });
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const { addToast, token, setAuthState, history } = this.props;
    const { repeat_password, password } = this.state;

    if (repeat_password !== password) {
      this.setState({
        errors: {
          repeat_password: 'Repeated password is not the same.'
        }
      });

      return;
    }

    this.setState({
      busy: true,
      message: null,
      errors: null
    });

    try {
      await resetPassword({
        repeat_password,
        password,
        token
      });

      addToast(resetPasswordToastSuccess());

      setAuthState({
        message: {
          value: 'Password changed.',
          variant: ColorVariants.Success
        }
      });

      history.push(getPathUrl(ROUTE_LOGIN));
    } catch (error) {
      addToast(forgotPasswordToastError());

      if (error instanceof ApiError) {
        this.setState({
          busy: false,
          message: error.getMessage(),
          errors: error.getErrors()
        });
      }
    }
  }

  render(): JSX.Element {
    const { token } = this.props;
    const { busy, message, errors, password, repeat_password } = this.state;

    if (!token) {
      return <Redirect to={ROUTE_LOGIN} />;
    }

    return (
      <PublicWrapper>
        <div className="forgot-password-view pt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11 col-lg-9 col-xl-7">
                <div className="card position-relative px-md-5 py-2 bg-secondary">
                  <div className="card-body">
                    <Logo />
                    {busy && <Loader />}
                    <div className="px-lg-4 mt-4">
                      <h4 className="text-primary">Change password</h4>
                      {message && <Alert message={message} />}
                      <Form className="mt-3" onSubmit={this.onSubmit}>
                        <FormGroup>
                          <Input
                            type="password"
                            onChange={(event) =>
                              this.setState({
                                password: event.currentTarget.value
                              })
                            }
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*)(\-+=]).{5,20}$"
                            title="Minimum length 5, maximum length 20, must contain at least one number and one uppercase and lowercase letter, and one special character !@#$%^&*)(\-+="
                            placeholder="Password*"
                            value={password}
                            invalid={hasError(errors, 'password')}
                            required
                          />
                          {hasError(errors, 'password') && (
                            <FormFeedback>
                              {getError(errors, 'password')}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Input
                            type="password"
                            onChange={(event) =>
                              this.setState({
                                repeat_password: event.currentTarget.value
                              })
                            }
                            placeholder="Repeat password*"
                            value={repeat_password}
                            invalid={hasError(errors, 'repeat_password')}
                            required
                          />
                          {hasError(errors, 'repeat_password') && (
                            <FormFeedback>
                              {getError(errors, 'repeat_password')}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <div className="d-flex justify-content-between mt-3">
                          <Link className="btn btn-primary" to={ROUTE_LOGIN}>
                            Cancel
                          </Link>
                          <Button color="success" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PublicWrapper>
    );
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(ResetPasswordView)
);
