import React, { Component, ReactNode } from 'react';
import BaseModal from 'modules/Layout/component/Modal';
import InformationList from 'modules/Layout/component/InformationList';
import { Button } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import TermsOfService from 'modules/Policy/model/TermsOfService';
import {
  deleteTermsOfServiceToastError,
  deleteTermsOfServiceToastSuccess
} from 'modules/Policy/toasts';
import { deleteTOS } from 'modules/Policy/repository';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export interface State {
  termsOfService: TermsOfService;
  open: boolean;
  deleting: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class DeleteTermsOfServiceModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      termsOfService: null,
      open: false,
      deleting: false
    };

    this.renderBody = this.renderBody.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.delete = this.delete.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  open(termsOfService: TermsOfService): void {
    this.setState({
      open: true,
      termsOfService,
      deleting: false
    });
  }

  close(): void {
    this.setState({
      open: false
    });
  }

  renderBody(): ReactNode {
    const { termsOfService, deleting } = this.state;

    const fields = [
      {
        label: 'Activation date:',
        value: termsOfService.activation_date
      }
    ];

    return (
      <div>
        {deleting && <Loader />}
        <InformationList fields={fields} />
      </div>
    );
  }

  renderFooter(): ReactNode {
    const { deleting } = this.state;

    return (
      <div className="d-flex w-100 justify-content-between">
        <Button
          color="primary"
          disabled={deleting}
          onClick={this.close}
          className="mr-2"
        >
          Cancel
        </Button>
        <Button color="danger" disabled={deleting} onClick={this.delete}>
          Delete
        </Button>
      </div>
    );
  }

  async delete(): Promise<void> {
    const { onSuccess, onError, addToast } = this.props;
    const { termsOfService } = this.state;

    try {
      this.setState({
        deleting: true
      });

      await deleteTOS(termsOfService.id);

      this.close();

      if (onSuccess) {
        onSuccess();
      }

      addToast(deleteTermsOfServiceToastSuccess());
    } catch (error) {
      this.close();

      if (onError) {
        onError();
      }

      addToast(deleteTermsOfServiceToastError());
    }
  }

  render(): ReactNode {
    const { open, termsOfService } = this.state;

    if (!termsOfService) {
      return null;
    }

    return (
      <BaseModal
        isOpen={open}
        body={this.renderBody()}
        footer={this.renderFooter()}
        title="Do you want to delete terms of service?"
        toggle={this.close}
        onClosed={this.close}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(DeleteTermsOfServiceModal);
