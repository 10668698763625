import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import HealthCheckHelpPagination from 'modules/PlantDiseases/container/Pagination/Help';
import HealthCheckHelpPerPage from 'modules/PlantDiseases/container/PerPage/Help';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { RootState } from 'app/reducer';
import HealthCheckHelp from 'modules/PlantDiseases/model/HealthCheckHelp';
import {
  setHealthCheckHelpListParamsAction,
  SetHealthCheckHelpListParamsAction
} from 'modules/PlantDiseases/action/list';
import { breadcrumbRouteHealthCheckHelpList } from 'modules/PlantDiseases/breadcrumbs';
import HealthCheckHelpTable from 'modules/PlantDiseases/container/Table/Help';
import HealthCheckHelpFilter from 'modules/PlantDiseases/container/Filter/Help';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (
    payload: PartialSearchingProps
  ) => SetHealthCheckHelpListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setHealthCheckHelpListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.plantDiseases.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class HealthCheckHelpListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      HealthCheckHelp.getFilterableAttributes(),
      HealthCheckHelp.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Health check help',
      breadcrumb: breadcrumbRouteHealthCheckHelpList()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { filter } = this.searchProps;
    const { fetching, type } = this.props;

    if (type) return null;

    return (
      <div className="row health-help-view">
        <div className="col-12">
          <Listing
            table={<HealthCheckHelpTable />}
            filter={<HealthCheckHelpFilter values={filter} />}
            pagination={<HealthCheckHelpPagination />}
            perPage={<HealthCheckHelpPerPage />}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HealthCheckHelpListView);
