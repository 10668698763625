import { RootState } from 'app/reducer';
import {
  SET_NEWS_DETAILS_PARAMS,
  FETCH_NEWS_DETAILS,
  fetchNewsDetailsAction,
  newsDetailsFetchedAction
} from 'modules/News/action/Details';
import {
  fetchNewsDetails,
  FetchNewsDetailsResponse
} from 'modules/News/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';
import { AxiosResponse } from 'axios';

export function* setNewsDetailsParamsSaga(): SagaIterator {
  yield put(fetchNewsDetailsAction());
}

export function* fetchNewsDetailsSaga(): SagaIterator {
  try {
    const { newsId } = yield select((state: RootState) => state.news.details);

    const response: AxiosResponse<FetchNewsDetailsResponse> = yield call(
      fetchNewsDetails,
      newsId
    );

    const { news } = response.data;

    yield put(
      newsDetailsFetchedAction({
        newsDetails: news
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(newsDetailsFetchedAction({ newsDetails: null }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantDetailsSaga(): SagaIterator {
  yield takeLatest(SET_NEWS_DETAILS_PARAMS, setNewsDetailsParamsSaga);
  yield takeLatest(FETCH_NEWS_DETAILS, fetchNewsDetailsSaga);
}
