import { ROUTE_PLANT_GROUPS_DETAILS_REDIRECT_TEMPLATE } from 'modules/PlantGroups/routes';
import { ROUTE_FAMILY_DETAILS_REDIRECT_TEMPLATE } from 'modules/Family/routes';
import PlantDetails, { LangsEntity } from 'modules/Plants/model/PlantDetails';
import {
  supportedLangsEnum,
  supportedLangs
} from 'modules/Shared/configs/supportedLang/config';

const createGroupLink = (id: number) =>
  id ? ROUTE_PLANT_GROUPS_DETAILS_REDIRECT_TEMPLATE + id : null;
const createGroupFamilyLink = (id: number) =>
  id ? ROUTE_FAMILY_DETAILS_REDIRECT_TEMPLATE + id : null;

export const primaryInfoArr = (data: PlantDetails = null) => [
  {
    title: 'Scientific name',
    description: data?.scientific_name || '',
    mapped: 'scientific_name',
    type: 'text'
  },
  {
    title: 'Is deleted',
    description: data?.is_deleted ? 'Yes' : 'No',
    mapped: '',
    type: 'text'
  },
  {
    title: 'Is confirmed',
    description: data?.confirmed ? 'Yes' : 'No',
    mapped: '',
    type: 'text'
  },
  {
    title: 'Eligibility',
    description: data?.eligibility || '',
    mapped: '',
    type: 'text'
  },
  {
    title: 'Genus',
    description: data?.genus || '',
    mapped: '',
    type: 'link',
    to: createGroupFamilyLink(data?.family_id)
  },
  {
    title: 'Category #1',
    description: data?.plant_groups?.[0]?.name || '',
    mapped: '',
    type: 'link',
    to: createGroupLink(data?.plant_groups?.[0]?.id)
  },
  {
    title: 'Category #2',
    description: data?.plant_groups?.[1]?.name || '',
    mapped: '',
    type: 'link',
    to: createGroupLink(data?.plant_groups?.[1]?.id)
  },
  {
    title: 'Created at',
    description: data?.created_at || '-',
    mapped: '',
    type: 'text'
  }
];

export const descriptionArrBase = (langs: LangsEntity = null) => [
  {
    title: 'Name',
    description: langs?.name || '',
    mapped: 'name',
    type: 'textarea'
  }
];

export const descriptionArrDesc = (langs: LangsEntity = null) => [
  {
    title: 'Description',
    description: langs?.description || '',
    mapped: 'description',
    type: 'textarea'
  }
];

export const descriptionArrOther = (langs: LangsEntity = null) => [
  {
    title: 'Location',
    description: langs?.location || '',
    mapped: 'location',
    type: 'textarea'
  },
  {
    title: 'Plants experience',
    description: langs?.plant_experience || '',
    mapped: 'plant_experience',
    type: 'textarea'
  },
  {
    title: 'Life span',
    description: langs?.life_span || '',
    mapped: 'life_span',
    type: 'textarea'
  }
];

export const getDescriptionLang = (
  data: PlantDetails,
  lang: string,
  type: string
) => {
  if (!supportedLangs.includes(lang)) return [];

  const langsObj = data?.langs?.[lang as supportedLangsEnum] || null;

  if (type === 'base') return descriptionArrBase(langsObj);
  if (type === 'desc') return descriptionArrDesc(langsObj);
  if (type === 'other') return descriptionArrOther(langsObj);
  return [];
};
