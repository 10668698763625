import { MissingFamilyDetailsEntity } from 'modules/MissingFamily/model/MissingFamilyDetails';
import { Action } from 'redux';

export const SET_MISSING_FAMILY_DETAILS_PARAMS =
  'MISSING_FAMILY/DETAILS/PARAMS/SET';
export const FETCH_MISSING_FAMILY_DETAILS = 'MISSING_FAMILY/DETAILS/FETCH';
export const MISSING_FAMILY_DETAILS_FETCHED = 'MISSING_FAMILY/DETAILS/FETCHED';

export type MissingFamilyDetailsAction =
  | SetMissingFamilyDetailsParamsAction
  | FetchMissingFamilyDetailsAction
  | MissingFamilyDetailsFetchedAction;

export type SetMissingFamilyDetailsParamsAction = {
  type: typeof SET_MISSING_FAMILY_DETAILS_PARAMS;
  payload: number;
};

export const setMissingFamilyDetailsParamsAction = (
  payload: number
): SetMissingFamilyDetailsParamsAction => {
  return { type: SET_MISSING_FAMILY_DETAILS_PARAMS, payload };
};

export type FetchMissingFamilyDetailsAction = Action<
  typeof FETCH_MISSING_FAMILY_DETAILS
>;

export const fetchMissingFamilyDetailsAction =
  (): FetchMissingFamilyDetailsAction => ({
    type: FETCH_MISSING_FAMILY_DETAILS
  });

export type MissingFamilyDetailsFetchedPayload = {
  missingFamilyDetails: MissingFamilyDetailsEntity;
};

export interface MissingFamilyDetailsFetchedAction
  extends Action<typeof MISSING_FAMILY_DETAILS_FETCHED> {
  payload: MissingFamilyDetailsFetchedPayload;
}

export const missingFamilyDetailsFetchedAction = (
  payload: MissingFamilyDetailsFetchedPayload
): MissingFamilyDetailsFetchedAction => ({
  type: MISSING_FAMILY_DETAILS_FETCHED,
  payload
});
