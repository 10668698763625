import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import SensorSoftware from 'modules/Sensor/model/SensorSoftware';

export interface SensorSoftwareListState extends SearchingProps {
  sensorSoftwares: SensorSoftware[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initSensorSoftwareListState = (): SensorSoftwareListState => ({
  sensorSoftwares: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
