import listReducer from 'modules/News/reducer/list';
import detailsReducer from 'modules/News/reducer/details';
import { combineReducers, Reducer } from 'redux';
import { NewsState } from 'modules/News/state';

const reducer = (): Reducer<NewsState> => {
  const reducers = {
    list: listReducer,
    details: detailsReducer
  };

  return combineReducers<NewsState>(reducers);
};

export default reducer;
