import News from 'modules/News/model/News';

export interface NewsDetailsState {
  newsDetails: News;
  fetching: boolean;
  newsId: number;
}

export const initNewsDetailsState = (): NewsDetailsState => ({
  newsDetails: null,
  fetching: false,
  newsId: null
});
