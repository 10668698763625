import SensorSoftwareListView from 'modules/Sensor/view/sensorSoftwareList';
import SensorDetailsView from 'modules/Sensor/view/sensorDetails';
import SensorListView from 'modules/Sensor/view/sensorList';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';

export const ROUTE_SENSOR_SOFTWARES = '/sensor-softwares';
export const ROUTE_SENSOR_LIST = '/sensor/list';
export const ROUTE_SENSOR_DETAILS = '/sensor/details/:id';
export const ROUTE_SENSOR_DETAILS_REDIRECT_TEMPLATE = '/sensor/details/';

export const createSensorRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="sensor-softwares"
      path={ROUTE_SENSOR_SOFTWARES}
      exact
      component={SensorSoftwareListView}
    />,
    <Route
      key="sensor-list"
      path={ROUTE_SENSOR_LIST}
      exact
      component={SensorListView}
    />,
    <Route
      key="sensor-details"
      path={ROUTE_SENSOR_DETAILS}
      exact
      render={(props) => (
        <SensorDetailsView {...props} key={props.match.params.id} />
      )}
      //component={SensorDetailsView}
    />
  ]
});
