import React from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { HubEntity } from 'modules/Hub/model/Hub';
import { ROUTE_HUB_DETAILS_REDIRECT } from 'modules/Hub/routes';

export interface Props {
  hubs: HubEntity[];
}

const UserDetailsHubs = (props: Props): JSX.Element => {
  const { hubs } = props;

  const cols: TableCol<HubEntity>[] = [
    {
      label: 'Mac',
      property: 'hub_id'
    },
    {
      label: 'Name',
      property: 'name'
    },
    {
      label: 'Version',
      property: 'version'
    },
    {
      label: 'IoT thing name',
      property: 'iot_thing_name'
    }
  ];

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Hubs">
        <Table
          cols={cols}
          rows={hubs}
          onRowClickPath={ROUTE_HUB_DETAILS_REDIRECT}
        />
      </DisplayBaseTabContent>
    </div>
  );
};

export default UserDetailsHubs;
