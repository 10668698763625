import {
  initSensorDetailsState,
  SensorDetailsState
} from 'modules/Sensor/state/sensorDetails';
import {
  FETCH_SENSOR_DETAILS,
  SENSOR_DETAILS_FETCHED,
  SensorDetailsAction,
  SET_SENSOR_DETAILS_PARAMS
} from 'modules/Sensor/action/details';
import { createSensorDetails } from 'modules/Sensor/model/SensorDetails';

const initState = initSensorDetailsState();

const reducer = (
  state: SensorDetailsState = initState,
  action: SensorDetailsAction
): SensorDetailsState => {
  switch (action.type) {
    case SET_SENSOR_DETAILS_PARAMS:
      return { ...state, sensorId: action.payload };

    case FETCH_SENSOR_DETAILS:
      return { ...state, fetching: true };

    case SENSOR_DETAILS_FETCHED: {
      const { sensorDetails = null } = action.payload;

      return {
        ...state,
        fetching: false,
        sensorDetails: createSensorDetails(sensorDetails)
      };
    }

    default:
      return state;
  }
};

export default reducer;
