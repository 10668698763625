import listReducer from 'modules/Dictionary/reducer/list';
import { combineReducers, Reducer } from 'redux';
import { DictionaryState } from 'modules/Dictionary/state';

const reducer = (): Reducer<DictionaryState> => {
  const reducers = {
    list: listReducer
  };

  return combineReducers<DictionaryState>(reducers);
};

export default reducer;
