import React, { ReactNode, useRef } from 'react';
import { ListCol } from 'modules/Layout/component/ReorderList';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { Button, Input } from 'reactstrap';
import debounce from 'lodash/debounce';
import ActionDelete from 'modules/Layout/component/Action/Delete';

export interface Props<V = never> {
  id: string;
  accept: string;
  item: V;
  index: number;
  length: number;
  cols: ListCol<V>[];
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  className?: string;
  colKey?: (column: ListCol<V>, index: number) => React.ReactText;
  onDelete?: () => void;
}

export interface DragItem {
  index: number;
  id: string;
  type: string;
}

function ReorderListItem<V = never>(props: Props<V>): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  const { id, accept, item, cols, colKey, index, moveCard, length, onDelete } =
    props;

  const [{ handlerId }, drop] = useDrop({
    accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(hoverItem: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = hoverItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      // eslint-disable-next-line no-param-reassign
      hoverItem.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: accept,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  const getColKey = (col: ListCol<V>, colIndex: number): React.ReactText => {
    if (colKey) {
      return colKey(col, colIndex);
    }

    return col.property;
  };

  const renderCol = (col: ListCol<V>, colIndex: number): ReactNode => {
    const { className, property, value } = col;

    let render;

    if (value) {
      render = value(item, colIndex);
    } else {
      render = item[property];
    }

    return (
      <div
        key={getColKey(col, colIndex)}
        className={`ordered-list-col ${className || 'col p-1'}`}
      >
        {render}
      </div>
    );
  };

  const debounceOnChange = debounce(moveCard, 1000);

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div className="d-flex my-2">
      <div
        style={{ maxWidth: '75px' }}
        className="d-flex justify-content-center align-items-center"
      >
        <Input
          key={index + 1}
          onChange={(event) => {
            let value = Number(event.currentTarget.value) - 1;

            if (value < 0) {
              value = 0;
            } else if (value > length - 1) {
              value = length;
            }

            debounceOnChange(index, value);
          }}
          type="number"
          defaultValue={index + 1}
        />
      </div>
      <div
        ref={ref}
        style={{
          opacity,
          cursor: 'move',
          minHeight: '75px'
        }}
        data-handler-id={handlerId}
        className="order-list-row border d-flex overflow-hidden w-100 position-relative"
      >
        {cols.map(renderCol)}
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Drag me
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center ml-2">
        <Button
          disabled={index === 0}
          title="Move to start"
          color="link"
          className="p-2"
          onClick={() => moveCard(index, 0)}
        >
          <i className="fas fa-angle-double-up" />
        </Button>
        <Button
          disabled={length === index + 1}
          title="Move to end"
          color="link"
          className="p-2"
          onClick={() => moveCard(index, length - 1)}
        >
          <i className="fas fa-angle-double-down" />
        </Button>
      </div>
      {Boolean(onDelete) && (
        <ActionDelete className="ml-2" onClick={onDelete} />
      )}
    </div>
  );
}

export default ReorderListItem;
