import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { PlantDiseaseEntity } from 'modules/PlantDiseases/model/PlantDisease';
import { HealthCheckHelpEntity } from 'modules/PlantDiseases/model/HealthCheckHelp';

export const API_PLANT_DISEASES = '/admin-plant-diseases/list';
export const API_PLANT_DISEASE = '/admin-plant-diseases/:id';
export const API_HEALTH_CHECK_HELP_LIST = '/admin-plant-diseases/help/list';
export const API_HEALTH_CHECK_HELP = '/admin-plant-diseases/help/:id';

export interface FetchPlantDiseasesResponse {
  content: PlantDiseaseEntity[];
}

export const fetchPlantDiseases = (
  params: SearchParams = {}
): AxiosPromise<FetchPlantDiseasesResponse> =>
  axios.post(getAPIUrl(API_PLANT_DISEASES), params);

export interface FetchPlantDiseaseResponse {
  data: PlantDiseaseEntity;
}

export const fetchPlantDisease = (
  id: number | string,
  params: SearchParams = {}
): AxiosPromise<FetchPlantDiseaseResponse> =>
  axios.get(getAPIUrl(API_PLANT_DISEASE, { id }), params);

export const updatePlantDisease = (
  id: number | string,
  lang: {
    [lang: string]: { about: string; treatment: string; prevention: string };
  }
): AxiosPromise => axios.put(getAPIUrl(API_PLANT_DISEASE, { id }), { lang });

export interface FetchHealthCheckResponse {
  content: HealthCheckHelpEntity[];
  totalCount: any;
}

export const fetchHealthCheckHelpList = (
  params: SearchParams = {}
): AxiosPromise<FetchHealthCheckResponse> =>
  axios.post(getAPIUrl(API_HEALTH_CHECK_HELP_LIST, null), params);

export interface FetchHealthCheckDetailsResponse {
  healthCheckHelp: HealthCheckHelpEntity;
}

export const fetchHealthCheckHelp = (
  id: number | string
): AxiosPromise<FetchHealthCheckDetailsResponse> =>
  axios.get(getAPIUrl(API_HEALTH_CHECK_HELP, { id }));

export const updateHealthCheckHelp = (
  id: number,
  data: { responded: boolean }
): AxiosPromise => axios.put(getAPIUrl(API_HEALTH_CHECK_HELP, { id }), data);
