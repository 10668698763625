import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { primaryInfoArr } from 'modules/Media/view/Details/helper/DetailsConfig';
import DiplayPrimaryInfo from 'modules/Layout/view/Details/Helper/DisplayPrimaryInfo';
import DisplayPrimaryInfoWrapper from 'modules/Layout/view/Details/Helper/DisplayPrimaryInfoWrapper';
import {
  ROUTE_MEDIA,
  ROUTE_MEDIA_DETAILS_FORM_REDIRECT_TEMPLATE
} from 'modules/Media/routes';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { mediaDetailsDeletedAction } from 'modules/Media/action/Details';
import Media from 'modules/Media/model/Media';
import DeleteMediaModal from 'modules/Media/component/Modal/Delete';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import AttachAsKnowHowMediaModal from 'modules/Media/component/Modal/AttachAsKnowHow';
import { TableCol } from 'modules/Layout/component/Table';
import { MediaKnowHowEntity } from 'modules/Media/model/MediaKnowHow';
import AttachedToAsKnowHow from 'modules/Media/component/AttachedToAsKnowHow';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANTS_DETAILS } from 'modules/Plants/routes';
import { ROUTE_PLANT_GROUPS_DETAILS } from 'modules/PlantGroups/routes';
import { ROUTE_FAMILY_DETAILS } from 'modules/Family/routes';

interface IProps {
  data: Media;
  onSuccessMediaAttach?: () => void;
}

const Details: React.FC<IProps> = ({ data, onSuccessMediaAttach }: IProps) => {
  const [deleteMessage, setDeleteMessage] = useState({ text: '', color: '' });

  const history = useHistory();
  const dispatch = useDispatch();

  const navElements = [
    'Media',
    'Attached to plants as know-hows',
    'Attached to plant groups as know-hows',
    'Attached to families as know-hows'
  ];
  const [nav, setNav] = useState(navElements.map((_, index) => index === 0));

  const deleteModalRef = useRef(null);
  const attachAsKnowHowModalRef = useRef(null);

  const onDeleteSuccess = () => {
    dispatch(mediaDetailsDeletedAction());
    history.push(ROUTE_MEDIA);
  };

  const attachBtn = {
    callback: () => attachAsKnowHowModalRef?.current?.openModal(data),
    text: 'Attach as know-how',
    bsColor: 'success'
  };

  const arrayOfBtnObjects = [
    {
      callback: () => deleteModalRef?.current?.openModal(data),
      text: 'Delete',
      bsColor: 'danger'
    },
    {
      callback: () =>
        history.push(ROUTE_MEDIA_DETAILS_FORM_REDIRECT_TEMPLATE + data?.id),
      text: 'Edit'
    },
    attachBtn
  ];

  const plantsCols: TableCol<MediaKnowHowEntity>[] = [
    {
      label: 'Image',
      property: 'plant_thumb_path',
      className: 'col-2',
      type: 'img',
      value: (row) => row.plant_thumb_path || '',
      columnRedirectTo: (row) =>
        getPathUrl(ROUTE_PLANTS_DETAILS, { id: row.plant_id })
    },
    {
      label: 'Plant scientific name',
      property: 'plant_scientific_name',
      className: 'col-10',
      columnRedirectTo: (row) =>
        getPathUrl(ROUTE_PLANTS_DETAILS, { id: row.plant_id })
    }
  ];

  const plantGroupCols: TableCol<MediaKnowHowEntity>[] = [
    {
      label: 'Group name',
      property: 'plant_group_name',
      columnRedirectTo: (row) =>
        getPathUrl(ROUTE_PLANT_GROUPS_DETAILS, { id: row.plant_group_id })
    }
  ];

  const familyCols: TableCol<MediaKnowHowEntity>[] = [
    {
      label: 'Family genus',
      property: 'family_genus',
      columnRedirectTo: (row) =>
        getPathUrl(ROUTE_FAMILY_DETAILS, { id: row.family_id })
    }
  ];

  return (
    <div className="content restrict-width">
      <AttachAsKnowHowMediaModal
        ref={attachAsKnowHowModalRef}
        onSuccess={onSuccessMediaAttach}
      />
      <div className="details-top-nav mb-3">
        <DisplayNav
          valueArr={navElements}
          booleanArr={nav}
          setBooleanArr={setNav}
        />
      </div>
      <DeleteMediaModal ref={deleteModalRef} onSuccess={onDeleteSuccess} />
      {nav[0] && (
        <DisplayPrimaryInfoWrapper title="Media" arrBtnObj={arrayOfBtnObjects}>
          <ShowMessage
            message={deleteMessage.text}
            deleteMessage={() => setDeleteMessage({ text: '', color: '' })}
            bsColor={deleteMessage.color}
          />
          <DiplayPrimaryInfo
            imagesURL={data?.image ? [{ id: data?.id, url: data?.image }] : []}
            objArr={primaryInfoArr(data)}
          />
        </DisplayPrimaryInfoWrapper>
      )}
      {nav[1] && (
        <DisplayPrimaryInfoWrapper
          title="Attached to plants as know-how"
          arrBtnObj={[attachBtn]}
        >
          <AttachedToAsKnowHow
            media={data}
            attachedTo="plant"
            cols={plantsCols}
          />
        </DisplayPrimaryInfoWrapper>
      )}
      {nav[2] && (
        <DisplayPrimaryInfoWrapper
          title="Attached to plant groups as know-how"
          arrBtnObj={[attachBtn]}
        >
          <AttachedToAsKnowHow
            media={data}
            attachedTo="plantGroup"
            cols={plantGroupCols}
          />
        </DisplayPrimaryInfoWrapper>
      )}
      {nav[3] && (
        <DisplayPrimaryInfoWrapper
          title="Attached to families as know-how"
          arrBtnObj={[attachBtn]}
        >
          <AttachedToAsKnowHow
            media={data}
            attachedTo="family"
            cols={familyCols}
          />
        </DisplayPrimaryInfoWrapper>
      )}
    </div>
  );
};

export default Details;
