import { RootState } from 'app/reducer';
import { setLocationSearchAction } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import {
  FETCH_HUB_SOFTWARE_LIST,
  fetchHubSoftwareListAction,
  hubSoftwareListFetchedAction,
  SET_HUB_SOFTWARE_LIST_PARAMS
} from 'modules/Hub/action/listSoftware';
import {
  fetchHubSoftwares,
  FetchHubSoftwaresResponse
} from 'modules/Hub/repository';
import { manageThrow } from 'modules/Shared/action';

export function* setHubSoftwareListParamsSaga(): SagaIterator {
  yield put(fetchHubSoftwareListAction());
}

export function* fetchHubSoftwareListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.hub.hubSoftwareList
    );

    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response: FetchHubSoftwaresResponse = yield call(fetchHubSoftwares, {
      ...sort,
      pagination,
      search: filter
    });

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (_err) {}

    yield put(
      hubSoftwareListFetchedAction({ hubSoftwares: content, totalPages })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        hubSoftwareListFetchedAction({ hubSoftwares: [], totalPages: 1 })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* hubSoftwareListSaga(): SagaIterator {
  yield takeLatest(SET_HUB_SOFTWARE_LIST_PARAMS, setHubSoftwareListParamsSaga);
  yield takeLatest(FETCH_HUB_SOFTWARE_LIST, fetchHubSoftwareListSaga);
}
