import { RootState } from 'app/reducer';
import {
  SET_PLANT_DETAILS_PARAMS,
  FETCH_PLANT_DETAILS,
  fetchPlantDetailsAction,
  plantDetailsFetchedAction
} from 'modules/Plants/action/Details';
import {
  fetchPlantDetails,
  FetchPlantDetailsResponse
} from 'modules/Plants/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';

export function* setPlantDetailsParamsSaga(): SagaIterator {
  yield put(fetchPlantDetailsAction());
}

export function* fetchPlantDetailsSaga(): SagaIterator {
  try {
    const { plantId } = yield select((state: RootState) => state.plant.details);

    const response: FetchPlantDetailsResponse = yield call(
      fetchPlantDetails,
      plantId
    );

    const { plant, ...rest } = response.data;

    yield put(
      plantDetailsFetchedAction({
        plantDetails: {
          ...plant,
          ...rest
        }
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(plantDetailsFetchedAction({ plantDetails: null }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantDetailsSaga(): SagaIterator {
  yield takeLatest(SET_PLANT_DETAILS_PARAMS, setPlantDetailsParamsSaga);
  yield takeLatest(FETCH_PLANT_DETAILS, fetchPlantDetailsSaga);
}
