const wysiwygToolbar = {
  options: ['inline', 'list'],
  inline: {
    options: ['bold', 'italic']
  }
};

export const policyWysiwygToolbar = {
  options: ['inline', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline']
  }
};

export const faqToolbar = {
  options: ['inline', 'list', 'blockType', 'colorPicker'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough']
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
  }
};

export default wysiwygToolbar;
