import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { Action } from 'redux';
import { HealthCheckHelpEntity } from 'modules/PlantDiseases/model/HealthCheckHelp';

export const SET_HEALTH_CHECK_HELP_LIST_PARAMS =
  'PLANT_DISEASES/HEALTH_CHECK_HELP/LIST/PARAMS/SET';
export const UPDATE_HEALTH_CHECK_HELP_LIST_PARAMS =
  'PLANT_DISEASES/HEALTH_CHECK_HELP/LIST/PARAMS/UPDATE';
export const FETCH_HEALTH_CHECK_HELP_LIST =
  'PLANT_DISEASES/HEALTH_CHECK_HELP/LIST/FETCH';
export const HEALTH_CHECK_HELP_LIST_FETCHED =
  'PLANT_DISEASES/HEALTH_CHECK_HELP/LIST/FETCHED';
export const HEALTH_CHECK_HELP_LIST_SET_RESPONDED =
  'PLANT_DISEASES/HEALTH_CHECK_HELP/LIST/SET_RESPONDED';

export type PlantDiseasesListAction =
  | SetHealthCheckHelpListParamsAction
  | FetchHealthCheckHelpListAction
  | HealthCheckHelpListFetchedAction
  | HealthCheckHelpListSetRespondedAction;

export type SetHealthCheckHelpListParamsAction = SetSearchingParamsAction<
  typeof SET_HEALTH_CHECK_HELP_LIST_PARAMS
>;

export const setHealthCheckHelpListParamsAction = (
  payload: PartialSearchingProps
): SetHealthCheckHelpListParamsAction =>
  setSearchingParamsAction(SET_HEALTH_CHECK_HELP_LIST_PARAMS, payload);

export type FetchHealthCheckHelpListAction = Action<
  typeof FETCH_HEALTH_CHECK_HELP_LIST
>;

export const fetchHealthCheckHelpListAction =
  (): FetchHealthCheckHelpListAction => ({
    type: FETCH_HEALTH_CHECK_HELP_LIST
  });

export type HealthCheckHelpListFetchedPayload = {
  content: HealthCheckHelpEntity[];
  totalPages: number;
};

export interface HealthCheckHelpListFetchedAction
  extends Action<typeof HEALTH_CHECK_HELP_LIST_FETCHED> {
  payload: HealthCheckHelpListFetchedPayload;
}

export const healthCheckHelpListFetchedAction = (
  payload: HealthCheckHelpListFetchedPayload
): HealthCheckHelpListFetchedAction => ({
  type: HEALTH_CHECK_HELP_LIST_FETCHED,
  payload
});

export interface HealthCheckHelpListSetRespondedPayload {
  healthCheckHelpId: number;
  responded: boolean;
}

export interface HealthCheckHelpListSetRespondedAction
  extends Action<typeof HEALTH_CHECK_HELP_LIST_SET_RESPONDED> {
  payload: HealthCheckHelpListSetRespondedPayload;
}

export const healthCheckHelpListSetRespondedAction = (
  payload: HealthCheckHelpListSetRespondedPayload
): HealthCheckHelpListSetRespondedAction => ({
  type: HEALTH_CHECK_HELP_LIST_SET_RESPONDED,
  payload
});
