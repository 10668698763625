import React, { FormEvent, ReactNode } from 'react';
import Dictionary, {
  createDictionary
} from 'modules/Dictionary/model/Dictionary';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import { FormGroup, Input, Label, Form } from 'reactstrap';
import { StandardBtn } from 'modules/Layout/view/Buttons/StandardBtn';
import { getPathUrl } from 'modules/Shared/helper/api';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { addDictionary } from 'modules/Dictionary/repository';
import { ROUTE_DICTIONARY_DETAILS } from 'modules/Dictionary/routes';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { breadcrumbRouteDictionaryAdd } from 'modules/Dictionary/breadcrumbs';

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
}

export interface Props extends RouteComponentProps<any>, DispatchProps {
  table: string;
}

export interface State {
  dictionary: Dictionary;
  creating: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload))
});

class AddDictionaryView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dictionary: createDictionary(),
      creating: false
    };

    this.onChangeLang = this.onChangeLang.bind(this);
    this.create = this.create.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
  }

  componentDidMount(): void {
    const { table, managePage } = this.props;

    managePage({
      title: `Dictionary ${Dictionary.getName(table)} - add position`,
      breadcrumb: breadcrumbRouteDictionaryAdd(table),
      type: null
    });
  }

  onChangeLang(value: string, lang: string): void {
    const { dictionary } = this.state;

    dictionary.langs[lang] = {
      text: value
    };

    this.setState({
      dictionary
    });
  }

  onChangeName(value: string): void {
    const { dictionary } = this.state;

    dictionary.name = value;

    this.setState({
      dictionary
    });
  }

  create(event: FormEvent): void {
    const { table, history } = this.props;
    const { dictionary } = this.state;

    event.preventDefault();

    this.setState(
      {
        creating: true
      },
      async () => {
        try {
          const {
            data: {
              dictionary: { id }
            }
          } = await addDictionary(dictionary, table);

          history.push(getPathUrl(ROUTE_DICTIONARY_DETAILS, { id }, { table }));
        } catch (_error) {
          this.setState({
            creating: false
          });
        }
      }
    );
  }

  render(): ReactNode {
    const { history, table } = this.props;
    const { dictionary, creating } = this.state;

    return (
      <div className="card">
        <div className="card-body p-3">
          <Form onSubmit={this.create}>
            <div className="row">
              <div className="col-12">
                <FormGroup className="row">
                  <Label className="col-4">Name</Label>
                  <Input
                    className="col-8"
                    type="text"
                    value={dictionary.name || ''}
                    required
                    onChange={(event) => this.onChangeName(event.target.value)}
                  />
                </FormGroup>
                {supportedLangs.map((suppLang) => (
                  <FormGroup key={suppLang} className="row">
                    <Label className="col-4">
                      {`Text ${suppLang.toUpperCase()}`}
                    </Label>
                    <Input
                      className="col-8"
                      id={`text-${suppLang}`}
                      type="text"
                      value={dictionary.langs[suppLang].text || ''}
                      onChange={(event) =>
                        this.onChangeLang(event.target.value, suppLang)
                      }
                    />
                  </FormGroup>
                ))}
              </div>
              <div className="col-12 d-flex mt-2 flex-wrap justify-content-end">
                <StandardBtn
                  callback={() =>
                    history.push(getPathUrl(`/${table.replace(/_/g, '-')}`))
                  }
                  text="Cancel"
                  bsColor="danger"
                  inactive={creating}
                />
                <StandardBtn
                  callback={() => {}}
                  type="submit"
                  text="Create"
                  loading={creating}
                  bsColor="success"
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(AddDictionaryView)
);
