import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import PlantGroups from 'modules/PlantGroups/model/PlantGroups';

export interface PlantGroupsListState extends SearchingProps {
  plantGroups: PlantGroups[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initPlantGroupsListState = (): PlantGroupsListState => ({
  plantGroups: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
