import React from 'react';
import { StandardBtn } from 'modules/Layout/view/Buttons/StandardBtn';

export interface IBtnObj {
  callback: () => void;
  text: string;
  bsColor?: string;
  loading?: boolean;
  inactive?: boolean;
}

export interface IDisplayPrimaryInfo {
  title?: string;
  arrBtnObj?: IBtnObj[];
}

const displayBtns = (arrBtnObj: IBtnObj[]) => {
  return arrBtnObj.map((obj, index) => {
    return (
      <StandardBtn
        callback={obj.callback}
        text={obj.text}
        bsColor={obj.bsColor}
        loading={obj.loading}
        inactive={obj.inactive}
        key={index}
      />
    );
  });
};

const DisplayPrimaryInfoWrapper: React.FC<IDisplayPrimaryInfo> = ({
  title = 'Primary Info',
  arrBtnObj,
  children
}) => {
  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body p-3">
            <div className="row align-items-center">
              <div className="col-md-12 pr-3 h3 d-flex flex-wrap align-items-center justify-content-between w-100">
                <span className="mr-2">{title}</span>
                <div className="d-flex flex-wrap">
                  {arrBtnObj && displayBtns(arrBtnObj)}
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayPrimaryInfoWrapper;
