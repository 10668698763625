import React, { ReactNode } from 'react';
import { Button, CustomInput, Input, Label } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import getCroppedImg from 'modules/Layout/helper/cropImage';

export interface Props {
  onCrop?: (src: string) => void;
}

export interface State {
  src: string;
  showCrop: boolean;
  crop: Point;
  zoom: number;
  aspectRatioX: number;
  aspectRatioY: number;
  croppedAreaPixels: Area;
}

export class CropImageModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      src: null,
      showCrop: false,
      crop: {
        x: 0,
        y: 0
      },
      zoom: 1,
      aspectRatioX: 1,
      aspectRatioY: 1,
      croppedAreaPixels: {
        width: 0,
        height: 0,
        x: 0,
        y: 0
      }
    };

    this.renderBody = this.renderBody.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.renderCropper = this.renderCropper.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(): Promise<void> {
    const { onCrop } = this.props;
    const { croppedAreaPixels, src } = this.state;

    try {
      const imageUrl = await getCroppedImg(src, croppedAreaPixels);

      onCrop(imageUrl);
      this.close();
    } catch (error) {
      console.log(error);
    }
  }

  onCropComplete(_croppedArea: Area, croppedAreaPixels: Area): void {
    this.setState({
      croppedAreaPixels
    });
  }

  open(src: string): void {
    this.setState({
      src,
      showCrop: true,
      crop: {
        x: 0,
        y: 0
      },
      zoom: 1,
      aspectRatioX: 1,
      aspectRatioY: 1,
      croppedAreaPixels: {
        width: 0,
        height: 0,
        x: 0,
        y: 0
      }
    });
  }

  close(): void {
    this.setState({
      showCrop: false
    });
  }

  renderTitle = (): React.ReactNode => {
    return 'Crop image';
  };

  renderCropper(): ReactNode {
    const { src, crop, zoom, aspectRatioX, aspectRatioY } = this.state;

    return (
      <div className="position-relative w-100" style={{ height: '700px' }}>
        <Cropper
          image={src}
          crop={crop}
          zoom={zoom}
          aspect={aspectRatioX / aspectRatioY}
          showGrid={false}
          zoomSpeed={0.3}
          onCropChange={(newCrop) => this.setState({ crop: newCrop })}
          onCropComplete={this.onCropComplete}
          onZoomChange={(newZoom) => this.setState({ zoom: newZoom })}
        />
      </div>
    );
  }

  renderOptions(): ReactNode {
    const { aspectRatioX, aspectRatioY, zoom } = this.state;

    return (
      <>
        <div>
          <div className="font-weight-bold">Crop aspect ratio:</div>
          <div className="d-flex flex-column pl-3">
            <Label>
              <Input
                type="radio"
                name="ratio_aspect"
                checked={aspectRatioX / aspectRatioY === 1}
                onChange={() =>
                  this.setState({
                    aspectRatioX: 1,
                    aspectRatioY: 1
                  })
                }
              />
              1 / 1
            </Label>
            <Label>
              <Input
                type="radio"
                name="ratio_aspect"
                checked={aspectRatioX / aspectRatioY === 4 / 3}
                onChange={() =>
                  this.setState({
                    aspectRatioX: 4,
                    aspectRatioY: 3
                  })
                }
              />
              4 / 3
            </Label>
            <Label>
              <Input
                type="radio"
                name="ratio_aspect"
                checked={aspectRatioX / aspectRatioY === 16 / 9}
                onChange={() =>
                  this.setState({
                    aspectRatioX: 16,
                    aspectRatioY: 9
                  })
                }
              />
              16 / 9
            </Label>
          </div>
          <Label className="mt-3">
            {`Aspect ratio X: ${aspectRatioX}`}
            <CustomInput
              id="aspect-ratio-x"
              type="range"
              value={aspectRatioX}
              onChange={(event) =>
                this.setState({
                  aspectRatioX: Number(event.currentTarget.value)
                })
              }
            />
          </Label>
          <Label>
            {`Aspect ratio Y: ${aspectRatioY}`}
            <CustomInput
              id="aspect-ratio-y"
              type="range"
              value={aspectRatioY}
              onChange={(event) =>
                this.setState({
                  aspectRatioY: Number(event.currentTarget.value)
                })
              }
            />
          </Label>
        </div>
        <div className="mt-3">
          <Label>
            {`Zoom: ${zoom}`}
            <CustomInput
              id="zoom"
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(event) =>
                this.setState({
                  zoom: Number(event.currentTarget.value)
                })
              }
            />
          </Label>
        </div>
      </>
    );
  }

  renderBody(): React.ReactNode {
    return (
      <div className="row">
        <div className="col-10">{this.renderCropper()}</div>
        <div className="col-2">{this.renderOptions()}</div>
      </div>
    );
  }

  renderFooter(): React.ReactNode {
    return (
      <div className="d-flex justify-content-between w-100">
        <Button color="primary" onClick={this.close}>
          Cancel
        </Button>
        <Button color="success" onClick={this.onSubmit}>
          Crop
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { showCrop, src } = this.state;

    if (!src) {
      return null;
    }

    return (
      <BaseModal
        isOpen={showCrop}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.close}
        size="xl"
      />
    );
  }
}

export default CropImageModal;
