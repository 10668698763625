import React, { createRef, FormEvent, ReactNode, RefObject } from 'react';
import { Button, FormFeedback, Input } from 'reactstrap';
import CropImageModal from 'modules/Layout/component/Modal/CropImage';

export interface Props {
  src?: string;
  required?: boolean;
  onChange: (fileObjectUrl: string) => void;
  invalid?: boolean;
  error?: string;
}

export interface State {
  src: string;
  newSrc: string;
}

class ImageCropUpload extends React.Component<Props, State> {
  cropModalRef: RefObject<CropImageModal>;

  constructor(props: Props) {
    super(props);

    const { src } = props;

    this.state = {
      src: src || null,
      newSrc: null
    };

    this.cropModalRef = createRef();

    this.renderInput = this.renderInput.bind(this);
    this.renderPreview = this.renderPreview.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.showCrop = this.showCrop.bind(this);
    this.revert = this.revert.bind(this);
    this.onChange = this.onChange.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentWillUnmount(): void {
    const { src, newSrc } = this.state;

    if (src) {
      URL.revokeObjectURL(src);
    }

    if (newSrc) {
      URL.revokeObjectURL(newSrc);
    }
  }

  onFileChange(event: FormEvent<HTMLInputElement>): void {
    const { onChange } = this.props;
    const [file] = event.currentTarget.files;

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      this.setState({
        src: fileUrl
      });
      onChange(fileUrl);
    }
  }

  renderInput(): ReactNode {
    const { required = false, error = null } = this.props;

    return (
      <>
        <Input
          type="file"
          required={required}
          accept="image/*"
          onChange={this.onFileChange}
          invalid={Boolean(error)}
        />
        {error && <FormFeedback>{error}</FormFeedback>}
      </>
    );
  }

  renderPreview(): ReactNode {
    const { src, newSrc } = this.state;

    return (
      <div className="img-container d-flex justify-content-center align-items-center">
        <img
          className="img-fluid"
          src={newSrc || src}
          alt="Upload"
          style={{ maxHeight: '400px' }}
        />
      </div>
    );
  }

  showCrop(): void {
    const { src } = this.state;

    this.cropModalRef?.current?.open(src);
  }

  revert(): void {
    const { src } = this.state;
    const { onChange } = this.props;

    this.setState({
      newSrc: null
    });

    onChange(src);
  }

  delete(): void {
    const { onChange } = this.props;

    this.setState({
      src: null,
      newSrc: null
    });

    onChange(null);
  }

  async onChange(cropped: string): Promise<void> {
    const { onChange } = this.props;

    this.setState({
      newSrc: cropped
    });

    onChange(cropped);
  }

  render(): ReactNode {
    const { src, newSrc } = this.state;

    if (!src) {
      return this.renderInput();
    }

    return (
      <div className="image-upload-with-crop border p-1">
        <CropImageModal ref={this.cropModalRef} onCrop={this.onChange} />
        <div className="position-relative d-flex justify-content-center align-items-center">
          {this.renderPreview()}
        </div>
        <div className="d-flex justify-content-center flex-wrap mt-4 mb-2">
          <Button className="mx-2" color="success" onClick={this.showCrop}>
            Crop
          </Button>
          <Button
            className="mx-2"
            color="primary"
            disabled={!newSrc}
            onClick={this.revert}
          >
            Revert
          </Button>
          <Button className="mx-2" color="danger" onClick={this.delete}>
            Delete
          </Button>
        </div>
      </div>
    );
  }
}

export default ImageCropUpload;
