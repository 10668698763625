import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import News from 'modules/News/model/News';

export interface NewsListState extends SearchingProps {
  news: News[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initNewsListState = (): NewsListState => ({
  news: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
