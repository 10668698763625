import Filter from 'modules/Layout/component/Filter';
import { FilterInputType } from 'modules/Layout/type';
import {
  PaginationParams,
  PartialSearchingProps,
  SearchParams
} from 'modules/Shared/type';
import React, { ReactNode } from 'react';
import { Collapse } from 'reactstrap';

export interface ListingFilterProps {
  inputs: FilterInputType[];
  filter: SearchParams;
  pagination?: PaginationParams;
  setParams: (params: PartialSearchingProps) => void;
}

export interface ListingFilterState {
  isOpen: boolean;
}

class ListingFilter extends React.Component<
  ListingFilterProps,
  ListingFilterState
> {
  constructor(props: ListingFilterProps) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.onChange = this.onChange.bind(this);
    this.getActiveFiltersCount = this.getActiveFiltersCount.bind(this);
    this.renderToggle = this.renderToggle.bind(this);
  }

  onChange(property: string, value: string): void {
    const { filter, pagination, setParams } = this.props;

    const params: PartialSearchingProps = {
      filter: { ...filter },
      pagination
    };

    if (value) {
      params.filter[property] = value;
      pagination.page = 1;
    } else {
      delete params.filter[property];
    }

    setParams(params);
  }

  getActiveFiltersCount(): number {
    const { filter } = this.props;

    let activeFilters = 0;
    const filters = Object.keys(filter);

    filters.forEach((objKey) => {
      const value = filter[objKey];

      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        value.toString().trim()
      ) {
        activeFilters += 1;
      }
    });

    return activeFilters;
  }

  renderToggle(): ReactNode {
    const { isOpen: isFilterOpen } = this.state;

    const activeFiltersCount = this.getActiveFiltersCount();

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() =>
            this.setState(({ isOpen }) => ({
              isOpen: !isOpen
            }))
          }
          className="card-header text-white cursor-pointer bg-secondary font-18 py-2 d-flex align-content-center justify-content-center"
        >
          <span className="mx-auto">
            {isFilterOpen ? 'Hide' : `Show`} filters
            {activeFiltersCount > 0 ? ` (${activeFiltersCount} active)` : ''}
          </span>
          {isFilterOpen ? (
            <i className="mdi mdi-chevron-up text-white" />
          ) : (
            <i className="mdi mdi-chevron-down text-white" />
          )}
        </div>
      </>
    );
  }

  render(): ReactNode {
    const { inputs } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="card">
        {this.renderToggle()}
        <Collapse isOpen={isOpen}>
          <div className="filter-wrapper">
            <Filter inputs={inputs} onChange={this.onChange} />
          </div>
        </Collapse>
      </div>
    );
  }
}

export default ListingFilter;
