import React from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import Hub from 'modules/Hub/model/Hub';

export interface Props {
  hub: Hub;
}

const HubDetailsLogs = (props: Props): JSX.Element => {
  const { hub } = props;

  const cols: TableCol<Hub['hubLogs'][0]>[] = [
    {
      label: 'Error',
      property: 'error'
    },
    {
      label: 'Sensor mac',
      property: 'sensor_id',
      value: (row) => row.sensor_id || ''
    },
    {
      label: 'Timestamp (UTC)',
      property: 'timestamp'
    }
  ];

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Logs">
        <Table
          cols={cols}
          rows={hub.hubLogs.sort((a, b) =>
            b.timestamp.localeCompare(a.timestamp)
          )}
        />
      </DisplayBaseTabContent>
    </div>
  );
};

export default HubDetailsLogs;
