import { supportedLangsEnum } from 'modules/Shared/configs/supportedLang/config';

export type MeasurementsStatusDescEntity = {
  id?: number;
  measurement_status?: MeasurementStatusesEnum;
  measurement?: MeasurementTypesEnum;
  lang?: supportedLangsEnum;
  description?: string;
  long_term_description?: string;
  plant_group_id?: number;
  plant_id?: number;
};

export enum MeasurementStatusesEnum {
  NO_DATA = 0,
  TOO_LOW = 1,
  LOW = 2,
  PERFECT = 3,
  HIGH = 4,
  TOO_HIGH = 5
}

export enum MeasurementStatusesOptionsEnum {
  TOO_LOW = 'too low',
  LOW = 'low',
  PERFECT = 'perfect',
  HIGH = 'high',
  TOO_HIGH = 'too high'
}

export const measurementStatusesOptions = [
  'too low',
  'low',
  'perfect',
  'high',
  'too high'
];

export const getStatusValue = (status: string) => {
  switch (status) {
    case MeasurementStatusesOptionsEnum.TOO_LOW:
      return MeasurementStatusesEnum.TOO_LOW;
    case MeasurementStatusesOptionsEnum.LOW:
      return MeasurementStatusesEnum.LOW;
    case MeasurementStatusesOptionsEnum.PERFECT:
      return MeasurementStatusesEnum.PERFECT;
    case MeasurementStatusesOptionsEnum.HIGH:
      return MeasurementStatusesEnum.HIGH;
    case MeasurementStatusesOptionsEnum.TOO_HIGH:
      return MeasurementStatusesEnum.TOO_HIGH;
    default:
      return null;
  }
};

export const getPhStatus = (status: number) => {
  if (status === 0) {
    return 'low';
  }

  if (status === 1) {
    return 'perfect';
  }

  if (status === 2) {
    return 'high';
  }

  return '-';
};

export const getMeasurementStatus = (status: number) => {
  switch (status) {
    case MeasurementStatusesEnum.NO_DATA:
      return 'No data available.';
    case MeasurementStatusesEnum.TOO_LOW:
      return MeasurementStatusesOptionsEnum.TOO_LOW;
    case MeasurementStatusesEnum.LOW:
      return MeasurementStatusesOptionsEnum.LOW;
    case MeasurementStatusesEnum.PERFECT:
      return MeasurementStatusesOptionsEnum.PERFECT;
    case MeasurementStatusesEnum.HIGH:
      return MeasurementStatusesOptionsEnum.HIGH;
    case MeasurementStatusesEnum.TOO_HIGH:
      return MeasurementStatusesOptionsEnum.TOO_HIGH;
    default:
      return null;
  }
};

export enum MeasurementTypesEnum {
  TEMPERATURE = 'temperature',
  SOIL_MOISTURE = 'soil_moisture',
  SOIL_FERTILITY = 'soil_fertility',
  LIGHT = 'light'
}

export const measurementTypes = [
  'temperature',
  'soil_moisture',
  'soil_fertility',
  'light'
];

export type MeasurementType =
  | 'temperature'
  | 'light'
  | 'soil_moisture'
  | 'soil_fertility';

export type MeasurementStatus = 1 | 2 | 3 | 4 | 5;
