import Media from 'modules/Media/model/Media';

export interface MediaDetailsState {
  mediaDetails: Media;
  fetching: boolean;
  mediaId: number;
}

export const initMediaDetailsState = (): MediaDetailsState => ({
  mediaDetails: null,
  fetching: false,
  mediaId: null
});
