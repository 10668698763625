import React from 'react';

interface IProps {
  booleanArr: boolean[];
  setBooleanArr:
    | React.Dispatch<React.SetStateAction<boolean[]>>
    | ((state: boolean[]) => void);
  valueArr: string[];
}

const DisplayNav: React.FC<IProps> = ({
  valueArr,
  booleanArr,
  setBooleanArr
}) => {
  return (
    <div>
      <ul className="nav nav-tabs">
        {valueArr.map((el, index) => {
          return (
            <li className="nav-item mr-1" key={index}>
              <div
                className={`nav-link cursor-pointer${
                  booleanArr[index] ? ' active' : ''
                }`}
                onClick={() =>
                  !booleanArr[index] &&
                  setBooleanArr(
                    booleanArr.map((_, stateIndex) => stateIndex === index)
                  )
                }
              >
                {el}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DisplayNav;
