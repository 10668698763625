import PlantGroupsListView from 'modules/PlantGroups/view/List';
import PlantGroupsAddView from 'modules/PlantGroups/view/Add';
import PlantGroupsDetailsView from 'modules/PlantGroups/view/Details';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import PlantGroupEditView from 'modules/PlantGroups/view/Edit';
import EditPlantGroupView from 'modules/PlantGroups/view/Edit/Basic';
import EditPlantGroupPlantsView from 'modules/PlantGroups/view/Edit/Plants';
import PlantGroupsBulkUpdateView from 'modules/PlantGroups/view/Edit/Bulk';

export const ROUTE_PLANT_GROUPS = '/plant-groups/list';
export const ROUTE_PLANT_GROUPS_ADD = '/plant-groups/add';
export const ROUTE_PLANT_GROUPS_DETAILS = '/plant-groups/details/:id';
export const ROUTE_PLANT_GROUPS_DETAILS_REDIRECT_TEMPLATE =
  '/plant-groups/details/';
export const ROUTE_PLANT_GROUPS_DETAILS_FORM_REDIRECT_TEMPLATE =
  '/plant-groups/details/form/';
export const ROUTE_PLANT_GROUP_DETAILS_FORM_LANG =
  '/plant-groups/details/form/:type/:id/:lang';
export const ROUTE_PLANT_GROUP_DETAILS_FORM_LANG_OPTION =
  '/plant-groups/details/form/:type/:id/:lang/:option';
export const ROUTE_PLANT_GROUP_EDIT = '/plant-groups/details/:id(\\d+)/edit';
export const ROUTE_PLANT_GROUP_MANAGE_PLANTS =
  '/plant-groups/details/:id(\\d+)/manage-plants';
export const ROUTE_PLANT_GROUP_BULK_UPDATE = '/plant-groups/bulk-update';

export const createPlantGroupsRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="plant-group-edit"
      path={ROUTE_PLANT_GROUP_EDIT}
      exact
      render={(props) => {
        const { id } = props.match.params;

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <EditPlantGroupView {...props} key={id} id={id} />;
      }}
    />,
    <Route
      key="plant-groups-list"
      path={ROUTE_PLANT_GROUPS}
      exact
      component={PlantGroupsListView}
    />,
    <Route
      key="plant-groups-details"
      path={ROUTE_PLANT_GROUPS_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <PlantGroupsDetailsView {...props} id={props.match.params.id} />
      )}
    />,
    <Route
      key="plant-groups-add"
      path={ROUTE_PLANT_GROUPS_ADD}
      exact
      component={PlantGroupsAddView}
    />,
    <Route
      key="plant-group-details-form-lang"
      path={ROUTE_PLANT_GROUP_DETAILS_FORM_LANG}
      exact
      render={(props) => {
        const { id, lang, type } = props.match.params;

        return (
          <PlantGroupEditView
            key={id}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            id={id}
            lang={lang}
            editType={type}
          />
        );
      }}
    />,
    <Route
      key="plant-group-details-form-lang-option"
      path={ROUTE_PLANT_GROUP_DETAILS_FORM_LANG_OPTION}
      exact
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      render={(props) => {
        const { id, lang, option, type } = props.match.params;

        return (
          <PlantGroupEditView
            key={id}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            id={id}
            lang={lang}
            option={option}
            editType={type}
          />
        );
      }}
    />,
    <Route
      key="plant-groups-manage-plants"
      path={ROUTE_PLANT_GROUP_MANAGE_PLANTS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <EditPlantGroupPlantsView {...props} id={props.match.params.id} />
      )}
    />,
    <Route
      key="plant-groups-bulk-update"
      path={ROUTE_PLANT_GROUP_BULK_UPDATE}
      component={PlantGroupsBulkUpdateView}
      exact
    />
  ]
});
