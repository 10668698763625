import { ConnectedPlantListEntity } from 'modules/PlantGroups/model/PlantGroupDetails';

export type GardenEntity = {
  id?: number;
  user_id?: number;
  name?: string;
  status?: number;
  email?: string;
  username?: string;
  sensor_data_received?: string;
  mac_address?: string;
  sensor_mesh_error?: string;
  origin_path?: string;
  thumb_path?: string;
  created_at?: string;
  updated_at?: string;
  plants?: ConnectedPlantListEntity[];
};

export default class Garden {
  readonly id?: number;
  readonly user_id?: number;
  readonly name?: string;
  readonly status?: number;
  readonly email?: string;
  readonly username?: string;
  readonly sensor_data_received?: string;
  readonly mac_address?: string;
  readonly sensor_mesh_error?: string;
  readonly origin_path?: string;
  readonly thumb_path?: string;
  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(entity: GardenEntity) {
    this.id = entity.id;
    this.user_id = entity.user_id;
    this.name = entity.name;
    this.status = entity.status;
    this.email = entity.email;
    this.username = entity.username;
    this.sensor_data_received = entity.sensor_data_received;
    this.mac_address = entity.mac_address;
    this.sensor_mesh_error = entity.sensor_mesh_error;
    this.origin_path = entity.origin_path;
    this.thumb_path = entity.thumb_path;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  static getFilterableAttributes(): string[] {
    return ['email', 'name', 'with_deleted'];
  }

  static getSortableAttributes(): string[] {
    return ['id', 'user_id', 'email', 'name'];
  }
}

export const createGarden = (entity: GardenEntity): Garden =>
  new Garden(entity);
