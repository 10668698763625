import { Routes } from 'app/routes';
import Unguarded from 'modules/Auth/component/Unguarded';
import React from 'react';
import { Route } from 'react-router-dom';
import LoginView from 'modules/Auth/view/Login';
import ForgotPasswordView from 'modules/Auth/view/ForgotPassword';
import ResetPasswordView from 'modules/Auth/view/ResetPassword';
import VerifyEmailView from 'modules/Auth/view/VerifyEmail';

export const ROUTE_LOGIN = '/login';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_PASSWORD_RESET = '/password-reset';
export const ROUTE_VERIFY_EMAIL = '/verify-email';

export const createAuthRoutes = (): Routes => ({
  public: [
    <Route key="login" path={ROUTE_LOGIN} exact>
      <Unguarded>
        <LoginView />
      </Unguarded>
    </Route>,
    <Route key="forgot-password" path={ROUTE_FORGOT_PASSWORD} exact>
      <Unguarded>
        <ForgotPasswordView />
      </Unguarded>
    </Route>,
    <Route
      key="reset-password"
      path={ROUTE_PASSWORD_RESET}
      exact
      render={(props) => (
        <ResetPasswordView
          token={new URLSearchParams(props.location.search).get('token')}
        />
      )}
    />,
    <Route
      key="verify-email"
      path={ROUTE_VERIFY_EMAIL}
      exact
      render={(props) => (
        <VerifyEmailView
          token={new URLSearchParams(props.location.search).get('token')}
        />
      )}
    />
  ]
});
