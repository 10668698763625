import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setUserFeedbackListParamsAction,
  SetUserFeedbackListParamsAction,
  UserFeedbackListRespondedAction,
  UserFeedbackListRespondedPayload,
  userFeedbackListRespondedAction
} from 'modules/User/action/list';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ROUTE_USER_DETAILS,
  ROUTE_USER_FEEDBACK_DETAILS
} from 'modules/User/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { FeedbackEntity } from '../../../model/Feedback';
import ActionShow from '../../../../Layout/component/Action/Show';
import ActionDelete from '../../../../Layout/component/Action/Delete';
import ActionUpdate from '../../../../Layout/component/Action/Update';

export interface StateProps {
  feedback: FeedbackEntity[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetUserFeedbackListParamsAction;
  setResponded: (
    payload: UserFeedbackListRespondedPayload
  ) => UserFeedbackListRespondedAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { feedback, sort } = state.user.list;

  return { feedback, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setUserFeedbackListParamsAction(payload)),
  setResponded: (payload) => dispatch(userFeedbackListRespondedAction(payload))
});

export class UserFeedbackTable extends React.Component<Props> {
  readonly cols: TableCol<FeedbackEntity>[];

  constructor(props: Props) {
    super(props);

    const { setResponded } = props;

    this.cols = [
      {
        property: 'email',
        label: 'User email',
        className: 'col-6',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: 'created_at',
        label: 'Sent at',
        className: 'col-6'
      },
      {
        property: 'responded',
        label: 'Responded',
        value: (row) => (
          <div className="actions-wrapper">
            <div>{row.responded ? 'Yes' : 'No'}</div>
            {row.responded ? (
              <ActionDelete
                label={<i className="fas font-22 fa-times" />}
                title="Set to not responded"
                onClick={() =>
                  setResponded({ feedbackId: row.id, responded: false })
                }
              />
            ) : (
              <ActionUpdate
                label={<i className="fas font-20 fa-check" />}
                title="Set to responded"
                onClick={() =>
                  setResponded({ feedbackId: row.id, responded: true })
                }
              />
            )}
          </div>
        )
      },
      {
        property: null,
        label: 'Action',
        className: 'col',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show feedback details"
              to={getPathUrl(ROUTE_USER_FEEDBACK_DETAILS, { id: row.id })}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { feedback, sort, setParams } = this.props;

    return (
      <>
        <Table
          cols={this.cols}
          rows={feedback}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(UserFeedbackTable);
