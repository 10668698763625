import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

interface IProps {
  text: string;
  // eslint-disable-next-line react/require-default-props
  url?: string;
  // eslint-disable-next-line react/require-default-props
  onClick?: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const AddBtnList: React.FC<IProps> = ({
  text,
  url,
  onClick
}: IProps) => {
  const history = useHistory();
  return (
    <Button
      color="success"
      onClick={() => (url ? history.push(url) : onClick())}
    >
      {text}
    </Button>
  );
};
