import React from 'react';
import Page from 'modules/Layout/component/Page/index';

export const PageNetworkError: React.FC = (): JSX.Element => (
  <Page
    errorCode="Network error"
    errorText="This could be a CORS issue or a dropped internet connection. It is not possible for us to know."
  />
);

export default PageNetworkError;
