import { BreadcrumbItem } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANT_GROUPS } from 'modules/PlantGroups/routes';
import PlantGroupDetails from 'modules/PlantGroups/model/PlantGroupDetails';

export const breadcrumbRoutePlantGroups = (): BreadcrumbItem[] => [
  {
    title: 'Plant groups',
    link: getPathUrl(ROUTE_PLANT_GROUPS)
  }
];

export const breadcrumbRoutePlantGroupsBulkUpdate = (): BreadcrumbItem[] => [
  ...breadcrumbRoutePlantGroups(),
  {
    title: 'Plant groups - multiple update'
  }
];

export const breadcrumbRouteAddPlantGroup = (): BreadcrumbItem[] => [
  ...breadcrumbRoutePlantGroups(),
  {
    title: 'Add'
  }
];

export const breadcrumbRouteDetailsPlantGroups = (
  plantGroup?: PlantGroupDetails
): BreadcrumbItem[] => [
  ...breadcrumbRoutePlantGroups(),
  {
    title: plantGroup ? plantGroup.name : 'Details',
    link: plantGroup ? `/plant-groups/details/${plantGroup.id}` : null
  }
];

export const breadcrumbRouteEditPlantGroup = (
  plantGroup?: PlantGroupDetails,
  measurement?: string,
  mappedType?: string,
  lang?: string
): BreadcrumbItem[] => [
  ...breadcrumbRouteDetailsPlantGroups(plantGroup),
  {
    title: `Edit ${
      measurement && mappedType && lang
        ? `${measurement?.replace('_', '')} ${mappedType} (${lang})`
        : ''
    }`
  }
];

export const breadcrumbRouteEditPlantGroupPlants = (
  plantGroup?: PlantGroupDetails
): BreadcrumbItem[] => [
  ...breadcrumbRouteDetailsPlantGroups(plantGroup),
  {
    title: 'Manage plants'
  }
];

// export const breadcrumbRouteDetailsFormPlantGroup = (
//   id: string,
//   type: string
// ): BreadcrumbItem[] => [
//   {
//     title: 'Plant groups',
//     link: '/plant-groups/list'
//   },
//   {
//     title: 'Details plant groups',
//     link: '/plant-groups/details/' + id
//   },
//   {
//     title: 'Edit' + (type ? ` ${type}` : '')
//   }
// ];
