import NewsListView from 'modules/News/view/List';
import CreateUpdateNewsView from 'modules/News/view/CreateUpdate';
import NewsDetailsView from 'modules/News/view/Details';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import NewsOrderListView from 'modules/News/view/ReorderList';
import PlantsNewsOrderListView from 'modules/News/view/ReorderList/Plant';

export const ROUTE_NEWS = '/news/list';
export const ROUTE_NEWS_ADD = '/news/add';
export const ROUTE_NEWS_EDIT = '/news/:id/edit';
export const ROUTE_NEWS_DETAILS = '/news/details/:id';
export const ROUTE_NEWS_ORDER = '/news/order';
export const ROUTE_PLANTS_NEWS_ORDER = '/news/plants-order';
export const ROUTE_NEWS_DETAILS_REDIRECT_TEMPLATE = '/news/details/';
export const ROUTE_NEWS_DETAILS_FORM_REDIRECT_TEMPLATE = '/news/details/form/';

export const createNewsRoutes = (): Routes => ({
  wrapped: [
    <Route key="news-list" path={ROUTE_NEWS} exact component={NewsListView} />,
    <Route
      key="news-add"
      path={ROUTE_NEWS_ADD}
      exact
      component={CreateUpdateNewsView}
    />,
    <Route
      key="news-details"
      path={ROUTE_NEWS_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <NewsDetailsView {...props} key={props.match.params.id} />
      )}
    />,
    <Route
      key="news-details-form"
      path={ROUTE_NEWS_EDIT}
      exact
      render={(props) => (
        <CreateUpdateNewsView
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          id={props.match.params.id}
          key={props.match.params.id}
        />
      )}
    />,
    <Route
      key="news-order"
      path={ROUTE_NEWS_ORDER}
      exact
      component={NewsOrderListView}
    />,
    <Route
      key="plants-news-order"
      path={ROUTE_PLANTS_NEWS_ORDER}
      exact
      component={PlantsNewsOrderListView}
    />
  ]
});
