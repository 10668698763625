import Loader from 'modules/Layout/component/Loader';
import React, { ReactNode } from 'react';
import { AddBtnList } from 'modules/Layout/view/Buttons/AddBtnList';

export interface ListingButton {
  text: string;
  url?: string;
  onClick?: () => void;
}

export interface ListingProps {
  table: ReactNode;
  filter?: ReactNode;
  pagination?: ReactNode;
  perPage?: ReactNode;
  loading?: boolean;
  childrenNextToPagination?: ReactNode;
  addBtn?: ListingButton;
  buttons?: ReactNode[];
}

const Listing = (props: ListingProps): JSX.Element => {
  const {
    table,
    filter,
    pagination,
    perPage,
    childrenNextToPagination,
    addBtn,
    loading = false,
    buttons = null
  } = props;

  return (
    <div className="listing-wrapper">
      {filter && <div className="filter-wrapper">{filter}</div>}
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex align-items-center">
          {addBtn?.text && (addBtn?.url || addBtn?.onClick) && (
            <AddBtnList
              text={addBtn.text}
              url={addBtn.url}
              onClick={addBtn.onClick}
            />
          )}
          {buttons}
        </div>
        <div className="listing-pagination-wrapper just d-flex align-items-center flex-column flex-sm-row flex-justify-content-right">
          {childrenNextToPagination}
          {perPage && <div className="mt-3 mt-sm-0"> Page Size {perPage}</div>}
          {pagination && <div className="ml-3">{pagination}</div>}
        </div>
      </div>

      <div className="table-wrapper position-relative mb-3">
        {loading && <Loader />}
        {table}
      </div>

      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex align-items-center">
          {addBtn?.text && (addBtn?.url || addBtn?.onClick) && (
            <AddBtnList
              text={addBtn.text}
              url={addBtn.url}
              onClick={addBtn.onClick}
            />
          )}
          {buttons}
        </div>
        <div className="listing-pagination-wrapper just d-flex align-items-center flex-column flex-sm-row flex-justify-content-right">
          {childrenNextToPagination}
          {perPage && <div className="mt-3 mt-sm-0"> Page Size {perPage}</div>}
          {pagination && <div className="ml-3">{pagination}</div>}
        </div>
      </div>
    </div>
  );
};

export default Listing;
