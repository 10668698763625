import React, { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import User, { createUser } from 'modules/User/model/User';
import { fetchUser } from 'modules/User/repository';
import { breadcrumbRouteEditAdministrator } from 'modules/User/breadcrumbs';
import AdministratorForm from 'modules/User/component/Form/Administrator';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps {
  id: string;
}

export interface State {
  user: User;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class AdministratorEditView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: null
    };
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow } = this.props;

    managePage({
      title: 'Edit administrator',
      breadcrumb: breadcrumbRouteEditAdministrator(),
      type: 'loading'
    });

    try {
      const { data } = await fetchUser(id);

      const user = createUser(data.user);

      this.setState({
        user
      });

      managePage({
        title: `Edit administrator - ${user.getName()}`,
        breadcrumb: breadcrumbRouteEditAdministrator(user),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  render(): ReactNode {
    const { type } = this.props;
    const { user } = this.state;

    if (type || !user) {
      return null;
    }

    return <AdministratorForm user={user} />;
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(AdministratorEditView);
