import React, { FormEvent, ReactNode } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import Loader from 'modules/Layout/component/Loader';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import {
  editPhResultsToastError,
  editPhResultsToastSuccess
} from 'modules/Plants/toasts';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import PhResults from 'modules/Plants/model/PhResults';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import MediaAutocomplete from 'modules/Plants/container/Autocomplete';
import Wysiwyg from 'modules/Layout/component/Wysiwyg';
import {
  mapEditorStateToHtml,
  mapHtmlToEditorState
} from 'modules/Layout/helper/wysiwyg';
import { EditorState } from 'draft-js';
import { updatePhResults } from 'modules/Plants/repository';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onSuccess?: (response: AxiosResponse) => void;
}

export interface PhResultsValue {
  lang: string;
  description: EditorState;
  what_you_can_do: EditorState;
  media_id: number;
}

export interface State {
  isOpen: boolean;
  phResults: PhResults;
  phResultsValues: PhResultsValue[];
  fetching: boolean;
  activeTabs: boolean[];
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class EditPhResultsModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      phResults: null,
      phResultsValues: [],
      fetching: false,
      activeTabs: supportedLangs.map((_obj, index) => index === 0)
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.renderNav = this.renderNav.bind(this);
    this.onMediaChange = this.onMediaChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onWhatCanYouDoChange = this.onWhatCanYouDoChange.bind(this);
  }

  openModal(phResults: PhResults): void {
    const phResultsValues = supportedLangs.map((lang) => {
      const phResultsLang = phResults.lang.find((obj) => obj.lang === lang);

      if (!phResultsLang) {
        return {
          lang,
          description: mapHtmlToEditorState(''),
          what_you_can_do: mapHtmlToEditorState(''),
          media_id: null
        };
      }

      return {
        lang,
        description: mapHtmlToEditorState(phResultsLang.description),
        what_you_can_do: mapHtmlToEditorState(phResultsLang.what_you_can_do),
        media_id: phResultsLang.media?.id || null
      };
    });

    this.setState({
      isOpen: true,
      fetching: false,
      phResults,
      phResultsValues,
      activeTabs: supportedLangs.map((_obj, index) => index === 0)
    });
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const { addToast, onSuccess } = this.props;
    const { phResultsValues, phResults } = this.state;

    this.setState({
      fetching: true
    });

    try {
      const mappedData = phResultsValues.map((obj) => ({
        ...obj,
        description: mapEditorStateToHtml(obj.description),
        what_you_can_do: mapEditorStateToHtml(obj.what_you_can_do)
      }));
      const response = await updatePhResults(phResults.id, mappedData);

      addToast(editPhResultsToastSuccess());

      this.setState({
        isOpen: false
      });

      if (onSuccess) {
        onSuccess(response);
      }
    } catch (error) {
      console.log(error);
      addToast(editPhResultsToastError());
    }
  }

  renderTitle = (): React.ReactNode => {
    return 'Edit pH results';
  };

  renderNav(): ReactNode {
    const { activeTabs } = this.state;

    return (
      <div className="top-nav mt-2 mb-3">
        <DisplayNav
          booleanArr={activeTabs}
          setBooleanArr={(newActiveTabs: boolean[]) =>
            this.setState({ activeTabs: newActiveTabs })
          }
          valueArr={supportedLangs}
        />
      </div>
    );
  }

  onMediaChange(index: number, id: number | string): void {
    const { phResultsValues } = this.state;
    phResultsValues[index].media_id = Number(id);

    this.setState({
      phResultsValues: [...phResultsValues]
    });
  }

  onDescriptionChange(index: number, description: EditorState): void {
    const { phResultsValues } = this.state;
    phResultsValues[index].description = description;

    this.setState({
      phResultsValues: [...phResultsValues]
    });
  }

  onWhatCanYouDoChange(index: number, description: EditorState): void {
    const { phResultsValues } = this.state;
    phResultsValues[index].what_you_can_do = description;

    this.setState({
      phResultsValues: [...phResultsValues]
    });
  }

  renderBody(): React.ReactNode {
    const { fetching, phResults, activeTabs, phResultsValues } = this.state;

    const activeTabIndex = activeTabs.findIndex((obj) => obj);
    const activeLang = supportedLangs[activeTabIndex];
    const phResultsIndex = phResultsValues.findIndex(
      (obj) => obj.lang === activeLang
    );
    const phResultsLang = phResultsValues[phResultsIndex];

    return (
      <Form id="edit-ph-form" onSubmit={this.onSubmit}>
        {fetching && <Loader />}
        <h3 className="mt-0">{phResults.getStatus()}</h3>
        {this.renderNav()}
        <FormGroup key={`${phResultsLang.lang}-description`}>
          <span className="font-weight-bold font-20">Description:</span>
          <Wysiwyg
            initialEditorState={phResultsLang.description}
            onChange={(editorState) =>
              this.onDescriptionChange(phResultsIndex, editorState)
            }
          />
          <div className="mt-2">
            <span className="font-weight-bold">Note:</span> to use min/max pH
            threshold from plant type insert in description(use can insert each
            variable only once):
          </div>
          <div className="mt-1">
            - <span className="font-weight-bold">{`{threshold_min}`}</span> for
            pH min threshold from plant type
          </div>
          <div className="mt-1">
            - <span className="font-weight-bold">{`{threshold_max}`}</span> for
            pH max threshold from plant type
          </div>
        </FormGroup>
        <FormGroup key={`${phResultsLang.lang}-what-can-you-do`}>
          <span className="font-weight-bold font-20">What you can do:</span>
          <Wysiwyg
            initialEditorState={phResultsLang.what_you_can_do}
            onChange={(editorState) =>
              this.onWhatCanYouDoChange(phResultsIndex, editorState)
            }
          />
          <div className="mt-2">
            <span className="font-weight-bold">Note:</span> to use min/max pH
            threshold from plant type insert in description(use can insert each
            variable only once):
          </div>
          <div className="mt-1">
            - <span className="font-weight-bold">{`{threshold_min}`}</span> for
            pH min threshold from plant type
          </div>
          <div className="mt-1">
            - <span className="font-weight-bold">{`{threshold_max}`}</span> for
            pH max threshold from plant type
          </div>
        </FormGroup>
        <FormGroup>
          <span className="font-weight-bold font-20">Media:</span>
          <MediaAutocomplete
            label="Choose media"
            mediaId={phResultsLang.media_id}
            onChange={(id) => this.onMediaChange(phResultsIndex, id)}
          />
        </FormGroup>
      </Form>
    );
  }

  renderFooter(): React.ReactNode {
    const { fetching } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button
          type="button"
          disabled={fetching}
          color="primary"
          onClick={this.toggle}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="#edit-ph-form"
          disabled={fetching}
          color="success"
          onClick={this.onSubmit}
        >
          Update
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen, phResults } = this.state;

    if (!phResults) {
      return null;
    }

    return (
      <BaseModal
        size="lg"
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(EditPhResultsModal);
