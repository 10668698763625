import { Cancel } from 'modules/Layout/view/SVGs';
import React from 'react';

export interface IProps {
  message: string;
  deleteMessage?: () => void;
  bsColor?: string;
}

const ShowMessage: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { message, bsColor = 'danger', deleteMessage = null } = props;

  if (message)
    return (
      <div
        className={`col-sm-12 alert alert-${bsColor} fade show d-flex justify-content-between ${
          deleteMessage ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (deleteMessage) {
            deleteMessage();
          }
        }}
      >
        <div className="row mr-2 align-items-center">{message}</div>
        {deleteMessage && (
          <div>
            <Cancel width="24" height="24" />
          </div>
        )}
      </div>
    );

  return null;
};

export default ShowMessage;
