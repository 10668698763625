import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import Plant from 'modules/Plants/model/Plant';
import PlantImport from 'modules/Plants/model/PlantImport';
import HelpFindPlant from 'modules/Plants/model/HelpFindPlant';

export interface PlantListState extends SearchingProps {
  plants: Plant[];
  plantImports: PlantImport[];
  helpFindPlant: HelpFindPlant[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initPlantListState = (): PlantListState => ({
  plants: [],
  plantImports: [],
  helpFindPlant: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
