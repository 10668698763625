export type PlantGroupsEntity = {
  id?: number;
  name?: string;
  created_at?: string;
  updated_at?: string;
  plants_count?: number;
};

export default class PlantGroups {
  readonly id?: number;
  readonly name?: string;
  readonly plants_count?: number;
  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(entity: PlantGroupsEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.plants_count = entity.plants_count;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  static getFilterableAttributes(): string[] {
    return ['name'];
  }

  static getSortableAttributes(): string[] {
    return ['name'];
  }
}

export const createPlantGroups = (entity: PlantGroupsEntity): PlantGroups =>
  new PlantGroups(entity);
