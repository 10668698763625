import React from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { HelpFindPlantEntity } from 'modules/Plants/model/HelpFindPlant';
import { ROUTE_HELP_FIND_PLANT_DETAILS } from 'modules/Plants/routes';

export interface Props {
  helpFindPlant: HelpFindPlantEntity[];
}

const UserDetailsHelpFindPlant = (props: Props): JSX.Element => {
  const { helpFindPlant } = props;

  const cols: TableCol<HelpFindPlantEntity>[] = [
    {
      label: 'Sent at',
      property: 'created_at',
      columnRedirectTo: (row) =>
        getPathUrl(ROUTE_HELP_FIND_PLANT_DETAILS, { id: row.id })
    }
  ];

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Help find plant">
        <Table cols={cols} rows={helpFindPlant} />
      </DisplayBaseTabContent>
    </div>
  );
};

export default UserDetailsHelpFindPlant;
