import { AxiosError } from 'axios';
import ApiError from 'modules/Shared/exception/ApiError';

export default class NetworkError extends ApiError {
  constructor(error: AxiosError) {
    super('Network error.', error);
  }

  getMessageValue(): string {
    return 'Network error';
  }
}
