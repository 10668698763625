import GardenListView from 'modules/Garden/view/List';
import GardenDetailsView from 'modules/Garden/view/Details';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';

export const ROUTE_GARDEN = '/garden/list';
export const ROUTE_GARDEN_DETAILS = '/garden/details/:id';
export const ROUTE_GARDEN_DETAILS_REDIRECT_TEMPLATE = '/garden/details/';

export const createGardenRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="garden-list"
      path={ROUTE_GARDEN}
      exact
      component={GardenListView}
    />,
    <Route
      key="garden-details"
      path={ROUTE_GARDEN_DETAILS}
      exact
      render={(props) => (
        <GardenDetailsView {...props} key={props.match.params.id} />
      )}
      // component={GardenDetailsView}
    />
  ]
});
