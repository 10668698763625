import { MediaEntity } from 'modules/Media/model/Media';
import { Action } from 'redux';

export const SET_MEDIA_DETAILS_PARAMS = 'MEDIA/DETAILS/PARAMS/SET';
export const FETCH_MEDIA_DETAILS = 'MEDIA/DETAILS/FETCH';
export const MEDIA_DETAILS_FETCHED = 'MEDIA/DETAILS/FETCHED';
export const MEDIA_DETAILS_DELETED = 'MEDIA/DETAILS/DELETED';

export type MediaDetailsAction =
  | SetMediaDetailsParamsAction
  | FetchMediaDetailsAction
  | MediaDetailsFetchedAction
  | MediaDetailsDeletedAction;

export type SetMediaDetailsParamsAction = {
  type: typeof SET_MEDIA_DETAILS_PARAMS;
  payload: number;
};

export const setMediaDetailsParamsAction = (
  payload: number
): SetMediaDetailsParamsAction => {
  return { type: SET_MEDIA_DETAILS_PARAMS, payload };
};

export type FetchMediaDetailsAction = Action<typeof FETCH_MEDIA_DETAILS>;

export const fetchMediaDetailsAction = (): FetchMediaDetailsAction => ({
  type: FETCH_MEDIA_DETAILS
});

export type MediaDetailsFetchedPayload = {
  mediaDetails: MediaEntity;
};

export interface MediaDetailsFetchedAction
  extends Action<typeof MEDIA_DETAILS_FETCHED> {
  payload: MediaDetailsFetchedPayload;
}

export const mediaDetailsFetchedAction = (
  payload: MediaDetailsFetchedPayload
): MediaDetailsFetchedAction => ({
  type: MEDIA_DETAILS_FETCHED,
  payload
});

export type MediaDetailsDeletedAction = {
  type: typeof MEDIA_DETAILS_DELETED;
};

export const mediaDetailsDeletedAction = (): MediaDetailsDeletedAction => ({
  type: MEDIA_DETAILS_DELETED
});
