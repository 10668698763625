import MissingFamilyListView from 'modules/MissingFamily/view/List';
import MissingFamilyDetailsView from 'modules/MissingFamily/view/Details';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';

export const ROUTE_MISSING_FAMILY = '/missing-family/list';
export const ROUTE_MISSING_FAMILY_DETAILS = '/missing-family/details/:id';
export const ROUTE_MISSING_FAMILY_DETAILS_REDIRECT_TEMPLATE =
  '/missing-family/details/';

export const createMissingFamilyRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="missing-family-list"
      path={ROUTE_MISSING_FAMILY}
      exact
      component={MissingFamilyListView}
    />,
    <Route
      key="missing-family-details"
      path={ROUTE_MISSING_FAMILY_DETAILS}
      exact
      render={(props) => (
        <MissingFamilyDetailsView {...props} key={props.match.params.id} />
      )}
      //component={MissingFamilyDetailsView}
    />
  ]
});
