import React, { Component, ReactNode } from 'react';
import User from 'modules/User/model/User';
import BaseModal from 'modules/Layout/component/Modal';
import InformationList from 'modules/Layout/component/InformationList';
import { Button } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import { updateUserRole } from 'modules/User/repository';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  changeUserRoleToastError,
  changeUserRoleToastSuccess
} from 'modules/User/toasts';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onSuccess?: () => void;
  onError?: () => void;
  role: 'admin' | 'user';
}

export interface State {
  user: User;
  open: boolean;
  updating: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class ChangeUserRoleModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: null,
      open: false,
      updating: false
    };

    this.renderBody = this.renderBody.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  open(user: User): void {
    this.setState({
      open: true,
      user,
      updating: false
    });
  }

  close(): void {
    this.setState({
      open: false
    });
  }

  renderTitle(): ReactNode {
    const { role } = this.props;

    if (role === 'admin') {
      return 'Do you want to make this user administrator?';
    }

    return 'Do you want to take administrator from this user?';
  }

  renderBody(): ReactNode {
    const { user, updating } = this.state;

    const fields = [
      {
        label: 'Username:',
        value: user.username
      },
      {
        label: 'Email:',
        value: user.email
      },
      {
        label: 'Status:',
        value: user.getStatus()
      }
    ];

    return (
      <div>
        {updating && <Loader />}
        <InformationList fields={fields} />
      </div>
    );
  }

  renderFooter(): ReactNode {
    const { role } = this.props;
    const { updating } = this.state;

    return (
      <div className="d-flex w-100 justify-content-between">
        <Button
          color="primary"
          disabled={updating}
          onClick={this.close}
          className="mr-2"
        >
          Cancel
        </Button>
        <Button
          color={role === 'admin' ? 'success' : 'danger'}
          disabled={updating}
          onClick={this.onSubmit}
        >
          {role === 'admin' ? 'Make administrator' : 'Take administrator'}
        </Button>
      </div>
    );
  }

  async onSubmit(): Promise<void> {
    const { onSuccess, onError, addToast, role } = this.props;
    const { user } = this.state;

    try {
      this.setState({
        updating: true
      });

      await updateUserRole(user.id, role);

      if (onSuccess) {
        onSuccess();
      }

      addToast(changeUserRoleToastSuccess());
    } catch (error) {
      if (onError) {
        onError();
      }

      addToast(changeUserRoleToastError());
    } finally {
      this.close();
    }
  }

  render(): ReactNode {
    const { open, user } = this.state;

    if (!user) {
      return null;
    }

    return (
      <BaseModal
        isOpen={open}
        body={this.renderBody()}
        footer={this.renderFooter()}
        title={this.renderTitle()}
        toggle={this.close}
        onClosed={this.close}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(ChangeUserRoleModal);
