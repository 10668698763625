import React, { createRef, ReactNode, RefObject } from 'react';
import { PageProps } from 'modules/Layout/type';
import {
  AddToastAction,
  addToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteOrderPlants } from 'modules/News/breadcrumbs';
import { connect } from 'react-redux';
import { RootState } from 'app/reducer';
import ReorderList, { ListCol } from 'modules/Layout/component/ReorderList';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { Button } from 'reactstrap';
import {
  reorderPlantsNewsToastError,
  reorderPlantsNewsToastSuccess
} from 'modules/News/toasts';
import Loader from 'modules/Layout/component/Loader';
import { PlantEntity } from 'modules/Plants/model/Plant';
import AddPlantsNewsModal from 'modules/News/component/Modal/Add/Plant';
import {
  fetchOrderedPlantNews,
  updatePlantNewsOrder
} from 'modules/News/repository';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export type Props = StateProps & DispatchProps;

export interface State {
  plants: PlantEntity[];
  fetching: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error)),
  addToast: (payload) => dispatch(addToastAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

export class PlantsNewsOrderListView extends React.Component<Props, State> {
  readonly cols: ListCol<PlantEntity>[];
  readonly addPlantsModalRef: RefObject<AddPlantsNewsModal>;

  constructor(props: Props) {
    super(props);

    this.cols = [
      {
        property: 'images',
        label: 'Image',
        value: (row) => (
          <img src={row.images[0]?.thumb_path || ''} width="50px" alt="" />
        ),
        className: 'p-1 d-flex align-items-center justify-content-center'
      },
      {
        property: 'scientific_name',
        label: 'Scientific name',
        className: 'w-100 p-1 d-flex align-items-center font-weight-bold'
      }
    ];

    this.state = {
      plants: [],
      fetching: false
    };

    this.addPlantsModalRef = createRef();

    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
    this.openPlants = this.openPlants.bind(this);
    this.onAddPlants = this.onAddPlants.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { managePage } = this.props;

    managePage({
      title: 'Plants on home screen',
      type: 'loading',
      breadcrumb: breadcrumbRouteOrderPlants()
    });

    try {
      const {
        data: { plants }
      } = await fetchOrderedPlantNews();

      this.setState({
        plants
      });

      managePage({
        title: 'Plants on home screen',
        type: null
      });
    } catch (error) {
      manageThrowAction(error);
    }
  }

  async save(): Promise<void> {
    const { addToast } = this.props;
    const { plants } = this.state;

    const plantsToSave = plants
      .filter(
        (obj, index) =>
          plants.findIndex((plant) => obj.id === plant.id) === index
      )
      .map((plant) => plant.id);

    try {
      this.setState({
        fetching: true
      });

      await updatePlantNewsOrder(plantsToSave);

      this.setState({
        fetching: false
      });

      addToast(reorderPlantsNewsToastSuccess());
    } catch (error) {
      console.log(error);

      this.setState({
        fetching: false
      });

      addToast(reorderPlantsNewsToastError());
    }
  }

  onChange(plants: PlantEntity[]): void {
    this.setState({ plants });
  }

  openPlants(): void {
    this.addPlantsModalRef.current?.openModal();
  }

  onAddPlants(plants: PlantEntity[]): void {
    this.setState((state) => ({
      plants: [
        ...state.plants,
        ...plants.map((obj) => ({
          ...obj,
          images: [
            {
              id: 1,
              thumb_path: obj.thumb_path,
              origin_path: '',
              updated_at: '',
              created_at: ''
            }
          ]
        }))
      ]
    }));
  }

  render(): ReactNode {
    const { type } = this.props;
    const { fetching, plants } = this.state;

    if (type) return null;

    const filteredPlants = plants.filter(
      (obj, index) => plants.findIndex((plant) => obj.id === plant.id) === index
    );

    return (
      <>
        <AddPlantsNewsModal
          ref={this.addPlantsModalRef}
          onSuccess={this.onAddPlants}
        />
        <div
          className="card position-fixed"
          style={{ right: '20px', top: '220px' }}
        >
          <div className="card-body d-flex flex-column p-2">
            {fetching && <Loader />}
            <Button disabled={fetching} onClick={this.save} color="success">
              Save
            </Button>
            <Button
              className="mt-2"
              disabled={fetching}
              onClick={this.openPlants}
              color="secondary"
            >
              Add plants
            </Button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <ReorderList
                  accept="news"
                  items={filteredPlants}
                  cols={this.cols}
                  rowKey={(row) => row.id}
                  onChange={(newPlants) => this.onChange(newPlants)}
                  withDelete
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantsNewsOrderListView);
