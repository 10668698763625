import React, { FormEvent, ReactNode } from 'react';
import { Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import User, { createUser } from 'modules/User/model/User';
import { FormGroup, Label, Form, Input, Button } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import {
  AddToastAction,
  addToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { connect } from 'react-redux';
import { getPathUrl } from 'modules/Shared/helper/api';
import {
  ROUTE_ADMINISTRATOR_DETAILS,
  ROUTE_ADMINISTRATORS
} from 'modules/User/routes';
import { updateAdmin } from 'modules/User/repository';
import {
  updateAdminToastError,
  updateAdminToastSuccess
} from 'modules/User/toasts';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import ApiError from 'modules/Shared/exception/ApiError';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends RouteComponentProps, DispatchProps {
  user?: User;
}

export interface State {
  user: User;
  updating: boolean;
  error?: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload: AddToastPayload) => dispatch(addToastAction(payload))
});

class AdministratorForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { user } = props;

    this.state = {
      user: user || createUser({}),
      updating: false,
      error: null
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(value: never, key: keyof User): void {
    const { user } = this.state;

    user[key] = value;

    this.setState({
      user
    });
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const { history, addToast } = this.props;
    const { user } = this.state;

    this.setState({
      updating: true
    });

    try {
      await updateAdmin({
        id: user.id,
        username: user.username,
        email: user.email
      });

      addToast(updateAdminToastSuccess());

      history.push(getPathUrl(ROUTE_ADMINISTRATOR_DETAILS, { id: user.id }));
    } catch (error) {
      if (error instanceof ApiError) {
        this.setState({
          updating: false,
          error: error.getErrors() as string
        });

        addToast(updateAdminToastError());
      }
    }
  }

  render(): ReactNode {
    const { user: userProps, history } = this.props;
    const { user, updating, error } = this.state;

    return (
      <div className="row justify-content-center">
        <div className="col-md-4 card">
          {updating && <Loader />}
          <div className="card-body">
            <Form onSubmit={this.onSubmit}>
              {error && (
                <ShowMessage
                  message={error}
                  deleteMessage={() => this.setState({ error: null })}
                />
              )}
              <FormGroup>
                <Label>Username</Label>
                <Input
                  type="text"
                  value={user.username || ''}
                  onChange={(event) =>
                    this.onChange(event.target.value as never, 'username')
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>E-mail*</Label>
                <Input
                  type="email"
                  value={user.email || ''}
                  required
                  onChange={(event) =>
                    this.onChange(event.target.value as never, 'email')
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>New password</Label>
                <Input
                  type="password"
                  value={user.password || ''}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*)(\-+=]).{5,20}$"
                  title="Minimum length 5, maximum length 20, must contain at least one number and one uppercase and lowercase letter, and one special character !@#$%^&*)(\-+="
                  onChange={(event) =>
                    this.onChange(event.target.value as never, 'password')
                  }
                />
              </FormGroup>
              <FormGroup>
                <div className="mt-3 d-flex justify-content-between">
                  <Button
                    onClick={() =>
                      history.push(
                        getPathUrl(
                          userProps && userProps.id
                            ? ROUTE_ADMINISTRATOR_DETAILS
                            : ROUTE_ADMINISTRATORS,
                          {
                            id: userProps?.id
                          }
                        )
                      )
                    }
                    color="primary"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button color="success" type="submit">
                    {userProps && userProps.id ? 'Update' : 'CreateUpdate'}
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(AdministratorForm)
);
