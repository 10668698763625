import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteDetailsGarden } from 'modules/Garden/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { setGardenDetailsParamsAction } from 'modules/Garden/action/details';
import { RootState } from 'app/reducer';
import DetailsMain from 'modules/Garden/view/Details/components/DisplayDetailsMain';
import Loader from 'modules/Layout/component/Loader';
import { ROUTE_GARDEN } from 'modules/Garden/routes';

interface IParams {
  id: string;
}

export const GardenDetailsView = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector((state: RootState) => state.garden.details);
  const { type } = useSelector((state: RootState) => state.layout.page);

  useEffect(() => {
    dispatch(
      managePageAction({
        title: details?.gardenDetails ? details.gardenDetails.name : 'Garden',
        breadcrumb: breadcrumbRouteDetailsGarden(details.gardenDetails)
      })
    );
  }, [details.gardenDetails]);

  useEffect(() => {
    if (params?.id) {
      const id = parseInt(params.id, 10);
      if (id) dispatch(setGardenDetailsParamsAction(id));
      else history.push(ROUTE_GARDEN);
    }
  }, []);

  if (type) return null;
  if (details.fetching)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );
  return (
    <div className="position-relative">
      <DetailsMain data={details.gardenDetails} />
    </div>
  );
};

export default GardenDetailsView;
