import { BreadcrumbItem } from 'modules/Layout/type';
import { ROUTE_NEWS, ROUTE_NEWS_DETAILS } from 'modules/News/routes';
import News from 'modules/News/model/News';
import { getPathUrl } from 'modules/Shared/helper/api';

export const breadcrumbRouteNews = (): BreadcrumbItem[] => [
  {
    title: 'News',
    link: ROUTE_NEWS
  }
];

export const breadcrumbRouteAddNews = (): BreadcrumbItem[] => [
  {
    title: 'News',
    link: ROUTE_NEWS
  },
  {
    title: 'Create'
  }
];

export const breadcrumbRouteOrderPlants = (): BreadcrumbItem[] => [
  {
    title: 'Plants on home screen'
  }
];

export const breadcrumbRouteOrderNews = (): BreadcrumbItem[] => [
  ...breadcrumbRouteNews(),
  {
    title: 'Reorder'
  }
];

export const breadcrumbRouteDetailsNews = (news?: News): BreadcrumbItem[] => [
  {
    title: 'News',
    link: '/news/list'
  },
  {
    title: news ? news.title : 'Details'
  }
];

export const breadcrumbRouteFormDetailsNews = (
  news?: News
): BreadcrumbItem[] => [
  {
    title: 'News',
    link: '/news/list'
  },
  {
    title: news ? news.title : 'Details',
    link: news ? getPathUrl(ROUTE_NEWS_DETAILS, { id: news.id }) : null
  },
  {
    title: 'Edit'
  }
];
