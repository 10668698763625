import { RootState } from 'app/reducer';
import { setLocationSearchAction } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_MISSING_FAMILY_LIST,
  fetchMissingFamilyListAction,
  SET_MISSING_FAMILY_LIST_PARAMS,
  missingFamilyListFetchedAction
} from 'modules/MissingFamily/action/List';
import {
  fetchMissingFamily,
  FetchFamiliesResponse
} from 'modules/MissingFamily/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';

export function* setMissingFamilyListParamsSaga(): SagaIterator {
  yield put(fetchMissingFamilyListAction());
}

export function* fetchMissingFamilyListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.missingFamily.list
    );

    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    let response: FetchFamiliesResponse = yield call(fetchMissingFamily, {
      ...sort,
      pagination,
      search: filter
    });

    const { content, totalCount } = response.data;
    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount?.[0]?.['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      missingFamilyListFetchedAction({
        families: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        missingFamilyListFetchedAction({ families: [], totalPages: 1 })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantListSaga(): SagaIterator {
  yield takeLatest(
    SET_MISSING_FAMILY_LIST_PARAMS,
    setMissingFamilyListParamsSaga
  );
  yield takeLatest(FETCH_MISSING_FAMILY_LIST, fetchMissingFamilyListSaga);
}
