import { SettableAuthState } from 'modules/Auth/state';
import { Credentials } from 'modules/Auth/type';
import { Message } from 'modules/Shared/type';
import { UserEntity } from 'modules/User/model/User';
import { Action } from 'redux';

export const AUTHENTICATE = 'AUTH/AUTHENTICATE';
export const AUTHENTICATED = 'AUTH/AUTHENTICATED';
export const LOGIN = 'AUTH/LOGIN';
export const LOGOUT = 'AUTH/LOGOUT';
export const SET_AUTH_USER = 'AUTH/USER/SET';
export const SET_AUTH_STATE = 'AUTH/STATE/SET';

export type AuthAction =
  | AuthenticateAction
  | AuthenticatedAction
  | SetAuthUserAction
  | LoginAction
  | LogoutAction
  | SetAuthStateAction;

export type AuthenticateAction = Action<typeof AUTHENTICATE>;

export const authenticateAction = (): AuthenticateAction => ({
  type: AUTHENTICATE
});

export type AuthenticatedAction = Action<typeof AUTHENTICATED>;

export const authenticatedAction = (): AuthenticatedAction => ({
  type: AUTHENTICATED
});

export interface SetAuthUserAction extends Action<typeof SET_AUTH_USER> {
  payload: UserEntity;
}

export const setAuthUserAction = (payload: UserEntity): SetAuthUserAction => ({
  type: SET_AUTH_USER,
  payload
});

export interface LoginAction extends Action<typeof LOGIN> {
  payload: Credentials;
}

export const loginAction = (payload: Credentials): LoginAction => ({
  type: LOGIN,
  payload
});

export interface LogoutPayload {
  message?: Message;
}

export interface LogoutAction extends Action<typeof LOGOUT> {
  payload: LogoutPayload;
}

export const logoutAction = (payload: LogoutPayload = {}): LogoutAction => ({
  type: LOGOUT,
  payload
});

export interface SetAuthStateAction extends Action<typeof SET_AUTH_STATE> {
  payload: SettableAuthState;
}

export const setAuthStateAction = (
  payload: SettableAuthState
): SetAuthStateAction => ({
  type: SET_AUTH_STATE,
  payload
});
