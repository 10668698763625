import UserHubsListView from 'modules/Hub/view/List';
import HubSoftwareListView from 'modules/Hub/view/ListSoftware';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import HubDetailsView from 'modules/Hub/view/Details';

export const ROUTE_HUBS = '/hubs/list';
export const ROUTE_HUB_SOFTWARES = '/hub-softwares';
export const ROUTE_HUB_DETAILS = '/hubs/details/:id';
export const ROUTE_HUB_DETAILS_REDIRECT = '/hubs/details/';

export const createHubRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="hubs-list"
      path={ROUTE_HUBS}
      exact
      component={UserHubsListView}
    />,
    <Route
      key="hub-softwares"
      path={ROUTE_HUB_SOFTWARES}
      exact
      component={HubSoftwareListView}
    />,
    <Route
      key="hub-details"
      path={ROUTE_HUB_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <HubDetailsView {...props} id={props.match.params.id} />
      )}
    />
  ]
});
