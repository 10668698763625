import Autocomplete, {
  AutocompleteOmitProps,
  AutocompleteOption
} from 'modules/Layout/component/Autocomplete';
import React, { ReactNode } from 'react';
import ApiError from 'modules/Shared/exception/ApiError';
import {
  fetchPlantGroupsAll,
  FetchPlantGroupsAllPayload
} from 'modules/PlantGroups/repository';
import PlantGroups from '../../model/PlantGroups';

export interface AutocompletePlantGroup {
  id?: number;
  name?: string;
}

export interface Props extends AutocompleteOmitProps {
  plantGroupId?: number | string;
  disabledOption?: (plantGroup: AutocompletePlantGroup) => boolean;
  onChangeObject?: (plantGroup: PlantGroups) => void;
}

export interface State {
  plantGroups?: AutocompletePlantGroup[];
  error?: ApiError;
  fetching?: boolean;
}

class PlantGroupsAutocomplete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fetching: false,
      plantGroups: []
    };

    this.toOption = this.toOption.bind(this);
    this.fetchPlantGroups = this.fetchPlantGroups.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  toOption(
    plantGroupId?: number | string,
    disabledOption?: (plantGroup: AutocompletePlantGroup) => boolean
  ): AutocompleteOption {
    const { plantGroups } = this.state;

    const foundPlantGroup = plantGroups.find(
      (family) => String(family.id) === String(plantGroupId)
    );

    if (!foundPlantGroup) {
      return {
        label: '',
        value: ''
      };
    }

    return {
      label: foundPlantGroup.name,
      value: foundPlantGroup.id.toString(),
      disabled: disabledOption ? disabledOption(foundPlantGroup) : false
    };
  }

  async fetchPlantGroups(payload: FetchPlantGroupsAllPayload): Promise<void> {
    try {
      this.setState({
        fetching: true
      });

      const {
        data: { content }
      } = await fetchPlantGroupsAll(payload);

      this.setState({
        plantGroups: content
      });
    } catch (error) {
      this.setState({
        error
      });
    } finally {
      this.setState({
        fetching: false
      });
    }
  }

  render(): ReactNode {
    const {
      onChange,
      onChangeObject,
      plantGroupId,
      required = false,
      disabled = false,
      disabledOption = null,
      closeOnSelect,
      label = 'Choose category'
    } = this.props;
    const { plantGroups, fetching, error } = this.state;

    const plantGroupsOptions = plantGroups.map((plantGroup) =>
      this.toOption(plantGroup.id, disabledOption)
    );

    return (
      <Autocomplete
        onChange={(id) => {
          if (onChange) {
            onChange(id);
          }
          if (onChangeObject) {
            const foundPlantGroup = plantGroups.find(
              (plantGroup) => Number(plantGroup.id) === Number(id)
            );
            onChangeObject(foundPlantGroup);
          }
        }}
        onReset={async () => this.fetchPlantGroups({ search: {} })}
        fetching={fetching}
        error={error}
        onSearch={async (name) => this.fetchPlantGroups({ search: { name } })}
        value={this.toOption(plantGroupId)}
        options={plantGroupsOptions}
        required={required}
        disabled={disabled}
        label={label}
        closeOnSelect={closeOnSelect}
      />
    );
  }
}

export default PlantGroupsAutocomplete;
