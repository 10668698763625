import PlantUserDetails from 'modules/PlantUser/model/PlantUserDetails';

export interface PlantUserDetailsState {
  plantUserDetails: PlantUserDetails;
  fetching: boolean;
  plantUserId: number;
}

export const initPlantUserDetailsState = (): PlantUserDetailsState => ({
  plantUserDetails: null,
  fetching: false,
  plantUserId: null
});
