import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import {
  breadcrumbRouteFormDetailsFamily,
  breadcrumbRouteFormDetailsThresholdsFamily
} from 'modules/Family/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { setFamilyDetailsParamsAction } from 'modules/Family/action/Details';
import { RootState } from 'app/reducer';

import {
  acceptableTypesArray,
  fillArrayOfFieldDesc
} from 'modules/Family/view/DetailsForm/helper/acceptableTypes';
import DetailsForm, { IOnSubmitAction } from 'modules/Layout/view/DetailsForm';
import {
  ROUTE_FAMILY,
  ROUTE_FAMILY_DETAILS_REDIRECT_TEMPLATE
} from 'modules/Family/routes';
import {
  handleChangeRequest,
  handleChangeErrorResponse
} from 'modules/Family/view/DetailsForm/helper/handleFamilyDetailsCommunication';
import Loader from 'modules/Layout/component/Loader';
import useCancelToken from 'modules/Shared/helper/useCancelToken';
import { BreadcrumbItem } from 'modules/Layout/type';

interface IParams {
  id: string;
  type: string;
}

interface IProps {
  data: { [key: string]: string | boolean | number };
  serverEndpoint: string;
  title: string;
}

export const FamilyDetailsForm: React.FC<IProps> = ({}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const params = useParams<IParams>();
  const history = useHistory();
  const details = useSelector((state: RootState) => state.family.details);
  const { type } = useSelector((state: RootState) => state.layout.page);

  useEffect(() => {
    let title = '';
    let breadcrumb: BreadcrumbItem[] = [];

    if (params?.type === 'main') {
      title = details?.familyDetails
        ? `${details.familyDetails.genus} - edit`
        : 'Family - edit';
      breadcrumb = breadcrumbRouteFormDetailsFamily(details.familyDetails);
    } else {
      title = details?.familyDetails
        ? `${details.familyDetails.genus} - edit thresholds`
        : 'Family - edit thresholds';
      breadcrumb = breadcrumbRouteFormDetailsThresholdsFamily(
        details.familyDetails
      );
    }

    dispatch(
      managePageAction({
        title,
        breadcrumb
      })
    );
  }, [details.familyDetails]);

  useEffect(() => {
    if (params?.type && params?.id) {
      const id = parseInt(params.id, 10);
      if (!acceptableTypesArray.includes(params.type) || !id) {
        history.push(ROUTE_FAMILY);
      } else {
        if (id !== details?.familyId) {
          dispatch(setFamilyDetailsParamsAction(id));
        }
      }
    }
  }, []);

  const cancelToken = useCancelToken();
  const onSubmitBtnClick = async (data: IOnSubmitAction) => {
    const id = parseInt(params?.id) || null;
    if (!loading) {
      setLoading(true);
      if (!data?.editType || !id) {
        setError('Web panel error. No id and/or type found.');
      } else {
        try {
          const response = await handleChangeRequest(
            {
              data,
              details,
              id
            },
            cancelToken
          );

          if (response.status === 200) {
            setLoading(false);
            history.push(ROUTE_FAMILY_DETAILS_REDIRECT_TEMPLATE + params?.id);
          }
        } catch (err) {
          if (err?.message !== 'AxiosCancel') {
            setLoading(false);
            setError(
              handleChangeErrorResponse(
                err?.error?.response?.data?.statusCode,
                err?.error?.response?.data?.errors
              )
            );
          }
        }
      }
    }
  };

  const deleteError = () => {
    setError('');
  };

  let data = null;
  if (params?.type === 'main') {
    data = details.familyDetails;
  } else if (params?.type === 'thresholds') {
    data = details.familyDetails?.thresholds;
  }

  if (type) return null;
  if (details.fetching)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );
  return (
    <>
      {data !== undefined ? (
        <DetailsForm
          data={data as any}
          arrayOfFieldDesc={fillArrayOfFieldDesc(params?.type, null)}
          onSubmitAction={onSubmitBtnClick}
          onCancelAction={() =>
            history.push(ROUTE_FAMILY_DETAILS_REDIRECT_TEMPLATE + params?.id)
          }
          error={error}
          deleteError={deleteError}
          editType={params?.type}
          loading={loading}
        />
      ) : (
        <div className="card p-3">This type is not supported.</div>
      )}
    </>
  );
};

export default FamilyDetailsForm;
