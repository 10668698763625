import {
  FETCH_GARDEN_LIST,
  SET_GARDEN_LIST_PARAMS,
  GARDEN_LIST_FETCHED,
  GardenListAction
} from 'modules/Garden/action/list';
import { createGarden } from 'modules/Garden/model/Garden';
import {
  initGardenListState,
  GardenListState
} from 'modules/Garden/state/list';

const initState = initGardenListState();

const reducer = (
  state: GardenListState = initState,
  action: GardenListAction
): GardenListState => {
  switch (action.type) {
    case SET_GARDEN_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_GARDEN_LIST:
      return { ...state, fetching: true };

    case GARDEN_LIST_FETCHED: {
      const { gardens = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        gardens: gardens.map(createGarden),
        totalPages: action.payload.totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
