import { BreadcrumbItem } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_HUBS } from 'modules/Hub/routes';
import Hub from 'modules/Hub/model/Hub';

export const breadcrumbRouteHubs = (): BreadcrumbItem[] => [
  {
    title: 'Hubs',
    link: getPathUrl(ROUTE_HUBS)
  }
];

export const breadcrumbRouteHubSoftwares = (): BreadcrumbItem[] => [
  {
    title: 'Hub software'
  }
];

export const breadcrumbRouteHubDetails = (hub?: Hub): BreadcrumbItem[] => [
  ...breadcrumbRouteHubs(),
  {
    title: hub ? `Hub - ${hub.hub_id}` : 'Hub'
  }
];
