import {
  primaryInfoArr,
  getDescriptionLang
} from 'modules/Plants/view/Details/helper/DetailsConfig';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import { thresholdArr } from 'modules/Shared/configs/tresholdsEntity/detailsArr';

export const PLANT_DETAILS_MAIN = 'main';
export const PLANT_DETAILS_DESCRIPTION = 'description';
export const PLANT_DETAILS_DESCRIPTION_BASE = 'description_base';
export const PLANT_DETAILS_DESCRIPTION_OTHER = 'description_other';
export const PLANT_DETAILS_THRESHOLD = 'thresholds';
export const PLANT_DETAILS_MEASUREMENT_DESC = 'measurement_desc';

export const acceptableTypesArray = [
  PLANT_DETAILS_MAIN,
  PLANT_DETAILS_DESCRIPTION,
  PLANT_DETAILS_THRESHOLD,
  PLANT_DETAILS_MEASUREMENT_DESC
];

export const accetableOptionsForDescriptionArray = ['base', 'desc', 'other'];

export type IType = typeof PLANT_DETAILS_MAIN | typeof PLANT_DETAILS_THRESHOLD;

export const fillArrayOfFieldDesc = (
  type: string,
  data: PlantDetails,
  lang?: string,
  option?: string
) => {
  console.log(option);
  switch (type) {
    case PLANT_DETAILS_MAIN:
      return primaryInfoArr(data);
    case PLANT_DETAILS_DESCRIPTION:
      return getDescriptionLang(data, lang, option);
    case PLANT_DETAILS_THRESHOLD:
      return thresholdArr(data?.thresholds);
    default:
      return [];
  }
};
