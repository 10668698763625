import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal, { Props } from 'modules/Layout/component/Modal';
import { StandardBtn } from 'modules/Layout/view/Buttons/StandardBtn';
import { deletePlantImage } from 'modules/Plants/repository';
import { plantDetailsUpdatedAction } from 'modules/Plants/action/Details';
import { RootState } from 'app/reducer';
import { ImagesObject } from 'modules/Plants/model/PlantDetails';
import useCancelToken from 'modules/Shared/helper/useCancelToken';

interface IProps extends Props {
  imgId: number;
  plantId: number;
  loader: boolean;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMessage?: React.Dispatch<React.SetStateAction<string>>;
}

const DisplayRemoveImageModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  let plantDetails = useSelector(
    (state: RootState) => state.plant.details.plantDetails
  );
  const { loader, setLoader, imgId, setShowMessage } = props;

  const cancelToken = useCancelToken();
  const onDeleteClick = async () => {
    if (loader) return;
    try {
      setLoader(true);
      const response = await deletePlantImage(imgId, cancelToken);
      if (response.status >= 200 && response.status < 300) {
        const { images, ...restPlantDetails } = plantDetails;
        const newImages: ImagesObject[] = [];

        images?.forEach((image) => {
          if (image.id !== imgId) newImages.push(image);
        });
        if (images) {
          dispatch(
            plantDetailsUpdatedAction({
              ...restPlantDetails,
              images: newImages
            })
          );
          setShowMessage('Successfully deleted image.');
        }
      }
      setLoader(false);
      props.onClosed();
    } catch (err) {
      if (err?.message !== 'AxiosCancel') {
        const standardError =
          "Sorry, something went wrong and we couldn't delete this image. If you see this message multiple times contact with your administrator.";
        setShowMessage(err?.error?.response?.data?.errors || standardError);
        setLoader(false);
        props.onClosed();
      }
    }
  };

  const displayBtn = () => {
    return (
      <>
        <StandardBtn text="No" bsColor="danger" callback={props.onClosed} />
        <StandardBtn
          text="Yes"
          bsColor="success"
          loading={loader}
          callback={onDeleteClick}
        />
      </>
    );
  };

  const bodyText = () => (
    <div className="text-center h5">
      Are you sure? Deleting this image will be irreversible.
    </div>
  );

  return <Modal {...props} body={bodyText()} footer={displayBtn()} />;
};

export default DisplayRemoveImageModal;
