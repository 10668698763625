import {
  FETCH_FAMILY_LIST,
  SET_FAMILY_LIST_PARAMS,
  FAMILY_LIST_FETCHED,
  FamilyListAction
} from 'modules/Family/action/List';
import { createFamily } from 'modules/Family/model/Family';
import {
  initFamilyListState,
  FamilyListState
} from 'modules/Family/state/list';

const initState = initFamilyListState();

const reducer = (
  state: FamilyListState = initState,
  action: FamilyListAction
): FamilyListState => {
  switch (action.type) {
    case SET_FAMILY_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_FAMILY_LIST:
      return { ...state, fetching: true };

    case FAMILY_LIST_FETCHED: {
      const { families = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        families: families.map(createFamily),
        totalPages: action.payload.totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
