import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import Media from 'modules/Media/model/Media';
import React, { createRef, ReactNode } from 'react';
import { connect } from 'react-redux';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setMediaAdvancementListParamsAction,
  SetMediaAdvancementListParamsAction
} from 'modules/Media/action/List';
import { Dispatch } from 'redux';
import { getPathUrl } from 'modules/Shared/helper/api';
import {
  ROUTE_MEDIA_ADVANCEMENT_DETAILS,
  ROUTE_MEDIA_ADVANCEMENT_EDIT
} from 'modules/Media/routes';
import ActionShow from 'modules/Layout/component/Action/Show';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import MediaAdvancement from 'modules/Media/model/MediaAdvancement';
import DeleteMediaAdvancementModal, {
  DeleteMediaAdvancementModal as DeleteMediaAdvancementModalClass
} from 'modules/Media/component/Modal/Delete/Advancement';

export interface StateProps {
  mediaAdvancement: MediaAdvancement[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetMediaAdvancementListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (media: Media) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { mediaAdvancement, sort } = state.media.list;

  return { mediaAdvancement, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setMediaAdvancementListParamsAction(payload))
});

export class MediaAdvancementTable extends React.Component<Props> {
  readonly cols: TableCol<MediaAdvancement>[];
  readonly deleteModalRef: React.RefObject<DeleteMediaAdvancementModalClass>;

  constructor(props: Props) {
    super(props);

    this.deleteModalRef = createRef();

    this.cols = [
      {
        property: 'name',
        label: 'Name',
        className: 'col-8',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_MEDIA_ADVANCEMENT_DETAILS, { id: row.id })
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show media advancement"
              to={getPathUrl(ROUTE_MEDIA_ADVANCEMENT_DETAILS, { id: row.id })}
            />
            <ActionUpdate
              title="Edit media advancement"
              to={getPathUrl(ROUTE_MEDIA_ADVANCEMENT_EDIT, { id: row.id })}
            />
            <ActionDelete
              title="Delete media advancement"
              onClick={() => this.deleteModalRef?.current?.openModal(row)}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { mediaAdvancement, sort, setParams } = this.props;

    return (
      <>
        <DeleteMediaAdvancementModal
          ref={this.deleteModalRef}
          onSuccess={() => setParams({ sort })}
        />
        <Table
          cols={this.cols}
          rows={mediaAdvancement}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(MediaAdvancementTable);
