import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { useSelector } from 'react-redux';
import Plant from 'modules/Plants/model/Plant';
import { ROUTE_PLANTS_DETAILS_REDIRECT_TEMPLATE } from 'modules/Plants/routes';

export const FamilyDetailsPlantsTable = () => {
  const plants = useSelector((state: RootState) => state.plant.list.plants);

  const cols: TableCol<Plant>[] = [
    {
      property: 'thumb_path',
      label: 'Image',
      type: 'img',
      value: (row) => row.thumb_path
    },
    {
      property: 'scientific_name',
      label: 'Scientific name',
      className: 'col-4'
    },
    {
      property: 'name',
      label: 'Name',
      className: 'col-3'
    }
  ];

  return (
    <div>
      <Table
        cols={cols}
        rows={plants}
        onRowClickPath={ROUTE_PLANTS_DETAILS_REDIRECT_TEMPLATE}
      />
    </div>
  );
};
