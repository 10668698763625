import React, { ReactNode } from 'react';
import PlantGroups from 'modules/PlantGroups/model/PlantGroups';
import BaseModal from 'modules/Layout/component/Modal';
import InformationList from 'modules/Layout/component/InformationList';
import { Button } from 'reactstrap';
import { deletePlantGroup } from 'modules/PlantGroups/repository';
import Loader from 'modules/Layout/component/Loader';
import {
  AddToastAction,
  addToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import {
  deletePlantGroupToastError,
  deletePlantGroupToastSuccess
} from 'modules/PlantGroups/toasts';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onDeleted?: () => void;
}

export interface State {
  plantGroup: PlantGroups;
  isOpen: boolean;
  deleting: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class DeletePlantGroupModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      plantGroup: null,
      isOpen: false,
      deleting: false
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  open(plantGroup: PlantGroups): void {
    this.setState({
      plantGroup,
      isOpen: true,
      deleting: false
    });
  }

  close(): void {
    this.setState({
      isOpen: false,
      deleting: false
    });
  }

  renderTitle(): ReactNode {
    return 'Do you want to delete plant group?';
  }

  renderBody(): ReactNode {
    const { plantGroup, deleting } = this.state;

    const fields = [
      {
        label: 'Name:',
        value: plantGroup.name
      },
      {
        label: 'Plants count:',
        value: plantGroup.plants_count
      }
    ];

    return (
      <>
        {deleting && <Loader />}
        <InformationList fields={fields} />
      </>
    );
  }

  async onSubmit(): Promise<void> {
    const { onDeleted, addToast } = this.props;
    const { plantGroup } = this.state;

    try {
      this.setState({
        deleting: true
      });

      await deletePlantGroup(plantGroup.id);

      addToast(deletePlantGroupToastSuccess());
    } catch (error) {
      console.log(error);
      addToast(deletePlantGroupToastError());
    } finally {
      this.close();
      if (onDeleted) {
        onDeleted();
      }
    }
  }

  renderFooter(): ReactNode {
    const { deleting } = this.state;

    return (
      <div className="w-100 d-flex justify-content-between">
        <Button disabled={deleting} onClick={this.close} color="primary">
          Cancel
        </Button>
        <Button disabled={deleting} onClick={this.onSubmit} color="danger">
          Delete
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen, plantGroup } = this.state;

    if (!plantGroup) {
      return null;
    }

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.close}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(DeletePlantGroupModal);
