import { BreadcrumbItem } from 'modules/Layout/type';
import {
  ROUTE_PP_DETAILS,
  ROUTE_PP_LIST,
  ROUTE_TOS_DETAILS,
  ROUTE_TOS_LIST
} from 'modules/Policy/routes';
import TermsOfService from 'modules/Policy/model/TermsOfService';
import { getPathUrl } from 'modules/Shared/helper/api';
import PrivacyPolicy from 'modules/Policy/model/PrivacyPolicy';

export const breadcrumbRouteTermsOfServices = (): BreadcrumbItem[] => [
  {
    title: 'Terms of services',
    link: ROUTE_TOS_LIST
  }
];

export const breadcrumbRouteTermsOfService = (
  termsOfService?: TermsOfService
): BreadcrumbItem[] => [
  ...breadcrumbRouteTermsOfServices(),
  {
    title: 'Terms of service',
    link: termsOfService
      ? getPathUrl(ROUTE_TOS_DETAILS, { id: termsOfService.id })
      : null
  }
];

export const breadcrumbRouteCreateTermsOfService = (): BreadcrumbItem[] => [
  ...breadcrumbRouteTermsOfServices(),
  {
    title: 'Create'
  }
];

export const breadcrumbRouteEditTermsOfService = (
  termsOfService?: TermsOfService
): BreadcrumbItem[] => [
  ...breadcrumbRouteTermsOfService(termsOfService),
  {
    title: 'Edit'
  }
];

export const breadcrumbRoutePrivacyPolicies = (): BreadcrumbItem[] => [
  {
    title: 'Privacy policies',
    link: ROUTE_PP_LIST
  }
];

export const breadcrumbRoutePrivacyPolicy = (
  pp?: PrivacyPolicy
): BreadcrumbItem[] => [
  ...breadcrumbRoutePrivacyPolicies(),
  {
    title: 'Privacy policy',
    link: pp ? getPathUrl(ROUTE_PP_DETAILS, { id: pp.id }) : null
  }
];

export const breadcrumbRouteCreatePrivacyPolicy = (): BreadcrumbItem[] => [
  ...breadcrumbRoutePrivacyPolicies(),
  {
    title: 'Create'
  }
];
