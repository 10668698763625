import React from 'react';
import { userPlantInfoArr } from 'modules/PlantUser/view/Details/helper/DetailsConfig';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import PlantUserDetails from 'modules/PlantUser/model/PlantUserDetails';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import ProtectedImage from 'modules/Layout/component/ProtectedImage';

interface IProps {
  data: PlantUserDetails;
}

const Details: React.FC<IProps> = ({ data }: IProps) => {
  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="User plant">
        <div className="tab-content mt-3 d-flex">
          <div
            className="position-relative d-flex justify-content-center align-items-center pr-2"
            style={{ minWidth: '250px' }}
          >
            <ProtectedImage url={data?.origin_path} width={200} />
          </div>
          <div className="w-100">
            <div className="row">
              {displayOptionRowContent(
                userPlantInfoArr(data),
                null,
                'col-md-6'
              )}
            </div>
          </div>
        </div>
      </DisplayBaseTabContent>
    </div>
  );
};

export default Details;
