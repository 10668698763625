import { RootState } from 'app/reducer';
import { setLocationSearchAction, manageThrow } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_PLANT_USER_LIST,
  fetchPlantUserListAction,
  SET_PLANT_USER_LIST_PARAMS,
  plantUserListFetchedAction
} from 'modules/PlantUser/action/list';
import {
  fetchPlantUser,
  FetchPlantUserResponse
} from 'modules/PlantUser/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { AxiosResponse } from 'axios';

export function* setPlantUserListParamsSaga(): SagaIterator {
  yield put(fetchPlantUserListAction());
}

export function* fetchPlantUserListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.plantUser.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const mappedFilter = {
      ...filter
    };

    if (mappedFilter.has_sensor) {
      if (mappedFilter.has_sensor === 'false') mappedFilter.has_sensor = false;
      if (mappedFilter.has_sensor === 'true') mappedFilter.has_sensor = true;
    }

    if (mappedFilter.status) {
      mappedFilter.status = parseInt(mappedFilter.status, 10);

      delete Object.assign(mappedFilter, {
        user_plant_status: mappedFilter.status
      }).status;
    }

    const response: AxiosResponse<FetchPlantUserResponse> = yield call(
      fetchPlantUser,
      {
        ...sort,
        pagination,
        search: mappedFilter
      }
    );

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      plantUserListFetchedAction({
        plantUser: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(plantUserListFetchedAction({ plantUser: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantListSaga(): SagaIterator {
  yield takeLatest(SET_PLANT_USER_LIST_PARAMS, setPlantUserListParamsSaga);
  yield takeLatest(FETCH_PLANT_USER_LIST, fetchPlantUserListSaga);
}
