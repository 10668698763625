import React, { ReactNode } from 'react';
import { Button, Label, Input, FormGroup, FormFeedback } from 'reactstrap';
import SensorSoftware from 'modules/Sensor/model/SensorSoftware';
import BaseModal from 'modules/Layout/component/Modal';
import InformationList, {
  InformationField
} from 'modules/Layout/component/InformationList';
import Loader from 'modules/Layout/component/Loader';
import { Message, ValidationErrors } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface Props {
  onSubmit: (
    sensorSoftware: SensorSoftware,
    data: { version: string; required: boolean }
  ) => void;
  fetching: boolean;
  errorMessage?: Message | null;
  errors?: ValidationErrors;
}

export interface State {
  isOpen: boolean;
  sensorSoftware?: SensorSoftware;
  newVersion: string | null;
  required: boolean;
}

class SensorSoftwareEditModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      sensorSoftware: null,
      newVersion: null,
      required: null
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  openModal(sensorSoftware: SensorSoftware): void {
    const state: State = {
      isOpen: true,
      sensorSoftware,
      newVersion: sensorSoftware.version,
      required: sensorSoftware.required
    };

    this.setState(state);
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  renderTitle = (): React.ReactNode => {
    const { sensorSoftware } = this.state;

    if (sensorSoftware) {
      return `Edit sensor software - ${sensorSoftware.version}`;
    }

    return null;
  };

  renderBody(): React.ReactNode {
    const { fetching, errorMessage, errors } = this.props;
    const { sensorSoftware, newVersion, required } = this.state;

    if (sensorSoftware) {
      const fields: InformationField[] = [
        {
          label: 'Version:',
          value: sensorSoftware.version
        },
        {
          label: 'Software:',
          value: sensorSoftware.software
        },
        {
          label: 'Required:',
          value: sensorSoftware.getRequired()
        },
        {
          label: 'Published:',
          value: sensorSoftware.getPublished()
        },
        {
          label: 'Created at:',
          value: sensorSoftware.created_at
        },
        {
          label: 'Updated at:',
          value: sensorSoftware.updated_at
        }
      ];

      return (
        <>
          {errorMessage && <Alert message={errorMessage} />}
          {fetching && <Loader />}
          <InformationList fields={fields} />
          <fieldset className="mt-4">
            <FormGroup>
              <Label for="#new-version-software">Software version</Label>
              <Input
                id="new-version-software"
                type="text"
                value={newVersion || ''}
                onChange={(event) =>
                  this.setState({
                    newVersion: event.target.value
                  })
                }
                invalid={hasError(errors, 'version')}
              />
              {hasError(errors, 'version') && (
                <FormFeedback>{getError(errors, 'version')}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="#required-software" className="pl-3">
                <Input
                  id="required-software"
                  type="checkbox"
                  checked={required}
                  onChange={(event) =>
                    this.setState({
                      required: event.target.checked
                    })
                  }
                  invalid={hasError(errors, 'required')}
                />
                Is software required
              </Label>
              {hasError(errors, 'required') && (
                <FormFeedback className="d-block">
                  {getError(errors, 'required')}
                </FormFeedback>
              )}
            </FormGroup>
          </fieldset>
        </>
      );
    }

    return null;
  }

  renderFooter(): React.ReactNode {
    const { onSubmit } = this.props;
    const { sensorSoftware, newVersion, required } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button color="secondary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button
          color="success"
          onClick={() =>
            onSubmit(sensorSoftware, { version: newVersion, required })
          }
        >
          Update
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen } = this.state;

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default SensorSoftwareEditModal;
