import React, { FormEvent, ReactNode } from 'react';
import Plant from 'modules/Plants/model/Plant';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import FamiliesAutocomplete from 'modules/Family/container/Autocomplete';
import PlantGroupsAutocomplete from 'modules/PlantGroups/container/Autocomplete';
import Loader from 'modules/Layout/component/Loader';
import { updatePlantsDetails } from 'modules/Plants/repository';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  editPlantToastError,
  editPlantToastSuccess
} from 'modules/Plants/toasts';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANTS_DETAILS } from 'modules/Plants/routes';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends RouteComponentProps, DispatchProps {
  plant: Plant;
}

export interface State {
  values: {
    scientific_name?: string;
    family_id?: number;
    plant_group_one_id?: number;
    plant_group_two_id?: number;
  };
  fetching: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

class EditPlantForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { plant } = this.props;

    const [groupOne, groupTwo] = plant?.plant_groups;

    this.state = {
      values: {
        scientific_name: plant?.scientific_name,
        family_id: plant?.family_id,
        plant_group_one_id: groupOne?.id,
        plant_group_two_id: groupTwo?.id
      },
      fetching: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const { plant, addToast, history } = this.props;
    const {
      values: {
        scientific_name,
        family_id,
        plant_group_two_id,
        plant_group_one_id
      }
    } = this.state;

    this.setState({
      fetching: true
    });

    try {
      await updatePlantsDetails(plant.id, {
        scientific_name,
        family_id: family_id ? Number(family_id) : null,
        plant_groups: [
          Number(plant_group_one_id),
          Number(plant_group_two_id)
        ].filter((group) => Boolean(group))
      });

      addToast(editPlantToastSuccess());

      history.push(getPathUrl(ROUTE_PLANTS_DETAILS, { id: plant.id }));
    } catch (error) {
      console.log(error);
      this.setState({
        fetching: false
      });
      addToast(editPlantToastError());
    }
  }

  onChange(value: never, key: keyof State['values']): void {
    this.setState((state) => ({
      ...state,
      values: {
        ...state.values,
        [key]: value
      }
    }));
  }

  render(): ReactNode {
    const { history, plant } = this.props;
    const {
      values: {
        scientific_name,
        family_id,
        plant_group_one_id,
        plant_group_two_id
      },
      fetching
    } = this.state;

    return (
      <div className="row justify-content-center">
        <div className="card col-md-4 col-sm-8">
          <div className="card-body">
            {fetching && <Loader />}
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <Label>Scientific name*</Label>
                <Input
                  type="text"
                  value={scientific_name || ''}
                  onChange={(event) =>
                    this.onChange(
                      event.currentTarget.value as never,
                      'scientific_name'
                    )
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Genus</Label>
                <FamiliesAutocomplete
                  onChange={(newFamilyId) =>
                    this.onChange(newFamilyId as never, 'family_id')
                  }
                  familyId={family_id}
                />
              </FormGroup>
              <FormGroup>
                <Label>Category #1</Label>
                <PlantGroupsAutocomplete
                  onChange={(plantGroupId) =>
                    this.onChange(plantGroupId as never, 'plant_group_one_id')
                  }
                  plantGroupId={plant_group_one_id}
                  disabledOption={(plantGroup) =>
                    Number(plantGroup.id) === Number(plant_group_two_id)
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Category #2</Label>
                <PlantGroupsAutocomplete
                  onChange={(plantGroupId) =>
                    this.onChange(plantGroupId as never, 'plant_group_two_id')
                  }
                  plantGroupId={plant_group_two_id}
                  disabledOption={(plantGroup) =>
                    Number(plantGroup.id) === Number(plant_group_one_id)
                  }
                />
              </FormGroup>
              <FormGroup className="d-flex justify-content-between mb-0 mt-4">
                <Button
                  onClick={() =>
                    history.push(
                      getPathUrl(ROUTE_PLANTS_DETAILS, { id: plant.id })
                    )
                  }
                  type="button"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button type="submit" color="secondary">
                  Update
                </Button>
              </FormGroup>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(EditPlantForm)
);
