import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import SensorSoftware from 'modules/Sensor/model/SensorSoftware';
import BaseModal from 'modules/Layout/component/Modal';
import InformationList, {
  InformationField
} from 'modules/Layout/component/InformationList';
import Loader from 'modules/Layout/component/Loader';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';

export interface Props {
  onSubmit: (sensorSoftware: SensorSoftware) => void;
  fetching: boolean;
  errorMessage?: Message | null;
}

export interface State {
  isOpen: boolean;
  sensorSoftware?: SensorSoftware;
}

class SensorSoftwareDeleteModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      sensorSoftware: null
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  openModal(sensorSoftware: SensorSoftware): void {
    const state: State = {
      isOpen: true,
      sensorSoftware
    };

    this.setState(state);
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  renderTitle = (): React.ReactNode => {
    const { sensorSoftware } = this.state;

    if (sensorSoftware) {
      return `Delete sensor software - ${sensorSoftware.version}`;
    }

    return null;
  };

  renderBody(): React.ReactNode {
    const { fetching, errorMessage } = this.props;
    const { sensorSoftware } = this.state;

    if (sensorSoftware) {
      const fields: InformationField[] = [
        {
          label: 'Version:',
          value: sensorSoftware.version
        },
        {
          label: 'Software:',
          value: sensorSoftware.software
        },
        {
          label: 'Required:',
          value: sensorSoftware.getRequired()
        },
        {
          label: 'Published:',
          value: sensorSoftware.getPublished()
        },
        {
          label: 'Created at:',
          value: sensorSoftware.created_at
        },
        {
          label: 'Updated at:',
          value: sensorSoftware.updated_at
        }
      ];

      return (
        <>
          {errorMessage && <Alert message={errorMessage} />}
          {fetching && <Loader />}
          <InformationList fields={fields} />
        </>
      );
    }

    return null;
  }

  renderFooter(): React.ReactNode {
    const { onSubmit } = this.props;
    const { sensorSoftware } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button color="secondary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button color="danger" onClick={() => onSubmit(sensorSoftware)}>
          Delete
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen } = this.state;

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default SensorSoftwareDeleteModal;
