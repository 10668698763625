import PlantUserListView from 'modules/PlantUser/view/List';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import PlantUserDetailsView from 'modules/PlantUser/view/Details';

export const ROUTE_PLANT_USER = '/plant-user/list';
export const ROUTE_PLANT_USER_DETAILS = '/plant-user/details/:id';
export const ROUTE_PLANT_USER_DETAILS_REDIRECT_TEMPLATE =
  '/plant-user/details/';

export const createPlantUserRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="plant-user-list"
      path={ROUTE_PLANT_USER}
      exact
      component={PlantUserListView}
    />,
    <Route
      key="plant-user-details"
      path={ROUTE_PLANT_USER_DETAILS}
      exact
      render={(props) => (
        <PlantUserDetailsView {...props} key={props.match.params.id} />
      )}
      //component={PlantUserDetailsView}
    />
  ]
});
