import listReducer from 'modules/Plants/reducer/list';
import detailsReducer from 'modules/Plants/reducer/details';
import { combineReducers, Reducer } from 'redux';
import { PlantState } from 'modules/Plants/state';

const reducer = (): Reducer<PlantState> => {
  const reducers = {
    list: listReducer,
    details: detailsReducer
  };

  return combineReducers<PlantState>(reducers);
};

export default reducer;
