import React, { FormEvent, ReactNode } from 'react';
import {
  Input,
  Label,
  Form,
  Button,
  FormGroup,
  FormFeedback,
  CustomInput,
  FormText
} from 'reactstrap';
import ImageCropUpload from 'modules/Layout/component/ImageCropUpload';
import { PageProps, PageType } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Validation from 'modules/Shared/exception/Validation';
import { AxiosResponse } from 'axios';
import objectUrlToBase64 from 'modules/Shared/helper/image';
import Loader from 'modules/Layout/component/Loader';
import { ValidationPayload } from 'modules/Shared/type';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { getPathUrl } from 'modules/Shared/helper/api';
import {
  breadcrumbRouteAddNews,
  breadcrumbRouteFormDetailsNews
} from 'modules/News/breadcrumbs';
import {
  fetchAddNews,
  FetchAddNewsResponse,
  fetchNewsDetails,
  FetchNewsDetailsResponse,
  updateNewsDetails
} from 'modules/News/repository';
import { ROUTE_NEWS, ROUTE_NEWS_DETAILS } from 'modules/News/routes';
import { acceptableNewsTypesValues } from 'modules/News/model/News';
import {
  createNewsToastError,
  createNewsToastSuccess,
  updateNewsToastError,
  updateNewsToastSuccess
} from 'modules/News/toasts';
import { manageThrow, ManageThrowAction } from 'modules/Shared/action';
import { RootState } from 'app/reducer';

export interface StateProps {
  pageType: PageType;
}

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrowSaga: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps, RouteComponentProps {
  id?: string | number;
}

export interface State {
  title: string;
  description: string;
  type: string;
  section:
    | 'tutorials'
    | 'plant_of_the_week'
    | 'smart_gardening'
    | 'living_with_plants';
  url: string;
  image: string;
  fetching: boolean;
  errors?: ValidationPayload;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload)),
  manageThrowSaga: (error) => dispatch(manageThrow(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return {
    pageType: type
  };
};

class CreateUpdateNewsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      title: null,
      description: '',
      type: acceptableNewsTypesValues[0],
      url: null,
      section: null,
      image: null,
      fetching: false,
      errors: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.fetchNews = this.fetchNews.bind(this);
  }

  async fetchNews(): Promise<void> {
    const { managePage, id, manageThrowSaga } = this.props;

    try {
      managePage({
        title: 'News - edit',
        type: 'loading',
        breadcrumb: breadcrumbRouteFormDetailsNews()
      });

      const {
        data: { news }
      }: AxiosResponse<FetchNewsDetailsResponse> = await fetchNewsDetails(
        Number(id)
      );

      this.setState({
        title: news.title,
        description: news.description,
        type: news.type,
        section: news.section,
        url: news.url,
        image: news.image
      });

      managePage({
        title: `${news.title} - edit`,
        breadcrumb: breadcrumbRouteFormDetailsNews(news)
      });
    } catch (error) {
      manageThrowSaga(error);
    }
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id } = this.props;

    if (id) {
      await this.fetchNews();
    } else {
      managePage({
        title: 'News - create',
        breadcrumb: breadcrumbRouteAddNews()
      });
    }
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { history, addToast, id } = this.props;
    const { title, description, type, url, image, section } = this.state;

    this.setState({
      fetching: true,
      errors: null
    });

    const imageBase64 =
      image && image.includes('blob') ? await objectUrlToBase64(image) : null;

    let redirectId = id;

    const payload = {
      image: imageBase64,
      title,
      description,
      type,
      url,
      section
    };

    try {
      if (id) {
        await updateNewsDetails(id, payload);

        addToast(updateNewsToastSuccess());
      } else {
        const {
          data: {
            news: { id: newId }
          }
        }: AxiosResponse<FetchAddNewsResponse> = await fetchAddNews(payload);

        redirectId = newId;

        addToast(createNewsToastSuccess());
      }

      history.push(getPathUrl(ROUTE_NEWS_DETAILS, { id: redirectId }));
    } catch (error) {
      if (id) {
        addToast(updateNewsToastError());
      } else {
        addToast(createNewsToastError());
      }

      if (error instanceof Validation) {
        this.setState({
          fetching: false,
          errors: error.getPayload()
        });
      } else {
        this.setState({
          fetching: false
        });
      }
    }
  }

  render(): ReactNode {
    const { history, id, pageType } = this.props;
    const { title, fetching, errors, description, url, type, image, section } =
      this.state;

    if (pageType) {
      return null;
    }

    return (
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              {fetching && <Loader />}
              <Form onSubmit={this.onSubmit}>
                {errors && (
                  <ShowMessage
                    message={errors.message.value}
                    bsColor={errors.message.variant}
                  />
                )}
                <FormGroup>
                  <Label className="w-100">
                    Title*:
                    <Input
                      type="text"
                      value={title || ''}
                      required
                      onChange={(event) =>
                        this.setState({
                          title: event.currentTarget.value
                        })
                      }
                      invalid={errors && hasError(errors.errors, 'title')}
                    />
                    {errors && hasError(errors.errors, 'title') && (
                      <FormFeedback>
                        {getError(errors.errors, 'title')}
                      </FormFeedback>
                    )}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="w-100">
                    Section*:
                    <CustomInput
                      id="type"
                      type="select"
                      value={section || ''}
                      onChange={(event) =>
                        this.setState({
                          section: event.currentTarget.value as
                            | 'tutorials'
                            | 'plant_of_the_week'
                            | 'smart_gardening'
                            | 'living_with_plants'
                        })
                      }
                      required
                      invalid={errors && hasError(errors.errors, 'section')}
                    >
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <option value="" hidden disabled />
                      <option value="tutorials">Tutorials</option>
                      <option value="plant_of_the_week">
                        Plant of the week
                      </option>
                      <option value="smart_gardening">Smart gardening</option>
                      <option value="living_with_plants">
                        Living with plants
                      </option>
                    </CustomInput>
                    {errors && hasError(errors.errors, 'type') && (
                      <FormFeedback>
                        {getError(errors.errors, 'type')}
                      </FormFeedback>
                    )}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="w-100">
                    Type*:
                    <CustomInput
                      id="type"
                      type="select"
                      value={type || ''}
                      onChange={(event) =>
                        this.setState({ type: event.currentTarget.value })
                      }
                      required
                      invalid={errors && hasError(errors.errors, 'type')}
                    >
                      {acceptableNewsTypesValues.map((typeOption) => (
                        <option key={typeOption} value={typeOption}>
                          {typeOption}
                        </option>
                      ))}
                    </CustomInput>
                    {errors && hasError(errors.errors, 'type') && (
                      <FormFeedback>
                        {getError(errors.errors, 'type')}
                      </FormFeedback>
                    )}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="w-100">
                    Description:
                    <Input
                      type="text"
                      value={description || ''}
                      onChange={(event) =>
                        this.setState({
                          description: event.currentTarget.value
                        })
                      }
                      invalid={errors && hasError(errors.errors, 'description')}
                    />
                    {errors && hasError(errors.errors, 'description') && (
                      <FormFeedback>
                        {getError(errors.errors, 'description')}
                      </FormFeedback>
                    )}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="w-100">
                    Link:
                    <Input
                      type="text"
                      value={url || ''}
                      onChange={(event) =>
                        this.setState({
                          url: event.currentTarget.value
                        })
                      }
                      invalid={errors && hasError(errors.errors, 'url')}
                    />
                    {errors && hasError(errors.errors, 'url') && (
                      <FormFeedback>
                        {getError(errors.errors, 'url')}
                      </FormFeedback>
                    )}
                  </Label>
                  {type === 'video' && (
                    <FormText color="danger">
                      Link for video must be from YouTube.
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <div className="w-100 font-weight-bold">
                    {`Image${id ? '*' : ''}:`}
                    <ImageCropUpload
                      required={Boolean(id)}
                      src={image}
                      onChange={(newImage) =>
                        this.setState({ image: newImage })
                      }
                      error={errors ? getError(errors.errors, 'image') : null}
                    />
                  </div>
                </FormGroup>
                <div className="mt-4 d-flex justify-content-between">
                  <Button
                    type="button"
                    onClick={() =>
                      history.push(
                        id ? getPathUrl(ROUTE_NEWS_DETAILS, { id }) : ROUTE_NEWS
                      )
                    }
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="success">
                    {id ? 'Update' : 'Create'}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(
    mapState,
    mapDispatch
  )(CreateUpdateNewsView)
);
