import Loader from 'modules/Layout/component/Loader';
import React from 'react';

interface IProps {
  icon: React.ReactNode;
  callback?: () => void;
  loading?: boolean;
  bsColor?: string;
  classNames?: string;
}

export const IconBtn: React.FC<IProps> = ({
  callback,
  loading,
  bsColor,
  classNames,
  icon
}) => {
  if (!bsColor) bsColor = 'light';
  if (!classNames) classNames = 'btn-light';

  return (
    <button
      type="button"
      className={`btn details-form-icon-btn ${classNames}`}
      onClick={() => (callback && !loading ? callback() : null)}
    >
      {loading ? (
        <div className="position-relative w-100">
          <Loader size="sm" />
        </div>
      ) : (
        icon
      )}
    </button>
  );
};
