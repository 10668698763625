import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { FamilyEntity } from 'modules/Family/model/Family';
import { FamilyDetailsEntity } from 'modules/Family/model/FamilyDetails';
import { PlantEntity } from 'modules/Plants/model/Plant';
import { TresholdsEntity } from 'modules/Shared/configs/tresholdsEntity/type';
import { MeasurementsKnowHow } from '../Shared/configs/measurementKnowHowEntity/type';

export const API_FAMILY_LIST = '/admin-families/list';
export const API_FAMILY_LIST_ALL = '/admin-families/list-all';
export const API_FAMILY = '/admin-families';

export interface FetchFamiliesResponse {
  content: FamilyEntity[];
  totalCount: any;
}

export const fetchFamily = (
  params: SearchParams = {}
): AxiosPromise<FetchFamiliesResponse> =>
  axios.post(getAPIUrl(API_FAMILY_LIST, null), params);

export interface FetchFamilyAllPayload {
  search?: {
    genus?: string;
  };
}

export const fetchFamilyAll = (
  payload?: FetchFamilyAllPayload
): AxiosPromise<FetchFamiliesResponse> =>
  axios.post(getAPIUrl(API_FAMILY_LIST_ALL), payload);

export type FetchFamilyResponse = AxiosResponse<{
  family: FamilyEntity;
}>;

export const fetchAddFamily = (
  params: {
    scientific_name: string;
    langs: { en: { name: string } };
  },
  cancelToken?: any
): AxiosPromise<FetchFamilyResponse> =>
  axios.post(getAPIUrl(API_FAMILY), params, { cancelToken });

// details

export type FetchFamilyDetailsResponse = AxiosResponse<{
  family: FamilyDetailsEntity;
  thresholds: TresholdsEntity;
  plants: PlantEntity[];
  measurements_statuses_know_hows?: MeasurementsKnowHow[];
}>;

export const fetchFamilyDetails = (
  id: number
): AxiosPromise<FetchFamilyDetailsResponse> =>
  axios.get(getAPIUrl(`${API_FAMILY}/${id}`));

// delete

export type DeleteFamilyDetailsResponse = AxiosResponse<{
  statusCode: number;
}>;

export const deleteFamilyDetails = (
  id: number,
  cancelToken?: any
): AxiosPromise<DeleteFamilyDetailsResponse> =>
  axios.delete(getAPIUrl(`${API_FAMILY}/${id}`), { cancelToken });

// edit

export type UpdateFamilyDetailsResponse = AxiosResponse<{
  family: FamilyEntity;
}>;

export const updateFamilyDetails = (
  id: number,
  params: {
    genus: string;
  },
  cancelToken?: any
): AxiosPromise<UpdateFamilyDetailsResponse> =>
  axios.put(getAPIUrl(`${API_FAMILY}/${id}`), params, { cancelToken });

export type UpdateFamilyDetailsThresholdsResponse = AxiosResponse<{
  thresholds: TresholdsEntity;
}>;

export const updateFamilyDetailsThresholds = (
  id: number,
  params: TresholdsEntity,
  cancelToken?: any
): AxiosPromise<UpdateFamilyDetailsThresholdsResponse> =>
  axios.put(getAPIUrl(`${API_FAMILY}/${id}/thresholds`), params, {
    cancelToken
  });
