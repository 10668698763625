import Autocomplete, {
  AutocompleteOmitProps,
  AutocompleteOption
} from 'modules/Layout/component/Autocomplete';
import React, { ReactNode } from 'react';
import ApiError from 'modules/Shared/exception/ApiError';
import {
  fetchFamilyAll,
  FetchFamilyAllPayload
} from 'modules/Family/repository';
import Family from '../../model/Family';

export interface AutocompleteFamily {
  id?: number;
  genus?: string;
}

export interface Props extends AutocompleteOmitProps {
  familyId?: number | string;
  filter?: (family: AutocompleteFamily) => boolean;
  disabledOption?: (family: Family) => boolean;
  onChangeObject?: (family: Family) => void;
}

export interface State {
  families?: AutocompleteFamily[];
  error?: ApiError;
  fetching?: boolean;
}

class FamiliesAutocomplete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fetching: false,
      families: []
    };

    this.toOption = this.toOption.bind(this);
    this.fetchFamilies = this.fetchFamilies.bind(this);
    this.filter = this.filter.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  toOption(
    familyId?: number | string,
    disabledOption?: (family: Family) => boolean
  ): AutocompleteOption {
    const { families } = this.state;

    const foundFamily = families.find(
      (family) => String(family.id) === String(familyId)
    );

    if (!foundFamily) {
      return {
        label: '',
        value: ''
      };
    }

    return {
      label: foundFamily.genus,
      value: foundFamily.id.toString(),
      disabled: disabledOption ? disabledOption(foundFamily as Family) : false
    };
  }

  async fetchFamilies(payload: FetchFamilyAllPayload): Promise<void> {
    try {
      this.setState({
        fetching: true
      });

      const {
        data: { content }
      } = await fetchFamilyAll(payload);

      this.setState({
        families: content
      });
    } catch (error) {
      this.setState({
        error
      });
    } finally {
      this.setState({
        fetching: false
      });
    }
  }

  filter(): AutocompleteFamily[] {
    const { filter } = this.props;
    const { families } = this.state;

    if (filter) {
      return families.filter(filter);
    }

    return families;
  }

  render(): ReactNode {
    const {
      onChange,
      onChangeObject,
      familyId,
      required = false,
      disabled = false,
      disabledOption,
      label = 'Choose family',
      closeOnSelect = false
    } = this.props;
    const { fetching, error, families } = this.state;

    const familiesOptions = this.filter().map((family) =>
      this.toOption(family.id, disabledOption)
    );

    return (
      <Autocomplete
        onChange={(id) => {
          if (onChange) {
            onChange(id);
          }
          if (onChangeObject) {
            const foundFamily = families.find((obj) => obj.id === Number(id));

            onChangeObject(foundFamily as Family);
          }
        }}
        onReset={async () => this.fetchFamilies({ search: {} })}
        fetching={fetching}
        error={error}
        onSearch={async (genus) => this.fetchFamilies({ search: { genus } })}
        value={this.toOption(familyId)}
        options={familiesOptions}
        required={required}
        disabled={disabled}
        label={label}
        closeOnSelect={closeOnSelect}
      />
    );
  }
}

export default FamiliesAutocomplete;
