import React, { ReactNode } from 'react';
import { Button, Label, Input, FormGroup, FormFeedback } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import Loader from 'modules/Layout/component/Loader';
import { Message, ValidationErrors } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface Props {
  onSubmit: (data: { file: File }) => void;
  fetching: boolean;
  errorMessage?: Message | null;
  errors?: ValidationErrors;
}

export interface State {
  isOpen: boolean;
  file: File;
}

class PlantsImportExcelModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      file: null
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  openModal(): void {
    const state: State = {
      isOpen: true,
      file: null
    };

    this.setState(state);
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  renderTitle = (): React.ReactNode => {
    return 'Import excel file';
  };

  renderBody(): React.ReactNode {
    const { fetching, errorMessage, errors } = this.props;

    return (
      <>
        {errorMessage && <Alert message={errorMessage} />}
        {fetching && <Loader />}
        <fieldset>
          <FormGroup>
            <Label for="#new-import-file">Import excel file</Label>
            <Input
              id="new-import-file"
              type="file"
              required
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(event) => {
                const file = event.target.files[0];

                this.setState({
                  file
                });
              }}
              invalid={hasError(errors, 'excel_file')}
            />
            {hasError(errors, 'excel_file') && (
              <FormFeedback>{getError(errors, 'excel_file')}</FormFeedback>
            )}
          </FormGroup>
        </fieldset>
      </>
    );
  }

  renderFooter(): React.ReactNode {
    const { onSubmit } = this.props;
    const { file } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button color="primary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button
          disabled={!file}
          color="success"
          onClick={() => onSubmit({ file })}
        >
          Start import
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen } = this.state;

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default PlantsImportExcelModal;
