import { RootState } from 'app/reducer';
import { setLocationSearchAction, manageThrow } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_HUB_LIST,
  fetchHubListAction,
  SET_HUB_LIST_PARAMS,
  hubListFetchedAction
} from 'modules/Hub/action/list';
import { fetchHubs, FetchHubsResponse } from 'modules/Hub/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';

export function* setHubListParamsSaga(): SagaIterator {
  yield put(fetchHubListAction());
}

export function* fetchHubListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.hub.list
    );

    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response: FetchHubsResponse = yield call(fetchHubs, {
      ...sort,
      pagination,
      search: filter
    });

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(hubListFetchedAction({ hubs: content, totalPages }));
  } catch (error) {
    if (error instanceof Validation) {
      yield put(hubListFetchedAction({ hubs: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* hubListSaga(): SagaIterator {
  yield takeLatest(SET_HUB_LIST_PARAMS, setHubListParamsSaga);
  yield takeLatest(FETCH_HUB_LIST, fetchHubListSaga);
}
