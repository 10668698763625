import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deleteHubSoftwareToastSuccess = (): AddToastPayload => ({
  header: 'Delete hub software',
  body: 'Hub software was deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteHubSoftwareToastError = (): AddToastPayload => ({
  header: 'Delete hub software',
  body: 'Hub software was not deleted.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const publishHubSoftwareToastSuccess = (): AddToastPayload => ({
  header: 'Publish hub software',
  body: 'Hub software was published.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const publishHubSoftwareToastError = (): AddToastPayload => ({
  header: 'Publish hub software',
  body: 'Hub software was not published.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const editHubSoftwareToastSuccess = (): AddToastPayload => ({
  header: 'Edit hub software',
  body: 'Hub software was updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const editHubSoftwareToastError = (): AddToastPayload => ({
  header: 'Edit hub software',
  body: 'Hub software was not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
