import React, { Component, ReactNode } from 'react';
import User from 'modules/User/model/User';
import BaseModal from 'modules/Layout/component/Modal';
import InformationList from 'modules/Layout/component/InformationList';
import { Button } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import { deleteAdmin, deleteUser } from 'modules/User/repository';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  deleteAdminToastError,
  deleteAdminToastSuccess,
  deleteUserToastError,
  deleteUserToastSuccess
} from 'modules/User/toasts';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends DispatchProps {
  onSuccess?: () => void;
  onError?: () => void;
  admin?: boolean;
}

export interface State {
  user: User;
  open: boolean;
  deleting: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

export class DeleteUserModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: null,
      open: false,
      deleting: false
    };

    this.renderBody = this.renderBody.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.delete = this.delete.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  open(user: User): void {
    this.setState({
      open: true,
      user,
      deleting: false
    });
  }

  close(): void {
    this.setState({
      open: false
    });
  }

  renderBody(): ReactNode {
    const { user, deleting } = this.state;

    const fields = [
      {
        label: 'Username:',
        value: user.username
      },
      {
        label: 'Email:',
        value: user.email
      },
      {
        label: 'Status:',
        value: user.getStatus()
      }
    ];

    return (
      <div>
        {deleting && <Loader />}
        <InformationList fields={fields} />
      </div>
    );
  }

  renderFooter(): ReactNode {
    return (
      <div className="d-flex w-100 justify-content-between">
        <Button color="primary" onClick={this.close} className="mr-2">
          Cancel
        </Button>
        <Button color="danger" onClick={this.delete}>
          Delete
        </Button>
      </div>
    );
  }

  async delete(): Promise<void> {
    const { onSuccess, onError, addToast, admin } = this.props;
    const { user } = this.state;

    try {
      this.setState({
        deleting: true
      });

      if (admin) {
        await deleteAdmin(user.id);
      } else {
        await deleteUser(user.id);
      }

      if (onSuccess) {
        onSuccess();
      }

      if (admin) {
        addToast(deleteAdminToastSuccess());
      } else {
        addToast(deleteUserToastSuccess());
      }
    } catch (error) {
      if (onError) {
        onError();
      }

      if (admin) {
        addToast(deleteAdminToastError());
      } else {
        addToast(deleteUserToastError());
      }
    } finally {
      this.close();
    }
  }

  render(): ReactNode {
    const { admin } = this.props;
    const { open, user } = this.state;

    if (!user) {
      return null;
    }

    return (
      <BaseModal
        isOpen={open}
        body={this.renderBody()}
        footer={this.renderFooter()}
        title={`Do you want to delete ${admin ? 'administrator' : 'user'}?`}
        toggle={this.close}
        onClosed={this.close}
      />
    );
  }
}

export default connect<null, DispatchProps>(null, mapDispatch, null, {
  forwardRef: true
})(DeleteUserModal);
