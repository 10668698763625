import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import {
  Message,
  PartialSearchingProps,
  SortParams,
  ValidationErrors
} from 'modules/Shared/type';
import SensorSoftware from 'modules/Sensor/model/SensorSoftware';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import User from 'modules/User/model/User';
import ActionPublish from 'modules/Layout/component/Action/Publish';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ApiError from 'modules/Shared/exception/ApiError';
import {
  AddToastAction,
  addToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import HubSoftwareEditModal from 'modules/Hub/component/Modal/EditHubSoftware';
import {
  deleteHubSoftware,
  editHubSoftware,
  publishHubSoftware
} from 'modules/Hub/repository';
import {
  SetHubSoftwareListParamsAction,
  setHubSoftwareListParamsAction
} from 'modules/Hub/action/listSoftware';
import HubSoftware from 'modules/Hub/model/HubSoftware';
import HubSoftwareDeleteModal from 'modules/Hub/component/Modal/DeleteHubSoftware';
import HubSoftwarePublishModal from 'modules/Hub/component/Modal/PublishHubSoftware';
import {
  deleteHubSoftwareToastError,
  deleteHubSoftwareToastSuccess,
  editHubSoftwareToastError,
  editHubSoftwareToastSuccess,
  publishHubSoftwareToastError,
  publishHubSoftwareToastSuccess
} from 'modules/Hub/toasts';

export interface StateProps {
  auth: User;
  hubSoftwares: HubSoftware[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetHubSoftwareListParamsAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (hubSoftware: HubSoftware) => void;
}

export interface State {
  fetching: boolean;
  errors: ValidationErrors;
  errorMessage: Message | null;
}

export const mapState = (state: RootState): StateProps => {
  const { user: auth } = state.auth;
  const { hubSoftwares, sort } = state.hub.hubSoftwareList;

  return { auth, hubSoftwares, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setHubSoftwareListParamsAction(payload)),
  addToast: (payload: AddToastPayload) => dispatch(addToastAction(payload))
});

export class HubSoftwareTable extends React.Component<Props, State> {
  readonly cols: TableCol<HubSoftware>[];
  readonly modalDeleteRef: React.RefObject<HubSoftwareDeleteModal>;
  readonly modalPublishRef: React.RefObject<HubSoftwarePublishModal>;
  readonly modalEditRef: React.RefObject<HubSoftwareEditModal>;

  constructor(props: Props) {
    super(props);

    this.state = {
      fetching: false,
      errorMessage: null,
      errors: null
    };

    this.modalDeleteRef = React.createRef();
    this.modalPublishRef = React.createRef();
    this.modalEditRef = React.createRef();

    this.cols = [
      {
        property: 'version',
        label: 'Version',
        sortable: true,
        className: 'col-2'
      },
      {
        property: 'software',
        label: 'Software',
        className: 'col-4'
      },
      {
        property: 'required',
        label: 'Required',
        className: 'col-1',
        value: (row) => row.getRequired()
      },
      {
        property: 'published',
        label: 'Published',
        className: 'col-1',
        value: (row) => row.getPublished()
      },
      {
        property: 'created_at',
        label: 'Created at',
        sortable: true,
        className: 'col-2'
      },
      {
        property: 'updated_at',
        label: 'Updated at',
        sortable: true,
        className: 'col-2'
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionPublish
              id="publish-software"
              title="Publish software"
              disabled={row.published || !row.software}
              onClick={() => this.openModal(row, this.modalPublishRef)}
            />
            <ActionUpdate
              id="edit-software"
              disabled={row.published}
              title="Edit software"
              onClick={() => this.openModal(row, this.modalEditRef)}
            />
            <ActionDelete
              id="delete-software"
              title="Delete software"
              disabled={row.published}
              onClick={() => this.openModal(row, this.modalDeleteRef)}
            />
          </div>
        )
      }
    ];

    this.openModal = this.openModal.bind(this);
    this.onSubmitDelete = this.onSubmitDelete.bind(this);
    this.onSubmitPublish = this.onSubmitPublish.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(sensorSoftware: SensorSoftware, ref: React.RefObject<any>): void {
    this.setState(
      {
        errorMessage: null,
        errors: null
      },
      () => {
        ref.current.openModal(sensorSoftware);
      }
    );
  }

  closeModal(ref: React.RefObject<any>): void {
    ref.current.toggle();
  }

  onSubmitDelete(hubSoftware: HubSoftware): void {
    const { setParams, addToast } = this.props;

    this.setState(
      {
        fetching: true,
        errorMessage: null,
        errors: null
      },
      () => {
        deleteHubSoftware(hubSoftware.version)
          .then(() => {
            setParams({});
            addToast(deleteHubSoftwareToastSuccess());
            this.closeModal(this.modalDeleteRef);
          })
          .catch((error) => {
            if (error instanceof ApiError) {
              this.setState({
                errorMessage: error.getMessage()
              });
            }
            addToast(deleteHubSoftwareToastError());
          })
          .finally(() => {
            this.setState({
              fetching: false
            });
          });
      }
    );
  }

  onSubmitPublish(hubSoftware: HubSoftware): void {
    const { setParams, addToast } = this.props;

    this.setState(
      {
        fetching: true,
        errorMessage: null,
        errors: null
      },
      () => {
        publishHubSoftware(hubSoftware.version)
          .then(() => {
            setParams({});
            addToast(publishHubSoftwareToastSuccess());
            this.closeModal(this.modalPublishRef);
          })
          .catch((error) => {
            if (error instanceof ApiError) {
              this.setState({
                errorMessage: error.getMessage()
              });
            }
            addToast(publishHubSoftwareToastError());
          })
          .finally(() => {
            this.setState({
              fetching: false
            });
          });
      }
    );
  }

  onSubmitEdit(
    hubSoftware: HubSoftware,
    data: { version: string; required: boolean }
  ): void {
    const { setParams, addToast } = this.props;

    this.setState(
      {
        fetching: true,
        errorMessage: null,
        errors: null
      },
      () => {
        editHubSoftware(hubSoftware.version, data)
          .then(() => {
            setParams({});
            addToast(editHubSoftwareToastSuccess());
            this.closeModal(this.modalEditRef);
          })
          .catch((error) => {
            if (error instanceof ApiError) {
              this.setState({
                errorMessage: error.getMessage(),
                errors: error.getErrors()
              });
            }
            addToast(editHubSoftwareToastError());
          })
          .finally(() => {
            this.setState({
              fetching: false
            });
          });
      }
    );
  }

  render(): ReactNode {
    const { hubSoftwares, sort, setParams } = this.props;
    const { fetching, errorMessage, errors } = this.state;

    return (
      <>
        <HubSoftwareDeleteModal
          ref={this.modalDeleteRef}
          onSubmit={this.onSubmitDelete}
          errorMessage={errorMessage}
          fetching={fetching}
        />
        <HubSoftwarePublishModal
          ref={this.modalPublishRef}
          onSubmit={this.onSubmitPublish}
          errorMessage={errorMessage}
          fetching={fetching}
        />
        <HubSoftwareEditModal
          ref={this.modalEditRef}
          onSubmit={this.onSubmitEdit}
          errorMessage={errorMessage}
          fetching={fetching}
          errors={errors}
        />
        <Table
          cols={this.cols}
          rows={hubSoftwares}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HubSoftwareTable);
