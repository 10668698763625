import React from 'react';
import { Link } from 'react-router-dom';

export const displayOptionRowContent = (
  objArr: {
    title: string;
    description?: string | number;
    shortTitle?: string;
    type?: string;
    to?: string;
    html?: boolean;
    reactNode?: JSX.Element;
  }[],
  longTitle?: boolean,
  className?: string,
  border?: boolean
) => {
  return objArr.map((info, index) => {
    return (
      <div
        className={`form-group${className ? ` ${className}` : ''}`}
        key={index}
      >
        <div className="form-row justify-content-between">
          <div
            className={`bv-no-focus-ring d-flex font-weight-bold pt-1 ${
              longTitle ? 'col-md-6' : 'col-md-3'
            }`}
          >
            {info?.shortTitle ? `${info.title} ${info.shortTitle}` : info.title}
          </div>
          <div className={`pt-1 ${longTitle ? 'col-md-5' : 'col-md-8'}`}>
            {info.type === 'link' && info.to ? (
              <Link to={info.to} className="link-with-svg">
                <div className="d-inline-flex flex-wrap align-items-center">
                  {info.html ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: info.description as string
                      }}
                    />
                  ) : (
                    info.description
                  )}
                </div>
              </Link>
            ) : // eslint-disable-next-line no-nested-ternary
            info.html ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: (info.description as string) || '-'
                }}
              />
            ) : info.reactNode ? (
              info.reactNode
            ) : (
              info.description || '-'
            )}
          </div>
        </div>

        {border && <hr />}
      </div>
    );
  });
};
