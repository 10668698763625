import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { MissingFamilyEntity } from 'modules/MissingFamily/model/MissingFamily';
import { Action } from 'redux';

export const SET_MISSING_FAMILY_LIST_PARAMS = 'MISSING_FAMILY/LIST/PARAMS/SET';
export const FETCH_MISSING_FAMILY_LIST = 'MISSING_FAMILY/LIST/FETCH';
export const MISSING_FAMILY_LIST_FETCHED = 'MISSING_FAMILY/LIST/FETCHED';

export type MissingFamilyListAction =
  | SetMissingFamilyListParamsAction
  | FetchMissingFamilyListAction
  | MissingFamilyListFetchedAction;

export type SetMissingFamilyListParamsAction = SetSearchingParamsAction<
  typeof SET_MISSING_FAMILY_LIST_PARAMS
>;

export const setMissingFamilyListParamsAction = (
  payload: PartialSearchingProps
): SetMissingFamilyListParamsAction =>
  setSearchingParamsAction(SET_MISSING_FAMILY_LIST_PARAMS, payload);

export type FetchMissingFamilyListAction = Action<typeof FETCH_MISSING_FAMILY_LIST>;

export const fetchMissingFamilyListAction = (): FetchMissingFamilyListAction => ({
  type: FETCH_MISSING_FAMILY_LIST
});

export type MissingFamilyListFetchedPayload = {
  families: MissingFamilyEntity[];
  totalPages: number;
};

export interface MissingFamilyListFetchedAction
  extends Action<typeof MISSING_FAMILY_LIST_FETCHED> {
  payload: MissingFamilyListFetchedPayload;
}

export const missingFamilyListFetchedAction = (
  payload: MissingFamilyListFetchedPayload
): MissingFamilyListFetchedAction => ({
  type: MISSING_FAMILY_LIST_FETCHED,
  payload
});
