import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { GardenEntity } from 'modules/Garden/model/Garden';
import { Action } from 'redux';

export const SET_GARDEN_LIST_PARAMS = 'GARDEN/LIST/PARAMS/SET';
export const UPDATE_GARDEN_LIST_PARAMS = 'GARDEN/LIST/PARAMS/UPDATE';
export const FETCH_GARDEN_LIST = 'GARDEN/LIST/FETCH';
export const GARDEN_LIST_FETCHED = 'GARDEN/LIST/FETCHED';

export type GardenListAction =
  | SetGardenListParamsAction
  | FetchGardenListAction
  | GardenListFetchedAction;

export type SetGardenListParamsAction = SetSearchingParamsAction<
  typeof SET_GARDEN_LIST_PARAMS
>;

export const setGardenListParamsAction = (
  payload: PartialSearchingProps
): SetGardenListParamsAction =>
  setSearchingParamsAction(SET_GARDEN_LIST_PARAMS, payload);

export type FetchGardenListAction = Action<typeof FETCH_GARDEN_LIST>;

export const fetchGardenListAction = (): FetchGardenListAction => ({
  type: FETCH_GARDEN_LIST
});

export type GardenListFetchedPayload = {
  gardens: GardenEntity[];
  totalPages: number;
};

export interface GardenListFetchedAction
  extends Action<typeof GARDEN_LIST_FETCHED> {
  payload: GardenListFetchedPayload;
}

export const gardenListFetchedAction = (
  payload: GardenListFetchedPayload
): GardenListFetchedAction => ({
  type: GARDEN_LIST_FETCHED,
  payload
});
