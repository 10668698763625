import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import Sensor from 'modules/Sensor/model/Sensor';
import { deleteSensor } from 'modules/Sensor/repository';
import {
  deleteSensorToastError,
  deleteSensorToastSuccess
} from 'modules/Sensor/toasts';
import {
  Message,
  PartialSearchingProps,
  SortParams,
  ValidationErrors
} from 'modules/Shared/type';
import ApiError from 'modules/Shared/exception/ApiError';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import {
  setSensorListParamsAction,
  SetSensorListParamsAction
} from 'modules/Sensor/action/sensorList';
import { Dispatch } from 'redux';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';
import { ROUTE_PLANT_USER_DETAILS } from 'modules/PlantUser/routes';
import { ROUTE_SENSOR_DETAILS } from 'modules/Sensor/routes';
import ActionShow from 'modules/Layout/component/Action/Show';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import SensorDeleteModal from 'modules/Sensor/component/Modal/DeleteSensor';
import {
  AddToastAction,
  addToastAction,
  AddToastPayload
} from 'modules/Layout/action';

export interface StateProps {
  sensors: Sensor[];
  sort: SortParams;
}
export interface State {
  fetching: boolean;
  errors: ValidationErrors;
  errorMessage: Message | null;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetSensorListParamsAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (sensor: Sensor) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { sensors, sort } = state.sensor.sensorList;

  return { sensors, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setSensorListParamsAction(payload)),
  addToast: (payload: AddToastPayload) => dispatch(addToastAction(payload))
});

export class SensorTable extends React.Component<Props, State> {
  readonly cols: TableCol<Sensor>[];
  readonly modalDeleteRef: React.RefObject<SensorDeleteModal>;
  constructor(props: Props) {
    super(props);
    this.state = {
      fetching: false,
      errorMessage: null,
      errors: null
    };

    this.modalDeleteRef = React.createRef();
    this.onSubmitDelete = this.onSubmitDelete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cols = [
      {
        property: 'sensor_id',
        label: 'Mac',
        sortable: true,
        className: 'col-3',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_SENSOR_DETAILS, { id: row.sensor_id })
      },
      {
        property: 'email',
        label: 'Owner email',
        sortable: true,
        className: 'col-3',
        columnRedirectTo: (row) =>
          Boolean(row.user_id) &&
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: 'username',
        label: 'Owner username',
        sortable: true,
        className: 'col-3',
        columnRedirectTo: (row) =>
          Boolean(row.user_id) &&
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: 'nickname',
        label: 'Plant nickname',
        sortable: true,
        className: 'col-3',
        columnRedirectTo: (row) =>
          Boolean(row.user_plant_id) &&
          getPathUrl(ROUTE_PLANT_USER_DETAILS, { id: row.user_plant_id })
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show sensor"
              to={getPathUrl(ROUTE_SENSOR_DETAILS, { id: row.sensor_id })}
            />
            <ActionDelete
              id={`delete-sensor-${row.sensor_id}`}
              title="Delete Sensor"
              onClick={() => this.openModal(row, this.modalDeleteRef)}
            />
          </div>
        )
      }
    ];
  }

  onSubmitDelete(sensor: Sensor): void {
    const { setParams, addToast } = this.props;
    this.setState(
      {
        fetching: true,
        errorMessage: null
      },
      () => {
        deleteSensor(sensor.id)
          .then(() => {
            setParams({});
            addToast(deleteSensorToastSuccess());
            this.closeModal(this.modalDeleteRef);
          })
          .catch((error) => {
            if (error instanceof ApiError) {
              this.setState({
                errorMessage: error.getMessage()
              });
            }
            addToast(deleteSensorToastError());
          })
          .finally(() => {
            this.setState({
              fetching: false
            });
          });
      }
    );
  }

  closeModal(ref: React.RefObject<any>): void {
    ref.current.toggle();
  }

  openModal(sensor: Sensor, ref: React.RefObject<any>): void {
    this.setState(
      {
        errorMessage: null
      },
      () => {
        ref.current.openModal(sensor);
      }
    );
  }

  render(): ReactNode {
    const { sensors, sort, setParams } = this.props;
    const { fetching, errorMessage } = this.state;
    return (
      <>
        <SensorDeleteModal
          ref={this.modalDeleteRef}
          onSubmit={this.onSubmitDelete}
          errorMessage={errorMessage}
          fetching={fetching}
        />
        <Table
          cols={this.cols}
          rows={sensors}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(SensorTable);
