import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { MissingFamilyEntity } from 'modules/MissingFamily/model/MissingFamily';
import { MissingFamilyDetailsEntity } from 'modules/MissingFamily/model/MissingFamilyDetails';

export const API_FAMILY_LIST = '/admin-families/missing-families';
export const API_FAMILY_DETAILS = '/admin-families/missing-families/:id';

//list

export type FetchFamiliesResponse = AxiosResponse<{
  content: MissingFamilyEntity[];
  totalCount: any;
}>;

export const fetchMissingFamily = (
  params: SearchParams = {}
): AxiosPromise<FetchFamiliesResponse> =>
  axios.post(getAPIUrl(API_FAMILY_LIST), params);

// details

export type FetchMissingFamilyDetailsResponse = AxiosResponse<{
  missingFamily: MissingFamilyDetailsEntity;
}>;

export const fetchMissingFamilyDetails = (
  id: number
): AxiosPromise<FetchMissingFamilyDetailsResponse> =>
  axios.get(getAPIUrl(API_FAMILY_DETAILS, { id }));
