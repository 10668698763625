export interface PlantDiseaseLang {
  lang?: string;
  about?: string;
  prevention?: string;
  treatment?: string;
}

export interface PlantDiseaseEntity {
  id?: number;
  first_type?: string;
  second_type?: string;
  third_type?: string;
  fourth_type?: string;
  fifth_type?: string;
  lang?: PlantDiseaseLang[];
  created_at?: string;
  updated_at?: string;
}

class PlantDisease {
  readonly id?: number;
  readonly first_type?: string;
  readonly second_type?: string;
  readonly third_type?: string;
  readonly fourth_type?: string;
  readonly fifth_type?: string;
  lang?: PlantDiseaseLang[];

  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(plantDiseaseEntity?: PlantDiseaseEntity) {
    this.id = plantDiseaseEntity?.id;
    this.first_type = plantDiseaseEntity?.first_type;
    this.second_type = plantDiseaseEntity?.second_type;
    this.third_type = plantDiseaseEntity?.third_type;
    this.fourth_type = plantDiseaseEntity?.fourth_type;
    this.fifth_type = plantDiseaseEntity?.fifth_type;
    this.lang = plantDiseaseEntity?.lang;
    this.created_at = plantDiseaseEntity?.created_at;
    this.updated_at = plantDiseaseEntity?.updated_at;
  }
}

export const createPlantDisease = (
  plantDiseaseEntity?: PlantDiseaseEntity
): PlantDisease => new PlantDisease(plantDiseaseEntity);

export default PlantDisease;
