import { RootState } from 'app/reducer';
import {
  PartialSearchingProps,
  PaginationParams,
  SearchParams
} from 'modules/Shared/type';
import {
  setMissingFamilyListParamsAction,
  SetMissingFamilyListParamsAction
} from 'modules/MissingFamily/action/List';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Pagination } from 'modules/Layout/component/Pagination/pagination';

export interface StateProps {
  pagination: PaginationParams;
  filter: SearchParams;
  currentCount: number;
  totalPages: number;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetMissingFamilyListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { pagination, filter, missingFamilies, totalPages } =
    state.missingFamily.list;

  return {
    pagination,
    filter,
    currentCount: missingFamilies.length,
    totalPages
  };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setMissingFamilyListParamsAction(payload))
});

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(Pagination);
