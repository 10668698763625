import React, { createRef, ReactNode } from 'react';
import Dictionary, {
  createDictionary
} from 'modules/Dictionary/model/Dictionary';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import { breadcrumbRouteDictionaryDetails } from 'modules/Dictionary/breadcrumbs';
import { fetchDictionary } from 'modules/Dictionary/repository';
import Loader from 'modules/Layout/component/Loader';
import DictionaryDetails from 'modules/Dictionary/component/Details';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import DeleteDictionaryModal, {
  DeleteDictionaryModal as DeleteDictionaryModalClass
} from 'modules/Dictionary/component/Modal/Delete';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps {
  id: string;
  table: string;
}

export interface State {
  dictionary: Dictionary;
  deleteMessageText: string;
  deleteMessageVariant: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class DictionaryDetailsView extends React.Component<Props, State> {
  readonly deleteModalRef: React.RefObject<DeleteDictionaryModalClass>;

  constructor(props: Props) {
    super(props);

    this.deleteModalRef = createRef();

    this.state = {
      dictionary: null,
      deleteMessageVariant: null,
      deleteMessageText: null
    };

    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { managePage, table, id, manageThrow } = this.props;

    managePage({
      title: `Dictionary ${Dictionary.getName(table)}`,
      breadcrumb: breadcrumbRouteDictionaryDetails(table),
      type: 'loading'
    });

    try {
      const { data } = await fetchDictionary(id, table);

      const dictionary = createDictionary(data.dictionary);

      this.setState({
        dictionary
      });

      managePage({
        title: `Dictionary ${Dictionary.getName(table)} - ${dictionary.name}`,
        breadcrumb: breadcrumbRouteDictionaryDetails(table, dictionary),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  onDeleteSuccess(): void {
    const { dictionary } = this.state;

    dictionary.is_deleted = true;

    this.setState({
      dictionary,
      deleteMessageText: 'Dictionary deleted.',
      deleteMessageVariant: 'success'
    });
  }

  deleteMessage(): void {
    this.setState({
      deleteMessageText: null,
      deleteMessageVariant: null
    });
  }

  render(): ReactNode {
    const { type, table } = this.props;
    const { dictionary, deleteMessageText, deleteMessageVariant } = this.state;

    if (type) {
      return null;
    }

    if (!dictionary) {
      return (
        <div className="position-relative">
          <Loader />
        </div>
      );
    }

    return (
      <>
        <DeleteDictionaryModal
          ref={this.deleteModalRef}
          table={table}
          onSuccess={this.onDeleteSuccess}
        />
        <DictionaryDetails
          dictionary={dictionary}
          onDelete={() => this.deleteModalRef?.current?.open(dictionary)}
          deleteMessageText={deleteMessageText}
          deleteMessageVariant={deleteMessageVariant}
          deleteMessage={this.deleteMessage}
          table={table}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(DictionaryDetailsView);
