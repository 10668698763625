import { RootState } from 'app/reducer';
import {
  SET_USER_PLANT_DETAILS_PARAMS,
  FETCH_USER_PLANT_DETAILS,
  fetchPlantUserDetailsAction,
  plantUserDetailsFetchedAction
} from 'modules/PlantUser/action/details';
import {
  fetchPlantUserDetails,
  FetchPlantUserDetailsResponse
} from 'modules/PlantUser/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';

export function* setPlantUserDetailsParamsSaga(): SagaIterator {
  yield put(fetchPlantUserDetailsAction());
}

export function* fetchPlantUserDetailsSaga(): SagaIterator {
  try {
    const { plantUserId } = yield select(
      (state: RootState) => state.plantUser.details
    );

    const response: FetchPlantUserDetailsResponse = yield call(
      fetchPlantUserDetails,
      plantUserId
    );

    const { userPlant, user } = response.data;
    userPlant.user = user;

    yield put(
      plantUserDetailsFetchedAction({
        plantUserDetails: userPlant
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(plantUserDetailsFetchedAction({ plantUserDetails: null }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantUserDetailsSaga(): SagaIterator {
  yield takeLatest(
    SET_USER_PLANT_DETAILS_PARAMS,
    setPlantUserDetailsParamsSaga
  );
  yield takeLatest(FETCH_USER_PLANT_DETAILS, fetchPlantUserDetailsSaga);
}
