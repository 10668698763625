import {
  FETCH_SENSOR_LIST,
  SET_SENSOR_LIST_PARAMS,
  SENSOR_LIST_FETCHED,
  SensorListAction
} from 'modules/Sensor/action/sensorList';
import { createSensor } from 'modules/Sensor/model/Sensor';
import {
  initSensorListState,
  SensorListState
} from 'modules/Sensor/state/sensorList';

const initState = initSensorListState();

const reducer = (
  state: SensorListState = initState,
  action: SensorListAction
): SensorListState => {
  switch (action.type) {
    case SET_SENSOR_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_SENSOR_LIST:
      return { ...state, fetching: true };

    case SENSOR_LIST_FETCHED: {
      const { sensors = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        sensors: sensors.map(createSensor),
        totalPages: action.payload.totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
