import { PlantUserDetailsEntity } from 'modules/PlantUser/model/PlantUserDetails';
import { UserEntity } from 'modules/User/model/User';

export type SensorDetailsEntity = {
  id?: string;
  sensor_id?: string;
  user_id?: number;
  user_username?: string;
  user_plant_id?: number;
  version?: string;
  garden_id?: number;
  garden_name?: string;
  status?: number;
  user_plant_nickname?: string;
  sensor_created_at?: string;
  userPlant?: PlantUserDetailsEntity;
  user?: UserEntity;
  created_at?: string;
  updated_at?: string;
};

export default class SensorDetails {
  readonly id?: string;
  readonly sensor_id?: string;
  readonly user_id?: number;
  readonly user_username?: string;
  readonly user_plant_id?: number;
  readonly version?: string;
  readonly garden_id?: number;
  readonly garden_name?: string;
  readonly status?: number;
  readonly user_plant_nickname?: string;
  readonly sensor_created_at?: string;
  readonly userPlant?: PlantUserDetailsEntity;
  readonly user?: UserEntity;
  readonly updated_at?: string;
  readonly created_at?: string;

  constructor(entity: SensorDetailsEntity) {
    this.id = entity.id;
    this.sensor_id = entity.sensor_id;
    this.user_id = entity.user_id;
    this.user_username = entity.user_username;
    this.user_plant_id = entity.user_plant_id;
    this.version = entity.version;
    this.garden_id = entity.garden_id;
    this.garden_name = entity.garden_name;
    this.status = entity.status;
    this.user_plant_nickname = entity.user_plant_nickname;
    this.sensor_created_at = entity.sensor_created_at;
    this.userPlant = entity.userPlant;
    this.user = entity.user;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }
}

export const createSensorDetails = (
  entity: SensorDetailsEntity
): SensorDetails => new SensorDetails(entity);

export const sensorStatus = (status: number) => {
  switch (status) {
    case 0:
      return 'Sensor not connected.';
    case 1:
      return 'Measurements were sent within 48 hours.';
    case 2:
      return 'Measurements were not sent within 48 hours.';
    default:
      return '-';
  }
};
