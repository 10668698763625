import React, { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import Hub, { createHub } from 'modules/Hub/model/Hub';
import { fetchHub } from 'modules/Hub/repository';
import { breadcrumbRouteHubDetails } from 'modules/Hub/breadcrumbs';
import HubDetails from 'modules/Hub/component/Details';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps {
  id: string;
}

export interface State {
  hub: Hub;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class HubDetailsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hub: null
    };
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow } = this.props;

    managePage({
      title: 'Hub',
      breadcrumb: breadcrumbRouteHubDetails(),
      type: 'loading'
    });

    try {
      const { data } = await fetchHub(id);

      const hub = createHub(data);

      this.setState({
        hub
      });

      managePage({
        title: `Hub - ${data.hub_id}`,
        breadcrumb: breadcrumbRouteHubDetails(hub),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  render(): ReactNode {
    const { type } = this.props;
    const { hub } = this.state;

    if (type || !hub) {
      return null;
    }

    return <HubDetails hub={hub} />;
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HubDetailsView);
