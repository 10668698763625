import axios, { AxiosPromise, AxiosResponse } from 'axios';
import {
  RefreshTokenPayload,
  RequestTokenPayload,
  Token
} from 'modules/Auth/type';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { UserEntity } from 'modules/User/model/User';

export const API_TOKEN = '/auth/panel/login';
export const API_REFRESH_TOKEN = '/auth/refresh';
export const API_AUTHENTICATE = '/user';
export const API_FORGOT_PASSWORD = '/auth/panel/password/request';
export const API_RESET_PASSWORD = '/auth/panel/password/reset';
export const API_VERIFY_EMAIL = '/auth/panel/verify-email';

export type TokenResponse = AxiosResponse<Token>;

export const requestToken = (
  payload: RequestTokenPayload
): AxiosPromise<Token> => axios.post(getAPIUrl(API_TOKEN), payload);

export type AuthenticateResponse = AxiosResponse<{
  profileData: UserEntity;
}>;

export const authenticate = (): AxiosPromise<AuthenticateResponse> =>
  axios.get(getAPIUrl(API_AUTHENTICATE));

export const refreshToken = (
  payload: RefreshTokenPayload
): AxiosPromise<Token> => axios.post(getAPIUrl(API_REFRESH_TOKEN), payload);

export const forgotPassword = ({ email }: { email: string }): AxiosPromise =>
  axios.post(getAPIUrl(API_FORGOT_PASSWORD), { email });

export const resetPassword = ({
  password,
  repeat_password,
  token
}: {
  repeat_password: string;
  password: string;
  token: string;
}): AxiosPromise =>
  axios.post(getAPIUrl(API_RESET_PASSWORD, null, { token }), {
    password,
    repeat_password
  });

export const verifyEmail = ({ token }: { token: string }): AxiosPromise =>
  axios.get(getAPIUrl(API_VERIFY_EMAIL, null, { token }));
