import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const WysiwygExplanation = (): JSX.Element => {
  return (
    <>
      <div id="question-icon" className="rdw-inline-wrapper px-1 ml-auto">
        <i className="fas fa-question-circle" />
      </div>
      <UncontrolledTooltip target="#question-icon">
        <div>- Line break on list (Shift + Enter)</div>
        <div>- Line break on list for Mac (Control + Option + Enter)</div>
      </UncontrolledTooltip>
    </>
  );
};

export default WysiwygExplanation;
