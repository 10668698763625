import WysiwygHtmlPreview from 'modules/Tools/view/WysiwygHtmlPreview';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';

export const ROUTE_TOOLS_WYSIWYG_HTML_PREVIEW = '/tools/wysiwyg-html';

export const createToolsRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="tools-wysiwyg-html-preview"
      path={ROUTE_TOOLS_WYSIWYG_HTML_PREVIEW}
      exact
      component={WysiwygHtmlPreview}
    />
  ]
});
