// eslint-disable-next-line import/prefer-default-export
export const imageToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      const [, base64] = (fileReader.result as string).split(',');
      resolve(base64);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
