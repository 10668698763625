export type HubSoftwareEntity = {
  version?: string;
  software?: string;
  published?: number;
  required?: number;
  created_at?: string;
  updated_at?: string;
};

export default class HubSoftware {
  readonly version?: string;
  readonly software?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  published?: boolean;
  required?: boolean;

  constructor(entity: HubSoftwareEntity) {
    this.version = entity.version;
    this.software = entity.software;
    this.published = Boolean(entity.published);
    this.required = Boolean(entity.required);
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  getPublished(): string {
    return this.published ? 'Yes' : 'No';
  }

  getRequired(): string {
    return this.required ? 'Yes' : ' No';
  }

  static getFilterableAttributes(): string[] {
    return ['version', 'published', 'required'];
  }

  static getSortableAttributes(): string[] {
    return ['version', 'created_at', 'updated_at'];
  }
}

export const createHubSoftware = (entity: HubSoftwareEntity): HubSoftware =>
  new HubSoftware(entity);
