import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const createNewsToastSuccess = (): AddToastPayload => ({
  header: 'Create news',
  body: 'News created.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createNewsToastError = (): AddToastPayload => ({
  header: 'Create news',
  body: 'Creating news failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const updateNewsToastSuccess = (): AddToastPayload => ({
  header: 'Update news',
  body: 'News updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateNewsToastError = (): AddToastPayload => ({
  header: 'Update news',
  body: 'Updating news failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deleteNewsToastSuccess = (): AddToastPayload => ({
  header: 'Delete news',
  body: 'News deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteNewsToastError = (): AddToastPayload => ({
  header: 'Delete news',
  body: 'Deleting news failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const reorderNewsToastSuccess = (): AddToastPayload => ({
  header: 'News reorder',
  body: 'News reorder was saved.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const reorderNewsToastError = (): AddToastPayload => ({
  header: 'News reorder',
  body: 'News reorder was not saved.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const reorderPlantsNewsToastSuccess = (): AddToastPayload => ({
  header: 'Plants on home screen reorder',
  body: 'Plants reorder was saved.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const reorderPlantsNewsToastError = (): AddToastPayload => ({
  header: 'Plants on home screen reorder',
  body: 'Plants reorder was not saved.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
