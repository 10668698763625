import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import {
  Message,
  PartialSearchingProps,
  SortParams,
  ValidationErrors
} from 'modules/Shared/type';
import SensorSoftware from 'modules/Sensor/model/SensorSoftware';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import User from 'modules/User/model/User';
import {
  setSensorSoftwareListParamsAction,
  SetSensorSoftwareListParamsAction
} from 'modules/Sensor/action/list';
import ActionPublish from 'modules/Layout/component/Action/Publish';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import SensorSoftwareDeleteModal from 'modules/Sensor/component/Modal/DeleteSensorSoftware';
import {
  deleteSensorSoftware,
  editSensorSoftware,
  publishSensorSoftware
} from 'modules/Sensor/repository';
import ApiError from 'modules/Shared/exception/ApiError';
import {
  AddToastAction,
  addToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import {
  deleteSensorSoftwareToastError,
  deleteSensorSoftwareToastSuccess,
  editSensorSoftwareToastError,
  editSensorSoftwareToastSuccess,
  publishSensorSoftwareToastError,
  publishSensorSoftwareToastSuccess
} from 'modules/Sensor/toasts';
import SensorSoftwarePublishModal from 'modules/Sensor/component/Modal/PublishSensorSoftware';
import SensorSoftwareEditModal from 'modules/Sensor/component/Modal/EditSensorSoftware';

export interface StateProps {
  auth: User;
  sensorSoftwares: SensorSoftware[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetSensorSoftwareListParamsAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (sensorSoftware: SensorSoftware) => void;
}

export interface State {
  fetching: boolean;
  errors: ValidationErrors;
  errorMessage: Message | null;
}

export const mapState = (state: RootState): StateProps => {
  const { user: auth } = state.auth;
  const { sensorSoftwares, sort } = state.sensor.sensorSoftwareList;

  return { auth, sensorSoftwares, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setSensorSoftwareListParamsAction(payload)),
  addToast: (payload: AddToastPayload) => dispatch(addToastAction(payload))
});

export class SensorSoftwareTable extends React.Component<Props, State> {
  readonly cols: TableCol<SensorSoftware>[];
  readonly modalDeleteRef: React.RefObject<SensorSoftwareDeleteModal>;
  readonly modalPublishRef: React.RefObject<SensorSoftwarePublishModal>;
  readonly modalEditRef: React.RefObject<SensorSoftwareEditModal>;

  constructor(props: Props) {
    super(props);

    this.state = {
      fetching: false,
      errorMessage: null,
      errors: null
    };

    this.modalDeleteRef = React.createRef();
    this.modalPublishRef = React.createRef();
    this.modalEditRef = React.createRef();

    this.cols = [
      {
        property: 'version',
        label: 'Version',
        sortable: true,
        className: 'col-2'
      },
      {
        property: 'software',
        label: 'Software',
        className: 'col-4'
      },
      {
        property: 'required',
        label: 'Required',
        className: 'col-1',
        value: (row) => row.getRequired()
      },
      {
        property: 'published',
        label: 'Published',
        className: 'col-1',
        value: (row) => row.getPublished()
      },
      {
        property: 'created_at',
        label: 'Created at',
        sortable: true,
        className: 'col-2'
      },
      {
        property: 'updated_at',
        label: 'Updated at',
        sortable: true,
        className: 'col-2'
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionPublish
              id={`publish-software-${row.version}`}
              title="Publish software"
              disabled={row.published || !row.software}
              onClick={() => this.openModal(row, this.modalPublishRef)}
            />
            <ActionUpdate
              id={`edit-software-${row.version}`}
              disabled={row.published}
              title="Edit software"
              onClick={() => this.openModal(row, this.modalEditRef)}
            />
            <ActionDelete
              id={`delete-software-${row.version}`}
              title="Delete software"
              disabled={row.published}
              onClick={() => this.openModal(row, this.modalDeleteRef)}
            />
          </div>
        )
      }
    ];

    this.openModal = this.openModal.bind(this);
    this.onSubmitDelete = this.onSubmitDelete.bind(this);
    this.onSubmitPublish = this.onSubmitPublish.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(sensorSoftware: SensorSoftware, ref: React.RefObject<any>): void {
    this.setState(
      {
        errorMessage: null,
        errors: null
      },
      () => {
        ref.current.openModal(sensorSoftware);
      }
    );
  }

  closeModal(ref: React.RefObject<any>): void {
    ref.current.toggle();
  }

  onSubmitDelete(sensorSoftware: SensorSoftware): void {
    const { setParams, addToast } = this.props;

    this.setState(
      {
        fetching: true,
        errorMessage: null,
        errors: null
      },
      () => {
        deleteSensorSoftware(sensorSoftware.version)
          .then(() => {
            setParams({});
            addToast(deleteSensorSoftwareToastSuccess());
            this.closeModal(this.modalDeleteRef);
          })
          .catch((error) => {
            if (error instanceof ApiError) {
              this.setState({
                errorMessage: error.getMessage()
              });
            }
            addToast(deleteSensorSoftwareToastError());
          })
          .finally(() => {
            this.setState({
              fetching: false
            });
          });
      }
    );
  }

  onSubmitPublish(sensorSoftware: SensorSoftware): void {
    const { setParams, addToast } = this.props;

    this.setState(
      {
        fetching: true,
        errorMessage: null,
        errors: null
      },
      () => {
        publishSensorSoftware(sensorSoftware.version)
          .then(() => {
            setParams({});
            addToast(publishSensorSoftwareToastSuccess());
            this.closeModal(this.modalPublishRef);
          })
          .catch((error) => {
            if (error instanceof ApiError) {
              this.setState({
                errorMessage: error.getMessage()
              });
            }
            addToast(publishSensorSoftwareToastError());
          })
          .finally(() => {
            this.setState({
              fetching: false
            });
          });
      }
    );
  }

  onSubmitEdit(
    sensorSoftware: SensorSoftware,
    data: { version: string; required: boolean }
  ): void {
    const { setParams, addToast } = this.props;

    this.setState(
      {
        fetching: true,
        errorMessage: null,
        errors: null
      },
      () => {
        editSensorSoftware(sensorSoftware.version, data)
          .then(() => {
            setParams({});
            addToast(editSensorSoftwareToastSuccess());
            this.closeModal(this.modalEditRef);
          })
          .catch((error) => {
            if (error instanceof ApiError) {
              this.setState({
                errorMessage: error.getMessage(),
                errors: error.getErrors()
              });
            }
            addToast(editSensorSoftwareToastError());
          })
          .finally(() => {
            this.setState({
              fetching: false
            });
          });
      }
    );
  }

  render(): ReactNode {
    const { sensorSoftwares, sort, setParams } = this.props;
    const { fetching, errorMessage, errors } = this.state;

    return (
      <>
        <SensorSoftwareDeleteModal
          ref={this.modalDeleteRef}
          onSubmit={this.onSubmitDelete}
          errorMessage={errorMessage}
          fetching={fetching}
        />
        <SensorSoftwarePublishModal
          ref={this.modalPublishRef}
          onSubmit={this.onSubmitPublish}
          errorMessage={errorMessage}
          fetching={fetching}
        />
        <SensorSoftwareEditModal
          ref={this.modalEditRef}
          onSubmit={this.onSubmitEdit}
          errorMessage={errorMessage}
          fetching={fetching}
          errors={errors}
        />
        <Table
          cols={this.cols}
          rows={sensorSoftwares}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(SensorSoftwareTable);
