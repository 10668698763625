import { ConnectedPlantListEntity } from 'modules/PlantGroups/model/PlantGroupDetails';
import User, { createUser } from 'modules/User/model/User';
import { hubStatus } from 'modules/Hub/model/HubDetails';

export interface HubLog {
  id?: number;
  error?: number;
  sensor_id?: string;
  timestamp?: string;
  created_at?: string;
}

export type HubEntity = {
  id?: number;
  user_id?: number;
  status?: number;
  hub_id?: string;
  username?: string;
  name?: string;
  email?: string;
  iot_certificate_id?: string;
  iot_thing_name?: string;
  version?: string;
  last_measurement_send_date?: string;
  plants?: ConnectedPlantListEntity[];
  user?: User;
  hubLogs?: HubLog[];
};

export default class Hub {
  readonly id?: number;
  readonly user_id?: number;
  readonly status?: number;
  readonly hub_id?: string;
  readonly username?: string;
  readonly name?: string;
  readonly email?: string;
  readonly iot_certificate_id?: string;
  readonly iot_thing_name?: string;
  readonly version?: string;
  readonly last_measurement_send_date?: string;
  readonly user?: User;
  readonly plants: ConnectedPlantListEntity[];
  readonly hubLogs: HubLog[];

  constructor(entity: HubEntity) {
    this.id = entity.id;
    this.user_id = entity.user_id;
    this.status = entity.status;
    this.hub_id = entity.hub_id;
    this.username = entity.username;
    this.name = entity.name;
    this.email = entity.email;
    this.iot_certificate_id = entity.iot_certificate_id;
    this.iot_thing_name = entity.iot_thing_name;
    this.user = entity.user ? createUser(entity.user) : null;
    this.plants = entity.plants;
    this.version = entity.version;
    this.last_measurement_send_date = entity.last_measurement_send_date;
    this.hubLogs = entity.hubLogs;
  }

  static getFilterableAttributes(): string[] {
    return ['hub_id', 'username', 'email', 'with_deleted'];
  }

  static getSortableAttributes(): string[] {
    return ['idArrOrder', 'hub_id', 'username', 'email'];
  }

  getStatus(): string {
    return hubStatus(this.status);
  }
}

export const createHub = (entity: HubEntity): Hub => new Hub(entity);
