import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const forgotPasswordToastSuccess = (): AddToastPayload => ({
  header: 'Forgot password',
  body: 'Email with instructions sent.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const forgotPasswordToastError = (): AddToastPayload => ({
  header: 'Forgot password',
  body: 'Failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const resetPasswordToastSuccess = (): AddToastPayload => ({
  header: 'Reset password',
  body: 'Password changed.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const resetPasswordToastError = (): AddToastPayload => ({
  header: 'Reset password',
  body: 'Password not changed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const verifyEmailToastSuccess = (): AddToastPayload => ({
  header: 'Email',
  body: 'Email changed.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const verifyEmailToastError = (): AddToastPayload => ({
  header: 'Email',
  body: 'Email not changed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
