import listReducer from 'modules/PlantUser/reducer/list';
import detailsReducer from 'modules/PlantUser/reducer/details';
import { combineReducers, Reducer } from 'redux';
import { PlantUserState } from 'modules/PlantUser/state';

const reducer = (): Reducer<PlantUserState> => {
  const reducers = {
    list: listReducer,
    details: detailsReducer
  };

  return combineReducers<PlantUserState>(reducers);
};

export default reducer;
