import React from 'react';
import { useHistory } from 'react-router-dom';
import { primaryInfoArr } from 'modules/Family/view/Details/helper/DetailsConfig';
import FamilyDetails from 'modules/Family/model/FamilyDetails';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import DiplayOptionRow from 'modules/Layout/view/Details/Helper/DisplayOptionRow';
import DiplayOptionRowArray from 'modules/Layout/view/Details/Helper/DisplayOptionRowArray';
import { ROUTE_FAMILY_DETAILS_FORM_REDIRECT_TEMPLATE } from 'modules/Family/routes';
import {
  FAMILY_DETAILS_MAIN,
  FAMILY_DETAILS_THRESHOLD
} from 'modules/Family/view/DetailsForm/helper/acceptableTypes';
import { thresholdArr } from 'modules/Shared/configs/tresholdsEntity/detailsArr';

interface IProps {
  supportedLang: string[];
  data: FamilyDetails;
  onDelete: () => void;
  onDeleteMessage: {
    text: string;
    color: string;
  };
  removeDeleteMessage: () => void;
  onDeleteBtnLoading: boolean;
}

const Details: React.FC<IProps> = ({
  data,
  onDelete,
  onDeleteBtnLoading,
  onDeleteMessage,
  removeDeleteMessage
}) => {
  const history = useHistory();

  const arrayOfBtnObjects = [
    {
      callback: onDelete,
      text: 'Delete',
      bsColor: 'danger',
      loading: onDeleteBtnLoading,
      inactive: data?.is_deleted
    }
  ];

  if (!data?.is_deleted) {
    arrayOfBtnObjects.push({
      callback: () =>
        history.push(
          `${
            ROUTE_FAMILY_DETAILS_FORM_REDIRECT_TEMPLATE + FAMILY_DETAILS_MAIN
          }/${data?.id}`
        ),
      text: 'Edit'
    } as any);
  }

  return (
    <div className="content restrict-width">
      <div className="row">
        <DiplayOptionRowArray
          optionArr={[
            {
              title: 'Primary Info',
              content: () =>
                displayOptionRowContent(primaryInfoArr(data), true),
              arrayOfBtn: arrayOfBtnObjects,
              showMessage: onDeleteMessage,
              hideMessage: removeDeleteMessage
            }
          ]}
        />

        <DiplayOptionRow
          title="Thresholds"
          content={() =>
            displayOptionRowContent(thresholdArr(data.thresholds), true)
          }
          onEditClick={
            !data?.is_deleted
              ? () =>
                  history.push(
                    `${
                      ROUTE_FAMILY_DETAILS_FORM_REDIRECT_TEMPLATE +
                      FAMILY_DETAILS_THRESHOLD
                    }/${data?.id}`
                  )
              : null
          }
        />
      </div>
    </div>
  );
};

export default Details;
