import {
  FETCH_GARDEN_DETAILS,
  GARDEN_DETAILS_FETCHED,
  SET_GARDEN_DETAILS_PARAMS,
  GardenDetailsAction
} from 'modules/Garden/action/details';
import { createGardenDetails } from 'modules/Garden/model/GardenDetails';
import {
  initGardenDetailsState,
  GardenDetailsState
} from 'modules/Garden/state/details';

const initState = initGardenDetailsState();

const reducer = (
  state: GardenDetailsState = initState,
  action: GardenDetailsAction
): GardenDetailsState => {
  switch (action.type) {
    case SET_GARDEN_DETAILS_PARAMS:
      return { ...state, gardenId: action.payload };

    case FETCH_GARDEN_DETAILS:
      return { ...state, fetching: true };

    case GARDEN_DETAILS_FETCHED: {
      const { gardenDetails = null } = action.payload;

      return {
        ...state,
        fetching: false,
        gardenDetails: createGardenDetails(gardenDetails)
      };
    }

    default:
      return state;
  }
};

export default reducer;
