import React, { useEffect, useState } from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import ProtectedImage from 'modules/Layout/component/ProtectedImage';
import { ROUTE_PLANT_USER_DETAILS_REDIRECT_TEMPLATE } from 'modules/PlantUser/routes';
import User from 'modules/User/model/User';
import PlantUser, { createPlantUser } from 'modules/PlantUser/model/PlantUser';
import {
  fetchPlantUser,
  FetchPlantUserResponse
} from 'modules/PlantUser/repository';
import { SearchParams } from 'modules/Shared/type';
import { AxiosResponse } from 'axios';
import Loader from 'modules/Layout/component/Loader';
import { CustomInput, Label } from 'reactstrap';

export interface Props {
  user: User;
}

const UserDetailsPlants = (props: Props): JSX.Element => {
  const { user } = props;

  const [withDeleted, setWithDeleted] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [plants, setPlants] = useState<PlantUser[]>([]);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      setFetching(true);

      const search: {
        user_id: string;
        with_deleted?: string;
      } = {
        user_id: String(user.id)
      };

      if (withDeleted) {
        search.with_deleted = '1';
      }

      try {
        const {
          data: { content }
        }: AxiosResponse<FetchPlantUserResponse> = await fetchPlantUser({
          search,
          pagination: {
            page: 1,
            count: 100000
          }
        } as unknown as SearchParams);

        setPlants(content.map(createPlantUser));
        setFetching(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetch();
  }, [withDeleted, user.id]);

  const cols: TableCol<PlantUser>[] = [
    {
      label: 'Image',
      property: 'thumb_path',
      // eslint-disable-next-line react/prop-types
      value({ thumb_path }) {
        return (
          <div className="d-flex justify-content-center">
            <ProtectedImage url={thumb_path} maxHeight={100} />
          </div>
        );
      },
      className: 'col-2'
    },
    {
      label: 'Nickname',
      property: 'nickname',
      className: 'col-10'
    }
  ];

  if (withDeleted) {
    cols.push({
      label: 'Is deleted',
      property: 'is_deleted',
      value: (row) => row.getIsDeleted()
    });
  }

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="User plants">
        <Label>
          Show all with deleted
          <CustomInput
            id="user-plant-with-deleted"
            className="mb-2"
            type="switch"
            checked={withDeleted}
            onChange={(event) => setWithDeleted(event.currentTarget.checked)}
          />
        </Label>
        <div className="position-relative">
          {fetching && <Loader />}
          <Table
            cols={cols}
            rows={plants}
            onRowClickPath={ROUTE_PLANT_USER_DETAILS_REDIRECT_TEMPLATE}
          />
        </div>
      </DisplayBaseTabContent>
    </div>
  );
};

export default UserDetailsPlants;
