import { PaginationParams, SearchingProps } from 'modules/Shared/type';
import Sensor from 'modules/Sensor/model/Sensor';

export interface SensorListState extends SearchingProps {
  sensors: Sensor[];
  fetching: boolean;
  totalPages: number;
  pagination: PaginationParams;
}

export const initSensorListState = (): SensorListState => ({
  sensors: [],
  pagination: {
    page: 1,
    count: 100
  },
  totalPages: 1,
  filter: {},
  sort: {},
  fetching: false
});
