import {
  FETCH_PLANT_GROUPS_DETAILS,
  PLANT_GROUPS_DETAILS_FETCHED,
  SET_PLANT_GROUPS_DETAILS_PARAMS,
  PlantGroupsDetailsAction,
  PLANT_GROUPS_DETAILS_DELETED
} from 'modules/PlantGroups/action/Details';
import { createPlantGroupDetails } from 'modules/PlantGroups/model/PlantGroupDetails';
import {
  initPlantGroupDetailsState,
  PlantGroupDetailsState
} from 'modules/PlantGroups/state/details';

const initState = initPlantGroupDetailsState();

const reducer = (
  state: PlantGroupDetailsState = initState,
  action: PlantGroupsDetailsAction
): PlantGroupDetailsState => {
  switch (action.type) {
    case SET_PLANT_GROUPS_DETAILS_PARAMS:
      return { ...state, plantGroupId: action.payload };

    case FETCH_PLANT_GROUPS_DETAILS:
      return { ...state, fetching: true };

    case PLANT_GROUPS_DETAILS_FETCHED: {
      const { plantGroupDetails = null } = action.payload;

      return {
        ...state,
        fetching: false,
        plantGroupDetails: createPlantGroupDetails(plantGroupDetails)
      };
    }

    case PLANT_GROUPS_DETAILS_DELETED:
      return { ...state, plantGroupDetails: null };

    default:
      return state;
  }
};

export default reducer;
