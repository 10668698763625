import { IOnSubmitAction } from 'modules/Layout/view/DetailsForm';
import {
  updatePlantsDetails,
  updatePlantThresholds,
  updatePlantsMeasurementDesc,
  updatePlantDescription,
  updatePlantOtherDescription
} from 'modules/Plants/repository';
import { PlantDetailsState } from 'modules/Plants/state/details';
import { removeDuplicateFromMeasurement } from 'modules/Shared/configs/measurementStatusDescEntity/helper/getDataFromMeasurement';
import { MeasurementsStatusDescEntity } from 'modules/Shared/configs/measurementStatusDescEntity/type';
import { TresholdsEntity } from 'modules/Shared/configs/tresholdsEntity/type';

interface IHandleChangeRequest {
  data: IOnSubmitAction;
  id: number;
  details?: PlantDetailsState;
}

interface IMeasurementData {
  editOption: string;
  editLang: string;
  stateValue: MeasurementsStatusDescEntity[];
}

export type IPlantArr = { id: number; genus: string }[];
export const getPlantFamilyId = (genus: string, plantFamilyArr: IPlantArr) =>
  plantFamilyArr?.find((family) => family.genus === genus)?.id || null;

export const handleChangeRequest = (
  object: IHandleChangeRequest,
  cancelToken?: any
) => {
  const { data, id, details } = object;

  switch (data?.editType) {
    case 'main': {
      if (
        !data?.hasOwnProperty('scientific_name') ||
        !data?.hasOwnProperty('family_id')
      ) {
        throw new Error('internal error');
      }

      return updatePlantsDetails(
        id,
        {
          scientific_name: data?.scientific_name as string,
          family_id: data?.family_id as number
        },
        cancelToken
      );
    }
    case 'measurement_desc': {
      const { editOption, editLang, stateValue } =
        data as unknown as IMeasurementData;

      if (stateValue?.length > 0) {
        const measurementDescArr =
          details?.plantDetails?.measurements_statuses_desc;
        if (measurementDescArr) {
          const cleanedMeasurementDescArr = removeDuplicateFromMeasurement(
            {
              measurement: editOption as any,
              lang: editLang,
              statuses: stateValue.map((state) => state?.measurement_status)
            },
            measurementDescArr
          );

          return updatePlantsMeasurementDesc(
            id,
            {
              descriptions: [...cleanedMeasurementDescArr, ...stateValue]
            },
            cancelToken
          );
        }
      } else {
        throw new Error(
          "Input change before saving. If you don't want to change any values click Cancel."
        );
      }
    }
    case 'thresholds': {
      const { editLang, editType, ...thresholdData } = data;

      if (typeof thresholdData !== 'object') {
        throw new Error('internal error');
      }

      Object.keys(thresholdData).forEach(
        (el) => (thresholdData[el] = parseFloat(thresholdData[el] as string))
      );

      return updatePlantThresholds(
        id,
        {
          ...thresholdData
        } as TresholdsEntity,
        cancelToken
      );
    }
    case 'description': {
      const { editLang, editOption, editType, ...rest } = data;

      console.log('editOption', editOption);

      if (!editLang || !editOption || !editType) {
        throw new Error('internal error');
      }

      if (editOption === 'base') {
        const params = {
          scientific_name: details.plantDetails?.scientific_name,
          plant_langs: {
            [editLang]: {
              ...rest
            }
          }
        };
        return updatePlantsDetails(id, params as any, cancelToken);
      }
      if (editOption === 'desc' && data?.hasOwnProperty('description')) {
        const { description } = data;
        const params = {
          plant_langs: {
            [editLang]: {
              description
            }
          }
        };
        return updatePlantDescription(id, params as any);
      }
      if (editOption === 'other' && data?.hasOwnProperty('location')) {
        const params = {
          plant_langs: {
            [editLang]: {
              ...rest
            }
          }
        };
        return updatePlantOtherDescription(id, params as any, cancelToken);
      }
      throw new Error('internal error');
    }

    default:
      return null;
  }
};

export const handlePlantErrorResponse = (
  serverStatus?: number,
  message?: string,
  internalMessage?: string
) => {
  const validMessage = typeof message === 'string';

  if (internalMessage) return internalMessage;
  if (validMessage) {
    return message;
  }
  if (serverStatus === 422) return 'Invalid values.';
  if (serverStatus === 500)
    return 'Server unavailable. Please try again later.';
  if (serverStatus === 404) return 'Plant not found.';
  if (serverStatus === 406) return 'Plant is already assigned.';
  return 'Something went wrong. Contact with administrator to resolve the problem.';
};
