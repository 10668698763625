import { RootState } from 'app/reducer';
import { setLocationSearchAction } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import {
  FETCH_SENSOR_SOFTWARE_LIST,
  fetchSensorSoftwareListAction,
  sensorSoftwareListFetchedAction,
  SET_SENSOR_SOFTWARE_LIST_PARAMS
} from 'modules/Sensor/action/list';
import {
  fetchSensorSoftwares,
  FetchSensorSoftwaresResponse
} from 'modules/Sensor/repository';
import { manageThrow } from 'modules/Shared/action';

export function* setSensorSoftwareListParamsSaga(): SagaIterator {
  yield put(fetchSensorSoftwareListAction());
}

export function* fetchSensorSoftwareListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.sensor.sensorSoftwareList
    );

    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response: FetchSensorSoftwaresResponse = yield call(
      fetchSensorSoftwares,
      {
        ...sort,
        pagination,
        search: filter
      }
    );

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (_err) {}

    yield put(
      sensorSoftwareListFetchedAction({ sensorSoftwares: content, totalPages })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(
        sensorSoftwareListFetchedAction({ sensorSoftwares: [], totalPages: 1 })
      );
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* sensorSoftwareListSaga(): SagaIterator {
  yield takeLatest(
    SET_SENSOR_SOFTWARE_LIST_PARAMS,
    setSensorSoftwareListParamsSaga
  );
  yield takeLatest(FETCH_SENSOR_SOFTWARE_LIST, fetchSensorSoftwareListSaga);
}
