import { RootState } from 'app/reducer';
import { setLocationSearchAction } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_GARDEN_LIST,
  fetchGardenListAction,
  SET_GARDEN_LIST_PARAMS,
  gardenListFetchedAction
} from 'modules/Garden/action/list';
import { fetchGardens, FetchGardensResponse } from 'modules/Garden/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';

export function* setGardenListParamsSaga(): SagaIterator {
  yield put(fetchGardenListAction());
}

export function* fetchGardenListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.garden.list
    );
    const searchParams = createSearchParams({ pagination, filter, sort });

    yield put(setLocationSearchAction({ searchParams }));

    const response: FetchGardensResponse = yield call(fetchGardens, {
      ...sort,
      pagination,
      search: filter
    });

    const { content, totalCount } = response.data;

    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount[0]['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      gardenListFetchedAction({
        gardens: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(gardenListFetchedAction({ gardens: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* gardenListSaga(): SagaIterator {
  yield takeLatest(SET_GARDEN_LIST_PARAMS, setGardenListParamsSaga);
  yield takeLatest(FETCH_GARDEN_LIST, fetchGardenListSaga);
}
