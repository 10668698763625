import React from 'react';
import { CustomInput, Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';

export type inputConfigType = {
  label: string;
  type: InputType;
  name: string;
};

interface IProps {
  inputConfig: inputConfigType;
  selectConfig?: string[];
  value?: string;
  onChangeInput?: (value: string, name?: string) => void;
  onChangeSelect?: (value: string) => void;
  error?: string;
  className?: string;
  inputClassName?: string;
  required?: boolean;
}

export const InputComponent: React.FC<IProps> = ({
  inputConfig,
  selectConfig,
  value,
  onChangeInput,
  onChangeSelect,
  error = '',
  className = 'row col-md-4 mt-1',
  inputClassName,
  required = false
}) => {
  const selectOptions = () => {
    const arr = [];
    for (let i = 0; i < selectConfig.length; i++) {
      const currentValue = selectConfig[i];
      arr.push(
        <option key={currentValue} value={currentValue}>
          {currentValue}
        </option>
      );
    }
    return arr;
  };

  const errStyle = {
    border: '1px solid red'
  };
  return (
    <div className={className}>
      <Label>{inputConfig.label}</Label>
      {inputConfig.type === 'select' ? (
        <CustomInput
          className="pagination-page-selector"
          name={inputConfig.name}
          id={inputConfig.name}
          type="select"
          onChange={(event) => onChangeSelect(event.target.value)}
          value={value}
          style={error ? errStyle : {}}
          required={required}
        >
          {selectOptions()}
        </CustomInput>
      ) : (
        <Input
          type={inputConfig.type}
          name={inputConfig.name}
          id={inputConfig.name}
          value={value}
          onChange={(e) => onChangeInput(e.target.value, inputConfig.name)}
          style={error ? errStyle : {}}
          className={inputClassName}
          required={required}
        />
      )}
      {error && (
        <div
          className="row break-word mr-1 ml-1 text-danger"
          style={{ bottom: 0 }}
        >
          {error}
        </div>
      )}
    </div>
  );
};
