import UsersListView from 'modules/User/view/List';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import UserDetailsView from 'modules/User/view/Details';
import UsersAdministratorListView from 'modules/User/view/List/Administrator';
import AdministratorDetailsView from 'modules/User/view/Details/Administrator';
import AdministratorEditView from 'modules/User/view/Edit/Administrator';
import CreateAdministratorView from 'modules/User/view/Create/Administrator';
import ProfileView from 'modules/User/view/Profile';
import UsersFeedbackListView from 'modules/User/view/List/Feedback';
import UsersFeedbackView from 'modules/User/view/Details/Feedback';

export const ROUTE_USERS = '/users/list';
export const ROUTE_USERS_DETAILS = '/users/details';
export const ROUTE_USER_DETAILS = '/users/details/:id';
export const ROUTE_ADMINISTRATOR_DETAILS_REDIRECT = '/administrators/details/';
export const ROUTE_ADMINISTRATOR_DETAILS = '/administrators/details/:id';
export const ROUTE_EDIT_ADMINISTRATOR = '/administrators/edit/:id';
export const ROUTE_ADMINISTRATORS = '/administrators/list';
export const ROUTE_ADD_ADMINISTRATOR = '/administrators/add';
export const ROUTE_PROFILE = '/profile';

export const ROUTE_USERS_FEEDBACK = '/users-feedback/list';
export const ROUTE_USER_FEEDBACK_DETAILS = '/users-feedback/:id(\\d+)';

export const createUserRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="users-list"
      path={ROUTE_USERS}
      exact
      component={UsersListView}
    />,
    <Route
      key="user-details"
      path={ROUTE_USER_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <UserDetailsView {...props} id={props.match.params.id} />
      )}
    />,
    <Route
      key="administrators-list"
      path={ROUTE_ADMINISTRATORS}
      exact
      component={UsersAdministratorListView}
    />,
    <Route
      key="administrator-details"
      path={ROUTE_ADMINISTRATOR_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <AdministratorDetailsView {...props} id={props.match.params.id} />
      )}
    />,
    <Route
      key="administrator-add"
      path={ROUTE_ADD_ADMINISTRATOR}
      exact
      component={CreateAdministratorView}
    />,
    <Route key="profile" path={ROUTE_PROFILE} exact component={ProfileView} />,
    <Route
      key="administrator-edit"
      path={ROUTE_EDIT_ADMINISTRATOR}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <AdministratorEditView {...props} id={props.match.params.id} />
      )}
    />,
    <Route
      key="users-feedback-list"
      path={ROUTE_USERS_FEEDBACK}
      exact
      component={UsersFeedbackListView}
    />,
    <Route
      key="users-feedback-details"
      path={ROUTE_USER_FEEDBACK_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <UsersFeedbackView {...props} id={props.match.params.id} />
      )}
    />
  ]
});
