import React, { FormEvent, ReactNode } from 'react';
import { Form, Button, FormGroup } from 'reactstrap';
import ImageCropUpload from 'modules/Layout/component/ImageCropUpload';
import { PageProps, PageType } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { breadcrumbRoutePlantAddImage } from 'modules/Plants/breadcrumbs';
import Validation from 'modules/Shared/exception/Validation';
import { addPlantImage, fetchPlantDetails } from 'modules/Plants/repository';
import objectUrlToBase64 from 'modules/Shared/helper/image';
import Loader from 'modules/Layout/component/Loader';
import { ValidationPayload } from 'modules/Shared/type';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { getError } from 'modules/Shared/helper/validation';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANTS_DETAILS } from 'modules/Plants/routes';
import {
  addPlantImageToastError,
  addPlantImageToastSuccess
} from 'modules/Plants/toasts';
import PlantDetails, {
  createPlantDetails
} from 'modules/Plants/model/PlantDetails';
import InformationList from 'modules/Layout/component/InformationList';
import { RootState } from 'app/reducer';
import PlantGroups from 'modules/PlantGroups/model/PlantGroups';

export interface StateProps {
  pageType: PageType;
}

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
  managePage: (payload: PageProps) => ManagePageAction;
}

export interface Props extends StateProps, DispatchProps, RouteComponentProps {
  id: string;
}

export interface State {
  image: string;
  fetching: boolean;
  plantDetails?: PlantDetails;
  plantGroups?: PlantGroups[];
  errors?: ValidationPayload;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return {
    pageType: type
  };
};

class AddPlantImageView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      image: null,
      plantDetails: null,
      plantGroups: [],
      fetching: false,
      errors: null
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id } = this.props;

    managePage({
      title: 'Plant - add image',
      type: 'loading',
      breadcrumb: breadcrumbRoutePlantAddImage()
    });

    const {
      data: { plant, plant_groups }
    } = await fetchPlantDetails(Number(id));

    const plantDetails = createPlantDetails(plant);

    this.setState(
      {
        plantDetails,
        plantGroups: plant_groups
      },
      () => {
        managePage({
          title: `${plantDetails.scientific_name} - add image`,
          breadcrumb: breadcrumbRoutePlantAddImage(plantDetails)
        });
      }
    );
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { history, addToast, id } = this.props;
    const { image } = this.state;

    this.setState({
      fetching: true,
      errors: null
    });

    const imageBase64 = await objectUrlToBase64(image);

    try {
      await addPlantImage({ plant_id: Number(id), image: imageBase64 });

      addToast(addPlantImageToastSuccess());

      history.push(getPathUrl(ROUTE_PLANTS_DETAILS, { id }));
    } catch (error) {
      addToast(addPlantImageToastError());

      if (error instanceof Validation) {
        this.setState({
          fetching: false,
          errors: error.getPayload()
        });
      } else {
        this.setState({
          fetching: false
        });
      }
    }
  }

  render(): ReactNode {
    const { history, id, pageType } = this.props;
    const { fetching, errors, plantDetails, plantGroups } = this.state;

    if (pageType || !plantDetails) {
      return null;
    }

    const [groupOne, groupTwo] = plantGroups;

    const fields = [
      {
        label: 'Scientific name:',
        value: plantDetails.scientific_name
      },
      {
        label: 'Genus:',
        value: plantDetails.genus
      },
      {
        label: 'Category #1:',
        value: groupOne?.name
      },
      {
        label: 'Category #2:',
        value: groupTwo?.name
      }
    ];

    return (
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              {fetching && <Loader />}
              <InformationList fields={fields} />
              <Form className="mt-3" onSubmit={this.onSubmit}>
                {errors && (
                  <ShowMessage
                    message={errors.message.value}
                    bsColor={errors.message.variant}
                  />
                )}
                <FormGroup>
                  <div className="w-100 font-weight-bold">
                    New plant image*:
                    <ImageCropUpload
                      required
                      onChange={(image) => this.setState({ image })}
                      error={errors ? getError(errors.errors, 'image') : null}
                    />
                  </div>
                </FormGroup>
                <div className="mt-4 d-flex justify-content-between">
                  <Button
                    type="button"
                    onClick={() =>
                      history.push(getPathUrl(ROUTE_PLANTS_DETAILS, { id }))
                    }
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="success">
                    Add image
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(mapState, mapDispatch)(AddPlantImageView)
);
