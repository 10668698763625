import { RootState } from 'app/reducer';
import { setLocationSearchAction } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  FETCH_FAMILY_LIST,
  fetchFamilyListAction,
  SET_FAMILY_LIST_PARAMS,
  familyListFetchedAction
} from 'modules/Family/action/List';
import {
  fetchFamily,
  FetchFamiliesResponse,
  fetchFamilyAll
} from 'modules/Family/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';
import { AxiosResponse } from 'axios';

export function* setFamilyListParamsSaga(): SagaIterator {
  yield put(fetchFamilyListAction());
}

export function* fetchFamilyListSaga(): SagaIterator {
  try {
    const { pagination, filter, sort } = yield select(
      (state: RootState) => state.family.list
    );

    let response: AxiosResponse<FetchFamiliesResponse> = null;
    if (pagination === 'all') {
      response = yield call(fetchFamilyAll);
    } else {
      const searchParams = createSearchParams({ pagination, filter, sort });

      yield put(setLocationSearchAction({ searchParams }));

      const mappedFilter = {
        ...filter
      };

      if (mappedFilter.is_deleted) {
        mappedFilter.is_deleted = true;
      }

      response = yield call(fetchFamily, {
        ...sort,
        pagination,
        search: mappedFilter
      });
    }

    const { content, totalCount } = response.data;
    let totalPages = 1;
    try {
      const getIntTotalCount = totalCount?.[0]?.['COUNT(*)'];
      totalPages = Math.ceil(getIntTotalCount / pagination.count);
    } catch (err) {}

    yield put(
      familyListFetchedAction({
        families: content,
        totalPages
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(familyListFetchedAction({ families: [], totalPages: 1 }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantListSaga(): SagaIterator {
  yield takeLatest(SET_FAMILY_LIST_PARAMS, setFamilyListParamsSaga);
  yield takeLatest(FETCH_FAMILY_LIST, fetchFamilyListSaga);
}
