import React, { FormEvent, ReactNode } from 'react';
import PlantGroupDetails from 'modules/PlantGroups/model/PlantGroupDetails';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ROUTE_PLANT_GROUPS_DETAILS } from 'modules/PlantGroups/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { updatePlantGroup } from 'modules/PlantGroups/repository';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  editPlantGroupToastError,
  editPlantGroupToastSuccess
} from 'modules/PlantGroups/toasts';

export interface DispatchProps {
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends RouteComponentProps, DispatchProps {
  plantGroup: PlantGroupDetails;
}

export interface State {
  updating: boolean;
  name: string;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  addToast: (payload) => dispatch(addToastAction(payload))
});

class EditPlantGroupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { plantGroup } = this.props;

    this.state = {
      updating: false,
      name: plantGroup.name
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    const { plantGroup, addToast, history } = this.props;
    const { name } = this.state;

    this.setState({
      updating: true
    });

    try {
      await updatePlantGroup(plantGroup.id, { name });

      addToast(editPlantGroupToastSuccess());
    } catch (error) {
      console.log(error);
      addToast(editPlantGroupToastError());
    } finally {
      history.push(
        getPathUrl(ROUTE_PLANT_GROUPS_DETAILS, { id: plantGroup.id })
      );
    }
  }

  render(): ReactNode {
    const { history, plantGroup } = this.props;
    const { name, updating } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        {updating && <Loader />}
        <FormGroup>
          <Label>Name*</Label>
          <Input
            required
            type="text"
            value={name}
            onChange={(event) => this.setState({ name: event.target.value })}
          />
        </FormGroup>
        <div className="mt-4 d-flex justify-content-between">
          <Button
            onClick={() =>
              history.push(
                getPathUrl(ROUTE_PLANT_GROUPS_DETAILS, { id: plantGroup.id })
              )
            }
            disabled={updating}
            color="primary"
            type="button"
          >
            Cancel
          </Button>
          <Button disabled={updating} type="submit">
            Save
          </Button>
        </div>
      </Form>
    );
  }
}

export default withRouter(
  connect<null, DispatchProps>(null, mapDispatch)(EditPlantGroupForm)
);
