import { SagaIterator } from 'redux-saga';
import { fork } from 'redux-saga/effects';
import sensorSoftwareListSaga from 'modules/Sensor/saga/sensorSoftwareList';
import sensorDetailsSaga from 'modules/Sensor/saga/sensorDetails';
import sensorListSaga from 'modules/Sensor/saga/sensorList';

export default function* sensorSaga(): SagaIterator {
  yield fork(sensorSoftwareListSaga);
  yield fork(sensorDetailsSaga);
  yield fork(sensorListSaga);
}
