import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PartialSearchingProps, PaginationParams } from 'modules/Shared/type';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  setUserFeedbackListParamsAction,
  SetUserFeedbackListParamsAction
} from '../../../action/list';

export interface StateProps {
  pagination: PaginationParams;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetUserFeedbackListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { pagination } = state.user.list;

  return { pagination };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setUserFeedbackListParamsAction(payload))
});

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(ListingPerPage);
