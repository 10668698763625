import React, { useState } from 'react';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import { getDescriptionLang } from 'modules/Plants/view/Details/helper/DetailsConfig';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';

interface IProps {
  supportedLang: string[];
  data: PlantDetails;
}

const DetailsDescriptions: React.FC<IProps> = ({
  data,
  supportedLang
}: IProps) => {
  const [langs, setLangs] = useState(
    supportedLang.map((_, index) => index === 0)
  );

  const getLang = supportedLang[langs.findIndex((lang) => lang)];

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Descriptions">
        <DisplayNav
          booleanArr={langs}
          setBooleanArr={setLangs}
          valueArr={supportedLang}
        />
        <div className="row">
          <div className="col-lg-6">
            <DisplayBaseTabContent title="" removeCard>
              <div className="tab-content pt-0">
                {displayOptionRowContent(
                  getDescriptionLang(data, getLang as any, 'desc')
                )}
              </div>
            </DisplayBaseTabContent>
          </div>
          <div className="col-lg-6" style={{ borderLeft: '1px solid #dee2e6' }}>
            <DisplayBaseTabContent title="" removeCard>
              <div className="tab-content pt-0">
                {displayOptionRowContent(
                  getDescriptionLang(data, getLang as any, 'other')
                )}
              </div>
            </DisplayBaseTabContent>
          </div>
        </div>
      </DisplayBaseTabContent>
    </div>
  );
};

export default DetailsDescriptions;
