import React, { useState, useEffect } from 'react';
import {
  InputComponent,
  inputConfigType
} from 'modules/Layout/view/InputComponent';
import { Button, Spinner } from 'reactstrap';
import { AxiosPromise } from 'axios';
import { useHistory } from 'react-router';
import { manageThrow } from 'modules/Shared/action';
import { useDispatch } from 'react-redux';
import Validation from 'modules/Shared/exception/Validation';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import ImageUploader from 'modules/Layout/component/ImageUploader';
import { imageToBase64 } from 'modules/Layout/component/ImageUploader/helper/imageToBase64';
import useCancelToken from 'modules/Shared/helper/useCancelToken';

type IElement = { [key: string]: any };

export interface IInputObj extends inputConfigType {
  selectConfig?: string[];
}

interface IProps {
  inputsObj?: IInputObj[];
  onSubmit: (obj: any, cancelToken?: any) => AxiosPromise<any>;
  dataFormat: (obj: any) => any;
  successUrl: string;
  dataNameInResponse?: string;
  addImage?: boolean;
}

const convertArrayToObject = (
  array: IElement[],
  key: string,
  selectDefault?: string
) => {
  let initialValue = {};

  array.forEach((value) => {
    if (value.type === 'select')
      initialValue = { ...initialValue, [value[key]]: selectDefault || '' };
    else initialValue = { ...initialValue, [value[key]]: '' };
  });

  return initialValue;
};

export const CreateNewPage: React.FC<IProps> = ({
  inputsObj,
  onSubmit,
  dataFormat,
  successUrl,
  dataNameInResponse,
  addImage
}) => {
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState<{ [key: string]: string[] }>({});
  const [formState, setFormState] = useState<IElement>(
    convertArrayToObject(inputsObj, 'name')
  );
  const [image, setImage] = useState<File>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const onChangeInput = (value: string, name: string) =>
    setFormState({ ...formState, [name]: value });

  useEffect(() => {
    let initObj: { name: string; initValue: string }[] = [];

    inputsObj.forEach((obj) => {
      if (obj.type === 'select') {
        initObj.push({
          name: obj.name,
          initValue: obj?.selectConfig?.[0] || ''
        });
      }
    });

    if (initObj.length) {
      setFormState({
        ...formState,
        ...initObj.reduce((prev, cur) => {
          return { ...prev, [cur.name]: cur.initValue };
        }, {})
      });
    }
  }, []);

  const cancelToken = useCancelToken();
  return (
    <div className="row justify-content-center restrict-width">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body p-4">
            {errMessage.global && errMessage.global?.length > 0 && (
              <ShowMessage
                message={errMessage.global[0]}
                deleteMessage={() => setErrMessage({})}
              />
            )}

            <form
              className={`form-row${
                Object.keys(errMessage).length > 0 ? ' pb-3' : ''
              } ${
                inputsObj.length > 1
                  ? 'justify-content-between flex-wrap'
                  : 'justify-content-center'
              }`}
            >
              {inputsObj.map((inputConfig) =>
                inputConfig.type === 'text' ? (
                  <InputComponent
                    key={inputConfig.name}
                    inputConfig={inputConfig}
                    value={formState[inputConfig.name] || ''}
                    onChangeInput={onChangeInput}
                    error={errMessage[inputConfig.name]?.[0] || ''}
                  />
                ) : inputConfig.type === 'select' ? (
                  <InputComponent
                    key={inputConfig.name}
                    inputConfig={inputConfig}
                    selectConfig={inputConfig.selectConfig || []}
                    value={formState[inputConfig.name] || ''}
                    onChangeSelect={(value: string) =>
                      onChangeInput(value, inputConfig.name)
                    }
                    error={errMessage[inputConfig.name]?.[0] || ''}
                  />
                ) : null
              )}
            </form>

            {addImage && (
              <div
                className="col-md-12 mt-2 mx-auto"
                style={{ maxWidth: '400px' }}
              >
                <label>Add image (optional)</label>
                <div
                  className="pt-1 pb-1"
                  style={{ border: image ? 'none' : '1px solid #ced4da' }}
                >
                  <ImageUploader image={image} setImage={setImage} />
                </div>
              </div>
            )}

            <div className="d-flex justify-content-center mt-3">
              <Button
                color="success"
                onClick={async () => {
                  if (!loading) {
                    try {
                      setLoading(true);
                      const response = await onSubmit(
                        dataFormat(
                          addImage
                            ? {
                                ...formState,
                                image: image ? await imageToBase64(image) : null
                              }
                            : formState
                        ),
                        cancelToken
                      );
                      if (
                        response?.data?.success ||
                        (response?.status >= 200 && response?.status < 300)
                      ) {
                        setLoading(false);
                        history.push(
                          dataNameInResponse
                            ? `${successUrl}${response?.data?.[dataNameInResponse]?.id}`
                            : '/'
                        );
                      } else {
                        setLoading(false);
                        setErrMessage({
                          global: [`Server status: ${response?.status}`]
                        });
                      }
                    } catch (err) {
                      if (err?.message !== 'AxiosCancel') {
                        if (err?.message === 'internal error') {
                          if (
                            err instanceof Validation &&
                            typeof err?.error?.response?.data?.errors ===
                              'object'
                          ) {
                            setErrMessage(err?.error?.response?.data?.errors);
                          } else {
                            dispatch(manageThrow(err));
                          }
                          setLoading(false);
                        }
                        {
                          if (
                            err instanceof Validation &&
                            typeof err?.error?.response?.data?.errors ===
                              'object'
                          ) {
                            setErrMessage(err?.error?.response?.data?.errors);
                          } else {
                            dispatch(manageThrow(err));
                          }
                          setLoading(false);
                        }
                      }
                    }
                  }
                }}
              >
                {loading ? <Spinner size="sm" /> : 'Add'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
