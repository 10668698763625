import listReducer from 'modules/Media/reducer/list';
import detailsReducer from 'modules/Media/reducer/details';
import { combineReducers, Reducer } from 'redux';
import { MediaState } from 'modules/Media/state';

const reducer = (): Reducer<MediaState> => {
  const reducers = {
    list: listReducer,
    details: detailsReducer
  };

  return combineReducers<MediaState>(reducers);
};

export default reducer;
