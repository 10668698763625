import { RootState } from 'app/reducer';
import {
  PartialSearchingProps,
  PaginationParams,
  SearchParams,
  SortParams
} from 'modules/Shared/type';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Pagination } from 'modules/Layout/component/Pagination/pagination';
import {
  SetDictionaryListParamsAction,
  setDictionaryListParamsAction
} from 'modules/Dictionary/action/list';

export interface StateProps {
  pagination: PaginationParams;
  filter: SearchParams;
  sort: SortParams;
  currentCount: number;
  totalPages: number;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetDictionaryListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  table: string;
}

export const mapState = (state: RootState): StateProps => {
  const { pagination, filter, sort, dictionaries, totalPages } =
    state.dictionary.list;

  return {
    pagination,
    filter,
    sort,
    currentCount: dictionaries.length,
    totalPages
  };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setDictionaryListParamsAction(payload))
});

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(Pagination);
