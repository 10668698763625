import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import PrivacyPolicyPagination from 'modules/Policy/container/Pagination/PrivacyPolicy';
import PrivacyPolicyPerPage from 'modules/Policy/container/PerPage/PrivacyPolicy';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { RootState } from 'app/reducer';
import {
  setPPListParamsAction,
  SetPPListParamsAction
} from 'modules/Policy/action/list';
import { breadcrumbRoutePrivacyPolicies } from 'modules/Policy/breadcrumbs';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_ADD_PP } from 'modules/Policy/routes';
import PrivacyPolicy from 'modules/Policy/model/PrivacyPolicy';
import PrivacyPolicyTable from 'modules/Policy/container/Table/PrivacyPolicy';
import PrivacyPolicyFilter from 'modules/Policy/container/Filter/PrivacyPolicy';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (payload: PartialSearchingProps) => SetPPListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPPListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.policy.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class PrivacyPolicyListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      PrivacyPolicy.getFilterableAttributes(),
      PrivacyPolicy.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Privacy policies',
      breadcrumb: breadcrumbRoutePrivacyPolicies()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { filter } = this.searchProps;
    const { fetching, type } = this.props;

    if (type) return null;

    return (
      <div className="row privacy-policies-view">
        <div className="col-12">
          <Listing
            addBtn={{
              text: 'Add privacy policy',
              url: getPathUrl(ROUTE_ADD_PP)
            }}
            table={<PrivacyPolicyTable />}
            filter={<PrivacyPolicyFilter values={filter} />}
            pagination={<PrivacyPolicyPagination />}
            perPage={<PrivacyPolicyPerPage />}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PrivacyPolicyListView);
