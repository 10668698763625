import listReducer from 'modules/Family/reducer/list';
import detailsReducer from 'modules/Family/reducer/details';
import { combineReducers, Reducer } from 'redux';
import { FamilyState } from 'modules/Family/state';

const reducer = (): Reducer<FamilyState> => {
  const reducers = {
    list: listReducer,
    details: detailsReducer
  };

  return combineReducers<FamilyState>(reducers);
};

export default reducer;
