import React, { createRef, ReactNode, RefObject } from 'react';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRoutePhResults } from 'modules/Plants/breadcrumbs';
import { connect } from 'react-redux';
import { RootState } from 'app/reducer';
import PhResults, { createPhResults } from 'modules/Plants/model/PhResults';
import ApiError from 'modules/Shared/exception/ApiError';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import PhResultsDetails from 'modules/Plants/component/Details/PhResults';
import { fetchPhResults } from 'modules/Plants/repository';
import EditPhResultsModal, {
  EditPhResultsModal as EditPhResultsModalClass
} from 'modules/Plants/component/Modal/EditPh';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export type Props = StateProps & DispatchProps;

export interface State {
  phResults: PhResults[];
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

export class PhResultsListView extends React.Component<Props, State> {
  readonly editModal: RefObject<EditPhResultsModalClass>;

  constructor(props: Props) {
    super(props);

    this.state = {
      phResults: []
    };

    this.editModal = createRef();

    this.fetch = this.fetch.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  async fetch(): Promise<void> {
    const { managePage, manageThrow } = this.props;

    managePage({
      title: 'pH results - list',
      breadcrumb: breadcrumbRoutePhResults(),
      type: 'loading'
    });

    try {
      const {
        data: { content }
      } = await fetchPhResults();

      this.setState({
        phResults: content.map(createPhResults)
      });

      managePage({
        title: 'pH results - list',
        type: null
      });
    } catch (error) {
      console.log(error);
      if (error instanceof ApiError) {
        manageThrow(error);
      }
    }
  }

  async componentDidMount(): Promise<void> {
    await this.fetch();
  }

  onEdit(phResults: PhResults): void {
    this.editModal?.current?.openModal(phResults);
  }

  render(): ReactNode {
    const { type } = this.props;
    const { phResults } = this.state;

    if (type) return null;

    return (
      <div className="row ph-results-view">
        <EditPhResultsModal ref={this.editModal} onSuccess={this.fetch} />
        {phResults
          .sort((a, b) => a.status - b.status)
          .map((obj) => (
            <div key={obj.id} className="col-xl-4 col-lg-6 col-12">
              <PhResultsDetails
                phResults={obj}
                onEdit={() => this.onEdit(obj)}
              />
            </div>
          ))}
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PhResultsListView);
