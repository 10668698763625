import { BreadcrumbItem } from 'modules/Layout/type';
import FamilyDetails from 'modules/Family/model/FamilyDetails';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_FAMILY_DETAILS } from 'modules/Family/routes';

export const breadcrumbRouteFamily = (): BreadcrumbItem[] => [
  {
    title: 'Family list'
  }
];

export const breadcrumbRouteAddFamily = (): BreadcrumbItem[] => [
  {
    title: 'Family list',
    link: '/family/list'
  },
  {
    title: 'Add family'
  }
];

export const breadcrumbRouteDetailsFamily = (
  family?: FamilyDetails
): BreadcrumbItem[] => [
  {
    title: 'Families',
    link: '/family/list'
  },
  {
    title: family ? family.genus : 'Details'
  }
];

export const breadcrumbRouteFormDetailsFamily = (
  family?: FamilyDetails
): BreadcrumbItem[] => [
  {
    title: 'Families',
    link: '/family/list'
  },
  {
    title: family ? family.genus : 'Details',
    link: family ? getPathUrl(ROUTE_FAMILY_DETAILS, { id: family.id }) : null
  },
  {
    title: 'Edit'
  }
];

export const breadcrumbRouteFormDetailsThresholdsFamily = (
  family?: FamilyDetails
): BreadcrumbItem[] => [
  {
    title: 'Families',
    link: '/family/list'
  },
  {
    title: family ? family.genus : 'Details',
    link: family ? getPathUrl(ROUTE_FAMILY_DETAILS, { id: family.id }) : null
  },
  {
    title: 'Edit thresholds'
  }
];
