import DictionariesListView from 'modules/Dictionary/view/List';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import DictionaryDetails from 'modules/Dictionary/view/Details';
import DictionaryUpdateView from 'modules/Dictionary/view/Update';
import AddDictionaryView from 'modules/Dictionary/view/Add';

export const ROUTE_DICTIONARY_ACCOUNT_DELETE_REASON_LIST =
  '/dictionary-account-delete-reason';
export const ROUTE_DICTIONARY_PLANT_DELETE_REASON_LIST =
  '/dictionary-plant-delete-reason';
export const ROUTE_DICTIONARY_HUB_DELETE_REASON_LIST =
  '/dictionary-hub-delete-reason';
export const ROUTE_DICTIONARY_SENSOR_DELETE_REASON_LIST =
  '/dictionary-sensor-delete-reason';
export const ROUTE_DICTIONARY_PLANT_STATUS_LIST = '/dictionary-plant-status';
export const ROUTE_DICTIONARY_PLANTS_KNOWlEDGE_LIST =
  '/dictionary-plants-knowledge';
export const ROUTE_DICTIONARY_PLANTS_OWNED_LIST = '/dictionary-plants-owned';
export const ROUTE_DICTIONARY_DETAILS = '/dictionary/details/:id';
export const ROUTE_DICTIONARY_UPDATE = '/dictionary/update/:id';
export const ROUTE_DICTIONARY_ADD = '/dictionary/add';

export const createDictionaryRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="dictionary-account-delete-reason-list"
      path={ROUTE_DICTIONARY_ACCOUNT_DELETE_REASON_LIST}
      exact
      render={(props) => (
        <DictionariesListView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          table="dictionary_account_delete_reason"
        />
      )}
    />,
    <Route
      key="dictionary-plant-delete-reason-list"
      path={ROUTE_DICTIONARY_PLANT_DELETE_REASON_LIST}
      exact
      render={(props) => (
        <DictionariesListView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          table="dictionary_plant_delete_reason"
        />
      )}
    />,
    <Route
      key="dictionary-hub-delete-reason-list"
      path={ROUTE_DICTIONARY_HUB_DELETE_REASON_LIST}
      exact
      render={(props) => (
        <DictionariesListView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          table="dictionary_hub_delete_reason"
        />
      )}
    />,
    <Route
      key="dictionary-sensor-delete-reason-list"
      path={ROUTE_DICTIONARY_SENSOR_DELETE_REASON_LIST}
      exact
      render={(props) => (
        <DictionariesListView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          table="dictionary_sensor_delete_reason"
        />
      )}
    />,
    <Route
      key="dictionary-plant-status-list"
      path={ROUTE_DICTIONARY_PLANT_STATUS_LIST}
      exact
      render={(props) => (
        <DictionariesListView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          table="dictionary_plant_status"
        />
      )}
    />,
    <Route
      key="dictionary-plants-knowledge-list"
      path={ROUTE_DICTIONARY_PLANTS_KNOWlEDGE_LIST}
      exact
      render={(props) => (
        <DictionariesListView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          table="dictionary_plants_knowledge"
        />
      )}
    />,
    <Route
      key="dictionary-plants-owned-list"
      path={ROUTE_DICTIONARY_PLANTS_OWNED_LIST}
      exact
      render={(props) => (
        <DictionariesListView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          table="dictionary_plants_owned"
        />
      )}
    />,
    <Route
      key="dictionary-details"
      path={ROUTE_DICTIONARY_DETAILS}
      exact
      render={(props) => (
        <DictionaryDetails
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          id={props.match.params.id}
          table={new URLSearchParams(props.location.search).get('table')}
        />
      )}
    />,
    <Route
      key="dictionary-update"
      path={ROUTE_DICTIONARY_UPDATE}
      exact
      render={(props) => (
        <DictionaryUpdateView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          id={props.match.params.id}
          table={new URLSearchParams(props.location.search).get('table')}
        />
      )}
    />,
    <Route
      key="dictionary-add"
      path={ROUTE_DICTIONARY_ADD}
      exact
      render={(props) => (
        <AddDictionaryView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          table={new URLSearchParams(props.location.search).get('table')}
        />
      )}
    />
  ]
});
