import {
  FETCH_NEWS_LIST,
  SET_NEWS_LIST_PARAMS,
  NEWS_LIST_FETCHED,
  NewsListAction
} from 'modules/News/action/List';
import { createNews } from 'modules/News/model/News';
import { initNewsListState, NewsListState } from 'modules/News/state/list';

const initState = initNewsListState();

const reducer = (
  state: NewsListState = initState,
  action: NewsListAction
): NewsListState => {
  switch (action.type) {
    case SET_NEWS_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_NEWS_LIST:
      return { ...state, fetching: true };

    case NEWS_LIST_FETCHED: {
      const { news = [] } = action.payload;

      return {
        ...state,
        fetching: false,
        news: news.map(createNews),
        totalPages: action.payload.totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
