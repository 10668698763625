import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { PlantUserEntity } from 'modules/PlantUser/model/PlantUser';
import { PlantUserDetailsEntity } from 'modules/PlantUser/model/PlantUserDetails';
import { UserEntity } from 'modules/User/model/User';

export const API_PLANT_USER_LIST = '/admin-user-plant/list';
export const API_PLANT_USER_DETAILS = '/admin-user-plant/:id';
export const API_CHANGE_PLANT_TYPE = '/admin-user-plant/change-user-plant-type';

export interface FetchPlantUserResponse {
  content: PlantUserEntity[];
  totalCount: any;
}

export const fetchPlantUser = (
  params: SearchParams = {}
): AxiosPromise<FetchPlantUserResponse> =>
  axios.post(getAPIUrl(API_PLANT_USER_LIST), params);

export type FetchPlantUserDetailsResponse = AxiosResponse<{
  userPlant: PlantUserDetailsEntity;
  user: UserEntity;
}>;

export const fetchPlantUserDetails = (
  id: number
): AxiosPromise<FetchPlantUserResponse> =>
  axios.get(getAPIUrl(API_PLANT_USER_DETAILS, { id }));

export interface UpdatePlantTypeResponse {
  affected_plants: PlantUserEntity[];
}

export const updatePlantType = (
  fromPlant: number,
  toPlant: number,
  confirm: boolean
): AxiosPromise<UpdatePlantTypeResponse> =>
  axios.post(getAPIUrl(API_CHANGE_PLANT_TYPE), {
    fromPlant: String(fromPlant),
    toPlant: String(toPlant),
    confirm
  });
