import Autocomplete, {
  AutocompleteOmitProps,
  AutocompleteOption
} from 'modules/Layout/component/Autocomplete';
import React, { ReactNode } from 'react';
import ApiError from 'modules/Shared/exception/ApiError';
import Plant, { createPlant } from 'modules/Plants/model/Plant';
import { SearchParams } from 'modules/Shared/type';
import { fetchPlantsAll } from '../../../repository';

export interface Props extends Omit<AutocompleteOmitProps, 'onChange'> {
  plantId?: number | string;
  disabledOption?: (plant: Plant) => boolean;
  onChange: (plant: Plant) => void;
}

export interface State {
  error?: ApiError;
  fetching?: boolean;
  plants: Plant[];
}

class PlantAutocomplete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fetching: false,
      plants: []
    };

    this.toOption = this.toOption.bind(this);
  }

  async fetchPlants(payload: SearchParams): Promise<void> {
    try {
      this.setState({
        fetching: true
      });

      const {
        data: { content }
      } = await fetchPlantsAll({
        search: payload as unknown as string
      });

      this.setState({
        plants: content.map(createPlant)
      });
    } catch (error) {
      this.setState({
        error
      });
    } finally {
      this.setState({
        fetching: false
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  toOption(
    plantId?: number | string,
    disabledOption?: (plant: Plant) => boolean
  ): AutocompleteOption {
    const { plants } = this.state;

    const foundPlant = plants.find((obj) => String(obj.id) === String(plantId));

    if (!foundPlant) {
      return {
        label: '',
        value: '',
        node: null
      };
    }

    return {
      label: foundPlant.scientific_name,
      value: foundPlant.id.toString(),
      node: (
        <div className="d-flex align-items-center">
          <img
            className="mr-2"
            style={{ maxHeight: '40px' }}
            src={foundPlant.thumb_path}
            alt=""
          />
          <div style={{ whiteSpace: 'break-spaces' }}>
            {foundPlant.scientific_name}
          </div>
        </div>
      ),
      disabled: disabledOption ? disabledOption(foundPlant) : false
    };
  }

  render(): ReactNode {
    const {
      onChange,
      plantId,
      required = false,
      disabled = false,
      disabledOption = null,
      closeOnSelect = false,
      label = 'Choose plant'
    } = this.props;
    const { fetching, error, plants } = this.state;

    const plantsOptions = plants.map((obj) =>
      this.toOption(obj.id, disabledOption)
    );

    return (
      <Autocomplete
        onChange={(id) => {
          const foundPlant = plants.find(
            (plant) => Number(plant.id) === Number(id)
          );
          onChange(foundPlant);
        }}
        fetching={fetching}
        error={error}
        onSearch={(name) => this.fetchPlants({ name })}
        value={this.toOption(plantId)}
        options={plantsOptions}
        required={required}
        disabled={disabled}
        label={label}
        closeOnSelect={closeOnSelect}
      />
    );
  }
}

export default PlantAutocomplete;
