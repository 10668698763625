import React, { useRef, useState } from 'react';
import PlantGroupDetailsModel from 'modules/PlantGroups/model/PlantGroupDetails';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import PlantGroupDetailsPlants from 'modules/PlantGroups/component/Details/Plants/Details';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import DetailsPlantMeasurementDesc from 'modules/Plants/view/Details/components/DisplayDetailsMeasurementDesc';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import { PLANT_DETAILS_MEASUREMENT_DESC } from 'modules/Plants/view/DetailsForm/helper/acceptableTypes';
import {
  ROUTE_PLANT_GROUP_EDIT,
  ROUTE_PLANT_GROUPS,
  ROUTE_PLANT_GROUPS_DETAILS_FORM_REDIRECT_TEMPLATE
} from 'modules/PlantGroups/routes';
import { MeasurementsKnowHow as MeasurementsKnowHowType } from 'modules/Shared/configs/measurementKnowHowEntity/type';
import DeletePlantGroupModal, {
  DeletePlantGroupModal as DeletePlantGroupModalClass
} from 'modules/PlantGroups/component/Modal/Delete';
import { getPathUrl } from 'modules/Shared/helper/api';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import MeasurementsKnowHow from 'modules/Plants/component/MeasurementsKnowHow';
import _get from 'lodash/get';

export interface Props extends RouteComponentProps {
  plantGroup: PlantGroupDetailsModel;
  plantGroupUpdated: (
    knowHow: MeasurementsKnowHowType,
    type: 'add' | 'delete'
  ) => void;
}

const PlantGroupDetails = (props: Props): JSX.Element => {
  const { plantGroup, history, plantGroupUpdated } = props;

  const navElements = [
    'Main data and plants',
    'Measurements desc (What to do)',
    'Measurements know-how (Tutorials)'
  ];

  const { state: locationState } = useLocation<any>();

  const prevNav = locationState?.nav;

  const [nav, setNav] = useState(
    navElements.map((navName, index) =>
      prevNav ? navName === prevNav : index === 0
    )
  );
  const deleteModalRef = useRef<DeletePlantGroupModalClass>(null);

  const additionalButtons = [
    {
      callback: () =>
        history.push(getPathUrl(ROUTE_PLANT_GROUP_EDIT, { id: plantGroup.id })),
      text: 'Edit',
      bsColor: 'secondary'
    },
    {
      callback: () => deleteModalRef?.current?.open(plantGroup),
      text: 'Delete',
      bsColor: 'danger'
    }
  ];

  return (
    <div className="position-relative">
      <DeletePlantGroupModal
        ref={deleteModalRef}
        onDeleted={() => history.push(getPathUrl(ROUTE_PLANT_GROUPS))}
      />
      <div className="details-top-nav mb-3">
        <DisplayNav
          booleanArr={nav}
          setBooleanArr={setNav}
          valueArr={navElements}
        />
      </div>

      {nav[0] && (
        <div className="content restrict-width">
          <DisplayBaseTabContent
            title={`Plant group - ${plantGroup.name}`}
            additionalButtons={additionalButtons}
          />
          <PlantGroupDetailsPlants plantGroup={plantGroup} />
        </div>
      )}

      {nav[1] && (
        <DetailsPlantMeasurementDesc
          editUrl={(lang, measurementType) =>
            `${
              ROUTE_PLANT_GROUPS_DETAILS_FORM_REDIRECT_TEMPLATE +
              PLANT_DETAILS_MEASUREMENT_DESC
            }/${plantGroup.id}/${lang}/${measurementType}`
          }
          measurements={plantGroup.measurements_statuses_desc}
          supportedLang={supportedLangs}
        />
      )}

      {nav[2] && (
        <MeasurementsKnowHow
          plantGroup={plantGroup}
          measurementsKnowHow={plantGroup.measurements_statuses_know_hows}
          plantGroupUpdated={plantGroupUpdated}
        />
      )}
    </div>
  );
};

export default withRouter(PlantGroupDetails);
