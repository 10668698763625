import React, { CSSProperties, ReactNode } from 'react';
import { Input } from 'reactstrap';
import { List as VirtualizedList } from 'react-virtualized';
import './style.scss';
import Loader from 'modules/Layout/component/Loader';

export interface MoveListValue {
  label: string;
  value: string;
  render?: () => ReactNode;
}

export interface Props {
  options: MoveListValue[];
  values: MoveListValue[];
  fetchingOptions?: boolean;
  fetchingValues?: boolean;
}

export interface State {
  optionLabel: string;
  valueLabel: string;
}

class MoveList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      optionLabel: '',
      valueLabel: ''
    };

    this.renderOption = this.renderOption.bind(this);
    this.renderValue = this.renderValue.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.renderValues = this.renderValues.bind(this);
  }

  renderOption({
    index,
    key,
    style
  }: {
    index: number;
    key: string;
    style: CSSProperties;
  }): ReactNode {
    const { options, values } = this.props;
    const { optionLabel } = this.state;
    const filteredOptions = this.filter(options, optionLabel);
    const filteredNotInGroup = filteredOptions.filter(
      (option) => !values.some((obj) => obj.value === option.value)
    );
    const value = filteredNotInGroup[index];

    return (
      <div
        key={`${key}-${value.value}`}
        style={style}
        className="border-top border-bottom border-right p-1"
      >
        {value.render ? value.render() : value.label}
      </div>
    );
  }

  renderValue({
    index,
    key,
    style
  }: {
    index: number;
    key: string;
    style: CSSProperties;
  }): ReactNode {
    const { values } = this.props;
    const { valueLabel } = this.state;
    const filteredValues = this.filter(values, valueLabel);
    const value = filteredValues[index];

    return (
      <div
        key={`${key}-${value.value}`}
        style={style}
        className="border-top border-bottom border-left p-1"
      >
        {value.render ? value.render() : value.label}
      </div>
    );
  }

  filter(values: MoveListValue[], label: string): MoveListValue[] {
    if (!label) {
      return values;
    }

    return values.filter((value) =>
      value.label.toLocaleLowerCase().includes(label.toLocaleLowerCase())
    );
  }

  renderOptions(): ReactNode {
    const { options, values, fetchingOptions } = this.props;
    const { optionLabel } = this.state;

    const filteredOptions = this.filter(options, optionLabel);
    const filteredNotInGroup = filteredOptions.filter(
      (option) => !values.some((obj) => obj.value === option.value)
    );

    return (
      <div className="p-0 border-right mr-5 position-relative">
        {fetchingOptions && <Loader />}
        <Input
          placeholder="Search..."
          value={optionLabel}
          onChange={(event) =>
            this.setState({
              optionLabel: event.currentTarget.value
            })
          }
        />
        <div className="options">
          <VirtualizedList
            height={600}
            width={300}
            overscanRowCount={10}
            rowHeight={36}
            rowRenderer={this.renderOption}
            rowCount={filteredNotInGroup.length}
          />
        </div>
      </div>
    );
  }

  renderValues(): ReactNode {
    const { values, fetchingValues } = this.props;
    const { valueLabel } = this.state;

    const filteredValues = this.filter(values, valueLabel);

    return (
      <div className="p-0 border-left ml-5 position-relative">
        {fetchingValues && <Loader />}
        <Input
          placeholder="Search..."
          value={valueLabel}
          onChange={(event) =>
            this.setState({
              valueLabel: event.currentTarget.value
            })
          }
        />
        <div className="values">
          <VirtualizedList
            height={600}
            width={300}
            overscanRowCount={10}
            rowHeight={36}
            rowRenderer={this.renderValue}
            rowCount={filteredValues.length}
          />
        </div>
      </div>
    );
  }

  render(): ReactNode {
    return (
      <div className="move-list">
        <div className="d-flex border">
          {this.renderOptions()}
          {this.renderValues()}
        </div>
      </div>
    );
  }
}

export default MoveList;
