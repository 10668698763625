import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { MediaEntity } from 'modules/Media/model/Media';
import { MediaAdvancementEntity } from 'modules/Media/model/MediaAdvancement';
import { MediaKnowHowEntity } from 'modules/Media/model/MediaKnowHow';

export const API_MEDIA = '/admin-media';
export const API_MEDIA_LIST = '/admin-media/list';
export const API_MEDIA_DETAILS = '/admin-media/:id';
export const API_MEDIA_ADVANCEMENT = '/admin-media/advancement-level/list';
export const API_MEDIA_ADVANCEMENT_DETAILS =
  '/admin-media/advancement-level/:id';
export const API_MEDIA_ADVANCEMENT_ADD = '/admin-media/advancement-level';
export const API_MEDIA_ATTACH_AS_KNOW_HOW =
  '/admin-media/:id/know-how-attach-to';

export interface FetchMediaResponse {
  content: MediaEntity[];
  totalCount: any;
}

export const fetchMedia = (
  params: SearchParams = {}
): AxiosPromise<FetchMediaResponse> =>
  axios.post(getAPIUrl(API_MEDIA_LIST, null), params);

// details

export interface FetchMediaDetailsResponse {
  media: MediaEntity;
  knowHows: MediaKnowHowEntity[];
}

export const fetchMediaDetails = (
  id: number
): AxiosPromise<FetchMediaDetailsResponse> =>
  axios.get(getAPIUrl(API_MEDIA_DETAILS, { id }));

// add

export interface FetchMediaRequest {
  title: string;
  advancement_level_id: number;
  type: string;
  url: string;
  image: any;
}

export interface FetchAddMediaResponse {
  success: boolean;
  media: MediaEntity;
}

export const fetchAddMedia = (
  params: FetchMediaRequest & AttachMediaAsKnowHowPayload,
  cancelToken?: any
): AxiosPromise<FetchAddMediaResponse> =>
  axios.post(getAPIUrl(API_MEDIA), params, { cancelToken });

export interface FetchMediaAdvancementLevel {
  content: MediaAdvancementEntity[];
  totalCount: any;
}

export const fetchMediaAdvancementLevel = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchMediaAdvancementLevel> =>
  axios.post(getAPIUrl(API_MEDIA_ADVANCEMENT), params, { cancelToken });

export interface FetchMediaAdvancementDetailsResponse {
  mediaAdvancement: MediaAdvancementEntity;
}

export const fetchMediaAdvancementDetails = (
  id: number | string
): AxiosPromise<FetchMediaAdvancementDetailsResponse> =>
  axios.get(getAPIUrl(API_MEDIA_ADVANCEMENT_DETAILS, { id }));

export const updateMediaAdvancement = (
  id: number | string,
  params: {
    name: string;
    langs: {
      [lang: string]: {
        text: string;
      };
    };
  },
  cancelToken?: any
): AxiosPromise =>
  axios.put(getAPIUrl(API_MEDIA_ADVANCEMENT_DETAILS, { id }), params, {
    cancelToken
  });

export interface AddMediaAdvancementResponse {
  media_advancement_level: {
    id: number;
    name: string;
  };
}

export const addMediaAdvancement = (
  params: {
    name: string;
    langs: {
      [lang: string]: {
        text: string;
      };
    };
  },
  cancelToken?: any
): AxiosPromise<AddMediaAdvancementResponse> =>
  axios.post(getAPIUrl(API_MEDIA_ADVANCEMENT_ADD), params, {
    cancelToken
  });

export const deleteMediaAdvancement = (id: number | string): AxiosPromise =>
  axios.delete(getAPIUrl(API_MEDIA_ADVANCEMENT_DETAILS, { id }));

// delete

export const deleteMediaDetails = (
  id: number,
  cancelToken?: any
): AxiosPromise =>
  axios.delete(getAPIUrl(API_MEDIA_DETAILS, { id }), { cancelToken });

// edit

export type UpdateMediaDetailsResponse = AxiosResponse<{
  news: MediaEntity;
}>;

export const updateMediaDetails = (
  id: number | string,
  params: FetchMediaRequest,
  cancelToken?: any
): AxiosPromise<UpdateMediaDetailsResponse> =>
  axios.put(getAPIUrl(API_MEDIA_DETAILS, { id }), params, { cancelToken });

export interface AttachMediaAsKnowHowPayload {
  know_how_measurements: {
    measurement: string;
    measurement_status: number;
  }[];
  plants_know_how: number[];
  plant_groups_know_how: number[];
  families_know_how: number[];
}

export const attachMediaAsKnowHow = (
  id: number | string,
  payload: AttachMediaAsKnowHowPayload
): AxiosPromise =>
  axios.post(getAPIUrl(API_MEDIA_ATTACH_AS_KNOW_HOW, { id }), payload);
