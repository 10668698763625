import React, { useState } from 'react';
import { Faq } from 'modules/Faq/model/FAQ';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_FAQ_UPDATE } from 'modules/Faq/routes';

export interface Props {
  faq: Faq;
}

const useFaqListItem = (props: Props) => {
  const {
    faq: { lang }
  } = props;
  const [langs, setLangs] = useState(
    supportedLangs.map((_, index) => index === 0)
  );
  const currentLang = supportedLangs[langs.findIndex((isActive) => isActive)];
  const html = lang.find((obj) => obj.lang === currentLang)?.text || '';

  return {
    langs,
    setLangs,
    html
  };
};

const FaqListItem = (props: Props): JSX.Element => {
  const { faq } = props;
  const { langs, setLangs, html } = useFaqListItem(props);

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-end">
          <Link
            className="btn btn-success"
            to={getPathUrl(ROUTE_FAQ_UPDATE, { id: faq.id })}
          >
            Edit
          </Link>
        </div>
        <h2 className="text-center">{`${faq.getFaqTypeName()} FAQ`}</h2>
        <DisplayNav
          booleanArr={langs}
          setBooleanArr={setLangs}
          valueArr={supportedLangs}
        />
        <div className="mt-3" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

export default FaqListItem;
