import { SelectOption } from 'modules/Shared/type';

export type NewsEntity = {
  id?: number;
  title?: string;
  description?: string;
  type?: string;
  section?:
    | 'tutorials'
    | 'plant_of_the_week'
    | 'smart_gardening'
    | 'living_with_plants';
  url?: string;
  image?: string;
  created_at?: string;
  updated_at?: string;
};

export default class News {
  readonly id?: number;
  readonly title?: string;
  readonly description?: string;
  readonly type?: string;
  readonly section?: string;
  readonly url?: string;
  readonly image?: string;
  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(entity: NewsEntity) {
    this.id = entity.id;
    this.title = entity.title;
    this.description = entity.description;
    this.type = entity.type;
    this.section = entity.section;
    this.url = entity.url;
    this.image = entity.image;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  static getFilterableAttributes(): string[] {
    return ['title', 'type'];
  }

  static getSortableAttributes(): string[] {
    return ['id'];
  }

  static getTypeOptions(): SelectOption[] {
    return [
      {
        label: 'article',
        value: 'article'
      },
      {
        label: 'video',
        value: 'video'
      }
    ];
  }

  static getSection(section: string): string {
    switch (section) {
      case 'tutorials': {
        return 'Tutorials';
      }

      case 'plant_of_the_week': {
        return 'Plant of the week';
      }

      case 'smart_gardening': {
        return 'Smart gardening';
      }

      case 'living_with_plants': {
        return 'Living with plants';
      }

      default: {
        return '';
      }
    }
  }
}

export const createNews = (entity: NewsEntity): News => new News(entity);

export const acceptableNewsTypesValues = ['article', 'video'];
