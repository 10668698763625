import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { DictionaryEntity } from 'modules/Dictionary/model/Dictionary';

export const API_DICTIONARIES = '/admin-dictionaries/list';
export const API_DICTIONARY = '/admin-dictionaries/:id';
export const API_ADD_DICTIONARY = '/admin-dictionaries';

export type FetchDictionariesResponse = AxiosResponse<{
  content: DictionaryEntity[];
  totalCount: any;
}>;

export const fetchDictionaries = (
  params: SearchParams = {},
  table: string
): AxiosPromise<FetchDictionariesResponse> =>
  axios.post(getAPIUrl(API_DICTIONARIES, null, { table }), params);

export type FetchDictionaryResponse = {
  dictionary: DictionaryEntity;
};

export const fetchDictionary = (
  id: number | string,
  table: string
): AxiosPromise<FetchDictionaryResponse> =>
  axios.get(getAPIUrl(API_DICTIONARY, { id }, { table }));

export const deleteDictionary = (
  id: number | string,
  table: string
): AxiosPromise => axios.delete(getAPIUrl(API_DICTIONARY, { id }, { table }));

export const updateDictionary = (
  dictionary: DictionaryEntity,
  table: string
): AxiosPromise =>
  axios.put(
    getAPIUrl(API_DICTIONARY, { id: dictionary.id }, { table }),
    dictionary
  );

export interface AddDictionaryResponse {
  dictionary: {
    id: number;
  };
}

export const addDictionary = (
  dictionary: DictionaryEntity,
  table: string
): AxiosPromise<AddDictionaryResponse> =>
  axios.post(getAPIUrl(API_ADD_DICTIONARY, null, { table }), dictionary);
