import PlantListView from 'modules/Plants/view/List';
import PlantImportsListView from 'modules/Plants/view/List/Import';
import PlantCreateView from 'modules/Plants/view/Create';
import PlantDetailsView from 'modules/Plants/view/Details';
import PlantDetailsFormView from 'modules/Plants/view/DetailsForm';
import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import HelpFindPlantsListView from 'modules/Plants/view/List/HelpFindPlant';
import HelpFindPlantDetailsView from 'modules/Plants/view/Details/HelpFindPlant';
import PlantEditSectionView from 'modules/Plants/view/Edit/Section';
import AddPlantImageView from 'modules/Plants/view/AddImage';
import PhResultsListView from 'modules/Plants/view/List/PhResults';

export const ROUTE_PLANTS = '/plant/list';
export const ROUTE_PLANTS_ADD = '/plant/add';
export const ROUTE_PLANTS_DETAILS = '/plant/details/:id';
export const ROUTE_PLANTS_DETAILS_TEMPLATE = '/plant/details/';
export const ROUTE_PLANT_DETAILS_FORM = '/plant/details/form/:type/:id';
export const ROUTE_PLANT_DETAILS_FORM_LANG =
  '/plant/details/form/:type/:id/:lang';
export const ROUTE_PLANT_DETAILS_FORM_LANG_OPTION =
  '/plant/details/form/:type/:id/:lang/:option';
export const ROUTE_PLANTS_DETAILS_REDIRECT_TEMPLATE = '/plant/details/';
export const ROUTE_PLANT_DETAILS_FORM_REDIRECT_TEMPLATE =
  '/plant/details/form/';
export const ROUTE_PLANT_IMPORTS = '/plant/imports';
export const ROUTE_HELP_FIND_PLANT = '/plant/help-find';
export const ROUTE_HELP_FIND_PLANT_DETAILS = '/plant/help-find/:id';
export const ROUTE_HELP_FIND_PLANT_DETAILS_REDIRECT = '/plant/help-find/';
export const ROUTE_PLANT_EDIT_SECTION =
  '/plant/details/:id/section/:section/:lang';
export const ROUTE_PLANT_EDIT_DESCRIPTIONS = '/plant/details/:id/descriptions';
export const ROUTE_PLANT_ADD_IMAGE = '/plant/:id/add-image';
export const ROUTE_PH_RESULTS_LIST = '/ph-results';

export const createPlantsRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="plant-list"
      path={ROUTE_PLANTS}
      exact
      component={PlantListView}
    />,
    <Route
      key="plant-add"
      path={ROUTE_PLANTS_ADD}
      exact
      component={PlantCreateView}
    />,
    <Route
      key="plant-details"
      path={ROUTE_PLANTS_DETAILS}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <PlantDetailsView {...props} key={props.match.params.id} />
      )}
    />,
    <Route
      key="plant-details-form"
      path={ROUTE_PLANT_DETAILS_FORM}
      exact
      component={PlantDetailsFormView}
    />,
    <Route
      key="plant-details-form-lang"
      path={ROUTE_PLANT_DETAILS_FORM_LANG}
      exact
      component={PlantDetailsFormView}
    />,
    <Route
      key="plant-imports"
      path={ROUTE_PLANT_IMPORTS}
      exact
      component={PlantImportsListView}
    />,
    <Route
      key="plant-details-form-lang-option"
      path={ROUTE_PLANT_DETAILS_FORM_LANG_OPTION}
      exact
      component={PlantDetailsFormView}
    />,
    <Route
      key="help-find-plant"
      path={ROUTE_HELP_FIND_PLANT}
      exact
      component={HelpFindPlantsListView}
    />,
    <Route
      key="help-find-plant-details"
      path={ROUTE_HELP_FIND_PLANT_DETAILS}
      exact
      render={(props) => (
        <HelpFindPlantDetailsView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          id={props.match.params.id}
          key={props.match.params.id}
        />
      )}
    />,
    <Route
      key="plant-edit-descriptions"
      path={ROUTE_PLANT_EDIT_DESCRIPTIONS}
      exact
      render={(props) => (
        <PlantEditSectionView
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          id={props.match.params.id}
          key={props.match.params.id}
        />
      )}
    />,
    <Route
      key="plant-add-image"
      path={ROUTE_PLANT_ADD_IMAGE}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <AddPlantImageView {...props} id={props.match.params.id} />
      )}
    />,
    <Route
      key="ph-results"
      path={ROUTE_PH_RESULTS_LIST}
      exact
      component={PhResultsListView}
    />
  ]
});
