import React, { useRef, useState } from 'react';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import InformationList from 'modules/Layout/component/InformationList';
import { useHistory } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PP_LIST, ROUTE_UPDATE_PP } from 'modules/Policy/routes';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import PrivacyPolicy from 'modules/Policy/model/PrivacyPolicy';
import DeletePrivacyPolicyModal from 'modules/Policy/component/Modal/Delete/PrivacyPolicy';

export interface Props {
  pp: PrivacyPolicy;
}

const PrivacyPolicyDetails = (props: Props): JSX.Element => {
  const { pp } = props;
  const [nav, setNav] = useState(supportedLangs.map((_, index) => index === 0));
  const deleteModalRef = useRef(null);
  const history = useHistory();
  const activeLangIndex = nav.findIndex((obj) => obj);
  const activeLang = supportedLangs[activeLangIndex];

  const fields = [
    {
      label: 'Activation date:',
      value: pp.activation_date
    },
    {
      label: 'Published:',
      value: pp.getIsPublished()
    },
    {
      label: 'Mails sent at:',
      value: pp.mails_sended
    },
    {
      label: 'Created at:',
      value: pp.created_at
    }
  ];

  return (
    <div className="card">
      <div className="card-body">
        <DeletePrivacyPolicyModal
          ref={deleteModalRef}
          onSuccess={() => history.push(getPathUrl(ROUTE_PP_LIST))}
        />
        {!pp.published && (
          <div className="d-flex justify-content-end actions-wrapper">
            <ActionUpdate
              title="Edit terms of service"
              className="mr-2"
              to={getPathUrl(ROUTE_UPDATE_PP, { id: pp.id })}
            />
            <ActionDelete
              title="Delete terms of service"
              onClick={() => deleteModalRef?.current?.open(pp)}
            />
          </div>
        )}
        <InformationList fields={fields} />
        <hr className="border my-3" />
        <div className="details-top-nav mb-3">
          <DisplayNav
            booleanArr={nav}
            setBooleanArr={setNav}
            valueArr={supportedLangs}
          />
        </div>
        {supportedLangs.map((lang) => {
          const body = pp.langs[lang]?.body;

          if (activeLang !== lang) {
            return null;
          }

          return (
            <div
              className="wysiwyg-preview"
              key={lang}
              dangerouslySetInnerHTML={{ __html: body }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PrivacyPolicyDetails;
