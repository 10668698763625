import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setAdministratorListParamsAction,
  SetAdministratorListParamsAction
} from 'modules/User/action/list';
import User from 'modules/User/model/User';
import React, { createRef, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ROUTE_ADMINISTRATOR_DETAILS,
  ROUTE_EDIT_ADMINISTRATOR
} from 'modules/User/routes';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionShow from 'modules/Layout/component/Action/Show';
import { getPathUrl } from 'modules/Shared/helper/api';
import DeleteUserModal, {
  DeleteUserModal as DeleteUserModalClass
} from 'modules/User/component/Modal/Delete';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ChangeUserRoleModal, {
  ChangeUserRoleModal as ChangeUserRoleModalType
} from 'modules/User/component/Modal/Role';

export interface StateProps {
  auth: User;
  users: User[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetAdministratorListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (user: User) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { user: auth } = state.auth;
  const { users, sort } = state.user.list;

  return { auth, users, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setAdministratorListParamsAction(payload))
});

export class AdministratorTable extends React.Component<Props> {
  readonly cols: TableCol<User>[];
  readonly deleteModalRef: React.RefObject<DeleteUserModalClass>;
  readonly changeRoleModalRef: React.RefObject<ChangeUserRoleModalType>;

  constructor(props: Props) {
    super(props);

    this.deleteModalRef = createRef();
    this.changeRoleModalRef = createRef();

    this.cols = [
      {
        property: 'email',
        label: 'Email',
        className: 'col-4',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_ADMINISTRATOR_DETAILS, { id: row.id })
      },
      {
        property: 'username',
        label: 'Username',
        className: 'col-4',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_ADMINISTRATOR_DETAILS, { id: row.id })
      },
      {
        property: 'status',
        label: 'Status',
        value: (row) => row.getStatus(),
        className: 'col-4'
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              id={`show-administrator-${row.id}`}
              title="Show administrator"
              to={getPathUrl(ROUTE_ADMINISTRATOR_DETAILS, { id: row.id })}
            />
            <ActionUpdate
              id={`edit-administrator-${row.id}`}
              disabled={row.isDeleted()}
              title="Edit administrator"
              to={getPathUrl(ROUTE_EDIT_ADMINISTRATOR, { id: row.id })}
            />
            <ActionDelete
              id={`change-role-${row.id}`}
              disabled={row.isDeleted()}
              label={<i className="far font-20 fa-arrow-alt-circle-down" />}
              title="Take administrator"
              onClick={() => this.changeRoleModalRef?.current?.open(row)}
            />
            <ActionDelete
              id={`delete-administrator-${row.id}`}
              disabled={row.isDeleted()}
              title="Delete administrator"
              onClick={() => this.deleteModalRef?.current?.open(row)}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { users, sort, setParams } = this.props;

    return (
      <>
        <DeleteUserModal
          ref={this.deleteModalRef}
          onSuccess={() => setParams({ sort })}
          admin
        />
        <ChangeUserRoleModal
          ref={this.changeRoleModalRef}
          /* eslint-disable-next-line jsx-a11y/aria-role */
          role="user"
          onSuccess={() => setParams({ sort })}
        />
        <Table
          cols={this.cols}
          rows={users}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(AdministratorTable);
