import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteDetailsNews } from 'modules/News/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { setNewsDetailsParamsAction } from 'modules/News/action/Details';
import { RootState } from 'app/reducer';
import DetailsMain from 'modules/News/view/Details/components/DisplayDetailsMain';
import Loader from 'modules/Layout/component/Loader';
import { ROUTE_NEWS } from 'modules/News/routes';

interface IParams {
  id: string;
}

export const NewsDetailsView = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector((state: RootState) => state.news.details);
  const { type } = useSelector((state: RootState) => state.layout.page);

  useEffect(() => {
    dispatch(
      managePageAction({
        title: details.newsDetails ? details.newsDetails.title : 'News',
        breadcrumb: breadcrumbRouteDetailsNews(details.newsDetails)
      })
    );
  }, [details.newsDetails]);

  useEffect(() => {
    if (params?.id) {
      const id = parseInt(params.id, 10);
      if (id) dispatch(setNewsDetailsParamsAction(id));
      else history.push(ROUTE_NEWS);
    }
  }, []);

  if (type) return null;
  if (details.fetching)
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );
  return <DetailsMain data={details.newsDetails} />;
};

export default NewsDetailsView;
