import GardenDetails from 'modules/Garden/model/GardenDetails';

export interface GardenDetailsState {
  gardenDetails: GardenDetails;
  fetching: boolean;
  gardenId: number;
}

export const initGardenDetailsState = (): GardenDetailsState => ({
  gardenDetails: null,
  fetching: false,
  gardenId: null
});
