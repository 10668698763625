import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  primaryInfoArr,
  getDescriptionLang
} from 'modules/Plants/view/Details/helper/DetailsConfig';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import DiplayPrimaryInfo from 'modules/Layout/view/Details/Helper/DisplayPrimaryInfo';
import DisplayPrimaryInfoWrapper, {
  IBtnObj
} from 'modules/Layout/view/Details/Helper/DisplayPrimaryInfoWrapper';
import {
  ROUTE_PLANT_ADD_IMAGE,
  ROUTE_PLANT_DETAILS_FORM_REDIRECT_TEMPLATE
} from 'modules/Plants/routes';
import {
  PLANT_DETAILS_DESCRIPTION,
  PLANT_DETAILS_MAIN
} from 'modules/Plants/view/DetailsForm/helper/acceptableTypes';
import DisplayRemoveImageModal from 'modules/Plants/view/Details/components/DisplayDetailsMain/helper/DisplayRemoveImageModal';
import ShowMessage from 'modules/Layout/component/ShowMessage';
import { confirmPlant } from 'modules/Plants/repository';
import {
  plantDetailsDeletedAction,
  plantDetailsUpdatedAction
} from 'modules/Plants/action/Details';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { displayOptionRowContent } from 'modules/Layout/view/Details/Helper/DisplayContent';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import useCancelToken from 'modules/Shared/helper/useCancelToken';
import DeletePlantModal from 'modules/Plants/component/Modal/Delete';
import { getPathUrl } from 'modules/Shared/helper/api';

interface IProps {
  data: PlantDetails;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  imagesURL: { id: number; url: string }[];
  supportedLang: string[];
  // eslint-disable-next-line react/require-default-props
  protectedImagesUrl?: string[];
}

const Details: React.FC<IProps> = ({
  data,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  imagesURL,
  supportedLang
}: IProps) => {
  const [displayRemoveModal, setDisplayRemoveModal] = useState(null);
  const [removeImageLoader, setRemoveImageLoader] = useState(false);
  const [showMessage, setShowMessage] = useState('');
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [langs, setLangs] = useState(
    supportedLang.map((_, index) => index === 0)
  );
  const deleteModalRef = useRef(null);

  const findLang = supportedLang[langs.findIndex((lang) => lang)];

  const history = useHistory();
  const dispatch = useDispatch();

  const deleteMessage = () => setShowMessage('');
  const clearMessage = (setModal: () => void) => {
    if (showMessage) deleteMessage();
    setModal();
  };

  const onDeleteSuccess = (): void => {
    dispatch(plantDetailsDeletedAction());
    setShowMessage('Plant successfully deleted.');
  };

  const cancelToken = useCancelToken();
  const onConfirmBtnClick = async () => {
    if (!confirmLoader) {
      setConfirmLoader(true);

      try {
        await confirmPlant(data.id, cancelToken);
        const { confirmed, ...rest } = data;
        if (!confirmed) {
          dispatch(plantDetailsUpdatedAction({ ...rest, confirmed: true }));
          setShowMessage('Plant successfully confirmed.');
        }
        setConfirmLoader(false);
      } catch (err) {
        console.log(err);
        if (err?.message !== 'AxiosCancel') {
          setShowMessage(err?.message || 'Something went wrong.');
          setConfirmLoader(false);
        }
      }
    }
  };

  const arrayOfBtnObjects: IBtnObj[] = [
    {
      callback: () => deleteModalRef?.current.openModal(data),
      text: 'Delete',
      bsColor: 'danger',
      inactive: data?.is_deleted
    }
  ];

  if (!data?.is_deleted) {
    arrayOfBtnObjects.push(
      {
        callback: () =>
          history.push(
            `${
              ROUTE_PLANT_DETAILS_FORM_REDIRECT_TEMPLATE + PLANT_DETAILS_MAIN
            }/${data?.id}`
          ),
        text: 'Edit'
      } as any,
      {
        callback: () =>
          history.push(getPathUrl(ROUTE_PLANT_ADD_IMAGE, { id: data?.id })),
        text: 'Add image',
        bsColor: 'primary',
        inactive: false
      } as any
    );
  }

  if (!data.confirmed) {
    arrayOfBtnObjects.unshift({
      callback: () => onConfirmBtnClick(),
      text: 'Confirm',
      bsColor: 'success',
      loading: confirmLoader,
      inactive: false
    });
  }

  return (
    <div className="content restrict-width">
      <DeletePlantModal ref={deleteModalRef} onSuccess={onDeleteSuccess} />
      <DisplayPrimaryInfoWrapper arrBtnObj={arrayOfBtnObjects}>
        <ShowMessage
          message={showMessage}
          deleteMessage={deleteMessage}
          bsColor={showMessage.includes('uccess') ? 'success' : 'danger'}
        />
        <DiplayPrimaryInfo
          imagesURL={imagesURL}
          onDeleteImageClick={
            !data?.is_deleted
              ? (id: number) => clearMessage(() => setDisplayRemoveModal(id))
              : null
          }
          objArr={primaryInfoArr(data)}
          loader={removeImageLoader}
        />
        <DisplayRemoveImageModal
          isOpen={!!displayRemoveModal}
          imgId={displayRemoveModal}
          onClosed={() => setDisplayRemoveModal(null)}
          title={<div className="h4">Delete image</div>}
          toggle={() => setDisplayRemoveModal(null)}
          plantId={data?.id}
          loader={removeImageLoader}
          setLoader={setRemoveImageLoader}
          setShowMessage={setShowMessage}
        />
      </DisplayPrimaryInfoWrapper>
      <DisplayBaseTabContent
        onEditBtnClick={
          !data?.is_deleted
            ? () =>
                history.push(
                  `${ROUTE_PLANT_DETAILS_FORM_REDIRECT_TEMPLATE}${PLANT_DETAILS_DESCRIPTION}/${data?.id}/${findLang}/base`
                )
            : null
        }
        title="Base Description"
      >
        <DisplayNav
          booleanArr={langs}
          setBooleanArr={setLangs}
          valueArr={supportedLang}
        />
        <div className="tab-content mt-3">
          {displayOptionRowContent(
            getDescriptionLang(data, findLang as any, 'base')
          )}
        </div>
      </DisplayBaseTabContent>
    </div>
  );
};

export default Details;
