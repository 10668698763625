import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ROUTE_USER_DETAILS } from 'modules/User/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import HealthCheckHelp from 'modules/PlantDiseases/model/HealthCheckHelp';
import {
  HealthCheckHelpListSetRespondedAction,
  healthCheckHelpListSetRespondedAction,
  HealthCheckHelpListSetRespondedPayload,
  setHealthCheckHelpListParamsAction,
  SetHealthCheckHelpListParamsAction
} from 'modules/PlantDiseases/action/list';
import { ROUTE_HEALTH_CHECK_HELP_DETAILS } from 'modules/PlantDiseases/routes';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionShow from '../../../../Layout/component/Action/Show';

export interface StateProps {
  healthCheckHelp: HealthCheckHelp[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (
    payload: PartialSearchingProps
  ) => SetHealthCheckHelpListParamsAction;
  setResponded: (
    payload: HealthCheckHelpListSetRespondedPayload
  ) => HealthCheckHelpListSetRespondedAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { healthCheckHelp, sort } = state.plantDiseases.list;

  return { healthCheckHelp, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setHealthCheckHelpListParamsAction(payload)),
  setResponded: (payload) =>
    dispatch(healthCheckHelpListSetRespondedAction(payload))
});

export class HealthCheckHelpTable extends React.Component<Props> {
  readonly cols: TableCol<HealthCheckHelp>[];

  constructor(props: Props) {
    super(props);

    const { setResponded } = props;

    this.cols = [
      {
        property: 'email',
        label: 'User email',
        className: 'col-6',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_USER_DETAILS, { id: row.user_id })
      },
      {
        property: 'created_at',
        label: 'Sent at',
        className: 'col-6'
      },
      {
        property: 'responded',
        label: 'Responded',
        value: (row) => (
          <div className="actions-wrapper">
            <div>{row.responded ? 'Yes' : 'No'}</div>
            {row.responded ? (
              <ActionDelete
                label={<i className="fas font-22 fa-times" />}
                title="Set to not responded"
                onClick={() =>
                  setResponded({ healthCheckHelpId: row.id, responded: false })
                }
              />
            ) : (
              <ActionUpdate
                label={<i className="fas font-20 fa-check" />}
                title="Set to responded"
                onClick={() =>
                  setResponded({ healthCheckHelpId: row.id, responded: true })
                }
              />
            )}
          </div>
        )
      },
      {
        property: null,
        label: 'Action',
        className: 'col',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show health check help details"
              to={getPathUrl(ROUTE_HEALTH_CHECK_HELP_DETAILS, { id: row.id })}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { healthCheckHelp, sort, setParams } = this.props;

    return (
      <>
        <Table
          cols={this.cols}
          rows={healthCheckHelp}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HealthCheckHelpTable);
