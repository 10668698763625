import { RootState } from 'app/reducer';
import { SetAuthStateAction, setAuthStateAction } from 'modules/Auth/action';
import Form from 'modules/Auth/component/Form/Login';
import { Credentials } from 'modules/Auth/type';
import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import { Message, ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import Logo from 'modules/Layout/component/Logo';
import { SettableAuthState } from 'modules/Auth/state';
import { requestToken, TokenResponse } from 'modules/Auth/repository';
import { createRequestTokenPayload } from 'modules/Auth/helper';
import { saveToken } from 'modules/Auth/service';
import { ROUTE_DASHBOARD } from 'modules/Layout/routes';
import ApiError from 'modules/Shared/exception/ApiError';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export type StateProps = {
  message?: Message;
};

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setAuthState: (payload: SettableAuthState) => SetAuthStateAction;
}

export type Props = StateProps & DispatchProps & RouteComponentProps;

export interface State {
  message?: Message;
  busy: boolean;
  errors?: ValidationErrors;
}

export const mapState = (state: RootState): StateProps => {
  const { message } = state.auth;

  return { message };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setAuthState: (payload) => dispatch(setAuthStateAction(payload))
});

export class LoginView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: null,
      busy: false,
      errors: null
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(): void {
    const { managePage, message, setAuthState } = this.props;

    managePage({ title: 'Log in' });

    if (message) {
      this.setState({
        message
      });

      setAuthState({
        message: null
      });
    }
  }

  async onSubmit(credentials: Credentials): Promise<void> {
    const { history } = this.props;

    this.setState({
      busy: true,
      errors: null,
      message: null
    });

    try {
      const { data: token }: TokenResponse = await requestToken(
        createRequestTokenPayload(credentials)
      );

      saveToken(token);

      history.push(ROUTE_DASHBOARD);
    } catch (error) {
      if (error instanceof ApiError) {
        this.setState({
          errors: error.getErrors(),
          message: error.getMessage(),
          busy: false
        });
      }
    }
  }

  render(): JSX.Element {
    const { message, errors, busy } = this.state;

    return (
      <PublicWrapper>
        <div className="login-view pt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11 col-lg-9 col-xl-7">
                <div className="card position-relative px-md-5 py-2 bg-secondary">
                  <div className="card-body">
                    <Logo />
                    {busy && <Loader />}
                    <div className="px-lg-4 mt-4">
                      {message && <Alert message={message} />}
                      <Form
                        busy={busy}
                        errors={errors}
                        submit={this.onSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PublicWrapper>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(mapState, mapDispatch)(LoginView)
);
