export type HubDetailsEntity = {
  id?: number;
  hub_id?: string;
  status?: number;
  received_data_at?: string;
};

export const hubStatus = (status: number) => {
  switch (status) {
    case 1:
      return 'Measurements were sent within the last hour.';
    case 2:
      return 'Measurements were not sent within the last hour.';
    default:
      return null;
  }
};
