import { RootState } from 'app/reducer';
import {
  SET_MEDIA_DETAILS_PARAMS,
  FETCH_MEDIA_DETAILS,
  fetchMediaDetailsAction,
  mediaDetailsFetchedAction
} from 'modules/Media/action/Details';
import {
  fetchMediaDetails,
  FetchMediaDetailsResponse
} from 'modules/Media/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';
import { AxiosResponse } from 'axios';

export function* setMediaDetailsParamsSaga(): SagaIterator {
  yield put(fetchMediaDetailsAction());
}

export function* fetchMediaDetailsSaga(): SagaIterator {
  try {
    const { mediaId } = yield select((state: RootState) => state.media.details);

    const response: AxiosResponse<FetchMediaDetailsResponse> = yield call(
      fetchMediaDetails,
      mediaId
    );

    const { media, knowHows } = response.data;

    media.knowHows = knowHows;

    yield put(mediaDetailsFetchedAction({ mediaDetails: media }));
  } catch (error) {
    if (error instanceof Validation) {
      yield put(mediaDetailsFetchedAction({ mediaDetails: null }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* mediaDetailsSaga(): SagaIterator {
  yield takeLatest(SET_MEDIA_DETAILS_PARAMS, setMediaDetailsParamsSaga);
  yield takeLatest(FETCH_MEDIA_DETAILS, fetchMediaDetailsSaga);
}
