import MissingFamilyDetails from 'modules/MissingFamily/model/MissingFamilyDetails';

export interface MissingFamilyDetailsState {
  missingFamilyDetails: MissingFamilyDetails;
  fetching: boolean;
  missingFamilyId: number;
}

export const initMissingFamilyDetailsState = (): MissingFamilyDetailsState => ({
  missingFamilyDetails: null,
  fetching: false,
  missingFamilyId: null
});
