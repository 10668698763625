import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import PlantDiseasesView from 'modules/PlantDiseases/view/List';
import PlantEditDiseaseView from 'modules/PlantDiseases/view/Edit';
import HealthCheckHelpListView from 'modules/PlantDiseases/view/List/Help';
import HealthCheckHelpDetailsView from 'modules/PlantDiseases/view/Details/Help';

export const ROUTE_PLANT_DISEASES = '/plant-diseases';
export const ROUTE_PLANT_DISEASES_EDIT = '/plant-diseases/:id/edit';
export const ROUTE_HEALTH_CHECK_HELP = '/plant-diseases/help';
export const ROUTE_HEALTH_CHECK_HELP_DETAILS = '/plant-diseases/help/:id(\\d+)';

export const createPlantDiseasesRoutes = (): Routes => ({
  wrapped: [
    <Route
      key="plant-diseases"
      path={ROUTE_PLANT_DISEASES}
      exact
      component={PlantDiseasesView}
    />,
    <Route
      key="plant-disease-edit"
      path={ROUTE_PLANT_DISEASES_EDIT}
      exact
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <PlantEditDiseaseView {...props} id={Number(props.match.params.id)} />
      )}
    />,
    <Route
      key="health-check-help"
      path={ROUTE_HEALTH_CHECK_HELP}
      exact
      component={HealthCheckHelpListView}
    />,
    <Route
      key="health-check-help-details"
      path={ROUTE_HEALTH_CHECK_HELP_DETAILS}
      exact
      render={(props) => (
        <HealthCheckHelpDetailsView
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          id={Number(props.match.params.id)}
        />
      )}
    />
  ]
});
