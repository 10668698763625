import React, { ReactNode } from 'react';
import {
  Message,
  PartialSearchingProps,
  SearchingProps,
  ValidationErrors
} from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import PlantImportsTable from 'modules/Plants/container/Table/Import';
import PlantPagination from 'modules/Plants/container/Pagination';
import PlantPerPage from 'modules/Plants/container/PerPage';
import Plant from 'modules/Plants/model/Plant';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setPlantImportsListParamsAction,
  SetPlantImportsListParamsAction
} from 'modules/Plants/action/list';
import { RootState } from 'app/reducer';
import { breadcrumbRoutePlantImports } from 'modules/Plants/breadcrumbs';
import PlantsImportExcelModal from 'modules/Plants/component/Modal/ImportExcel';
import { importPlantExcel } from 'modules/Plants/repository';
import ApiError from 'modules/Shared/exception/ApiError';
import {
  importPlantExcelToastError,
  importPlantExcelToastSuccess
} from 'modules/Plants/toasts';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (
    payload: PartialSearchingProps
  ) => SetPlantImportsListParamsAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export type Props = StateProps & DispatchProps;

export interface State {
  fetching: boolean;
  errors: ValidationErrors;
  errorMessage: Message | null;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPlantImportsListParamsAction(payload)),
  addToast: (payload: AddToastPayload) => dispatch(addToastAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.plant.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class PlantImportsListView extends React.Component<Props, State> {
  protected searchProps: SearchingProps;
  readonly modalImportRef: React.RefObject<PlantsImportExcelModal>;

  constructor(props: Props) {
    super(props);

    this.modalImportRef = React.createRef();

    this.state = {
      fetching: false,
      errorMessage: null,
      errors: null
    };

    this.searchProps = createSearchingProps(
      window.location.hash,
      Plant.getFilterableAttributes(),
      Plant.getSortableAttributes()
    );

    this.onSubmitModal = this.onSubmitModal.bind(this);
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Plant imports',
      breadcrumb: breadcrumbRoutePlantImports()
    });

    setParams(this.searchProps);
  }

  onSubmitModal({ file }: { file: File }): void {
    const { setParams, addToast } = this.props;

    this.setState(
      {
        fetching: true,
        errorMessage: null,
        errors: null
      },
      () => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          try {
            const finalResult = (reader.result as string)
              .replace('data:', '')
              .replace(/^.+,/, '');
            await importPlantExcel(finalResult);

            setParams({});
            addToast(importPlantExcelToastSuccess());
            this.modalImportRef.current.toggle();
          } catch (error) {
            if (error instanceof ApiError) {
              this.setState({
                errorMessage: error.getMessage(),
                errors: error.getErrors()
              });
            }

            addToast(importPlantExcelToastError());
          }

          this.setState({
            fetching: false
          });
        };
      }
    );
  }

  openModal(): void {
    this.setState(
      {
        errorMessage: null,
        errors: null
      },
      () => {
        this.modalImportRef.current.openModal();
      }
    );
  }

  render(): ReactNode {
    const { fetching: fetchingList, type } = this.props;

    if (type) return null;

    const { errors, errorMessage, fetching } = this.state;

    return (
      <div className="row plant-imports-view">
        <div className="col-12">
          <PlantsImportExcelModal
            ref={this.modalImportRef}
            onSubmit={this.onSubmitModal}
            errorMessage={errorMessage}
            errors={errors}
            fetching={fetching}
          />
          <Listing
            table={<PlantImportsTable />}
            filter={null}
            pagination={<PlantPagination />}
            perPage={<PlantPerPage />}
            addBtn={{
              text: 'Import new file',
              onClick: () => this.openModal()
            }}
            loading={fetchingList}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantImportsListView);
