import { RootState } from 'app/reducer';
import {
  PartialSearchingProps,
  PaginationParams,
  SearchParams,
  SortParams
} from 'modules/Shared/type';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Pagination } from 'modules/Layout/component/Pagination/pagination';
import {
  SetHubSoftwareListParamsAction,
  setHubSoftwareListParamsAction
} from 'modules/Hub/action/listSoftware';

export interface StateProps {
  pagination: PaginationParams;
  filter: SearchParams;
  sort: SortParams;
  currentCount: number;
  totalPages: number;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetHubSoftwareListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapState = (state: RootState): StateProps => {
  const { pagination, filter, sort, hubSoftwares, totalPages } =
    state.hub.hubSoftwareList;

  return {
    pagination,
    filter,
    sort,
    currentCount: hubSoftwares.length,
    totalPages
  };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setHubSoftwareListParamsAction(payload))
});

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(Pagination);
