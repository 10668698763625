import React, { ReactNode, ReactText } from 'react';
import './style.scss';

export interface InformationField {
  label: string;
  value: ReactText;
  afterValue?: ReactNode;
  html?: boolean;
}

export interface InformationListProps {
  fields: InformationField[];
  children?: ReactNode;
}

const InformationList: React.FC<InformationListProps> = ({
  fields,
  children
}: InformationListProps): JSX.Element => {
  const paragraphs = fields.map((field, index) => {
    const key = `information-list-item-${index}`;

    if (field.value) {
      if (field.html) {
        return (
          <div key={key} className="information-list-item">
            {field.label && (
              <p className="information-list-paragraph">
                <strong className="label">{field.label}</strong>
              </p>
            )}
            <div
              className="information-list-html"
              dangerouslySetInnerHTML={{ __html: String(field.value) }}
            />
            {Boolean(field.afterValue) && field.afterValue}
          </div>
        );
      }

      return (
        <div key={key} className="information-list-item">
          <p className="information-list-paragraph">
            {field.label && <strong className="label">{field.label}</strong>}
            <span className="value">{field.value}</span>
            {Boolean(field.afterValue) && field.afterValue}
          </p>
        </div>
      );
    }

    return null;
  });

  return (
    <div className="information-list-wrapper">
      {children}
      {paragraphs}
    </div>
  );
};

export default InformationList;
