import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setPlantListParamsAction,
  SetPlantListParamsAction
} from 'modules/Plants/action/list';
import Plant from 'modules/Plants/model/Plant';
import React, { createRef, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getPathUrl } from 'modules/Shared/helper/api';
import {
  ROUTE_PLANT_DETAILS_FORM,
  ROUTE_PLANTS_DETAILS
} from 'modules/Plants/routes';
import { ROUTE_FAMILY_DETAILS } from 'modules/Family/routes';
import ActionShow from 'modules/Layout/component/Action/Show';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import DeletePlantModal, {
  DeletePlantModal as DeletePlantModalClass
} from 'modules/Plants/component/Modal/Delete';
import ActionDelete from 'modules/Layout/component/Action/Delete';

export interface StateProps {
  plants: Plant[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetPlantListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (plant: Plant) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { plants, sort } = state.plant.list;

  return { plants, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setPlantListParamsAction(payload))
});

export class PlantTable extends React.Component<Props> {
  readonly cols: TableCol<Plant>[];
  readonly deleteRefModal: React.RefObject<DeletePlantModalClass>;

  constructor(props: Props) {
    super(props);

    this.deleteRefModal = createRef();

    this.cols = [
      {
        property: 'thumb_path',
        label: 'Image',
        type: 'img',
        className: 'col-2',
        value: (row) => row.thumb_path || '',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_PLANTS_DETAILS, { id: row.id })
      },
      {
        property: 'scientific_name',
        label: 'Scientific name',
        sortable: true,
        className: 'col-3',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_PLANTS_DETAILS, { id: row.id })
      },
      {
        property: 'name',
        label: 'Name',
        sortable: true,
        className: 'col-3',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_PLANTS_DETAILS, { id: row.id })
      },
      {
        property: 'genus',
        label: 'Genus',
        sortable: true,
        className: 'col-2',
        columnRedirectTo: (row) =>
          Boolean(row.family_id) &&
          getPathUrl(ROUTE_FAMILY_DETAILS, { id: row.family_id })
      },
      {
        property: 'confirmed',
        label: 'Is confirmed',
        value: (row) => `${row.confirmed ? 'Yes' : 'No'}`,
        className: 'col-1'
      },
      {
        property: 'is_deleted',
        label: 'Is deleted',
        value: (row) => `${row.is_deleted ? 'Yes' : 'No'}`,
        className: 'col-1'
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show plant"
              to={getPathUrl(ROUTE_PLANTS_DETAILS, { id: row.id })}
            />
            <ActionUpdate
              title="Edit plant"
              disabled={row.is_deleted}
              to={getPathUrl(ROUTE_PLANT_DETAILS_FORM, {
                id: row.id,
                type: 'main'
              })}
            />
            <ActionDelete
              title="Delete plant"
              disabled={row.is_deleted}
              onClick={() => this.deleteRefModal?.current?.openModal(row)}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { plants, sort, setParams } = this.props;

    return (
      <>
        <DeletePlantModal
          ref={this.deleteRefModal}
          onSuccess={() => setParams({ sort })}
        />
        <Table
          cols={this.cols}
          rows={plants}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantTable);
