import React from 'react';
import { EditorState } from 'react-draft-wysiwyg';
import { mapEditorStateToHtml } from 'modules/Layout/helper/wysiwyg';
import './index.scss';

export interface Props {
  editorState: EditorState;
}

const WysiwygPreview = ({ editorState }: Props): JSX.Element => {
  const html = editorState ? mapEditorStateToHtml(editorState) : '';

  return (
    <div className="wysiwyg-preview">
      <h2>Preview</h2>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default WysiwygPreview;
