import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteFamily } from 'modules/Family/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import FamilyTable from 'modules/Family/container/Table';
import FamilyFilter from 'modules/Family/container/Filter';
import FamilyPagination from 'modules/Family/container/Pagination';
import FamilyPerPage from 'modules/Family/container/PerPage';
import Family from 'modules/Family/model/Family';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setFamilyListParamsAction,
  SetFamilyListParamsAction
} from 'modules/Family/action/List';
import { RootState } from 'app/reducer';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (payload: PartialSearchingProps) => SetFamilyListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setFamilyListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.family.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class FamilyListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      Family.getFilterableAttributes(),
      Family.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Family - list',
      breadcrumb: breadcrumbRouteFamily()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { fetching, type } = this.props;
    const { filter } = this.searchProps;

    if (type) return null;
    return (
      <div className="row family-view">
        <div className="col-12">
          <Listing
            table={<FamilyTable />}
            filter={<FamilyFilter values={filter} />}
            pagination={<FamilyPagination />}
            perPage={<FamilyPerPage />}
            addBtn={{ text: 'Add family', url: '/family/add' }}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(FamilyListView);
