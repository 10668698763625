import React, { ReactNode } from 'react';
import { StandardBtn } from 'modules/Layout/view/Buttons/StandardBtn';
import { IBtnObj } from 'modules/Layout/view/Details/Helper/DisplayPrimaryInfoWrapper';
import ShowMessage from 'modules/Layout/component/ShowMessage';

export interface IRowsDisplayArray {
  optionArr: {
    content: () => JSX.Element[];
    title: React.ReactNode;
    onEditClick?: () => void;
    arrayOfBtn?: IBtnObj[];
    menu?: () => JSX.Element;
    showMessage?: {
      text: string;
      color: string;
    };
    hideMessage?: () => void;
    image?: ReactNode;
  }[];
  className?: string;
}

const DisplayOptionRowArray: React.FC<IRowsDisplayArray> = ({
  optionArr,
  className
}) => {
  return (
    <div className={className || 'col-lg-6'}>
      {optionArr.map((rowDisplay, index) => {
        return (
          <div className="card" key={index}>
            <div className="card-body p-3">
              {rowDisplay.showMessage && (
                <ShowMessage
                  message={rowDisplay.showMessage.text}
                  bsColor={rowDisplay.showMessage.color}
                  deleteMessage={rowDisplay.hideMessage}
                />
              )}

              <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                <div className="h3">{rowDisplay.title}</div>

                {rowDisplay.arrayOfBtn && (
                  <div className="d-flex flex-wrap">
                    {rowDisplay.arrayOfBtn.map((obj) => {
                      return (
                        <StandardBtn
                          callback={obj.callback}
                          text={obj.text}
                          bsColor={obj.bsColor}
                          loading={obj.loading}
                          inactive={obj.inactive}
                          key={obj.text}
                        />
                      );
                    })}
                  </div>
                )}
                {rowDisplay.onEditClick && (
                  <StandardBtn
                    callback={rowDisplay.onEditClick}
                    text="Edit"
                    classNames="ml-2"
                  />
                )}
              </div>
              {rowDisplay.menu && rowDisplay.menu()}
              <div
                className={`tab-content mt-3${
                  rowDisplay.image
                    ? ' d-flex flex-wrap-reverse flex-column-reverse flex-lg-row'
                    : ''
                }`}
              >
                {rowDisplay.image ? (
                  <>
                    <div style={{ flexGrow: 1 }}>{rowDisplay.content()}</div>
                    <div className="mx-auto mb-3">{rowDisplay.image}</div>
                  </>
                ) : (
                  rowDisplay.content()
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DisplayOptionRowArray;
