import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteAdministrators } from 'modules/User/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import AdministratorPagination from 'modules/User/container/Pagination/Administrator';
import AdministratorPerPage from 'modules/User/container/PerPage/Administrator';
import User from 'modules/User/model/User';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setAdministratorListParamsAction,
  SetAdministratorListParamsAction
} from 'modules/User/action/list';
import { RootState } from 'app/reducer';
import AdministratorTable from 'modules/User/container/Table/Administrator';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_ADD_ADMINISTRATOR } from 'modules/User/routes';
import AdministratorFilter from 'modules/User/container/Filter/Administrator';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (
    payload: PartialSearchingProps
  ) => SetAdministratorListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setAdministratorListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.user.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class UsersAdministratorListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      User.getFilterableAttributes(),
      User.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Administrators',
      breadcrumb: breadcrumbRouteAdministrators()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { filter } = this.searchProps;
    const { fetching, type } = this.props;

    if (type) return null;

    return (
      <div className="row administrators-view">
        <div className="col-12">
          <Listing
            table={<AdministratorTable />}
            filter={<AdministratorFilter values={filter} />}
            pagination={<AdministratorPagination />}
            perPage={<AdministratorPerPage />}
            loading={fetching}
            addBtn={{
              text: 'Add administrator',
              url: getPathUrl(ROUTE_ADD_ADMINISTRATOR)
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(UsersAdministratorListView);
