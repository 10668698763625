import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import React, { createRef, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  setDictionaryListParamsAction,
  SetDictionaryListParamsAction
} from 'modules/Dictionary/action/list';
import Dictionary from 'modules/Dictionary/model/Dictionary';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_DICTIONARY_DETAILS } from 'modules/Dictionary/routes';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ActionShow from 'modules/Layout/component/Action/Show';
import DeleteDictionaryModal, {
  DeleteDictionaryModal as DeleteDictionaryModalClass
} from 'modules/Dictionary/component/Modal/Delete';
import ActionDelete from 'modules/Layout/component/Action/Delete';

export interface StateProps {
  dictionaries: Dictionary[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetDictionaryListParamsAction;
}

export interface Props
  extends StateProps,
    DispatchProps,
    RouteComponentProps<any> {
  table: string;
}

export const mapState = (state: RootState): StateProps => {
  const { dictionaries, sort } = state.dictionary.list;

  return { dictionaries, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setDictionaryListParamsAction(payload))
});

export class DictionaryTable extends React.Component<Props> {
  readonly cols: TableCol<Dictionary>[];
  readonly deleteModalRef: React.RefObject<DeleteDictionaryModalClass>;

  constructor(props: Props) {
    super(props);

    this.deleteModalRef = createRef();

    const { table } = props;

    this.cols = [
      {
        property: 'name',
        label: 'Name',
        className: 'col-5',
        sortable: true,
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_DICTIONARY_DETAILS, { id: row.id }, { table })
      },
      {
        property: 'created_at',
        label: 'Created at',
        className: 'col-2'
      },
      {
        property: 'updated_at',
        label: 'Updated at',
        className: 'col-2'
      },
      {
        property: 'is_deleted',
        label: 'Is deleted',
        className: 'col-1',
        value: (row) => row.getIsDeleted()
      },
      {
        property: 'deleted_at',
        label: 'Deleted at',
        className: 'col-2'
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show dictionary"
              to={getPathUrl(
                ROUTE_DICTIONARY_DETAILS,
                { id: row.id },
                { table }
              )}
            />
            <ActionDelete
              title="Delete dictionary"
              onClick={() => this.deleteModalRef?.current?.open(row)}
              disabled={Boolean(row.is_deleted)}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { dictionaries, sort, setParams, table } = this.props;

    return (
      <>
        <DeleteDictionaryModal
          ref={this.deleteModalRef}
          onSuccess={() => setParams({ sort })}
          table={table}
        />
        <Table
          cols={this.cols}
          rows={dictionaries}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps>(mapState, mapDispatch)(DictionaryTable)
);
