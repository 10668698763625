import { BreadcrumbItem } from 'modules/Layout/type';
import MissingFamily from 'modules/MissingFamily/model/MissingFamily';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_MISSING_FAMILY } from 'modules/MissingFamily/routes';

export const breadcrumbRouteMissingFamily = (): BreadcrumbItem[] => [
  {
    title: 'Missing families'
  }
];

export const breadcrumbRouteDetailsMissingFamily = (
  missingFamily?: MissingFamily
): BreadcrumbItem[] => [
  {
    title: 'Missing families',
    link: getPathUrl(ROUTE_MISSING_FAMILY)
  },
  {
    title: missingFamily?.family_scientific_name || 'Family'
  }
];
