import PlantDetails from 'modules/Plants/model/PlantDetails';

export interface PlantDetailsState {
  plantDetails: PlantDetails;
  fetching: boolean;
  plantId: number;
}

export const initPlantDetailsState = (): PlantDetailsState => ({
  plantDetails: null,
  fetching: false,
  plantId: null
});
