import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const createMediaToastSuccess = (): AddToastPayload => ({
  header: 'Create media',
  body: 'Media created.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createMediaToastError = (): AddToastPayload => ({
  header: 'Create media',
  body: 'Creating media failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const updateMediaToastSuccess = (): AddToastPayload => ({
  header: 'Update media',
  body: 'Media updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateMediaToastError = (): AddToastPayload => ({
  header: 'Update media',
  body: 'Updating media failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deleteMediaToastSuccess = (): AddToastPayload => ({
  header: 'Delete media',
  body: 'Media deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteMediaToastError = (): AddToastPayload => ({
  header: 'Delete media',
  body: 'Deleting media failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const createMediaAdvancementToastSuccess = (): AddToastPayload => ({
  header: 'Create media advancement',
  body: 'Media advancement created.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createMediaAdvancementToastError = (): AddToastPayload => ({
  header: 'Create media advancement',
  body: 'Creating media advancement failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const updateMediaAdvancementToastSuccess = (): AddToastPayload => ({
  header: 'Update media advancement',
  body: 'Media advancement updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateMediaAdvancementToastError = (): AddToastPayload => ({
  header: 'Update media advancement',
  body: 'Updating media advancement failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deleteMediaAdvancementToastSuccess = (): AddToastPayload => ({
  header: 'Delete media advancement',
  body: 'Media advancement deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteMediaAdvancementToastError = (): AddToastPayload => ({
  header: 'Delete media advancement',
  body: 'Deleting media advancement failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const attachMediaAsKnowHowToastSuccess = (): AddToastPayload => ({
  header: 'Attach media as know-how',
  body: 'Media attached.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const attachMediaAsKnowHowToastError = (): AddToastPayload => ({
  header: 'Attach media as know-how',
  body: 'Media not attached.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
