import React from 'react';
import DisplayBaseTabContent from 'modules/Layout/view/Details/Helper/DisplayBaseTabContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { GardenEntity } from 'modules/Garden/model/Garden';
import { ROUTE_GARDEN_DETAILS_REDIRECT_TEMPLATE } from 'modules/Garden/routes';

export interface Props {
  gardens: GardenEntity[];
}

const UserDetailsGardens = (props: Props): JSX.Element => {
  const { gardens } = props;

  const cols: TableCol<GardenEntity>[] = [
    {
      label: 'Name',
      property: 'name'
    }
  ];

  return (
    <div className="content restrict-width">
      <DisplayBaseTabContent title="Gardens">
        <Table
          cols={cols}
          rows={gardens}
          onRowClickPath={ROUTE_GARDEN_DETAILS_REDIRECT_TEMPLATE}
        />
      </DisplayBaseTabContent>
    </div>
  );
};

export default UserDetailsGardens;
