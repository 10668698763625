import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deleteFamilyToastSuccess = (): AddToastPayload => ({
  header: 'Delete family',
  body: 'Family deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteFamilyToastError = (): AddToastPayload => ({
  header: 'Delete family',
  body: 'Deleting family failed.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
