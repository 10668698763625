import { BreadcrumbItem } from 'modules/Layout/type';
import {
  ROUTE_HELP_FIND_PLANT,
  ROUTE_PH_RESULTS_LIST,
  ROUTE_PLANTS,
  ROUTE_PLANTS_DETAILS
} from 'modules/Plants/routes';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import { getPathUrl } from 'modules/Shared/helper/api';

export const breadcrumbRoutePlantImports = (): BreadcrumbItem[] => [
  {
    title: 'Plant imports'
  }
];

export const breadcrumbRoutePlants = (): BreadcrumbItem[] => [
  {
    title: 'Plants'
  }
];

export const breadcrumbRouteHelpFindPlants = (): BreadcrumbItem[] => [
  {
    title: 'Help find plant',
    link: ROUTE_HELP_FIND_PLANT
  }
];

export const breadcrumbRouteHelpFindPlantDetails = (): BreadcrumbItem[] => [
  ...breadcrumbRouteHelpFindPlants(),
  {
    title: 'Details'
  }
];

export const breadcrumbRouteDetailsPlant = (
  plant?: PlantDetails
): BreadcrumbItem[] => [
  {
    title: 'Plants',
    link: '/plant/list'
  },
  {
    title: plant ? plant.scientific_name : 'Details',
    link: plant ? getPathUrl(ROUTE_PLANTS_DETAILS, { id: plant.id }) : null
  }
];

export const breadcrumbRouteAddPlant = (): BreadcrumbItem[] => [
  {
    title: 'Plants',
    link: '/plant/list'
  },
  {
    title: 'CreateUpdate'
  }
];

export const breadcrumbRouteDetailsFormMainPlant = (
  plant?: PlantDetails
): BreadcrumbItem[] => [
  {
    title: 'Plants',
    link: '/plant/list'
  },
  {
    title: plant ? plant.scientific_name : 'Details',
    link: plant ? getPathUrl(ROUTE_PLANTS_DETAILS, { id: plant.id }) : null
  },
  {
    title: 'Edit'
  }
];

export const breadcrumbRouteDetailsFormThresholdsPlant = (
  plant?: PlantDetails
): BreadcrumbItem[] => [
  {
    title: 'Plants',
    link: '/plant/list'
  },
  {
    title: plant ? plant.scientific_name : 'Details',
    link: plant ? getPathUrl(ROUTE_PLANTS_DETAILS, { id: plant.id }) : null
  },
  {
    title: 'Edit thresholds'
  }
];

export const breadcrumbRouteDetailsFormDescriptionsPlant = (
  plant?: PlantDetails,
  lang?: string
): BreadcrumbItem[] => [
  {
    title: 'Plants',
    link: '/plant/list'
  },
  {
    title: plant ? plant.scientific_name : 'Details',
    link: plant ? getPathUrl(ROUTE_PLANTS_DETAILS, { id: plant.id }) : null
  },
  {
    title: `Edit description(${lang})`
  }
];

export const breadcrumbRouteDetailsFormMeasurementsDescriptionsPlant = (
  plant?: PlantDetails,
  option?: string,
  lang?: string
): BreadcrumbItem[] => [
  {
    title: 'Plants',
    link: '/plant/list'
  },
  {
    title: plant ? plant.scientific_name : 'Details',
    link: plant ? getPathUrl(ROUTE_PLANTS_DETAILS, { id: plant.id }) : null
  },
  {
    title: `Edit ${option} descriptions(${lang})`
  }
];

export const breadcrumbRoutePlantEditDescriptions = (
  plant?: PlantDetails,
  state?: Record<string, unknown>
): BreadcrumbItem[] => [
  {
    title: 'Plants',
    link: ROUTE_PLANTS
  },
  {
    title: plant ? plant.scientific_name : 'Plant',
    link: {
      pathname: plant
        ? getPathUrl(ROUTE_PLANTS_DETAILS, { id: plant.id })
        : null,
      state
    }
  },
  {
    title: `Edit descriptions`
  }
];

export const breadcrumbRoutePlantAddImage = (
  plant?: PlantDetails,
  state?: Record<string, unknown>
): BreadcrumbItem[] => [
  {
    title: 'Plants',
    link: ROUTE_PLANTS
  },
  {
    title: plant ? plant.scientific_name : 'Plant',
    link: {
      pathname: plant
        ? getPathUrl(ROUTE_PLANTS_DETAILS, { id: plant.id })
        : null,
      state
    }
  },
  {
    title: 'Add image'
  }
];

export const breadcrumbRoutePhResults = (): BreadcrumbItem[] => [
  {
    title: 'pH results',
    link: ROUTE_PH_RESULTS_LIST
  }
];
