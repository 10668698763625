import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deleteTermsOfServiceToastSuccess = (): AddToastPayload => ({
  header: 'Delete terms of service',
  body: 'Terms of service was deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteTermsOfServiceToastError = (): AddToastPayload => ({
  header: 'Delete terms of service',
  body: 'Terms of service was not deleted.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const createTermsOfServiceToastSuccess = (): AddToastPayload => ({
  header: 'Create terms of service',
  body: 'Terms of service was created.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createTermsOfServiceToastError = (): AddToastPayload => ({
  header: 'Create terms of service',
  body: 'Terms of service was not created.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const updateTermsOfServiceToastSuccess = (): AddToastPayload => ({
  header: 'Update terms of service',
  body: 'Terms of service was updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateTermsOfServiceToastError = (): AddToastPayload => ({
  header: 'Update terms of service',
  body: 'Terms of service was not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deletePrivacyPolicyToastSuccess = (): AddToastPayload => ({
  header: 'Delete privacy policy',
  body: 'Privacy policy was deleted.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deletePrivacyPolicyToastError = (): AddToastPayload => ({
  header: 'Delete terms of service',
  body: 'Privacy policy was not deleted.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const createPrivacyPolicyToastSuccess = (): AddToastPayload => ({
  header: 'Create privacy policy',
  body: 'Privacy policy was created.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createPrivacyPolicyToastError = (): AddToastPayload => ({
  header: 'Create terms of service',
  body: 'Privacy policy was not created.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const updatePrivacyPolicyToastSuccess = (): AddToastPayload => ({
  header: 'Update privacy policy',
  body: 'Privacy policy was updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updatePrivacyPolicyToastError = (): AddToastPayload => ({
  header: 'Update terms of service',
  body: 'Privacy policy was not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
