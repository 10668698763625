import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import HubSoftwareTable from 'modules/Hub/container/Table/Software';
import HubSoftwareFilter from 'modules/Hub/container/Filter/Software';
import HubSoftwarePagination from 'modules/Hub/container/Pagination/Software';
import HubSoftwarePerPage from 'modules/Hub/container/PerPage/Software';
import { RootState } from 'app/reducer';
import {
  SetHubSoftwareListParamsAction,
  setHubSoftwareListParamsAction
} from 'modules/Hub/action/listSoftware';
import HubSoftware from 'modules/Hub/model/HubSoftware';
import { breadcrumbRouteHubSoftwares } from 'modules/Hub/breadcrumbs';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (payload: PartialSearchingProps) => SetHubSoftwareListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setHubSoftwareListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.hub.hubSoftwareList;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class HubSoftwaresListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      HubSoftware.getFilterableAttributes(),
      HubSoftware.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Hub software',
      breadcrumb: breadcrumbRouteHubSoftwares()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { fetching, type } = this.props;
    const { filter } = this.searchProps;

    if (type) return null;
    return (
      <div className="row hub-softwares-view">
        <div className="col-12">
          <Listing
            table={<HubSoftwareTable />}
            filter={<HubSoftwareFilter values={filter} />}
            pagination={<HubSoftwarePagination />}
            perPage={<HubSoftwarePerPage />}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(HubSoftwaresListView);
