import React, { ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReorderListItem from 'modules/Layout/component/ReorderList/Item';
import update from 'immutability-helper';

export interface ListCol<V = never> {
  property: Extract<keyof V, string>;
  label: ReactNode;
  value?: (row: V, index: number) => React.ReactNode;
  className?: string;
}

export interface Props<V = never> {
  accept: string;
  items: V[];
  cols: ListCol<V>[];
  onChange: (orderedList: V[]) => void;
  colKey?: (column: ListCol<V>, index: number) => React.ReactText;
  rowKey?: (row: V, index: number) => React.ReactText;
  withDelete?: boolean;
}

class ReorderList<V = never> extends React.Component<Props<V>> {
  constructor(props: Props<V>) {
    super(props);

    this.getRowKey = this.getRowKey.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.moveCard = this.moveCard.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  getRowKey(row: V, index: number): string | number {
    const { rowKey } = this.props;

    if (rowKey) {
      return rowKey(row, index);
    }

    return `row-${index}`;
  }

  moveCard(dragIndex: number, hoverIndex: number): void {
    const { items, onChange } = this.props;

    const dragCard = items[dragIndex];

    onChange(
      update(items, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      })
    );
  }

  onDelete(indexToRemove: number): void {
    const { onChange, items } = this.props;

    onChange(items.filter((_, index) => index !== indexToRemove));
  }

  renderRow(item: V, index: number): ReactNode {
    const { cols, colKey, accept, items, withDelete = false } = this.props;

    const id = String(this.getRowKey(item, index));

    return (
      <ReorderListItem
        id={id}
        accept={accept}
        length={items.length}
        key={id}
        item={item}
        index={index}
        cols={cols}
        colKey={colKey}
        moveCard={this.moveCard}
        onDelete={withDelete ? () => this.onDelete(index) : null}
      />
    );
  }

  render(): ReactNode {
    const { items } = this.props;

    if (items.length === 0) {
      return (
        <div className="d-flex justify-content-center font-weight-bold">
          List is empty
        </div>
      );
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="order-list-container">{items.map(this.renderRow)}</div>
      </DndProvider>
    );
  }
}

export default ReorderList;
