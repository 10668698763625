import React, { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { PageProps } from 'modules/Layout/type';
import { ManagePageAction, managePageAction } from 'modules/Layout/action';
import {
  manageThrow as manageThrowAction,
  ManageThrowAction
} from 'modules/Shared/action';
import { RootState } from 'app/reducer';
import { connect } from 'react-redux';
import { breadcrumbRoutePrivacyPolicy } from 'modules/Policy/breadcrumbs';
import { fetchPPDetails } from 'modules/Policy/repository';
import PrivacyPolicy, {
  createPrivacyPolicy
} from 'modules/Policy/model/PrivacyPolicy';
import PrivacyPolicyDetails from 'modules/Policy/component/Details/PrivacyPolicy';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  manageThrow: (error: Error) => ManageThrowAction;
}

export interface Props extends StateProps, DispatchProps {
  id: string;
}

export interface State {
  pp: PrivacyPolicy;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  manageThrow: (error: Error) => dispatch(manageThrowAction(error))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

class PrivacyPolicyDetailsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pp: null
    };
  }

  async componentDidMount(): Promise<void> {
    const { managePage, id, manageThrow } = this.props;

    managePage({
      title: 'Privacy policy',
      breadcrumb: breadcrumbRoutePrivacyPolicy(),
      type: 'loading'
    });

    try {
      const { data } = await fetchPPDetails(id);

      const pp = createPrivacyPolicy(data.pp);

      this.setState({
        pp
      });

      managePage({
        title: 'Privacy policy',
        breadcrumb: breadcrumbRoutePrivacyPolicy(pp),
        type: null
      });
    } catch (error) {
      manageThrow(error);
    }
  }

  render(): ReactNode {
    const { type } = this.props;
    const { pp } = this.state;

    if (type || !pp) {
      return null;
    }

    return (
      <div className="row justify-content-center">
        <div className="col-md-6">
          <PrivacyPolicyDetails pp={pp} />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PrivacyPolicyDetailsView);
