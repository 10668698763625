import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteUsersFeedback } from 'modules/User/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import UserFeedbackFilter from 'modules/User/container/Filter/Feedback';
import UserFeedbackPagination from 'modules/User/container/Pagination/Feedback';
import UserFeedbackPerPage from 'modules/User/container/PerPage/Feedback';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setUserFeedbackListParamsAction,
  SetUserFeedbackListParamsAction
} from 'modules/User/action/list';
import { RootState } from 'app/reducer';
import Feedback from 'modules/User/model/Feedback';
import UserFeedbackTable from 'modules/User/container/Table/Feedback';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (
    payload: PartialSearchingProps
  ) => SetUserFeedbackListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setUserFeedbackListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.user.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class UsersFeedbackListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      Feedback.getFilterableAttributes(),
      Feedback.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Users feedback',
      breadcrumb: breadcrumbRouteUsersFeedback()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { filter } = this.searchProps;
    const { fetching, type } = this.props;

    if (type) return null;

    return (
      <div className="row users-feedback-view">
        <div className="col-12">
          <Listing
            table={<UserFeedbackTable />}
            filter={<UserFeedbackFilter values={filter} />}
            pagination={<UserFeedbackPagination />}
            perPage={<UserFeedbackPerPage />}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(UsersFeedbackListView);
