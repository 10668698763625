import React, { ReactNode } from 'react';
import { Button, Label } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import PlantAutocomplete from 'modules/Plants/container/Autocomplete/Plant';
import { PlantEntity } from 'modules/Plants/model/Plant';
import ActionDelete from 'modules/Layout/component/Action/Delete';

export interface Props {
  onSuccess: (plants: PlantEntity[]) => void;
}

export interface State {
  isOpen: boolean;
  fetching: boolean;
  plants: PlantEntity[];
}

export class AddPlantsNewsModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      fetching: false,
      plants: []
    };

    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addNewPlant = this.addNewPlant.bind(this);
    this.deletePlant = this.deletePlant.bind(this);
  }

  openModal(): void {
    this.setState({
      isOpen: true,
      fetching: false,
      plants: []
    });
  }

  toggle(): void {
    this.setState({
      isOpen: false
    });
  }

  renderTitle = (): React.ReactNode => {
    return 'Add plants to home screen';
  };

  addNewPlant(plant: PlantEntity): void {
    this.setState((state) => ({
      plants: [...state.plants, plant]
    }));
  }

  deletePlant(id: number): void {
    this.setState((state) => ({
      plants: state.plants.filter((obj) => obj.id !== id)
    }));
  }

  renderBody(): React.ReactNode {
    const { plants } = this.state;

    return (
      <>
        <PlantAutocomplete onChange={this.addNewPlant} />
        <Label>Plants to add on home screen:</Label>
        {plants.map((plant) => (
          <div key={plant.id} className="d-flex align-items-center my-2">
            <div className="mr-2">-</div>
            <img
              className="mr-2"
              style={{ maxHeight: '40px' }}
              src={plant.thumb_path}
              alt=""
            />
            <div style={{ whiteSpace: 'break-spaces', fontWeight: 'bold' }}>
              {plant.scientific_name}
            </div>
            <ActionDelete
              className="ml-2"
              onClick={() => this.deletePlant(plant.id)}
            />
          </div>
        ))}
      </>
    );
  }

  onSubmit(): void {
    const { onSuccess } = this.props;
    const { plants } = this.state;

    onSuccess(plants);
    this.toggle();
  }

  renderFooter(): React.ReactNode {
    const { fetching } = this.state;

    return (
      <div className="d-flex justify-content-between w-100">
        <Button disabled={fetching} color="primary" onClick={this.toggle}>
          Cancel
        </Button>
        <Button disabled={fetching} color="success" onClick={this.onSubmit}>
          Add plants to home screen
        </Button>
      </div>
    );
  }

  render(): ReactNode {
    const { isOpen } = this.state;

    return (
      <BaseModal
        isOpen={isOpen}
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        toggle={this.toggle}
      />
    );
  }
}

export default AddPlantsNewsModal;
