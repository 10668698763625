import { RootState } from 'app/reducer';
import {
  SET_GARDEN_DETAILS_PARAMS,
  FETCH_GARDEN_DETAILS,
  fetchGardenDetailsAction,
  gardenDetailsFetchedAction
} from 'modules/Garden/action/details';
import {
  fetchGardenDetails,
  FetchGardenDetailsResponse
} from 'modules/Garden/repository';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Validation from 'modules/Shared/exception/Validation';
import { manageThrow } from 'modules/Shared/action';

export function* setGardenDetailsParamsSaga(): SagaIterator {
  yield put(fetchGardenDetailsAction());
}

export function* fetchGardenDetailsSaga(): SagaIterator {
  try {
    const { gardenId } = yield select(
      (state: RootState) => state.garden.details
    );

    const response: FetchGardenDetailsResponse = yield call(
      fetchGardenDetails,
      gardenId
    );

    const { garden, userPlants } = response.data;

    yield put(
      gardenDetailsFetchedAction({
        gardenDetails: { ...garden, userPlants: userPlants }
      })
    );
  } catch (error) {
    if (error instanceof Validation) {
      yield put(gardenDetailsFetchedAction({ gardenDetails: null }));
    } else {
      yield put(manageThrow(error));
    }
  }
}

export default function* plantDetailsSaga(): SagaIterator {
  yield takeLatest(SET_GARDEN_DETAILS_PARAMS, setGardenDetailsParamsSaga);
  yield takeLatest(FETCH_GARDEN_DETAILS, fetchGardenDetailsSaga);
}
