import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { GardenEntity } from 'modules/Garden/model/Garden';
import { PlantUserDetailsEntity } from 'modules/PlantUser/model/PlantUserDetails';

export const API_GARDEN = '/admin-garden/list';
export const API_GARDEN_DETAILS = '/admin-garden/:id';
export const API_GARDEN_DETAILS_REDIRECT_TEMPLATE = '/admin-garden/:id';

// list

export type FetchGardensResponse = AxiosResponse<{
  content: GardenEntity[];
  totalCount: any;
}>;

export const fetchGardens = (
  params: SearchParams = {}
): AxiosPromise<FetchGardensResponse> =>
  axios.post(getAPIUrl(API_GARDEN, null), params);

// details

export type FetchGardenDetailsResponse = AxiosResponse<{
  garden: GardenEntity;
  userPlants: PlantUserDetailsEntity[];
}>;

export const fetchGardenDetails = (
  id: number
): AxiosPromise<FetchGardenDetailsResponse> =>
  axios.get(getAPIUrl(API_GARDEN_DETAILS, { id }));
