export type SensorEntity = {
  id?: string;
  user_id?: string;
  user_plant_id?: string;
  sensor_id?: string;
  email?: number;
  username?: string;
  nickname?: number;
  created_at?: string;
  mac_address?: string;
};

export default class Sensor {
  readonly id?: string;
  readonly user_id?: string;
  readonly user_plant_id?: string;
  readonly sensor_id?: string;
  readonly mac_address?: string;
  readonly email?: number;
  readonly username?: string;
  readonly nickname?: number;
  readonly created_at?: string;

  constructor(entity: SensorEntity) {
    this.id = entity.id;
    this.user_id = entity.user_id;
    this.user_plant_id = entity.user_plant_id;
    this.sensor_id = entity.sensor_id;
    this.mac_address = entity.mac_address;
    this.email = entity.email;
    this.username = entity.username;
    this.nickname = entity.nickname;
    this.created_at = entity.created_at;
  }

  static getFilterableAttributes(): string[] {
    return ['id', 'sensor_id', 'username', 'email', 'nickname', 'with_deleted'];
  }

  static getSortableAttributes(): string[] {
    return ['id', 'sensor_id', 'username', 'email', 'nickname', 'created_at'];
  }
}

export const createSensor = (entity: SensorEntity): Sensor =>
  new Sensor(entity);
