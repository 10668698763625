import { createHubSoftware } from 'modules/Hub/model/HubSoftware';
import {
  FETCH_HUB_SOFTWARE_LIST,
  HUB_SOFTWARE_LIST_FETCHED,
  HubSoftwareListAction,
  SET_HUB_SOFTWARE_LIST_PARAMS
} from 'modules/Hub/action/listSoftware';
import {
  HubSoftwareListState,
  initHubSoftwareListState
} from 'modules/Hub/state/hubSoftwareList';

const initState = initHubSoftwareListState();

const reducer = (
  state: HubSoftwareListState = initState,
  action: HubSoftwareListAction
): HubSoftwareListState => {
  switch (action.type) {
    case SET_HUB_SOFTWARE_LIST_PARAMS:
      return { ...state, ...action.payload };

    case FETCH_HUB_SOFTWARE_LIST:
      return { ...state, fetching: true };

    case HUB_SOFTWARE_LIST_FETCHED: {
      const { hubSoftwares = [], totalPages } = action.payload;

      return {
        ...state,
        fetching: false,
        hubSoftwares: hubSoftwares.map(createHubSoftware),
        totalPages
      };
    }

    default:
      return state;
  }
};

export default reducer;
