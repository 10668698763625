import React, { useRef, useState } from 'react';
import { MeasurementsKnowHow as MeasurementsKnowHowType } from 'modules/Shared/configs/measurementKnowHowEntity/type';
import DisplayNav from 'modules/Layout/view/Details/Helper/DisplayNav';
import { MeasurementsKnowHowTable } from 'modules/Plants/component/Table/MeasurementsKnowHow';
import { MeasurementStatusesEnum } from 'modules/Shared/configs/measurementStatusDescEntity/type';
import { Button } from 'reactstrap';
import AddMeasurementKnowHowModal from 'modules/Plants/component/Modal/MeasurementsKnowHow/Add';
import PlantDetails from 'modules/Plants/model/PlantDetails';
import PlantGroupDetails from 'modules/PlantGroups/model/PlantGroupDetails';
import FamilyDetails from '../../../Family/model/FamilyDetails';

export interface Props {
  plant?: PlantDetails;
  family?: FamilyDetails;
  plantGroup?: PlantGroupDetails;
  measurementsKnowHow: MeasurementsKnowHowType[];
  showActions?: boolean;
  onSuccess?: (media?: MeasurementsKnowHowType) => void;
  plantGroupUpdated?: (
    knowHow: MeasurementsKnowHowType,
    type: 'add' | 'delete'
  ) => void;
}

const MeasurementsKnowHow: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    measurementsKnowHow,
    plant,
    plantGroup,
    onSuccess,
    plantGroupUpdated,
    family,
    showActions = true
  } = props;
  const [measurementsNav, setMeasurementsNav] = useState([
    true,
    false,
    false,
    false
  ]);
  const addModalRef = useRef(null);

  let measurementToFilter:
    | 'temperature'
    | 'light'
    | 'soil_moisture'
    | 'soil_fertility' = 'temperature';

  if (measurementsNav[1]) {
    measurementToFilter = 'soil_moisture';
  } else if (measurementsNav[2]) {
    measurementToFilter = 'soil_fertility';
  } else if (measurementsNav[3]) {
    measurementToFilter = 'light';
  }

  const filteredMeasurements = measurementsKnowHow.filter(
    (measurement) => measurement.measurement === measurementToFilter
  );

  return (
    <div className="content restrict-width">
      <AddMeasurementKnowHowModal
        ref={addModalRef}
        onSuccess={onSuccess}
        plant={plant}
        plantGroupUpdated={plantGroupUpdated}
        plantGroup={plantGroup}
        familyMeasurementsKnowHows={measurementsKnowHow}
        family={family}
      />
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="h3">Measurements know-how (Tutorials)</div>
              </div>
              <DisplayNav
                booleanArr={measurementsNav}
                setBooleanArr={setMeasurementsNav}
                valueArr={[
                  'Temperature',
                  'Soil moisture',
                  'Soil fertility',
                  'Light'
                ]}
              />
              <div className="tab-content mt-3">
                <div className="form-group mb-3">
                  <div className="form-row justify-content-between">
                    <div className="mr-1 h4 d-flex justify-content-center align-items-center">
                      Too low
                    </div>
                    {showActions && (
                      <Button
                        color="success"
                        onClick={() =>
                          addModalRef?.current?.open(
                            measurementToFilter,
                            MeasurementStatusesEnum.TOO_LOW
                          )
                        }
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  <div className="mt-2">
                    <MeasurementsKnowHowTable
                      plant={plant}
                      plantGroup={plantGroup}
                      family={family}
                      measurementsKnowHow={filteredMeasurements.filter(
                        (obj) =>
                          obj.measurement_status ===
                          MeasurementStatusesEnum.TOO_LOW
                      )}
                      showActions={showActions}
                      plantGroupUpdated={plantGroupUpdated}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="form-row justify-content-between">
                    <div className="mr-1 h4 d-flex justify-content-center align-items-center">
                      Low
                    </div>
                    {showActions && (
                      <Button
                        color="success"
                        onClick={() =>
                          addModalRef?.current?.open(
                            measurementToFilter,
                            MeasurementStatusesEnum.LOW
                          )
                        }
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  <div className="mt-2">
                    <MeasurementsKnowHowTable
                      plant={plant}
                      plantGroup={plantGroup}
                      family={family}
                      measurementsKnowHow={filteredMeasurements.filter(
                        (obj) =>
                          obj.measurement_status === MeasurementStatusesEnum.LOW
                      )}
                      showActions={showActions}
                      plantGroupUpdated={plantGroupUpdated}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="form-row justify-content-between">
                    <div className="mr-1 h4 d-flex justify-content-center align-items-center">
                      Perfect
                    </div>
                    {showActions && (
                      <Button
                        color="success"
                        onClick={() =>
                          addModalRef?.current?.open(
                            measurementToFilter,
                            MeasurementStatusesEnum.PERFECT
                          )
                        }
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  <div className="mt-2">
                    <MeasurementsKnowHowTable
                      plant={plant}
                      plantGroup={plantGroup}
                      measurementsKnowHow={filteredMeasurements.filter(
                        (obj) =>
                          obj.measurement_status ===
                          MeasurementStatusesEnum.PERFECT
                      )}
                      family={family}
                      showActions={showActions}
                      plantGroupUpdated={plantGroupUpdated}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="form-row justify-content-between">
                    <div className="mr-1 h4 d-flex justify-content-center align-items-center">
                      High
                    </div>
                    {showActions && (
                      <Button
                        color="success"
                        onClick={() =>
                          addModalRef?.current?.open(
                            measurementToFilter,
                            MeasurementStatusesEnum.HIGH
                          )
                        }
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  <div className="mt-2">
                    <MeasurementsKnowHowTable
                      plant={plant}
                      plantGroup={plantGroup}
                      measurementsKnowHow={filteredMeasurements.filter(
                        (obj) =>
                          obj.measurement_status ===
                          MeasurementStatusesEnum.HIGH
                      )}
                      family={family}
                      showActions={showActions}
                      plantGroupUpdated={plantGroupUpdated}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="form-row justify-content-between">
                    <div className="mr-1 h4 d-flex justify-content-center align-items-center">
                      Too high
                    </div>
                    {showActions && (
                      <Button
                        color="success"
                        onClick={() =>
                          addModalRef?.current?.open(
                            measurementToFilter,
                            MeasurementStatusesEnum.TOO_HIGH
                          )
                        }
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  <div className="mt-2">
                    <MeasurementsKnowHowTable
                      plant={plant}
                      plantGroup={plantGroup}
                      measurementsKnowHow={filteredMeasurements.filter(
                        (obj) =>
                          obj.measurement_status ===
                          MeasurementStatusesEnum.TOO_HIGH
                      )}
                      family={family}
                      showActions={showActions}
                      plantGroupUpdated={plantGroupUpdated}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeasurementsKnowHow;
