import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const updateFaqToastSuccess = (): AddToastPayload => ({
  header: 'FAQ',
  body: 'Faq updated.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateFaqToastError = (): AddToastPayload => ({
  header: 'FAQ',
  body: 'FAQ not updated.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
