import { TresholdsEntity } from 'modules/Shared/configs/tresholdsEntity/type';

export const thresholdArr = (thresholds: TresholdsEntity = null) => [
  {
    title: 'Temperature',
    description: thresholds?.temperature_min_acceptable || '',
    shortTitle: 'min acceptable',
    mapped: 'temperature_min_acceptable',
    type: 'number'
  },
  {
    title: 'Temperature',
    description: thresholds?.temperature_min_good || '',
    shortTitle: 'min good',
    mapped: 'temperature_min_good',
    type: 'number'
  },
  {
    title: 'Temperature',
    description: thresholds?.temperature_max_good || '',
    shortTitle: 'max good',
    mapped: 'temperature_max_good',
    type: 'number'
  },
  {
    title: 'Temperature',
    description: thresholds?.temperature_max_acceptable || '',
    shortTitle: 'max acceptable',
    mapped: 'temperature_max_acceptable',
    type: 'number'
  },
  {
    title: 'Light',
    description: thresholds?.light_min_acceptable || '',
    shortTitle: 'min acceptable',
    mapped: 'light_min_acceptable',
    type: 'number'
  },
  {
    title: 'Light',
    description: thresholds?.light_min_good || '',
    shortTitle: 'min good',
    mapped: 'light_min_good',
    type: 'number'
  },
  {
    title: 'Light',
    description: thresholds?.light_max_good || '',
    shortTitle: 'max good',
    mapped: 'light_max_good',
    type: 'number'
  },
  {
    title: 'Light',
    description: thresholds?.light_max_acceptable || '',
    shortTitle: 'max acceptable',
    mapped: 'light_max_acceptable',
    type: 'number'
  },
  {
    title: 'DLI light',
    description: thresholds?.dli_light_min_acceptable || '',
    shortTitle: 'min acceptable',
    mapped: 'dli_light_min_acceptable',
    type: 'number'
  },
  {
    title: 'DLI light',
    description: thresholds?.dli_light_min_good || '',
    shortTitle: 'min good',
    mapped: 'dli_light_min_good',
    type: 'number'
  },
  {
    title: 'DLI light',
    description: thresholds?.dli_light_max_good || '',
    shortTitle: 'max good',
    mapped: 'dli_light_max_good',
    type: 'number'
  },
  {
    title: 'DLI light',
    description: thresholds?.dli_light_max_acceptable || '',
    shortTitle: 'max acceptable',
    mapped: 'dli_light_max_acceptable',
    type: 'number'
  },
  {
    title: 'Moisture',
    description: thresholds?.moisture_min_acceptable || '',
    shortTitle: 'min acceptable',
    mapped: 'moisture_min_acceptable',
    type: 'number'
  },
  {
    title: 'Moisture',
    description: thresholds?.moisture_min_good || '',
    shortTitle: 'min good',
    mapped: 'moisture_min_good',
    type: 'number'
  },
  {
    title: 'Moisture',
    description: thresholds?.moisture_max_good || '',
    shortTitle: 'max good',
    mapped: 'moisture_max_good',
    type: 'number'
  },
  {
    title: 'Moisture',
    description: thresholds?.moisture_max_acceptable || '',
    shortTitle: 'max acceptable',
    mapped: 'moisture_max_acceptable',
    type: 'number'
  },
  {
    title: 'Salinity',
    description: thresholds?.salinity_min_acceptable || '',
    shortTitle: 'min acceptable',
    mapped: 'salinity_min_acceptable',
    type: 'number'
  },
  {
    title: 'Salinity',
    description: thresholds?.salinity_min_good || '',
    shortTitle: 'min good',
    mapped: 'salinity_min_good',
    type: 'number'
  },
  {
    title: 'Salinity',
    description: thresholds?.salinity_max_good || '',
    shortTitle: 'max good',
    mapped: 'salinity_max_good',
    type: 'number'
  },
  {
    title: 'Salinity',
    description: thresholds?.salinity_max_acceptable || '',
    shortTitle: 'max acceptable',
    mapped: 'salinity_max_acceptable',
    type: 'number'
  },
  {
    title: 'Soil pH',
    description: thresholds?.ph_min || '',
    shortTitle: 'min',
    mapped: 'ph_min',
    type: 'number'
  },
  {
    title: 'Soil pH',
    description: thresholds?.ph_max || '',
    shortTitle: 'max',
    mapped: 'ph_max',
    type: 'number'
  },
  {
    title: 'Air humidity',
    description: thresholds?.air_humidity_min || '',
    shortTitle: 'min',
    mapped: 'air_humidity_min',
    type: 'number'
  },
  {
    title: 'Air humidity',
    description: thresholds?.air_humidity_max || '',
    shortTitle: 'max',
    mapped: 'air_humidity_max',
    type: 'number'
  }
];
