import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import Media from 'modules/Media/model/Media';
import React, { createRef, ReactNode } from 'react';
import { connect } from 'react-redux';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import {
  setMediaListParamsAction,
  SetMediaListParamsAction
} from 'modules/Media/action/List';
import { Dispatch } from 'redux';
import { getPathUrl } from 'modules/Shared/helper/api';
import {
  ROUTE_MEDIA_DETAILS,
  ROUTE_MEDIA_DETAILS_FORM
} from 'modules/Media/routes';
import ActionShow from 'modules/Layout/component/Action/Show';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import DeleteMediaModal, {
  DeleteMediaModal as DeleteMediaModalClass
} from 'modules/Media/component/Modal/Delete';

export interface StateProps {
  media: Media[];
  sort: SortParams;
}

export interface DispatchProps {
  setParams: (payload: PartialSearchingProps) => SetMediaListParamsAction;
}

export interface Props extends StateProps, DispatchProps {
  onDeleteClick?: (media: Media) => void;
}

export const mapState = (state: RootState): StateProps => {
  const { media, sort } = state.media.list;

  return { media, sort };
};

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setMediaListParamsAction(payload))
});

export class MediaTable extends React.Component<Props> {
  readonly cols: TableCol<Media>[];
  readonly deleteModalRef: React.RefObject<DeleteMediaModalClass>;

  constructor(props: Props) {
    super(props);

    this.deleteModalRef = createRef();

    this.cols = [
      {
        property: 'image',
        label: 'Image',
        type: 'img',
        value: (row) => row.image,
        className: 'col-2',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_MEDIA_DETAILS, { id: row.id })
      },
      {
        property: 'title',
        label: 'Title',
        className: 'col-8',
        columnRedirectTo: (row) =>
          getPathUrl(ROUTE_MEDIA_DETAILS, { id: row.id })
      },
      {
        property: 'type',
        label: 'Type',
        className: 'col-2'
      },
      {
        property: null,
        label: 'Action',
        value: (row) => (
          <div className="actions-wrapper">
            <ActionShow
              title="Show media"
              to={getPathUrl(ROUTE_MEDIA_DETAILS, { id: row.id })}
            />
            <ActionUpdate
              title="Edit media"
              to={getPathUrl(ROUTE_MEDIA_DETAILS_FORM, { id: row.id })}
            />
            <ActionDelete
              title="Delete media"
              onClick={() => this.deleteModalRef?.current?.openModal(row)}
            />
          </div>
        )
      }
    ];
  }

  render(): ReactNode {
    const { media, sort, setParams } = this.props;

    return (
      <>
        <DeleteMediaModal
          ref={this.deleteModalRef}
          onSuccess={() => setParams({ sort })}
        />
        <Table
          cols={this.cols}
          rows={media}
          sort={sort}
          onSort={(params) => setParams({ sort: params })}
        />
      </>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(MediaTable);
