import React, { ReactNode } from 'react';
import { PartialSearchingProps, SearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import { Dispatch } from 'redux';
import { breadcrumbRouteGardens } from 'modules/Garden/breadcrumbs';
import { connect } from 'react-redux';
import Listing from 'modules/Layout/component/Listing';
import GardenTable from 'modules/Garden/container/Table';
import GardenFilter from 'modules/Garden/container/Filter';
import GardenPagination from 'modules/Garden/container/Pagination';
import GardenPerPage from 'modules/Garden/container/PerPage';
import Garden from 'modules/Garden/model/Garden';
import { createSearchingProps } from 'modules/Shared/helper/params';
import {
  setGardenListParamsAction,
  SetGardenListParamsAction
} from 'modules/Garden/action/list';
import { RootState } from 'app/reducer';

export interface StateProps {
  fetching: boolean;
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  setParams: (payload: PartialSearchingProps) => SetGardenListParamsAction;
}

export type Props = StateProps & DispatchProps;

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload: PageProps) => dispatch(managePageAction(payload)),
  setParams: (payload: PartialSearchingProps) =>
    dispatch(setGardenListParamsAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { fetching } = state.garden.list;
  const { type } = state.layout.page;

  return { fetching, type };
};

export class GardensListView extends React.Component<Props> {
  protected searchProps: SearchingProps;

  constructor(props: Props) {
    super(props);

    this.searchProps = createSearchingProps(
      window.location.hash,
      Garden.getFilterableAttributes(),
      Garden.getSortableAttributes()
    );
  }

  componentDidMount(): void {
    const { managePage, setParams } = this.props;

    managePage({
      title: 'Gardens',
      breadcrumb: breadcrumbRouteGardens()
    });

    setParams(this.searchProps);
  }

  render(): ReactNode {
    const { filter } = this.searchProps;
    const { fetching, type } = this.props;

    if (type) return null;
    return (
      <div className="row gardens-view">
        <div className="col-12">
          <Listing
            table={<GardenTable />}
            filter={<GardenFilter values={filter} />}
            pagination={<GardenPagination />}
            perPage={<GardenPerPage />}
            loading={fetching}
          />
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(GardensListView);
