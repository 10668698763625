import {
  setSearchingParamsAction,
  SetSearchingParamsAction
} from 'modules/Shared/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { PlantGroupsEntity } from 'modules/PlantGroups/model/PlantGroups';
import { Action } from 'redux';

export const SET_PLANT_GROUPS_LIST_PARAMS = 'PLANTGROUPS/LIST/PARAMS/SET';
export const UPDATE_PLANT_GROUPS_LIST_PARAMS = 'PLANTGROUPS/LIST/PARAMS/UPDATE';
export const FETCH_PLANT_GROUPS_LIST = 'PLANTGROUPS/LIST/FETCH';
export const PLANT_GROUPS_LIST_FETCHED = 'PLANTGROUPS/LIST/FETCHED';

export type PlantGroupsListAction =
  | SetPlantGroupsListParamsAction
  | FetchPlantGroupsListAction
  | PlantGroupsListFetchedAction;

export type SetPlantGroupsListParamsAction = SetSearchingParamsAction<
  typeof SET_PLANT_GROUPS_LIST_PARAMS
>;

export const setPlantGroupsListParamsAction = (
  payload: PartialSearchingProps
): SetPlantGroupsListParamsAction =>
  setSearchingParamsAction(SET_PLANT_GROUPS_LIST_PARAMS, payload);

export type FetchPlantGroupsListAction = Action<typeof FETCH_PLANT_GROUPS_LIST>;

export const fetchPlantGroupsListAction = (): FetchPlantGroupsListAction => ({
  type: FETCH_PLANT_GROUPS_LIST
});

export type PlantGroupsListFetchedPayload = {
  plantGroups: PlantGroupsEntity[];
  totalPages: number;
};

export interface PlantGroupsListFetchedAction
  extends Action<typeof PLANT_GROUPS_LIST_FETCHED> {
  payload: PlantGroupsListFetchedPayload;
}

export const plantGroupsListFetchedAction = (
  payload: PlantGroupsListFetchedPayload
): PlantGroupsListFetchedAction => ({
  type: PLANT_GROUPS_LIST_FETCHED,
  payload
});
