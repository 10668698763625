import React, { createRef, FormEvent, ReactNode, RefObject } from 'react';
import { PageProps } from 'modules/Layout/type';
import {
  addToastAction,
  AddToastAction,
  AddToastPayload,
  managePageAction,
  ManagePageAction
} from 'modules/Layout/action';
import { Dispatch } from 'redux';
import Loader from 'modules/Layout/component/Loader';
import { breadcrumbRouteEditPlantDisease } from 'modules/PlantDiseases/breadcrumbs';
import PlantDisease, {
  createPlantDisease,
  PlantDiseaseLang
} from 'modules/PlantDiseases/model/PlantDisease';
import {
  fetchPlantDisease,
  updatePlantDisease
} from 'modules/PlantDiseases/repository';
import { connect } from 'react-redux';
import { RootState } from 'app/reducer';
import { Button, Card, CardBody, Form } from 'reactstrap';
import { supportedLangs } from 'modules/Shared/configs/supportedLang/config';
import NavTab from 'modules/Layout/component/NavTab';
import Wysiwyg from 'modules/Layout/component/Wysiwyg';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PLANT_DISEASES } from 'modules/PlantDiseases/routes';
import { mapEditorStateToHtml } from 'modules/Layout/helper/wysiwyg';
import {
  editPlantDiseaseToastError,
  editPlantDiseaseToastSuccess
} from 'modules/PlantDiseases/toasts';

export interface StateProps {
  type: string;
}

export interface DispatchProps {
  managePage: (payload: PageProps) => ManagePageAction;
  addToast: (payload: AddToastPayload) => AddToastAction;
}

export interface Props extends StateProps, DispatchProps, RouteComponentProps {
  id: number;
}

export interface State {
  fetching: boolean;
  plantDisease: PlantDisease;
  activeLang: string;
  updating: boolean;
}

export const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
  managePage: (payload) => dispatch(managePageAction(payload)),
  addToast: (payload) => dispatch(addToastAction(payload))
});

export const mapState = (state: RootState): StateProps => {
  const { type } = state.layout.page;

  return { type };
};

export class PlantEditDiseaseView extends React.Component<Props, State> {
  readonly wysiwygRefs: {
    [lang: string]: {
      [section: string]: RefObject<Wysiwyg>;
    };
  };

  constructor(props: Props) {
    super(props);

    this.wysiwygRefs = {};

    supportedLangs.forEach((lang) => {
      ['about', 'treatment', 'prevention'].forEach((section) => {
        if (!this.wysiwygRefs[lang]) {
          this.wysiwygRefs[lang] = {};
        }
        this.wysiwygRefs[lang][section] = createRef();
      });
    });

    this.state = {
      fetching: true,
      plantDisease: null,
      activeLang: 'en',
      updating: false
    };

    this.fetchDisease = this.fetchDisease.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderWysiwyg = this.renderWysiwyg.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
  }

  async fetchDisease(): Promise<void> {
    const { id } = this.props;

    this.setState({
      fetching: true
    });

    const {
      data: { data }
    } = await fetchPlantDisease(id);

    this.setState({
      fetching: false,
      plantDisease: createPlantDisease(data)
    });
  }

  async componentDidMount(): Promise<void> {
    const { managePage } = this.props;

    await this.fetchDisease();

    managePage({
      title: 'Plant disease - edit',
      breadcrumb: breadcrumbRouteEditPlantDisease()
    });
  }

  renderWysiwyg(key: keyof PlantDiseaseLang, lang: string): ReactNode {
    const { plantDisease } = this.state;
    const initialValue = plantDisease.lang.find((obj) => obj.lang === lang)?.[
      key
    ];

    return (
      <div key={`${key}-${lang}`} className="mt-4">
        <div className="font-weight-bold text-capitalize">{key}</div>
        <Wysiwyg
          ref={this.wysiwygRefs[lang][key]}
          initialValue={initialValue}
        />
      </div>
    );
  }

  async onSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { id, addToast, history } = this.props;

    this.setState({
      updating: true
    });

    const langs: {
      [lang: string]: { about: string; treatment: string; prevention: string };
    } = {};

    supportedLangs.forEach((lang) => {
      const treatment = mapEditorStateToHtml(
        this.wysiwygRefs[lang].treatment.current.state.editorState
      );
      const about = mapEditorStateToHtml(
        this.wysiwygRefs[lang].about.current.state.editorState
      );
      const prevention = mapEditorStateToHtml(
        this.wysiwygRefs[lang].prevention.current.state.editorState
      );

      langs[lang] = {
        treatment,
        about,
        prevention
      };
    });

    try {
      await updatePlantDisease(id, langs);
      addToast(editPlantDiseaseToastSuccess());

      history.push({
        pathname: getPathUrl(ROUTE_PLANT_DISEASES)
      });
    } catch (error) {
      console.log(error);
      addToast(editPlantDiseaseToastError());
      this.setState({
        updating: false
      });
    }
  }

  renderTitle(): ReactNode {
    const { plantDisease } = this.state;

    let title = `${plantDisease.first_type} >`;

    if (plantDisease.second_type) {
      title += ` ${plantDisease.second_type} >`;
    }

    if (plantDisease.third_type) {
      title += ` ${plantDisease.third_type} >`;
    }

    if (plantDisease.fourth_type) {
      title += ` ${plantDisease.fourth_type} >`;
    }

    if (plantDisease.fifth_type) {
      title += ` ${plantDisease.fifth_type} >`;
    }

    return (
      <div className="font-18 font-weight-400 mb-4">
        {title.substr(0, title.length - 2)}
      </div>
    );
  }

  render(): ReactNode {
    const { type } = this.props;
    const { fetching, plantDisease, activeLang, updating } = this.state;

    if (type) return null;

    const tabs = supportedLangs.map((lang) => ({
      slug: lang
    }));

    return (
      <div className="row justify-content-center plant-edit-disease-view">
        <div className="col-6">
          {(fetching || updating) && <Loader />}
          {plantDisease && (
            <Card>
              <CardBody>
                {this.renderTitle()}
                <NavTab
                  tabs={tabs}
                  activeTab={activeLang}
                  onChange={(lang) => this.setState({ activeLang: lang })}
                />
                <Form onSubmit={this.onSubmit}>
                  {supportedLangs.map((lang) => {
                    return (
                      <div
                        key={lang}
                        className={activeLang !== lang ? 'd-none' : ''}
                      >
                        {this.renderWysiwyg('about', lang)}
                        {this.renderWysiwyg('treatment', lang)}
                        {this.renderWysiwyg('prevention', lang)}
                      </div>
                    );
                  })}
                  <div className="mt-3 d-flex justify-content-between">
                    <Link
                      to={{
                        pathname: getPathUrl(ROUTE_PLANT_DISEASES)
                      }}
                      className="btn btn-primary"
                      color="primary"
                    >
                      Cancel
                    </Link>
                    <Button disabled={updating} color="secondary">
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(PlantEditDiseaseView);
