import listReducer from 'modules/Garden/reducer/list';
import detailsReducer from 'modules/Garden/reducer/details';
import { combineReducers, Reducer } from 'redux';
import { GardenState } from 'modules/Garden/state';

const reducer = (): Reducer<GardenState> => {
  const reducers = {
    list: listReducer,
    details: detailsReducer
  };

  return combineReducers<GardenState>(reducers);
};

export default reducer;
